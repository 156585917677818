/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { User } from "@helpers";
import useWindowDimensions from "@hooks/useWindowDimensions";
import DoctorNavigation from "@src/layouts/navigation/doctor";
import PatientNavigation from "@src/layouts/navigation/patient";
import AdminDashboardNavigation from "@src/layouts/navigation/admin_app";
import NurseAppNavigation from "@src/layouts/navigation/nurses_app";
import { SHOW_LOGIN_MODAL } from "@modules/gui";

const AppHeader = ({ user, mainCameraPublisher }) => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setShowMenu(width > 1024);
  }, [width]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const showCallTest = () => {
    console.log("toggle menu");
  };
  const toHomePage = () => {
    history.push("/");
  };
  // const visitor = {};
  const isDoctor = User.isDoctor(user);
  const isAdmin = User.isAdmin(user);
  const isPatient = User.isPatient(user);
  const isNurse = User.isNurse(user);
  return (
    <div className="top-bar">
      <div className="main-brand" onClick={toHomePage}></div>
      <div className="responsive-menu-icon" onClick={toggleMenu}>
        <i className="icon-menu-toggle"></i>
      </div>
      <div className="sub-brand"></div>

      {isDoctor &&
        ((
          <div>
            {width < 1025 && !mainCameraPublisher && (
              <div className="mobile-notification-action  mna-bell">
                <a href="#" onClick={showCallTest}>
                  <i className="icon-settings-icon"></i>
                </a>
              </div>
            )}
          </div>
        ),
        (<DoctorNavigation showMenu={showMenu} setShowMenu={setShowMenu} />))}
      {isNurse && <NurseAppNavigation showMenu={showMenu} />}
      {isAdmin && (
        <AdminDashboardNavigation
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      )}
      {isPatient && (
        <PatientNavigation showMenu={showMenu} setShowMenu={setShowMenu} />
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    showLoginModal: () =>
      dispatch({
        type: SHOW_LOGIN_MODAL,
        show: true,
      }),
  };
};
const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
