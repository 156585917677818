import React from "react";

const CircleLoader = ({ children, done, size }) => {
  return (
    <div
      style={size ? { width: size, height: size } : {}}
      className={done ? "circle-loader load-complete" : "circle-loader"}
    >
      <div className="checkmark draw">{children}</div>
    </div>
  );
};
export default CircleLoader;
