import React from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

export const CountryDropdownField = ({ input, meta, ...props }) => {
  return (
    <CountryDropdown
      {...props}
      {...input}
      isInvalid={meta.error && meta.touched}
    />
  );
};

export const RegionDropdownField = ({ input, meta, ...props }) => {
  return (
    <RegionDropdown
      {...props}
      {...input}
      isInvalid={meta.error && meta.touched}
    />
  );
};
