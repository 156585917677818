import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SHOW_POSTCODE_CHECKER } from "@modules/gui";
import { Routes } from "@constants/";
import useWindowDimensions from "@hooks/useWindowDimensions";
import LogoutHeader from "./logout_header";

function DefaultNavigation({ showMenu, setShowMenu }) {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const hideInMobile = () => {
    if (width < 800) {
      setShowMenu(false);
    }
  };

  const showPostCodeChecker = () => {
    dispatch({
      type: SHOW_POSTCODE_CHECKER,
      show: true,
    });
    hideInMobile();
  };

  return (
    <ul className={`nav ${showMenu ? "d-block" : ""}`}>
      <li>
        <span> Date & Time Audits </span>

        <ul className="drop-menu">
          <li>
            <Link to="/admin/appointments" onClick={hideInMobile}>
              Appointments
            </Link>
          </li>
          <li>
            <Link to={Routes.paths.messages} onClick={hideInMobile}>
              Inbox
            </Link>
          </li>
        </ul>
      </li>

      <li>
        <span> Specialists Management </span>
        <ul className="drop-menu">
          <li>
            <Link to="/admin/specialists" onClick={hideInMobile}>
              Specialists Accounts
            </Link>
          </li>
          <li>
            <Link to="/admin/emergency-room-manager" onClick={hideInMobile}>
              Emerg room management
            </Link>
          </li>
          <li>
            <Link to="/admin/specialties" onClick={hideInMobile}>
              Specialties
            </Link>
          </li>
          <li>
            <Link to="/admin/companies" onClick={hideInMobile}>
              Companies
            </Link>
          </li>
        </ul>
      </li>

      <li>
        <span> Patients Management </span>
        <ul className="drop-menu">
          <li>
            <a href="#" onClick={showPostCodeChecker}>
              POSTCODE CHECKER
            </a>
          </li>
          <li>
            <Link to="/admin/referrals" onClick={hideInMobile}>
              Referrals
            </Link>
          </li>
          <li>
            <Link to="/admin/patients" onClick={hideInMobile}>
              Patients Accounts
            </Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/admin/emergency-doctors" onClick={hideInMobile}>
          Emergency Rooster
        </Link>
      </li>

      <LogoutHeader />
    </ul>
  );
}

export default DefaultNavigation;
