import React from "react";

function PriceWrap() {
  return (
    <div className="mo-qld-wrap">
      <div className="container">
        <div className="row row-flex">
          <img
            alt="2xadvance qld logo"
            className="xadvance-logo"
            src="/assets/images/2xadvance_qld_lockup.png"
          />

          <div className="xadvance-text">
            <span className="large">WINNER</span>
            <span className="small">
              Ignite Ideas Grant for high-growth potential start-ups to compete
              in a global market.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceWrap;
