import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import useWindowDimensions from "@hooks/useWindowDimensions";
import Routes from "@src/constants/routes";
import ServicesMenu from "./services_menu";
import LoginMenu from "./login_menu";
import { SHOW_PRICING_MODAL } from "@modules/gui";

function DefaultNavigation({ showMenu, setShowMenu }) {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const hideInMobile = () => {
    if (width < 800) {
      setShowMenu(false);
    }
  };
  const showPricingModal = () => {
    return dispatch({
      type: SHOW_PRICING_MODAL,
      show: true,
    });
  };
  const showSpecialistPricingModal = () => {
    console.log("showSpecialistPricingModal");
  };
  return (
    <ul
      id="menu-primary-menu"
      className="menu genesis-nav-menu menu-primary responsive-menu nav-primary"
      style={{ display: showMenu ? "block" : "none" }}
    >
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children ">
        <Link to={Routes.paths.about} onClick={hideInMobile}>
          About
        </Link>
        <ul className="sub-menu">
          <li className="menu-item menu-item-type-post_type menu-item-object-page ">
            <Link to={Routes.paths.about} onClick={hideInMobile}>
              What is DOCTO?
            </Link>
          </li>
          <li className="menu-item menu-item-type-post_type menu-item-object-page ">
            <Link to={Routes.paths.executiveTeam} onClick={hideInMobile}>
              Executive Team
            </Link>
          </li>
          <li
            id="menu-item-1255"
            className="menu-item menu-item-type-post_type menu-item-object-page "
          >
            <Link to={Routes.paths.press} onClick={hideInMobile}>
              Press
            </Link>
          </li>
        </ul>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page">
        <Link to={Routes.paths.browseHospital} onClick={hideInMobile}>
          Browse Hospital
        </Link>
        <ul className="sub-menu">
          <li className="menu-item menu-item-type-post_type menu-item-object-page ">
            <Link to={Routes.paths.browseHospital} onClick={hideInMobile}>
              Online specialist
            </Link>
          </li>
          <li className="menu-item menu-item-type-post_type menu-item-object-page ">
            <Link to={Routes.paths.referAPatient} onClick={hideInMobile}>
              Refer a Patient
            </Link>
          </li>
        </ul>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children ">
        <Link to="#">Pricing</Link>
        <ul className="sub-menu">
          <li className="menu-item menu-item-type-post_type menu-item-object-page ">
            <Link to="#">
              <span onClick={showPricingModal}>Online Dr now</span>
            </Link>
          </li>
          <li className="menu-item menu-item-type-post_type menu-item-object-page ">
            <Link to="#" onClick={showSpecialistPricingModal}>
              <span>Specialists</span>
            </Link>
          </li>
        </ul>
      </li>

      <ServicesMenu />
      <LoginMenu />
    </ul>
  );
}

export default DefaultNavigation;
