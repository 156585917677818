import Guid from "./guid";
import moment from "moment";

const formatTime = (time) => {
  let hr = time.getHours();
  let min = time.getMinutes();
  let day = time.getDate();
  let month = time.getMonth();
  let year = time.getFullYear();

  if (min < 10) {
    min = "0" + min;
  }
  let ampm = hr < 12 ? "am" : "pm";
  return month + "/" + day + "/" + year + " " + hr + ":" + min + ampm;
};

export default {
  formatTime(time) {
    return formatTime(time);
  },

  formatMessages(chats, currentUser) {
    let messages = [];
    if (!chats) {
      return messages;
    }
    chats.forEach(function (message, index) {
      let date = new Date(message.unixTime);
      let received = message.user ? currentUser.id !== message.user.id : false;
      let isMine = message.user ? currentUser.id === message.user.id : false;
      let name = message.user ? message.user.firstName : message.userId;

      //let lastMessage = R.last(messages);
      let newMessage = {
        chatId: message.id,
        thumbnailImage: message.user?.thumbnailImage,
        channel: message.appointmentId,
        senderId: message.userId,
        sender: isMine ? "You" : name,
        formattedTime: formatTime(date),
        headerFormatTime:
          moment.unix(message.unixTime).format("MMM DD, YYYY") ===
          moment().format("MMM DD, YYYY")
            ? "Today"
            : moment.unix(message.unixTime).format("MMM DD, YYYY"),
        received: received,
        isMine: isMine,
        date: date,
        unixTime: message.unixTime,
        body: message.message,
        timestamp: date,
        meta: message.meta,
        key: `${Guid()}index${index}`,
      };
      messages.push(newMessage);
    });
    return messages;
  },
  formatSystemMessage(systemMessage) {
    let messages = [];
    console.log("format systemMessage", systemMessage);
    systemMessage.meta.messages.forEach(function (message, index) {
      var systemMessageCopy = { ...systemMessage };
      systemMessageCopy.body = message.text;
      systemMessageCopy.meta = {
        type: message.type || "system",
        actions: message.actions,
        role: message.role,
        params: message.params || null,
      };

      messages.push(systemMessageCopy);
    });
    console.log("formatSystemMessage", messages);
    return messages;
  },
};
