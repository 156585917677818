import React, { useEffect, useRef, useState } from "react";
import { Utils } from "@helpers";
import Record from "./record";
import moment from "moment";
import empty from "is-empty";
import { Appointments, Toast } from "@helpers/index";
import {
  closeConfirmationModal,
  showConfirmationModal,
  hidePatientMedicalRecord,
} from "@modules/gui";
import { useDispatch } from "react-redux";
const Loading = () => {
  return (
    <div className="note-ittem-loading">
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
    </div>
  );
};

const Files = ({
  files,
  status,
  appointmentId,
  user,
  confirmRemoveReferralFile,
}) => {
  switch (status) {
    case "LOADING":
      return <Loading />;
    case "COMPLETED":
      return (
        <ul>
          {files
            .map((record, index) => {
              return (
                <Record
                  key={`note-${index}`}
                  record={record}
                  user={user}
                  index={index}
                  onDelete={(index, fileName) =>
                    confirmRemoveReferralFile(index, fileName)
                  }
                  appointmentId={appointmentId}
                />
              );
            })
            .reverse()}
        </ul>
      );
    default:
      return null;
  }
};

const MedicalRecords = (props) => {
  const [status, setStatus] = useState("LOADING");
  const [files, setFiles] = useState([]);
  const { appointmentId, user, show } = props;
  const uploadFileRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchFilesforUser();
    return () => {
      setStatus("LOADING");
      setFiles([]);
    };
  }, [appointmentId]);

  const uploadFile = () => {
    uploadFileRef.current.click();
  };

  const uploadFormData = async (formData, filesToUpload) => {
    setFiles([...files, ...filesToUpload]);
    try {
      await Appointments.uploadAppointmentReferralDocument(
        appointmentId,
        user.token,
        formData
      );
      fetchFilesforUser(appointmentId, user);
    } catch (error) {
      Toast.displayToast({
        message: "Unexpected error occur. Please try again",
        kind: "error",
      });
      fetchFilesforUser(appointmentId, user);
    }
  };
  const uploadReferralChange = (ev) => {
    const f = Array.from(ev.target.files);
    if (Utils.exceedFileSize(f)) {
      Toast.displayToast({
        message: "files exceeds the 25MB file-size",
        kind: "error",
      });
      return;
    }
    const filesToUpload = f.map((element) => {
      return {
        fileName: element.name,
        size: element.size,
        status: "LOADING",
      };
    });
    const formData = Utils.getFormData(f);
    uploadFormData(formData, filesToUpload);
  };
  const fetchFilesforUser = async () => {
    if (empty(appointmentId)) {
      return;
    }
    try {
      const response = await Appointments.listPatientDocumentsByAppointmentId(
        appointmentId,
        user.token
      );
      const f = response.sort(
        (a, b) =>
          moment(a.lastModified).toDate() - moment(b.lastModified).toDate()
      );
      setFiles(f);
      setStatus("COMPLETED");
    } catch (error) {
      Toast.displayToast({
        message: "Unexpected error occur. Please try again",
        kind: "error",
      });
      setStatus("ERROR");
    }
  };

  const removeReferralFile = async (index) => {
    console.log({ index, files });
    let newFiles = files;
    let file = newFiles[index];
    const { fileName } = file;
    file.status = "LOADING";
    setFiles([...newFiles]);
    dispatch(closeConfirmationModal());
    try {
      await Appointments.removeReferralFile(
        appointmentId,
        fileName,
        user.token
      );
      file.status = "DELETED";
      setFiles([...newFiles]);
    } catch (error) {
      Toast.displayToast({
        message: "Unexpected error occur. Please try again",
        kind: "error",
      });
      file.status = "COMPLETED";
      setFiles([...newFiles]);
    }
  };
  const confirmRemoveReferralFile = (index, fileName) => {
    console.log("confirmRemoveReferralFile", { index, fileName });
    dispatch(
      showConfirmationModal({
        onClose: () => dispatch(closeConfirmationModal()),
        onProceed: () => removeReferralFile(index),
        proceedText: "Delete",
        child: `Are you sure you want to delete ${fileName}?`,
      })
    );
  };

  const closeModal = () => {
    dispatch(hidePatientMedicalRecord());
  };

  return (
    <div className={show ? "notes-pannel active" : "notes-pannel"}>
      <div className="notes-pannel-inner">
        <div className="np-header">
          <h4>Medical Records</h4>
          <a onClick={() => closeModal()}>
            <i className="icon-cross"></i>
          </a>
        </div>
        <div className="np-actions">
          <a onClick={() => uploadFile()} className="add-note">
            Add New File
            <i className="icon-plus"></i>
          </a>
          <input
            multiple={true}
            id="upload-file"
            className="hide"
            ref={uploadFileRef}
            type="file"
            onChange={(e) => uploadReferralChange(e)}
          />
        </div>
        <div className="np-body np-body-files">
          <div className="ad-upload-pack">
            <Files
              {...{
                files,
                status,
                appointmentId,
                user,
                confirmRemoveReferralFile,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalRecords;
