import React from "react";
import { Routes } from "@constants/";
import NavLink from "components/nav_link";
import Profile from "./profile";
import Billing from "../billing";

class UserProfile extends React.Component {
  render() {
    const { location } = this.props;
    const isProfile = location.pathname === Routes.paths.account.profile;

    return (
      <div className="profile-holder">
        <div className="row">
          <div className="prof-menu">
            <NavLink active={isProfile} to={Routes.paths.account.profile}>
              Profile Information
            </NavLink>
            <NavLink active={!isProfile} to={Routes.paths.account.billing}>
              Billing Information
            </NavLink>
          </div>
        </div>
        {isProfile && <Profile {...this.props} />}
        {!isProfile && <Billing {...this.props} />}
      </div>
    );
  }
}
export default UserProfile;
