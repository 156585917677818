import React from "react";
import powerdByImg from "@assets/images/vidBox-brand.png";

function PoweredByBrand() {
  return (
    <div className="vid-brand">
      <span>Powered By:</span>
      <img src={powerdByImg} />
    </div>
  );
}

export default PoweredByBrand;
