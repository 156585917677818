import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Specialists } from "@helpers";
import SpecialistBox from "./specialist_box";

function specialists() {
  const [specialists, setSpecialists] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const specialists = await Specialists.fetchSpecialists();
        setSpecialists(specialists);
      } catch (error) {
        console.error(error);
        setSpecialists([]);
      }
    }
    fetchData();
  }, []);

  const [showAllSpecialists, setShowAllSpecialists] = useState(false);
  const specialistsDisplay = showAllSpecialists
    ? specialists
    : specialists.slice(0, 30);
  return (
    <div className="mo-doctor-list-wrap">
      <p>
        <Link to="/browse-hospital">The Online Hospital</Link> - 100+Medical
        Specialists - 18756 registered patients.
      </p>
      {specialistsDisplay && [
        <div
          key="molist1"
          className={
            showAllSpecialists ? "mo-list-holder show-all" : "mo-list-holder"
          }
        >
          {specialistsDisplay.map((specialist) => {
            return (
              <SpecialistBox key={`${specialist.id}_sp`} user={specialist} />
            );
          })}
        </div>,

        showAllSpecialists ? (
          <a
            key="molist2"
            onClick={() => setShowAllSpecialists(false)}
            className="btn"
          >
            Show less
          </a>
        ) : (
          <a
            key="molist3"
            onClick={() => setShowAllSpecialists(true)}
            className="btn"
          >
            Show All
          </a>
        ),
      ]}
    </div>
  );
}

export default specialists;
