import React from "react";
import { Link } from "react-router-dom";

class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      password: "",
    };
  }

  close(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onClose();
  }

  trySignUp(e) {
    if (e.key === "Enter") {
      this.signup(e);
    }
  }

  signup(event) {
    event.preventDefault();
    this.props.onChangePassword(this.state.password);
  }

  render() {
    let { password } = this.state;
    return (
      <div className="popup-wrap">
        <div className="modal-window md-sign-up">
          <div className="modal">
            <div>
              <div className="md-header">
                <a href="#" className="md-close" onClick={(e) => this.close(e)}>
                  <i className="icon-cross-circle"></i>
                </a>
                <h4>Change Password</h4>
              </div>
              <div className="md-body">
                <p>Please enter a password.</p>
                <input
                  tabIndex="6001"
                  onKeyDown={(e) => this.trySignUp(e)}
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <div className="md-footer">
                <button
                  className="btn btn-info"
                  onClick={(e) => this.signup(e)}
                >
                  {this.props.submitting ? (
                    <img
                      className="loading-login"
                      src="../assets/images/loadingLightBlue.gif"
                    />
                  ) : (
                    "Change Password"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
