import api from "@src/api";
import { Routes } from "@constants";

const Specialists = class Specialists {
  static async fetchSpecialists() {
    return await api.get(Routes.getDoctors);
  }
  static async fetchSpecialistsBySpecialtyId(specialtyId) {
    return await api.get(Routes.fetchSpecialistsBySpecialtyId(specialtyId));
  }
};
export default Specialists;
