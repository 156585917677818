import React, { useEffect, useState } from "react";
import { history } from "@modules/";
import moment from "moment";
import { Appointments } from "@helpers";

const AppointmentTimeZoneChecker = (props) => {
  const [status, setStatus] = useState("LOADING");
  const [appointment, setAppointment] = useState(null);
  const { appointmentId } = props.computedMatch.params;

  useEffect(() => {
    fetchAppointment();
  }, []);

  const renderLoading = () => {
    return (
      <div className="dr-profile-wrap">
        <div className="drp-scroll">
          <div className="drp-panel">
            <div className="drp-name">
              <span>Loading...</span>
            </div>
            <div className="drp-desc"></div>
            <div className="drp-footer"></div>
          </div>
        </div>
      </div>
    );
  };
  const fetchAppointment = async () => {
    if (!appointmentId) {
      history.replace("not-found");
      return;
    }
    try {
      const ap = await Appointments.getAppointment(appointmentId);
      setAppointment(ap);
      setStatus("COMPLETED");
    } catch (error) {
      console.error(error);
      history.replace("not-found");
    }
  };

  const renderAppointmentInfo = () => {
    let { scheduledOn } = appointment;
    scheduledOn = moment(scheduledOn);
    return (
      <div className="joinAsGuestWrap">
        <div className="jag_info_wrap">
          <div className="jag_body_settings">
            <div className="jbs-message">
              <div className="message-alert-box alert-info">
                <p>
                  Your call is scheduled for{" "}
                  {scheduledOn.format("DD MMM YYYY hh:mm A")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="jag_brand"></div>
      </div>
    );
  };
  switch (status) {
    case "LOADING":
      return renderLoading();
    case "COMPLETED":
      return renderAppointmentInfo();
    default:
      return null;
  }
};

export default AppointmentTimeZoneChecker;
