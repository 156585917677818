import React, { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";
import api from "@src/api";

import { useDispatch } from "react-redux";
import { closeDocumentViewer } from "@modules/gui";
import spin from "@assets/images/spin.gif";
import mime from "mime-types";

const DocViewer = (props) => {
  console.log(props);
  const { token, url } = props;

  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [blob, setBlob] = useState(null);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    loadDocument();
  }, [url]);
  const close = () => {
    dispatch(closeDocumentViewer());
  };
  const loadDocument = async () => {
    try {
      // setFile({ type, source: url });

      const b = await api.getBlob(`${url}?accessToken=${token}`);
      const key = url.split("/").pop().toLowerCase();
      const f = decodeURIComponent(key).split("/").pop().toLowerCase();
      setFileName(f);
      // console.log(response);
      // console.log(response.type);
      // const blob = new Blob(
      //   [response],
      //   { type: 'application/pdf' });
      setBlob(b);
      const fileURL = URL.createObjectURL(b);
      const type = mime.extension(b.type);
      setFile({ type, source: fileURL });
    } catch (error) {
      console.error(error);
    }
  };
  const showFile = () => {
    console.log("showFile");
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = data;
    link.target = "_blank";
    link.download = fileName;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  };

  return (
    <div className="modal-window">
      <div className="md-align-view-file">
        <div className="modal-view-file">
          <div className="md-header">
            <a onClick={() => close()} className="md-close">
              <i className="icon-cross-circle"></i>
            </a>

            <button className="btn-info mt-5" onClick={() => showFile()}>
              Download
            </button>
          </div>

          {file ? (
            <FileViewer fileType={file.type} filePath={file.source} />
          ) : (
            <div className="md-loader">
              <img src={spin} className="logo-spin" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DocViewer;
