import React from "react";

const Error = ({ error }) => {
  return (
    <div className="meeting-holder">
      <ul className="control-panel"></ul>
      <div className="video-box-panel"></div>
      <div className="meeting-message">
        <div className={`message-alert-box alert-${error.type}`}>
          <i className="icon-stream-alert"></i>
          <h4>{error.title}</h4>
          <p>{error.message} </p>
        </div>
      </div>
    </div>
  );
};
export default Error;
