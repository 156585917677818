import React, { Component } from "react";

class TagForm extends Component {
  constructor() {
    super();
    this.state = {
      values: [],
    };
  }
  componentDidMount() {
    this.setState({ values: this.props.values });
  }
  changeInputValue(e) {
    const { name, value } = e.target;
    const { values } = this.state;

    const sp = name.split(".");
    values[sp[0]][sp[1]] = value;
    this.setState({ values });
    this.props.saveValues(values);
  }
  createPair() {
    const { values } = this.state;
    values.push(this.props.default);
    this.setState({ values });
    this.props.saveValues(values);
  }
  render() {
    const { values } = this.state;
    return (
      <div>
        {values.map((tag, index) => {
          return (
            <div className="tag-input" key={index}>
              <input
                value={tag.name}
                onChange={this.changeInputValue.bind(this)}
                type="text"
                name={`${index}.name`}
              />
              <input
                value={tag.content}
                onChange={this.changeInputValue.bind(this)}
                type="text"
                name={`${index}.content`}
              />
            </div>
          );
        })}
        <button onClick={this.createPair.bind(this)} className="add">
          Add SEO Tag
        </button>
      </div>
    );
  }
}

export default TagForm;
