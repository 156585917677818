import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MagicLink from "./magic_link";
import Confirmation from "./confirmation";
import Password from "./password";
import Success from "./success";
import Signup from "./signup";
import { SHOW_LOGIN_MODAL } from "@modules/gui";
import { login } from "@modules/session";
import { Session } from "@helpers";
import * as EmailValidator from "email-validator";
import toastr from "toastr";
// import { Routes } from "@constants/";
// import { Link } from "react-router-dom";
// import { Token, User } from "@helpers";
// import { logout } from "@modules/session";

const LoginModal = (props) => {
  const { close, navigation } = props;

  const [step, setStep] = useState("init");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [confirmation, setConfirmation] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enablePassword, setEnablePassword] = useState(false);
  const dispatch = useDispatch();
  const usePassword = () => {
    setStep("password");
  };
  useEffect(() => {
    if (navigation === "password") {
      usePassword();
      setEnablePassword(true);
    }
  }, []);
  const sendMagicLink = async (email) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    if (!EmailValidator.validate(email)) {
      setError({ ...error, email: "Invalid Email" });
      return;
    }
    const response = await Session.sendMagicLink(email, {
      confirm: confirmation,
    });
    setSubmitting(false);
    const { status, message } = response;
    switch (status) {
      case "notFound":
        setStep("signUp");
        toastr.warning("Email is not register. Please create your profile.");
        break;
      case "invalidEmail":
      case "accountBlock":
        toastr.warning(message);
        setError({ ...error, email: "Invalid Email" });
        break;
      case "needConfirmation":
        setConfirmation(true);
        setStep("confirmation");
        break;
      case "success":
        setStep("successMagicLink");
        break;
      default:
        toastr.warning("Unexpected error. Please try again");
        break;
    }
  };

  const loginWithPassword = async () => {
    const loginFunction = login(email, password);
    setSubmitting(true);
    await dispatch(loginFunction);
    setSubmitting(false);
  };
  const createProfile = async (user) => {
    setSubmitting(true);
    try {
      await Session.createPatient(user);
      await Session.sendConfirmationSignUp(email);
      setStep("successMagicLink");
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      toastr.warning("Unexpected error. Please try again");
    }
  };

  switch (step) {
    case "init":
      return (
        <MagicLink
          error={error}
          sendMagicLink={sendMagicLink}
          close={close}
          submitting={submitting}
          email={email}
          setEmail={setEmail}
        />
      );
    case "confirmation":
      return (
        <Confirmation
          error={error}
          sendMagicLink={sendMagicLink}
          close={close}
          submitting={submitting}
          usePassword={usePassword}
          email={email}
          setEmail={setEmail}
        />
      );
    case "password":
      return (
        <Password
          error={error}
          email={email}
          close={close}
          submitting={submitting}
          loginWithPassword={loginWithPassword}
          setEmail={setEmail}
          enable={enablePassword}
          password={password}
          setPassword={setPassword}
        />
      );
    case "successMagicLink":
      return <Success close={close} />;
    case "signUp":
      return (
        <Signup
          close={close}
          setStep={setStep}
          submitting={submitting}
          email={email}
          createProfile={(user) => createProfile(user)}
        />
      );
    default:
      return (
        <MagicLink
          error={error}
          sendMagicLink={(email) => sendMagicLink(email)}
          close={close}
          submitting={submitting}
        />
      );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    close: () =>
      dispatch({
        type: SHOW_LOGIN_MODAL,
        show: false,
      }),
  };
};
const mapStateToProps = ({ session, gui }) => ({
  user: session.user,
  gui,
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
