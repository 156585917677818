import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Nurses, Toast } from "@helpers/";
import UnboundConfirmationModal from "@src/components/modals/unbound_confirmation_modal";
import TableLoader from "../preloader/table";

function index() {
  const [nurses, setNurses] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedNurse, setSelectedNurse] = useState(null);
  const user = useSelector((state) => state.session.user);

  useEffect(() => {
    fetchNurses();
  }, []);

  const fetchNurses = async () => {
    try {
      const response = await Nurses.fetchAll();

      setNurses(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  if (nurses.length === 0) {
    return (
      <TableLoader columns={["fistName", "lastName", "email", "countryCode"]} />
    );
  }

  const showDeleteAlert = (nurse) => {
    setSelectedNurse(nurse);
    console.log(nurse);
    setShowDeleteConfirmation(true);
  };

  const deleteNurse = async () => {
    try {
      await Nurses.deleteNurse(selectedNurse.id, user.token);

      setSelectedNurse(null);
      fetchNurses();
      Toast.displayToast({
        type: "success",
        message: "Nurse has been deleted",
      });
    } catch (error) {
      console.log(error);
      Toast.displayToast({ type: "error", message: "Something Went Wrong" });
    }
  };
  return (
    <div className="admin-holder">
      <div className="doctor-list">
        <div className="admin-header">
          <h1>Emerg Room</h1>
          <div className="top-actions">
            <a
              href="/admin/create-emergency-room-manager"
              className="btn-green"
            >
              Create New account
            </a>
          </div>
        </div>

        <table className="styled table-responsive nurses">
          <thead>
            <tr>
              <td>Name</td>
              <td>Lastname</td>
              <td>Email</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody className="nurses">
            {nurses.length &&
              nurses.map((nurse) => {
                return (
                  <tr key={nurse.id}>
                    <td>
                      <Link to={`/admin/emergency-room-manager/${nurse.id}`}>
                        {nurse.firstName}
                      </Link>
                    </td>
                    <td>{nurse.lastName}</td>
                    <td>{nurse.email}</td>
                    <td>
                      <button
                        className="btn btn-red"
                        onClick={() => showDeleteAlert(nurse)}
                      >
                        <i className="icon icon-trash2"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {showDeleteConfirmation && (
        <UnboundConfirmationModal
          proceed={deleteNurse}
          proceedText="Delete"
          open={showDeleteConfirmation}
          close={() => setShowDeleteConfirmation(false)}
        >
          Are you sure you want to delete {selectedNurse.firstName}?
        </UnboundConfirmationModal>
      )}
    </div>
  );
}

export default index;
