import React, { useEffect, useState } from "react";
import { User } from "@helpers/";
import ta from "time-ago";
import { Appointments } from "@helpers/index";

const Loading = () => {
  return (
    <div className="note-ittem-loading">
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
    </div>
  );
};

let timerInterval = null;
const Note = (props) => {
  const [status, setStatus] = useState("SAVED");
  const [message, setMessage] = useState("");
  const { note, user, index, onSave, appointmentId } = props;

  useEffect(() => {
    setMessage(note.message);
  }, []);

  const startTimerToSaveMessage = (m) => {
    setMessage(m);
    if (timerInterval) {
      clearInterval(timerInterval);
    }
    timerInterval = setTimeout(() => {
      upsertNote(m);
    }, 1000);
  };

  const upsertNote = (m) => {
    if (note.id) {
      updateNote(m);
      return;
    }
    saveNote(m);
  };
  const onDelete = async () => {
    setStatus("LOADING");
    try {
      await Appointments.deleteDoctorNote(note.id, user.token);
      setStatus("DELETED");
    } catch (error) {
      console.error(error);
      setStatus("SAVED");
    }
  };

  const updateNote = async (m) => {
    setStatus("SAVING");
    try {
      const n = await Appointments.updateDoctorNote(note.id, user.token, m);
      onSave({ note: n, index });
      setStatus("SAVED");
    } catch (error) {
      console.error(error);
      setStatus("NOT SAVED");
    }
  };

  const saveNote = async (m) => {
    setStatus("SAVING");
    try {
      const n = await Appointments.createDoctorNoteByAppointment(
        appointmentId,
        user.token,
        m
      );
      onSave({ note: n, index });
      setStatus("SAVED");
    } catch (error) {
      console.error(error);
      setStatus("NOT SAVED");
    }
  };

  const isOwner = note.id ? user.id === note.userId : true;
  if (status === "LOADING") {
    return <Loading />;
  }
  if (status === "DELETED") {
    return null;
  }
  return (
    <div className="note-ittem-wrap">
      {note.id ? (
        <div className="note-ittem-header">
          {note.user ? (
            <h4>
              {User.fullName(note.user)} -{" "}
              <span>{ta.ago(note.lastUpdate)}</span>
            </h4>
          ) : (
            <h4></h4>
          )}
          <div className="saving-message message-alert-box alert-info">
            {status === "SAVING" ? <p>Saving...</p> : null}
            {status === "SAVED" ? <i className="icon-check"></i> : null}
          </div>
          {note.id && isOwner ? (
            <a onClick={() => onDelete()} className="delete-note">
              <i className="icon-trash2"></i>
            </a>
          ) : null}
        </div>
      ) : null}
      <textarea
        id={`input-area-${index}`}
        key={`input-area-${index}`}
        onChange={(ev) => startTimerToSaveMessage(ev.target.value)}
        className="note-ittem"
        placeholder="Click here to edit note..."
        value={message}
        disabled={!isOwner}
      ></textarea>
    </div>
  );
};

export default Note;
