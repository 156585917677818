//TODO REFACTOR
import React, { useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import NumberFormat from "react-number-format";
import cardValidator from "card-validator";
import empty from "is-empty";
import { useDispatch } from "react-redux";
import {
  showAdvancePaymentModal,
  closeAdvancePaymentModal,
} from "@modules/gui";
import LoadingImg from "@assets/images/docto-symbol.svg";

const AdvancePaymentModal = (props) => {
  const { navigation, loading, description, price } = props;
  const [billingInfo, setBillingInfo] = useState({
    city: "",
    postCode: "",
    address: "",
    country: "",
  });
  const [cardNumber, setCardNumber] = useState("");
  const [cardCvc, setCardCvc] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardExpiration, setCardExpiration] = useState("");
  const [cardExpYear, setCardExpYear] = useState("");
  const [cardExpMonth, setCardExpMonth] = useState("");
  const loadingMessage = props.loadingMessage || "Procesing your payment...";
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const setCardNum = (cc) => {
    var valid = cardValidator.number(cc.value);
    console.log(valid);
    setCardNumber(cc.value);
    if (valid?.card) {
      setCardType(valid.card.type);
    }
  };

  const setCvcNumber = (cvc) => {
    setCardCvc(cvc.value);
  };
  const setExpirationDate = (exp) => {
    const valid = cardValidator.expirationDate(exp.formattedValue);
    setCardExpiration(exp.formattedValue);
    setCardExpMonth(valid.month);
    setCardExpYear(valid.year);
  };
  const validateBillingInfo = () => {
    let errors = {};
    if (billingInfo.city.trim() === "") {
      errors.city = "City can't be blank";
    }
    if (billingInfo.country.trim() === "") {
      errors.country = "Country can't be blank";
    }
    if (billingInfo.address.trim() === "") {
      errors.address = "Address can't be blank";
    }

    if (billingInfo.postCode.trim() === "") {
      errors.postCode = "Post code can't be blank";
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };
  const validatePaymentMethod = () => {
    let errors = {};
    if (!cardValidator.expirationDate(cardExpiration).isValid) {
      errors.cardExpiration = "Invalid expiration date.";
    }
    if (!cardValidator.cvv(cardCvc).isValid) {
      errors.cardCvc = "Invalid CVV.";
    }
    if (!cardValidator.number(cardNumber).isValid) {
      errors.cardNumber = "Invalid card number.";
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };
  const close = () => {
    if (props.onClose) {
      props?.onClose();
    }

    dispatch(closeAdvancePaymentModal());
  };
  const savePaymentMethod = () => {
    if (!validatePaymentMethod()) {
      return;
    }
    if (props.onSavePayment) {
      return dispatchOnSavePayment();
    }
    dispatch(showAdvancePaymentModal({ navigation: "confirmation" }));
  };

  const dispatchOnSavePayment = () => {
    const creditCardInfo = {
      number: cardNumber,
      expirationMonth: cardExpMonth,
      expirationYear: cardExpYear,
      type: cardType,
      securityCode: cardCvc,
      billingInfo: {
        city: billingInfo.city,
        postCode: billingInfo.postCode,
        address: billingInfo.address,
        country: billingInfo.country,
      },
    };
    const card = empty(creditCardInfo.number) ? null : creditCardInfo;
    if (props.onSavePayment) {
      props.onSavePayment(card);
    }
  };

  const submitPayment = () => {
    const creditCardInfo = {
      number: cardNumber,
      expirationMonth: cardExpMonth,
      expirationYear: cardExpYear,
      type: cardType,
      securityCode: cardCvc,
      billingInfo: {
        city: billingInfo.city,
        postCode: billingInfo.postCode,
        address: billingInfo.address,
        country: billingInfo.country,
      },
    };
    const card = empty(creditCardInfo.number) ? null : creditCardInfo;
    props.onConfirm(card);
  };
  const setNavigation = (navigation) => {
    if (navigation === "paymentInfo" && !validateBillingInfo()) {
      return;
    }
    dispatch(showAdvancePaymentModal({ navigation }));
  };
  const setBillingInf = (event, object) => {
    if (event) {
      event.preventDefault();
    }
    setBillingInfo((billingInfo) => {
      return { ...billingInfo, ...object };
    });
  };
  const renderConfirmPayment = () => {
    return (
      <div className="main-wrap">
        <div className="pm-header">
          <a className="pm-back">
            <i
              onClick={() => setNavigation("billingInfo")}
              className="icon-chevron-left-circle"
            ></i>
          </a>

          <a className="pm-close">
            <i onClick={() => close()} className="icon-cross-circle"></i>
          </a>

          <h1>Confirm Payment</h1>
          <div className="header-div"></div>
          <p>{description}</p>
        </div>
        <div className="pm-body">
          <div className="pmf-amount">
            <em>Due Amount:</em>
            <b>A${price}</b>
          </div>

          <div className="pmf-button">
            <button onClick={() => submitPayment()}>Confirm Payment</button>
          </div>
        </div>
      </div>
    );
  };
  const renderPayment = () => {
    return (
      <div className="main-wrap">
        <div className="pm-header">
          <a className="pm-back">
            <i
              onClick={() => setNavigation("billingInfo")}
              className="icon-chevron-left-circle"
            ></i>
          </a>

          <a className="pm-close">
            <i onClick={() => close()} className="icon-cross-circle"></i>
          </a>

          <h1>Docto Consult</h1>
          <div className="header-div"></div>
          <p>Payment Info</p>
        </div>
        <div className="pm-body">
          <div className="pmf-inputs">
            <div className="pmf-group">
              <NumberFormat
                onValueChange={(ev) => setCardNum(ev)}
                value={cardNumber}
                format="#### #### #### ####"
                placeholder={
                  errors.cardNumber ? errors.cardNumber : "Card Number"
                }
                className={
                  errors.cardNumber ? "form-styled has-error" : "form-styled"
                }
              />
            </div>

            <div className="pmf-group double">
              <NumberFormat
                onValueChange={(ev) => setExpirationDate(ev)}
                value={cardExpiration}
                format="##/##"
                placeholder={
                  errors.cardExpiration ? errors.cardExpiration : "MM/YY"
                }
                mask={["M", "M", "Y", "Y"]}
                className={
                  errors.cardExpiration
                    ? "form-styled has-error"
                    : "form-styled"
                }
              />
              <NumberFormat
                onValueChange={(ev) => setCvcNumber(ev)}
                value={cardCvc}
                format="####"
                placeholder={errors.cardCvc ? errors.cardCvc : "CVV"}
                className={
                  errors.cardCvc ? "form-styled has-error" : "form-styled"
                }
              />
            </div>
          </div>

          <div className="pmf-button">
            <button onClick={() => savePaymentMethod()}>Continue</button>
          </div>
        </div>
      </div>
    );
  };
  const renderLoading = () => {
    return (
      <div className="main-wrap">
        <div className="pm-body">
          <div className="pmf-loading">
            <div className="loader-wrapper">
              <div className="spinner">
                <img src={LoadingImg} className="logo-spin" />
              </div>
              <span>{loadingMessage}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderAddress = () => {
    return (
      <div className="main-wrap">
        <div className="pm-header">
          <a onClick={() => close()} className="pm-close">
            <i className="icon-cross-circle"></i>
          </a>

          <h1>Docto Consult</h1>
          <div className="header-div"></div>
          <p>Billing Information</p>
        </div>
        <div className="pm-body">
          <div className="pmf-inputs">
            <div className="pmf-group">
              <input
                type="text"
                id="address"
                placeholder={errors.address ? errors.address : "Address"}
                onChange={(ev) =>
                  setBillingInf(ev, { address: ev.target.value })
                }
                value={billingInfo.address}
                className={
                  errors.address ? "form-styled has-error" : "form-styled"
                }
              />
            </div>
            <div className="pmf-group double">
              <input
                type="text"
                id="city"
                onChange={(ev) => setBillingInf(ev, { city: ev.target.value })}
                value={billingInfo.city}
                placeholder={errors.city ? errors.city : "City"}
                className={
                  errors.city ? "form-styled has-error" : "form-styled"
                }
              />
              <input
                type="text"
                id="postCode"
                onChange={(ev) =>
                  setBillingInf(ev, { postCode: ev.target.value })
                }
                value={billingInfo.postCode}
                placeholder={errors.postCode ? errors.postCode : "postcode"}
                className={
                  errors.postCode ? "form-styled has-error" : "form-styled"
                }
              />
            </div>

            <div
              className={
                errors.country
                  ? "pmf-group pmf-group-select has-error"
                  : "pmf-group pmf-group-select"
              }
            >
              <CountryDropdown
                required={true}
                value={billingInfo.country}
                defaultOptionLabel="Select a country"
                onChange={(val) => setBillingInf(null, { country: val })}
              />
            </div>
          </div>

          <div className="pmf-button">
            <button onClick={() => setNavigation("paymentInfo")}>
              Payment Info
              <i className="icon-arrow-right-circle"></i>
            </button>
          </div>
        </div>
      </div>
    );
  };
  const view = () => {
    let view = renderAddress();
    switch (navigation) {
      case "billingInfo":
        view = renderAddress();
        break;
      case "paymentInfo":
        view = renderPayment();
        break;
      case "confirmation":
        view = renderConfirmPayment();
        break;
      default:
        view = renderAddress();

        break;
    }
    if (loading) {
      view = renderLoading();
    }
    return view;
  };

  return (
    <div className="modal-drop">
      <div className="payments-modal">{view()}</div>
    </div>
  );
};

export default AdvancePaymentModal;
