import api from "@src/api";
import { Routes } from "@constants";

const getNurse = (token, id) => {
  return api.get(Routes.nursesWithToken(token, id));
};
const deleteNurse = (id, token) => {
  return api.del(Routes.nursesWithToken(token, id));
};
const editNurse = async (nurseId, payload, token) => {
  return await api.put(Routes.nursesWithToken(token, nurseId), payload);
};

const createNurse = async (payload, token) => {
  return await api.post(Routes.createNurseWithToken(token), payload);
};

const fetchAll = async () => {
  return await api.get(Routes.nurses);
};

export default {
  getNurse,
  deleteNurse,
  editNurse,
  fetchAll,
  createNurse,
};
