import React from "react";

const Success = (props) => {
  const { message, onContinue, title, subtitle, continueText } = props;
  return (
    <div className="jag_info_wrap">
      <h1 className="jag_title">{title}</h1>
      <span className="jag_sub_title">{subtitle}</span>
      <div className="jag_body_wrap">
        <span>{message}</span>
        <a className="jag-solid-btn" onClick={() => onContinue()}>
          {continueText}
        </a>
      </div>
    </div>
  );
};

export default Success;
