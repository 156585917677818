/* eslint-disable no-undef */
/* eslint-disable react/no-string-refs */
import React, { Component } from "react";
import { TokBox, Routes } from "@constants/";
import { Browser } from "@helpers/";
import moment from "moment";
let OT;
if (typeof window !== "undefined") {
  OT = require("@opentok/client");
}
import browser from "browser-detect";
import Services from "@helpers/services";

import botSound from "@assets/sounds/beep2.wav";

class CallSettings extends Component {
  constructor() {
    super();
    this.state = {
      audioSource: true,
      videoSource: true,
      input: { width: "0%" },
      conectionTest: { width: "0%" },
      publishAudio: true,
      publishVideo: true,
      connectionMessage: null,
      error: null,
      videoInputs: [],
      audioInputs: [],
      audioIndex: 0,
      audioLabel: "",
    };
  }
  triggerUnsupportBrowser() {
    if (Browser.compatibleBrowser()) {
      this.setError(TokBox.errors.DEVICES_NOT_FOUND);
      return;
    }
    if (Browser.isIOs()) {
      this.setError(TokBox.errors.UNSUPPORTED_BROWSER_IOS);
      return;
    }
    if (Browser.isAndroid()) {
      this.setError(TokBox.errors.UNSUPPORTED_BROWSER_ANDROID);
      return;
    }
    this.setError(TokBox.errors.DEVICES_NOT_FOUND);
  }

  componentDidMount() {
    console.log("OT.checkSystemRequirements()", OT.checkSystemRequirements());
    if (!OT.checkSystemRequirements()) {
      this.triggerUnsupportBrowser();
      return;
    }

    var options = {
      insertMode: "append",
      facingMode: "user",
      height: "100%",
      showControls: false,
      usePreviousDeviceSelection: true,
    };

    this.publisher = OT.initPublisher("videoTest", options, (e) =>
      this.createPublisherCallBack(e)
    );
    this.publisher.on({
      accessAllowed: () => {
        console.log("accessAllowed");
        this.setError(null);

        this.publisher.off("accessDialogOpened");
        this.publisher.off("accessDialogClosed");
        this.publisher.off("accessDenied");
      },
      accessDenied: (event) => {
        console.log("access denied", event);
        this.setError(TokBox.errors.DENIED);
      },
      accessDialogOpened: () => {
        this.setError(TokBox.errors.REQUESTING_ACCESS);
      },
      accessDialogClosed: () => {
        console.log("accessDialogClosed");
      },
      audioLevelUpdated: (event) => {
        this.setState({ input: { width: `${event.audioLevel * 100}%` } });
      },
    });
  }

  componentWillUnmount() {
    if (this.publisher) {
      this.publisher.off();
      this.publisher.destroy();
      this.publisher = null;
    }
    this.setState({
      input: { width: "0%" },
      conectionTest: { width: "0%" },
      publishAudio: true,
      publishVideo: true,
      connectionMessage: null,
      videoSource: true,
      audioSource: true,
    });
    if (this.otNetworkTest) {
      this.otNetworkTest.stop();
      this.otNetworkTest = null;
    }
  }

  playSound() {
    let ding = new Audio(botSound);
    ding.play();
  }

  switchCamera() {
    this.publisher.cycleVideo().then((deviceId) => {
      console.log(deviceId);
      var props = {
        publishAudio: this.state.publishAudio,
        publishVideo: this.state.publishVideo,
        videoSource: deviceId,
        audioSource: this.state.audioSource,
      };
      this.setState(props);
    });
  }

  async initCallTest() {
    const NetworkTest = require("opentok-network-test-js").default;
    const ErrorNames = require("opentok-network-test-js").ErrorNames;
    try {
      const response = await Services.getSessiontest();
      const data = response.data;
      const sessionInfo = {
        apiKey: data.apiKey,
        sessionId: data.session.sessionId,
        token: data.token,
      };
      this.otNetworkTest = new NetworkTest(OT, sessionInfo, {});
      var start = 0;
      this.otNetworkTest
        .testQuality(() => {
          start = start + 1;
          var starper = start < 60 ? (start * 100) / 60 : 100;

          this.setState({ conectionTest: { width: `${starper}%` } });
        })
        .then((results) => {
          var videomos = results.video.mos;
          if (videomos < 2.4) {
            this.setState({
              connectionMessage: { type: "warning", message: "Poor" },
            });
          }
          if (videomos >= 2.4 && videomos < 3.79) {
            this.setState({
              connectionMessage: { type: "warning", message: "Fair :)" },
            });
          }
          if (videomos >= 3.79) {
            this.setState({
              connectionMessage: { type: "success", message: "Excellent :)" },
            });
          }
          this.setState({ conectionTest: { width: `100%` } });
          // Display UI based on results
        })
        .catch((error, results) => {
          console.log("error testQuality", error);
          console.log("error testQuality results", results);

          switch (error.name) {
            case ErrorNames.UNSUPPORTED_BROWSER:
              this.setError(TokBox.errors.UNSUPPORTED_BROWSER);
              console.log("UNSUPPORTED_BROWSER");
              this.setState({
                connectionMessage: {
                  type: "error",
                  message: "Unsuported browser",
                },
              });

              // Display UI message about unsupported browser
              break;
            case ErrorNames.CONNECT_TO_SESSION_NETWORK_ERROR:
              this.setError(TokBox.errors.NETWORK_ERROR);
              this.setState({
                connectionMessage: {
                  type: "error",
                  message: "Network Error",
                },
              });
              // Display UI message about network error
              break;
            case ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES:
              this.setError(TokBox.errors.DEVICES_NOT_FOUND);
              this.setState({
                connectionMessage: {
                  type: "error",
                  message: "Failed to obtain media devices",
                },
              });
              // Display UI message about granting access to the microphone and camera
              break;
            case ErrorNames.NO_AUDIO_CAPTURE_DEVICES:
            case ErrorNames.NO_VIDEO_CAPTURE_DEVICES:
              this.setError(TokBox.errors.DEVICES_NOT_FOUND);
              this.setState({
                connectionMessage: {
                  type: "error",
                  message: "No video audio capture devices",
                },
              });
              // Display UI message about no available camera or microphone
              break;
            default:
              console.error("Unknown error .");
              this.setError(TokBox.errors.NETWORK_ERROR);
              this.setState({
                connectionMessage: {
                  type: "error",
                  message: "Unknown error",
                },
              });
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  stopTest() {
    this.otNetworkTest.stop();
    this.refs.connectionRow.remove();
  }

  createPublisherCallBack(err) {
    if (err) {
      this.triggerUnsupportBrowser(err);
      return;
    }
    OT.getDevices((err, devices) => {
      if (err) {
        console.log("getDevices", err);
        this.setError(TokBox.errors.DEVICES_NOT_FOUND);
      }
      const audioSource = this.publisher.getAudioSource();
      const audioInputs = devices.filter(
        (device) => device.kind === "audioInput"
      );
      const videoInputs = devices.filter(
        (device) => device.kind === "videoInput"
      );
      const audioIndex = audioInputs.findIndex((device) => {
        return device.label === audioSource.label;
      });
      this.setState({
        videoInputs: videoInputs,
        audioInputs: audioInputs,
        audioIndex: audioIndex,
        audioLabel: audioSource.label,
      });
    });
  }

  cycleMicrophone() {
    const { audioInputs, audioIndex } = this.state;
    if (audioInputs.length <= 1) {
      return;
    }
    const nextIndex = [(audioIndex + 1) % audioInputs.length];
    let deviceId = audioInputs[nextIndex].deviceId;
    this.setState({ audioIndex: nextIndex });
    this.publisher.setAudioSource(deviceId);
  }

  setError(e) {
    this.setState({ error: e });
    if (this.props.onError) {
      this.props.onError(e);
    }
  }

  toggleVideo() {
    var newPublishVideo = !this.state.publishVideo;
    this.publisher.publishVideo(newPublishVideo);
    var props = {
      publishAudio: this.state.publishAudio,
      publishVideo: newPublishVideo,
      videoSource: this.state.videoSource,
      audioSource: this.state.audioSource,
    };
    this.setState(props);
  }

  toggleAudio() {
    var newPublishAudio = !this.state.publishAudio;
    this.publisher.publishAudio(newPublishAudio);
    var props = {
      publishAudio: newPublishAudio,
      publishVideo: this.state.publishVideo,
      videoSource: this.state.videoSource,
      audioSource: this.state.audioSource,
    };
    this.setState(props);
  }
  renderAccessDeniedMessage() {
    const brows = browser();

    if (!brows.mobile) {
      return (
        <p>
          Allow Docto to access your camera and microphone by clicking the
          camera icon in your URL bar.
        </p>
      );
    }
    switch (brows.name) {
      case "safari":
        return (
          <p className="justify-p">
            Allow Docto to access your camera and microphone.
            <br />1 Open the Settings app.
            <br />2 navigate to Safari {`>`} Camera
            <br />3 Scroll down to Camera & Microphone.
            <br />4 Confirm that "Ask" or "Allow" is checked.
            <br />5 Refresh browser
          </p>
        );
      case "chrome":
        return (
          <p className="justify-p">
            Allow Docto to access your camera and microphone.
            <br />1 To the right of the address bar, tap More. Settings.
            <br />2 Tap Site Settings.
            <br />3 Tap Microphone or Camera.
            <br />4 Tap to turn the microphone or camera ON.
            <br />5 Refresh browser
          </p>
        );

      default:
        return (
          <p>
            Allow Docto to access your camera and microphone by checking camera
            settings in your browser
          </p>
        );
    }
  }
  renderAccessDenied() {
    const { allowChat } = this.props;

    return (
      <div className="jag_body_settings">
        <div className="camerahide" key="videoTest" id="videoTest">
          {/*video here*/}
        </div>
        {allowChat ? (
          <div className="jag_join_mid">
            <a onClick={() => this.props.joinNow()}>JOIN BY CHAT ONLY</a>
          </div>
        ) : null}

        <div className="jag-call-alert">
          <div className={`message-alert-box alert-danger`}>
            <i className="icon-stream-alert"></i>
            <h4>{this.state.error.title}</h4>
            {this.renderAccessDeniedMessage()}
          </div>
        </div>
      </div>
    );
  }
  renderError() {
    const { allowChat } = this.props;
    if (this.state.error.title === "Camera and microphone are blocked") {
      return this.renderAccessDenied();
    }
    return (
      <div className="jag_body_settings">
        <div className="camerahide" key="videoTest" id="videoTest">
          {/*video here*/}
        </div>
        {allowChat ? (
          <div className="jag_join_mid">
            <a onClick={() => this.props.joinNow()}>JOIN BY CHAT ONLY</a>
          </div>
        ) : null}

        <div className="jag-call-alert">
          <div className={`message-alert-box alert-${this.state.error.type}`}>
            <i className="icon-stream-alert"></i>
            <h4>{this.state.error.title}</h4>
            <p>{this.state.error.message}</p>
          </div>
        </div>
      </div>
    );
  }

  renderTimeMessage() {
    const { appointment, doctorOnline } = this.props.joinNowData;
    if (!appointment) {
      return null;
    }
    let diferenceMinutes = 0;
    let { scheduledOn } = appointment;
    scheduledOn = moment(scheduledOn);
    diferenceMinutes = scheduledOn.diff(moment(), "minutes", true);

    if (doctorOnline) {
      return (
        <div className="message-alert-box alert-info">
          <p>Doctor is waiting please join.</p>
        </div>
      );
    }
    if (diferenceMinutes > 5) {
      return (
        <div className="message-alert-box alert-info">
          <p>
            Your call is scheduled for{" "}
            {scheduledOn.format("DD MMM YYYY hh:mm A")}
          </p>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      appointment,
      doctorOnline,
      isDoctor,
      patientWaiting,
    } = this.props.joinNowData;

    if (this.state.error) {
      return this.renderError();
    }
    return (
      <div className="jag_body_settings">
        {isDoctor ? null : (
          <div className="jbs-message">
            {this.renderTimeMessage(appointment)}
          </div>
        )}

        <div className="jbs-camera" key="videoTest" id="videoTest">
          {/*video here*/}
        </div>
        {this.props.joinNow ? (
          <div className="jag_join_mid">
            {isDoctor ? (
              patientWaiting ? (
                <a onClick={() => this.props.joinNow()}>PATIENT IS WAITING</a>
              ) : (
                <a onClick={() => this.props.joinNow()}>JOIN NOW</a>
              )
            ) : doctorOnline ? (
              <a onClick={() => this.props.joinNow()}>JOIN NOW</a>
            ) : (
              <a className="disabled">WAITING FOR DOCTOR</a>
            )}
          </div>
        ) : null}
        {this.props.signIn ? (
          <div className="jag_join_mid">
            <a onClick={() => this.props.signIn()}>SIGN IN</a>
          </div>
        ) : null}
        <div className="set-row">
          <i className="icon-Camera_DOCTO"></i>
          <span>Camera</span>
          <div className="actions">
            <a
              href="#"
              className="block-btn active"
              onClick={() => this.switchCamera()}
            >
              SWITCH
            </a>
            <a
              href="#"
              className={
                this.state.publishVideo ? "switcher active" : "switcher"
              }
              onClick={() => this.toggleVideo()}
            ></a>
          </div>
        </div>

        <div className="set-row">
          <i className="icon-Mic_DOCTO"></i>
          <span>Microphone</span>
          <div className="actions">
            <a
              href="#"
              className="block-btn active"
              onClick={() => this.cycleMicrophone()}
            >
              SWITCH
            </a>
            <a
              href="#"
              className={
                this.state.publishAudio ? "switcher active" : "switcher"
              }
              onClick={() => this.toggleAudio()}
            ></a>
          </div>
          <div className="counter">
            <div className="counter-inner" style={this.state.input}></div>
          </div>
        </div>

        <div className="set-row">
          <i className="icon-Speakers_DOCTO"></i>
          <span>Speakers</span>
          <div className="actions">
            <a
              href="#"
              className="block-btn disabled"
              onClick={() => this.playSound()}
            >
              PLAY TONE
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default CallSettings;
