/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Specialties } from "@helpers";
import MedicareLogo from "@src/assets/images/Docto_Home_MedicareLogo.png";

function ConsultSpecialist() {
  const [specialties, setSpecialties] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const specialties = await Specialties.fetchSpecialties();
      setSpecialties(specialties);
    }
    fetchData();
  }, []);
  const [showDropDownSpecialtyMenu, setShowDropDownSpecialtyMenu] = useState(
    false
  );
  const toggleMenu = () => {
    return setShowDropDownSpecialtyMenu(!showDropDownSpecialtyMenu);
  };

  return (
    <div>
      <div className="mo-medicare-search-wrap">
        <div className="container">
          <div className="row row-flex">
            <div className="col-sm-12 col-md-5">
              <img
                className="medicare-logo"
                alt="medicare logo"
                src={MedicareLogo}
              />
            </div>
            <div className="col-sm-12 col-md-5">
              <p>Consult a Medical Specialist online</p>
              <button
                onClick={toggleMenu}
                aria-expanded="false"
                aria-haspopup="true"
                className="dropdown-toggle rounded"
                id=""
                type="button"
              >
                Book by Specialty
              </button>
              <div
                className={
                  showDropDownSpecialtyMenu
                    ? "dropdown-menu show"
                    : "dropdown-menu"
                }
                aria-labelledby="dropdownMenuButton"
                onBlur={() => setShowDropDownSpecialtyMenu(false)}
              >
                {specialties.map((specialty) => {
                  return (
                    <Link
                      key={`dropdownKey-${specialty.id}`}
                      className="dropdown-item"
                      to={`/browse-hospital?specialtyId=${specialty.id}`}
                    >
                      {specialty.name}
                    </Link>
                  );
                })}
                <Link
                  key={`dropdownKey-othher`}
                  className="dropdown-item"
                  to={`/contact`}
                >
                  Other
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultSpecialist;
