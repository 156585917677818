import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Utils, Toast, User, Appointments } from "helpers";
import PhoneInput from "react-phone-number-input";
import Datetime from "react-datetime";

import {
  SHOW_LOADING_MODAL,
  SHOW_UPDATE_APPOINTMENTS,
  SHOW_UPSERT_APPOINTMENT_MODAL,
} from "@modules/gui";

import {
  parseNumber,
  getCountryCallingCode,
  isValidNumber,
} from "libphonenumber-js";

class UpsertAppointmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameError: null,
      email: "",
      emailError: null,
      phone: "",
      phoneError: null,
      note: "",
      noteError: "",
      date: moment(),
    };
  }

  componentDidMount() {
    const appointment = this.props.appointment;
    if (this.props.appointment) {
      this.setState({
        name: appointment.patient.firstName,
        nameError: null,
        email: appointment.patient.guestEmail || appointment.patient.email,
        emailError: null,
        phone: `+${appointment.patient.countryCode}${appointment.patient.phoneNumber}`,
        phoneError: null,
        note: appointment.consultInfo
          ? appointment.consultInfo.notesToPatient
          : "",
        noteError: "",
        date: moment(appointment.scheduledOn),
      });
    }
  }
  validDate(current) {
    var yesterday = Datetime.moment().subtract(1, "day");
    return current.isAfter(yesterday);
  }

  async validateAndCreateAppointment() {
    let valid = true;
    if (this.state.phone === undefined || !isValidNumber(this.state.phone)) {
      this.setState({ phone: "", phoneError: "Invalid Phone Number" });
      valid = false;
    }
    if (Utils.isEmpty(this.state.name)) {
      this.setState({ name: "", nameError: "invalid Name" });
      valid = false;
    }
    if (Utils.isEmpty(this.state.email)) {
      this.setState({ email: "", emailError: "invalid Email" });
      valid = false;
    }
    if (Utils.isEmpty(this.state.email)) {
      this.setState({ email: "", emailError: "invalid Email" });
      valid = false;
    }
    if (!valid) {
      console.log("not valid");
      return;
    }
    this.props.showLoadingModal(true);

    const verifyEmail = await User.verifyEmail(this.state.email);
    if (!verifyEmail.valid) {
      this.props.showLoadingModal(false);
      return this.setState({
        emailError: "Please enter a valid Email",
        email: "",
      });
    }
    this.upsertAppointment();
  }
  successHandler() {
    this.props.showLoadingModal(false);
    this.props.showUpsertAppointmentModal(false);
    this.props.updateAppointments(true);
    let toastInfo = { message: "Appointment updated :).", kind: "success" };
    Toast.displayToast(toastInfo);
    if (this.props?.onComplete) {
      this.props?.onComplete();
    }
  }

  errorHandler(error) {
    this.props.showLoadingModal(false);
    const toastInfo = {
      message: "Unexpected error please try again",
      kind: "error",
    };
    Toast.displayToast(toastInfo);
    if (this.props?.onComplete) {
      this.props?.onComplete(error);
    }
  }
  async createAppointment(appointmentPayload) {
    try {
      const appointment = await Appointments.createFreeScheduledAppointment(
        this.props.user.token,
        appointmentPayload
      );
      console.log(appointment);
      this.successHandler();
    } catch (error) {
      this.errorHandler(error);
    }
  }

  updateAppointment(appointmentPayload) {
    try {
      Appointments.updateFreeScheduledAppointment(
        this.props.user.token,
        appointmentPayload
      );
      this.successHandler();
    } catch (error) {
      this.errorHandler(error);
    }
  }

  upsertAppointment() {
    const parsePhoneNumber = parseNumber(this.state.phone);
    const countryCode = getCountryCallingCode(parsePhoneNumber.country);
    const phoneNumber = parsePhoneNumber.phone;
    let appointmentPayload = {
      guestUser: {
        firstName: this.state.name,
        lastName: "",
        email: this.state.email,
        phoneNumber: phoneNumber,
        countryCode: countryCode,
      },
      scheduledOn: this.state.date.toDate(),
      notesToPatient: this.state.note,
    };

    if (this.props.appointment) {
      appointmentPayload.id = this.props.appointment.id;
      return this.updateAppointment(appointmentPayload);
    }
    this.createAppointment(appointmentPayload);
  }

  render() {
    return (
      <div className="modal-window md-appointment">
        <div className="modal">
          <div className="appointments-dash-header">
            <span>
              {this.props.appointment
                ? `UPDATE APPOINTMENT`
                : "CREATE NEW APPOINTMENT"}
            </span>
            <a
              onClick={() => this.props.showUpsertAppointmentModal(false)}
              className="back-btn"
            >
              Close
            </a>
          </div>
          <div className="appointments-dash-title">
            <span>Patient Details</span>
          </div>
          <div className="appointments-dash-body">
            <div className="ad-pill ad-pill-input">
              <input
                onChange={(e) => this.setState({ name: e.target.value })}
                value={this.state.name}
                type="text"
                placeholder={
                  this.state.nameError ? this.state.nameError : "Name"
                }
              />
            </div>
            <div className="ad-pill ad-pill-input">
              <input
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email}
                type="text"
                placeholder={
                  this.state.emailError ? this.state.emailError : "Email"
                }
              />
            </div>
            <div className="ad-pill ad-pill-input">
              <PhoneInput
                country="AU"
                smartCaret={false}
                value={this.state.phone}
                placeholder={
                  this.state.phoneError ? this.state.phoneError : "Phone"
                }
                className="ml-10"
                onChange={(phone) => this.setState({ phone: phone })}
              />
            </div>
          </div>

          <div className="appointments-dash-title">
            <span>Appointment Date and Time</span>
          </div>

          <div className="appointments-dash-body">
            <div className="ad-pill ad-pill-input">
              <Datetime
                value={this.state.date}
                onChange={(value) => {
                  this.setState({ date: value });
                }}
                timeFormat={false}
                dateFormat={"DD/MM/YYYY"}
                isValidDate={this.validDate}
              />
              <Datetime
                onChange={(value) => {
                  this.setState({ date: value });
                }}
                value={this.state.date}
                timeFormat={true}
                dateFormat={false}
                isValidDate={this.validDate}
              />
            </div>
            <div className="ad-pill ad-pill-input">
              <textarea
                onChange={(e) => this.setState({ note: e.target.value })}
                value={this.state.note}
                placeholder={
                  this.state.noteError ? this.state.noteError : "Add note"
                }
              ></textarea>
            </div>
          </div>

          <div
            onClick={() => this.validateAndCreateAppointment()}
            className="modal-footer"
          >
            Save and Send
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoadingModal: (show) =>
      dispatch({
        type: SHOW_LOADING_MODAL,
        show: show,
      }),
    updateAppointments: (show) =>
      dispatch({
        type: SHOW_UPDATE_APPOINTMENTS,
        show: show,
      }),
    showUpsertAppointmentModal: (show, appointment) =>
      dispatch({
        type: SHOW_UPSERT_APPOINTMENT_MODAL,
        params: {
          show: show,
          appointment: appointment,
        },
      }),
  };
};
const mapStateToProps = ({ session, gui }) => ({
  user: session.user,
  gui,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpsertAppointmentModal);
