import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { User, Payments } from "@helpers/";
import {
  showAdvancePaymentModal,
  closeAdvancePaymentModal,
  showConfirmationModal,
  closeConfirmationModal,
  showLoadingModal,
} from "@modules/gui";
import PoweredByBrand from "components/powered_by_brand";
import Preloader from "../profile/preloader";
import empty from "is-empty";

const BillingInfoError = ({ children }) => {
  return (
    <div className="profile-alert">
      <div className="alert alert-danger">
        <div className="icon-wrap">
          <i className="icon-bubble-alert"></i>
        </div>
        <p>{children}</p>
      </div>
    </div>
  );
};

const BillingInformation = () => {
  const [user, setUser] = useState({});
  const [plan, setPlan] = useState({});
  const [succededPayments, setPayments] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const viewer = useSelector((state) => state.session.user);

  const fetchUser = async () => {
    try {
      const user = await User.fetchUser(viewer.id, viewer.token);
      setUser(user);
      const plan = User.subscriptionPlan(user);
      setPlan(plan);
      setShowLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPayments = async () => {
    try {
      const payments = await User.fetchPayments(user.id, viewer.token);
      setPayments(payments);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [viewer]);

  useEffect(() => {
    if (user.id) {
      fetchPayments();
    }
  }, [user]);

  const validPayment = User.hasValidPayment(user);
  const dispatch = useDispatch();

  const saveToDatabase = async (card) => {
    dispatch(closeAdvancePaymentModal());
    setShowLoading(true);
    try {
      await Payments.savePaymentMethod(viewer.id, viewer.token, card);
      return fetchUser();
    } catch (error) {
      dispatch(closeAdvancePaymentModal());
      setShowLoading(false);
      console.log(error);
    }
  };

  const savePaymentMethod = () => {
    dispatch(
      showAdvancePaymentModal({
        onSavePayment: (card) => {
          saveToDatabase(card);
        },
        onClose: () => {
          closeAdvancePaymentModal();
        },
      })
    );
  };

  const editPaymentMethod = () => {
    dispatch(
      showAdvancePaymentModal({
        onSavePayment: (card) => {
          return saveToDatabase(card);
        },
        onClose: () => {
          return closeAdvancePaymentModal();
        },
      })
    );
  };

  const confirmDeletePayment = () => {
    dispatch(
      showConfirmationModal({
        cancelText: "Cancel",
        proceedText: "Remove Credit Card",
        onProceed: removeCreditCard,
        onCancel: () => {
          dispatch(closeConfirmationModal());
        },
        children: `Are you sure you want to remove your credit card?'`,
      })
    );
  };

  const removeCreditCard = async () => {
    let { id, token } = viewer;
    dispatch(closeConfirmationModal());
    setShowLoading(true);

    try {
      await Payments.deleteCreditCard(id, token);
      fetchUser();
    } catch (error) {
      setShowLoading(false);
      console.log(error);
    }
  };

  if (empty(user) || showLoading)
    return (
      <div className="prof-body">
        <Preloader />
      </div>
    );

  let { billingInfo } = user;

  return (
    <div>
      {billingInfo?.paymentError && (
        <BillingInfoError>{billingInfo.paymentError}</BillingInfoError>
      )}

      <div className="prof-body">
        <h2>Billing Information</h2>

        <div className="prof-billing-wrap">
          <div className="pb-ittem">
            <h4>Payment Method</h4>
            <div className="pb-ittem-inner">
              <div className="content">
                {validPayment && <span> {user.paymentMethod.maskedPan}</span>}
                {!validPayment && <span> none</span>}
              </div>

              <div className="actions">
                {!validPayment && <a onClick={savePaymentMethod}>Add</a>}
                {validPayment && (
                  <div>
                    <a onClick={editPaymentMethod}>Edit</a>
                    <a onClick={confirmDeletePayment}>Delete</a>
                  </div>
                )}
              </div>
            </div>
          </div>

          {user && user.company && user.company.emergencyAccess && (
            <div className="pb-ittem">
              <h4>Subscription</h4>
              <div className="pb-ittem-inner">
                <div className="content">
                  <span>You have an unlimited business access.</span>
                </div>
                <div className="actions"></div>
              </div>
            </div>
          )}

          {User.hasValidSubscription(user) &&
            (User.hasOneOffValidSubscription(user) ||
              User.hasBusinessTrialValidSubscription(user)) && (
              <div className="pb-ittem">
                <h4>Subscription</h4>
                <div className="pb-ittem-inner">
                  <div className="content">
                    {User.hasOneOffValidSubscription(user) ? (
                      <span>{`Emergency access until ${moment(
                        user.oneOffUntil
                      ).format("DD MMM YYYY hh:mm A")}.`}</span>
                    ) : (
                      <span>{`${
                        user.permissions.subscriptionPlanLabel
                      } until ${moment(user.businessTrialUntil).format(
                        "DD MMM YYYY hh:mm A"
                      )}.`}</span>
                    )}
                  </div>
                  <div className="actions"></div>
                </div>
              </div>
            )}

          <div className="pb-ittem">
            <h4>Billing History</h4>
            <div className="pb-ittem-inner">
              <table className="pb-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Total</th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {succededPayments.map((payment) => {
                    return (
                      <tr key={`tr_${payment.id}`}>
                        <td>
                          {moment(payment.createdAt).format("DD MMM YYYY")}
                        </td>
                        <td>{payment.description}</td>
                        <td>{`A$${payment.amount / 100}`}</td>

                        <td>
                          {payment.invoice && payment.invoice.url && (
                            <a
                              href={payment.invoice.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <PoweredByBrand />
        </div>
      </div>
    </div>
  );
};

export default BillingInformation;
