/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

import React from "react";
import moment from "moment-timezone";
import api from "@api";
import { SHOW_LOGIN_MODAL, SHOW_EMERGENCY_BOT } from "@modules/gui";
import { connect } from "react-redux";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { Routes } from "@constants/";
import { Drugs, Email, Session, Bot } from "@helpers/";
import empty from "is-empty";
import Autocomplete from "react-autocomplete";
import botSound from "@assets/sounds/beep2.wav";
import { history } from "@modules";

const autoCompleteComponentForm = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  country: "long_name",
  postal_code: "short_name",
};
const autoCompleteDoctoAddress = {
  street_number: "address",
  route: "address",
  locality: "address2",
  administrative_area_level_1: "region",
  country: "country",
  postal_code: "postcode",
};
class AutoCompleteAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {
        address2: null,
        address: null,
        region: null,
        country: null,
        postcode: null,
      },
    };
  }
  componentWillUnmount() {
    this.setState({
      address: {
        address2: null,
        address: null,
        region: null,
        country: null,
        postcode: null,
      },
    });
    this.autocomplete = null;
  }
  componentDidMount() {
    this.initAutocomplete();
  }
  initAutocomplete() {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      this.refs.autocomplete,
      { types: ["geocode"] }
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(["address_component"]);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autocomplete.addListener("place_changed", () => this.fillInAddress());
  }

  fillInAddress() {
    try {
      let place = this.autocomplete.getPlace();
      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      let res = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (autoCompleteComponentForm[addressType]) {
          let key = autoCompleteDoctoAddress[addressType];
          let val =
            place.address_components[i][autoCompleteComponentForm[addressType]];
          res[key] = res[key] ? `${res[key]} ${val}` : val;
        }
      }
      this.setState({ address: res });
    } catch (error) {
      this.setState({ address: {} });
    }
  }
  setAddress() {
    if (empty(this.refs.autocomplete.value)) {
      return;
    }
    let address = this.state.address;
    address.inputText = this.refs.autocomplete.value;
    if (!address.country) {
      address.address = address.inputText;
    }
    this.props.onAddressSelect(address);
  }

  // Bias the autocomplete object to the user's geographical location,
  // as supplied by the browser's 'navigator.geolocation' object.
  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // eslint-disable-next-line no-undef
        let circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        this.autocomplete.setBounds(circle.getBounds());
      });
    }
  }
  render() {
    return (
      <div className="bot-send-form stick-bottom">
        <input
          ref="autocomplete"
          onFocus={() => this.geolocate()}
          placeholder="Enter your address"
          type="text"
        />
        <button onClick={() => this.setAddress()}>Send</button>
      </div>
    );
  }
}

class EmergencyBot extends React.Component {
  constructor(props) {
    super(props);
    console.log({ props });
    this.botContext = {};
    this.state = {
      pharmacySearchResults: [],
      botInputValue: "",
      dropDownMenuShow: false,
      referralFiles: [],
      paymentError: null,
      prescriptionDrugs: [],
      messages: [],
      bookingParams: {},
    };
  }
  initMessage(message) {
    let messages = [];
    messages.push(message);
    this.setState({ messages });
  }
  setContext(context) {
    this.botContext = context;
  }
  addMessage(message) {
    let { messages } = this.state;
    messages.push(message);
    this.setState({ messages }, () => {
      this.scrollDown();
    });
  }
  sendReport() {
    let { email, name, mailSend, phonenumber } = this.botContext;
    if (email || phonenumber) {
      api
        .post(`${Routes.sendBotRequest()}`, {
          name: name,
          email: email,
          phone: phonenumber,
          messages: this.state.messages,
        })
        .then((response2) => console.log(response2))
        .catch((error) => console.error(error));
    }
  }

  showNearbyPharmacy(user, params, data) {
    console.log("data showNearbyPharmacy", data);

    var error = (err) => {
      console.error(`ERROR`, err);
      var newContext = data.context;
      api
        .post(`${Routes.botMessage}`, {
          message: "Unknown error",
          context: newContext,
        })
        .then((responseBot) => {
          var messageToAdd = {
            text: responseBot.output.text[0],
            sender: "bot",
            data: responseBot,
          };
          this.addMessage(messageToAdd);
          this.setContext(responseBot.context);
        });
    };

    var success = (pos) => {
      var crd = pos.coords;

      console.log("Your current position is:");
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);
      var lat = crd.latitude;
      var long = crd.longitude;
      //long = "145.49267578125";
      //lat = "-36.62018464353607";
      api
        .post(Routes.getNearbyPharmacy(), { lat: lat, long: long })
        .then((response) => {
          var options = response.map((op) => {
            return op.label;
          });
          options.push("other");
          var allOptions = [].concat(options);
          console.log(allOptions);
          data.output.quickReplies = {
            hideUserInput: "yes",
            quickRepliesButtons: allOptions,
            quickReplyTitle: "select pharmacy",
            quickReplyType: "dropdownList",
          };
          data.output.hideUserInput = "yes";
          var messageToAdd = {
            text: data.output.text[0],
            sender: "bot",
            data: data,
          };
          this.addMessage(messageToAdd);
          this.setContext(data.context);
        });
    };

    var messageToAdd = {
      text: "checking your location...",
      sender: "bot",
      data: { output: { hideUserInput: "yes" } },
    };
    this.addMessage(messageToAdd);
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    try {
      navigator.geolocation.getCurrentPosition(success, error, options);
    } catch (error) {
      console.error("error", error);
      error();
    }
  }
  // Responses expected by bot
  // - success
  // - email not found
  // - unknown error
  async sendLoginCredentials(params, data) {
    const newContext = data.context;
    let nextSearch = "";
    let path = "/";
    if (
      ["ConsultOnlineDoctor", "emergency-consult"].includes(
        newContext.userIntention
      )
    ) {
      const consultation = {
        pharmacyName: newContext.prefered_pharmacie || newContext.pharmacyName,
        reason: newContext.HowWeCanHelp || newContext.user_pref_entryflow,
        drug:
          newContext.user_pref_drug_not_in_list || newContext.user_pref_drug,
      };
      const consultStr = encodeURIComponent(JSON.stringify(consultation));
      nextSearch = `? consultInfo = ${consultStr}`;
      path = "/consult-dr-now";
    }
    try {
      const response = await Session.sendMagicLink(params.email, {
        path,
        nextSearch,
        confirm: true,
      });
      let message = "success";
      if (!response.success) {
        switch (response.status) {
          case "notFound":
            message = "email not found";

            break;
          case "accountBlock":
            message = "email block";
            break;
          case "invalidEmail":
            message = "mail not right";
            break;

          default:
            message = "unknown error";
            break;
        }
      }
      this.askBot(message, false, null, false);
    } catch (error) {
      this.askBot("unknown error", false, null, false);
    }
  }

  showFindPharmacy(user, params, data) {
    console.log("data showfindPharmacy", data);
    var messageToAdd = {
      text: "checking your location...",
      sender: "bot",
      data: { output: { hideUserInput: "yes" } },
    };
    this.addMessage(messageToAdd);

    api
      .post(Routes.findPharmacy(), { search: params.pharmacyName })
      .then((response) => {
        var options = response.map((op) => {
          return op.label;
        });
        if (options.length <= 0) {
          this.askBot("Name not found", false, null, false);
          return;
        }

        data.output.quickReplies = {
          hideUserInput: "yes",
          quickRepliesButtons: options,
          quickReplyTitle: "select pharmacy",
          quickReplyType: "dropdownList",
        };
        var messageToAdd = {
          text: data.output.text[0],
          sender: "bot",
          data: data,
        };
        this.addMessage(messageToAdd);
        this.setContext(data.context);
      })
      .catch((err) => {
        console.warn(`ERROR`, err);
        var message = "Unknown error";
        var newContext = data.context;
        this.askBot(message, false, null, false);
      });
  }

  async createUserFromBot(payload, emailData = {}) {
    try {
      const patient = await Session.createPatient(payload);
      await Session.sendConfirmationSignUp(patient.email, emailData);
      this.askBot("user created successfully", false, this.props.user, false);
    } catch (error) {
      console.log(error);
    }
  }

  pushMessage(message = null, withSound = false) {
    let ding = new Audio(botSound);
    var messageToAdd = { text: message, sender: "bot", data: null };
    this.addMessage(messageToAdd);
    if (withSound) {
      ding.play();
    }
  }

  initBot(message, user = {}) {
    user = user ? user : {};
    let { context } = this;
    document.getElementById("landing").scrollTop = 0;

    if (!message) {
      message = user && user.id ? "welcome_isLogged" : "welcome";
    }
    if (context && user.id) {
      context = { ...context, ...Bot.botUser(user) };
    }
    switch (message) {
      case "welcome_sanitarium":
      case "welcome_expat":
        var message = { message: "", context: context };
        console.log("INIT BOT welcome_expat", message);
        context.workspaceId = "5352d120-d1bd-452d-8d48-80d8117eea13";
        api
          .post(`${Routes.botMessage}`, { context: context })
          .then((response) => {
            console.log("INIT BOT RESPONSE welcome_expat", response);
            this.setContext(response.context);
            var messageToAdd = {
              text: response.output.text[0],
              sender: "bot",
              data: response,
            };
            this.initMessage(messageToAdd);
          });
        return;
      case "welcome_isBusinness":
        context.userIntention = "Business";
        // statements_1
        break;
      case "welcome_isConsultOnlineDoctor":
        message = user && user.id ? "welcome_isLogged" : message;
        break;
      default:
        break;
    }
    console.log("INIT BOT", { message: message, context: context });
    api
      .post(`${Routes.botMessage}`, { message: message, context: context })
      .then((response) => {
        console.log("INIT BOT RESPONSE", response);
        this.setContext(response.context);
        var messageToAdd = {
          text: response.output.text[0],
          sender: "bot",
          data: response,
        };
        this.initMessage(messageToAdd);
      });
  }

  askBot(message = null, withSound = false, user, showMessage = true) {
    console.log(showMessage);
    let ding = new Audio(botSound);

    if (withSound) {
      ding.play();
    }

    console.log("USER SEND", { message: message, context: this.botContext });
    if (message && showMessage) {
      this.addMessage({ text: message, sender: "user" });
    }

    api
      .post(`${Routes.botMessage}`, {
        message: message,
        context: this.botContext,
      })
      .then((response) => {
        console.log("BOT RESPONSE", response);
        this.setContext(response.context);
        let context = this.botContext;
        const actions = response.output.actions || response.actions;

        let notPushMessage = false;
        let mailData = {};
        if (actions) {
          actions.forEach((action, index, arr) => {
            switch (action.name) {
              case "signUpAction":
                var params = action.parameters;
                let consultation = null;
                if (context.userIntention == "emergency-consult") {
                  consultation = {
                    pharmacyName:
                      context.prefered_pharmacie || context.pharmacyName,
                    reason: context.HowWeCanHelp || context.user_pref_entryflow,
                    drug:
                      context.user_pref_drug_not_in_list ||
                      context.user_pref_drug,
                  };
                  const consultStr = encodeURIComponent(
                    JSON.stringify(consultation)
                  );
                  mailData = {
                    nextPathname: `/consult-dr-now`,
                    nextSearch: `?consultInfo=${consultStr}`,
                  };
                }
                // eslint-disable-next-line no-case-declarations
                let userPayload = {
                  firstConsultInfo: consultation,
                  firstName: params.name || context.maybename,
                  lastName: params.lastname,
                  email: params.email,
                  countryCode: params.countryCode,
                  phoneNumber: params.phone,
                  medicareNumber: params.MedicareNumber
                    ? `${params.MedicareNumber} / ${params.IRN}`
                    : null,
                  birthday: moment(context.DOB, "DD/MM/YYYY", true).toDate(),
                  preferredPharmacy: context.pharmacyName || "",
                };
                if (context.address) {
                  userPayload.address = context.address.address;
                  userPayload.address2 = context.address.address2;
                  userPayload.region = context.address.region;
                  userPayload.country = context.address.country;
                  userPayload.postcode = context.address.postcode;
                }
                this.createUserFromBot(userPayload, mailData);
                break;
              case "resendLink":
                const consultation2 = {
                  pharmacyName:
                    context.prefered_pharmacie || context.pharmacyName,
                  reason: context.HowWeCanHelp || context.user_pref_entryflow,
                  drug:
                    context.user_pref_drug_not_in_list ||
                    context.user_pref_drug,
                };
                const consultStr = encodeURIComponent(
                  JSON.stringify(consultation2)
                );
                mailData = {
                  nextPathname: `/consult-dr-now`,
                  nextSearch: `?consultInfo=${consultStr}`,
                };
                Session.sendConfirmationSignUp(context.email, mailData);
                break;
              case "sendNotificationBusinessStandAlone":
              case "sendNotificationBusinessQR":
              case "ContactMe":
                var payload = action;
                console.log(payload);
                payload.parameters.timezone = moment.tz.guess();
                Email.sendBotActionEmail(payload);

                break;
              case "show_nearby_pharmacy":
                var params = action.parameters;
                console.log("show_nearby_pharmacy", params);
                this.showNearbyPharmacy(user, params, response);
                notPushMessage = true;
                return;
              case "searchForPharmacy":
                var params = action.parameters;
                console.log("searchForPharmacy", params);
                this.showFindPharmacy(user, params, response);
                notPushMessage = true;
                return;
              case "sendLoginCredentials":
                var params = action.parameters;
                console.log("sendLoginCredentials", params);
                this.sendLoginCredentials(params, response);

                break;
              default:
                console.log("Action trigger with no handle", action);
            }
          });
        }

        if (notPushMessage) {
          return;
        }
        var messageToAdd = {
          text: response.output.text[0],
          sender: "bot",
          data: response,
        };
        this.addMessage(messageToAdd);
        if (withSound) {
          ding.play();
        }
      });
  }

  closeBot() {
    this.sendReport();
    this.props.closeEmergencyBot();
  }
  loginClick() {
    this.closeBot();
    this.props.showLoginModal();
  }
  componentDidMount() {
    this.initBot(this.props.initMessage, this.props.user);
  }
  sendAddres(address) {
    let context = this.botContext;
    context.address = address;
    this.askBot(address.inputText, true, this.props.user, true);
  }

  askBotInterface(fixMessage, showMessage = true) {
    console.log("askBot", { fixMessage, context: this.botContext });
    const inputMessage = this.state.botInputValue;
    var message = fixMessage ? fixMessage : inputMessage.trim();

    if (message == "") {
      return;
    }
    this.askBot(message, true, this.props.user, showMessage);
    this.setState({ botInputValue: "" });
  }
  selectDropdownMenu(fixMessage, showMessage = true) {
    this.setState({ dropDownMenuShow: false });
    this.askBotInterface(fixMessage, showMessage);
  }
  onClickDropDownMenu() {
    this.setState({ dropDownMenuShow: !this.state.dropDownMenuShow }, () => {
      let container = this.refs.scrollChatBot;
      setTimeout(() => {
        container.scrollTop = container.scrollHeight + 300;
      }, 300);
    });
  }
  keyEnter(ev, showMessage, message = null) {
    if (ev.key === "Enter") {
      this.askBotInterface(message, showMessage);
    }
  }

  scrollDown() {
    if (this.refs.scrollChatBot) {
      let container = this.refs.scrollChatBot;
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 100);
    }
  }

  botAction(parameters) {
    var actionName = parameters.botaction;

    if (actionName === "consult-dr") {
      var email = parameters.email;
      this.closeBot();
      //flux.actions.chat.requestChatAccess();
    }
  }

  htmlParserTransform(node, index) {
    var params = node.attribs;
    if (node.type == "tag" && node.name == "a" && params) {
      return (
        <a onClick={() => this.botAction(params)}>
          {convertNodeToElement(
            node.children[0],
            index,
            this.htmlParserTransform.bind(this)
          )}
        </a>
      );
    }
  }

  renderBotActions(message) {
    if (!message.data || !message.data.output.type) {
      return ReactHtmlParser(message.text);
    }
    this.messageActions = message.data.actions;
    var actionType = message.data.output.type;

    switch (actionType) {
      case "linkAction":
        return ReactHtmlParser(message.text, {
          transform: this.htmlParserTransform.bind(this),
        });
      case "signUpAction":
      default:
        return ReactHtmlParser(message.text);
    }
  }

  goToBrowseHospital() {
    this.closeBot();
    history.push("/browse-hospital");
  }
  requestEmergencyChat() {
    console.log("request emergencyChat");
    const context = this.botContext;
    const consultation = {
      pharmacyName: context.prefered_pharmacie || context.pharmacyName,
      reason: context.HowWeCanHelp || context.user_pref_entryflow,
      drug: context.user_pref_drug_not_in_list || context.user_pref_drug,
    };
    const consultInfoStringify = JSON.stringify(consultation);
    history.push(`/consult-dr-now?consultInfo=${consultInfoStringify}`);
    this.closeBot();
  }
  renderActionButtons(ActionButton, index) {
    switch (ActionButton.action) {
      case "goToBrowseHospital":
        return (
          <a
            className=""
            key={`quickreply = ${index}`}
            onClick={() => this.goToBrowseHospital()}
          >
            {ActionButton.text}
          </a>
        );
      case "requestEmergencyCall":
        return (
          <a
            className=""
            key={`quickreply = ${index}`}
            onClick={() => this.requestEmergencyChat()}
          >
            {ActionButton.text}
          </a>
        );
      case "onClick":
        return (
          <a
            className=""
            key={`quickreply = ${index}`}
            onClick={() => ActionButton.onClick()}
          >
            {ActionButton.text}
          </a>
        );
      case "respondInBot":
      default:
        return (
          <a
            className=""
            key={`quickreply = ${index}`}
            onClick={() => this.askBotInterface(ActionButton.text)}
          >
            {ActionButton.text}
          </a>
        );
    }
  }

  renderQuickReplies(quickReplies) {
    var type = quickReplies.quickReplyType;
    switch (type) {
      case "dropdownList":
        return (
          <div className="bc-bubble in">
            <div className="dropdown dropdown-bot">
              <button
                onClick={() => this.onClickDropDownMenu()}
                aria-expanded="false"
                aria-haspopup="true"
                className="dropdown-toggle"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
              >
                {quickReplies.quickReplyTitle}
              </button>
              <div
                aria-labelledby="dropdownMenuButton"
                className={
                  this.state.dropDownMenuShow
                    ? "dropdown-menu show"
                    : "dropdown-menu hide"
                }
              >
                {quickReplies.quickRepliesButtons.map(
                  (quickReplyButton, index) => (
                    <a
                      className="dropdown-item"
                      key={`quickreply = ${index}`}
                      href="#"
                      onClick={() => this.selectDropdownMenu(quickReplyButton)}
                    >
                      {ReactHtmlParser(quickReplyButton)}
                    </a>
                  )
                )}
              </div>
            </div>
          </div>
        );

      case "textButtons":
        return (
          <div className="bc-bubble-sudgestions">
            <span>{quickReplies.quickReplyTitle}</span>
            {quickReplies.quickRepliesButtons.map((quickReplyButton, index) => (
              <a
                className={
                  quickReplyButton === "Consult Dr. now" ? "a-red" : ""
                }
                key={`quickreply = ${index}`}
                href="#"
                onClick={() => this.askBotInterface(quickReplyButton)}
              >
                {ReactHtmlParser(quickReplyButton)}
              </a>
            ))}
          </div>
        );

      case "action-buttons":
        return (
          <div className="bc-bubble-sudgestions">
            <span>{quickReplies.quickReplyTitle}</span>
            {quickReplies.quickRepliesButtons.map((quickReplyButton, index) =>
              this.renderActionButtons(quickReplyButton, index)
            )}
          </div>
        );
      default:
        return null;
    }
  }

  renderMessages() {
    let { messages } = this.state;
    if (messages.length == 0) {
      return null;
    }
    var lastMessage = messages[messages.length - 1];
    var quickReplies =
      lastMessage &&
      lastMessage.data &&
      lastMessage.data.output &&
      lastMessage.data.output.quickReplies
        ? lastMessage.data.output.quickReplies
        : null;

    return messages.length === 1 ? (
      <div className="bot-chat-initial">
        <h4>
          <i className="icon-chat-plus-lined"></i>
          {ReactHtmlParser(messages[0].text)}
        </h4>
        {quickReplies ? this.renderQuickReplies(quickReplies) : null}
      </div>
    ) : (
      <div className="bot-chat">
        <div className="bot-chat-scroll" ref="scrollChatBot">
          {messages.map((message, index) => (
            <div
              key={`bot - ${index}`}
              className={
                message.sender === "bot" ? "bc-bubble in" : "bc-bubble out"
              }
            >
              <i className="icon-"></i>
              <div className="bc-bubble-text">
                {message.sender === "bot"
                  ? this.renderBotActions(message)
                  : message.text}
              </div>
            </div>
          ))}

          {quickReplies ? this.renderQuickReplies(quickReplies) : null}
        </div>
      </div>
    );
  }

  onChangeAutoCompleteDrug(e) {
    const value = e.target.value;
    if (value.length < 4) {
      this.setState({ prescriptionDrugs: [], botInputValue: value });
      return;
    }
    const drugs = Drugs.prescriptionDrugs;
    const prescriptionDrugs = drugs.filter((d) => {
      return d.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });
    this.setState({
      prescriptionDrugs: prescriptionDrugs,
      botInputValue: value,
    });
  }
  onChangeAutoCompletePharmacyFields(e) {
    const value = e.target.value;
    this.setState({ botInputValue: value });

    clearTimeout(this.pharmaSearchTimeOutId);
    if (value.length < 3) {
      this.setState({ pharmacySearchResults: [] });
      return;
    }
    if (this.state.pharmacySearchResults.length == 0) {
      this.setState({
        pharmacySearchResults: [{ name: "loading ...", enable: false }],
      });
    }

    this.pharmaSearchTimeOutId = setTimeout(() => {
      findPharmacy(value);
    }, 800);

    const findPharmacy = (value) => {
      api.post(Routes.findPharmacy(), { search: value }).then((response) => {
        const pharmacyResults = response.map((op) => {
          return { name: op.label, enable: true };
        });
        this.setState({ pharmacySearchResults: pharmacyResults });
      });
    };
  }
  renderBotInput(type) {
    switch (type) {
      case "autocompleteAddress":
        console.log("entra");
        return (
          <AutoCompleteAddress
            onAddressSelect={(address) => this.sendAddres(address)}
          />
        );
      case "password field":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-send-form"
                : "bot-send-form stick-bottom"
            }
          >
            <input
              type="password"
              value={this.state.botInputValue}
              onChange={(e) => this.setState({ botInputValue: e.target.value })}
              onKeyPress={(ev) => this.keyEnter(ev, false)}
              placeholder="type here"
            />
            <button onClick={() => this.askBotInterface(null, false)}>
              Send
            </button>
          </div>
        );
      case "none":
        return null;
      case "autocomplete-drugs-field":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-drop-wrap"
                : "bot-drop-wrap stick-bottom"
            }
          >
            <Autocomplete
              items={this.state.prescriptionDrugs}
              getItemValue={(item) => item.name}
              renderItem={(item, isHighlighted) => (
                <div className={isHighlighted ? "selected" : ""}>
                  {item.name}
                </div>
              )}
              renderMenu={(items, value, style) => {
                return <div className="bot-drop-menu" children={items} />;
              }}
              renderInput={(props) => (
                <input
                  onKeyPress={(ev) => this.keyEnter(ev)}
                  className="bot-drop-toggle"
                  {...props}
                />
              )}
              value={this.state.botInputValue}
              onChange={(e) => this.onChangeAutoCompleteDrug(e)}
              onSelect={(val) => this.setState({ botInputValue: val })}
            />
            <button
              className="bot-drop-send"
              onClick={() => this.askBotInterface()}
            >
              Send
            </button>
          </div>
        );

      case "autocomplete-pharma-field":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-drop-wrap"
                : "bot-drop-wrap stick-bottom"
            }
          >
            <Autocomplete
              isItemSelectable={(item) => {
                return item.enable;
              }}
              items={this.state.pharmacySearchResults}
              shouldItemRender={(item, value) =>
                item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                !item.enable
              }
              getItemValue={(item) => item.name}
              renderItem={(item, isHighlighted) => (
                <div className={isHighlighted ? "selected" : ""}>
                  {item.name}
                </div>
              )}
              renderMenu={(items, value, style) => {
                return <div className="bot-drop-menu" children={items} />;
              }}
              renderInput={(props) => (
                <input
                  onKeyPress={(ev) => this.keyEnter(ev)}
                  className="bot-drop-toggle"
                  {...props}
                />
              )}
              value={this.state.botInputValue}
              onChange={(e) => this.onChangeAutoCompletePharmacyFields(e)}
              onSelect={(val) => this.setState({ botInputValue: val })}
            />
            <button
              className="bot-drop-send"
              onClick={() => this.askBotInterface()}
            >
              Send
            </button>
          </div>
        );

      default:
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-send-form"
                : "bot-send-form stick-bottom"
            }
          >
            <input
              type="text"
              autoComplete="nope"
              value={this.state.botInputValue}
              onChange={(e) => this.setState({ botInputValue: e.target.value })}
              onKeyPress={(ev) => this.keyEnter(ev)}
              placeholder="type here"
            />
            <button onClick={() => this.askBotInterface()}>Send</button>
          </div>
        );
    }
  }
  render() {
    console.log("render", this.botContext);
    console.log("render22", this.botContext2);
    console.log("pedo", this.pedo);
    let { messages } = this.state;
    var lastMessage = messages[messages.length - 1];
    var hideUserInput =
      lastMessage &&
      lastMessage.data &&
      lastMessage.data.output &&
      ((lastMessage.data.output.quickReplies &&
        lastMessage.data.output.quickReplies.hideUserInput === "yes") ||
        lastMessage.data.output.type === "signUpAction" ||
        lastMessage.data.output.hideUserInput === "yes")
        ? true
        : false;
    hideUserInput =
      lastMessage && lastMessage.text == "Uploading files..."
        ? true
        : hideUserInput;
    let inputBotType =
      lastMessage && lastMessage.data && lastMessage.data.output.type
        ? lastMessage.data.output.type
        : "input";
    inputBotType = hideUserInput ? "none" : inputBotType;

    if (this.state.messages.length === 0) {
      return (
        <div
          className={
            this.props.fullScreen
              ? "lndng-section-blue"
              : "lndng-hero bot-inner-wrap"
          }
        >
          <div className={this.props.fullScreen ? "bot-overlay" : "bot-inline"}>
            <h1 className="bot-header">Eva, Virtual Assistant</h1>
            {!this.props.user.id ? (
              <div className="bot-login-btn">
                <a onClick={() => this.loginClick()} href="#" className="btn">
                  Login{" "}
                </a>
              </div>
            ) : null}

            <div onClick={() => this.closeBot()} className="bot-close">
              <i className="icon-cross2"></i>
            </div>
            <div className="bot-container">
              <div className="bot-chat-initial">
                <h4>
                  <i className="icon-chat-plus-lined"></i>
                  One sec ...
                </h4>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        className={
          this.props.fullScreen
            ? "lndng-section-blue"
            : "lndng-hero bot-inner-wrap"
        }
      >
        <div className={this.props.fullScreen ? "bot-overlay" : "bot-inline"}>
          <h1 className="bot-header">Eva, Virtual Assistant</h1>
          {!this.props.user.id ? (
            <div className="bot-login-btn">
              <a onClick={() => this.loginClick()} href="#" className="btn">
                Login{" "}
              </a>
            </div>
          ) : null}

          <div onClick={() => this.closeBot()} className="bot-close">
            <i className="icon-cross2"></i>
          </div>
          <div className="bot-container">
            {/*RENDER*/ this.renderMessages()}

            {this.renderBotInput(inputBotType)}
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showLoginModal: () =>
      dispatch({
        type: SHOW_LOGIN_MODAL,
        show: true,
      }),
    closeEmergencyBot: (show, initMessage) =>
      dispatch({
        type: SHOW_EMERGENCY_BOT,
        show: false,
      }),
  };
};
const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(EmergencyBot);
