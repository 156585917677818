import api from "@api";
import { Routes } from "@constants/";

const Session = class Session {
  static async sendMagicLink(email, options = {}) {
    const defaultOptions = {
      path: null,
      nextSearch: null,
      nextFullRedirect: null,
      confirm: false,
    };
    options = { ...defaultOptions, ...options };
    return await api.post(Routes.sendMagicLink(email), options);
  }
  static async login(email, password) {
    return await api.post(Routes.login, { email, password });
  }

  static async createPatient(user) {
    return await api.post(Routes.createPatient, user);
  }
  static async sendConfirmationSignUp(email, data) {
    return await api.post(`${Routes.sendConfirmationSignUp(email)}`, data);
  }
  static async logout() {
    return await api.post(Routes.logout);
  }
  static async loginWithPinCode(email, pinCode) {
    return await api.post(Routes.loginWithPinCode(), { email, pinCode });
  }
};

export default Session;
