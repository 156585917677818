import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Specialties, Toast } from "@helpers";
import SpecialtyForm from "./form";

function NewSpecialty() {
  const { user } = useSelector((state) => state.session);
  const history = useHistory();
  const onSubmit = (values) => {
    try {
      if (user && user.token) {
        Specialties.createSpecialty(user.token, values).then((response) => {
          Toast.displayToast({
            kind: "success",
            message: "Specialty has been created",
          });
          history.push(`/admin/specialties/edit/${response.id}`);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>Create Specialty</h1>
        <div className="header-lined"></div>
        <SpecialtyForm onSubmit={onSubmit} />
      </div>
    </div>
  );
}

export default NewSpecialty;
