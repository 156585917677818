import React from "react";

class ConfirmationModal extends React.Component {
  closeAlert() {
    this.props.close();
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  cancel() {
    if (this.props.cancel) {
      this.props.cancel();
    }
    this.closeAlert();
  }

  proceed() {
    if (this.props.proceed) {
      this.props.proceed();
    }
    this.closeAlert();
  }

  render() {
    let { children, proceedText, cancelText, onClose, open } = this.props;
    children = children ? children : "Are you sure you want to proceed";
    proceedText = proceedText ? proceedText : "Proceed";
    cancelText = cancelText ? cancelText : "Cancel";
    if (!open) {
      return <div></div>;
    }
    return (
      <div className="modal-window">
        <div className="md-align">
          <div className="modal chat-alert-modal">
            <div className="md-header">
              {onClose ? (
                <a href="#" className="md-close" onClick={() => this.close()}>
                  <i className="icon-cross-circle"></i>
                </a>
              ) : null}
            </div>

            <div className="md-body">
              <i className="icon-warning3"></i>
              <p>{children}</p>
            </div>

            <div className="md-footer">
              {this.props.proceed ? (
                <a
                  href="#"
                  className="btn-red btn-large"
                  onClick={() => this.proceed()}
                >
                  {proceedText}
                </a>
              ) : null}
              {this.props.cancel ? (
                <a
                  href="#"
                  className="btn-grey btn-large"
                  onClick={() => this.cancel()}
                >
                  {cancelText}
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConfirmationModal;
