import React, { useState } from "react";
import { showCallTestModal } from "@modules/gui";
import { useDispatch } from "react-redux";
import CallSettings from "@components/call_settings";

const CallTestModal = () => {
  const [settingOptions, setSettingOptions] = useState({});
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(showCallTestModal(false));
  };

  return (
    <div className="modal-window md-call-setup">
      <div className="modal">
        <div className="md-header">
          <a className="md-close" onClick={() => closeModal()} href="#">
            <i className="icon-cross-circle"></i>
          </a>
          <span>YOUR CAMERA AND MICROPHONE</span>
        </div>
        <div className="md-body ">
          <div className="jag-call-setup">
            <CallSettings
              joinNowData={{}}
              onChange={(options) => setSettingOptions(options)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallTestModal;
