import React from "react";
import { history } from "@modules";
import { connect } from "react-redux";
import { User, Appointments, Toast } from "helpers";
import moment from "moment";
import ConfirmationModal from "@src/components/modals/confirmation_modal";
import AppointmentLinkModal from "@src/components/modals/appointment_link";
import copy from "copy-to-clipboard";

import {
  SHOW_LOADING_MODAL,
  SHOW_UPSERT_APPOINTMENT_MODAL,
  SHOW_PATIENT_MEDICAL_RECORDS,
  SHOW_DOCTOR_NOTES,
  SHOW_UPSERT_REFERRAL_MODAL,
} from "@modules/gui";

class AppointmentRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      showDeleteConfirmation: false,
      showAppointmentLinkModal: false,
    };
    this.parentHasClass = this.parentHasClass.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentWillUnmount() {
    const c = document.removeEventListener(
      "mousedown",
      this.handleClick,
      false
    );
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  parentHasClass(element, classname) {
    if (
      element.className &&
      element.className.split(" ").indexOf(classname) >= 0
    )
      return true;
    return (
      element.parentNode && this.parentHasClass(element.parentNode, classname)
    );
  }

  handleClick(e) {
    const element = e.target;
    const { appointment } = this.props;
    const ellipsisClass = `ellipsis-${appointment.id}`;
    if (element.className.split(" ").indexOf(ellipsisClass) >= 0) {
      return;
    }
    if (element.className !== "options-inner-modal") {
      if (!this.parentHasClass(element, "options-inner-modal")) {
        this.setState({ showOptions: false });
      }
    }
  }

  startConsult(appointment) {
    history.push(
      `/room/${appointment.user.username}/appointment/${appointment.id}`
    );
  }

  goToMessages(appointmentId) {
    history.push(`/messages?appointmentId=${appointmentId}`);
  }

  copyLoguedUserLink(appointment) {
    this.setState({ showOptions: false });
    const link = `${window.location.origin}/room/${appointment.user.username}/appointment/${appointment.id}`;
    copy(link, {
      message: "Press #{key} to copy",
    });
    Toast.displayToast({
      kind: "success",
      message: "link copied to your clipboard",
    });
  }

  showAppointmentLinkModal() {
    this.setState({ showAppointmentLinkModal: true });
  }

  editAppointment(appointment) {
    console.log(appointment.id);
    this.props.showUpsertAppointmentModal({
      show: true,
      appointment: appointment,
      onComplete: this.props.updateAppointments,
    });
  }

  async cancelAppointment(appointmentId) {
    this.closeDeleteConfirmationModal();
    this.props.showLoadingModal(true);
    try {
      await Appointments.cancelAppointment(
        appointmentId,
        this.props.user.token
      );
      this.props.showLoadingModal(false);
      this.props.updateAppointments();
      Toast.displayToast({
        kind: "success",
        message: "Appointment has been canceled",
      });
    } catch (error) {
      console.error(error);
      this.closeDeleteConfirmationModal();
      this.props.showLoadingModal(false);
      Toast.displayToast({
        kind: "error",
        message: "Something went wrong",
      });
    }
  }

  showDeleteConfirmationModal() {
    this.setState({ showDeleteConfirmation: true });
  }
  closeDeleteConfirmationModal() {
    this.setState({ showDeleteConfirmation: false });
  }
  sendReferral(ap) {
    this.props.showUpsertReferralModal({ show: true, patient: ap.patient });
  }

  renderEmergencyActionOptions(appointment) {
    console.log(appointment);
    const { patient } = appointment;
    const { status } = patient;
    switch (status) {
      case "created":
      case "guest-access":
      case "active":
      default:
        return (
          <div className="options-inner-modal">
            <button
              key="got-to-messages"
              onClick={() => this.goToMessages(appointment.id)}
              className="inner-pill ip-green"
            >
              <i className="icon-chat-lined"></i> Chat
            </button>
            <button
              key="got-to-send-referral"
              onClick={() => this.sendReferral(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-users-plus"></i> Send Referral
            </button>
            <button
              key="files-option"
              onClick={() => this.goToAppointmentFiles(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-files"></i> Medical Records
            </button>
            <button
              key="notes-option"
              onClick={() => this.showNotes(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-document"></i> Notes
            </button>
          </div>
        );
    }
  }
  goToAppointmentFiles(appointment) {
    this.props.showPatientMedicalRecord({
      show: true,
      appointmentId: appointment.id,
    });
  }
  showNotes(appointment) {
    this.props.showAppointmentDoctorNotes({
      show: true,
      appointmentId: appointment.id,
    });
  }

  renderPastActionOptions(appointment) {
    const { patient } = appointment;
    const { status } = patient;
    switch (status) {
      case "created":
      case "active":
        return (
          <div key="inner-options" className="options-inner-modal">
            <button
              onClick={() => this.setState({ showDeleteConfirmation: true })}
              className="inner-pill ip-red"
            >
              <i className="icon-trash2"></i> Delete
            </button>
            <button
              onClick={() => this.startConsult(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-group-work"></i> Join Now
            </button>
            <button
              onClick={() => this.goToMessages(appointment.id)}
              className="inner-pill ip-green"
            >
              <i className="icon-chat-lined"></i> Chat
            </button>
            <button
              onClick={() => this.sendReferral(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-users-plus"></i> Send Referral
            </button>
            <button
              key="files-option"
              onClick={() => this.goToAppointmentFiles(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-files"></i> Medical Records
            </button>
            <button
              key="notes-option"
              onClick={() => this.showNotes(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-document"></i> Notes
            </button>
          </div>
        );
        break;

      case "guest-access":
      default:
        return (
          <div key="inner-options" className="options-inner-modal">
            <button
              onClick={() => this.setState({ showDeleteConfirmation: true })}
              className="inner-pill ip-red"
            >
              <i className="icon-trash2"></i> Delete
            </button>
            <button
              onClick={() => this.startConsult(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-group-work"></i> Join Now
            </button>
            <button
              onClick={() => this.goToMessages(appointment.id)}
              className="inner-pill ip-green"
            >
              <i className="icon-chat-lined"></i> Chat
            </button>
            <button
              key="files-option"
              onClick={() => this.goToAppointmentFiles(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-files"></i> Medical Records
            </button>
            <button
              key="notes-option"
              onClick={() => this.showNotes(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-document"></i> Notes
            </button>
          </div>
        );
    }
  }

  renderActionOptions(appointment) {
    const { patient } = appointment;
    const { status } = patient;
    switch (status) {
      case "created":
      case "active":
        return (
          <div key={`action_${status}`} className="options-inner-modal">
            <button
              onClick={() => this.setState({ showDeleteConfirmation: true })}
              className="inner-pill ip-red"
            >
              <i className="icon-trash2"></i> Delete
            </button>
            <button
              onClick={() => this.goToMessages(appointment.id)}
              className="inner-pill ip-green"
            >
              <i className="icon-chat-lined"></i> Chat
            </button>
            <button
              onClick={() => this.startConsult(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-group-work"></i> Join Now
            </button>

            {/* <button className="inner-pill ip-green">
              <i className="icon-document"></i> Notes
            </button> */}
            <button
              onClick={() => this.sendReferral(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-users-plus"></i> Send Referral
            </button>
            <button
              onClick={() => this.copyLoguedUserLink(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-copy"></i> Copy Link
            </button>
            <button
              key="files-option"
              onClick={() => this.goToAppointmentFiles(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-files"></i> Medical Records
            </button>
            <button
              key="notes-option"
              onClick={() => this.showNotes(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-document"></i> Notes
            </button>
          </div>
        );
      case "guest-access":
        return (
          <div
            className="options-inner-modal"
            key={`show-modal-${appointment.id}`}
          >
            <button
              key={`show-delete-${appointment.id}`}
              onClick={() => this.setState({ showDeleteConfirmation: true })}
              className="inner-pill ip-red"
            >
              <i className="icon-trash2"></i> Delete
            </button>
            <button
              key={`show-edit-${appointment.id}`}
              onClick={() => this.editAppointment(appointment)}
              className="inner-pill ip-blue"
            >
              <i className="icon-pencil5"></i> Edit Appointment
            </button>
            <button
              key={`show-messages-${appointment.id}`}
              onClick={() => this.goToMessages(appointment.id)}
              className="inner-pill ip-green"
            >
              <i className="icon-chat-lined"></i> Chat
            </button>
            <button
              key={`show-start-${appointment.id}`}
              onClick={() => this.startConsult(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-group-work"></i> Join Now
            </button>
            <button
              key={`show-link-${appointment.id}`}
              onClick={() => this.showAppointmentLinkModal()}
              className="inner-pill ip-green"
            >
              <i className="icon-copy"></i> Copy Link
            </button>
            <button
              key={`files-option-${appointment.id}`}
              onClick={() => this.goToAppointmentFiles(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-files"></i> Medical Records
            </button>
            <button
              key={`notes-option-${appointment.id}`}
              onClick={() => this.showNotes(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-document"></i> Notes
            </button>
          </div>
        );

      default:
        return (
          <div className="options-inner-modal">
            <button
              onClick={() => this.goToMessages(appointment.id)}
              className="inner-pill ip-green"
            >
              <i className="icon-chat-lined"></i> Chat
            </button>
            <button
              key="files-option"
              onClick={() => this.goToAppointmentFiles(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-files"></i> Medical Records
            </button>
            <button
              key="notes-option"
              onClick={() => this.showNotes(appointment)}
              className="inner-pill ip-green"
            >
              <i className="icon-document"></i> Notes
            </button>
          </div>
        );
        break;
    }
  }

  toggleOptions() {
    this.setState({ showOptions: !this.state.showOptions });
  }

  renderActions(type, ap) {
    switch (type) {
      case "upcoming":
        return [
          <div
            key="up-1"
            onClick={() => this.startConsult(ap)}
            className="ad-pill-status"
          >
            {this.props.patientWaiting ? (
              <span>PATIENT WAITING</span>
            ) : (
              <span>START CONSULT</span>
            )}
          </div>,

          <div
            key="up-5"
            className="ad-pill-options"
            onClick={() => this.toggleOptions()}
          >
            <i className={`ellipsis-${ap.id} icon-ellipsis i-close`}></i>
            <i className="icon-arrow-right i-open"></i>
          </div>,
          this.renderActionOptions(ap),
        ];
      case "past":
        return [
          <div
            key="up-5"
            className="ad-pill-options"
            onClick={() => this.toggleOptions()}
          >
            <i className={`ellipsis-${ap.id} icon-ellipsis i-close`}></i>
            <i className="icon-arrow-right i-open"></i>
          </div>,
          this.renderPastActionOptions(ap),
        ];
      case "emergency":
      default:
        return [
          <div
            key="up-5"
            className="ad-pill-options"
            onClick={() => this.toggleOptions()}
          >
            <i className={`ellipsis-${ap.id} icon-ellipsis i-close`}></i>
            <i className="icon-arrow-right i-open"></i>
          </div>,
          this.renderEmergencyActionOptions(ap),
        ];
    }
  }

  render() {
    const { user } = this.props;
    let showOptionsClass = this.state.showOptions ? "options-modal-active" : "";
    let typeClass =
      this.props.type == "upcoming" ? "status-success" : "status-past";
    let ap = this.props.appointment;
    return (
      <div
        key={`up-${ap.id}`}
        className={
          this.props.patientWaiting
            ? `ad-pill status-danger ${showOptionsClass}`
            : `ad-pill ${typeClass}  ${showOptionsClass}`
        }
      >
        {this.state.showAppointmentLinkModal ? (
          <AppointmentLinkModal
            appointmentId={ap.id}
            user={user}
            closeModal={() =>
              this.setState({ showAppointmentLinkModal: false })
            }
          />
        ) : null}
        {this.state.showDeleteConfirmation ? (
          <ConfirmationModal
            onProceed={() => this.cancelAppointment(ap.id)}
            onCancel={() => this.closeDeleteConfirmationModal()}
            onClose={() => this.closeDeleteConfirmationModal()}
            proceedText="Accept"
            cancelText="Cancel"
          >
            Are you sure you want to delete appointment ?
          </ConfirmationModal>
        ) : null}
        <div className="ref-modal ad-pill-info">
          <span>{User.fullName(ap.patient)}</span>
          <span>
            {moment(ap.scheduledOn).format("DD.MM.YYYY")}&nbsp;
            <strong>{moment(ap.scheduledOn).format("HH:mm")}</strong>
          </span>
        </div>
        {this.renderActions(this.props.type, ap)}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoadingModal: (show) =>
      dispatch({
        type: SHOW_LOADING_MODAL,
        show: show,
      }),
    showUpsertAppointmentModal: ({ show, appointment, onComplete }) =>
      dispatch({
        type: SHOW_UPSERT_APPOINTMENT_MODAL,
        params: { show, appointment, onComplete },
      }),
    showUpsertReferralModal: ({ show, patient }) =>
      dispatch({
        type: SHOW_UPSERT_REFERRAL_MODAL,
        params: { show, patient },
      }),
    showPatientMedicalRecord: (show, appointmentId) =>
      dispatch({
        type: SHOW_PATIENT_MEDICAL_RECORDS,
        params: { ...show, ...appointmentId },
      }),
    showAppointmentDoctorNotes: (show, appointmentId) =>
      dispatch({
        type: SHOW_DOCTOR_NOTES,
        params: { ...show, ...appointmentId },
      }),
  };
};
const mapStateToProps = ({ session, gui }) => ({
  user: session.user,
  gui,
});
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentRow);
