/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import AutoCompleteAddress from "@components/fields/auto_complete_address";
import empty from "is-empty";
import queryString from "query-string";
import { history } from "@modules/";
import { FormHelpers, User, Toast, Utils } from "@helpers/";
import { EmergencyContent } from "@constants/";
import MaskedInput from "react-maskedinput";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { showLoadingModal, showLoginModal } from "@modules/gui";
import {
  parseNumber,
  getCountryCallingCode,
  isValidNumber,
} from "libphonenumber-js";
import Services from "@helpers/services";
import validator from "au-bn-validator";

const REQUIRE_FIELDS = {
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Phone Number",
  fullAddress: "Address",
  birthday: "Birthday",
  email: "Email",
};
const ALL_FIELDS = {
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Phone Number",
  fullAddress: "Address",
  birthday: "Birthday",
  email: "Email",
  medicareNumber: "Medicare Number",
};
const PatientInformation = (props) => {
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({});
  const [content, setContent] = useState(EmergencyContent["default"]);
  const location = history.location;
  const {
    user,
    onAccept,
    onUserExists,
    isNursingRoom,
    contentTemplate,
  } = props;
  console.log({ contentTemplate, temp: EmergencyContent[contentTemplate] });
  const dispatch = useDispatch();
  const showLoadingModalThunk = showLoadingModal(true);
  const closeLoadingModalThunk = showLoadingModal(false);
  const showLoginModalThunk = showLoginModal(true);

  useEffect(() => {
    const query = Utils.useQuery(location);
    const {
      firstName,
      lastName,
      phoneNumber,
      address,
      birthday,
      email,
      fullAddress,
      medicareNumber,
    } = query;
    setFields({
      firstName,
      lastName,
      phoneNumber,
      address,
      fullAddress,
      birthday,
      email,
      medicareNumber,
    });
    if (contentTemplate) {
      setContent((content) => {
        return { ...content, ...EmergencyContent[contentTemplate] };
      });
    }
  }, []);

  const raiseInvalidBirthday = () => {
    let { birthday } = fields;
    let errors = {};
    const birthdate = moment(birthday, "DD/MM/YYYY");
    const tomorrow = moment().add(1, "day");
    if (!birthdate.isValid() || !birthdate.isBefore(tomorrow)) {
      errors["birthday"] = "check date of birth";
      setErrors(errors);
      throw new Error("invalid date of birth");
    }
  };
  const raiseInvalidMedicareNumber = () => {
    if (empty(fields?.medicareNumber)) {
      return;
    }
    const isValid = validator.validateMedicareNumber(fields.medicareNumber);
    let errors = {};
    if (!isValid) {
      errors["medicareNumber"] = "Invalid medicare number";
      setErrors(errors);
      throw new Error("invalid date of birth");
    }
  };
  const raiseEmptyFields = () => {
    let errors = {};
    Object.keys(REQUIRE_FIELDS).forEach((key) => {
      if (empty(fields[key])) {
        errors[key] = "Cannot be empty";
      }
    });
    if (!empty(errors)) {
      console.log(errors);
      setErrors(errors);
      throw new Error("Requiere fields are empty");
    }
  };
  const raiseInvalidEmail = () => {
    const errors = {};
    const { email } = fields;
    const isValid = FormHelpers.validateEmail(email);
    if (!isValid) {
      errors["email"] = "Invalid Email";
      setErrors(errors);
      throw new Error("Invalid Email");
    }
  };
  const raiseInvalidPhoneNumber = () => {
    const errors = {};
    const { phoneNumber } = fields;
    if (phoneNumber === undefined || !isValidNumber(phoneNumber)) {
      errors["phoneNumber"] = "Invalid Number";
      setErrors(errors);
      throw new Error("Invalid Number");
    }
  };
  const setFieldsAndChangeLocation = (newFields) => {
    newFields = { ...fields, ...newFields };
    setFields(newFields);
    let searchFields = _.pick(newFields, [
      "firstName",
      "lastName",
      "email",
      "birthday",
      "fullAddress",
      "phoneNumber",
      "medicareNumber",
    ]);

    const search = queryString.stringify({
      ...searchFields,
      ...{ address: searchFields.fullAddress },
    });
    history.replace(`${location.pathname}?${search}`);
  };

  const submitNewUser = async () => {
    try {
      raiseEmptyFields();
      raiseInvalidBirthday();
      raiseInvalidEmail();
      raiseInvalidMedicareNumber();
      raiseInvalidPhoneNumber();
      dispatch(showLoadingModalThunk);
      let response = {};
      try {
        response = await Services.validateDoctoEmail(fields.email);
      } catch (error) {
        anErrorOccur(error);
      }
      dispatch(closeLoadingModalThunk);
      const {
        valid,
        exists,
        phoneNumber,
        email,
        countryCode,
        errorMessage,
      } = response;
      if (!valid) {
        if (exists) {
          onUserExists({
            phoneNumber,
            countryCode,
            email,
            exists: true,
          });
          return;
        }
        errors["email"] = errorMessage;
        setErrors(errors);
        return;
      }

      let patien = { ...fields };
      patien.fullPhoneNumber = fields.phoneNumber;
      const parsePhoneNumber = parseNumber(fields.phoneNumber);
      patien.countryCode = getCountryCallingCode(parsePhoneNumber.country);
      patien.phoneNumber = parsePhoneNumber.phone;
      patien.birthday = moment(fields.birthday, "DD/MM/YYYY");

      if (fields.countryCode == 61) {
        patien.phoneNumber = fields.phoneNumber.substr(
          fields.phoneNumber.length - 9
        );
      }
      onAccept(patien);
    } catch (error) {
      console.error(error);
    }
  };

  const anErrorOccur = (e) => {
    dispatch(closeLoadingModalThunk);
    const paymentError = {
      message:
        "An error occurr please try again. If the problem persist please contact admin@docto.com",
      kind: "error",
    };
    Toast.displayToast(paymentError);
    console.error(e);
  };

  const renderErrors = () => {
    if (!empty(errors)) {
      return (
        <div className="error-alert">
          <p>Please check the following errors.</p>
          <ul>
            {Object.keys(errors).map((key) => {
              return (
                <li
                  key={`li-err-${key}`}
                >{`${ALL_FIELDS[key]}: ${errors[key]}`}</li>
              );
            })}
          </ul>
        </div>
      );
    }
  };

  const roleName = User.getRoleName(user);
  const {
    firstName,
    lastName,
    email,
    fullAddress,
    birthday,
    phoneNumber,
    medicareNumber,
  } = fields;
  return (
    <div className="jag_info_wrap">
      <h1 key="h1-nursint" className="jag_title">
        {content.title}
      </h1>
      <span key="sp-nursint" className="jag_sub_title">
        {content.subtitle}
      </span>
      <div className="jag_body_wrap">
        {renderErrors()}
        <input
          type="text"
          className="jag-lined-box"
          value={firstName}
          autoComplete="off"
          onChange={(ev) =>
            setFieldsAndChangeLocation({ firstName: ev.target.value })
          }
          placeholder={content.firstName}
        />
        <input
          type="text"
          className="jag-lined-box"
          value={lastName}
          autoComplete="off"
          onChange={(ev) =>
            setFieldsAndChangeLocation({ lastName: ev.target.value })
          }
          placeholder={content.lastName}
        />
        <input
          type="text"
          className="jag-lined-box"
          value={email}
          autoComplete="off"
          onChange={(ev) =>
            setFieldsAndChangeLocation({ email: ev.target.value })
          }
          placeholder={content.email}
        />
        <MaskedInput
          className="jag-lined-box"
          mask="11/11/1111"
          placeholder="DD/MM/YYYY (date of birth)"
          value={birthday}
          autoComplete="off"
          onChange={(ev) =>
            setFieldsAndChangeLocation({ birthday: ev.target.value })
          }
        />

        <PhoneInput
          country="AU"
          value={phoneNumber}
          smartCaret={false}
          placeholder={content.phoneNumber}
          onChange={(phone) =>
            setFieldsAndChangeLocation({ phoneNumber: phone })
          }
        />
        <AutoCompleteAddress
          className="jag-lined-box"
          placeholder={content.fullAddress}
          value={fullAddress}
          onChange={(address) => setFieldsAndChangeLocation(address)}
        />
        {content?.medicareNumber && (
          <MaskedInput
            className="jag-lined-box"
            mask="1111111111/1"
            placeholder={content.medicareNumber}
            value={medicareNumber}
            autoComplete="off"
            onChange={(ev) =>
              setFieldsAndChangeLocation({ medicareNumber: ev.target.value })
            }
          />
        )}

        {isNursingRoom && roleName !== "nurse" ? (
          <a
            className="jag-solid-btn jag_fixed-bottom"
            onClick={() => dispatch(showLoginModalThunk)}
          >
            Sign In
          </a>
        ) : (
          <a
            className="jag-solid-btn jag_fixed-bottom"
            onClick={() => submitNewUser()}
            href="#"
          >
            {content.submitBtn}
          </a>
        )}
      </div>
      {!user.id && content.showSignIn && (
        <div className="jag_footer_wrap">
          <span>Already have an account?</span>
          <a href="#" onClick={() => dispatch(showLoginModalThunk)}>
            Sign In
          </a>
        </div>
      )}
      {content?.footer && (
        <div className="jag_footer_wrap">
          <span>{content?.footer}</span>
        </div>
      )}
    </div>
  );
};

export default PatientInformation;
