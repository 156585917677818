import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Specialties } from "@helpers/";
import ConfirmationModal from "@src/components/modals/confirmation_modal";
import { SHOW_CONFIRMATION_MODAL } from "@modules/gui";

const SpecialtiesView = ({ user, gui, showConfirmationModal }) => {
  const history = useHistory();
  const [specialties, setSpecialties] = useState([]);
  const [selectedSpeciality, setSelectedSpecialty] = useState([]);

  useEffect(() => {
    Specialties.fetchSpecialtiesAdmin().then((response) => {
      if (response) {
        setSpecialties(response);
      }
    });
  }, []);

  const renderHeaders = () => {
    return (
      <thead>
        <tr>
          <td>Name</td>
          <td>Description</td>
          <td>Type</td>
          <td>Visibility</td>
          <td>Order</td>
          <td className="final-col"></td>
        </tr>
      </thead>
    );
  };

  const showDeleteAlert = (specialty) => {
    setSelectedSpecialty(specialty);
    showConfirmationModal(true);
  };

  const deleteSpecialty = () => {
    Specialties.deleteSpecialty(selectedSpeciality.id, user.token);
  };

  const editSpecialty = (specialty) => {
    setSelectedSpecialty(specialty);
    history.push(`/admin/specialties/edit/${specialty.id}`);
  };

  const renderTable = () => {
    return specialties.map((specialty) => (
      <tr key={specialty.id}>
        <td>{specialty.name}</td>
        <td>{specialty.description}</td>
        <td>{specialty.type}</td>
        <td>{specialty.visibility}</td>
        <td>{specialty.order}</td>
        <td>
          <Link to={`/admin/specialties/edit/${specialty.id}`}>Edit</Link> |{" "}
          <a href="#" disabled onClick={() => showDeleteAlert(specialty)}>
            Delete
          </a>{" "}
          | <a href={`/admin/specialty/${specialty.id}`}>CMS</a>
        </td>
      </tr>
    ));
  };
  return (
    <div className="meeting-container">
      <div className="admin">
        <div className="doctor-list">
          <div className="top-actions">
            <Link to={"/admin/specialties/new"} className="btn btn-green">
              Create Specialty
            </Link>
          </div>
          <table className="styled">
            {renderHeaders()}
            <tbody>{renderTable()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    showConfirmationModal: (show) =>
      dispatch({
        type: SHOW_CONFIRMATION_MODAL,
        show: show,
      }),
  };
};
const mapStateToProps = ({ gui, session }) => ({
  user: session.user,
  gui,
});
export default connect(mapStateToProps, mapDispatchToProps)(SpecialtiesView);
