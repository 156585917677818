import React from "react";
import { Link } from "react-router-dom";
import loadingLightBlue from "@assets/images/loadingLightBlue.gif";

const Confirmation = (props) => {
  const {
    error,
    sendMagicLink,
    submitting,
    close,
    usePassword,
    email,
    setEmail,
  } = props;
  const tryMagicLinkSignUp = (e) => {
    if (e.key === "Enter") {
      sendMagicLink(email);
    }
  };
  return (
    <div className="popup-wrap">
      <div className="modal-window md-sign-up">
        <div className="modal">
          <div>
            <div className="md-header">
              <a href="#" className="md-close" onClick={close}>
                <i className="icon-cross-circle"></i>
              </a>
              <h4>Hello!</h4>
            </div>

            <div className="md-body">
              <input
                autoCapitalize="off"
                key="email-requ"
                tabIndex="6001"
                onKeyDown={(e) => tryMagicLinkSignUp(e)}
                type="email"
                placeholder={error?.email ? error?.email : "Email"}
                value={email}
                disabled="disabled"
              />
            </div>

            <div className="md-footer">
              <Link
                className="btn btn-info"
                onClick={() => sendMagicLink(email)}
              >
                {submitting ? (
                  <img className="loading-login" src={loadingLightBlue} />
                ) : (
                  "Email me a login link"
                )}
              </Link>
              <Link className="link" onClick={usePassword}>
                Enter password
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
