import React, { useState } from "react";
import { history } from "@modules/";
import { Appointments, Toast } from "@helpers/index";
import { useDispatch } from "react-redux";
import { hideDoctorSurvey } from "@modules/gui";
import ThumbsDownImg from "@assets/images/thumbs-down.svg";
import ThumbsUpImg from "@assets/images/thumbs-up.svg";
const PatientSurvey = (props) => {
  const { appointmentId, user } = props;
  const [showComments, setShowComments] = useState(false);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const answerSurvey = async () => {
    try {
      await Appointments.registerFeedBack(
        appointmentId,
        { comment },
        user.token
      );
      dispatch(hideDoctorSurvey());
      Toast.displayToast({
        message: "Thank you for your feedback!",
        kind: "success",
      });
      history.replace("/");
    } catch (error) {
      console.error(error);
    }
  };

  const updateComment = (event) => {
    setComment(event.target.value);
  };
  const thumbsUp = () => {
    dispatch(hideDoctorSurvey());
    window.open("https://g.page/docto-near/review?rc", "_blank");
  };
  const thumbsDown = () => {
    setShowComments(true);
  };
  const renderComments = () => {
    return (
      <div className="modal">
        <div>
          <div className="call-rate-header md-header">
            <h3>How did we do?</h3>
          </div>

          <div className="call-rate-body">
            <div className="call-rate_wrap">
              <div className="call-rate_comment">
                <div className="commet-head">
                  <span>Write your comment</span>
                </div>
                <textarea
                  value={comment}
                  onChange={(event) => updateComment(event)}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="md-footer">
            <a onClick={() => answerSurvey()} className="btn btn-info">
              Submit
            </a>
          </div>
        </div>
      </div>
    );
  };
  const renderRating = () => {
    return (
      <div className="modal">
        <div>
          <div className="call-rate-header md-header">
            <h3>How did we do?</h3>
          </div>

          <div className="call-rate-body">
            <div className="call-rate_wrap">
              <div className="call-rate_vote">
                <a onClick={() => thumbsDown()} className="vote-icon-wrap bad">
                  <img alt="thumbs down" src={ThumbsDownImg} />
                </a>
                <a onClick={() => thumbsUp()} className="vote-icon-wrap good">
                  <img alt="thumbs up" src={ThumbsUpImg} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="modal-window md-sign-up">
      {showComments ? renderComments() : renderRating()}
    </div>
  );
};

export default PatientSurvey;
