import React, { useEffect, useState } from "react";
import { history } from "@modules/";
import { Appointments, Toast } from "@helpers/index";
import { useDispatch } from "react-redux";
import { showLoadingModal, showChatSideBar } from "@modules/gui";
import CallSettings from "@components/call_settings";
import queryString from "query-string";

const EmergencyRoom = (props) => {
  console.log(props);
  const { user, query, location } = props;
  const [appointment, setAppointment] = useState(null);
  const [status, setStatus] = useState("LOADING");
  const { appointmentId } = props.computedMatch.params;

  const dispatch = useDispatch();

  const raiseInvalidLink = () => {
    Toast.displayToast({
      message: "Invalid Link",
      kind: "error",
    });
    history.replace("/");
  };
  const initChat = () => {
    setStatus("INIT_CHAT");
    dispatch(showChatSideBar({ appointmentId, user }));
    let parsed = queryString.parse(location.search);
    parsed.accepted = true;
    const search = queryString.stringify(parsed);
    history.replace(`${location.pathname}?${search}`);
  };
  const validateEmergencyAppointmentAccess = async () => {
    try {
      const response = await Appointments.validateEmergencyAppointmentAccess(
        appointmentId,
        user.token
      );
      setAppointment(response?.appointment);
      let { accepted } = query;
      const isAccepted = accepted === "true";
      if (isAccepted) {
        initChat();
        return;
      }

      setStatus("REQUEST_ACCESS");
    } catch (error) {
      const res = JSON.parse(error.response);
      const message = res.error.message;
      if (res.error.code === "INVALID_APPOINTMENT_USER_ACCESS") {
        const app = {
          id: appointmentId,
          errors: [message],
        };
        setStatus("ERROR");
        setAppointment(app);

        return;
      }
      history.replace("/");
      let toastInfo = {
        message: "Error Occur. Please contact administration",
        kind: "error",
      };
      Toast.displayToast(toastInfo);
    }
  };
  useEffect(() => {
    validateEmergencyAppointmentAccess();
  }, []);

  const requestAppointment = async () => {
    dispatch(showLoadingModal(true));
    try {
      const response = await Appointments.acceptAppointment(
        appointmentId,
        user.token,
        { userId: user.id }
      );
      console.log(response);
      dispatch(showLoadingModal(false));
      initChat();
    } catch (error) {
      const toastInfo = {
        message:
          "Error please try again. If the error persist please contact docto support.",
        kind: "error",
      };
      Toast.displayToast(toastInfo);
      dispatch(showLoadingModal(false));
    }
  };

  const renderInvalidAppointment = (errors) => {
    return (
      <div className="joinAsGuestWrap">
        <div className="jag_info_wrap">
          <h1 className="jag_title">Emergency Room</h1>
          <span className="jag_sub_title"></span>
          <div className="jag-call-alert">
            <div className="message-alert-box alert-danger">
              <i className="icon-warning"></i>
              <h4>Access Denied</h4>
              {errors.map((error, index) => {
                return <p key={`error-${index}`}>{error}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderLoading = () => {
    return (
      <div className="joinAsGuestWrap">
        <div className="jag_info_wrap">
          <h1 className="jag_title"></h1>
          <span className="jag_sub_title">LOADING ...</span>
        </div>
        <div className="jag_brand"></div>
      </div>
    );
  };

  const renderAcceptDoctor = () => {
    const { patient } = appointment;
    return (
      <div className="joinAsGuestWrap">
        <div className="jag_info_wrap">
          <span className="jag_sub_title">
            {patient.firstName} is waiting for you.
          </span>
          <CallSettings
            joinNowData={{
              isDoctor: true,
              doctorOnline: true,
            }}
            allowChat={true}
            joinNow={() => requestAppointment()}
            onError={(error) => callSettingError(error)}
          />
          <div className="jag_footer_action">
            <a onClick={() => requestAppointment()}>JOIN NOW</a>
          </div>
        </div>

        <div className="jag_brand"></div>
      </div>
    );
  };
  const callSettingError = (e) => {
    console.error("callSettingError", e);
  };
  switch (status) {
    case "LOADING":
      return renderLoading();
    case "ERROR":
      return renderInvalidAppointment(appointment.errors);
    case "REQUEST_ACCESS":
      return renderAcceptDoctor();
    case "INIT_CHAT":
    default:
      return (
        <div className="joinAsGuestWrap">
          <div className="jag_brand"></div>
        </div>
      );
  }
};

export default EmergencyRoom;
