import React from "react";
import { useField } from "react-final-form";

export const AdaptedInput = ({ input, meta, ...props }) => {
  return <input {...props} {...input} isInvalid={meta.error && meta.touched} />;
};

export const InputField = ({
  name,
  label,
  placeholder,
  fieldProps,
  formControlProps,
  formLabelProps,
  groupClass = null,
  showError = ({ meta: { touched, invalid } }) => touched && invalid,
  type,
  isDisabled,
  ...props
}) => {
  const { input, meta } = useField(name, { type, ...fieldProps });
  const { error } = meta;
  const isError = showError({ meta });
  let fieldClass = groupClass ? groupClass : "form-group";
  fieldClass = isError ? `${fieldClass} alert-error` : `${fieldClass}`;

  return (
    <div
      className={fieldClass}
      disabled={isDisabled}
      {...formControlProps}
      data-alert={error || ""}
    >
      {!!label && (
        <label htmlFor={input.name} {...formLabelProps}>
          {label}
        </label>
      )}
      <input {...props} {...input} id={input.name} placeholder={placeholder} />
    </div>
  );
};
