"use strict";
import browser from "browser-detect";

export default {
  browser: function () {
    let userAgent = navigator.userAgent;

    if (userAgent.indexOf("Android") !== -1) {
      return "Android";
    } else if (userAgent.indexOf("Edge") !== -1) {
      return "Edge";
    } else if (
      (userAgent.indexOf("Opera") || userAgent.indexOf("OPR")) !== -1
    ) {
      return "Opera";
    } else if (userAgent.indexOf("Chrome") !== -1) {
      return "Chrome";
    } else if (userAgent.indexOf("Firefox") !== -1) {
      return "Firefox";
    } else if (userAgent.indexOf("Safari") !== -1) {
      return "Safari";
    } else if (
      userAgent.indexOf("MSIE") !== -1 ||
      document.documentMode === true
    ) {
      //IF IE > 10
      return "Internet Explorer";
    } else {
      return "unknown";
    }
  },

  compatibleBrowser: function () {
    let browser2 = browser();
    const browserName = browser2.name;
    let isCompatibleBrowser = false;

    isCompatibleBrowser =
      browserName === "chrome" ||
      browserName === "firefox" ||
      browserName === "android" ||
      browserName === "safari";
    if (browser2.mobile && this.isIOs()) {
      isCompatibleBrowser = browserName === "safari";
    }
    return isCompatibleBrowser;
  },

  isIOs: function () {
    const isIos =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return isIos;
  },
  isMobile: function () {
    return browser().mobile;
  },

  isSafari: function () {
    const result = browser();
    return result.name === "safari";
  },

  isAndroid: function () {
    return this.browser() === "Android";
  },

  isMobileResolution: function () {
    if (typeof window === "undefined") {
      return false;
    }
    return document.body.clientWidth <= 800;
  },
};
