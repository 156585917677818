"use strict";
import React, { useState, useEffect } from "react";
import { Routes } from "@constants";
import isImage from "is-image";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { history } from "@modules/";
import {
  Chat as ChatHelpers,
  User,
  Appointments,
  Toast,
  Utils,
} from "@helpers";
import { useDispatch } from "react-redux";
import {
  showConfirmationModal,
  closeConfirmationModal,
  showAdvancePaymentModal,
  closeAdvancePaymentModal,
  showDocumentViewer,
} from "@modules/gui";

const TextMessage = (props) => {
  const { body, link, isMine, sender, unixTime } = props?.message;
  const isArray = Array.isArray(body);
  return (
    <div className={isMine ? "arci-ittem received" : "arci-ittem"}>
      <div className="arci-message">
        <span>
          {isArray ? (
            body.map((text, index) => (
              <span key={`message${index}`}>
                {text}
                <br />
              </span>
            ))
          ) : link ? (
            <a rel="noreferrer" href={link} target="_blank">
              {body}
            </a>
          ) : (
            <span>{body}</span>
          )}
        </span>
      </div>
      <div className="arci-time-stamp">
        {sender}
        {""}|{""}
        {moment.unix(unixTime).format("h:mm a")}
      </div>
    </div>
  );
};

const SystemMessage = (props) => {
  const {
    updateBookStatus,
    onAppointmentChange,
    currentUser,
    message,
    appointment,
  } = props;
  const dispatch = useDispatch();

  const chageDates = () => {
    const proceedFunction = () => {
      var payload = {
        bookStatus: "REQUEST-DATES",
        chatId: message.chatId,
      };
      updateBookStatus(payload);
    };
    dispatch(
      showConfirmationModal({
        onProceed: proceedFunction,
        onCancel: () => dispatch(closeConfirmationModal()),
        onClose: () => dispatch(closeConfirmationModal()),
        proceedText: "Confirm",
        cancelText: "Close",
        children: `Do you want to request new dates?`,
      })
    );
  };
  const addToCalendar = () => {
    window.open(
      Routes.downloadCalendar(
        params.appointmentId,
        this.props.currentUser.token
      )
    );
  };

  const acceptDate = (date) => {
    dispatch(closeConfirmationModal());
    const price = Appointments.pendingAmount(appointment);
    if (price <= 0) {
      proceedWithPayment({}, date);
      return;
    }
    dispatch(
      showAdvancePaymentModal({
        onClose: dispatch(closeAdvancePaymentModal()),
        navigation: "confirmation",
        price: price,
        onConfirm: (card) => proceedWithPayment(card, date),
      })
    );
  };
  const proceedWithPayment = async (card, date) => {
    dispatch(
      showAdvancePaymentModal({
        loading: true,
      })
    );
    try {
      await Appointments.acceptAppointmentDate(
        appointment.id,
        currentUser.token,
        card,
        date
      );
      dispatch(closeAdvancePaymentModal());
      setTimeout(() => {
        onAppointmentChange();
      }, 500);
    } catch (error) {
      console.error(error);
      const res = JSON.parse(error.response);
      if (res?.error?.code === "INVALID_PAYMENT_METHOD") {
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            navigation: "billingInfo",
          })
        );
        Toast.displayToast({
          kind: "error",
          message: "Invalid payment method. Please try again",
        });
        return;
      }
      if (res?.error?.code === "PAYMENT_DECLINE") {
        Toast.displayToast({
          kind: "error",
          message:
            "Payment decline. Please update your payment method and try again.",
        });
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            navigation: "billingInfo",
          })
        );
        return;
      }
      Toast.displayToast({
        kind: "error",
        message: "An unexpected error occur. please try again",
      });
      dispatch(closeAdvancePaymentModal());
    }
  };
  const preApprovedDates = (params) => {
    const price = params.pendingAmount;

    const confirmText =
      Number(price) <= 0 ? (
        `Confirming  ${moment(params.preaproveDate).format(
          "hh:mma DD/MM/YYYY"
        )}?`
      ) : (
        <span>
          Confirming {moment(params.preaproveDate).format("hh:mma DD/MM/YYYY")}?
          <br />
          Your card will be debited ${price}
        </span>
      );
    dispatch(
      showConfirmationModal({
        onProceed: () => acceptDate(params.preaproveDate),
        onCancel: () => dispatch(closeConfirmationModal()),
        onClose: () => dispatch(closeConfirmationModal()),
        proceedText: "Confirm",
        cancelText: "Close",
        children: confirmText,
      })
    );
  };
  const cancelAppointment = () => {
    const proceedFunction = () => {
      const role = currentUser.roles[0].name;
      const payload = {
        bookStatus: "CANCELLED",
        cancelledBy: role,
        chatId: message.chatId,
      };
      updateBookStatus(payload);
    };
    dispatch(
      showConfirmationModal({
        onProceed: proceedFunction,
        onCancel: () => dispatch(closeConfirmationModal()),
        onClose: () => dispatch(closeConfirmationModal()),
        proceedText: "Cancel appointment",
        cancelText: "Close",
        children: "Are you sure you want to cancel the consultation",
      })
    );
  };

  const triggerAction = (type, params) => {
    switch (type) {
      case "changeDates":
        chageDates();
        break;
      case "addToCalendar":
        addToCalendar();
        break;
      case "preApprovedDates":
        preApprovedDates(params);
        break;
      case "cancelAppointment":
        cancelAppointment();
        break;
      case "otherSpecialties":
        history.push(`/browse-hospital`);
        break;
    }
  };
  const renderAction = (action, index) => {
    const { meta } = message;
    const isLastAction = meta.actions[index + 1] == undefined ? true : false;
    switch (action.type) {
      case "preApprovedDates":
        return (
          <span key={`action${action.type}-${index}`}>
            <a onClick={() => triggerAction(action.type, action.params)}>
              {moment(action.params.preaproveDate).format("hh:mma DD.MM.YY")}
            </a>{" "}
            {isLastAction ? "" : " | "}
          </span>
        );
      default:
        return (
          <span key={`action${action.type}-${index}`}>
            <a onClick={() => triggerAction(action.type, action.params)}>
              {ReactHtmlParser(action.text)}
            </a>{" "}
            {isLastAction ? "" : " | "}
          </span>
        );
    }
  };

  const { body, meta, sender, unixTime } = message;
  const { params } = meta;

  let role = User.appointmentRole(currentUser, appointment);
  role = role == "admin" ? "doctor" : role;
  if (meta.role && meta.role != role) {
    return null;
  }
  switch (meta.type) {
    case "select-dates":
      return (
        <div className="arci-ittem system">
          <div className="arci-message">
            <div className="arci-message-body">
              <h4>{ReactHtmlParser(body)}</h4>
              {params.date1 ? (
                <div className="selection-row">
                  <span>
                    ●{" "}
                    {moment(params.date1).format(
                      "dddd Do MMMM YYYY [at] h:mm a"
                    )}
                  </span>
                  <a
                    onClick={() =>
                      triggerAction("preApprovedDates", {
                        preaproveDate: params.date1,
                        appointmentId: params.appointmentId,
                        pendingAmount: params.pendingAmount,
                      })
                    }
                    className="btn"
                  >
                    Accept
                  </a>
                </div>
              ) : null}
              {params.date2 ? (
                <div className="selection-row">
                  <span>
                    ●{" "}
                    {moment(params.date2).format(
                      "dddd Do MMMM YYYY [at] h:mm a"
                    )}
                  </span>
                  <a
                    onClick={() =>
                      triggerAction("preApprovedDates", {
                        preaproveDate: params.date2,
                        appointmentId: params.appointmentId,
                        pendingAmount: params.pendingAmount,
                      })
                    }
                    className="btn"
                  >
                    Accept
                  </a>
                </div>
              ) : null}
            </div>

            <div className="arci-message-footer">
              <a
                onClick={() =>
                  triggerAction("changeDates", {
                    appointmentId: params.appointmentId,
                  })
                }
              >
                Request New Date
              </a>
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div className="arci-ittem received color-system">
          <div className="arci-message">
            <span>
              {ReactHtmlParser(body)}
              <br />
            </span>
            <br />
            {appointment.bookStatus == "COMPLETED" ||
            appointment.bookStatus == "EXPIRED"
              ? null
              : meta.actions
              ? meta.actions.map((action, index) => renderAction(action, index))
              : null}
          </div>
          <div className="arci-time-stamp">
            {sender}| {moment.unix(unixTime).format("h:mm a")}
          </div>
        </div>
      );
  }
};

const FileMessage = (props) => {
  console.log(props);
  const { message, currentUser } = props;
  const dispatch = useDispatch();
  const [meta, setMeta] = useState({
    isImage: false,
    fileUrl: null,
    fileReader: null,
    fileName: null,
    type: "file",
  });
  const [objectUrl, setObjectUrl] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  useEffect(() => {
    setMeta(message?.meta);
  }, []);
  useEffect(() => {
    if (!meta) {
      return;
    }
    if (meta.fileReader) {
      setObjectUrl(meta.fileReader);
      return;
    }
    if (meta.bucket) {
      const appointmentId = message.channel;
      const fileUrl =
        meta.bucket === "docto-referral-documents"
          ? Routes.downloadReferralDocument(appointmentId, meta.fileUrl)
          : Routes.chatFileUrl(meta.fileUrl);
      setFileUrl(fileUrl);
      loadObjectUrl(`${fileUrl}?accessToken=${currentUser.token}`);
    }
  }, [meta]);
  const loadObjectUrl = async (url) => {
    const objectUr = await Utils.getObjectUrl(url);
    setObjectUrl(objectUr);
  };

  const showFile = (url) => {
    dispatch(showDocumentViewer({ url, token: currentUser.token }));
  };

  return (
    <div className="chat-item attachment">
      {isImage(meta?.fileName) && objectUrl && <img src={objectUrl} />}
      <br />
      {fileUrl && (
        <a
          target="_blank"
          rel="noreferrer"
          onClick={() => showFile(fileUrl)}
          className="ci-attch-link"
        >
          {meta.fileName}
        </a>
      )}
    </div>
  );
};

const Message = (props) => {
  const {
    onAppointmentChange,
    updateBookStatus,
    currentUser,
    message,
    appointment,
  } = props;
  const renderMultipleSystemMessage = () => {
    var messages = ChatHelpers.formatSystemMessage(message);

    var map = messages.map((messageNew, index) => {
      return (
        <SystemMessage
          {...{
            onAppointmentChange,
            updateBookStatus,
            currentUser,
            message: messageNew,
            appointment,
          }}
          key={`${message.chatId}${index}`}
        />
      );
    });

    return <div>{map}</div>;
  };

  const meta = message?.meta;
  const type = meta && meta.type ? meta.type : "text";

  switch (type) {
    case "multiple-system":
      return renderMultipleSystemMessage(message);
    case "system":
      return (
        <SystemMessage
          {...{
            updateBookStatus,
            onAppointmentChange,
            currentUser,
            message,
            appointment,
          }}
        />
      );
    case "file":
      return <FileMessage {...{ currentUser, message, appointment }} />;
    case "text":
    default:
      return <TextMessage {...{ currentUser, message }} />;
  }
};
export default Message;
