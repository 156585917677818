import React, { useEffect, useState } from "react";
import { Docto } from "@constants/";
import moment from "moment";
import { useDispatch } from "react-redux";
import { history } from "@modules/";
import { useLocation } from "react-router-dom";
import {
  User,
  Appointments,
  Toast,
  Chat as ChatHelper,
  PubNubInterface,
} from "@helpers";
import PubNub from "pubnub";
import Chat from "./chat";
import botSound from "@assets/sounds/beep2.wav";
const WAIT_TIME_SECONDS = 60 * 5;
import {
  showConfirmationModal,
  closeConfirmationModal,
  showChatSideBar,
  closeChatSideBar,
  showDoctorNotes,
  showPatientMedicalRecord,
  showPatientSurvey,
  showDoctorSurvey,
  showCallTestModal,
} from "@modules/gui";

const Loading = () => {
  const divStyle = {
    backgroundImage: `url('@assets/images/patient_placeholder.jpg')`,
  };
  return (
    <div className={"chat-module"}>
      <div className="chat-module__inner">
        <div className={"chat-module__block active"}>
          <div className="chat-module__block__header inactive">
            <div className="ps-message-count">
              <i className="icon-chat-lined"></i>
            </div>
            <div className="cp-minim" href="#">
              <span className="ps-timer">Requesting Emergency Call</span>
            </div>
          </div>
          <div className="chat-module__block__sub-header">
            <div className="client-avatar" style={divStyle}></div>
            <div className="client-info">
              <span>Requesting Emergency Call</span>
            </div>
          </div>
          <div className="chat-module__block__body">
            <div className="chat-module__block__scroll">
              <div className="chat-module__block__scroll__inner">
                <div className="pre-chat-message">
                  <p>Requesting your emergency call. Please wait</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

let posponeMessageSend = false;
let occupancy = 0;
let pubnub, pubNubInterface;
const ding = new Audio(botSound);
let timeouts = [];
let requestTimeOut = null;

const ChatSidebar = (props) => {
  const {
    appointmentId,
    user,
    minimizeChat,
    envs,
    isCallerOnVideoCall,
    trigerEndCall,
    trigerLeaveCall,
  } = props;
  const location = useLocation();
  const [status, setStatus] = useState("loading");
  const [senderWriting, setSenderWriting] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();
  const [callerActive, setCallerActive] = useState(false);
  const isAppointmentDoctor = User.isAppointmentDoctor(user, appointment);

  const appendMessage = (message) => {
    console.log("appendMessage", [...messages, message]);
    setMessages((messages) => [...messages, message]);
  };

  useEffect(() => {
    if (trigerEndCall > 0) {
      showConfirmEndConsultation();
    }
  }, [trigerEndCall]);
  useEffect(() => {
    if (trigerLeaveCall > 0) {
      leaveCall();
    }
  }, [trigerLeaveCall]);

  const requestMessages = async () => {
    try {
      const response = await Appointments.requestMessages(
        appointmentId,
        user.token
      );
      const messag = ChatHelper.formatMessages(response.chats, user);
      await setMessages(messag);
    } catch (error) {
      console.error(error);
    }
  };
  const displayCog = () => {
    dispatch(showCallTestModal(true));
  };
  const fetchChat = async () => {
    const response = await Appointments.fetchAppointmentChat(
      appointmentId,
      user.token
    );
    const appointmentFetched = response.appointment;
    setAppointment(appointmentFetched);
  };
  const initRequestChatPolling = async (appointmentId, lastReminderUnix) => {
    if (appointmentId == null) {
      return;
    }
    try {
      const response = await Appointments.fetchAppointmentChat(
        appointmentId,
        user.token
      );
      const appointmentFetched = response.appointment;
      console.log({ appointmentFetched });

      const posponeEmergencyReminderUntil =
        appointmentFetched.notifications.posponeEmergencyReminderUntil || {};
      const posponeEmergencyReminderUnix =
        posponeEmergencyReminderUntil.unix || 0;

      if (appointmentFetched.status === "ACCEPTED") {
        setAppointment(appointmentFetched);
        const message = {
          body: "Sorry to keep you waiting, the Dr is joining now.",
          channel: appointmentId,
          sender: "Docto",
          senderId: null,
          timestamp: new Date().getTime(),
        };
        appendMessage(message);
        return appointmentFetched;
      }
      if (
        !posponeMessageSend &&
        moment().unix() < posponeEmergencyReminderUnix
      ) {
        posponeMessageSend = true;
        const postponeMessage = {
          type: "postPoneMessage",
          channel: appointmentFetched.id,
          meta: {
            patientName: user.firstName,
            posponeEmergencyReminderUntil,
          },
          sender: "Docto",
          senderId: null,
          timestamp: new Date().getTime(),
        };
        appendMessage(postponeMessage);
      }
      if (appointmentFetched.status === "EMERGENCY_REQUESTED") {
        const timelapsedSeconds = moment().unix() - lastReminderUnix;
        if (
          moment().unix() > posponeEmergencyReminderUnix &&
          timelapsedSeconds >= WAIT_TIME_SECONDS
        ) {
          try {
            await Appointments.sendEmergencyCallReminder(
              appointmentId,
              user.token
            );
            lastReminderUnix = moment().unix();
          } catch (error) {
            console.error(error);
          }
        }
        requestTimeOut = setTimeout(() => {
          initRequestChatPolling(appointmentId, lastReminderUnix);
        }, 5000);
      }
      return appointmentFetched;
    } catch (error) {
      console.error(error);
      const toastInfo = {
        message: "Error Occur. Please contact administration",
        kind: "error",
      };
      Toast.displayToast(toastInfo);
    }
  };
  const onStatusPubNubChange = (status) => {
    switch (status) {
      case "writing":
        setSenderWriting(true);
        return;
      case "waiting":
        setSenderWriting(false);
        return;
      case "joining":
        appendMessage({
          body: `Call accepted, please wait...`,
          channel: appointment.id,
          sender: "Docto",
          senderId: chatUser.id,
          timestamp: new Date().getTime(),
        });
        return;
    }
  };
  const showSurveyWithAppointment = (ap) => {
    const isDoc = User.isAppointmentDoctor(user, ap);
    console.log("show survery", { user, appointment, isDoc });
    gotoMain();
    if (isDoc) {
      dispatch(showPatientSurvey({ appointmentId }));
      return;
    }
    dispatch(showDoctorSurvey({ appointmentId }));
  };
  const showSurvey = () => {
    gotoMain();
    if (isAppointmentDoctor) {
      dispatch(showPatientSurvey({ appointmentId }));
      return;
    }
    dispatch(showDoctorSurvey({ appointmentId }));
  };
  const endCall = async () => {
    try {
      await Appointments.endAppointment(
        appointmentId,
        user.token,
        !isAppointmentDoctor
      );
      let data = {
        body: `${user.firstName} has left the chat`,
        channel: appointmentId,
        appointmentId,
        sender: "Docto",
        senderId: user.id,
        username: user.username,
        timestamp: new Date().getTime(),
      };
      pubNubInterface.sendMessageNoSave({ type: "EndCall", data: data });
      showSurvey();
      gotoMain();
    } catch (error) {
      console.log(error);
    }
  };
  const gotoMain = () => {
    history.push("/");
    dispatch(closeChatSideBar());
    dispatch(closeConfirmationModal());
  };

  const leaveCall = () => {
    const data = {
      body: `${user.firstName} has left the chat`,
      channel: appointmentId,
      appointmentId,
      sender: "Docto",
      senderId: user.id,
      username: user.username,
      timestamp: new Date().getTime(),
    };
    pubNubInterface.sendMessageNoSave({ type: "LeaveCall", data: data });
    gotoMain();
  };
  const pubNubAppendMessage = (message) => {
    console.log("pubNubAppendMessage", { messages, appointment });
    console.log(message);

    let payload = message.data;
    payload.meta = message.meta;
    payload.date = new Date(payload.timestamp);
    console.log({ payload, message });
    appendMessage(payload);
    ding.play();
  };
  const pubNubOnMessage = (income, apmnt) => {
    const { message } = income;
    const { type, status, senderId } = message;
    if (senderId === user.id) {
      return;
    }
    switch (type) {
      case "status":
        onStatusPubNubChange(status);
        return;
      case "EndCall":
        showSurveyWithAppointment(apmnt);
        return;
      case "LeaveCall":
        gotoMain();
        return;
      case "RequestCall":
        pubNubAppendMessage(message);
        return;
      case "file":
      case "chat":
        console.log(messages);
        pubNubAppendMessage(message);
        return;
      default:
        console.log("unhandlepubnub", { message });
        break;
    }
  };
  const pubnubOnPresence = (p) => {
    if (p.action === "join") {
      occupancy = p?.occupancy;
      if (p.occupancy > 1) {
        fetchChat();
        setCallerActive(true);
      } else {
        setCallerActive(false);
      }
    }
  };
  const addPubNubListener = (apmnt) => {
    pubnub.addListener({
      message: (a) => pubNubOnMessage(a, apmnt),
      presence: (a) => pubnubOnPresence(a, apmnt),
    });
    pubnub.subscribe({
      channels: [appointmentId],
      withPresence: true,
    });
    pubnub.hereNow(
      {
        channels: [appointmentId],
        includeState: true,
      },
      (status, response) => {
        occupancy = response?.totalOccupancy;
        if (response?.totalOccupancy > 1) {
          setCallerActive(true);
        } else {
          setCallerActive(false);
        }
      }
    );
  };
  const unsubscribeChat = () => {
    console.log("unsubscribeChat");
    timeouts.forEach((t) => {
      clearTimeout(t);
    });
    clearTimeout(requestTimeOut);
    if (pubnub) {
      pubnub.unsubscribe({ channels: [appointmentId] });
      const existingListener = {
        message: function () {},
        presence: function () {},
      };
      pubnub.removeListener(existingListener);
    }
  };
  const sendSmsWaitingPatient = (user, ap) => {
    if (User.isAppointmentDoctor(user, ap)) {
      return;
    }
    const timeout = setTimeout(() => {
      if (occupancy < 2) {
        const doctoMessage = {
          type: "SmsWaitingPatient",
          channel: appointmentId,
          sender: "Docto",
          senderId: null,
          timestamp: new Date().getTime(),
        };
        appendMessage(doctoMessage);
      }
    }, 240000);
    timeouts.push(timeout);
  };
  const sendPatientHasNotYetJoin = (user, ap) => {
    if (!User.isAppointmentDoctor(user, ap)) {
      return;
    }
    console.log("sendPatientHasNotYetJoin");
    const timeout = setTimeout(() => {
      if (occupancy < 2) {
        const doctoMessage = {
          body: `Your patient has not yet joined. They have been sent an SMS reminder. Please wait`,
          channel: appointmentId,
          sender: "Docto",
          senderId: user.id,
          timestamp: new Date().getTime(),
        };
        appendMessage(doctoMessage);
      }
    }, 10000);
    timeouts.push(timeout);
  };
  const initChat = async () => {
    try {
      pubnub = new PubNub({
        publishKey: envs.PUB_NUB_PUBLISH_KEY,
        subscribeKey: envs.PUB_NUB_SUBSCRIBE_KEY,
        ssl: true,
        presenceTimeout: 120,
        heartbeatInterval: 30,
        uuid: `${user.id}:${Docto.version}:${User.getRoleName(user)}`,
        error: (error) => {
          console.error("PUB NUB Error:", error);
        },
      });
      pubNubInterface = new PubNubInterface(pubnub);
      const ap = await initRequestChatPolling(appointmentId, moment().unix());

      setAppointment(ap);
      await requestMessages(appointmentId);
      addPubNubListener(ap);
      setStatus("done");
      sendPatientHasNotYetJoin(user, ap);
      sendSmsWaitingPatient(user, ap);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    initChat();
    return unsubscribeChat;
  }, []);

  if (status === "loading") {
    return <Loading />;
  }
  const isOnConsultationRoom =
    location.pathname.indexOf("consultation-room") >= 0;
  const isminimizeChat = isOnConsultationRoom ? minimizeChat : false;

  let chatUser = User.isNurse(user) ? appointment.patient : user;
  let caller = isAppointmentDoctor ? appointment.patient : appointment.user;

  const onMinimizeChat = () => {
    dispatch(showChatSideBar({ minimizeChat: true }));
  };

  const requestCall = (callType) => {
    var isVideoCall = callType === "video";
    const requestCallMessage = {
      callType: callType,
      body: "Call requested",
      type: "RequestCall",
      channel: appointment.id,
      sender: "Docto",
      senderId: chatUser.id,
      timestamp: new Date().getTime(),
    };

    appendMessage(requestCallMessage);
    const payload = {
      type: "RequestCall",
      senderId: chatUser.id,
      data: requestCallMessage,
    };
    pubNubInterface.sendMessageNoSave(payload);
    history.push({
      pathname: `/consultation-room/${appointment.id}/audio/${!isVideoCall}`,
    });
  };

  const showConfirmEnConsultationRequest = () => {
    const thunk = showConfirmationModal({
      onProceed: () => confirmHangUp(),
      proceedText: "Accept",
      cancelText: "Cancel",
      children: (
        <span>
          This will end the consult request.
          <br />
          You will be unable to reactivate for 5 mins{" "}
        </span>
      ),
      onClose: () => dispatch(closeConfirmationModal()),
      onCancel: () => dispatch(closeConfirmationModal()),
    });
    dispatch(thunk);
  };
  const showConfirmEndConsultation = () => {
    const thunk = showConfirmationModal({
      onProceed: () => endCall(),
      proceedText: "End Consultation",
      cancelText: "Leave",
      children: "Are you sure you want to finish the consultation.",
      onClose: () => dispatch(closeConfirmationModal()),
      onCancel: () => leaveCall(),
    });
    dispatch(thunk);
  };

  const confirmHangUp = async () => {
    try {
      await Appointments.endAppointment(
        appointmentId,
        user.token,
        !isAppointmentDoctor
      );
      gotoMain();
    } catch (error) {
      console.error(error);
      gotoMain();
    }
  };

  const onUserTyping = (isWriting) => {
    console.log({ isWriting });
    const status = isWriting ? "writing" : "waiting";
    const payload = {
      type: "status",
      status,
      senderId: chatUser.id,
      data: { channel: appointmentId, sender: chatUser.firstName },
    };
    pubNubInterface.sendMessageNoSave(payload);
  };
  const onSendMessage = (message) => {
    const data = {
      body: message,
      channel: appointment.id,
      sender: chatUser.firstName,
      thumbnailImage: chatUser.thumbnailImage,
      senderId: chatUser.id,
      timestamp: new Date().getTime(),
    };
    const payload = {
      type: "chat",
      senderId: chatUser.id,
      data: data,
    };
    appendMessage(data);
    pubNubInterface.sendMessage(payload);
  };
  const showNotes = () => {
    dispatch(showDoctorNotes({ show: true, appointmentId: appointment.id }));
  };
  const showFiles = () => {
    dispatch(
      showPatientMedicalRecord({ show: true, appointmentId: appointment.id })
    );
  };

  const onSendFile = (file) => {
    const date = new Date();
    const data = {
      body: "",
      channel: appointment.id,
      sender: chatUser.firstName,
      senderId: chatUser.id,
      timestamp: date.getTime(),
    };
    const payload = {
      channel: appointment.id,
      type: "sendFile",
      file: file,
      token: user.token,
      data: data,
      timestamp: date,
      pubNubInterface,
      formattedTime: ChatHelper.formatTime(date),
    };

    appendMessage(payload);
  };

  if (User.isNurse(user)) {
    chatUser.token = user.token;
  }
  return (
    <div>
      <div
        className={isminimizeChat ? "chat-module chat-hidden" : "chat-module"}
      >
        <div className="chat-module-call-actions">
          {isAppointmentDoctor && [
            <button
              key="showNotes"
              onClick={() => showNotes(appointment)}
              className="button-yellow"
              type="button"
            >
              <i className="icon-pencil5"></i>
            </button>,
            <button
              key="showFiles"
              onClick={() => showFiles(appointment)}
              className="button-white"
              type="button"
            >
              <i className="icon-files"></i>
            </button>,
          ]}
        </div>
        <div className="chat-module__inner">
          <Chat
            {...{
              showConfirmEnConsultationRequest,
              showConfirmEndConsultation,
              user: chatUser,
              envs,
              isCallerOnVideoCall,
              callerActive,
              isOnConsultationRoom,
              appointment,
              caller,
              isAppointmentDoctor,
              senderWriting,
              messages,
              onUserTyping,
              onSendMessage,
              onSendFile,
              showNotes,
              showFiles,
              onMinimizeChat,
              requestCall,
              displayCog,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatSidebar;
