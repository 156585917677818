import React from "react";
import { Guid } from "@helpers";
import Brand from "@assets/images/vidBox-brand.png";
const InvalidAppointment = (props) => {
  return (
    <div className="meeting-message">
      <div className="mm-allow-access">
        <i className="icon-warning"></i>
        {props.errors.length
          ? props.errors.map((error) => {
              return <p key={`${Guid()}-error`}>{error}</p>;
            })
          : null}
        <div className="vid-brand">
          <span>Powered By:</span>
          <img src={Brand} />
        </div>
      </div>
    </div>
  );
};

export default InvalidAppointment;
