import React from "react";
const NotFound = () => {
  return (
    <div className="error-page">
      <div className="left-side">
        <span className="left-side">404</span>
      </div>
      <div className="right-side">
        <span className="large">Sorry!</span>
        <span>
          The page you are looking
          <br />
          for was not found.
        </span>
      </div>
    </div>
  );
};

export default NotFound;
