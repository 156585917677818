import React from "react";
import _ from "lodash";

function loader() {
  const repeat = 5;
  return (
    <div className="preloader-bh">
      <div className="account-body">
        {_.times(repeat, (index) => {
          return (
            <div key={`loader_${index}`} className="prof-load">
              <div className="left">
                <div className="loader-animation">
                  <div></div>
                </div>
              </div>
              <div className="center">
                <div className="loader-animation">
                  <div></div>
                </div>
              </div>
              <div className="right">
                <div className="loader-animation">
                  <div></div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="prof-load"></div>
        <div className="prof-load"></div>

        {_.times(1, (index) => {
          return (
            <div key={`loader_${index}`} className="prof-load teal">
              <div className="left">
                <div className="loader-animation-button">
                  <div></div>
                </div>
              </div>
              <div className="center">
                <div className="loader-animation-button">
                  <div></div>
                </div>
              </div>
              <div className="right">
                <div className="loader-animation-button">
                  <div></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default loader;
