import React from "react";
import Helmet from "react-helmet";
import Hero from "@src/components/home/hero";
import PriceWrap from "@src/components/home/price_wrap";
import Specialists from "@src/components/home/specialists";
import ConsultSpecialist from "@src/components/home/consult_specialist";
import HowItWorks from "@src/components/home/how_it_works";
import WhyDocto from "@src/components/home/why_docto";
import Testimonials from "@src/components/home/testimonials";
import Partners from "@src/components/home/partners";
import { connect } from "react-redux";

function index(props) {
  console.log({ props });
  return (
    <div>
      <Helmet>
        <title>
          Online doctor and medical specialist consultations - DOCTO
        </title>
        <meta
          property="title"
          content="Online doctor and medical specialist consultations - DOCTO"
        />
        <meta
          name="description"
          content="Online doctor and medical specialist telehealth service. Online consultations with matched medical specialists for prescriptions, referrals and definitive care"
        />
        <link rel="canonical" href="https://www.docto.com.au" />
      </Helmet>
      <Hero />
      <PriceWrap />
      <Specialists />
      <ConsultSpecialist />
      <HowItWorks />
      <WhyDocto />
      <Testimonials />
      <Partners />
    </div>
  );
}
const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps)(index);
