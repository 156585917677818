import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UnboundConfirmationModal from "@src/components/modals/unbound_confirmation_modal";
import copy from "copy-to-clipboard";
import { reject } from "lodash";
import ChangePassword from "./change_password";
import SpecialistsTable from "./table";
import { Toast, Doctors, User } from "@helpers";
import { SHOW_LOGIN_MODAL } from "@modules/gui";
import { mascaradeUserWithToken } from "@modules/session";

class SpecialistsAdmin extends React.Component {
  constructor() {
    super();
    this.state = {
      changePasswordSpecialistId: null,
      showGuestLink: false,
      guestLinkStatus: "PROCESSING",
      guestLink: "",
      selectedSpecialist: null,
      showDeleteConfirmation: false,
      tableFilters: {
        page: 0,
        sort: "DESC",
        sortBy: "id",
        pageSize: 10,
        textFilter: "",
      },
      specialists: {},
    };
    this.fetchSpecialists = this.fetchSpecialists.bind(this);
    this.showGuestLink = this.showGuestLink.bind(this);
    this.copyGuestLink = this.copyGuestLink.bind(this);
    this.showDeleteAlert = this.showDeleteAlert.bind(this);
    this.deleteSpecialist = this.deleteSpecialist.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.removeUserFromSpecialists = this.removeUserFromSpecialists.bind(this);
    this.mascaradeUser = this.mascaradeUser.bind(this);
  }
  componentDidMount() {
    this.fetchSpecialists();
  }

  fetchSpecialists() {
    this.setState({ loading: true });
    Doctors.fetchAll(this.props.user.token, {
      ...this.state.tableFilters,
    }).then((res) => {
      console.log("fetchAll....");
      console.log(res.data);
      const response = res;
      this.setState({ specialists: response, loading: false });
    });
  }
  showGuestLink(specialist) {
    this.setState({
      guestLinkStatus: "PROCESSING",
      showGuestLink: true,
      guestLink: "",
    });

    let doctor = specialist;
    console.log(doctor);

    let appointmentData = {
      patientId: null,
      userId: doctor.id,
      message: "GUEST LINK",
      type: "guest-video",
      isfollowUp: false,
      freeBusiness: true,
      scheduledOn: new Date(),
    };

    let _this = this;
    try {
      Doctors.appointmentsWithToken(
        this.props.user.token,
        appointmentData
      ).then((response) => {
        let appointmentCreated = response;
        let appointmentLink = `${window.location.origin}/room/guest/${appointmentCreated.id}`;
        _this.setState({
          guestLinkStatus: "SUCCESS",
          guestLink: appointmentLink,
        });
      });
    } catch (error) {
      console.error(error);
      this.setState({
        guestLinkStatus: "PROCESSING",
        showGuestLink: false,
        guestLink: "",
      });
      Toast.displayToast({
        kind: "error",
        message: "An error occurred, please try again later.",
      });
    }
  }
  async mascaradeUser(specialist) {
    try {
      const response = await User.mascaradePatient(
        specialist,
        this.props.user.token
      );
      this.props.maskUser(response.id);
    } catch (error) {
      console.error(error);
    }
  }
  copyGuestLink() {
    copy(this.state.guestLink, {
      debug: true,
      message: "Press #{key} to copy",
    });
    Toast.displayToast({
      kind: "success",
      message: "link copied to your clipboard",
    });
  }

  showDeleteAlert(specialistId) {
    console.log("borrelo");
    this.setState({
      showDeleteConfirmation: true,
      selectedSpecialist: specialistId,
    });
  }

  deleteSpecialist() {
    const specialistId = this.state.selectedSpecialist;
    const token = this.props.user.token;
    this.props.showLoadingModal(true);
    this.setState({ showDeleteConfirmation: false });
    try {
      Doctors.softDelete(specialistId, token).then((res) => {
        this.removeUserFromSpecialists(res.id);
        this.props.showLoadingModal(false);
        Toast.displayToast({
          message: "The doctor has been deleted",
          kind: "success",
        });
      });
    } catch (error) {
      console.log(error);
      // Toast.displayToast({
      // 	message: JSON.parse(error.response).message,
      // 	kind: 'error',
      // });
    }
  }

  removeUserFromSpecialists(specialistId) {
    let newSpecialists = Object.assign({}, this.state.specialists);
    newSpecialists.data = reject(newSpecialists.data, function (sp) {
      return sp.id === specialistId;
    });
    this.setState({ specialists: newSpecialists });
  }

  onChangePassword(pass) {
    this.props.showLoadingModal(true);
    try {
      Doctors.updateDoctor(
        this.state.changePasswordSpecialistId,
        this.props.user.token,
        pass
      ).then((res) => {
        Toast.displayToast({
          message: "Password updated successfully",
          kind: "success",
        });
        this.props.showLoadingModal(false);
      });
    } catch (error) {
      console.log(error);
      Toast.displayToast({
        message:
          "Error please try again. If the error persist please contact docto support.",
        kind: "error",
      });
      this.props.showLoadingModal(false);
    }

    this.setState({ changePasswordSpecialistId: null });
  }

  linkCopied() {
    Toast.displayToast({ kind: "success", message: "Link copied" });
  }

  handleChangePassword(specialistId) {
    this.setState({ changePasswordSpecialistId: specialistId });
  }

  handleFilterChange(filter, value) {
    let { tableFilters } = this.state;
    tableFilters[filter] = value;
    tableFilters.loading = true;
    this.setState({ tableFilters });
    this.fetchSpecialists();
  }

  render() {
    const {
      tableFilters,
      loading,
      specialists,
      showDeleteConfirmation,
      guestLinkStatus,
    } = this.state;
    return (
      <div className="specialists-container">
        {this.state.showGuestLink ? (
          <div className="modal-window md-copy-url">
            <div className="modal">
              <div className="md-head">
                <a
                  onClick={() => this.setState({ showGuestLink: false })}
                  className="md-close"
                  href="#"
                >
                  <i className="icon-cross-circle"></i>
                </a>
                <h1>Guest Link</h1>
                <p>
                  {this.state.guestLinkStatus === "SUCCESS"
                    ? "Copy guest link to share and start your consultation"
                    : "Generating your guest link please wait..."}
                </p>
              </div>

              <div className="md-body">
                {guestLinkStatus === "SUCCESS" && (
                  <div className="md-copy-url-wrap">
                    <input type="text" value={this.state.guestLink} />
                    <button onClick={() => this.copyGuestLink()} type="button">
                      <i className="icon-copy"></i>
                      Copy link
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}

        {this.state.changePasswordSpecialistId && (
          <ChangePassword
            onChangePassword={(password) => this.onChangePassword(password)}
            onClose={() => this.setState({ changePasswordSpecialistId: null })}
          />
        )}

        {showDeleteConfirmation && (
          <UnboundConfirmationModal
            proceed={() => this.deleteSpecialist()}
            proceedText="Delete"
            open={showDeleteConfirmation}
          >
            Are you sure you want to delete the specialist?
          </UnboundConfirmationModal>
        )}

        <div className="admin-dash">
          <div className="doctor-list">
            <div className="admin-header">
              <h1>Specialists Accounts</h1>
              <div className="top-actions">
                <Link to={"/admin/signup-doctor"} className="btn btn-green">
                  Create Specialist
                </Link>
              </div>
            </div>

            <SpecialistsTable
              loading={loading}
              specialists={specialists}
              filters={tableFilters}
              onChangePassword={this.handleChangePassword}
              showDeleteAlert={this.showDeleteAlert}
              showGuestLink={this.showGuestLink}
              mascaradeUser={this.mascaradeUser}
              handleFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    maskUser: (token) => dispatch(mascaradeUserWithToken(token)),
    showLoadingModal: (visible) =>
      dispatch({
        type: SHOW_LOGIN_MODAL,
        show: visible,
      }),
  };
};
const mapStateToProps = ({ session, gui }) => ({
  user: session.user,
  gui,
});
export default connect(mapStateToProps, mapDispatchToProps)(SpecialistsAdmin);
