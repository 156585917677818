import React, { useState } from "react";
import moment from "moment-timezone";
import { User, Appointments, Toast } from "helpers";
import empty from "is-empty";
import Datetime from "react-datetime";
import {
  closeConfirmationModal,
  showConfirmationModal,
  showEditBookAppointmentModal,
  showLoadingModal,
} from "@modules/gui";
import { history } from "@modules/";
import { useDispatch } from "react-redux";

import Specialists from "@helpers/specialists";

const New = (props) => {
  const [dateTimeOption1, setDateTimeOption1] = useState(moment());
  const [dateTimeOption2, setDateTimeOption2] = useState(
    moment().add(1, "day")
  );
  const { appointment, role, updateBookStatus } = props;

  const validDate = (current) => {
    const yesterday = Datetime.moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  const submitPreAproval = () => {
    const payload = {
      bookStatus: "PREAPROVED-DATES",
      preApprovedDates: [dateTimeOption1, dateTimeOption2],
    };
    updateBookStatus(payload);
  };

  const renderPatientView = () => {
    return (
      <div className="book-appointment-wrap">
        <div className="book-appointment-body">
          <div className="baw-info-title">
            <h1>Consult Request Received</h1>
          </div>

          <div className="baw-info-body">
            <div className="baw-info-wrap">
              <p>
                Booking request sent to Dr{" "}
                {User.userToNameString(appointment.user)},{" "}
                {appointment.specialty.name} You will be alerted when
                appointment dates are offered. Please allow 2 working days.
              </p>

              <div className="info-block">
                <b>Doctor:</b>
                <span>{User.userToNameString(appointment.user)}</span>
              </div>
              <div className="info-block">
                <b>REFERRED BY:</b>
                <span>{appointment.referralName}</span>
              </div>
              <div className="info-block">
                <b>Medicare Number:</b>
                <span>{appointment.patient.medicareNumber || "None"}</span>
              </div>
              <br />
              <div className="info-block">
                <b>REASON:</b>
                <span>{appointment.message}</span>
              </div>
            </div>
            <div className="baw-actions"></div>
          </div>
        </div>
      </div>
    );
  };
  const renderAppoinmentOptions = () => {
    return (
      <div className="appointment-options-wrap">
        <p>
          Thanks {User.userToNameString(appointment.user)}. Please provide your
          next 2 available appointment options.
        </p>

        <strong className="aow-title">Option 1:</strong>
        <div className="aow-row">
          <i className="icon-calendar-empty"></i>

          <label className="multiline-form-box">
            <span>Select date</span>
            <Datetime
              value={dateTimeOption1}
              onChange={(value) => setDateTimeOption1(value)}
              timeFormat={false}
              dateFormat={"DD/MM/YYYY"}
              isValidDate={validDate}
            />
            <div className="caret"></div>
          </label>

          <label className="multiline-form-box">
            <span>Select time</span>
            <Datetime
              onChange={(value) => setDateTimeOption1(value)}
              value={dateTimeOption1}
              timeFormat={true}
              dateFormat={false}
              isValidDate={validDate}
            />
            <div className="caret"></div>
          </label>
        </div>

        <strong className="aow-title">Option 2:</strong>
        <div className="aow-row">
          <i className="icon-calendar-empty"></i>

          <label className="multiline-form-box">
            <span>Select date</span>
            <Datetime
              value={dateTimeOption2}
              onChange={(value) => setDateTimeOption2(value)}
              dateFormat={"DD/MM/YYYY"}
              timeFormat={false}
              isValidDate={validDate}
            />

            <div className="caret"></div>
          </label>

          <label className="multiline-form-box">
            <span>Select time</span>
            <Datetime
              onChange={(value) => setDateTimeOption2(value)}
              value={dateTimeOption2}
              timeFormat={true}
              dateFormat={false}
              isValidDate={validDate}
            />
            <div className="caret"></div>
          </label>
        </div>
        <p className="mt-20 mb-10"></p>

        <div className="aow-buttons-send">
          <button className="btn active" onClick={() => submitPreAproval()}>
            Send
          </button>
        </div>
      </div>
    );
  };
  const renderDoctorView = () => {
    return (
      <div className="book-appointment-wrap">
        <div className="book-appointment-body">
          <div className="baw-info-title">
            <h1>
              Telehealth{" "}
              {appointment.specialty
                ? appointment.specialty.name
                : "Emergency Medicine"}
            </h1>
          </div>

          <div className="baw-info-body">
            <div key="info" className="baw-info-wrap">
              <p>
                You’ve been booked! A response is needed at your earliest
                convenience.
              </p>
              <div className="info-block">
                <b>PATIENT:</b>
                <span>{User.userToNameString(appointment.patient)}</span>
              </div>
              <div className="info-block">
                <b>DOB:</b>
                <span>
                  {appointment.patient.birthday
                    ? moment(appointment.patient.birthday).format("DD/MM/YYYY")
                    : "-"}
                </span>
              </div>
              <div className="info-block">
                <b>REFERRED BY:</b>
                <span>{appointment.referralName}</span>
              </div>
              <br />
              <div className="info-block">
                <b>Medicare Number:</b>
                <span>{appointment.patient.medicareNumber || "None"}</span>
              </div>
              <div className="info-block">
                <b>ADDRESS:</b>
                <span>{User.getAddress(appointment.patient)}</span>
              </div>
              <br />
              <div className="info-block">
                <b>REASON:</b>
                <span>{appointment.message}</span>
              </div>
            </div>
            <div key="info-actions" className="baw-actions"></div>
          </div>
          {renderAppoinmentOptions()}
        </div>
      </div>
    );
  };

  //NEW, PENDING_CONFIRMATION, REQUEST_DATES, ACCEPTED, PREAPROVED-DATES, CANCELLED, EXPIRED

  switch (role) {
    case "admin":
    case "doctor":
      return renderDoctorView();
    case "patient":
      return renderPatientView();
    default:
      return null;
  }
};

const RequestDates = (props) => {
  const [dateTimeOption1, setDateTimeOption1] = useState(moment());
  const [dateTimeOption2, setDateTimeOption2] = useState(
    moment().add(1, "day")
  );
  const { appointment, updateBookStatus, role } = props;

  const validDate = (current) => {
    const yesterday = Datetime.moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };
  const submitPreAproval = () => {
    const payload = {
      bookStatus: "PREAPROVED-DATES",
      preApprovedDates: [dateTimeOption1, dateTimeOption2],
    };
    updateBookStatus(payload);
  };

  const renderPatientView = () => {
    return null;
  };
  const renderSelectAppointmentOptions = () => {
    return (
      <div className="baw-info-body">
        <div className="appointment-options-wrap">
          <p>
            Thanks {User.userToNameString(appointment.user)}. Please provide
            your next 2 available appointment options.
          </p>

          <strong className="aow-title">Option 1:</strong>
          <div className="aow-row">
            <i className="icon-calendar-empty"></i>

            <label className="multiline-form-box">
              <span>Select date</span>
              <Datetime
                value={dateTimeOption1}
                onChange={(value) => setDateTimeOption1(value)}
                dateFormat={"DD/MM/YYYY"}
                timeFormat={false}
                isValidDate={validDate}
              />
              <div className="caret"></div>
            </label>

            <label className="multiline-form-box">
              <span>Select time</span>
              <Datetime
                onChange={(value) => setDateTimeOption1(value)}
                value={dateTimeOption1}
                timeFormat={true}
                dateFormat={false}
                isValidDate={validDate}
              />
              <div className="caret"></div>
            </label>
          </div>

          <strong className="aow-title">Option 2:</strong>
          <div className="aow-row">
            <i className="icon-calendar-empty"></i>

            <label className="multiline-form-box">
              <span>Select date</span>
              <Datetime
                value={dateTimeOption2}
                onChange={(value) => setDateTimeOption2(value)}
                dateFormat={"DD/MM/YYYY"}
                timeFormat={false}
                isValidDate={validDate}
              />

              <div className="caret"></div>
            </label>

            <label className="multiline-form-box">
              <span>Select time</span>
              <Datetime
                onChange={(value) => setDateTimeOption2(value)}
                value={dateTimeOption2}
                timeFormat={true}
                dateFormat={false}
                isValidDate={validDate}
              />
              <div className="caret"></div>
            </label>
          </div>
          <p className="mt-20 mb-10"></p>

          <div className="aow-buttons-send">
            <button className="btn active" onClick={() => submitPreAproval()}>
              Send
            </button>
          </div>
        </div>
      </div>
    );
  };
  const renderDoctorView = () => {
    return (
      <div className="book-appointment-wrap">
        <div className="book-appointment-body">
          <div className="baw-info-title">
            <h1>
              Telehealth{" "}
              {appointment.specialty
                ? appointment.specialty.name
                : "Emergency Medicine"}
            </h1>
          </div>
          <p>
            New dates requested! A response is needed at your earliest
            convenience.
          </p>
          <div className="baw-info-body">
            <div className="baw-info-body">
              <div key="info" className="baw-info-wrap">
                <div className="info-block">
                  <b>PATIENT:</b>
                  <span>{User.userToNameString(appointment.patient)}</span>
                </div>
                <div className="info-block">
                  <b>DOB:</b>
                  <span>
                    {appointment.patient.birthday
                      ? moment(appointment.patient.birthday).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </span>
                </div>
                <div className="info-block">
                  <b>REFERRED BY:</b>
                  <span>{appointment.referralName}</span>
                </div>
                <br />
                <div className="info-block">
                  <b>ADDRESS:</b>
                  <span>{User.getAddress(appointment.patient)}</span>
                </div>
                <div className="info-block">
                  <b>Medicare Number:</b>
                  <span>{appointment.patient.medicareNumber || "None"}</span>
                </div>
                <br />
                <div className="info-block">
                  <b>REASON:</b>
                  <span>{appointment.message}</span>
                </div>
              </div>
            </div>
          </div>
          {renderSelectAppointmentOptions(appointment)}
        </div>
      </div>
    );
  };

  //NEW, PENDING_CONFIRMATION, REQUEST_DATES, ACCEPTED, PREAPROVED-DATES, CANCELLED, EXPIRED

  switch (role) {
    case "admin":
    case "doctor":
      return renderDoctorView();
    case "patient":
      return renderPatientView();
    default:
      return null;
  }
};

const Accepted = (props) => {
  const { appointment, role } = props;

  const renderDoctorView = () => {
    return (
      <div className="book-appointment-wrap">
        <div className="book-appointment-body">
          <div className="baw-info-body">
            <div className="baw-info-body">
              <div key="info" className="baw-info-wrap">
                <div className="info-block">
                  <b>PATIENT:</b>
                  <span>{User.userToNameString(appointment.patient)}</span>
                </div>
                {appointment.patient.guestEmail ? (
                  <div className="info-block">
                    <b>EMAIL:</b>
                    <span>{appointment.patient.guestEmail}</span>
                  </div>
                ) : null}
                <div className="info-block">
                  <b>DOB:</b>
                  <span>
                    {appointment.patient.birthday
                      ? moment(appointment.patient.birthday).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </span>
                </div>
                <div className="info-block">
                  <b>REFERRED BY:</b>
                  <span>{appointment.referralName}</span>
                </div>
                <br />
                <div className="info-block">
                  <b>ADDRESS:</b>
                  <span>{User.getAddress(appointment.patient)}</span>
                </div>
                <div className="info-block">
                  <b>Medicare Number:</b>
                  <span>{appointment.patient.medicareNumber || "None"}</span>
                </div>
                <br />
                <div className="info-block">
                  <b>REASON:</b>
                  <span>{appointment.message}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //NEW, PENDING_CONFIRMATION, REQUEST_DATES, ACCEPTED, PREAPROVED-DATES, CANCELLED, EXPIRED

  switch (role) {
    case "admin":
    case "doctor":
      return renderDoctorView(appointment);
    case "patient":
    default:
      return null;
  }
};

const AdminReview = (props) => {
  const [selectedSpecialist, setSelectedSpecialist] = useState({});
  const [allSpecialists, setAllSpecialists] = useState([]);
  const [allSpecialistsLabel, setAllSpecialistsLabel] = useState(
    "Show all specialists"
  );
  const { appointment, updateBookStatus, role } = props;
  const dispatch = useDispatch();

  const showAllSpecialists = async () => {
    try {
      const specialists = await Specialists.fetchSpecialists();
      specialists.sort(function (a, b) {
        var nameA = a.firstName.toLowerCase(),
          nameB = b.firstName.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      setAllSpecialists(specialists);
    } catch (error) {
      console.error(error);
    }
  };

  const confirmForwardSpecialist = () => {
    dispatch(showEditBookAppointmentModal({ appointmentId: appointment.id }));
    // if (empty(selectedSpecialist) && empty(appointment.user)) {
    //   Toast.displayToast({
    //     message: "Select specialist to forward",
    //     kind: "error",
    //   });
    //   return;
    // }
    // const forwardUserName = !empty(selectedSpecialist)
    //   ? User.userToNameString(selectedSpecialist)
    //   : User.userToNameString(appointment.user);
    // dispatch(
    //   showConfirmationModal({
    //     onProceed: () => forwardSpecialist(),
    //     onCancel: () => dispatch(closeConfirmationModal()),
    //     onClose: () => dispatch(closeConfirmationModal()),
    //     proceedText: "Accept",
    //     cancelText: "Cancel",
    //     children: `Are you sure you want to forward this consult to ${forwardUserName}`,
    //   })
    // );
  };

  const forwardSpecialist = () => {
    dispatch(closeConfirmationModal());
    const payload = selectedSpecialist
      ? { bookStatus: "NEW", userId: selectedSpecialist.id }
      : { bookStatus: "NEW" };
    updateBookStatus(payload);
  };
  const selectForwardSpecialist = (event, specialists) => {
    if (event.target.value === "allSpecialists") {
      setAllSpecialistsLabel("Loading...");
      showAllSpecialists();
      return false;
    }
    setSelectedSpecialist(specialists[event.target.value]);
  };

  const renderSelectSpecialist = () => {
    if (allSpecialists.length > 0) {
      return (
        <div className="baw-dropdown">
          <div className="caret"></div>
          <select
            onChange={(event) => selectForwardSpecialist(event, allSpecialists)}
            defaultValue={""}
            value={appointment?.userId}
          >
            <option value="" disabled hidden>
              Select Specialist
            </option>

            {allSpecialists.map((specialist, index) => (
              <option value={index} key={`specialist-${index}`}>
                {User.userToNameString(specialist)}
              </option>
            ))}
          </select>
        </div>
      );
    }
    return (
      <div className="baw-dropdown">
        <div className="caret"></div>
        <select
          value={appointment?.userId}
          defaultValue=""
          onChange={(event) =>
            selectForwardSpecialist(event, appointment.specialty.users)
          }
        >
          <option value="" disabled hidden>
            Select Specialist
          </option>
          {appointment.specialty.users
            ? appointment.specialty.users.map((specialist, index) => (
                <option value={index} key={`specialist-${index}`}>
                  {User.userToNameString(specialist)}
                </option>
              ))
            : null}
          <option value="allSpecialists">{allSpecialistsLabel}</option>
        </select>
      </div>
    );
  };

  const renderAdminView = () => {
    return (
      <div className="book-appointment-wrap">
        <div className="book-appointment-body">
          <div className="baw-info-title">
            <h1>
              Response Required
              <span> Telehealth Consult Request</span>
            </h1>
          </div>
          <div className="baw-info-body">
            <div className="baw-info-wrap">
              <p>
                New appointment! A response is needed at your earliest
                convenience.
              </p>
              <div className="info-block">
                <b>Patient:</b>
                <span>{User.userToNameString(appointment.patient)}</span>
              </div>
              <div className="info-block">
                <b>DOB:</b>
                <span>
                  {appointment.patient.birthday
                    ? moment(appointment.patient.birthday).format("DD/MM/YYYY")
                    : "-"}
                </span>
              </div>
              <div className="info-block">
                <b>SPECIALTY:</b>
                <span>{appointment.specialty.name}</span>
              </div>
              <br />
              <div className="info-block">
                <b>ADDRESS:</b>
                <span>{User.getAddress(appointment.patient)}</span>
              </div>
              <div className="info-block">
                <b>Medicare Number:</b>
                <span>{appointment.patient.medicareNumber || "None"}</span>
              </div>
              <br />
              <div className="info-block">
                <b>REFERRED BY:</b>
                <span>{appointment.referralName}</span>
              </div>
              <div className="info-block">
                <b>REFERRAL EMAIL:</b>
                <span>{appointment.referralEmail}</span>
              </div>
              <div className="info-block">
                <b>REFERRAL PAYMENT DATE:</b>
                <span>
                  {appointment.referralPaymentDate
                    ? moment(appointment.referralPaymentDate).format(
                        "DD/MM/YYYY"
                      )
                    : "None"}
                </span>
              </div>
              <div className="info-block">
                <b>REFERRAL PRACTICE:</b>
                <span>{appointment.referralPractice}</span>
              </div>

              <br />
              <div className="info-block">
                <b>REASON:</b>
                <span>{appointment.message}</span>
              </div>
            </div>
            <div className="baw-actions">
              <a className="btn" onClick={() => confirmForwardSpecialist()}>
                Forward to Specialist
              </a>
              <a
                className="btn grey"
                rel="noreferrer"
                target="_blank"
                href={`mailto:${appointment.patient.email}`}
              >
                Email patient
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //NEW, PENDING_CONFIRMATION, REQUEST_DATES, ACCEPTED, PREAPROVED-DATES, CANCELLED, EXPIRED

  switch (role) {
    case "admin":
      return renderAdminView(appointment);
    case "patient":
    case "doctor":
    default:
      return null;
  }
};

const Expired = ({ appointment, role }) => {
  const renderPatientView = () => {
    switch (appointment.cancelledBy) {
      case "patient":
      default:
        return (
          <div className="book-appointment-wrap">
            <div className="book-appointment-body">
              <div className="baw-info-title">
                <h1>Consultation Expired</h1>
              </div>
              <div className="baw-info-body">
                <div className="baw-actions">
                  <a
                    className="btn"
                    onClick={() => history.push(`/browse-hospital`)}
                  >
                    Other Specialties
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };
  const renderDoctorView = () => {
    return (
      <div className="book-appointment-wrap">
        <div className="book-appointment-body">
          <div className="baw-info-title">
            <h1>Consultation Expired</h1>
          </div>
        </div>
      </div>
    );
  };

  //NEW, PENDING_CONFIRMATION, REQUEST_DATES, ACCEPTED, PREAPROVED-DATES, CANCELLED, EXPIRED

  switch (role) {
    case "admin":
    case "doctor":
      return renderDoctorView();
    case "patient":
      return renderPatientView();
    default:
      return null;
  }
};

const BookStatus = (props) => {
  const { role, user, appointment, onAppointmentChange } = props;
  const bookStatus = appointment?.bookStatus;
  const dispatch = useDispatch();
  const updateBookStatus = async (payload) => {
    try {
      dispatch(showLoadingModal(true));
      const response = await Appointments.updateBookStatus(
        appointment?.id,
        user.token,
        payload
      );
      console.log({ payload, response });
      onAppointmentChange();
      dispatch(showLoadingModal(false));
    } catch (error) {
      dispatch(showLoadingModal(false));
      console.error(error);
    }
  };

  switch (bookStatus) {
    case "ADMIN-REVIEW":
      return <AdminReview {...{ appointment, updateBookStatus, role }} />;
    case "NEW":
      return <New {...{ appointment, role, updateBookStatus }} />;
    case "REQUEST-DATES":
      return <RequestDates {...{ appointment, updateBookStatus, role }} />;
    case "ACCEPTED":
      return <Accepted {...{ appointment, role }} />;
    case "EXPIRED":
      return <Expired {...{ appointment, role }} />;
    case "PREAPROVED-DATES":
    case "CANCELLED":
    case "COMPLETED":
    default:
      return null;
  }
};

export default BookStatus;
