import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { User, Doctors, Toast } from "@helpers/";
import UserProfileForm from "./form";
import Preloader from "./preloader";

function index() {
  const { id } = useParams();
  console.log(useLocation());
  const [file, setFile] = useState(null);
  const [doctor, setDoctor] = useState({});
  const user = useSelector((state) => state.session.user);

  useEffect(() => {
    const userID = id || user.id;
    User.getUserWithSpecialties(userID, user.token).then((response) => {
      const initialValues = {
        ...response,
        specialties: response.specialties.map((s) => {
          return { value: s.id, label: s.name };
        }),
        phoneNumber: response.phoneNumber
          ? `+${response.countryCode}${response.phoneNumber}`
          : "",
        practicePhoneNumber: response.practicePhoneNumber
          ? `+${response.practiceCountryCode}${response.practicePhoneNumber}`
          : "",
      };
      setDoctor(initialValues);
    });
  }, [user]);

  const submit = (values) => {
    let formData = new FormData();
    formData.append("file", file);
    const specialtyIds = values.specialties.map((specialty) => specialty.value);
    values.specialties = specialtyIds;

    const createOrUpdateDoctor = async () => {
      try {
        const result = await Doctors.createDoctorWithImage(
          formData,
          values,
          user.token,
          doctor.id
        );
        if (result) {
          Toast.displayToast({
            type: "success",
            message: "Doctor has been updated",
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    createOrUpdateDoctor();
  };
  if (!doctor.id) return <Preloader />;

  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>Account</h1>
        {doctor && (
          <UserProfileForm
            initialValues={doctor}
            onSubmit={submit}
            setImage={setFile}
          />
        )}
      </div>
    </div>
  );
}

export default index;
