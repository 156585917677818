import React, { useState } from "react";
import EmergencyConsultAccess from "@components/emergency_consult_access";
import Helmet from "react-helmet";
import { Appointments, Toast } from "@helpers/";
import { useDispatch } from "react-redux";
import {
  closeConfirmationModal,
  showConfirmationModal,
  showLoadingModal,
} from "@modules/gui";
import { initUser as initUserSession } from "@modules/session";
import moment from "moment";
import { history } from "@modules/";

const CorporateAccess = (props) => {
  console.log("CorporateAccess");
  const { user } = props;
  const isNursingRoom = false;
  const [consultInfoParse, setConsultInfoParse] = useState({});
  const dispatch = useDispatch();

  const onJoinNow = (patient, consultInfo) => {
    setConsultInfoParse(consultInfo);
    if (user.id) {
      requestFreeEmergencyAccess(user);
      return;
    }
    createPatientWithFreeEmergencyAccess(patient);
    return;
  };
  const onInitChat = (patient, data) => {
    const { appointment, isHoldCallEnable, onHoldData } = data;
    if (isHoldCallEnable) {
      showScheduleAppointmentSuccess(onHoldData);
      return;
    }
    // setStatus("INIT_CHAT");
    history.replace(`/consult-dr-now/${appointment?.id}?accepted=true`);
    // dispatch(
    //   showChatSideBar({ user: patient, appointmentId: appointment?.id })
    // );

    return;
  };
  const gotToDashBoard = () => {
    history.replace(`/`);
    dispatch(closeConfirmationModal());
  };
  const showScheduleAppointmentSuccess = (holdCallData) => {
    const thunk = showConfirmationModal({
      onProceed: gotToDashBoard,
      proceedText: "Accept",
      children: `Thanks you. Your consultation is scheduled for ${moment(
        holdCallData?.to
      ).format(
        "hh:mm a"
      )}. You will receive an SMS alert when the doctor joins`,
      onClose: () => dispatch(closeConfirmationModal()),
    });
    dispatch(thunk);
  };

  const anErrorOccur = (error) => {
    console.error("anErrorOccur payment", error);
    //  this.setState({ showPaymentModal: false });
    const paymentError = {
      message:
        "Payment decline please update your payment method and try again",
      kind: "error",
    };
    Toast.displayToast(paymentError);
  };

  const createPatientWithFreeEmergencyAccess = async (patientData) => {
    try {
      dispatch(showLoadingModal(true));
      const response = await Appointments.createPatientWithFreeEmergencyAccess(
        patientData,
        consultInfoParse
      );
      console.log("createPatientWithFreeEmergencyAccess", response);
      let { envs, token, patient } = response;
      initUser(patient, envs, token);
      onInitChat(patient, response);
      dispatch(showLoadingModal(false));
    } catch (error) {
      console.error(error);
      dispatch(showLoadingModal(false));
      anErrorOccur(error);
    }
  };
  const requestFreeEmergencyAccess = async (user) => {
    console.log("requestFreeEmergencyAccess");
    dispatch(showLoadingModal(true));
    try {
      const response = await Appointments.requestFreeEmergencyAccess(
        user.token,
        consultInfoParse
      );
      onInitChat(user, response);
      dispatch(showLoadingModal(false));
    } catch (error) {
      dispatch(showLoadingModal(false));
      console.log(error);
      const paymentError = {
        message: "Unexpected Error occur",
        kind: "error",
      };
      Toast.displayToast(paymentError);
    }
  };

  const initUser = async (patient, envs, token) => {
    patient.roles = [{ name: "patient" }];
    await dispatch(initUserSession({ env: envs, user: patient, token }));
  };

  return (
    <div className="joinAsGuestWrap jagw_form">
      <Helmet>
        <title>Telehealth consultation</title>
        <meta
          name="description"
          content="Instant online medical consultation for online prescription, e-prescription, referral or certificate when your GP is not available"
        />
        <link rel="canonical" href="https://www.docto.com.au/consullt-dr-now" />
      </Helmet>
      <EmergencyConsultAccess
        {...{
          user,
          isNursingRoom,
          renderStatus: null,
          onJoinNow,
          setPrice: () => {},
        }}
      />
      <div className="jag_brand"></div>
    </div>
  );
};

export default CorporateAccess;
