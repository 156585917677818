import React from "react";
import { Route, Redirect } from "react-router-dom";
import EmergencyConsultAccess from "@containers/emergency_consult_access/nurse_access";
import Home from "@containers/home";
import DoctorAppointments from "@containers/doctor_pages/appointments";
import { useSelector } from "react-redux";
import { User } from "@helpers";
import DoctoLayout from "@layouts/doctoLayout/index";
import AppLayout from "@layouts/appLayout/index";

const LandingWrapper = ({ ...rest }) => {
  const user = useSelector((state) => state.session.user);
  const isDoctor = User.isDoctor(user);
  const isPatient = User.isPatient(user);
  const isNurse = User.isNurse(user);
  const isAdmin = User.isAdmin(user);

  if (isDoctor) {
    return (
      <Route
        exact
        render={(props) => (
          <AppLayout {...props} name={name}>
            <DoctorAppointments
              {...rest}
              {...props}
              user={user}
              {...{ name }}
            />
          </AppLayout>
        )}
      />
    );
  } else if (isNurse) {
    const name = "NursingRoom";
    return (
      <Route
        exact
        render={(props) => (
          <AppLayout {...props} name={name}>
            <EmergencyConsultAccess
              {...rest}
              {...props}
              user={user}
              {...{ name }}
            />
          </AppLayout>
        )}
      />
    );
  }
  if (isAdmin) {
    return (
      <Redirect path="/admin/emergency-doctors" to="/admin/emergency-doctors" />
    );
  }
  if (isPatient) {
    return <Redirect path="/dashboard" to="/account/dashboard" />;
  }

  return (
    <Route
      render={(props) => (
        <DoctoLayout {...props}>
          <Home {...rest} {...props} user={user} />
        </DoctoLayout>
      )}
    />
  );
};
export default LandingWrapper;
