/* eslint-disable no-unused-vars */
const STABLE_API_ROOT = "/api/v1";
const APP_VERSION = "2.4";
export default {
  // envVariables: (userId, token) =>
  //   `${STABLE_API_ROOT}/users/${userId}/env?access_token=${token}`,
  // account: `${STABLE_API_ROOT}/accounts`,
  // addRole: (userId) => `${STABLE_API_ROOT}/users/${userId}/roles`,
  // appointment: `${STABLE_API_ROOT}/appointments`,
  // userSessionTest: `${STABLE_API_ROOT}/users/tokBoxTestSession`,
  botMessage: `${STABLE_API_ROOT}/bot/message`,

  assets: {
    doctorPlaceHolder: "/assets/images/doctor_placeholder.jpg",
    // sounds: {
    //   ding: '/assets/sounds/ding.wav',
    //   bot: '/assets/sounds/beep2.wav',
    //   ring: '/assets/sounds/ring2.wav',
    // },
  },
  fetchPayments: (userId, accessToken) =>
    `${STABLE_API_ROOT}/users/${userId}/payments?accessToken=${accessToken}`,
  // getPermissions: (accessToken, userId) =>
  //   `${STABLE_API_ROOT}/users/${userId}/getPermissions?accessToken=${accessToken}`,
  getToken: (sessionId, type, accessToken) =>
    `${STABLE_API_ROOT}/appointments/getToken/${sessionId}/${type}?accessToken=${accessToken}`,
  // fetchCallPerformed: (token) =>
  //   `${STABLE_API_ROOT}/appointments?filter=%7B%22where%22%3A%7B%22endedAt%22%3A%7B%20%22neq%22%3A%20%20null%20%7D%7D%2C%20%22include%22%3A%5B%7B%22patient%22%3A%22company%22%7D%2C%22user%22%5D%7D&accessToken=${token}`,
  // fetchSpecialists: (name, specialtyId) =>
  //   `${STABLE_API_ROOT}/users/specialists?specialtyId=${specialtyId}&name=${name}`,
  // fetchSpecialistsNaked: `${STABLE_API_ROOT}/users/specialists`,
  // fetchSpecialist: (id) => `${STABLE_API_ROOT}/users/specialist?id=${id}`,
  companies: `${STABLE_API_ROOT}/companies`,
  companyWithToken: (token, id) =>
    `${STABLE_API_ROOT}/companies/${id}?accessToken=${token}`,
  nurses: `${STABLE_API_ROOT}/users/nurses`,
  getNurse: (token, id) =>
    `${STABLE_API_ROOT}/users/getNurse/${id}?accessToken=${token}`,
  nursesWithToken: (token, id) =>
    `${STABLE_API_ROOT}/users/nurses/${id}?accessToken=${token}`,
  createNurseWithToken: (token, id) =>
    `${STABLE_API_ROOT}/users/nurses?accessToken=${token}`,
  createNurses: (token) =>
    `${STABLE_API_ROOT}/users/createNurse/?accessToken=${token}`,
  companyLogo: (image) =>
    `${STABLE_API_ROOT}/containers/docto-company-logos/download/${encodeURIComponent(
      image
    )}`,
  askBot: `${STABLE_API_ROOT}/services/bot/ask`,
  // uploadCompanyLogo: (token) =>
  //   `${STABLE_API_ROOT}/containers/docto-company-logos/upload?accessToken=${token}`,
  // 	`${STABLE_API_ROOT}/containers/docto-company-logos/download/${encodeURIComponent(
  // 		image
  // 	)}`,
  // askBot: `${STABLE_API_ROOT}/services/bot/ask`,
  uploadCompanyLogo: (token) =>
    `${STABLE_API_ROOT}/containers/docto-company-logos/upload?accessToken=${token}`,
  uploadProfilePicture: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/uploadProfilePicture?accessToken=${token}`,
  // uploadProfilePic: (token) =>
  //   `${STABLE_API_ROOT}/containers/docto-profile-pictures/upload?accessToken=${token}`,

  uploadReferralDocument: (token) =>
    `${STABLE_API_ROOT}/containers/docto-referral-documents/upload?accessToken=${token}`,
  uploadAppointmentReferralDocument: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/${appointmentId}/upload-referral?accessToken=${token}`,
  uploadAppointmentReferralPublic: () =>
    `${STABLE_API_ROOT}/fileUploads/upload-public-referral`,
  // uploadHeroImage: (token, specialtyID) =>
  //   `${STABLE_API_ROOT}/specialties/${specialtyID}/upload-hero?accessToken=${token}`,
  uploadAppointmentPatientReferralDocument: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/${appointmentId}/upload-referral-patient?accessToken=${token}`,
  uploadHeroImage: (token, specialtyID) =>
    `${STABLE_API_ROOT}/specialties/${specialtyID}/upload-hero?accessToken=${token}`,

  // uploadMasterFolder: (userId, token) =>
  //   `${STABLE_API_ROOT}/fileUploads/${userId}/upload-master?accessToken=${token}`,
  // uploadExpertOpinionDocument: `${STABLE_API_ROOT}/containers/docto-expert-opinions-documents/upload`,
  // uploadChatDocument: (token) =>
  //   `${STABLE_API_ROOT}/containers/docto-chat-documents/upload?accessToken=${token}`,
  // profileLogoUrl: (image) =>
  //   `https://docto-profile-logos.s3-ap-southeast-2.amazonaws.com/${encodeURIComponent(
  //     image
  //   )}`,
  profilePicUrl: (image) =>
    `https://docto-profile-pictures.s3-ap-southeast-2.amazonaws.com/${encodeURIComponent(
      image
    )}`,
  thumbnailProfilePicUrl: (image) =>
    `https://d3v3oeitkas7cn.cloudfront.net/${encodeURIComponent(image)}`,
  chatFileUrl: (doc) =>
    `${STABLE_API_ROOT}/containers/docto-chat-documents/download/${encodeURIComponent(
      doc
    )}`,
  // uploadDoctorLogo: (token) =>
  //   `${STABLE_API_ROOT}/containers/docto-doctor-logos/upload?accessToken=${token}`,
  createDoctor: (token) =>
    `${STABLE_API_ROOT}/users/doctors/?accessToken=${token}`,
  updateDoctor: (doctorId, token) =>
    `${STABLE_API_ROOT}/users/doctors/${doctorId}?accessToken=${token}`,
  // doctorsUrl: (username, isAudioCall) =>
  //   `/room/${username}?isAudioCall=${isAudioCall}`,
  downloadReferral: (doc) =>
    `${STABLE_API_ROOT}/containers/docto-referral-documents/download/${encodeURIComponent(
      doc
    )}`,
  downloadReferralDocument: (appointmentId, fileName) =>
    `${STABLE_API_ROOT}/fileUploads/download-referral/${appointmentId}/${fileName}`,
  createPatient: `${STABLE_API_ROOT}/users/createPatient`,
  sendConfirmationSignUp: (email) =>
    `${STABLE_API_ROOT}/emails/sendConfirmationSignUp/${email}`,
  sendBotRequest: () => `${STABLE_API_ROOT}/emails/sendBotRequest`,

  downloadReferralPatient: (appointmentId, fileName) =>
    `${STABLE_API_ROOT}/fileUploads/download-referral-patient/${appointmentId}/${fileName}`,
  email: `${STABLE_API_ROOT}/emails`,
  downloadMasterFolder: (appointmentId, key) =>
    `${STABLE_API_ROOT}/fileUploads/download-master/${appointmentId}/${encodeURIComponent(
      key
    )}`,
  downloadReferralRequest: (key, token) =>
    `${STABLE_API_ROOT}/fileUploads/download-referral-request/${key}?accessToken=${token}`,
  // email: `${STABLE_API_ROOT}/emails`,
  // fetchUser: (userId, token) =>
  //   `${STABLE_API_ROOT}/users/${userId}?accessToken=${token}`,
  // loginUser: (userId, token) =>
  //   `${STABLE_API_ROOT}/users/${userId}/logInUser?accessToken=${token}&version=${APP_VERSION}`,
  // getDoctor: (username) => `${STABLE_API_ROOT}/users/${username}/getDoctor`,
  getDoctors: `${STABLE_API_ROOT}/users/getDoctors`,
  getEmergencyDoctors: (token) =>
    `${STABLE_API_ROOT}/users/getEmergencyDoctors?accessToken=${token}`,
  getAdminSpecialists: (token) =>
    `${STABLE_API_ROOT}/users/getAdminSpecialists?accessToken=${token}`,
  getPatients: () => `${STABLE_API_ROOT}/users/getPatients`,
  getEmergencyDoctor: `${STABLE_API_ROOT}/emergencyShifts/getEmergencyDoctor`,
  getEmergencyShifts: `${STABLE_API_ROOT}/emergencyShifts/getEmergencyShifts`,
  getShifts: `${STABLE_API_ROOT}/shifts`,
  getUser: (userId) => `${STABLE_API_ROOT}/users/${userId}`,
  // logoUrl: (image) =>
  //   `${STABLE_API_ROOT}/containers/docto-doctor-logos/download/${encodeURIComponent(
  //     image
  //   )}`,
  login: `${STABLE_API_ROOT}/users/login?include=user`,
  // requestPassword: `${STABLE_API_ROOT}/request-password-reset`,
  // resetPassword: (token) =>
  //   `${STABLE_API_ROOT}/reset-password?accessToken=${token}`,
  // verifyUser: (token) =>
  //   `${STABLE_API_ROOT}/users/me/password?accessToken=${token}`,
  getMessages: (token, role, page) =>
    `${STABLE_API_ROOT}/appointments/messages/${role}/${page}?accessToken=${token}`,
  getAppointmentWithMessages: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/conversation-messages?accessToken=${token}`,
  // fetchAppointments: (token, doctorId, patientId, isDoctor) =>
  // 	`${STABLE_API_ROOT}/appointments/doctor/${doctorId}/patient/${patientId}/conversation-messages?isDoctor=${isDoctor}&accessToken=${token}`,

  // openChats: (token, role) =>
  //   `${STABLE_API_ROOT}/users/me/${role}/openChats?accessToken=${token}&version=${APP_VERSION}`,
  // scheduleAppointment: (appointmentId, token) =>
  //   `${STABLE_API_ROOT}/appointments/${appointmentId}/schedule?accessToken=${token}`,
  // invoices: (token) => `${STABLE_API_ROOT}/invoices/?accessToken=${token}`,

  // inviteSpecialist: `${STABLE_API_ROOT}/specialistInvites`,
  paths: {
    root: "/",
    about: "/about",
    account: {
      index: "/account",
      profile: "/account/profile",
      appointments: "/account/appointments",
      invoices: "/account/invoices",
      paymentHistory: "/account/payment-history",
      billing: "/account/billing",
    },
    doctor_profile: "/account/doctor/profile",
    admin: "/admin",
    appointments: "/appointments",
    browseHospital: "/browse-hospital",
    businessPage: "/business",
    contact: "/contact",
    executiveTeam: "/executive-team",
    faq: "/faq",
    forgotPassword: `/request-password`,
    login: "login",
    loginFromSignup: `/?fromSignup=true`,
    logout: `/users/sign_out`,
    messages: "/messages",
    patientInfo: "/patient-info",
    press: "/press",
    pricing: "/pricing",
    privacyPolicy: "privacy-policy",
    referAPatient: "/refer-patient",
    expertOpinion: "/expert-opinion",
    seeASpecialist: "see-a-specialist",
    securePortal: "/secure-portal",
    signup: `/signup`,
    specialists: "/doctors",
    startChat: "/?startChat=true",
    consultDrNow: "/consult-dr-now",
  },
  referral: `${STABLE_API_ROOT}/referrals`,
  referralWithToken: (token) =>
    `${STABLE_API_ROOT}/referrals/?accessToken=${token}`,
  // expertOpinion: `${STABLE_API_ROOT}/expertOpinions`,
  getNotifications: (patientId, token) =>
    `${STABLE_API_ROOT}/users/${patientId}/notifications?access_token=${token}`,
  fetchAppointmentChat: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/fetchAppointmentChat/${appointmentId}?access_token=${token}`,
  // setupUrl: 'https://demo.com/dhouse',
  specialties: `${STABLE_API_ROOT}/specialties`,
  specialtiesWithToken: (token) =>
    `${STABLE_API_ROOT}/specialties?access_token=${token}`,
  specialtyWithToken: (token, specialtyId) =>
    `${STABLE_API_ROOT}/specialties/${specialtyId}?access_token=${token}`,
  // destroySpecialty: (specialtyId, token) =>
  //   `${STABLE_API_ROOT}/specialties/${specialtyId}/destroy?access_token=${token}`,
  // surveySample: `${STABLE_API_ROOT}/surveySamples`,
  // loginAuth: `${STABLE_API_ROOT}/loginTokens`,
  setSpecialtyPublic: (specialtyId, token) =>
    `${STABLE_API_ROOT}/specialties/publicUrl/${specialtyId}?access_token=${token}`,
  setSpecialtySlug: (specialtyId, token) =>
    `${STABLE_API_ROOT}/specialties/slug/${specialtyId}?access_token=${token}`,
  updateSpecialtyCMS: (specialtyId, token) =>
    `${STABLE_API_ROOT}/specialties/cms/${specialtyId}?access_token=${token}`,
  updateEmergencyDoctor: (token) =>
    `${STABLE_API_ROOT}/emergencyShifts?accessToken=${token}`,
  // user: `${STABLE_API_ROOT}/users`,
  // doctorInvoices: (doctorId, token) =>
  //   `${STABLE_API_ROOT}/users/${doctorId}/doctorInvoices?access_token=${token}`,
  // patientInvoices: (patientId, token) =>
  //   `${STABLE_API_ROOT}/users/${patientId}/patientInvoices?access_token=${token}`,
  userWithToken: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}?access_token=${token}`,
  softDeleteUser: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/softDelete?access_token=${token}`,
  blockUser: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/block?access_token=${token}`,
  getPatientAccessToken: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/accessTokens?access_token=${token}`,
  userMethodWithToken: (userId, token, method) =>
    `${STABLE_API_ROOT}/users/${userId}/${method}?access_token=${token}`,
  appointmentsWithToken: (token) =>
    `${STABLE_API_ROOT}/appointments?access_token=${token}`,
  getAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}?access_token=${token}`,
  // appointmentWithToken: (appointmentId, token) =>
  //   `${STABLE_API_ROOT}/appointments/${appointmentId}?access_token=${token}`,
  cancelAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/cancel?access_token=${token}`,
  validateAppointmentAccess: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/access?access_token=${token}`,
  // validateGuestAppointmentAccess: (appointmentId) =>
  //   `${STABLE_API_ROOT}/appointments/${appointmentId}/guestAccess`,
  // getNotClosedAppointments: (token) =>
  //   `${STABLE_API_ROOT}/appointments/getNotClosedAppointments?access_token=${token}`,
  // getPatientAppointments: (patientId, token) =>
  //   `${STABLE_API_ROOT}/appointments/patient/${patientId}?access_token=${token}`,
  // feedBackWithToken: (token) =>
  //   `${STABLE_API_ROOT}/feedbacks?access_token=${token}`,
  // doctorAppointments: (doctorId, token) =>
  //   `${STABLE_API_ROOT}/users/${doctorId}/appointments?access_token=${token}`,
  fetchSubscriptionPlans: (userId) =>
    `${STABLE_API_ROOT}/users/${userId}/plans`,
  // getGeoInfo: `/geo/`,
  // getPatientDoctorAppointmentInfo: (patientId, doctorId) =>
  //   `${STABLE_API_ROOT}/appointments/doctor/${doctorId}/patient/${patientId}`,
  // PrebookInfo: (userId, specialtyId) =>
  //   `${STABLE_API_ROOT}/users/${userId}/prebookInfo/specialty/${specialtyId}`,
  verifyMail: (mail) => `${STABLE_API_ROOT}/users/emails/valid/${mail}`,
  validateDoctoEmail: (mail) =>
    `${STABLE_API_ROOT}/users/emails/valid/docto/${mail}`,
  // validateUsername: (username) =>
  //   `${STABLE_API_ROOT}/users/username/valid/${username}`,

  updateBookStatus: (token, appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/updateBookStatus?access_token=${token}`,
  // getSlackEmergUsers: (token) =>
  //   `${STABLE_API_ROOT}/users/slackEmergUsers?access_token=${token}`,
  // uploadReferralFiles: (token, appointmentId) =>
  //   `${STABLE_API_ROOT}/appointments/${appointmentId}/uploadReferralFiles?access_token=${token}`,
  // downloadCalendar: (appointmentId, token) =>
  //   `${STABLE_API_ROOT}/appointments/${appointmentId}/calendar/download?access_token=${token}`,
  removeReferralFile: (appointmentId, referralFileName, token) =>
    `${STABLE_API_ROOT}/fileUploads/delete-referral-document/${appointmentId}/${encodeURIComponent(
      referralFileName
    )}?access_token=${token}`,
  deleteReferralPatientDocument: (appointmentId, referralFileName, token) =>
    `${STABLE_API_ROOT}/fileUploads/delete-referral-document-patient/${appointmentId}/${encodeURIComponent(
      referralFileName
    )}?access_token=${token}`,

  isMedicareElegible: (long, lat) =>
    `${STABLE_API_ROOT}/users/medicare/${long}/${lat}`,
  isMedicareElegibleWithPostalCode: (postCode) =>
    `${STABLE_API_ROOT}/users/medicare/postalCode/${postCode}`,

  savePaymentMethod: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/savePaymentMethod?access_token=${token}`,
  // processPayment: (userId, token) =>
  //   `${STABLE_API_ROOT}/users/${userId}/processPayment?access_token=${token}`,
  // emergencyAccess: (userId, token) =>
  //   `${STABLE_API_ROOT}/users/${userId}/emergencyAccess?access_token=${token}`,
  acceptAppointmentGuest: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/acceptGuest`,
  acceptAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/accept?access_token=${token}`,
  sendEmergencyCallReminder: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/sendEmergencyCallReminder?access_token=${token}`,
  getNearbyPharmacy: () => `${STABLE_API_ROOT}/twilioCalls/nearbyPharmacy`,
  findPharmacy: () => `${STABLE_API_ROOT}/twilioCalls/findPharmacy`,
  sendMagicLink: (email) => `${STABLE_API_ROOT}/emails/sendMagicLink/${email}`,
  getDetailsAppointments: (token, role) =>
    `${STABLE_API_ROOT}/appointments/details/${role}?access_token=${token}`,
  getAppointments: (token, type, role, page) =>
    `${STABLE_API_ROOT}/appointments/${type}/${role}/${page}?access_token=${token}`,
  createFreeScheduledAppointment: (token) =>
    `${STABLE_API_ROOT}/appointments/createFreeScheduledAppointment?access_token=${token}`,
  updateFreeScheduledAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/updateFreeScheduledAppointment/${appointmentId}?access_token=${token}`,
  destroyAppointmentById: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/destroy?access_token=${token}`,
  createAppointmentRequest: (token) =>
    `${STABLE_API_ROOT}/appointments/createAppointmentRequest?access_token=${token}`,
  createUserWithAppointmentRequest: () =>
    `${STABLE_API_ROOT}/users/createPatientWithAppointmentRequest`,
  createPatientWithSpecialtyRequestNoReferral: () =>
    `${STABLE_API_ROOT}/users/createPatientWithSpecialtyRequestNoReferral`,
  // getAdminAppointments: (type, filters) =>
  //   `${STABLE_API_ROOT}/appointments/admin/${type}?${filters}`,
  // createAppointmentRequest: (token) =>
  //   `${STABLE_API_ROOT}/appointments/createAppointmentRequest?access_token=${token}`,
  getAdminAppointments: (type, filters) =>
    `${STABLE_API_ROOT}/appointments/admin/${type}?${filters}`,
  // getAdminDoctorAppointments: (userId, filters) =>
  //   `${STABLE_API_ROOT}/appointments/admin/doctor/${userId}?${filters}`,
  fetchSpecialties: () => `${STABLE_API_ROOT}/specialties/public`,
  sendBotActionEmail: () => `${STABLE_API_ROOT}/emails/sendBotActionEmail`,
  fetchFilteredSpecialties: (filter) =>
    `${STABLE_API_ROOT}/specialties?${filter}`,
  fetchSpecialistsBySpecialtyId: (specialtyId) =>
    `${STABLE_API_ROOT}/specialties/${specialtyId}/users/public`,
  // fetchSpecialtyById: (specialtyId) =>
  //   `${STABLE_API_ROOT}/specialties/${specialtyId}`,
  // fetchSpecialtyBySlug: (specialtySlug) =>
  //   `${STABLE_API_ROOT}/specialties/slug/${specialtySlug}`,
  getSpecialtyInfoForUser: (specialtyId, userId) =>
    `${STABLE_API_ROOT}/specialties/${specialtyId}/specialtyInfoForUser/${userId}`,
  fetchSpecialtyById: (specialtyId) =>
    `${STABLE_API_ROOT}/specialties/${specialtyId}`,
  fetchSpecialtyBySlug: (specialtySlug) =>
    `${STABLE_API_ROOT}/specialties/slug/${specialtySlug}`,
  // getSpecialtyInfoForUser: (specialtyId, userId) =>
  //   `${STABLE_API_ROOT}/specialties/${specialtyId}/specialtyInfoForUser/${userId}`,
  // listReferralDocuments: (appointmentId, token) =>
  //   `${STABLE_API_ROOT}/fileUploads/list-referrals-files/${appointmentId}?access_token=${token}`,
  listPatientReferralDocuments: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/list-referrals-files-patient/${appointmentId}?access_token=${token}`,
  listPatientDocumentsByAppointmentId: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/list-patient-documents/${appointmentId}?access_token=${token}`,
  // listReferralDocumentsByUserId: (userId, token) =>
  //   `${STABLE_API_ROOT}/fileUploads/list-user-referrals-files/${userId}?access_token=${token}`,
  changeConsultationCost: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/changePrice?access_token=${token}`,
  getAppointmentPatientToken: (token) =>
    `${STABLE_API_ROOT}/appointments/patientToken?access_token=${token}`,
  // requestSpecialistSignUp: `${STABLE_API_ROOT}/users/requestSpecialistSignUp`,
  validateEmergencyAppointmentAccess: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/emergencyAppointment/${appointmentId}/access?access_token=${token}`,
  acceptEmergencyAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/emergencyAppointment/${appointmentId}/accept?access_token=${token}`,
  sendSmsCode: () => `${STABLE_API_ROOT}/verificationPhoneCodes/sendPinCode`,
  verifySmsCode: () =>
    `${STABLE_API_ROOT}/verificationPhoneCodes/verifyPinCode`,
  loginWithPinCode: () =>
    `${STABLE_API_ROOT}/verificationPhoneCodes/loginWithPinCode`,
  createPatientWithEmergencyAccess: () =>
    `${STABLE_API_ROOT}/users/createPatientWithEmergencyAccess`,
  companyEmergencyRequestForPatient: () =>
    `${STABLE_API_ROOT}/users/companyEmergencyRequestForPatient`,
  createPatientWithNurseEmergencyAccess: (token) =>
    `${STABLE_API_ROOT}/users/createPatientWithNurseEmergencyAccess?access_token=${token}`,
  requestNurseEmergencyAccess: (token) =>
    `${STABLE_API_ROOT}/users/requestNurseEmergencyAccess?access_token=${token}`,
  requestEmergencyAccessWithPayment: (token) =>
    `${STABLE_API_ROOT}/users/requestEmergencyAccessWithPayment?access_token=${token}`,
  requestFreeEmergencyAccess: (token) =>
    `${STABLE_API_ROOT}/users/requestFreeEmergencyAccess?access_token=${token}`,
  createPatientWithFreeEmergencyAccess: () =>
    `${STABLE_API_ROOT}/users/createPatientWithFreeEmergencyAccess`,
  fetchDoctorNotesByAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/appointments/${appointmentId}?access_token=${token}`,
  updateDoctorNote: (noteId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/${noteId}?access_token=${token}`,
  createDoctorNoteByAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/appointments/${appointmentId}/notes?access_token=${token}`,
  createScheduledAppointmentDoctorRequest: (token) =>
    `${STABLE_API_ROOT}/appointments/createScheduledAppointmentDoctorRequest?access_token=${token}`,
  getDoctorAppointmentsByDate: (userId, date, token) =>
    `${STABLE_API_ROOT}/users/${userId}/appointments/date/${date}?access_token=${token}`,
  registerFeedBack: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/registerFeedback?access_token=${token}`,
  // createScheduledAppointmentDoctorRequest: (token) =>
  //   `${STABLE_API_ROOT}/appointments/createScheduledAppointmentDoctorRequest?access_token=${token}`,
  // getDoctorAppointmentsByDate: (userId, date, token) =>
  //   `${STABLE_API_ROOT}/users/${userId}/appointments/date/${date}?access_token=${token}`,
  acceptAppointmentDate: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/accept-date?access_token=${token}`,
  requestNewDates: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/request-new-dates?access_token=${token}`,
  // registerFeedBack: (appointmentId, token) =>
  //   `${STABLE_API_ROOT}/appointments/${appointmentId}/registerFeedback?access_token=${token}`,
  logout: `${STABLE_API_ROOT}/users/logout`,
  requestMessages: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/chats?accessToken=${token}`,
  createChat: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/chat`,
  getChatTranscripts: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/chat-transcripts?accessToken=${token}`,
  endAppointment: (channel, token) =>
    `${STABLE_API_ROOT}/appointments/${channel}/end?accessToken=${token}`,
  companyFindBySlug: (slug) => `${STABLE_API_ROOT}/companies/slug/${slug}`,
  editSpecialtyAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/editSpecialtyAppointment?accessToken=${token}`,
};
