import React, { useEffect, useState } from "react";
import { User } from "@helpers";
import { Routes } from "@constants";
import ta from "time-ago";
import moment from "moment";
import userPlaceHolder from "@assets/images/patient_placeholder.jpg";
import { Appointments } from "@helpers/index";
const ConversationItem = (props) => {
  const { user, appointment, appointmentId, onConversationClick } = props;
  const recipient = User.getRecipient(appointment, user);
  let displayName = User.conversationDisplayName(recipient);
  displayName = User.isDoctor(recipient) ? `Dr ${displayName}` : displayName;
  const description = User.conversationDescription(appointment, recipient);
  const recipientImage =
    recipient && recipient.profileImage
      ? Routes.profilePicUrl(recipient.profileImage)
      : userPlaceHolder;
  const newNotificationAt = moment(appointment.newNotificationAt).unix();
  const role = user.roles[0].name;
  const lastViewON =
    appointment[`${role}LastViewOn`] == undefined
      ? 0
      : moment(appointment[`${role}LastViewOn`]).unix();

  return (
    <a
      key={appointment.id}
      href="#"
      onClick={() => onConversationClick(appointment)}
      className={
        appointmentId == appointment.id
          ? "message-preview active"
          : "message-preview"
      }
    >
      <img src={recipientImage} alt={displayName} />
      <div className="user-info">
        <i></i>
        <span>{displayName}</span>
      </div>
      {lastViewON < newNotificationAt ? (
        <strong>{appointment.bookStatusDescription}</strong>
      ) : (
        appointment.bookStatusDescription
      )}
      <br />
      <span>{description}</span>
      <em>{ta.ago(appointment.updatedAt)}</em>
    </a>
  );
};

const Conversations = (props) => {
  const { user, onClickConversation, appointmentId } = props;
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [appointments, setAppointments] = useState([]);
  const [status, setStatus] = useState("LOADING");
  const [viewMoreStatus, setViewMoreStatus] = useState("COMPLETED");
  useEffect(() => {
    fetchConversations(page);
  }, [page]);

  const fetchConversations = async (page) => {
    if (!user.id) {
      return;
    }
    const role = User.getRoleName(user);
    try {
      const response = await Appointments.getMessages(page, role, user.token);
      setAppointments((convs) => [...convs, ...response.conversations]);
      setCount(response?.count);
      setViewMoreStatus("COMPLETED");
      setStatus("COMPLETED");
    } catch (error) {
      console.error(error);
    }
  };

  const onClickConversationItem = async (appointment) => {
    onClickConversation(appointment);
    const newNotificationAt = moment(appointment.newNotificationAt).unix();
    const role = user.roles[0].name;
    const lastViewON =
      appointment[`${role}LastViewOn`] == undefined
        ? 0
        : moment(appointment[`${role}LastViewOn`]).unix();
    if (lastViewON < newNotificationAt) {
      const payload = { bookStatus: "CHECK-IN", role: role };
      try {
        const response = await Appointments.updateBookStatus(
          appointment?.id,
          user.token,
          payload
        );
        const index = appointments.findIndex((c) => {
          return c.id == appointment.id;
        });
        let updateAppointments = Object.assign([], appointments, {
          [index]: response.appointment,
        });
        setAppointments([...updateAppointments]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderLoading = () => {
    return (
      <div className="messages-list-pannel">
        <div className="scroll-wrap">
          <a className="message-preview" href="#">
            <div className="load-img">
              <div className="loader-animation">
                <div></div>
              </div>
            </div>
            <div className="user-info">
              <span>
                <div className="loader-animation">
                  <div></div>
                </div>
              </span>
            </div>
            <strong>
              <div className="loader-animation">
                <div></div>
              </div>
            </strong>
            <br />
            <span>
              <div className="loader-animation">
                <div></div>
              </div>
            </span>
          </a>
        </div>
      </div>
    );
  };
  const renderNoMessages = () => {
    return (
      <div className="messages-list-pannel">
        <div className="scroll-wrap">
          <div className="initial-message">
            <div className="im-inner">
              <div className="im-body">
                <h4>No messages</h4>
                <p>To view messages you must start a consultation</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderViewMore = () => {
    if (viewMoreStatus == "LOADING") {
      return (
        <a className="message-preview" href="#">
          <div className="load-img">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
          <div className="user-info">
            <span>
              <div className="loader-animation">
                <div></div>
              </div>
            </span>
          </div>
          <strong>
            <div className="loader-animation">
              <div></div>
            </div>
          </strong>
          <br />
          <span>
            <div className="loader-animation">
              <div></div>
            </div>
          </span>
        </a>
      );
    }
    return (
      <a
        onClick={() => clickViewMore()}
        className="message-preview mp-load-more"
      >
        <i className="icon-plus-circle"></i>
        <span>View More</span>
      </a>
    );
  };
  const clickViewMore = () => {
    setViewMoreStatus("LOADING");
    setPage((p) => p + 1);
  };

  if (status == "LOADING") {
    return renderLoading();
  }
  if (appointments.length <= 0) {
    return renderNoMessages();
  }

  return (
    <div className="messages-list-pannel">
      <div>
        <div className="scroll-wrap">
          {appointments.map((appointment) => {
            return (
              <ConversationItem
                key={appointment.id}
                appointment={appointment}
                appointmentId={appointmentId}
                user={user}
                onConversationClick={onClickConversationItem}
              />
            );
          })}
          {count > appointments.length && renderViewMore()}
        </div>
      </div>
    </div>
  );
};

export default Conversations;
