import React, { useEffect, useState } from "react";
import StepModal from "@components/modals/step_modal";
import empty from "is-empty";

const ChangePriceModal = (props) => {
  const { onChangePrice, closeModal } = props;
  const [price, setPrice] = useState(0);
  const [label, setLabel] = useState("");
  const changePrice = (price) => {
    let parseNumber = Number.parseInt(price);
    if (empty(price)) {
      parseNumber = 0;
    }
    setPrice(parseNumber);
    setLabel(parseNumber);
  };
  useEffect(() => {
    setPrice(props?.price);
    setLabel(`${props?.price} AUD`);
  }, []);

  const onFocus = () => {
    setLabel("");
  };
  const onBlur = () => {
    setLabel(`${price} AUD`);
  };

  return (
    <StepModal
      title="Change Price"
      bottomBtnClass={"btn-info"}
      onBottomBtnClick={() => onChangePrice(price)}
      onClose={() => closeModal()}
      bottomBtnText="Change Price"
    >
      <p>Please type the new price for this appointment.</p>
      <input
        onFocus={() => onFocus()}
        onBlur={() => onBlur()}
        className="input-center"
        type="tel"
        value={label}
        onChange={(ev) => changePrice(ev.target.value)}
      />
    </StepModal>
  );
};

export default ChangePriceModal;
