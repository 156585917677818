import React, { useEffect, useState } from "react";
import Conversations from "./conversations";
import Conversation from "./conversation";
import { history } from "@modules/";
import { Routes } from "@constants/";
import { useDispatch } from "react-redux";
import { showPatientMedicalRecord } from "@modules/gui";

const Messages = (props) => {
  const [showChat, setShowChat] = useState(false);
  const [standAlone, setStandAlone] = useState(false);
  const { query, user } = props;
  const { appointmentId, viewFiles, updunix } = query;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!appointmentId) {
      setShowChat(false);
      setStandAlone(false);
    }
    if (appointmentId) {
      setShowChat(true);
      setStandAlone(true);
      if (viewFiles) {
        dispatch(showPatientMedicalRecord({ show: true, appointmentId }));
      }
    }
  }, []);
  useEffect(() => {
    if (!appointmentId) {
      setShowChat(false);
      setStandAlone(false);
    }
    if (appointmentId) {
      setShowChat(true);
      setStandAlone(false);
    }
  }, [appointmentId]);

  const displayConversation = (appointment) => {
    history.push(`${Routes.paths.messages}?appointmentId=${appointment.id}`);
    setShowChat(true);
  };

  const showConversations = () => {
    console.log("showConversations");
    setShowChat(false);
  };

  const classHolder = standAlone
    ? "messages-holder stand-alone"
    : "messages-holder";
  return (
    <div className={showChat ? `${classHolder} active` : `${classHolder}`}>
      {/*conversations side box*/}
      <Conversations
        appointmentId={appointmentId}
        user={user}
        onClickConversation={(ap) => displayConversation(ap)}
      />

      <div>
        <Conversation
          {...{ appointmentId, user, showConversations, updunix }}
        />
      </div>
    </div>
  );
};

export default Messages;
