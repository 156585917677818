import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import downloadCsv from "download-csv";
import { reject } from "lodash";
import { User, DateHelpers, Toast } from "@helpers";
import StepModal from "@src/components/modals/step_modal";
import PatientTable from "./patient_table";
import { mascaradeUserWithToken } from "@modules/session";

class Patients extends React.Component {
  constructor() {
    super();
    this.state = {
      showDeleteConfirmation: false,
      showBlockConfirmation: false,
      showUnBlockConfirmation: false,
      showSoftDeleteConfirmation: false,
      userNameToDelete: "",
      userIdToDelete: "",
      filter: {
        page: 0,
        sort: "DESC",
        sortBy: "id",
        pageSize: 20,
        textFilter: "",
        patientType: "",
      },
      loading: true,
      patients: {},
    };
    this.mascaradeUser = this.mascaradeUser.bind(this);
    this.updatePatient = this.updatePatient.bind(this);
    this.showShowDeleteConfirmation = this.showShowDeleteConfirmation.bind(
      this
    );
    this.removeUserFromList = this.removeUserFromList.bind(this);
    this.showBlockConfirmation = this.showBlockConfirmation.bind(this);
    this.showUnBlockConfirmation = this.showUnBlockConfirmation.bind(this);
    this.fetchPatients = this.fetchPatients.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.setPatitentType = this.setPatitentType.bind(this);
  }
  fetchPatients() {
    this.setState({ loading: true });
    if (this.props.user) {
      try {
        const params = {
          ...this.state.filter,
          accessToken: this.props.user.token,
        };
        User.fetchPatients(params).then((res) => {
          if (res) {
            this.setState({ patients: res, loading: false });
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
  componentDidMount() {
    this.fetchPatients();
  }
  exportCSV() {
    let data = this.state.patients.data.map((patient) => {
      return {
        name: User.userToNameString(patient),
        email: patient.email,
        phone: patient.phoneNumber,
        created: patient.created
          ? DateHelpers.toDisplayDate(patient.created)
          : "",
        company: patient.company ? patient.company.name : "",
      };
    });
    let columns = {
      name: "Name",
      email: "Email",
      phone: "Phone Number",
      joined: "Joined At",
      company: "Company",
    };
    downloadCsv(data, columns, "patients.csv");
  }
  updatePatient(patient) {
    let newPatients = this.state.patients;
    const newData = newPatients.data.map(function (oldPatient) {
      if (oldPatient.id === patient.id) {
        return patient;
      }
      return oldPatient;
    });
    newPatients.data = newData;
    this.setState({ patients: newPatients });
  }
  setPatitentType(e) {
    let { filter } = this.state;
    console.log(filter);

    filter.patientType = e.currentTarget.value;
    filter.page = 0;
    this.setState({ filter });
    this.fetchPatients();
  }
  hardDelete() {
    if (this.refs.deleteInput.value === "DELETE") {
      try {
        User.hardDelete(this.state.userIdToDelete, this.props.user.token).then(
          (res) => {
            this.fetchPatients();
          }
        );
      } catch (error) {
        Toast.displayToast({
          message: JSON.parse(error.response).message,
          kind: "error",
        });
      }

      this.setState({ showDeleteConfirmationStep2: false });
    } else {
      Toast.displayToast({
        message: "Please type DELETE to confirm.",
        kind: "error",
      });
    }
  }

  block(block) {
    try {
      User.block(this.state.userIdToDelete, block, this.props.user.token).then(
        (res) => {
          const response = JSON.parse(res.response);
          this.updatePatient(response);
          this.setState({
            showBlockConfirmation: false,
            showUnBlockConfirmation: false,
          });
        }
      );
    } catch (error) {
      Toast.displayToast({
        message: JSON.parse(error.response).message,
        kind: "error",
      });
    }
  }
  softDelete() {
    this.setState({ showDeleteConfirmation: false });
    User.showLoadingModal(true);
    try {
      User.softDelete(this.state.userIdToDelete, this.props.user.token).then(
        (res) => {
          User.showLoadingModal(false);
          this.removeUserFromList(res.data.id);
          Toast.displayToast({
            message: "The doctor has been deleted",
            kind: "success",
          });
        }
      );
    } catch (error) {
      User.showLoadingModal(false);
      Toast.displayToast({
        message: JSON.parse(error.response).message,
        kind: "error",
      });
    }
  }

  removeUserFromList(userId) {
    let newUsers = Object.assign({}, this.state.patients);
    newUsers.data = reject(newUsers.data, function (sp) {
      return sp.id === userId;
    });
    this.setState({ patients: newUsers });
  }

  async mascaradeUser(patient) {
    try {
      const response = await User.mascaradePatient(
        patient,
        this.props.user.token
      );
      console.log(response);
      this.props.maskUser(response.id);
    } catch (error) {
      console.error(error);
    }
  }

  showShowDeleteConfirmation(patient) {
    this.setState({
      showDeleteConfirmation: true,
      userNameToDelete: `${User.userToNameString(patient)}`,
      userIdToDelete: patient.id,
    });
  }
  showDeleteConfirmationStep2(patient) {
    this.setState({
      showDeleteConfirmationStep2: true,
      userNameToDelete: `${User.userToNameString(patient)}`,
      userIdToDelete: patient.id,
    });
  }
  showBlockConfirmation(patient) {
    this.setState({
      showBlockConfirmation: true,
      userNameToDelete: `${User.userToNameString(patient)}`,
      userIdToDelete: patient.id,
    });
  }
  showUnBlockConfirmation(patient) {
    this.setState({
      showUnBlockConfirmation: true,
      userNameToDelete: `${User.userToNameString(patient)}`,
      userIdToDelete: patient.id,
    });
  }
  handleFilterChange(filterName, value) {
    let { filter } = this.state;
    filter[filterName] = value;
    if (filterName === "textFilter") {
      filter.page = 0;
    }
    this.setState({ filter });
    this.fetchPatients();
  }
  render() {
    const { filter, patients, loading } = this.state;
    return (
      <div>
        <div className="admin-dash">
          <div className="doctor-list">
            <div className="admin-header">
              <h1>Patients Accounts</h1>
              <div className="top-actions">
                <button onClick={() => this.exportCSV()} className="btn-green">
                  Export CSV
                </button>
              </div>
            </div>
            <PatientTable
              loading={loading}
              patients={patients}
              filters={filter}
              setShowDeleteConfirmation={this.showShowDeleteConfirmation}
              unBlockPatient={this.showUnBlockConfirmation}
              blockPatient={this.showBlockConfirmation}
              mascarateUser={this.mascaradeUser}
              setPatitentType={this.setPatitentType}
              handleFilterChange={this.handleFilterChange}
            />
          </div>
        </div>

        {this.state.showBlockConfirmation && (
          <StepModal
            title="BLOCK Account?"
            bottomBtnClass={"btn-red"}
            topBtnClass={"btn-info"}
            onTopBtnClick={() => this.block(true)}
            onClose={() => this.setState({ showBlockConfirmation: false })}
            topBtnText="BLOCK ACCOUNT"
          >
            <p>
              You will BLOCK{" "}
              <strong className="capitalize">
                {this.state.userNameToDelete}{" "}
              </strong>
              . Doing this subscription and Credit Card details will be removed.
              You want to continue?
            </p>
          </StepModal>
        )}
        {this.state.showUnBlockConfirmation && (
          <StepModal
            title="UNBLOCK Account?"
            bottomBtnClass={"btn-red"}
            topBtnClass={"btn-info"}
            onTopBtnClick={() => this.block(false)}
            onClose={() => this.setState({ showUnBlockConfirmation: false })}
            topBtnText="UNBLOCK ACCOUNT"
          >
            <p>
              You will UNBLOCK{" "}
              <strong className="capitalize">
                {this.state.userNameToDelete}{" "}
              </strong>
            </p>
          </StepModal>
        )}

        {this.state.showDeleteConfirmation && (
          <StepModal
            title="Delete Account"
            bottomBtnClass={"btn-red"}
            onBottomBtnClick={() => this.softDelete()}
            onClose={() => this.setState({ showDeleteConfirmation: false })}
            bottomBtnText="Delete Account"
          >
            <p>
              Are you sure you want to delete{" "}
              <strong className="capitalize">
                {this.state.userNameToDelete}{" "}
              </strong>
              account. This will remove all the info related to this user (Call
              history, Active subscriptions, credit card information, etc).
              Please Confirm by typing DELETE.
            </p>
            <input ref="deleteInput"></input>
          </StepModal>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    maskUser: (token) => dispatch(mascaradeUserWithToken(token)),
  };
};
const mapStateToProps = ({ session }) => ({
  session,
  user: session.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(Patients);
