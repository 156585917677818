/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { User } from "@helpers";
import { Link } from "react-router-dom";
import DefaultNavigation from "@src/layouts/navigation/default";
import AdminNavigation from "@src/layouts/navigation/admin";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { SHOW_EMERGENCY_BOT } from "@modules/gui";

const Header = (props) => {
  const [showMenu, setShowMenu] = useState(true);
  const { width } = useWindowDimensions();
  const { showEmergencyBot, user } = props;

  useEffect(() => {
    setShowMenu(width > 1024);
  }, [width]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const requestChat = () => {
    showEmergencyBot({
      show: true,
      initMessage: "welcome_isConsultOnlineDoctor",
    });
  };
  const isAdmin = User.isAdmin(user);
  return (
    <header
      className="header-image site-header site-header-centered"
      role="banner"
      itemScope="itemScope"
      itemType="https://schema.org/WPHeader"
    >
      <div onClick={requestChat} className="header-contact-wrap">
        Click For Online DR
      </div>
      <div className="wrap">
        <div className="title-area">
          <p className="site-title" itemProp="headline">
            <Link to="/">Docto</Link>
          </p>
          <p className="site-description" itemProp="description">
            The world's first online hospital.
          </p>
        </div>
        <nav className="nav-primary" role="navigation">
          <div className="responsive-menu-icon" onClick={toggleMenu}>
            <i className="icon-menu-toggle"></i>
          </div>
          {isAdmin && <AdminNavigation />}
          {!isAdmin && (
            <DefaultNavigation showMenu={showMenu} setShowMenu={setShowMenu} />
          )}
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = ({ gui, session }) => ({
  user: session.user,
  gui,
});
const mapDispatchToProps = (dispatch) => {
  return {
    showEmergencyBot: ({ show, initMessage }) =>
      dispatch({
        type: SHOW_EMERGENCY_BOT,
        show,
        initMessage,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
