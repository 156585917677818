/* eslint-disable react/no-unescaped-entities */
import React from "react";
import mock from "@src/assets/images/mock-hand-picked.jpg";

function whyDocto() {
  return (
    <div className="lndng-section-mock">
      <div className="container">
        <div className="row row-flex flex-reverse">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img alt="mock hand picked docto" className="app mock" src={mock} />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-5">
            <h2>Online Doctor - How It Works</h2>
            <p>
              DOCTO is Australian's online hospital providing instant medical
              advice 24 hours/day and medical specialist video-consultations by
              appointment.
            </p>
            <p>
              Patients are matched to the most appropriate specialist in our
              national database.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default whyDocto;
