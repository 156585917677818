import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Referrals } from "@helpers";
import { Routes } from "@constants";

const ReferralView = () => {
  const [referrals, setReferrals] = useState([]);
  const user = useSelector((state) => state.session.user);
  useEffect(() => {
    if (user) {
      try {
        Referrals.fetchAll(user.token).then((response) => {
          setReferrals(response);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [user]);

  const renderHeaders = () => {
    return (
      <thead>
        <tr>
          <td>Name</td>
          <td>Email</td>
          <td>Phone</td>
          <td>Specialty</td>
          <td className="final-col">Document</td>
        </tr>
      </thead>
    );
  };

  const renderTable = () => {
    return referrals.map((referral) => (
      <tr key={referral.id}>
        <td>{referral.name}</td>
        <td>{referral.email}</td>
        <td>{referral.phone}</td>
        <td>{referral.specialty ? referral.specialty.name : "None"}</td>
        <td>
          {referral.documentPath ? (
            <a
              href={Routes.downloadReferral(referral.documentPath)}
              download={referral.documentPath.split("/")[1]}
            >
              Download
            </a>
          ) : (
            <span>Not attached</span>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <div className="meeting-container">
      <div className="admin">
        <div className="doctor-list">
          <table className="styled">
            {renderHeaders()}
            <tbody>{renderTable()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReferralView;
