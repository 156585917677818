import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { history } from "@modules/";
import empty from "is-empty";
import initials from "initials";
import { User } from "@helpers/";
import moment from "moment";
import Datetime from "react-datetime";
import MaskedInput from "react-maskedinput";
import { useEffect } from "react";
import { Appointments } from "@helpers/index";
import { useDispatch } from "react-redux";
import {
  showLoadingModal,
  showConfirmationModal,
  closeConfirmationModal,
  hidePatientSurvey,
} from "@modules/gui";

const SavingStatus = ({ savingStatus }) => {
  if (!savingStatus) {
    return null;
  }
  switch (savingStatus) {
    case "SAVING":
      return (
        <div className="saving-message message-alert-box alert-info">
          <p>Saving...</p>
        </div>
      );
    case "SAVED":
      return (
        <div className="saving-message message-alert-box alert-info">
          <p>Notes Saved</p>
          <i className="icon-check"></i>
        </div>
      );

    default:
      return null;
  }
};
const Note = ({
  appointment,
  message,
  close,
  savingStatus,
  startTimerToSaveMessage,
  setShowFollowUpAppointment,
}) => {
  const { patient } = appointment;
  const ini = initials(User.fullName(patient));

  return (
    <div className="modal-window md-sign-up">
      <div className="modal">
        <div>
          <div className="call-rate-header md-header">
            <a href="#" className="md-close" onClick={() => close()}>
              <i className="icon-cross-circle"></i>
            </a>
            <h3>Please write a note.</h3>
          </div>
          <div className="call-rate-body">
            <div className="call-rate_wrap">
              <div className="call-rate_doctor">
                <div className="doc-image">
                  <span className="initials">{ini}</span>
                </div>
                <div className="doc-info">
                  <span>{User.fullName(patient)}</span>
                </div>
              </div>
              <div className="call-rate_comment">
                <div className="commet-head">
                  <span>Leave your notes</span>
                  <SavingStatus {...{ savingStatus }} />
                </div>
                <textarea
                  className="rate-message"
                  value={message}
                  onChange={(event) =>
                    startTimerToSaveMessage(event.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="md-footer">
            <a
              className="btn btn-info"
              onClick={() => setShowFollowUpAppointment(true)}
            >
              Schedule Follow-up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

let timerInterval = null;
const PatientSurvey = (props) => {
  const [status, setStatus] = useState("LOADING");
  const [savingStatus, setSavingStatus] = useState(null);
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState(null);
  const [noteId, setNoteId] = useState(null);
  const [appointment, setAppointment] = useState({});
  const [scheduledDate, setScheduledDate] = useState(moment().add(1, "day"));
  const [showFollowUpAppointment, setShowFollowUpAppointment] = useState(false);
  const [priceLabel, setPriceLabel] = useState(null);
  const [dateCheckerStatus, setDateCheckerStatus] = useState("INIT");
  const [previousAppointments, setPreviousAppointments] = useState(null);
  const dispatch = useDispatch();
  const refPrice = useRef();

  const validDate = (current) => {
    var yesterday = Datetime.moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };
  const { appointmentId, user } = props;

  useEffect(() => {
    fetchAppointment();
  }, []);

  const fetchAppointment = async () => {
    if (!appointmentId) {
      return;
    }
    try {
      const ap = await Appointments.getAppointment(appointmentId, user.token);

      setAppointment(ap);
      let p = ap.p / 100 || 0;
      p = p / 100 - 0.2 * p;
      p = p < 0 ? 0 : p;
      setPrice(p);
      setStatus("COMPLETED");
    } catch (error) {
      console.error(error);
    }
  };
  const startTimerToSaveMessage = (m) => {
    setMessage(m);
    if (timerInterval) {
      clearInterval(timerInterval);
    }
    timerInterval = setTimeout(() => {
      upsertNote();
    }, 1000);
  };
  const updateNote = async () => {
    setSavingStatus("SAVING");
    try {
      await Appointments.updateDoctorNote(noteId, user.token, message);
      setSavingStatus("SAVED");
    } catch (error) {
      setSavingStatus(null);
      console.error(error);
    }
  };
  const upsertNote = () => {
    if (noteId) {
      updateNote(message);
      return;
    }
    saveNote(message);
  };
  const saveNote = async () => {
    if (empty(message)) {
      return;
    }
    setSavingStatus("SAVING");
    try {
      const n = await Appointments.createDoctorNoteByAppointment(
        appointmentId,
        user.token,
        message
      );
      setNoteId(n.id);
      setSavingStatus("SAVED");
    } catch (error) {
      setSavingStatus(null);
    }
  };

  const close = () => {
    dispatch(hidePatientSurvey());
  };

  const showFollowUpConfirmationModal = () => {
    const { patient } = appointment;
    console.log(patient);

    dispatch(
      showConfirmationModal({
        onProceed: () => createFollowUpAppointment(),
        onCancel: () => dispatch(closeConfirmationModal()),
        onClose: () => dispatch(closeConfirmationModal()),
        children: `New appointment for ${User.fullName(
          patient
        )} on ${scheduledDate.format("DD MMM YYYY hh:mm A")}`,
        proceedText: "Confirm",
        cancelText: "Cancel",
      })
    );
  };
  const createFollowUpAppointment = async () => {
    const { patientId } = appointment;
    const doctorId = user.id;
    const newPrice = Number.parseInt(price) || 0;
    dispatch(closeConfirmationModal());
    dispatch(showLoadingModal(true));
    try {
      const newAppointment = await Appointments.createScheduledAppointmentDoctorRequest(
        patientId,
        doctorId,
        newPrice,
        scheduledDate,
        user.token
      );
      dispatch(showLoadingModal(false));
      history.push(`/messages?appointmentId=${newAppointment.id}`);
      close();
    } catch (error) {
      dispatch(showLoadingModal(false));
      close();
      console.error(error);
    }
  };
  const checkDates = async (date) => {
    setDateCheckerStatus("LOADING");
    try {
      const response = await Appointments.getDoctorAppointmentsByDate(
        user.id,
        date.format(),
        user.token
      );
      setDateCheckerStatus("COMPLETED");
      setPreviousAppointments(response);
    } catch (error) {
      console.error(error);
    }
  };

  const scheduleDateChange = (date) => {
    setScheduledDate(date);
    checkDates(date);
  };

  const onSetPrice = (p) => {
    if (Number(p) <= 0) {
      return;
    }
    setPrice(Number(p));
    setPriceLabel(Number(p).toString());
  };
  const priceFocus = () => {
    //setPriceLabel(null);
  };
  const priceBlur = () => {
    // setPriceLabel(price);
  };
  const renderDateChecker = () => {
    switch (dateCheckerStatus) {
      case "LOADING":
        return (
          <div className="ad-pill ad-pill-input">
            <div className="rdt-span">
              <span>
                <div className="loader-animation">
                  <div></div>
                </div>
              </span>
            </div>
          </div>
        );
      case "COMPLETED":
        if (previousAppointments <= 0) {
          return (
            <div className="ad-pill ad-pill-input">
              <div className="rdt-span">
                <span>No appoiments for this day</span>
              </div>
            </div>
          );
        }
        return previousAppointments.map((ap) => {
          return (
            <div key={`ap-${ap.id}`} className="ad-pill ad-pill-input">
              <div className="rdt-span">
                <span>{User.fullName(ap.patient)}</span>
              </div>
              <div className="rdt-span">
                <span>{moment(ap.scheduledOn).format("HH:mm a")}</span>
              </div>
            </div>
          );
        });

      default:
        return null;
    }
  };
  const renderFollowAppointment = () => {
    const { patient } = appointment;
    const ini = initials(User.fullName(patient));

    return (
      <div className="modal-window md-sign-up">
        <div className="modal">
          <div>
            <div className="md-header">
              <a href="#" className="md-close" onClick={() => close()}>
                <i className="icon-cross-circle"></i>
              </a>
              <h3>Create new Appointment</h3>
            </div>
            <div className="call-rate-body">
              <div className="call-rate_wrap">
                <div className="call-rate_doctor">
                  <div className="doc-image">
                    <span className="initials">{ini}</span>
                  </div>
                  <div className="doc-info">
                    <span>{User.fullName(patient)}</span>
                  </div>
                </div>

                <div className="call-rate_date">
                  <span>Date & time</span>

                  <div className="appointments-dash-body">
                    <div className="ad-pill-group">
                      <div className="ad-pill ad-pill-input">
                        <Datetime
                          value={scheduledDate}
                          onChange={(value) => {
                            scheduleDateChange(value);
                          }}
                          timeFormat={false}
                          dateFormat={"DD/MM/YYYY"}
                          isValidDate={validDate}
                        />
                        <Datetime
                          onChange={(value) => {
                            setScheduledDate(value);
                          }}
                          value={scheduledDate}
                          timeFormat={true}
                          dateFormat={false}
                          isValidDate={validDate}
                        />
                      </div>
                      {renderDateChecker()}
                    </div>
                  </div>
                </div>
                <div className="call-rate_price">
                  <span>Price</span>
                  <div className="appointments-dash-body">
                    <div className="ad-pill ad-pill-input">
                      <div
                        onClick={() => refPrice.current.focus()}
                        className="rdt-span"
                      >
                        <span>${priceLabel} AUD</span>
                      </div>
                      <div className="rdt-span rdt">
                        <MaskedInput
                          mask="11111"
                          ref={refPrice}
                          name="price"
                          placeholderChar=" "
                          value={priceLabel}
                          onFocus={() => priceFocus()}
                          onBlur={() => priceBlur()}
                          onChange={(ev) => onSetPrice(ev.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md-footer">
              <Link
                className="btn btn-info"
                onClick={() => showFollowUpConfirmationModal()}
              >
                Create Appointment
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (status === "LOADING") {
    return null;
  }
  if (showFollowUpAppointment) {
    return renderFollowAppointment();
  }
  return (
    <Note
      {...{
        appointment,
        message,
        close,
        savingStatus,
        startTimerToSaveMessage,
        setShowFollowUpAppointment,
      }}
    />
  );
};

export default PatientSurvey;
