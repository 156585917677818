import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import ReferralUpload from "@src/components/upload_referral";
import { Specialties, FormHelpers } from "@helpers";
import Referrals from "@helpers/referrals";
import specialists from "@src/components/home/specialists";
import referPatient1 from "@assets/images/refer_patient_1.jpg";
import referPatient2 from "@assets/images/refer_patient_2.jpg";
import referPatient3 from "@assets/images/refer_patient_3.jpg";
import referPatient4 from "@assets/images/refer_patient_4.jpg";
import toastr from "toastr";

const ReferAPatient = () => {
  const [specialty, setSpecialty] = useState(null);
  const [specialist, setSpecialist] = useState();
  const [specialties, setSpecialties] = useState([]);
  const [fileException, setFileException] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [joinConsult, setJointConsult] = useState(false);
  const [formValues, setFormValues] = useState({
    file: null,
    name: "",
    email: "",
    phone: "",
    files: [],
  });

  const [error, setErrorValues] = useState({
    errorName: "",
    errorEmail: "",
    errorPhone: "",
    errorSpecialty: "",
    errorSpecialist: "",
  });

  const fetchData = async () => {
    const specialties = await Specialties.fetchSpecialties();
    setSpecialties(specialties);
    if (!specialty) {
      setSpecialty(specialties[0]);
    }
  };

  useEffect(() => {
    console.log("fethc");
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [specialty]);

  const handlePhoneChange = (value) => {
    const newValues = formValues;
    newValues.phone = value;
    setFormValues(newValues);
  };

  const handleSetSpecialist = (event) => {
    setSpecialist(event.currentTarget.value);
  };

  const handleDocumentChange = (e) => {
    e.preventDefault();
    var files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      if (file.size > 2097152) {
        formValues.files = null;
        setFormValues(formValues);
        setFileException(true);
        return;
      }
    }
    formValues.file = files;
    setFormValues(formValues);
  };

  const validateForm = () => {
    let errorName = formValues.name ? "" : "Please enter your name";
    let errorEmail = FormHelpers.validateEmail(formValues.email)
      ? ""
      : "Please enter a valid email";
    let errorPhone = formValues.phone ? "" : "Please enter your phone number";
    let errorSpecialty = specialty ? "" : "Please select a specialty";
    let errorSpecialist =
      specialist === "" && specialists.length === 0
        ? "Please select a specialist"
        : "";

    setErrorValues({
      errorName: errorName,
      errorEmail: errorEmail,
      errorPhone: errorPhone,
      errorMessage: errorSpecialty,
      errorSpecialist: errorSpecialist,
    });

    if (errorEmail) {
      formValues.email = "";
      setFormValues(formValues);
    }

    return !errorName && !errorEmail && !errorPhone;
  };

  const submit = (event) => {
    console.log("submit");
    event.preventDefault();
    const valid = validateForm();
    console.log(formValues);
    const { files } = formValues;

    if (valid && files) {
      setSubmitting(true);
      Referrals.uploadReferral(formValues);
    }
  };

  const handleError = function (err) {
    var error = JSON.parse(err.response).error;
    console.error(error);
    if (error.code === "INVALID_EMAIL") {
      return toastr.error(
        "Invalid email. Please check the email and try again"
      );
    }
    return toastr.error(error.error);
  };

  const submittingText = formValues.files ? "Uploading ..." : "Submitting ...";
  const buttonText = submitting ? submittingText : "Refer a Patient";

  return (
    <div>
      <Helmet>
        <title>Telehealth referral</title>
        <link rel="canonical" href="https://www.docto.com.au/refer-patient" />
        <meta
          name="description"
          content="DOCTO Telehealth invites General Practitioners to form a partnership with us, to provide enhanced specialist care to your patients."
        />
      </Helmet>

      {fileException && alert("Please select a file smaller than 2MB")}

      <div className="sub--hero hero--cover--search">
        <div className="wrap">
          <h1 className="hero__title">
            Refer your patient for a Medical Specialist Video-consultation
          </h1>
        </div>
      </div>

      <section>
        <div className="wrap">
          <div className="refer-wrap reverse">
            <div className="refer-col top-form">
              <div className="refer-header">
                <h3>Or send your referral by:</h3>
                <p>'Medical Objects' Docto Telehealth TD4215000QW</p>
                <br />
                <p>password protected attachment to referrals@docto.com</p>
                <br />
                <p>Fax 07 3485 0689</p>
              </div>
            </div>
            <div className="refer-col border-right top-form">
              <ReferralUpload
                showName
                showPhone
                showPractice
                showSpecialtySelector
              />
            </div>
          </div>
        </div>
      </section>

      <section className="lndng-section-white">
        <div className="wrap">
          <div className="refer-wrap align-middle reverse">
            <div className="refer-col">
              <img src={referPatient1} />
            </div>
            <div className="refer-col">
              <div className="refer-text">
                <h3>How we can help your patients</h3>
                <p>
                  We offer your patients accelerated access to a wide variety of
                  leading Australian specialists via video consultation. This
                  has obvious benefits for patients in rural or remote areas,
                  but also for patients in urban areas where mobility or other
                  issues make it difficult to see a specialist in person. If
                  your patient has an existing relationship with a specialist
                  who does not currently offer video consultation, we can
                  approach that doctor to offer the use of our secure video
                  platform at no cost to them. And if you want to utilise (or
                  just trial) video consultation with your own patients (for
                  example, nursing home patients or those with impaired
                  mobility), we can offer the same service to you free of
                  charge. We’ll even take care of the billing for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lndng-section-grey">
        <div className="wrap">
          <div className="refer-wrap align-middle">
            <div className="refer-col">
              <img src={referPatient2} />
            </div>
            <div className="refer-col">
              <div className="refer-text">
                <h3>Dual consultation</h3>
                <p>
                  We see dual consultation as the gold standard for enhancing
                  quality of patient care and communication between colleagues.
                  This model of care involves the GP and the consulting
                  specialist participating in a simultaneous video consultation
                  with the patient, with real-time discussion between the three
                  parties. The specialist receives the benefit of your in depth
                  knowledge of the patient, while you can ask questions and
                  discuss management options. The result – enhanced patient
                  advocacy and a shared understanding of the way forward,
                  without waiting for letters or reports (although those are
                  sent out in the conventional fashion as well).
                </p>
                <p>
                  Of course we also offer conventional 2-way consultation
                  between specialist and patient if you are unable to attend.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lndng-section-white">
        <div className="wrap">
          <div className="refer-wrap align-middle reverse">
            <div className="refer-col">
              <img src={referPatient3} />
            </div>
            <div className="refer-col">
              <div className="refer-text">
                <h3>What’s involved?</h3>
                <p>
                  Not much in terms of additional hardware or software. You can
                  access our video platform if you have internet access and a
                  computer or tablet with a camera and microphone. Your patient
                  can either be in your surgery or in their own home.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lndng-section-grey">
        <div className="wrap">
          <div className="refer-wrap align-middle">
            <div className="refer-col">
              <img src={referPatient4} />
            </div>
            <div className="refer-col">
              <div className="refer-text">
                <h3>Cost</h3>
                <p>
                  Medicare rebates apply for eligible patients. A gap payment
                  will also apply; this varies between specialists but is
                  typically in the order of $100-150.
                </p>
              </div>
              <div className="refer-text">
                <h3>Why should you choose DOCTO?</h3>
                <p>
                  DOCTO was created by doctors, for patients. We pioneered the
                  “online hospital” concept in Australia. And if you want to
                  contact us, you can speak directly to the doctors who created
                  and run the service. Our passion is enhancing patient care.
                  We’d be delighted to discuss our service and how we can tailor
                  it to your patient’s needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReferAPatient;
