import React, { useState, useRef, useEffect } from "react";
import Message from "./message";
import DoctoMessage from "./docto_message";
import { Picker } from "emoji-mart";
import SessionTimer from "./session_timer";
import { Routes } from "@constants/";
import { Browser, Toast } from "@helpers/";
import PatientPlaceHolder from "@assets/images/patient_placeholder.jpg";
import empty from "is-empty";
import copy from "copy-to-clipboard";
let OT;
if (typeof window !== "undefined") {
  // eslint-disable-next-line no-undef
  OT = require("@opentok/client");
}
const Header = (props) => {
  const {
    caller,
    isAppointmentDoctor,
    callerActive,
    isOnConsultationRoom,
    isCallerOnVideoCall,
    appointment,
    onMinimizeChat,
    displayCog,
    sendSmsWaitingPatient,
  } = props;
  const classHeader = () => {
    let classname = "chat-module__block__header inactive";
    if (callerActive) {
      classname = "chat-module__block__header recent";
    }
    if (isCallerOnVideoCall) {
      classname = "chat-module__block__header current";
    }
    return classname;
  };
  const headerName = () => {
    if (caller?.firstName) {
      return caller?.firstName;
    }
    return isAppointmentDoctor ? "Waiting for patient" : "Waiting for doctor";
  };
  return (
    <div className={classHeader()}>
      {!isOnConsultationRoom && (
        <i className="icon-settings-icon" onClick={() => displayCog()}></i>
      )}
      {isCallerOnVideoCall && <i className="icon-camera-bold"></i>}

      <div className="cp-minim" href="#">
        <div className="patient-status">
          <span className="ps-truncate">{headerName()} -</span>
        </div>
        <SessionTimer {...{ appointment, sendSmsWaitingPatient }} />
      </div>

      {isOnConsultationRoom && (
        <div
          className="chat-module__block__switch-btn"
          onClick={() => onMinimizeChat()}
        >
          <i className="icon-video-return"></i>
        </div>
      )}
    </div>
  );
};
const SubHeader = (props) => {
  const {
    isAppointmentDoctor,
    closeChat,
    showNotes,
    showFiles,
    requestCall,
  } = props;
  return (
    <div className="chat-module__block__sub-header">
      <div className="sh-actions">
        <a className="cp-close" onClick={() => closeChat()}>
          <i className="icon-phone-outgoing"></i>
        </a>
      </div>
      <div className="sh-panel-trigger">
        {isAppointmentDoctor && [
          <a key="shownoteschat" onClick={() => showNotes()}>
            <i className="icon-pencil5"></i>
          </a>,
          <a key="shofilesschat" onClick={() => showFiles()}>
            <i className="icon-files"></i>
          </a>,
        ]}
        {isAppointmentDoctor &&
          OT.checkSystemRequirements() && [
            <a key="requestAudioCall" onClick={() => requestCall("audio")}>
              <i className="icon-phone-lined-left"></i>
            </a>,
            <a key="requestVideoCall" onClick={() => requestCall("video")}>
              <i className="icon-camera-bold"></i>
            </a>,
          ]}
      </div>
    </div>
  );
};
const Body = (props) => {
  const {
    isAppointmentDoctor,
    messages,
    isOnConsultationRoom,
    appointment,
    displayCog,
    copyGuestLink,
    user,
    messageContainer,
  } = props;
  // let classes = {
  //   receiveFromUserClass: isAppointmentDoctor ? "bubble-blue" : "bubble-green",
  //   receiveFromDoctorClass: isAppointmentDoctor ? "bubble-green" : null,
  //   receiveFromCaregiverClass: isAppointmentDoctor ? "" : "bubble-blue",
  // };
  //const callerImage = caller?.profileImage ? caller.profileImage : "";
  const type = appointment.type.split("-")[0];

  const unsupportedBrowserMessage = () => {
    if (OT.checkSystemRequirements()) {
      return null;
    }
    if (Browser.isIOs()) {
      return (
        <div className="pre-chat-message">
          <p>
            Your browser doesn't support our video calling feature yet. To make
            a call, open Docto in latest version of safari.
          </p>
        </div>
      );
    }
    return (
      <div className="pre-chat-message">
        <p>
          Your browser doesn't support our video calling feature yet. To make a
          call, open Docto in Google Chrome, Mozilla Firefox or latest version
          of safari.
        </p>
      </div>
    );
  };
  const waitingDrMessage = () => {
    if (isOnConsultationRoom || isAppointmentDoctor) {
      return null;
    }
    return (
      <div className="pre-chat-message">
        <p>
          Please check your{" "}
          <strong>
            <a onClick={() => displayCog()}>audio and microphone</a>
          </strong>{" "}
          settings. <br /> The doctor will be with you shortly.
        </p>
      </div>
    );
  };
  const copyLinkGuestMessage = () => {
    if (!isAppointmentDoctor || type != "guest") {
      return null;
    }
    return (
      <div className="pre-chat-message">
        <p>
          Waiting for patient to join.
          <br />
          Remember to share the link{" "}
          <strong>
            <a onClick={() => copyGuestLink()}>[Copy Link]</a>
          </strong>{" "}
          with your patient.
        </p>
      </div>
    );
  };
  const chatEndedMessage = () => {
    if (!["CLOSED_BY_PATIENT", "CLOSED"].includes(appointment?.status)) {
      return null;
    }
    return appointment?.status === "CLOSED_BY_PATIENT" ? (
      <div className="pre-chat-message">
        <p>Chat closed by patient.</p>
      </div>
    ) : (
      <div className="pre-chat-message">
        <p>Chat closed by doctor.</p>
      </div>
    );
  };
  return (
    <div className="chat-module__block__body">
      <div ref={messageContainer} className="chat-module__block__scroll">
        <div className="chat-module__block__scroll__inner">
          {copyLinkGuestMessage()}
          {unsupportedBrowserMessage()}
          {waitingDrMessage()}
          {messages.map((message, index) => {
            const key = `${message.timestamp}plus${index}`;
            return message.sender === "Docto" ? (
              <DoctoMessage
                key={key}
                {...message}
                user={user}
                appointment={appointment}
              />
            ) : (
              <Message key={key} {...message} user={user} />
            );
          })}
          {chatEndedMessage()}
          <div className="pre-chat-message">
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
};
const Footer = (props) => {
  const [emojiActive, setEmojiActive] = useState(false);
  const {
    appointment,
    message,
    chatInputRef,
    onMessageChange,
    sendFile,
    senderWriting,
    caller,
    trySubmit,
    sendMessage,
    messageContainer,
  } = props;
  const addEmoji = (emoji) => {
    console.log("addEmoji", emoji);
    setEmojiActive(false);
    onMessageChange(`${message}${emoji.native}`);
  };
  const onMessageEvent = (ev) => {
    const value = ev.target.value;
    onMessageChange(value);
  };

  if (["CLOSED_BY_PATIENT", "CLOSED"].includes(appointment?.status)) {
    return null;
  }
  return (
    <div className="chat-module__block__chat-box">
      <pre>{message}</pre>
      <textarea
        name="chatTextMessage"
        autoComplete="nope"
        className="cb-textbox"
        ref={chatInputRef}
        placeholder="Type a message…"
        rows="1"
        onKeyPress={(ev) => trySubmit(ev)}
        onChange={(ev) => onMessageEvent(ev)}
        value={message}
      ></textarea>
      ,
      <a className="cb-submit-message" onClick={() => sendMessage()}>
        <i className="icon-send-message"></i>
      </a>
      <div className="cb-actions">
        <input
          id={`fileSelector-${appointment.id}`}
          type="file"
          onChange={(event) => sendFile(event)}
          hidden
        />
        <a
          href="#"
          onClick={() =>
            document.getElementById(`fileSelector-${appointment.id}`).click()
          }
        >
          <i className="icon-attachment-lined"></i>
        </a>
        <a
          className="emoji-toggle"
          onClick={() => setEmojiActive(!emojiActive)}
        >
          <i className="icon-emoji-lined"></i>
        </a>
        <div
          className={emojiActive ? "chat-emoji-wrap active" : "chat-emoji-wrap"}
        >
          <div className="chat-emoji-popover">
            <Picker
              style={{
                width: "260px",
                height: "inherit",
                position: "absolute",
                bottom: "0",
                right: "0",
                overflow: "hidden",
              }}
              onClick={(emoji, ev) => addEmoji(emoji, ev)}
            />
          </div>
        </div>
      </div>
      {senderWriting ? (
        <div className="status-message">
          <span>{`${caller?.firstName} is typing ...`}</span>
        </div>
      ) : null}
    </div>
  );
};
let timerInterval = null;
const Chat = (props) => {
  const {
    requestCall,
    showConfirmEnConsultationRequest,
    showConfirmEndConsultation,
    confirmHangUp,
    user,
    isCallerOnVideoCall,
    callerActive,
    isOnConsultationRoom,
    appointment,
    caller,
    isAppointmentDoctor,
    senderWriting,
    onMinimizeChat,
    messages,
    onUserTyping,
    onSendMessage,
    onSendFile,
    showNotes,
    showFiles,
    displayCog,
  } = props;
  const [message, setMessage] = useState("");
  const messageContainer = useRef();
  const chatInputRef = useRef();
  useEffect(() => {
    scrollDown();
  }, [messages.length]);
  const profilePictureStyle = () => {
    let backgroundImage = `url(${PatientPlaceHolder})`;
    if (caller?.profileImage) {
      backgroundImage = `url(${Routes.profilePicUrl(caller?.profileImage)})`;
    }
    return { backgroundImage };
  };

  const closeChat = () => {
    if (["CLOSED_BY_PATIENT", "CLOSED"].includes(appointment.status)) {
      return confirmHangUp();
    }
    if (appointment.status === "EMERGENCY_REQUESTED") {
      return showConfirmEnConsultationRequest();
    }
    return showConfirmEndConsultation();
  };

  const onMessageChange = (value) => {
    clearTimeout(timerInterval);
    onUserTyping(true);
    timerInterval = setTimeout(() => {
      onUserTyping(false);
    }, 2000);
    setMessage(value);
  };
  const trySubmit = (ev) => {
    if (ev.key === "Enter") {
      sendMessage();
    }
  };
  const sendMessage = () => {
    if (empty(message.trim())) {
      return;
    }
    chatInputRef.current.blur();
    onSendMessage(message);
    setMessage("");
  };
  const scrollDown = () => {
    setTimeout(() => {
      chatInputRef?.current?.focus();
      messageContainer?.current?.scrollTo(
        0,
        messageContainer.current.scrollHeight
      );
    }, 10);
  };
  const sendFile = (e) => {
    console.log("sendFile");
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    onSendFile(file);
  };
  const copyGuestLink = () => {
    console.log("copyGuestLink");
    const appointmentLink = `${window.location.origin}/room/guest/${appointment.id}`;
    copy(appointmentLink, {
      debug: true,
      message: "Press #{key} to copy",
    });
    Toast.displayToast({
      kind: "success",
      message: "link copied to your clipboard",
    });
  };

  return (
    <div className="chat-module__block active">
      <Header
        {...{
          caller,
          isAppointmentDoctor,
          callerActive,
          isOnConsultationRoom,
          isCallerOnVideoCall,
          appointment,
          onMinimizeChat,
          displayCog,
        }}
      />
      <SubHeader
        {...{
          isAppointmentDoctor,
          closeChat,
          showNotes,
          showFiles,
          requestCall,
        }}
      />
      <Body
        {...{
          isAppointmentDoctor,
          messages,
          isOnConsultationRoom,
          appointment,
          displayCog,
          copyGuestLink,
          user,
          messageContainer,
        }}
      />
      <Footer
        {...{
          appointment,
          message,
          chatInputRef,
          onMessageChange,
          sendFile,
          senderWriting,
          caller,
          trySubmit,
          sendMessage,
          messageContainer,
        }}
      />
    </div>
  );
};

export default Chat;
