import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Nurses, Toast } from "@helpers";
import Preloader from "../preloader/account";
import Form from "@src/containers/admin/nurses/form";
import powerdByImg from "@assets/images/vidBox-brand.png";

const EditNurse = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [nurse, setNurse] = useState({});
  const { id } = useParams();
  const user = useSelector((state) => state.session.user);

  const fetchNurse = async () => {
    try {
      const response = await Nurses.getNurse(user.token, id);
      const { firstName, lastName, email } = response;
      setNurse({ firstName, lastName, email, id });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNurse();
  }, [user]);

  const submit = async (values) => {
    async function editNurse() {
      setLoading(true);
      try {
        const result = await Nurses.editNurse(id, values, user.token);
        console.log(result);
        setLoading(false);
        Toast.displayToast({
          type: "success",
          message: "Nurse has been updated",
        });
        return history.push("/admin/emergency-room-manager");
      } catch (error) {
        setLoading(false);
        Toast.displayToast({
          type: "error",
          message: "Something Went Wrong",
        });
      }
    }
    editNurse();
  };

  if (loading) return <Preloader title="Edit Nurse" />;
  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>Edit Nurse</h1>
        <div className="header-lined"></div>
        <Form initialValues={nurse} onSubmit={submit} />
        <div className="vid-brand">
          <span>Powered By:</span>
          <img src={powerdByImg} />
        </div>
      </div>
    </div>
  );
};

export default EditNurse;
