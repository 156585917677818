import React from "react";
import { Link } from "react-router-dom";
import Routes from "@src/constants/routes";
import { useDispatch } from "react-redux";
import { SHOW_EMERGENCY_BOT } from "@modules/gui";

function ServicesMenu() {
  const dispatch = useDispatch();

  const serviceAction = () => {
    return dispatch({
      type: SHOW_EMERGENCY_BOT,
      show: true,
      initMessage: "welcome_isConsultOnlineDoctor",
    });
  };

  const hideInMobile = () => {
    console.log("hideInMobile");
  };
  return (
    <li className="menu-item menu-item-type-post_type menu-item-object-page">
      <Link to="#">Services</Link>
      <ul className="sub-menu">
        <li className="menu-item menu-item-type-post_type menu-item-object-page ">
          <Link to="#" onClick={serviceAction}>
            Consult Dr Now
          </Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page ">
          <Link to="#" onClick={serviceAction}>
            Medical Report
          </Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page ">
          <Link to="/consult-dr-now">Sickness certificate</Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page ">
          <Link to="#" onClick={serviceAction}>
            Prescription
          </Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page ">
          <Link to="/consult-dr-now">X-ray and pathology referrals</Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page ">
          <Link to="#" onClick={serviceAction}>
            Specialist referrals
          </Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page ">
          <Link to={Routes.paths.expertOpinion} onClick={hideInMobile}>
            Expert Opinion
          </Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page ">
          <Link to={Routes.paths.browseHospital} onClick={hideInMobile}>
            Consult Medical Specialist
          </Link>
        </li>

        <li className="menu-item menu-item-type-post_type menu-item-object-page ">
          <Link to={Routes.paths.referAPatient} onClick={hideInMobile}>
            Refer a Patient
          </Link>
        </li>
      </ul>
    </li>
  );
}

export default ServicesMenu;
