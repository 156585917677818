import React from "react";
import Header from "./header";

// import Loader from '@modules/loader';

const AppLayout = ({ children, theme }) => {
  return (
    <div className={`meeting-container theme-${theme}`}>
      <Header />
      {children}
    </div>
  );
};

export default AppLayout;
