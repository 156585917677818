import React, { useRef } from "react";
import { Routes } from "@constants/";
import { Form, Field } from "react-final-form";
import { InputField } from "@src/components/fields/input";
import formValidators from "@src/components/fields/field_validators";
import HospitalImage from "@assets/images/avatar-hospital.png";

function CompanyForm({ onSubmit, setImage, initialValues = {} }) {
  const previewImage = useRef();
  const { required, validURL } = formValidators;

  const imageChange = (e) => {
    var image = e.target.files[0];
    if (image) {
      setImage(image);
      var reader = new FileReader();
      reader.onload = function (e) {
        previewImage.current.src = e.target.result;
      };
      reader.readAsDataURL(image);
    }
  };
  const imgSrc =
    initialValues.imagePath && !initialValues.image
      ? Routes.companyLogo(initialValues.imagePath)
      : HospitalImage;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="profile-img">
            <img ref={previewImage} src={imgSrc} />
          </div>
          <div className="form-group">
            <label>Company Logo</label>
            <input type="file" name="file" onChange={imageChange} />
          </div>

          <InputField
            name="name"
            label="Company Name:"
            fieldProps={{ validate: required }}
          />
          <InputField
            name="slug"
            label="Url:"
            fieldProps={{ validate: validURL() }}
          />

          <InputField
            name="code"
            label="Code:"
            fieldProps={{ validate: required }}
          />

          <div className="form-group">
            <label>Unlimited Agency Access</label>
            <Field name="emergencyAccess" component="input" type="checkbox" />
          </div>

          <div className="form-group">
            <label>Trial Days:</label>
            <Field name="trialDays" component="input" type="number" />
          </div>
          <div className="buttons">
            <button
              type="submit"
              disabled={submitting}
              className={
                submitting || pristine ? "btn-large" : "btn-purple btn-large"
              }
            >
              {initialValues.id ? "Edit Company" : "Create Company"}
            </button>
          </div>
        </form>
      )}
    />
  );
}

export default CompanyForm;
