import { Appointments } from "@helpers/index";
import React, { useEffect, useState } from "react";
import Note from "./note";
import { useDispatch } from "react-redux";
import { hideDoctorNotes } from "@modules/gui";

const Loading = () => {
  return (
    <div className="note-ittem-loading">
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
      <div className="loader-animation">
        <div></div>
      </div>
    </div>
  );
};
const Notes = ({ appointmentId, user, notes, status, onNoteSave }) => {
  switch (status) {
    case "LOADING":
      return <Loading />;
    case "COMPLETED":
      return notes
        .map((note, index) => {
          return (
            <Note
              key={`note-${index}`}
              note={note}
              user={user}
              index={index}
              onSave={(data) => onNoteSave(data)}
              appointmentId={appointmentId}
            />
          );
        })
        .reverse();
    default:
      return null;
  }
};

const DoctorNotes = (props) => {
  const [status, setStatus] = useState("LOADING");
  const [notes, setNotes] = useState([]);
  const { appointmentId, user, show } = props;
  const dispatch = useDispatch();

  const fetchNotes = async () => {
    if (!appointmentId) {
      return;
    }
    setStatus("LOADING");
    try {
      const response = await Appointments.fetchDoctorNotesByAppointment(
        appointmentId,
        user.token
      );
      setNotes(response);
      setStatus("COMPLETED");
    } catch (error) {
      console.error(error);
      setNotes([]);
      setStatus("COMPLETED");
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [appointmentId]);

  const closeModal = () => {
    dispatch(hideDoctorNotes());
  };
  const addNewNote = () => {
    setNotes((notes) => [...notes, {}]);
  };
  const onNoteSave = ({ note, index }) => {
    console.log("onsave", { note, index });
    let copyNotes = notes;
    copyNotes[index] = note;
    setNotes([...copyNotes]);
  };

  return (
    <div className={show ? "notes-pannel active" : "notes-pannel"}>
      <div className="notes-pannel-inner">
        <div className="np-header">
          <h4>Notes Panel</h4>
          <a onClick={() => closeModal()}>
            <i className="icon-cross"></i>
          </a>
        </div>
        <div className="np-actions">
          <a onClick={() => addNewNote()} className="add-note">
            Add New Note
            <i className="icon-plus"></i>
          </a>
        </div>
        <div className="np-body">
          <Notes {...{ appointmentId, user, notes, status, onNoteSave }} />
        </div>
      </div>
    </div>
  );
};
export default DoctorNotes;
