import React from "react";
import moment from "moment";
import { Routes } from "@constants/";
import { User } from "@helpers/";

class Upcoming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: {
        show: false,
        proceedText: "",
        confirmText: "",
        proceedFunction: null,
      },
    };
  }

  close() {
    const { appointment } = this.props;
    this.props.onClose(appointment.id);
  }
  manage() {
    const { appointment } = this.props;
    this.props.history.push(`/messages?appointmentId=${appointment.id}`);
    console.log("manage");
  }
  join() {
    const { appointment } = this.props;
    const { user } = appointment;
    const userName = user ? user.username : "general";
    let url = `/room/${userName}/appointment/${appointment.id}`;
    if (!appointment.bookStatus) {
      url = `/appointments/emergency/${appointment.id}`;
    }

    this.props.history.push(url);
  }

  render() {
    const { user, appointment } = this.props;
    const doctor = appointment.user;
    const doctorName = doctor
      ? `${User.titleString(doctor)} ${User.fullName(doctor)}`
      : "Duty doctor";
    const date = moment(appointment.scheduledOn);

    const divStyle = doctor
      ? {
          backgroundImage:
            "url(" + Routes.profilePicUrl(doctor.profileImage) + ")",
          // eslint-disable-next-line no-mixed-spaces-and-tabs
        }
      : {};

    return (
      <div className="simple-view-cta">
        <h1>
          Welcome
          <br />
          {user.firstName}!
        </h1>

        <div className="simple-view-panel">
          <a className="sv-close" onClick={() => this.close()} href="#">
            <i className="icon-cross-circle"></i>
          </a>

          <div className="svp-header svp-header-info">
            <h2>Your Next Appt</h2>
            {appointment.specialtyId ? (
              <p>{appointment.specialty.name} consultation with</p>
            ) : (
              <p>video consultation </p>
            )}
          </div>

          <div className="svp-body">
            {doctor ? (
              <div className="svp-dr-info">
                <div className="dr-image" style={divStyle}></div>
                <p>{doctorName}</p>
              </div>
            ) : null}

            <div className="svp-date-list">
              <div className="svp-item">
                <span>{date.format("ddd Do MMM YYYY [at] h:mm a")}</span>
                <button
                  type="button"
                  onClick={() => this.manage()}
                  className="btn svp-btn-grey"
                >
                  Manage
                </button>
              </div>
            </div>
          </div>

          <div className="svp-footer">
            <button
              type="button"
              onClick={() => this.join()}
              className="svp-btn-alert"
            >
              Click to Join
            </button>
          </div>
        </div>
        <p className="small">
          By using this service you agree to our Terms of Service and Privacy
          Policy
        </p>
      </div>
    );
  }
}

export default Upcoming;
