import React from "react";
import { connect } from "react-redux";
import Login from "./login";
import EmergencyBot from "./emergency_bot";
import ConfirmationModal from "./confirmation_modal";
import SpecialtyBot from "./specialty_bot";
import AdvancePaymentModal from "./advance_payment_modal";
import PricingModal from "./pricing_modal";
import UpsertAppointmentModal from "./upsert_appointment_modal";
import UpsertReferralModal from "./upsert_referral_modal";
import LoadingSpinner from "./loading_spinner";
import ChatSidebar from "./../chat_sidebar";
import PostcodeChecker from "./postcode_medicare_check";
import DoctorNotes from "./../doctor_notes";
import MedicalRecords from "./../medical_records";
import GuestLinkModal from "@src/components/modals/guest_link_modal";
import DoctorSurvey from "./doctor_survey";
import PatientSurvey from "./patient_survey";
import CallTest from "./call_test";
import EditBookAppointentModal from "./edit_book_appointment_modal";
import DocumentViewer from "./doc_viewer";
const AppModals = (props) => {
  const { gui, user, envs, location } = props;
  console.log(gui);
  return (
    <div>
      {gui.loginModal && <Login />}
      {gui.documentViewer.show && <DocumentViewer {...gui.documentViewer} />}
      {gui.confirmationModal.show && (
        <ConfirmationModal {...gui.confirmationModal} />
      )}

      {gui.editBookAppointmentModal.show && <EditBookAppointentModal />}
      {gui.callTest && <CallTest />}
      {gui.loadingModal && <LoadingSpinner />}
      {gui.chatSideBar.show && (
        <ChatSidebar
          user={gui.chatSideBar.user}
          location={location}
          trigerLeaveCall={gui.chatSideBar.trigerLeaveCall}
          trigerEndCall={gui.chatSideBar.trigerEndCall}
          isCallerOnVideoCall={gui.chatSideBar.isCallerOnVideoCall}
          appointmentId={gui.chatSideBar.appointmentId}
          minimizeChat={gui.chatSideBar.minimizeChat}
          envs={envs}
        />
      )}

      <PricingModal />
      <PostcodeChecker />
      {gui.emergencyBot.show && (
        <EmergencyBot
          fullScreen={true}
          initMessage={gui.emergencyBot.initMessage}
        />
      )}
      {gui.specialtyBot.show && (
        <SpecialtyBot
          specialtyId={gui.specialtyBot.specialtyId}
          params={gui.specialtyBot.params}
          fullScreen={true}
          user={user}
        />
      )}

      {gui.upsertAppointmentModal.show && user && (
        <UpsertAppointmentModal user={user} {...gui.upsertAppointmentModal} />
      )}
      {gui.guestLinkModal.show && <GuestLinkModal user={user} />}

      {gui.specialtyBot.show && (
        <SpecialtyBot
          specialtyId={gui.specialtyBot.specialtyId}
          params={gui.specialtyBot.params}
          fullScreen={true}
          user={user}
        />
      )}

      {gui.patientSurvey.show && (
        <PatientSurvey
          key="patientSurvey"
          appointmentId={gui.patientSurvey.appointmentId}
          user={user}
        />
      )}
      {gui.doctorSurvey.show && (
        <DoctorSurvey
          key="doctorSurvey"
          appointmentId={gui.doctorSurvey.appointmentId}
          user={user}
        />
      )}

      {user.id && gui.showPatientMedicalRecord.show && (
        <MedicalRecords
          key="medicalRecords"
          show={gui.showPatientMedicalRecord.show}
          appointmentId={gui.showPatientMedicalRecord.appointmentId}
          user={user}
        />
      )}
      {user.id && gui.showDoctorNotes.show && (
        <DoctorNotes
          key="doctorNotes"
          show={gui.showDoctorNotes.show}
          appointmentId={gui.showDoctorNotes.appointmentId}
          user={user}
        />
      )}
      {gui.upsertReferralModal.show && (
        <UpsertReferralModal {...gui.upsertReferralModal} />
      )}
      {gui.advancePaymentModal.show && (
        <AdvancePaymentModal
          onClose={gui.advancePaymentModal?.onClose}
          onConfirm={gui.advancePaymentModal?.onConfirm}
          onSavePayment={gui.advancePaymentModal?.onSavePayment}
          price={gui.advancePaymentModal?.price}
          details={gui.advancePaymentModal?.details}
          unix={gui.advancePaymentModal?.unix}
          loading={gui.advancePaymentModal?.loading}
          navigation={gui.advancePaymentModal?.navigation}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ gui, session }) => ({
  gui,
  user: session.user,
  envs: session.env,
});
export default connect(mapStateToProps)(AppModals);
