"use strict";

import React, { useEffect, useState } from "react";
import { TokBox, Docto } from "@constants/";
import { PubNubInterface, Browser, User, Toast } from "@helpers/";
import PubNub from "pubnub";
import { history } from "@modules/";
import VideoBox from "./video_box";
import Controls from "./controls";
import Error from "./error";
import Appointments from "@helpers/appointments";
import { useDispatch, useSelector } from "react-redux";
import {
  showConfirmationModal,
  closeConfirmationModal,
  showChatSideBar,
  closeChatSideBar,
  showPatientSurvey,
  showDoctorSurvey,
} from "@modules/gui";
import moment from "@src/../node_modules/moment/moment";
import ChatSidebar from "@src/components/chat_sidebar/index";
let OT;
if (typeof window !== "undefined") {
  // eslint-disable-next-line no-undef
  OT = require("@opentok/client");
}

const Loading = () => {
  return (
    <div className="meeting-holder">
      <div className="meeting-message">
        <div className="mm-precall">
          <div className="mm-precall-circle"></div>
          <div className="mm-precall-line-mask">
            <div className="mm-precall-line"></div>
          </div>
          <div className="mm-precall-text">
            <span>loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

let timeLeftInterval = null;
let sessionTimeOut = null;
let session = null;
let publisher = null;
let sessionId = null;
let pubnub, pubNubInterface;
const timeLimit = 1;

const ConsultationRoom = (props) => {
  const { user } = props;
  const { appointmentId, audio } = props.computedMatch.params;
  const [subscriberEnter, setSubscriberEnter] = useState(false);
  const [streamPublisher, setStreamPublisher] = useState(null);
  const [status, setStatus] = useState("LOADING");
  const [timeLeft, setTimeLeft] = useState(60);
  const [appointment, setAppointment] = useState(null);
  const [publisherReady, setPublisherReady] = useState(false);
  const [error, setError] = useState(null);
  const isAudioCall = audio === "true";
  const envs = useSelector((state) => state.session.env);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAppointment();
    return unmount;
  }, []);

  useEffect(() => {
    if (publisher) {
      restartPublisher();
    }
  }, [audio]);

  const onError = (e) => {
    dispatch(showChatSideBar({ minimizeChat: true }));
    setError(e);
    return unmount;
  };

  const onTimeLimitEnd = () => {
    showIsSessionActive();
    timeLeftInterval = setInterval(() => {
      const newTime = timeLeft - 1;
      if (newTime <= 0) {
        leaveCall();
        return;
      }
      setTimeLeft(newTime);
      return;
    }, 1000);
  };

  const clearIntervals = () => {
    setTimeLeft(60);
    clearTimeout(sessionTimeOut);
    clearInterval(timeLeftInterval);
  };

  const startEndSessionInterval = () => {
    if (!User.isAppointmentDoctor(user, appointment)) {
      return;
    }
    clearIntervals();
    sessionTimeOut = setTimeout(() => {
      return onTimeLimitEnd();
    }, timeLimit * 60000);
  };

  const confirmContinueSession = () => {
    dispatch(closeConfirmationModal());
    startEndSessionInterval();
  };

  const fetchAppointment = async () => {
    // const cookies = new Cookies();
    // const token = this.props.user.token || cookies.get("guestToken");
    // if (!appointmentId || !token) {
    //   return;
    // }
    setStatus("LOADING");
    try {
      const response = await Appointments.fetchAppointmentChat(
        appointmentId,
        user.token
      );
      setStatus("COMPLETED");
      const { chats, currentUser, envs } = response;
      const ap = chats[0].appointment;

      // pubnub = new PubNub({
      //   publishKey: envs.PUB_NUB_PUBLISH_KEY,
      //   subscribeKey: envs.PUB_NUB_SUBSCRIBE_KEY,
      //   ssl: true,
      //   presenceTimeout: 120,
      //   heartbeatInterval: 30,
      //   uuid: `${currentUser.id}:${Docto.version}:${User.getRoleName(currentUser)}`,
      //   error: (error) => {
      //     console.error("PUB NUB Error:", error);
      //   },
      // });
      // pubNubInterface = new PubNubInterface(pubnub);
      setAppointment(ap);
      initCall(ap);
      dispatch(
        showChatSideBar({ user, appointmentId: ap?.id, minimizeChat: false })
      );
    } catch (error) {
      console.error(error);
      Toast.displayToast({ message: "Invalid Link", kind: "error" });
      history.replace("/");
    }
  };

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.params.audio != nextProps.params.audio) {
  //     this.restartPublisher(nextProps.params);
  //   }
  // }

  const stopStreaming = () => {
    console.log("stopStreaming");
    if (session) {
      session.off();
      session.disconnect();
      session.destroy();
      session = null;
      sessionId = null;
    }

    if (publisher) {
      publisher.off();
      publisher.destroy();
      publisher = null;
    }
  };

  const restartPublisher = () => {
    if (publisher) {
      publisher.off();
      publisher.destroy();
      publisher = null;
    }
    OT.getDevices((error /*, devices*/) => {
      if (error) {
        onError(TokBox.errors.DEVICES_NOT_FOUND);
        return;
      }

      // const cookies = new Cookies();
      // var audioSourceLabel = cookies.get('audioSourceLabel');
      // var videoSourceLabel = cookies.get('videoSourceLabel');
      let audioSource = true; //devices.find((audioDevice) => { return (audioDevice.kind == "audioInput" && audioDevice.label === audioSourceLabel) }) || true;
      let videoSource = true; //devices.find((videoDevice) => { return (videoDevice.kind == "videoInput" && videoDevice.label === videoSourceLabel) }) || true;
      videoSource = isAudioCall ? false : videoSource;
      const mainVideoOptions = TokBox.publisherOptions.mainVideo;
      const options = {
        ...mainVideoOptions,
        ...{ videoSource: videoSource, audioSource: audioSource },
      };
      setTimeout(() => {
        initPublisher(options);
      }, 0);
    });
  };

  const unmount = () => {
    setSubscriberEnter(false);
    setStreamPublisher(null);
    setPublisherReady(false);
    setAppointment(null);
    setError(null);
    dispatch(closeChatSideBar());
    clearIntervals();
    stopStreaming();
  };
  const triggerUnsupportBrowser = () => {
    if (Browser.compatibleBrowser()) {
      onError(TokBox.errors.DEVICES_NOT_FOUND);
      return;
    }
    if (Browser.isIOs()) {
      onError(TokBox.errors.UNSUPPORTED_BROWSER_IOS);
      return;
    }
    if (Browser.isAndroid()) {
      onError(TokBox.errors.UNSUPPORTED_BROWSER_ANDROID);
      return;
    }
    onError(TokBox.errors.DEVICES_NOT_FOUND);
  };

  const initCall = (appointment) => {
    if (!OT.checkSystemRequirements()) {
      triggerUnsupportBrowser();
      return;
    }
    console.log({ session });
    session = OT.initSession(envs.TOKBOX_APIKEY, appointment.sessionId);
    console.log({ session });
    bindSessionListeners(session);
    OT.getDevices((error, devices) => {
      if (error) {
        triggerUnsupportBrowser();
        return;
      }
      // const cookies = new Cookies();
      // var audioSourceLabel = cookies.get('audioSourceLabel');
      // var videoSourceLabel = cookies.get('videoSourceLabel');
      let audioSource = true; //devices.find((audioDevice) => { return (audioDevice.kind == "audioInput" && audioDevice.label === audioSourceLabel) }) || true;
      let videoSource = true; //devices.find((videoDevice) => { return (videoDevice.kind == "videoInput" && videoDevice.label === videoSourceLabel) }) || true;
      videoSource = isAudioCall === true ? false : videoSource;
      const mainVideoOptions = TokBox.publisherOptions.mainVideo;
      const options = {
        ...mainVideoOptions,
        ...{ videoSource: videoSource, audioSource: audioSource },
      };
      setTimeout(() => {
        initPublisher(options);
      }, 0);
    });
  };
  const initPublisher = (options) => {
    publisher = OT.initPublisher(TokBox.ui.mine, options, (err) =>
      onPublishCreated(err)
    );
    bindPublisherListeners(publisher);
  };

  const onPublishCreated = (err) => {
    if (err) {
      onError(TokBox.errors.DEVICES_NOT_FOUND);
      return;
    }

    connectToSession(session);
    startEndSessionInterval();
    setPublisherReady(true);
  };
  const onAccessDialogClosed = (event) => {
    console.log("onAccessDialogClosed", event);
  };
  const onAccessDialogOpened = (event) => {
    // let data = {
    //   body: 'Allow camera and microphone when prompted',
    //   channel: this.state.appointment.id,
    //   sender: "Docto",
    //   senderId: this.props.user.id,
    //   timestamp: (new Date()).getTime()
    // };
    // flux.actions.chat.append(data);
  };
  // listeners for TokBox Publisher object
  const onAccessDenied = () => {
    onError(TokBox.errors.DENIED);
  };
  const onAccessAllowed = (ev) => {
    setStatus(TokBox.statuses.CONNECTED);
    setError(null);
  };

  const bindSessionListeners = (session) => {
    session.on("sessionConnected", (e) => onSessionConnected(e));
    session.on("sessionReconnecting", (e) => onSessionReconnecting(e));
    session.on("sessionDisconnected", (e) => onSessionDisconnected(e));
    session.on("streamCreated", (e) => onStreamCreated(e));
    session.on("streamDestroyed", (e) => onStreamDestroyed(e));
    session.on("connectionCreated", (e) => onConnectionCreated(e));
    session.on("connectionDestroyed", (e) => onConnectionDestroyed(e));
  };

  const bindPublisherListeners = (publisher) => {
    publisher.on("accessDialogOpened", (e) => onAccessDialogOpened(e));
    publisher.on("accessDialogClosed", (e) => onAccessDialogClosed(e));
    publisher.on("accessAllowed", (e) => onAccessAllowed(e));
    publisher.on("accessDenied", (e) => onAccessDenied(e));
    publisher.on("streamCreated", (e) => onStreamPublisherCreated(e));
    publisher.on("streamDestroyed", (e) => onStreamDestroyedPublisher(e));
  };

  const onPublisherDestroy = (ev) => {
    setPublisherReady(false);
    connectionLost();
  };
  const connectionLost = () => {
    const thunk = showConfirmationModal({
      // eslint-disable-next-line no-self-assign
      onProceed: () => location.reload(),
      proceedText: "RESTART CALL",
      children: (
        <span>
          Server connection has failed
          <br />
          Please click below to restart your call
        </span>
      ),
      onClose: () => dispatch(closeConfirmationModal()),
    });
    dispatch(thunk);
  };
  const showIsSessionActive = () => {
    const thunk = showConfirmationModal({
      onProceed: () => confirmContinueSession(),
      proceedText: "Continue Session",
      cancelText: "Cancel",
      children: (
        <span>
          {" "}
          Is this session still active?
          <br />
          Please confirm you are still using this session, other wise it will
          end in
          <strong>{` ${timeLeft} seconds`}</strong>
        </span>
      ),
      onCancel: () => leaveCall(),
    });
    dispatch(thunk);
  };

  const onStreamPublisherCreated = (event) => {
    console.log("onStreamPublisherCreated", event);
    //const streamPublisher = event.stream;
    // this.session.subscribe(streamPublisher,
    //   null, {
    //   audioVolume: 0,
    //   testNetwork: true
    // }, (error, subscriber) => {
    //   if (error) {
    //     console.log("ERROR ON SUBSCRIBE PUBLISHER", error);
    //   }
    //   this.bindNetworkTest(subscriber);
    // });
    setStreamPublisher(event.stream);
  };

  const bindNetworkTest = (subscriber) => {
    subscriber.on("videoDisabled", (_ref2) => {
      const reason = _ref2.reason;
      console.error(reason);
      if (reason === "quality") {
        Toast.displayToast({
          message: "Server connection has failed. They are unable to see you.",
          kind: "error",
        });
      }
    });
    subscriber.on("videoDisableWarning", (_ref3) => {
      const reason = _ref3.reason;
      console.error(reason);
      Toast.displayToast({
        message: "Server connection is poor. Please seek a better network.",
        kind: "warning",
      });
    });
    subscriber.on("videoDisableWarningLifted", (_ref4) => {
      const reason = _ref4.reason;
      console.error(reason);
      Toast.displayToast({
        message: "Sufficient connection is restored.",
        kind: "info",
      });
    });
    subscriber.on("videoEnabled", (_ref5) => {
      const reason = _ref5.reason;
      console.error(reason);
      if (reason === "quality") {
        Toast.displayToast({
          message: "Sufficient connection is restored.",
          kind: "success",
        });
      }
    });
  };

  const onStreamDestroyedPublisher = (ev) => {
    ev.preventDefault();
    setStreamPublisher(null);
  };

  const connectToSession = async () => {
    if (session.connection) {
      onSessionConnected();
      return;
    }
    try {
      const response = await Appointments.getToken(
        session.sessionId,
        user.token
      );
      session.connect(response.token, (e) =>
        onSessionConnectCompleteHandler(e)
      );
    } catch (error) {
      console.error(error);
      setStatus(TokBox.statuses.DOCTO_NETWORK_ERROR);
    }
  };

  const onSessionConnectCompleteHandler = (error) => {
    if (error) {
      console.error("Failed to connect: ", error);
      if (error.name === "OT_NOT_CONNECTED") {
        setStatus(TokBox.statuses.NETWORK_ERROR);
      }
    }
  };

  const onSessionDisconnected = (event) => {
    console.log("onSessionDisconnected", event);
    connectionLost();
    dispatch(
      showChatSideBar({ minimizeChat: true, isCallerOnVideoCall: false })
    );
    // flux.actions.gui.minimizeChat(true);
  };

  // listeners for TokBox Session object related to sessions
  // See (https://tokbox.com/developer/sdks/js/reference/Session.html#events)
  const onSessionConnected = (data) => {
    console.log("SESSION CONNECTED");
    if (publisher) {
      session.publish(publisher, (err) => onPublish(err));
    }
  };

  const onPublish = (err) => {
    console.log("publishing");
    if (err) {
      console.log("onPublish Error", err);
      onError(TokBox.errors.NETWORK_ERROR);
      return;
    }
  };

  const onSessionReconnecting = () => {
    console.log("onSessionReconnecting");
  };

  // listeners for TokBox Session object related to streams
  const onStreamCreated = (ev) => {
    console.log("onStreamCreated", ev);
    const stream = ev.stream;
    const subscribeOptions = TokBox.subscribeOptions;
    let subscriber = session.subscribe(
      stream,
      subscribeOptions.id,
      subscribeOptions,
      (err) => {
        if (err) {
          console.error(err);
        }
        setSubscriberEnter(true);
        dispatch(
          showChatSideBar({ minimizeChat: true, isCallerOnVideoCall: true })
        );
      }
    );

    subscriber.on("audioBlocked", function (event) {
      console.log("audioBlocked", event);
      OT.unblockAudio();
    });
  };

  const onStreamDestroyed = (ev) => {
    dispatch(
      showChatSideBar({ minimizeChat: false, isCallerOnVideoCall: false })
    );
    setSubscriberEnter(false);
  };

  // listeners for TokBox Session object related to connections
  const onConnectionCreated = (ev) => {
    console.log("onConnectionCreated", ev);
  };

  const onConnectionDestroyed = (ev) => {
    console.log("onConnectionDestroyed", ev);
    dispatch(
      showChatSideBar({ minimizeChat: false, isCallerOnVideoCall: false })
    );
  };

  const leaveCall = () => {
    dispatch(showChatSideBar({ trigerleaveCall: moment().unix() }));
  };
  const showConfirmEndConsultation = () => {
    dispatch(showChatSideBar({ trigerEndCall: moment().unix() }));
  };

  const showMessages = () => {
    dispatch(showChatSideBar({ minimizeChat: false }));
  };
  if (status == "LOADING") {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <div>
      <div className="meeting-holder">
        <div>
          <div className="video-box-panel">
            <VideoBox
              id={TokBox.ui.mainVideo}
              extraClass="vid-full"
              expanded={true}
            />
            <VideoBox
              id={TokBox.ui.mine}
              title="You"
              extraClass={subscriberEnter ? "vid-sec" : "vid-full expand"}
            />
          </div>
          {publisherReady && (
            <Controls
              {...{
                showConfirmEndConsultation,
                showMessages,
                publisherReady,
                publisher,
                appointmentId,
                stream: streamPublisher,
                isAudioCall,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsultationRoom;
