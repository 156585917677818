import React from "react";

class ConfirmationModal extends React.Component {
  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  cancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  proceed() {
    if (this.props.onProceed) {
      this.props.onProceed();
    }
  }

  render() {
    let {
      children,
      proceedText,
      cancelText,
      onClose,
      onProceed,
      onCancel,
    } = this.props;
    children = children ? children : "Are you sure you want to proceed";
    proceedText = proceedText ? proceedText : "Proceed";
    cancelText = cancelText ? cancelText : "Cancel";

    return (
      <div className="modal-window full-z-index">
        <div className="md-align">
          <div className="modal chat-alert-modal">
            <div className="md-header">
              {onClose ? (
                <a href="#" className="md-close" onClick={() => this.close()}>
                  <i className="icon-cross-circle"></i>
                </a>
              ) : null}
            </div>

            <div className="md-body">
              <i className="icon-warning3"></i>
              <p>{children}</p>
            </div>

            <div className="md-footer">
              {onProceed ? (
                <a
                  href="#"
                  className="btn-red btn-large"
                  onClick={() => this.proceed()}
                >
                  {proceedText}
                </a>
              ) : null}

              {onCancel ? (
                <a
                  href="#"
                  className="btn-grey btn-large"
                  onClick={() => this.cancel()}
                >
                  {cancelText}
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationModal;
