import React from "react";
import { connect } from "react-redux";
import { User } from "@helpers/index";
import { SHOW_EMERGENCY_BOT } from "@modules/gui";
import ActionRequired from "./action_required";
import Upcoming from "./upcoming";

class PatientDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
    };
  }
  fetchNotifications() {
    const { user } = this.props;
    if (user) {
      try {
        User.fetchNotifications(user.id, user.token).then((response) => {
          this.setState({ appointments: response.appointments });
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  requestChat() {
    this.props.showEmergencyBot();
  }
  componentDidMount() {
    this.fetchNotifications();
  }
  browseHospital() {
    this.props.history.push(`/browse-hospital`);
  }
  renderInit() {
    const { user } = this.props;

    return (
      <div id="landing" className="simple-view-cta">
        <h1>
          Welcome
          <br />
          {user.firstName}!
        </h1>
        <button
          type="button"
          onClick={() => this.requestChat(user)}
          className="btn sv-btn-alert"
        >
          INSTANT CONSULT 24/7
        </button>
        <button
          type="button"
          className="btn sv-btn-info"
          onClick={() => this.browseHospital()}
        >
          BOOK MEDICAL SPECIALIST
        </button>
        <p className="small">
          By using this service you agree to our Terms of Service and Privacy
          Policy
        </p>
      </div>
    );
  }
  renderByBookStatus(appointment) {
    const user = this.props.user;
    const { bookStatus } = appointment;
    switch (bookStatus) {
      case "ACCEPTED":
      case "EMERGENCY-CONSULTATION":
        return (
          <Upcoming
            history={this.props.history}
            appointment={appointment}
            user={user}
            onClose={(appointmentId) => this.closeAppointment(appointmentId)}
          />
        );
      case "PREAPROVED-DATES":
        return (
          <ActionRequired
            appointment={appointment}
            user={user}
            onClose={(appointmentId) => this.closeAppointment(appointmentId)}
          />
        );

      default:
        return null;
    }
  }
  closeAppointment(appointmentId) {
    let { appointments } = this.state;
    const index = appointments.findIndex((a) => {
      return a.id === appointmentId;
    });
    appointments[index].hide = true;
    this.setState({ appointments });
  }
  render() {
    const { appointments } = this.state;
    const visibleFilter = appointments.filter((a) => {
      return !a.hide;
    });
    console.log(visibleFilter);
    return (
      <div className="simple-landing-holder">
        {visibleFilter.length > 0
          ? this.renderByBookStatus(visibleFilter[0])
          : this.renderInit()}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showEmergencyBot: () =>
      dispatch({
        type: SHOW_EMERGENCY_BOT,
        show: true,
        initMessage: "welcome_isConsultOnlineDoctor",
      }),
  };
};
const mapStateToProps = ({ session, gui }) => ({
  user: session.user,
  gui,
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientDashboard);
