/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputField } from "@src/components/fields/input";
import PhoneAdapter from "@src/components/fields/phone_adapter";
import {
  CountryDropdownField,
  RegionDropdownField,
} from "@src/components/fields/country_region_fields";
import formValidators from "@src/components/fields/field_validators";
import DateField from "components/fields/date_field";

function UserProfileForm({ onSubmit, initialValues = null }) {
  const { required } = formValidators;

  return (
    <Form
      initialValues={initialValues || {}}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="patient-account">
          <h2>Contact Information</h2>

          <div className="form-group phone">
            <label>Cell Phone Number</label>
            <Field
              component={PhoneAdapter}
              name="phoneNumber"
              smartCaret={false}
              placeholder="Enter phone number"
            />
          </div>
          <div className="form-group phone">
            <label>Country</label>
            <Field
              component={CountryDropdownField}
              name="country"
              placeholder="Country"
            />
          </div>
          <div className="form-group phone">
            <label>Region</label>
            <Field
              component={RegionDropdownField}
              name="region"
              country={values.country}
              placeholder="Country"
            />
          </div>
          <InputField
            label="Address 1:"
            name="address"
            fieldProps={{ validate: required }}
          />
          <InputField
            label="Address 2:"
            name="address2"
            fieldProps={{ validate: required }}
          />
          <InputField
            label="Postcode:"
            name="postcode"
            fieldProps={{ validate: required }}
          />
          <InputField
            label="Prefered pharmacy:"
            name="preferredPharmacy"
            fieldProps={{ validate: required }}
          />
          <Field component={DateField} label="Birthday:" name="birthday" />

          <hr className="divider" />

          <h2>Aditional Information</h2>
          <div className="form-group">
            <InputField label="GP Name:" name="gpName" />
            <InputField
              type="text"
              label="GP Practice:"
              name="gpPractice"
              maxLength="100"
            />
            <InputField
              type="text"
              label="Emergency Contact Name:"
              name="emergencyContact"
              maxLength="100"
            />
            <div className="form-group phone">
              <label>Emergency Number</label>
              <Field
                component={PhoneAdapter}
                country="AU"
                name="emergencyPhoneNumber"
                smartCaret={false}
                placeholder="Enter phone number"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="buttons">
              <button
                type="submit"
                className={
                  submitting ? "btn-submitting" : "btn-purple btn-large"
                }
              >
                UPDATE USER
              </button>
            </div>
          </div>
        </form>
      )}
    />
  );
}
export default UserProfileForm;
