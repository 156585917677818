import React from "react";
import prettyBytes from "pretty-bytes";
import moment from "moment";
import ta from "time-ago";
import Spin from "@assets/images/spin.gif";
import { Routes } from "@constants/";
import { useDispatch } from "react-redux";
import { showDocumentViewer } from "@modules/gui";

const Loading = ({ fileName }) => {
  return (
    <li className="cpl-loading">
      <div className="cpl-pdf-item">
        <em className="jpg">{fileName.split(".").pop()}</em>
        <div className="pdf-info">
          <b>{fileName}</b>
          <span></span>
        </div>
        <img className="cpl-loader" src={Spin} />
      </div>
    </li>
  );
};

const Record = (props) => {
  const { record, user, onDelete, index } = props;
  const dispatch = useDispatch();
  const {
    fileName,
    size,
    status,
    appointmentId,
    patientId,
    lastModified,
    key,
  } = record;

  const viewFile = ({ appointmentId, key }) => {
    const type = key.split(".").pop().toLowerCase();
    let path = Routes.downloadMasterFolder(appointmentId, key);
    // if (type === "pdf") {
    //   path = `https://${window.location.host}${path}?accessToken=${user.token}`;
    // }
    // window.open(path);
    dispatch(showDocumentViewer({ url: path, token: user.token, type }));

    console.log(path);
    // TODO
    // flux.actions.gui.showFileViewer(path);
  };

  switch (status) {
    case "LOADING":
      return <Loading fileName={fileName} />;
    case "DELETED":
      return null;
    case "COMPLETED":
    default:
      return (
        <li>
          <div className="cpl-pdf-item">
            <em className="jpg">{fileName.split(".").pop()}</em>
            <div
              onClick={() => viewFile({ appointmentId, key, patientId })}
              className="pdf-info"
            >
              <b>{fileName}</b>
              <span>
                {prettyBytes(size)} | {ta.ago(moment(lastModified).toDate())}
              </span>
            </div>
            <a
              onClick={() => onDelete(index, fileName)}
              href="#"
              className="cpl-delete"
            >
              DELETE
            </a>
            <i className="cpl-ready icon-checkmark-circle"></i>
          </div>
        </li>
      );
  }
};

export default Record;
