export default {
  trackConversion(data) {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            currency: "USD",
            id: data.id,
            affiliation: `Docto GA`,
            revenue: parseFloat(data.price).toFixed(2),
            value: parseFloat(data.price).toFixed(2),
          },
          products: [
            {
              name: `${data.specialty} appointment`,
              id: data.id,
              price: parseFloat(data.price).toFixed(2),
              brand: data.specialty,
              category: data.type,
              quantity: 1,
            },
          ],
        },
      },
    });

    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        currency: "USD",
        transaction_id: data.id,
        affiliation: "Docto GA4",
        revenue: parseFloat(data.price).toFixed(2),
        value: parseFloat(data.price).toFixed(2),
        transactionTotal: parseFloat(data.price).toFixed(2),
        items: [
          {
            name: `${data.specialty} appointment`,
            id: data.id,
            price: parseFloat(data.price).toFixed(2),
            brand: data.specialty,
            category: data.type,
            quantity: 1,
          },
        ],
      },
    });
  },
};
