import React, { useState } from "react";
import EmergencyConsultAccess from "@components/emergency_consult_access";
import Helmet from "react-helmet";
import { Appointments, Toast } from "@helpers/";
import { useDispatch } from "react-redux";
import {
  closeConfirmationModal,
  showConfirmationModal,
  showLoadingModal,
} from "@modules/gui";
import moment from "moment";
import { history } from "@modules/";

const NurseAccess = (props) => {
  console.log("NurseAccess");
  const { user } = props;
  const isNursingRoom = true;
  const [consultInfoParse, setConsultInfoParse] = useState({});
  const dispatch = useDispatch();

  const onJoinNow = (patient, consultInfo) => {
    setConsultInfoParse(consultInfo);
    if (patient.exists) {
      requestNurseEmergencyAccess(patient);
      return;
    }
    createPatientWithNurseEmergencyAccess(patient);
    return;
  };
  const createPatientWithNurseEmergencyAccess = async (patientData) => {
    console.log("this.createPatientWithNurseEmergencyAccess");
    try {
      dispatch(showLoadingModal(true));
      const response = await Appointments.createPatientWithNurseEmergencyAccess(
        user.token,
        patientData,
        consultInfoParse
      );
      dispatch(showLoadingModal(false));
      onInitChat(user, response);
    } catch (error) {
      console.error(error);
      anErrorOccur(error);
      dispatch(showLoadingModal(false));
    }
  };
  const requestNurseEmergencyAccess = async (patient) => {
    console.log("requestNurseEmergencyAccess");
    const { email } = patient;
    try {
      dispatch(showLoadingModal(true));
      const response = await Appointments.requestNurseEmergencyAccess(
        user.token,
        consultInfoParse,
        email
      );
      dispatch(showLoadingModal(false));
      onInitChat(response?.patient, response);
    } catch (error) {
      dispatch(showLoadingModal(false));
      console.error(error);
      const paymentError = {
        message:
          "Payment decline please update your payment method and try again",
        kind: "error",
      };
      Toast.displayToast(paymentError);
    }
  };
  const onInitChat = (patient, data) => {
    const { appointment, isHoldCallEnable, onHoldData } = data;
    if (isHoldCallEnable) {
      showScheduleAppointmentSuccess(onHoldData);
      return;
    }
    history.replace(`/consult-dr-now/${appointment?.id}?accepted=true`);

    return;
  };

  const showScheduleAppointmentSuccessProceed = () => {
    dispatch(closeConfirmationModal());
    history.replace(`/`);
  };
  const showScheduleAppointmentSuccess = (holdCallData) => {
    const thunk = showConfirmationModal({
      onProceed: showScheduleAppointmentSuccessProceed,
      proceedText: "Accept",
      children: `Thanks you. Your consultation is scheduled for ${moment(
        holdCallData?.to
      ).format(
        "hh:mm a"
      )}. You will receive an SMS alert when the doctor joins`,
      onClose: () => dispatch(closeConfirmationModal()),
    });
    dispatch(thunk);
  };

  const anErrorOccur = (error) => {
    console.error("anErrorOccur payment", error);
    //  this.setState({ showPaymentModal: false });
    const paymentError = {
      message:
        "Payment decline please update your payment method and try again",
      kind: "error",
    };
    Toast.displayToast(paymentError);
  };

  return (
    <div className="joinAsGuestWrap jagw_form">
      <Helmet>
        <title>Telehealth consultation</title>
        <meta
          name="description"
          content="Instant online medical consultation for online prescription, e-prescription, referral or certificate when your GP is not available"
        />
        <link rel="canonical" href="https://www.docto.com.au/consullt-dr-now" />
      </Helmet>
      <EmergencyConsultAccess
        {...{
          user,
          isNursingRoom,
          renderStatus: null,
          onJoinNow,
          contentTemplate: "nursingRoom",
          setPrice: () => {},
        }}
      />
      <div className="jag_brand"></div>
    </div>
  );
};

export default NurseAccess;
