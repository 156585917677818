import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SHOW_GUEST_LINK_MODAL } from "@modules/gui";
import { Toast, Appointments } from "@helpers";
import copy from "copy-to-clipboard";

const GuestLinkModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const [guestLinkData, setGuestLinkData] = useState({
    showGuestLink: true,
    guestLinkStatus: "PROCESSING",
    guestLink: "",
  });

  useEffect(() => {
    let appointmentData = {
      patientId: null,
      userId: user.id,
      message: "GUEST LINK",
      type: "guest-video",
      isfollowUp: false,
      freeBusiness: true,
      scheduledOn: new Date(),
    };
    fetchAppointment(user.token, appointmentData);
  }, [user]);

  const fetchAppointment = async (token, appointmentData) => {
    console.log("FETCH");
    try {
      const response = await Appointments.appointmentsWithToken(
        token,
        appointmentData
      );
      let appointmentCreated = response;
      let appointmentLink = `${window.location.origin}/room/guest/${appointmentCreated.id}`;
      console.log(appointmentLink);
      setGuestLinkData({
        ...guestLinkData,
        guestLinkStatus: "SUCCESS",
        guestLink: appointmentLink,
      });
      history.replace(`/room/guest/${appointmentCreated.id}`);
    } catch (error) {
      setGuestLinkData({
        guestLinkStatus: "PROCESSING",
        showGuestLink: false,
        guestLink: "",
      });

      Toast.displayToast({
        kind: "error",
        message: "An error occurred, please try again later.",
      });
    }
  };

  const copyGuestLink = () => {
    copy(guestLinkData.guestLink, {
      debug: true,
      message: "Press #{key} to copy",
    });
    Toast.displayToast({
      kind: "success",
      message: "link copied to your clipboard",
    });
  };
  const hideLinkModal = () => {
    dispatch({
      type: SHOW_GUEST_LINK_MODAL,
      show: false,
    });
  };
  const { guestLinkStatus, guestLink } = guestLinkData;
  return (
    <div className="modal-window md-copy-url">
      <div className="modal">
        <div className="md-head">
          <a onClick={hideLinkModal} className="md-close" href="#">
            <i className="icon-cross-circle"></i>
          </a>
          <h1>CONSULT ROOM LINK</h1>
          <p>
            {guestLinkStatus === "SUCCESS"
              ? "Copy link and share it with your patient. Close this box, then click JOIN NOW to connect"
              : "Generating your room link please wait..."}
          </p>
        </div>

        <div className="md-body">
          {guestLinkStatus === "SUCCESS" && (
            <div className="md-copy-url-wrap">
              <input type="text" value={guestLink} />
              <button onClick={copyGuestLink} type="button">
                <i className="icon-copy"></i>
                Copy link
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuestLinkModal;
