import api from "@src/api";
import { Routes } from "@constants";

const fetchAll = async (token) => {
  return await api.get(
    `${Routes.referral}?filter[include][specialty]&accessToken=${token}`
  );
};

const uploadReferral = async (formData) => {
  return await api.multiPart(
    Routes.uploadAppointmentReferralPublic(),
    formData
  );
};

const sendReferral = (formValues, handleError) => {
  const { file } = formValues;
  if (file) {
    let formData = new FormData();
    var referralFilesUpload = [];
    for (var i = 0; i < file.length; i++) {
      var element = file[i];
      formData.append(element.name, element);
      var fileId = new Date().getTime();
      referralFilesUpload.push({ name: element.name, url: null, id: fileId });
    }
    return api
      .post(Routes.uploadReferralDocument(null), formData, {
        contentType: "multipart/form-data",
      })
      .then((response) => {
        console.log(response);

        var fileNamesUploadStr = [];
        for (var i = 0; i < referralFilesUpload.length; i++) {
          var fileName = referralFilesUpload[i].name;
          var urlFile = response.data.result.files[fileName][0].name;
          fileNamesUploadStr.push(urlFile);
        }
        formData.file = fileNamesUploadStr;
        return api
          .post(Routes.referralWithToken(null), formData)
          .then(() => {
            return api
              .post(`${Routes.email}/sendReferral`, formData)
              .then((response) => {
                console.log("response sendReferral", response);
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .error((err) => {
            handleError(err, this);
          });
      })
      .catch((err) => {
        console.log(err);
        handleError(`Error Uploading File: ${err}`, this);
      });
  } else {
    return api
      .post(Routes.referralWithToken(null), formValues)
      .then(() => {
        return api
          .post(`${Routes.email}/sendReferral`, formValues)
          .error((error) => {
            handleError(error, this);
          });
      })
      .error((error) => {
        handleError(error, this);
      });
  }
};

export default {
  sendReferral: sendReferral,
  uploadReferral: uploadReferral,
  fetchAll: fetchAll,
};
