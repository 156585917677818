export const SHOW_LOGIN_MODAL = "gui/SHOW_LOGIN_MODAL";
export const SHOW_EMERGENCY_BOT = "gui/SHOW_EMERGENCY_BOT";
export const SHOW_SPECIALTY_BOT = "gui/SHOW_SPECIALTY_BOT";
export const SHOW_CONFIRMATION_MODAL = "gui/SHOW_CONFIRMATION_MODAL";
export const SHOW_LOADING_MODAL = "gui/SHOW_LOADING_MODAL";
export const SHOW_FILE_VIEWER = "gui/SHOW_FILE_VIEWER";
export const SHOW_PRICING_MODAL = "gui/SHOW_PRICING_MODAL";
export const SHOW_PAYMENT_MODAL = "gui/SHOW_PAYMENT_MODAL";
export const SHOW_POSTCODE_CHECKER = "gui/SHOW_POSTCODE_CHECKER";
export const SHOW_UPDATE_APPOINTMENTS = "gui/SHOW_UPDATE_APPOINTMENTS";
export const SHOW_UPSERT_APPOINTMENT_MODAL =
  "gui/SHOW_UPSERT_APPOINTMENT_MODAL";
export const SHOW_CHAT_SIDE_BAR = "gui/SHOW_CHAT_SIDE_BAR";
export const SHOW_ADVANCE_PAYMENT_MODAL = "gui/SHOW_ADVANCE_PAYMENT_MODAL";
export const SHOW_DOCTOR_NOTES = "gui/SHOW_DOCTOR_NOTES";
export const SHOW_PATIENT_MEDICAL_RECORDS = "gui/SHOW_PATIENT_MEDICAL_RECORDS";
export const SHOW_PATIENT_SURVEY = "gui/SHOW_PATIENT_SURVEY";
export const SHOW_DOCTOR_SURVEY = "gui/SHOW_DOCTOR_SURVEY";
export const SHOW_GUEST_LINK_MODAL = "gui/SHOW_GUEST_LINK_MODAL";
export const SHOW_UPSERT_REFERRAL_MODAL = "gui/SHOW_UPSERT_REFERRAL_MODAL";
export const SHOW_CALL_TEST = "gui/SHOW_CALL_TEST";
export const SHOW_EDIT_BOOK_APPOINTMENT_MODAL =
  "gui/SHOW_EDIT_BOOK_APPOINTMENT_MODAL";
export const SHOW_DOCUMENT_VIEWER = "gui/SHOW_DOCUMENT_VIEWER";

const initialState = {
  loginModal: false,
  callTest: false,
  chatSideBar: {
    user: {},
    show: false,
    appointmentId: null,
    minimizeChat: false,
    isCallerOnVideoCall: false,
    trigerEndCall: 0,
    trigerLeaveCall: 0,
  },
  documentViewer: {
    show: false,
    url: null,
  },
  editBookAppointmentModal: {
    show: false,
    appointmentId: null,
  },
  showDoctorNotes: {
    show: false,
    appointmentId: null,
  },
  doctorSurvey: {
    show: false,
    appointmentId: null,
  },
  patientSurvey: {
    show: false,
    appointmentId: null,
  },
  showPatientMedicalRecord: {
    show: false,
    appointmentId: null,
  },
  guestLinkModal: {
    show: false,
  },
  confirmationModal: {
    show: false,
    children: null,
    proceedText: null,
    cancelText: null,
    onClose: null,
    onProceed: null,
    onCancel: null,
  },
  loadingModal: false,
  emergencyBot: { show: false, initMessage: "welcome_isConsultOnlineDoctor" },
  specialtyBot: { show: false, specialtyId: "", params: {} },
  advancePaymentModal: {
    details: "",
    loading: false,
    navigation: null,
    show: false,
    onClose: null,
    onConfirm: null,
    price: null,
    unix: null,
    onSavePayment: null,
  },
  showPricingModal: false,
  showPaymentModal: { show: false, price: 0 },
  showPostCodeMedicareChecker: false,
  upsertAppointmentModal: {
    show: false,
    appointment: null,
    user: null,
    onComplete: null,
  },
  upsertReferralModal: {
    show: false,
    patient: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DOCUMENT_VIEWER:
      return {
        ...state,
        documentViewer: {
          ...state.documentViewer,
          ...action.params,
        },
      };
    case SHOW_DOCTOR_SURVEY:
      return {
        ...state,
        doctorSurvey: {
          ...state.doctorSurvey,
          ...action.params,
        },
      };
    case SHOW_PATIENT_SURVEY:
      return {
        ...state,
        patientSurvey: {
          ...state.patientSurvey,
          ...action.params,
        },
      };
    case SHOW_PATIENT_MEDICAL_RECORDS:
      return {
        ...state,
        showPatientMedicalRecord: {
          ...state.showPatientMedicalRecord,
          ...action.params,
        },
      };
    case SHOW_DOCTOR_NOTES:
      return {
        ...state,
        showDoctorNotes: { ...state.showDoctorNotes, ...action.params },
      };
    case SHOW_ADVANCE_PAYMENT_MODAL:
      return {
        ...state,
        advancePaymentModal: { ...state.advancePaymentModal, ...action.params },
      };
    case SHOW_CHAT_SIDE_BAR:
      return {
        ...state,
        chatSideBar: { ...state.chatSideBar, ...action.params },
      };
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.show,
      };
    case SHOW_CALL_TEST:
      return {
        ...state,
        callTest: action.show,
      };
    case SHOW_POSTCODE_CHECKER:
      return {
        ...state,
        showPostCodeMedicareChecker: action.show,
      };
    case SHOW_EMERGENCY_BOT:
      return {
        ...state,
        emergencyBot: { show: action.show, initMessage: action.initMessage },
      };
    case SHOW_SPECIALTY_BOT:
      return {
        ...state,
        specialtyBot: { ...state.specialtyBot, ...action.params },
      };
    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: { ...state.confirmationModal, ...action.params },
      };
    case SHOW_LOADING_MODAL:
      return {
        ...state,
        loadingModal: action.show,
      };
    case SHOW_PRICING_MODAL:
      return {
        ...state,
        showPricingModal: action.show,
      };
    case SHOW_PAYMENT_MODAL:
      return {
        ...state,
        showPaymentModal: action.show,
        price: action.price,
      };
    case SHOW_GUEST_LINK_MODAL:
      console.log(action);
      return {
        ...state,
        guestLinkModal: {
          show: action.show,
        },
      };
    case SHOW_UPSERT_APPOINTMENT_MODAL:
      console.log(action);
      return {
        ...state,
        upsertAppointmentModal: {
          ...state.upsertAppointmentModal,
          ...action.params,
        },
      };
    case SHOW_UPSERT_REFERRAL_MODAL:
      console.log(action);
      return {
        ...state,
        upsertReferralModal: {
          ...state.upsertAppointmentModal,
          ...action.params,
        },
      };
    case SHOW_FILE_VIEWER:
      return {
        ...state,
        file: action.file,
      };
    case SHOW_EDIT_BOOK_APPOINTMENT_MODAL:
      return {
        ...state,
        editBookAppointmentModal: {
          ...state.editBookAppointmentModal,
          ...action.params,
        },
      };
    default:
      return state;
  }
};
export const showDocumentViewer = (parameters) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCUMENT_VIEWER,
        params: { show: true, ...parameters },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const closeDocumentViewer = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCUMENT_VIEWER,
        params: initialState.documentViewer,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showPatientMedicalRecord = ({ show, appointmentId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_MEDICAL_RECORDS,
        params: { show, appointmentId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const hidePatientMedicalRecord = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_MEDICAL_RECORDS,
        params: initialState.showPatientMedicalRecord,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showDoctorNotes = ({ show, appointmentId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCTOR_NOTES,
        params: { show, appointmentId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showEditBookAppointmentModal = ({ appointmentId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_EDIT_BOOK_APPOINTMENT_MODAL,
        params: { show: true, appointmentId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const closeEditBookAppointmentModal = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_EDIT_BOOK_APPOINTMENT_MODAL,
        params: initialState.editBookAppointmentModal,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const hideDoctorNotes = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCTOR_NOTES,
        params: initialState.showDoctorNotes,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showPatientSurvey = ({ appointmentId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_SURVEY,
        params: { show: true, appointmentId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const hidePatientSurvey = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_SURVEY,
        params: initialState.patientSurvey,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showDoctorSurvey = ({ appointmentId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCTOR_SURVEY,
        params: { show: true, appointmentId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const hideDoctorSurvey = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCTOR_SURVEY,
        params: initialState.doctorSurvey,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showLoadingModal = (show) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_LOADING_MODAL,
        show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showLoginModal = (show) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_LOGIN_MODAL,
        show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showCallTestModal = (show) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CALL_TEST,
        show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showConfirmationModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CONFIRMATION_MODAL,
        params: { ...params, show: true },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const closeConfirmationModal = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CONFIRMATION_MODAL,
        params: initialState.confirmationModal,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showAdvancePaymentModal = (params) => {
  console.log({ ...params, show: true });
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_ADVANCE_PAYMENT_MODAL,
        params: { ...params, show: true },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const closeAdvancePaymentModal = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_ADVANCE_PAYMENT_MODAL,
        params: { ...initialState.advancePaymentModal },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showChatSideBar = (params) => {
  console.log({ ...params, show: true });
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CHAT_SIDE_BAR,
        params: { ...params, show: true },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const closeChatSideBar = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CHAT_SIDE_BAR,
        params: { ...initialState.advancePaymentModal },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

// export const init = () => {
//   return async (dispatch) => {
//     try {
//       // dispatch({
//       //   type: INIT_USER,
//       //   user: {},
//       //   env: {},
//       // });
//     } catch (error) {
//       console.error(error);
//     }
//   };
// };
