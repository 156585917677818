import React from "react";

const AccountLoader = ({ title }) => {
  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>{title || "Loading"}</h1>
        <div className="header-lined"></div>
        <div className="profile-img">
          <div
            className="profile-avatar"
            id="previewImage"
            style={{
              backgroundImage:
                'url("https://docto-profile-pictures.s3-ap-southeast-2.amazonaws.com/production%2F1503550942131_Docto_online-doctor_dr-jonathan-field_300px.jpg")',
            }}
          >
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
          <a className="edit-avatar">
            <div className="loader-animation">
              <div></div>
            </div>
          </a>
        </div>
        <div className="prof-load">
          <div className="left">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
          <div className="right">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
        <div className="prof-load">
          <div className="left">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
          <div className="right">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
        <div className="prof-load">
          <div className="wide">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
        <div className="prof-load">
          <div className="wide">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
        <div className="prof-load">
          <div className="left">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountLoader;
