import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Company, Toast } from "@helpers/";
import UnboundConfirmationModal from "@src/components/modals/unbound_confirmation_modal";
import TableLoader from "../preloader/table";

function index() {
  const [companies, setCompanies] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const user = useSelector((state) => state.session.user);

  useEffect(() => {
    fetchCompanies();
  }, []);
  const fetchCompanies = async () => {
    try {
      const response = await Company.fetchAll();
      setCompanies(response);
    } catch (error) {
      console.log(error);
    }
  };
  if (companies.length === 0) {
    return (
      <TableLoader columns={["name", "code", "slug", "emergency", "actions"]} />
    );
  }

  const showDeleteAlert = (code) => {
    const company = companies.find((c) => c.code === code);
    setSelectedCompany(company);
    setShowDeleteConfirmation(true);
  };

  const deleteCompany = async () => {
    try {
      await Company.deleteCompanyWithToken(selectedCompany.id, user.token);

      setSelectedCompany(null);
      fetchCompanies();
      Toast.displayToast({
        type: "success",
        message: "Company has been deleted",
      });
    } catch (error) {
      Toast.displayToast({ type: "error", message: "Something Went Wrong" });
    }
  };
  return (
    <div className="admin-holder">
      <div className="doctor-list">
        <div className="admin-header">
          <h1>Company Accounts</h1>
          <div className="top-actions">
            <a href="/admin/create-company" className="btn-green">
              Create New Company
            </a>
          </div>
        </div>

        <table className="styled table-responsive companies">
          <thead>
            <tr>
              <td>Name</td>
              <td>Code</td>
              <td>Slug</td>
              <td>Has Emergency Access</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {companies.map((company) => {
              return (
                <tr key={company.id}>
                  <td>
                    <Link to={`/admin/companies/${company.id}`}>
                      {company.name}
                    </Link>
                  </td>
                  <td>{company.code}</td>
                  <td>{company.slug}</td>
                  <td>{company.emergencyAccess ? "YES" : "NO"}</td>
                  <td>
                    {" "}
                    <button
                      className="btn btn-red"
                      onClick={() => showDeleteAlert(company.code)}
                    >
                      <i className="icon icon-trash2"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showDeleteConfirmation && (
        <UnboundConfirmationModal
          proceed={deleteCompany}
          proceedText="Delete"
          open={showDeleteConfirmation}
          close={() => setShowDeleteConfirmation(false)}
        >
          Are you sure you want to delete {selectedCompany.name}?
        </UnboundConfirmationModal>
      )}
    </div>
  );
}

export default index;
