/* eslint-disable react/no-string-refs */

import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import MaskedInput from "react-maskedinput";
import moment from "moment-timezone";
import validator from "au-bn-validator";
import AutoCompleteAddress from "@components/auto_complete_address_input";
import {
  SHOW_LOGIN_MODAL,
  SHOW_EMERGENCY_BOT,
  SHOW_SPECIALTY_BOT,
} from "@modules/gui";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { REFERRAL_COST } from "@constants/docto";
import {
  Drugs,
  User,
  Toast,
  Utils,
  Bot,
  Services,
  Session,
  Appointments,
} from "@helpers/";
import empty from "is-empty";
import Autocomplete from "react-autocomplete";
// import botSound from "@assets/sounds/beep2.wav";
import { history } from "@modules";
import { SHOW_ADVANCE_PAYMENT_MODAL } from "@modules/gui";
import { INIT_USER } from "@modules/session";
import {
  parseNumber,
  getCountryCallingCode,
  isValidNumber,
} from "libphonenumber-js";
import loadingBotImg from "@assets/images/loading-bot.gif";

class SpecialtyBot extends React.Component {
  constructor() {
    super();
    this.botContext = {};
    this.scrollChatBot = React.createRef();
    this.uploadFile = React.createRef();
    this.referralFilesFormData = null;
    this.state = {
      loadingBotResponse: false,
      messages: [],
      specialty: null,
      status: "LOADING",
      pharmacySearchResults: [],
      botInputValue: "",
      dropDownMenuShow: false,
      referralFiles: [],
      paymentError: null,
      prescriptionDrugs: [],
    };
  }
  closeBot() {
    this.props.closeSpecialtyBot();
  }
  loginClick() {
    this.closeBot();
    this.props.showLoginModal();
  }
  componentDidMount() {
    this.initBot();
  }
  setSpecialtyPrices(isRural, specialty, isFollowUp) {
    // RuralAfterMedicareRebate: 0;
    // RuralFollowAfterMedicareRebate: 0;
    // afterMedicareRebate: 185;
    // description: "Neurosurgery is the surgical specialty concerned with disorders of the nervous system including the brain, spinal cord and peripheral nerves. Common neurosurgical procedures include excision of brain tumours, evacuation of blood clots from inside or around the brain, and spinal surgery.";
    // followAfterMedicareRebate: 95;
    // followUpfrontFee: 150;
    // id: "57b4ab57f23d110800315530";
    // name: "Neurosurgery";
    // order: 10;
    // type: "medical";
    // upfrontFee: 290;
    // visibility: "public";
    const {
      afterMedicareRebate,
      followAfterMedicareRebate,
      ruralAfterMedicareRebate,
      ruralFollowAfterMedicareRebate,
      upfrontFee,
      followUpfrontFee,
      ruralUpfrontFee,
      ruralFollowUpfrontFee,
    } = specialty;
    const followUpOutOfPocket = isRural
      ? ruralFollowAfterMedicareRebate
      : followAfterMedicareRebate;
    const outOfPocket = isRural
      ? ruralAfterMedicareRebate
      : afterMedicareRebate;

    let outOfPocketFinal = isFollowUp ? followUpOutOfPocket : outOfPocket;
    let price = isRural ? ruralUpfrontFee : upfrontFee;
    price = isFollowUp && isRural ? ruralFollowUpfrontFee : price;
    price = isFollowUp && !isRural ? followUpfrontFee : price;
    const medicareRebate = Number(price) - Number(outOfPocketFinal);
    let context = {
      outOfPocket: outOfPocketFinal,
      price,
      medicareRebate,
    };
    console.log(context);
    this.botContext = { ...this.botContext, ...context };
  }
  async initBot() {
    const { specialtyId, user, params } = this.props;
    const response = await User.getSpecialtyInfoForUser(specialtyId, user.id);
    console.log(response);
    const { specialty, referralFee, isFollowUp, emergencyCost } = response;

    this.setState({ specialty });
    this.setSpecialtyPrices(false, specialty, isFollowUp);
    this.botContext.referralCost = referralFee;
    this.botContext.emergencyCost = emergencyCost;
    this.botContext.isFollowUp = isFollowUp;
    this.botContext.specialtyId = specialty.id;
    this.botContext.specialty = specialty.name;
    if (params && params.fromMagicLink) {
      this.botContext.medicare = Boolean(params.medicare);
      this.botContext.magicLink = true;
      this.botContext.checkMedicare = true;
    }
    if (user.id) {
      const { firstName, lastName, id } = user;
      this.botContext = { ...this.botContext, firstName, lastName, userId: id };
    }
    const initValue =
      params && params.init ? params.init : "welcome-book-specialty";
    this.askBot(initValue, false);
  }
  showEmergencyDoctoBot() {
    this.closeBot();
    this.props.showEmergencyBot();
    setTimeout(() => {
      this.closeBot();
    }, 100);
  }
  async resendEmail() {
    const { email } = this.botContext;
    const data = {
      path: "/",
      nextSearch: `?bookSpecialtyId=${
        this.botContext.specialtyId
      }&medicare=${Boolean(this.botContext.medicare)}`,
      confirm: true,
    };
    try {
      const response = await Session.sendMagicLink(email, data);
      console.log(response);
      Toast.displayToast({
        kind: "success",
        message: "Email sent. Please check your email",
      });
    } catch (error) {
      console.error("bot send sendLoginCredentials", error);
    }
  }
  askBotWithQR(qr) {
    switch (qr.action) {
      case "resendEmail":
        this.resendEmail();
        break;
      case "otherPharmacy":
        this.showOtherPharmacy();
        break;
      case "nearByPharmacy":
        this.showNearbyPharmacy();
        break;
      case "simple":
        // eslint-disable-next-line no-case-declarations
        let { messages } = this.state;
        // eslint-disable-next-line no-case-declarations
        const message = { inputType: "hide", text: [qr.text], sender: "user" };
        messages.push(message);
        this.setState({ messages });
        this.askBot(qr.value, false);
        break;
      case "uploadReferral":
        this.uploadReferral();
        break;
      case "closeBot":
        this.closeBot();
        break;
      case "goToAppointmentMessage":
        this.goToAppointmentRequest();
        break;
      case "showEmergencyDoctoBot":
        this.showEmergencyDoctoBot();
        break;
      default:
        console.log("invalid action");
        console.log(qr);
        break;
    }
  }
  goToAppointmentRequest() {
    this.closeBot();
    let pushRoute = "/messages";
    if (this.botContext && this.botContext.appointmentId) {
      pushRoute = `/messages?appointmentId=${this.botContext.appointmentId}`;
    }
    history.push(pushRoute);
  }

  async checkMedicareWithPostCode() {
    const { medicarePostCode } = this.botContext;
    try {
      const response = await Services.isMedicareElegibleWithPostalCode(
        medicarePostCode
      );
      const { isMedicare } = response;
      const { specialty } = this.state;
      const { isFollowUp } = this.botContext;
      const message = isMedicare ? "Outside RA1" : "Inside RA1";
      this.setSpecialtyPrices(isMedicare, specialty, isFollowUp);
      console.log({ message });
      this.askBot(message, false);
    } catch (error) {
      console.error(error);
      this.askBot("error", false);
    }
  }
  async checkMedicareWithLocation() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    const error = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      const message = "error";
      this.botContext.medicare = false;
      this.askBot(message, false);
    };
    const success = async (pos) => {
      var crd = pos.coords;
      console.log("Your current position is:");
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);
      const lat = crd.latitude;
      const long = crd.longitude;
      try {
        const response = await Services.isMedicareElegibleWithLocation(
          long,
          lat
        );
        const { isMedicare } = response;
        const message = isMedicare ? "Outside RA1" : "Inside RA1";
        this.botContext.isRural = isMedicare;
        const { specialty } = this.state;
        const { isFollowUp } = this.botContext;
        this.setSpecialtyPrices(isMedicare, specialty, isFollowUp);
        this.askBot(message, false);
      } catch (error) {
        console.error(error);
      }
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  }
  async verifyEmail() {
    const { email } = this.botContext;
    try {
      const response = await Services.validateDoctoEmail(email);
      const { valid, exists, phoneNumber, countryCode } = response;
      if (exists) {
        this.botContext.smsData = { phoneNumber, countryCode };
        this.askBot("success", false);
        return;
      }
      if (!valid) {
        this.askBot("invalidEmail", false);
        return;
      }
      this.askBot("notFound", false);
    } catch (error) {
      console.error(error);
    }
  }
  async sendMagicLink() {
    const { email } = this.botContext;
    const data = {
      path: "/",
      nextSearch: `?bookSpecialtyId=${
        this.botContext.specialtyId
      }&medicare=${Boolean(this.botContext.medicare)}`,
      confirm: true,
    };
    try {
      const response = await Session.sendMagicLink(email, data);
      console.log(response);
    } catch (error) {
      console.error("bot send sendLoginCredentials", error);
    }
  }
  async sendSmsLoginCode() {
    try {
      const { smsData, email } = this.botContext;
      let { countryCode, phoneNumber } = smsData;
      const parsePhoneNumber = phoneNumber
        .replace(/[^0-9]/g, "")
        .replace(/^0/, "")
        .substr(-9);
      await Services.sendSmsCode(email, `+${countryCode}${parsePhoneNumber}`);
      console.log("sms send success");
    } catch (error) {
      console.error(error);
    }
  }
  async verifySmsLoginCode() {
    try {
      const { smsCode, email } = this.botContext;
      console.log(smsCode);
      const response = await Session.loginWithPinCode(email, smsCode);
      let { patient, envs, token } = response;
      const { firstName, lastName, id } = patient;
      this.botContext = { ...this.botContext, firstName, lastName, userId: id };
      patient.token = token;
      this.props.initUser({ user: patient, env: envs, token });
      this.askBot("success", false);
    } catch (error) {
      console.error(error);
      this.askBot("error", false);
    }
  }
  bookAppointmentWithReferralFee() {
    const { user } = this.props;
    const hasValidPaymentMethod = User.hasValidPayment(user);
    this.props.updateAdvancePaymentModal({
      show: true,
      onClose: () => this.closeAdvancePaymentModal(),
      navigation: hasValidPaymentMethod ? "confirmation" : null,
      price: REFERRAL_COST,
      unix: moment().unix(),
      onConfirm: (card) => this.proceedWithPayment(card),
    });
  }
  async uploadFormData(appointmentId, token) {
    const formData = this.referralFilesFormData;
    if (!formData) {
      return;
    }
    try {
      await Appointments.uploadAppointmentPatientReferralDocument(
        formData,
        appointmentId,
        token
      );
      this.askBot("success", false);
    } catch (error) {
      console.error(error);
      Toast.displayToast({
        message: "Unexpected error occur. Please try again",
        kind: "error",
      });
    }
  }
  hideAdvancePaymentModel() {
    this.props.updateAdvancePaymentModal({ show: false });
  }
  showNearbyPharmacy() {
    const error = (err) => {
      console.error(`ERROR`, err);
      this.pushBotMessage({
        text:
          "We were not able to get your location. Please type your preferred pharmacy",
        inputType: "pharmacy",
      });
    };
    const success = async (pos) => {
      var crd = pos.coords;
      console.log("Your current position is:");
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);
      var lat = crd.latitude;
      var long = crd.longitude;
      //long = "145.49267578125";
      //lat = "-36.62018464353607";
      try {
        const pharmas = await Services.getNearbyPharmacy(lat, long);
        let options = pharmas.map((op) => {
          return { text: op.label, action: "simple", value: op.label };
        });
        options.push({
          text: "other",
          action: "otherPharmacy",
          value: "other",
        });
        const messageToAdd = {
          inputType: "hide",
          qr: options,
          qrType: "dropdownList",
          text: ["select your preferred pharmacy"],
          sender: "bot",
        };
        this.pushBotRawMessage(messageToAdd);
      } catch (error) {
        console.error(error);
        error();
      }
    };

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    try {
      this.pushBotMessage({
        text: "checking your location...",
        inputType: "hide",
      });
      navigator.geolocation.getCurrentPosition(success, error, options);
    } catch (error) {
      console.error("error", error);
      error();
    }
  }
  async createPatientWithAppointmentRequest(payload) {
    console.log(payload);
    try {
      const response = await Appointments.createUserWithAppointmentRequest(
        payload
      );
      let { appointment, token } = response;
      this.botContext.appointmentId = appointment.id;
      this.hideAdvancePaymentModel();
      const formData = this.referralFilesFormData;
      if (formData) {
        console.log(formData);
        await this.uploadFormData(appointment.id, token);
        return;
      }
      this.askBot("success", false);
    } catch (error) {
      console.error(error);
      const res = JSON.parse(error.response);
      const code = res.error.code;
      switch (code) {
        case "INVALID_PAYMENT_METHOD":
          this.props.updateAdvancePaymentModal({
            loading: false,
            unix: moment().unix(),
            navigation: "billingInfo",
          });

          Toast.displayToast({
            kind: "error",
            message: "Invalid payment method. Please try again",
          });
          break;
        case "PAYMENT_DECLINE":
          Toast.displayToast({
            kind: "error",
            message:
              "Payment decline. Please update your payment method and try again.",
          });
          break;
        default:
          Toast.displayToast({
            kind: "error",
            message: "An unexpected error occur. please try again",
          });
      }
    }
  }
  async createPatientWithSpecialtyRequestNoReferral() {
    const {
      specialtyId,
      medicareNumber,
      referralPhone,
      isFollowUp,
      referralDr,
      referralEmail,
      referralPractice,
      referralDate,
      referralReason,
      collectReferralFee,
      referralProviderNumber,
      firstName,
      lastName,
      fullMedicareNumber,
      pharmacy,
      phoneNumber,
      countryCode,
      dob,
      email,
      isRural,
      homeAddress,
    } = this.botContext;

    const bookInfo = {
      collectReferralFee,
      specialtyId: specialtyId,
      medicareNumber: fullMedicareNumber,
      isMedicareElegible: empty(medicareNumber) ? false : true,
      message: referralReason,
      referralEmail,
      referralDate,
      referralProviderNumber,
      referralName: referralDr,
      referralPractice,
      referralPhone,
      isRural,
      isFollowUp: isFollowUp ? this.botContext.isFollowUp : false,
    };
    const { user } = this.props;
    let patient = { id: user?.id };
    if (!user.id) {
      patient = {
        firstName,
        lastName,
        medicareNumber: fullMedicareNumber,
        preferredPharmacy: pharmacy,
        phoneNumber,
        countryCode,
        country: homeAddress.country,
        address: homeAddress.address,
        address2: homeAddress.address2,
        region: homeAddress.region,
        fullAddress: homeAddress.inputText,
        birthday: moment(dob, "dd/MM/YYYY").toDate(),
        email,
      };
    }
    try {
      await Appointments.createPatientWithSpecialtyRequestNoReferral({
        bookInfo,
        patient,
      });
      this.askBot("success", false);
    } catch (error) {
      this.askBot("success", false);
      console.error(error);
    }
  }
  async createAppointmentRequest(payload) {
    const { user } = this.props;
    try {
      const response = await Appointments.createAppointmentRequest(
        payload,
        user.token
      );
      const appointmentId = response.id;
      this.botContext.appointmentId = appointmentId;
      this.hideAdvancePaymentModel();
      const formData = this.referralFilesFormData;
      if (formData) {
        console.log(formData);
        this.uploadFormData(appointmentId, user.token);
        return;
      }
      this.askBot("success", false);
    } catch (error) {
      console.error(error);
      const res = JSON.parse(error.response);
      const code = res.error.code;
      switch (code) {
        case "INVALID_PAYMENT_METHOD":
          this.props.updateAdvancePaymentModal({
            loading: false,
            unix: moment().unix(),
            navigation: "billingInfo",
          });

          Toast.displayToast({
            kind: "error",
            message: "Invalid payment method. Please try again",
          });
          break;
        case "PAYMENT_DECLINE":
          Toast.displayToast({
            kind: "error",
            message:
              "Payment decline. Please update your payment method and try again.",
          });
          break;

        default:
          Toast.displayToast({
            kind: "error",
            message: "An unexpected error occur. please try again",
          });
        // setTimeout(() => {
        //   this.props.updateAdvancePaymentModal({
        //     loading: false,
        //     unix: moment().unix(),
        //     navigation: "billingInfo",
        //   });
        // }, 100);
      }
    }
  }

  bookAppointment(card = null) {
    const {
      specialtyId,
      medicareNumber,
      referralPhone,
      isFollowUp,
      referralDr,
      referralEmail,
      referralPractice,
      referralDate,
      referralReason,
      collectReferralFee,
      referralProviderNumber,
      firstName,
      lastName,
      fullMedicareNumber,
      pharmacy,
      phoneNumber,
      countryCode,
      dob,
      email,
      isRural,
      homeAddress,
    } = this.botContext;

    const bookInfo = {
      collectReferralFee,
      specialtyId: specialtyId,
      medicareNumber: fullMedicareNumber,
      isMedicareElegible: empty(medicareNumber) ? false : true,
      message: referralReason,
      referralEmail,
      referralDate,
      referralProviderNumber,
      referralName: referralDr,
      referralPractice,
      referralPhone,
      isRural,
      isFollowUp: isFollowUp ? this.botContext.isFollowUp : false,
    };
    const { user } = this.props;
    console.log(bookInfo);
    if (!user.id) {
      let patient = {
        firstName,
        lastName,
        medicareNumber: fullMedicareNumber,
        preferredPharmacy: pharmacy,
        phoneNumber,
        countryCode,
        country: homeAddress.country,
        address: homeAddress.address,
        address2: homeAddress.address2,
        region: homeAddress.region,
        fullAddress: homeAddress.inputText,
        birthday: moment(dob, "dd/MM/YYYY").toDate(),
        email,
      };

      this.createPatientWithAppointmentRequest({
        patient,
        consultInfo: bookInfo,
        card,
      });
      return;
    }
    bookInfo.patientId = user?.id;

    this.createAppointmentRequest({ ...bookInfo, card });
  }
  closeAdvancePaymentModal() {
    console.log("closeAdvancePaymentModal");
    this.askBot("modalClosed", false);
  }
  proceedWithPayment(card) {
    console.log(card);
    this.props.updateAdvancePaymentModal({
      loading: true,
      unix: moment().unix(),
    });
    this.bookAppointment(card);
  }
  validateMedicareNumber() {
    let { medicareNumber, medicareIrn } = this.botContext;
    medicareNumber = `${medicareNumber.replace(/\s/g, "")}/${medicareIrn}`;
    console.log("medicareNumber", medicareNumber);
    const isValid = validator.validateMedicareNumber(medicareNumber);
    console.log({ isValid });
    if (!isValid) {
      this.askBot("error", false);
      return;
    }
    this.botContext.fullMedicareNumber = medicareNumber;
    this.askBot("success", false);
  }
  showOtherPharmacy() {
    this.pushBotRawMessage({
      text: ["Preferred Pharmacy"],
      inputType: "pharmacy",
      sender: "bot",
    });
  }
  triggerAction(action) {
    if (!action) {
      return;
    }
    console.log(action);
    switch (action) {
      case "otherPharmacy":
        this.showOtherPharmacy();
        break;
      case "nearByPharmacy":
        this.showNearbyPharmacy();
        break;
      case "checkMedicareLocation":
        this.checkMedicareWithLocation();
        break;
      case "checkMedicareWithPostCode":
        this.checkMedicareWithPostCode();
        break;
      case "verifyMedicareNumber":
        this.validateMedicareNumber();
        break;
      case "verifyEmail":
        this.verifyEmail();
        break;
      case "sendMagicLink":
        this.sendMagicLink();
        break;
      case "sendSmsLoginCode":
        this.sendSmsLoginCode();
        break;
      case "verifySmsLoginCode":
        this.verifySmsLoginCode();
        break;
      case "payReferralFeeAndBookAppointment":
        this.bookAppointmentWithReferralFee();
        break;
      case "bookAppointment":
        this.bookAppointment();
        break;
      case "createPatientWithSpecialtyRequestNoReferral":
        this.createPatientWithSpecialtyRequestNoReferral();
        break;
      default:
        console.log("action not implemented", action);
        break;
    }
  }
  pushUserMessage(message) {
    let { messages } = this.state;
    messages.push({ inputType: "hide", text: [message], sender: "user" });
    this.setState({ messages, botInputValue: "" }, () => this.scrollDown());
  }
  pushBotRawMessage(messageToAdd) {
    let { messages } = this.state;
    messages.push(messageToAdd);
    this.setState({ messages, botInputValue: "" }, () => this.scrollDown());
  }
  pushBotMessage(message) {
    let { messages } = this.state;
    const { inputType, text } = message;
    messages.push({
      inputType: inputType,
      text: [text],
      sender: "bot",
    });
    this.setState({ messages, botInputValue: "" }, () => this.scrollDown());
  }
  uploadReferral() {
    this.uploadFile.current.click();
  }

  uploadReferralChange(ev) {
    let files = Array.from(ev.target.files);
    if (Utils.exceedFileSize(files)) {
      Toast.displayToast({
        message: "files exceeds the 25MB file-size",
        kind: "error",
      });
      return;
    }
    const referralfileNames = files.map((element) => {
      return element.name;
    });

    this.botContext.referralfileNames = referralfileNames.join(", ");
    this.referralFilesFormData = Utils.getFormData(files);
    console.log(referralfileNames);
    this.pushUserMessage(this.botContext.referralfileNames);
    this.askBot("upload", false);
  }

  async askBot(message, show = true) {
    if (empty(message)) {
      return;
    }
    if (show) {
      this.pushUserMessage(message);
    }

    this.setState({ loadingBotResponse: true });
    try {
      const response = await Bot.askBot(message, this.botContext);
      console.log(response);
      const { context, output } = response;
      let { messages } = this.state;
      let messageFromBot = output;

      this.botContext = context;
      this.triggerAction(messageFromBot?.action);
      messageFromBot.sender = "bot";
      messages.push(messageFromBot);

      this.setState(
        {
          loadingBotResponse: false,
          messages,
          status: "COMPLETED",
        },
        () => {
          this.scrollDown();
        }
      );
    } catch (error) {
      this.setState({ loadingBotResponse: false });
      console.error(error);
    }
  }

  keyEnter(ev) {
    if (ev.key === "Enter") {
      const { botInputValue } = this.state;
      this.askBot(botInputValue);
    }
  }

  keyEnterWithFuntion(ev, func) {
    if (ev.key === "Enter") {
      const { botInputValue } = this.state;
      func(botInputValue);
    }
  }
  scrollDown() {
    if (!this.scrollChatBot.current) {
      return;
    }
    setTimeout(() => {
      this.scrollChatBot.current.scrollTop = this.scrollChatBot.current.scrollHeight;
    }, 100);
  }
  onClickDropDownMenu() {
    if (!this.scrollChatBot.current) {
      return;
    }
    this.setState({ dropDownMenuShow: !this.state.dropDownMenuShow }, () => {
      setTimeout(() => {
        this.scrollChatBot.current.scrollTop =
          this.scrollChatBot.current.scrollHeight + 300;
      }, 300);
    });
  }

  renderQuickReplies(qr, type) {
    switch (type) {
      case "dropdownList":
        return (
          <div className="bc-bubble in">
            <div className="dropdown dropdown-bot">
              <button
                onClick={() => this.onClickDropDownMenu()}
                aria-expanded="false"
                aria-haspopup="true"
                className="dropdown-toggle"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
              ></button>
              <div
                aria-labelledby="dropdownMenuButton"
                className={
                  this.state.dropDownMenuShow
                    ? "dropdown-menu show"
                    : "dropdown-menu hide"
                }
              >
                {qr.map((quickReplyButton, index) => (
                  <a
                    className="dropdown-item"
                    key={`quickreply=${index}`}
                    href="#"
                    onClick={() => this.askBotWithQR(quickReplyButton)}
                  >
                    {ReactHtmlParser(quickReplyButton.text)}
                  </a>
                ))}
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="bc-bubble-sudgestions">
            <span></span>
            {qr.map((quickReplyButton, index) => (
              <a
                key={`quickreply=${index}`}
                onClick={() => this.askBotWithQR(quickReplyButton)}
              >
                {ReactHtmlParser(quickReplyButton.text)}
              </a>
            ))}
          </div>
        );
    }
  }

  renderMessages() {
    let { messages, loadingBotResponse } = this.state;
    if (messages.length == 0) {
      return null;
    }
    const lastMessage = messages[messages.length - 1];
    const quickReplies = lastMessage && lastMessage.qr ? lastMessage.qr : null;
    const qrType =
      lastMessage && lastMessage.qrType ? lastMessage.qrType : "simple";

    return messages.length === 1 ? (
      <div className="bot-chat-initial">
        <h4>
          <i className="icon-chat-plus-lined"></i>
          {messages[0].text.map((text) => {
            return ReactHtmlParser(text);
          })}
        </h4>
        {quickReplies ? this.renderQuickReplies(quickReplies, qrType) : null}
      </div>
    ) : (
      <div className="bot-chat">
        <div className="bot-chat-scroll" ref={this.scrollChatBot}>
          {messages.map((message, index) => (
            <div
              key={`bot-${index}`}
              className={
                message.sender === "bot" ? "bc-bubble in" : "bc-bubble out"
              }
            >
              <i className="icon-"></i>
              <div className="bc-bubble-text">
                {message.text.map((text) => {
                  return ReactHtmlParser(text.replace("↵", "<br/>"));
                })}
              </div>
            </div>
          ))}
          {loadingBotResponse ? (
            <div key="bot-loading" className="bc-bubble in">
              <i className="icon-"></i>
              <div className="bc-bubble-text">
                <img src={loadingBotImg} style={{ height: "12px" }} />
              </div>
            </div>
          ) : null}

          {quickReplies ? this.renderQuickReplies(quickReplies, qrType) : null}
        </div>
      </div>
    );
  }

  onChangeAutoCompleteDrug(e) {
    const value = e.target.value;
    if (value.length < 4) {
      this.setState({ prescriptionDrugs: [], botInputValue: value });
      return;
    }
    const drugs = Drugs.prescriptionDrugs;
    const prescriptionDrugs = drugs.filter((d) => {
      return d.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });
    this.setState({
      prescriptionDrugs: prescriptionDrugs,
      botInputValue: value,
    });
  }
  onChangeAutoCompletePharmacyFields(e) {
    const value = e.target.value;
    this.setState({ botInputValue: value });

    clearTimeout(this.pharmaSearchTimeOutId);
    if (value.length < 3) {
      this.setState({ pharmacySearchResults: [] });
      return;
    }
    if (this.state.pharmacySearchResults.length == 0) {
      this.setState({
        pharmacySearchResults: [{ name: "loading ...", enable: false }],
      });
    }

    this.pharmaSearchTimeOutId = setTimeout(() => {
      findPharmacy(value);
    }, 800);

    const findPharmacy = async (value) => {
      try {
        const pharmacies = await Services.findPharmacy(value);
        const pharmacyResults = pharmacies.map((op) => {
          return { name: op.label, enable: true };
        });
        this.setState({ pharmacySearchResults: pharmacyResults });
      } catch (error) {
        console.error(error);
      }
    };
  }
  checkDateAndAskBot(date) {
    const d = moment(date, "dd/MM/YYYY");
    if (!d.isValid()) {
      this.pushBotMessage({
        text: "Seems you provide an invalid date, Please Type again",
        inputType: "date",
      });
      return;
    }
    this.askBot(date);
  }
  sendAddres(address) {
    this.botContext.homeAddress = address;
    this.askBot(address.inputText, true);
  }
  checkPhoneNumber(val) {
    if (val === undefined || !isValidNumber(val)) {
      this.pushBotMessage({
        text: "Invalid phone number please try again.",
        inputType: "phone",
      });
      return;
    }
    const parsePhoneNumber = parseNumber(val);
    console.log(parseNumber);
    this.botContext.countryCode = getCountryCallingCode(
      parsePhoneNumber.country
    );
    this.botContext.phoneNumber = parsePhoneNumber.phone;
    this.askBot(val);
  }
  renderBotInput(type) {
    if (type === null) {
      return null;
    }
    switch (type) {
      case "pharmacy":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-drop-wrap"
                : "bot-drop-wrap stick-bottom"
            }
          >
            <Autocomplete
              isItemSelectable={(item) => {
                return item.enable;
              }}
              items={this.state.pharmacySearchResults}
              shouldItemRender={(item, value) =>
                item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                !item.enable
              }
              getItemValue={(item) => item.name}
              renderItem={(item, isHighlighted) => (
                <div className={isHighlighted ? "selected" : ""}>
                  {item.name}
                </div>
              )}
              renderMenu={(items) => {
                // eslint-disable-next-line react/no-children-prop
                return <div className="bot-drop-menu" children={items} />;
              }}
              renderInput={(props) => (
                <input
                  onKeyPress={(ev) => this.keyEnter(ev)}
                  className="bot-drop-toggle"
                  {...props}
                />
              )}
              value={this.state.botInputValue}
              onChange={(e) => this.onChangeAutoCompletePharmacyFields(e)}
              onSelect={(val) => this.setState({ botInputValue: val })}
            />
            <button
              className="bot-drop-send"
              onClick={() => this.askBot(this.state.botInputValue)}
            >
              Send
            </button>
          </div>
        );

      case "phone":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-send-form"
                : "bot-send-form stick-bottom"
            }
          >
            <PhoneInput
              country="AU"
              value={this.state.botInputValue}
              smartCaret={false}
              placeholder="Phone Number"
              onKeyUp={(ev) =>
                this.keyEnterWithFuntion(ev, (input) =>
                  this.checkPhoneNumber(input)
                )
              }
              onChange={(phone) => this.setState({ botInputValue: phone })}
            />
            <button
              onClick={() => this.checkPhoneNumber(this.state.botInputValue)}
            >
              Send
            </button>
          </div>
        );
      case "date":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-send-form"
                : "bot-send-form stick-bottom"
            }
          >
            <MaskedInput
              mask="11/11/1111"
              placeholder="DD/MM/YYYY"
              placeholderChar=" "
              value={this.state.botInputValue}
              type="tel"
              onChange={(e) => this.setState({ botInputValue: e.target.value })}
              onKeyUp={(ev) =>
                this.keyEnterWithFuntion(ev, (input) =>
                  this.checkDateAndAskBot(input)
                )
              }
            />
            <button
              onClick={() => this.checkDateAndAskBot(this.state.botInputValue)}
            >
              Send
            </button>
          </div>
        );
      case "medicareIRN":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-send-form"
                : "bot-send-form stick-bottom"
            }
          >
            <MaskedInput
              mask="1"
              type="tel"
              placeholderChar=" "
              placeholder=""
              value={this.state.botInputValue}
              onChange={(e) => this.setState({ botInputValue: e.target.value })}
              onKeyUp={(ev) => this.keyEnter(ev, false)}
            />
            <button onClick={() => this.askBot(this.state.botInputValue)}>
              Send
            </button>
          </div>
        );
      case "number":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-send-form"
                : "bot-send-form stick-bottom"
            }
          >
            <MaskedInput
              mask="1111111111"
              placeholderChar=" "
              type="tel"
              placeholder=""
              value={this.state.botInputValue}
              onChange={(e) => this.setState({ botInputValue: e.target.value })}
              onKeyUp={(ev) => this.keyEnter(ev, false)}
            />
            <button onClick={() => this.askBot(this.state.botInputValue)}>
              Send
            </button>
          </div>
        );
      case "postCode":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-send-form"
                : "bot-send-form stick-bottom"
            }
          >
            <MaskedInput
              mask="1111"
              placeholderChar=" "
              placeholder=""
              type="tel"
              value={this.state.botInputValue}
              onChange={(e) => this.setState({ botInputValue: e.target.value })}
              onKeyUp={(ev) => this.keyEnter(ev, false)}
            />
            <button onClick={() => this.askBot(this.state.botInputValue)}>
              Send
            </button>
          </div>
        );
      case "hide":
      case "none":
        return null;

      case "address":
        console.log("entra");
        return (
          <AutoCompleteAddress
            onAddressSelect={(address) => this.sendAddres(address)}
          />
        );
      case "password field":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-send-form"
                : "bot-send-form stick-bottom"
            }
          >
            <input
              type="password"
              value={this.state.botInputValue}
              onChange={(e) => this.setState({ botInputValue: e.target.value })}
              onKeyPress={(ev) => this.keyEnter(ev, false)}
              placeholder="type here"
            />
            <button onClick={() => this.askBot(null, false)}>Send</button>
          </div>
        );

      case "autocomplete-drugs-field":
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-drop-wrap"
                : "bot-drop-wrap stick-bottom"
            }
          >
            <Autocomplete
              items={this.state.prescriptionDrugs}
              getItemValue={(item) => item.name}
              renderItem={(item, isHighlighted) => (
                <div className={isHighlighted ? "selected" : ""}>
                  {item.name}
                </div>
              )}
              renderMenu={(items) => {
                // eslint-disable-next-line react/no-children-prop
                return <div className="bot-drop-menu" children={items} />;
              }}
              renderInput={(props) => (
                <input
                  onKeyPress={(ev) => this.keyEnter(ev)}
                  className="bot-drop-toggle"
                  {...props}
                />
              )}
              value={this.state.botInputValue}
              onChange={(e) => this.onChangeAutoCompleteDrug(e)}
              onSelect={(val) => this.setState({ botInputValue: val })}
            />
            <button className="bot-drop-send" onClick={() => this.askBot()}>
              Send
            </button>
          </div>
        );

      default:
        return (
          <div
            className={
              this.state.messages.length <= 1
                ? "bot-send-form"
                : "bot-send-form stick-bottom"
            }
          >
            <input
              autoComplete="nope"
              type="text"
              value={this.state.botInputValue}
              onChange={(e) => this.setState({ botInputValue: e.target.value })}
              onKeyPress={(ev) => this.keyEnter(ev)}
              placeholder="type here"
            />
            <button onClick={() => this.askBot(this.state.botInputValue)}>
              Send
            </button>
          </div>
        );
    }
  }
  renderLoading() {
    return (
      <div
        className={
          this.props.fullScreen
            ? "lndng-section-blue"
            : "lndng-hero bot-inner-wrap"
        }
      >
        <div className={this.props.fullScreen ? "bot-overlay" : "bot-inline"}>
          <h1 className="bot-header">Eva, Virtual Assistant</h1>
          {!this.props.user.id ? (
            <div className="bot-login-btn">
              <a onClick={() => this.loginClick()} href="#" className="btn">
                Login{" "}
              </a>
            </div>
          ) : null}

          <div onClick={() => this.closeBot()} className="bot-close">
            <i className="icon-cross2"></i>
          </div>
          <div className="bot-container">
            <div className="bot-chat-initial">
              <h4>
                <i className="icon-chat-plus-lined"></i>
                One sec ...
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { messages, status } = this.state;
    const lastMessage = messages[messages.length - 1] || {};
    const inputType = lastMessage.inputType;
    if (status === "LOADING") {
      return this.renderLoading();
    }
    return (
      <div
        className={
          this.props.fullScreen
            ? "lndng-section-blue"
            : "lndng-hero bot-inner-wrap"
        }
      >
        <div className={this.props.fullScreen ? "bot-overlay" : "bot-inline"}>
          <h1 className="bot-header">Eva, Virtual Assistant</h1>
          {!this.props.user.id ? (
            <div className="bot-login-btn">
              <a onClick={() => this.loginClick()} href="#" className="btn">
                Login{" "}
              </a>
            </div>
          ) : null}

          <div onClick={() => this.closeBot()} className="bot-close">
            <i className="icon-cross2"></i>
          </div>
          <div className="bot-container">
            <input
              multiple={true}
              className="upload-file-selector"
              id="upload-file"
              ref={this.uploadFile}
              type="file"
              onChange={(ev) => this.uploadReferralChange(ev)}
            />

            {/*RENDER*/ this.renderMessages()}

            {this.renderBotInput(inputType)}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showEmergencyBot: () =>
      dispatch({
        type: SHOW_EMERGENCY_BOT,
        show: true,
      }),
    showLoginModal: () =>
      dispatch({
        type: SHOW_LOGIN_MODAL,
        show: true,
      }),
    initUser: ({ env, user }) =>
      dispatch({
        type: INIT_USER,
        env,
        user,
      }),
    updateAdvancePaymentModal: (params) =>
      dispatch({
        type: SHOW_ADVANCE_PAYMENT_MODAL,
        params,
      }),
    closeSpecialtyBot: () =>
      dispatch({
        type: SHOW_SPECIALTY_BOT,
        params: { show: false, params: {}, specialtyId: null },
      }),
  };
};
const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(SpecialtyBot);
