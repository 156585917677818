import React from "react";
import spin from "@assets/images/docto-symbol.svg";

const LoadingSpinner = () => {
  return (
    <div className="modal-window full-z-index">
      <div className="loader-wrapper">
        <div className="spinner">
          <img src={spin} className="logo-spin" />
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
