import React from "react";

const MedicareModal = () => {
  return (
    <div className="modal-window md-medicare">
      <div className="modal">
        <div className="md-header">
          <a className="md-close" onClick={this.props.onClose}>
            <i className="icon-cross-circle"></i>
          </a>
        </div>
        <div className="md-body">
          <strong className="color-blue">
            You may be eligible for a medicare rebate if: You are referred AND
          </strong>

          <ol>
            <li>In Australia </li>
            <li>
              Within an eligible area:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://www.doctorconnect.gov.au/internet/otd/publishing.nsf/Content/locator"
              >
                (View Locator)
              </a>
            </li>
            <li>Greater then 15km from the specialist </li>
          </ol>

          <strong className="color-blue">OR</strong>

          <ol>
            <li>In a residential care home</li>
            <li>A patient of an aboriginal medical center</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default MedicareModal;
