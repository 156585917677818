import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ReferralUpload from "@src/components/upload_referral";

class ExpertOpinion extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>
            Docto Online Hospital - Online Expert Medical Specialist Opinion{" "}
          </title>
          <meta
            name="description"
            content="Expert online Opinion. Australia’s first online hospital. A medical specialist telehealth service. Bringing the hospital to you."
          />
          <link
            rel="canonical"
            href="https://www.docto.com.au/expert-opinion"
          />
        </Helmet>
        <div className="sub--hero hero--cover--search expert-opinion-hero">
          <div className="wrap">
            <h1 className="hero__title bold">
              Secure Referral / Report Upload
            </h1>
          </div>
        </div>

        <section>
          <div className="wrap">
            <div className="refer-wrap">
              <div className="refer-col">
                <ReferralUpload showName showPractice />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ExpertOpinion;
