import React, { useState } from "react";
import EmergencyConsultAccess from "@components/emergency_consult_access";
import Helmet from "react-helmet";
import { Appointments, Toast, Analytics } from "@helpers/";
import { useDispatch } from "react-redux";
import {
  closeConfirmationModal,
  showConfirmationModal,
  showAdvancePaymentModal,
  closeAdvancePaymentModal,
} from "@modules/gui";
import { initUser as initUserSession } from "@modules/session";
import moment from "moment";
import { history } from "@modules/";

const EmergencyAccess = (props) => {
  const { user, routeName, content } = props;
  const isNursingRoom = routeName === "NursingRoom";
  const [renderStatus, setRenderStatus] = useState(null);
  const dispatch = useDispatch();
  const [price, setPrice] = useState("250");
  const [consultInfoParse, setConsultInfoParse] = useState({});

  const onJoinNow = async (patient, consultInfo) => {
    await setConsultInfoParse(consultInfo);
    if (user.id) {
      requestEmergencyAccessWithPayment({ user, consultInfo });
      return;
    }
    createPatientWithEmergencyAccess({ patientData: patient, consultInfo });
  };

  const createPatientWithEmergencyAccess = async ({
    patientData,
    card = {},
    confirmation = false,
    consultInfo = {},
  }) => {
    try {
      dispatch(showAdvancePaymentModal({ loading: true }));
      const response = await Appointments.createPatientWithEmergencyAccess({
        card,
        patient: patientData,
        consultInfo,
        confirmation,
      });
      let { envs, token, patient } = response;
      patient.token = token;
      initUser(patient, envs, token);
      onInitChat(patient, response);
      dispatch(closeAdvancePaymentModal());
    } catch (e) {
      const res = JSON.parse(e.response);
      const { error } = res;
      if (error.code === "NEED_PAYMENT_CONFIRMATION") {
        setRenderStatus("REQUEST_ACCESS");
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            price,
            navigation: "confirmation",
            onConfirm: (card) =>
              createPatientWithEmergencyAccess({
                patientData,
                card,
                confirmation: true,
                consultInfo,
              }),
          })
        );
        return;
      }
      if (error.code === "INVALID_PAYMENT_METHOD") {
        setRenderStatus("REQUEST_ACCESS");
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            price,
            navigation: "paymentInfo",
            onConfirm: (card) =>
              createPatientWithEmergencyAccess({
                patientData,
                card,
                confirmation: true,
                consultInfo,
              }),
          })
        );
        return;
      }
      setRenderStatus("REQUEST_ACCESS");
      dispatch(
        showAdvancePaymentModal({
          loading: false,
          price,
          navigation: "paymentInfo",
          onConfirm: (card) =>
            createPatientWithEmergencyAccess({
              patientData,
              card,
              confimation: true,
              consultInfo,
            }),
        })
      );
      const paymentError = {
        message:
          "Payment decline please update your payment method and try again",
        kind: "error",
      };
      Toast.displayToast(paymentError);
    }
  };
  const requestEmergencyAccessWithPayment = async ({
    user,
    card = {},
    confirmation = false,
    consultInfo = {},
  }) => {
    dispatch(showAdvancePaymentModal({ loading: true }));
    try {
      const response = await Appointments.requestEmergencyAccessWithPayment({
        token: user.token,
        card,
        confirmation,
        consultInfo,
      });
      dispatch(closeAdvancePaymentModal());
      onInitChat(user, response);
    } catch (e) {
      const res = JSON.parse(e.response);
      const { error } = res;
      if (error.code === "NEED_PAYMENT_CONFIRMATION") {
        setRenderStatus("REQUEST_ACCESS");
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            price,
            navigation: "confirmation",
            onConfirm: (card) =>
              requestEmergencyAccessWithPayment({
                user,
                card,
                confirmation: true,
                consultInfo,
              }),
          })
        );
        return;
      }
      if (error.code === "INVALID_PAYMENT_METHOD") {
        setRenderStatus("REQUEST_ACCESS");
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            price,
            navigation: "paymentInfo",
            onConfirm: (card) =>
              requestEmergencyAccessWithPayment({
                user,
                card,
                confirmation: true,
                consultInfo,
              }),
          })
        );
        return;
      }
      setRenderStatus("REQUEST_ACCESS");
      dispatch(
        showAdvancePaymentModal({
          loading: false,
          price,
          navigation: "paymentInfo",
          onConfirm: (card) =>
            requestEmergencyAccessWithPayment({
              user,
              card,
              confirmation: true,
              consultInfo,
            }),
        })
      );
      const paymentError = {
        message:
          "Payment decline please update your payment method and try again",
        kind: "error",
      };
      Toast.displayToast(paymentError);
    }
  };
  const onInitChat = (patient, data) => {
    const { appointment, isHoldCallEnable, onHoldData } = data;

    if (isHoldCallEnable) {
      showScheduleAppointmentSuccess(onHoldData);
      return;
    }
    history.replace(`/consult-dr-now/${appointment?.id}?accepted=true`);
    return;
  };
  const showScheduleAppointmentSuccessProceed = () => {
    dispatch(closeConfirmationModal());
    history.replace(`/`);
  };
  const showScheduleAppointmentSuccess = (holdCallData) => {
    const thunk = showConfirmationModal({
      onProceed: showScheduleAppointmentSuccessProceed,
      proceedText: "Accept",
      children: `Thanks you. Your consultation is scheduled for ${moment(
        holdCallData?.to
      ).format(
        "hh:mm a"
      )}. You will receive an SMS alert when the doctor joins`,
      onClose: () => dispatch(closeConfirmationModal()),
    });
    dispatch(thunk);
  };

  const initUser = async (patient, envs, token) => {
    patient.roles = [{ name: "patient" }];
    await dispatch(initUserSession({ env: envs, user: patient, token }));
  };

  return (
    <div className="joinAsGuestWrap jagw_form">
      <Helmet>
        <title>Telehealth consultation</title>
        <meta
          name="description"
          content="Instant online medical consultation for online prescription, e-prescription, referral or certificate when your GP is not available"
        />
        <link rel="canonical" href="https://www.docto.com.au/consullt-dr-now" />
      </Helmet>
      <EmergencyConsultAccess
        {...{
          user,
          isNursingRoom,
          renderStatus,
          onJoinNow,
          setPrice,
        }}
      />
      <div className="jag_brand"></div>
    </div>
  );
};

export default EmergencyAccess;
