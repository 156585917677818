import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Utils,
  Toast,
  User,
  Appointments,
  Specialties,
  Specialists,
} from "helpers";
import empty from "is-empty";
import prettyBytes from "pretty-bytes";
import {
  SHOW_LOADING_MODAL,
  SHOW_UPDATE_APPOINTMENTS,
  SHOW_UPSERT_APPOINTMENT_MODAL,
  SHOW_UPSERT_REFERRAL_MODAL,
} from "@modules/gui";

class UpsertReferralModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referralFilesUrl: [],
      referralFiles: [],
      specialtyId: "",
      doctorId: null,
      specialties: [],
      specialists: [],
      errors: {},
      note: "",
    };
    this.validateAndCreateReferral = this.validateAndCreateReferral.bind(this);
  }
  validDate(current) {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  }
  validateAndCreateReferral() {
    const { note, specialtyId } = this.state;
    const errors = {};

    if (empty(note)) {
      errors.note = "Please enter note";
    }
    if (empty(specialtyId)) {
      errors.specialtyId = "Please select specialty";
    }
    // if (empty(doctorId)) {
    //   errors.doctorId = "Please select specialist";
    // }
    console.log(errors);
    if (!empty(errors)) {
      this.setState({ errors });
      return;
    }
    this.createReferral();
  }
  closeModal() {
    this.props.showUpsertReferralModal(false, null);
  }
  async createReferral() {
    const { doctorId, note, specialtyId, referralFiles } = this.state;
    const { patient, user } = this.props;
    const bookInfo = {
      userId: doctorId,
      specialtyId: specialtyId,
      patientId: patient.id,
      message: note,
      referralId: user.id,
      referralEmail: user.email,
      referralName: User.userToNameString(user),
    };
    this.props.showLoadingModal(true);
    try {
      const appointment = await Appointments.createAppointmentRequest(
        bookInfo,
        user.token
      );
      if (referralFiles.length <= 0) {
        const toastInfo = {
          message: "Referral sends successfully",
          kind: "success",
        };
        Toast.displayToast(toastInfo);
        this.props.showLoadingModal(false);
        this.closeModal();
        return;
      }
      this.uploadReferalFiles(appointment.id);
    } catch (error) {
      console.log(error);
      const toastInfo = {
        message: "Unexpected error please try again later",
        kind: "error",
      };
      Toast.displayToast(toastInfo);
      this.closeModal();
    }
  }

  componentDidMount() {
    this.loadSpecialties();
  }
  async loadSpecialties() {
    try {
      const specialties = await Specialties.fetchSpecialties();
      this.setState({ specialties });
    } catch (error) {
      console.error(error);
    }
  }
  async loadSpecialists(specialtyId) {
    console.log("loadSpecialists");
    this.setState({ specialistsStatus: "LOADING" });
    try {
      const specialists = await Specialists.fetchSpecialistsBySpecialtyId(
        specialtyId
      );

      this.setState({
        specialists: specialists,
        specialistsStatus: "COMPLETED",
      });
    } catch (error) {
      console.error(error);
      this.setState({ specialistsStatus: "COMPLETED" });
    }
  }
  onChangeNote(note) {
    this.setState({ note });
  }
  selectSpecialty(specialtyId) {
    this.setState({ specialtyId });
    this.loadSpecialists(specialtyId);
  }
  selectDoctor(doctorId) {
    this.setState({ doctorId });
  }
  uploadReferral() {
    this.refs.uploadFile.click();
  }
  uploadReferralChange(ev) {
    let referralFiles = Array.from(ev.target.files);
    referralFiles.forEach((element, index) => {
      if (element.size > 25485760) {
        delete referralFiles[index];
        Toast.displayToast({
          message: `${element.name} exceeds the 25MB file-size`,
          kind: "warning",
        });
      }
    });
    referralFiles = [...this.state.referralFiles, ...referralFiles];
    this.setState({ referralFiles });
  }

  async uploadReferalFiles(appointmentId) {
    let { referralFiles } = this.state;
    let formData = new FormData();
    for (var i = 0; i < referralFiles.length; i++) {
      var element = referralFiles[i];
      if (element.size > 25485760) {
        Toast.displayToast({
          message: "files exceeds the 25MB file-size",
          kind: "error",
        });
        return;
      }
      formData.append("file", element);
    }
    try {
      await Appointments.uploadAppointmentReferralDocument(
        appointmentId,
        this.props.user.token,
        formData
      );

      const toastInfo = {
        message: "Referral sends successfully",
        kind: "success",
      };
      Toast.displayToast(toastInfo);
      this.props.showLoadingModal(false);
      this.closeModal();
    } catch (error) {
      const toastInfo = {
        message: "Unexpected error please try again later",
        kind: "error",
      };
      Toast.displayToast(toastInfo);
      this.props.showLoadingModal(false);
      this.closeModal();
    }
  }

  deleteFile(index) {
    let { referralFiles, uploadingFiles } = this.state;
    delete referralFiles[index];
    this.setState({ referralFiles });
  }

  renderFileUpload(referralFile, index) {
    return (
      <li key={`ref_${index}`}>
        <div className="cpl-pdf-item">
          <em className="pdf">{referralFile.name.split(".").pop()}</em>
          <div className="pdf-info">
            <b>{referralFile.name}</b>
            <span>{prettyBytes(referralFile.size)}</span>
          </div>
          <a onClick={() => this.deleteFile(index)} className="cpl-delete">
            DELETE
          </a>
        </div>
      </li>
    );
  }
  render() {
    const { patient } = this.props;
    const {
      note,
      specialties,
      specialistsStatus,
      specialists,
      referralFiles,
      errors,
    } = this.state;
    const errorClass = "ad-pill ad-pill-input";
    const successClass = "ad-pill ad-pill-input referal-pill";
    return (
      <div className="modal-window md-appointment">
        <div className="modal">
          <div className="appointments-dash-header">
            <span>Patient Referal</span>
            <a onClick={() => this.closeModal()} className="back-btn">
              Close
            </a>
          </div>
          <div className="appointments-dash-title">
            <span>Patient Details</span>
          </div>
          <div className="appointments-dash-body">
            <div className="ad-pill ad-pill-input referal-pill">
              <div className="read-only">
                <i className="icon-user"></i>
                {User.userToNameString(patient)}
              </div>
            </div>
          </div>
          <div className="appointments-dash-title">
            <span>Doctor Information</span>
          </div>

          <div className="appointments-dash-body">
            <div className={errors.specialtyId ? errorClass : successClass}>
              <select onChange={(ev) => this.selectSpecialty(ev.target.value)}>
                <option value="">Select Specialty</option>
                {specialties.map((s) => {
                  return (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {specialistsStatus === "LOADING" ? (
              <div className="ad-pill ad-pill-input referal-pill">
                <select disabled="disabled">
                  <option disabled="disabled">LOADING...</option>
                </select>
              </div>
            ) : (
              <div className={errors.doctorId ? errorClass : successClass}>
                <select onChange={(ev) => this.selectDoctor(ev.target.value)}>
                  <option value="">
                    {specialists.length > 0
                      ? "Select Specialist"
                      : "Select Specialty First"}
                  </option>

                  {specialists.map((s, index) => {
                    return (
                      <option key={`${s.id}_${index}`} value={s.id}>
                        {User.userToNameString(s)}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            <div className={errors.note ? errorClass : successClass}>
              <textarea
                placeholder="Add note to | Doctor"
                onChange={(ev) => this.onChangeNote(ev.target.value)}
                value={note}
              />
            </div>
          </div>
          <div className="appointments-dash-title">
            <span>Documents</span>
          </div>

          <div className="appointments-dash-body">
            <div className="ad-pill ad-pill-input ad-pill-button">
              <button onClick={() => this.uploadReferral()}>
                <i className="icon-cloud-upload"></i>Click Upload Files
              </button>
              <input
                multiple={true}
                id="upload-file"
                className="hide"
                ref="uploadFile"
                type="file"
                onChange={this.uploadReferralChange.bind(this)}
              />
            </div>
          </div>

          <div className="ad-upload-pack">
            <ul>
              {referralFiles.map((referralFile, index) => {
                return this.renderFileUpload(referralFile, index);
              })}
            </ul>
          </div>

          <div
            onClick={this.validateAndCreateReferral}
            className="modal-footer"
          >
            Send Referral
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showLoadingModal: (show) =>
      dispatch({
        type: SHOW_LOADING_MODAL,
        show: show,
      }),
    updateAppointments: (show) =>
      dispatch({
        type: SHOW_UPDATE_APPOINTMENTS,
        show: show,
      }),
    showUpsertReferralModal: ({ show, patiente }) =>
      dispatch({
        type: SHOW_UPSERT_REFERRAL_MODAL,
        params: { show, patiente },
      }),
    showUpsertAppointmentModal: (show, appointment) =>
      dispatch({
        type: SHOW_UPSERT_APPOINTMENT_MODAL,
        params: {
          show: show,
          appointment: appointment,
        },
      }),
  };
};
const mapStateToProps = ({ session, gui }) => ({
  user: session.user,
  gui,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpsertReferralModal);
