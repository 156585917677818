import React from "react";
import { Link } from "react-router-dom";

class NavLink extends React.Component {
  render() {
    const className = this.props.active ? "active" : "";
    return (
      <Link to={this.props.to} className={className}>
        {this.props.children}
      </Link>
    );
  }
}

export default NavLink;
