import { validate } from "email-validator";
// import { isDate, toDate } from './date';
// import { isEmpty as _isEmpty } from './utils';

// export function validateRequiredFields(fields) {
// 	let valid = true;
// 	let errors = {};

// 	for (let field in fields) {
// 		if (fields.hasOwnProperty(field)) {
// 			let ref = fields[field];
// 			let isEmpty = true;
// 			let value = '';
// 			switch (ref.constructor.name) {
// 				case 'InputField':
// 					value = ref.refs.inputField.value;

// 					if (ref.props.required && _isEmpty(value)) {
// 						valid = false;
// 						errors[field] = 'Field can not be blank';
// 					}
// 					break;
// 				case 'DateField':
// 					value = ref.state.value;

// 					if (ref.props.required && _isEmpty(value)) {
// 						valid = false;
// 						errors[field] = 'Field can not be blank';
// 					}
// 					if (isEmpty && !isDate(value)) {
// 						valid = false;
// 						errors[field] =
// 							'Please enter a valid date with the format "dd/mm/yyyy"';
// 					}
// 					break;

// 				case 'RegionDropdown':
// 					value = ref.props.value;
// 					if (ref.props.required && _isEmpty(value)) {
// 						valid = false;
// 						errors[field] = 'Field can not be blank';
// 					}
// 					break;
// 				case 'CountryDropdown':
// 					value = ref.props.value;
// 					if (ref.props.required && _isEmpty(value)) {
// 						valid = false;
// 						errors[field] = 'Field can not be blank';
// 					}
// 					break;
// 				default:
// 			}
// 		}
// 	}
// 	return { valid: valid, errors: errors };
// }
// export function validatePhone(errors, countryCodeField, phoneNumberField) {
// 	// Just validates that the phone is entered
// 	let code = errors[countryCodeField];
// 	let number = errors[phoneNumberField];
// 	if (code || number) {
// 		if (code === number) {
// 			return code;
// 		} else {
// 			return number ? number : code;
// 		}
// 	}
// }
// export function validateExpirationDate(month, year) {
// 	// validates that both month and year were entered
// 	if (!month || !year) {
// 		return { valid: false, error: 'Please enter this field' };
// 	}
// 	// Month should be between 1 and 12
// 	if (month && (month < '01' || month > '12')) {
// 		return { valid: false, error: 'Please enter a month between 1 and 12' };
// 	}
// 	// Date should be bigger than current date
// 	if (new Date() >= new Date(year, parseInt(month) - 1)) {
// 		return {
// 			valid: false,
// 			error: 'Please select an expiration date in the future',
// 		};
// 	}
// 	// The expiration date is valid
// 	return { valid: true, error: '' };
// }
// export function validateCardNumber(cardNumber) {
// 	// validates that both month and year were entered
// 	if (!cardNumber) {
// 		return { valid: false, error: 'Please enter this field' };
// 	}
// 	// Month should be between 1 and 12
// 	if (cardNumber.length !== 16) {
// 		return { valid: false, error: 'Please enter a valid credit card number' };
// 	}
// 	// The expiration date is valid
// 	return { valid: true, error: '' };
// }
// export function getInputFieldsValues(fields) {
// 	/*
// 	 * Returns the inputs values in a hash where the key is the ref value
// 	 */
// 	let values = {};
// 	for (let field in fields) {
// 		if (fields.hasOwnProperty(field)) {
// 			let ref = fields[field];
// 			let value = '';
// 			switch (ref.constructor.name) {
// 				case 'InputField':
// 					value = ref.refs.inputField.value;
// 					values[field] = _isEmpty(value) ? '' : value;
// 					break;
// 				case 'DateField':
// 					value = ref.state.value;
// 					values[field] = isDate(value) ? toDate(value) : null;
// 					break;
// 				case 'RegionDropdown':
// 				case 'CountryDropdown':
// 					value = ref.props.value;
// 					values[field] = _isEmpty(value) ? '' : value;
// 					break;
// 				default:
// 			}
// 		}
// 	}
// 	return values;
// }
export function validateEmail(email) {
  var valid = validate(email);
  return Boolean(valid);
}
export default { validateEmail: validateEmail };
