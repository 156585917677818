const required = (value) => (value ? undefined : "Required Field");

const mustBeNumber = (value) => (isNaN(value) ? "Must be a number" : undefined);

const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const minValue = (min, message) => (value) =>
  isNaN(value) || value >= min
    ? undefined
    : message ?? `Should be greater than ${min}`;

const validEmail = (message) => {
  if (message === void 0) {
    message = "Email is invalid";
  }
  return function (email) {
    // tslint:disable-next-line:max-line-length
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && !regex.test(email) ? message : null;
  };
};

const validURL = function (message) {
  if (message === void 0) {
    message = "URL is invalid";
  }
  return function (url) {
    var regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return url && !regex.test(url) ? message : null;
  };
};
export default {
  required,
  composeValidators,
  validEmail,
  validURL,
  mustBeNumber,
  minValue,
};
