import React, { useState } from "react";
import texts from "@src/content/homePage";
function Partners() {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const testimonials = texts.testimonials;
  const rotateTestimonials = (reverse) => {
    const direction = reverse ? -1 : +1;
    const active = !testimonials[currentTestimonial + direction]
      ? 0
      : currentTestimonial + direction;
    setCurrentTestimonial(active);
  };

  return (
    <div className="lndng-section-grey lndng-testimonial">
      <div className="container">
        <div className="row row-flex">
          <div className="col-xs-2 show-768">
            <button
              onClick={() => rotateTestimonials(true)}
              className="slider-trigger dark"
            >
              <i className="icon-chevron-left"></i>
            </button>
          </div>
          {testimonials.map((testimonial, index) => {
            return (
              <div
                key={`testimonial-${index}`}
                className={
                  index === currentTestimonial
                    ? "col-xs-8 col-sm-4"
                    : "col-xs-8 col-sm-4 hide-768"
                }
              >
                <div className="company-testimonial-wrap">
                  <img alt="docto testimonials" src={testimonial.imgUrl} />
                  <p>{testimonial.description}</p>
                </div>
              </div>
            );
          })}
          <div className="col-xs-2 show-768">
            <button
              onClick={() => rotateTestimonials(false)}
              className="slider-trigger dark"
            >
              <i className="icon-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
