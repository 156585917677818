import React, { useEffect, useState } from "react";
import botSound from "@assets/sounds/beep2.wav";
const ding = new Audio(botSound);

const Settings = ({
  publisher,
  closeSettings,
  cycleVideo,
  toggleVideo,
  hasVideo,
  hasAudio,
  audioLabel,
  toggleMute,
  cycleMic,
}) => {
  const [audioLevel, setAudioLevel] = useState(0);
  const playSound = () => {
    ding.play();
  };
  useEffect(() => {
    if (publisher) {
      publisher.on("audioLevelUpdated", (event) => {
        setAudioLevel(event.audioLevel);
      });
    }
    return unmount;
  }, []);

  const unmount = () => {
    if (publisher) {
      publisher.off("audioLevelUpdated");
    }
  };

  return (
    <div className="md-call-setup-inner">
      <div className="modal">
        <div className="md-body ">
          <a className="md-setup-close" onClick={() => closeSettings()}>
            Close
          </a>
          <div className="jag-call-setup">
            <div className="jag_body_settings_inner">
              <div className="set-row">
                <i className="icon-Camera_DOCTO"></i>
                <span>Camera</span>
                <div className="actions">
                  <a
                    href="#"
                    className="block-btn active"
                    onClick={() => cycleVideo()}
                  >
                    SWITCH
                  </a>
                  <a
                    href="#"
                    onClick={() => toggleVideo()}
                    className={hasVideo ? "switcher active" : "switcher"}
                  ></a>
                </div>
              </div>
              <div className="set-row">
                <i className="icon-Mic_DOCTO"></i>
                <span>{audioLabel}</span>
                <div className="actions">
                  <a
                    href="#"
                    className="block-btn active"
                    onClick={() => cycleMic()}
                  >
                    SWITCH
                  </a>
                  <a
                    href="#"
                    className={hasAudio ? "switcher active" : "switcher"}
                    onClick={() => toggleMute()}
                  ></a>
                </div>
                <div className="counter">
                  <div
                    className="counter-inner"
                    style={{ width: `${audioLevel * 100}%` }}
                  ></div>
                </div>
              </div>
              <div className="set-row">
                <i className="icon-Speakers_DOCTO"></i>
                <span>Speakers</span>
                <div className="actions">
                  <a
                    href="#"
                    className="block-btn disabled"
                    onClick={() => playSound()}
                  >
                    PLAY TONE
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
