import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Carousel, { Modal, ModalGateway } from "react-images";

const LIGHTBOX_IMAGE_SET = [
  { source: "/assets/images/media-assets/founders/01.jpg" },
  { source: "/assets/images/media-assets/founders/02.jpg" },
  { source: "/assets/images/media-assets/founders/03.jpg" },
  { source: "/assets/images/media-assets/logos/logo-white.jpg" },
  { source: "/assets/images/media-assets/logos/logo-blue.jpg" },
  { source: "/assets/images/media-assets/images/01.jpg" },
  { source: "/assets/images/media-assets/images/02.jpg" },
  { source: "/assets/images/media-assets/images/03.jpg" },
  { source: "/assets/images/media-assets/images/04.jpg" },
  { source: "/assets/images/media-assets/images/05.jpg" },
  { source: "/assets/images/media-assets/images/06.jpg" },
  { source: "/assets/images/media-assets/images/07.jpg" },
  { source: "/assets/images/media-assets/images/08.jpg" },
  { source: "/assets/images/media-assets/images/09.jpg" },
  { source: "/assets/images/media-assets/images/10.jpg" },
];

const LIGHTBOX_VIDEO_SET = [
  { src: "https://fast.wistia.net/embed/iframe/ogsazfggsk?videoFoam=true" },
  { src: "https://fast.wistia.net/embed/iframe/jsticozft7?videoFoam=true" },
  {
    src:
      "https://docto-public-documents.s3-ap-southeast-2.amazonaws.com/DOCTO_ED_Consult.mp4",
  },
];

const Press = () => {
  const [lightboxIsOpen, setLightboxOpen] = useState(false);
  const [videoBoxIsOpen, setVideoBoxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(3);
  const [currentVideo, setCurrentVideo] = useState(0);

  const showImage = (index) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };
  const showVideo = (index) => {
    setCurrentVideo(index);
    setVideoBoxOpen(true);
  };
  return (
    <div id="app-wrapper">
      <Helmet>
        <title>Docto Online Hospital - Press & Media</title>
        <link rel="canonical" href="https://www.docto.com.au/press" />
        <meta
          name="description"
          content="Press & Media Relations. Docto, Australia’s first online hospital."
        />
      </Helmet>
      <div className="home-primary-hero press-hero">
        <div className="home-hero">
          <h1 className="heading">Press and Media kit</h1>
        </div>
      </div>
      <div className="lndng-section-greyblue short-muted">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <span>For Press & Media Enquiries Contact: Phoebe Brown</span>
              <i className="div-circ"></i>
              <a className="" href="mailto:press@docto.com">
                press@docto.com
              </a>
              <i className="div-circ"></i>
              <span>1300 336 286</span>
            </div>
          </div>
        </div>
      </div>
      <div className="lndng-section-white press-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h4>IN THE NEWS</h4>
            </div>
          </div>

          <div className="press-table">
            <div className="row">
              <div className="col-md-3 logo">
                <img src="/assets/images/media-assets/press/press-moregoldcoast.png" />
              </div>
              <div className="col-md-7">
                <span className="date">May 20, 2017</span>
                <p>“Amazing doctors that call the Gold Coast home"</p>
              </div>
              <div className="col-md-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://moregoldcoast.com.au/study/amazing-doctors-gold-coast/"
                >
                  READ
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 logo">
                <img src="/assets/images/media-assets/press/press-01.png" />
              </div>
              <div className="col-md-7">
                <span className="date">May 15, 2016</span>
                <p>
                  “Gold Coast specialists establish online hospital to limit Dr
                  Google searches"
                </p>
              </div>
              <div className="col-md-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.goldcoastbulletin.com.au/business/gold-coast-specialists-establish-online-hospital-to-limit-dr-google-searches/news-story/fdbc104d937522aeb1ae0ce85aa0bfe6"
                >
                  READ
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 logo">
                <img src="/assets/images/media-assets/press/press-02.png" />
              </div>
              <div className="col-md-7">
                <span className="date">April 19, 2016</span>
                <p>"ONLINE HOSPITAL TAKES AIM AT 'DR GOOGLE'"</p>
              </div>
              <div className="col-md-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.businessnewsaus.com.au/articles/online-hospital-takes-aim-at--dr-google-.html"
                >
                  READ
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 logo">
                <img src="/assets/images/media-assets/press/press-03.png" />
              </div>
              <div className="col-md-7">
                <span className="date">April 18, 2016</span>
                <p>“Leading underwriter AHI unveils ‘online hospital’”</p>
              </div>
              <div className="col-md-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.insurancebusinessmag.com/au/news/breaking-news/leading-underwriter-ahi-unveils-online-hospital-57406.aspx"
                >
                  READ
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 logo">
                <img src="/assets/images/media-assets/press/press-04.png" />
              </div>
              <div className="col-md-7">
                <span className="date">May 25, 2016</span>
                <p>
                  “medical services more readily available to Australia’s rural
                  residents and travellers”
                </p>
              </div>
              <div className="col-md-2">
                <a href="https://issuu.com/rbia/docs/27may2016">READ</a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-center">
                <a className="load-more" href="#">
                  LOAD MORE NEWS
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lndng-section-greyblue press-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h4>MEDIA RESOURCES</h4>
            </div>
          </div>

          <div className="press-media">
            {videoBoxIsOpen && (
              <div className="press-lightbox">
                <a
                  onClick={() => {
                    return setVideoBoxOpen(false);
                  }}
                  className="close-lb"
                >
                  <i className="icon-cross-circle"></i>
                </a>
                <div
                  className="video-wrap"
                  style={{ width: "560px", height: "315px" }}
                >
                  <div
                    className="wistia_responsive_padding"
                    style={{ padding: "56.25% 0 0 0", position: "relative" }}
                  >
                    <div
                      className="wistia_responsive_wrapper"
                      style={{
                        height: "100%",
                        left: 0,
                        position: "absolute",
                        top: 0,
                        width: "100%",
                      }}
                    >
                      <iframe
                        src={LIGHTBOX_VIDEO_SET[currentVideo].src}
                        title="Wistia video player"
                        allowtransparency="true"
                        scrolling="no"
                        className="wistia_embed"
                        name="wistia_embed"
                        mozallowfullscreen="true"
                        webkitallowfullscreen="true"
                        oallowfullscreen="true"
                        msallowfullscreen="true"
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  </div>
                  <script
                    src="https://fast.wistia.net/assets/external/E-v1.js"
                    async
                  ></script>
                </div>
              </div>
            )}

            <div className="row ">
              <div className="col-sm-12 col-md-fifth-3 ">
                <h5>Founders</h5>
                <div className="row">
                  <div className="col-sm-4">
                    <a href="#" onClick={showImage} className="media-download">
                      <img src="/assets/images/media-assets/founders/01.jpg" />
                      <div className="hover-wrap">
                        <span>Dr. Jonathan Field</span>
                        <i className="icon-cloud-download"></i>
                      </div>
                    </a>
                  </div>

                  <div className="col-sm-4">
                    <a
                      href="#"
                      onClick={() => showImage(1)}
                      className="media-download"
                    >
                      <img src="/assets/images/media-assets/founders/02.jpg" />
                      <div className="hover-wrap">
                        <span>Dr. Tim Harraway</span>
                        <i className="icon-cloud-download"></i>
                      </div>
                    </a>
                  </div>

                  <div className="col-sm-4">
                    <a
                      href="#"
                      onClick={() => showImage(2)}
                      className="media-download"
                    >
                      <img src="/assets/images/media-assets/founders/03.jpg" />
                      <div className="hover-wrap">
                        <span>Dr. Andrew Jones</span>
                        <i className="icon-cloud-download"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-fifth-2">
                <h5>Logos</h5>
                <div className="row">
                  <div className="col-sm-6">
                    <a
                      href="#"
                      onClick={() => showImage(3)}
                      className="media-download"
                    >
                      <img src="/assets/images/media-assets/logos/logo-white.jpg" />
                      <div className="hover-wrap">
                        <span></span>
                        <i className="icon-cloud-download"></i>
                      </div>
                    </a>
                  </div>

                  <div className="col-sm-6">
                    <a
                      href="#"
                      onClick={() => showImage(4)}
                      className="media-download"
                    >
                      <img src="/assets/images/media-assets/logos/logo-blue.jpg" />
                      <div className="hover-wrap">
                        <span></span>
                        <i className="icon-cloud-download"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <h5>High Resolution Photos</h5>
                  <div className="row">
                    {LIGHTBOX_IMAGE_SET.slice(5, 15).map((photo, index) => {
                      return (
                        <div key={index + 5} className="col-sm-6 col-md-fifth">
                          <a
                            href="#"
                            onClick={() => showImage(index + 5)}
                            className="media-download"
                          >
                            <img src={photo.source} />
                            <div className="hover-wrap">
                              <span></span>
                              <i className="icon-cloud-download"></i>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <h5>Videos</h5>
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <a
                        href="#"
                        onClick={() => showVideo(0)}
                        className="media-download media-video"
                      >
                        <img src="/assets/images/media-assets/videos/01.jpg" />
                        <div className="hover-wrap">
                          <span>What is Docto?</span>
                          <i className="icon-cloud-download"></i>
                        </div>
                      </a>
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <a
                        href="#"
                        onClick={() => showVideo(1)}
                        className="media-download media-video"
                      >
                        <img src="/assets/images/media-assets/videos/02.jpg" />
                        <div className="hover-wrap">
                          <span>Docto Video Consult</span>
                          <i className="icon-cloud-download"></i>
                        </div>
                      </a>
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <a
                        href="#"
                        onClick={() => showVideo(2)}
                        className="media-download media-video"
                      >
                        <img src="/assets/images/media-assets/videos/03.jpg" />
                        <div className="hover-wrap">
                          <span>Docto Chat Consult</span>
                          <i className="icon-cloud-download"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalGateway>
        {lightboxIsOpen && (
          <Modal onClose={() => setLightboxOpen(false)}>
            <Carousel views={LIGHTBOX_IMAGE_SET} currentIndex={currentImage} />
          </Modal>
        )}
      </ModalGateway>
    </div>
  );
};
export default Press;
