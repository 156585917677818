import React, { useEffect, useState } from "react";
const Jotform = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://js.jotform.com/JotFormAnywhere.js";
    scriptTag.addEventListener("load", () => setLoaded(true));
    document.body.append(scriptTag);
  }, []);
  useEffect(() => {
    if (!loaded) return;
    const jotformId = props.jotformId;
    // eslint-disable-next-line no-undef
    JotformAnywhere.insertForm({
      formID: jotformId,
      formEmbedUrl: `https://docto.jotform.com/jsform/${jotformId}`,
      insertTo: "#formHere",
      putEditButton: false,
      putDeleteButton: false,
      builderMaskColor: "#283d74",
    });
    console.log("isloaded");
  }, [loaded]);

  return <div className="jotform-align" id="formHere"></div>;
};

export default Jotform;
