import React, { useState } from "react";
import EmergencyConsultAccess from "@components/emergency_consult_access";
import Helmet from "react-helmet";
import { Appointments, Toast } from "@helpers/";
import { useDispatch, useSelector } from "react-redux";
import {
  closeConfirmationModal,
  showConfirmationModal,
  showAdvancePaymentModal,
  closeAdvancePaymentModal,
} from "@modules/gui";
import { initUser as initUserSession } from "@modules/session";
import moment from "moment";
import { history } from "@modules/";
import { useLocation } from "react-router-dom";

const Default = (props) => {
  const { company } = props;
  const location = useLocation();
  const user = useSelector((state) => state.session.user);
  console.log({ user, props });
  const [renderStatus, setRenderStatus] = useState(null);
  const dispatch = useDispatch();
  const [price, setPrice] = useState("250");

  const onJoinNow = (patient) => {
    if (user.id) {
      requestEmergencyAccessWithPayment(user);
      return;
    }
    createPatientWithEmergencyAccess(patient);
  };

  const createPatientWithEmergencyAccess = async (
    patientData,
    card = {},
    confirmation = false
  ) => {
    try {
      dispatch(showAdvancePaymentModal({ loading: true }));
      const response = await Appointments.createPatientWithEmergencyAccess({
        card,
        patient: patientData,
        consultInfo: {},
        confirmation,
        companyId: company.id,
      });
      let { envs, token, patient } = response;
      patient.token = token;
      initUser(patient, envs, token);
      onInitChat(patient, response);
      dispatch(closeAdvancePaymentModal());
    } catch (e) {
      const res = JSON.parse(e.response);
      const { error } = res;
      if (error.code === "NEED_PAYMENT_CONFIRMATION") {
        setRenderStatus("REQUEST_ACCESS");
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            price,
            navigation: "confirmation",
            onConfirm: (card) =>
              createPatientWithEmergencyAccess(patientData, card, true),
          })
        );
        return;
      }
      if (error.code === "INVALID_PAYMENT_METHOD") {
        setRenderStatus("REQUEST_ACCESS");
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            price,
            navigation: "paymentInfo",
            onConfirm: (card) =>
              createPatientWithEmergencyAccess(patientData, card, true),
          })
        );
        return;
      }
      setRenderStatus("REQUEST_ACCESS");
      dispatch(
        showAdvancePaymentModal({
          loading: false,
          price,
          navigation: "paymentInfo",
          onConfirm: (card) =>
            createPatientWithEmergencyAccess(patientData, card, true),
        })
      );
      const paymentError = {
        message:
          "Payment decline please update your payment method and try again",
        kind: "error",
      };
      Toast.displayToast(paymentError);
    }
  };

  const requestEmergencyAccessWithPayment = async (
    user,
    card = {},
    confirmation = false
  ) => {
    console.log("requestEmergencyAccessWithPayment");
    dispatch(showAdvancePaymentModal({ loading: true }));
    try {
      const response = await Appointments.requestEmergencyAccessWithPayment({
        token: user.token,
        card,
        confirmation,
        consultInfo: {},
        companyId: company?.id,
      });
      dispatch(closeAdvancePaymentModal());
      onInitChat(user, response);
    } catch (e) {
      const res = JSON.parse(e.response);
      const { error } = res;
      if (error.code === "NEED_PAYMENT_CONFIRMATION") {
        setRenderStatus("REQUEST_ACCESS");
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            price,
            navigation: "confirmation",
            onConfirm: (card) =>
              requestEmergencyAccessWithPayment(user, card, true),
          })
        );
        return;
      }
      if (error.code === "INVALID_PAYMENT_METHOD") {
        setRenderStatus("REQUEST_ACCESS");
        dispatch(
          showAdvancePaymentModal({
            loading: false,
            price,
            navigation: "paymentInfo",
            onConfirm: (card) =>
              requestEmergencyAccessWithPayment(user, card, true),
          })
        );
        return;
      }
      setRenderStatus("REQUEST_ACCESS");
      dispatch(
        showAdvancePaymentModal({
          loading: false,
          price,
          navigation: "paymentInfo",
          onConfirm: (card) =>
            requestEmergencyAccessWithPayment(user, card, true),
        })
      );
      const paymentError = {
        message:
          "Payment decline please update your payment method and try again",
        kind: "error",
      };
      Toast.displayToast(paymentError);
    }
  };
  const onInitChat = (patient, data) => {
    const { appointment, isHoldCallEnable, onHoldData } = data;
    if (isHoldCallEnable) {
      showScheduleAppointmentSuccess(onHoldData);
      return;
    }
    history.replace(
      `${location.pathname}/emergency/${appointment?.id}?accepted=true`
    );
    return;
  };
  const showScheduleAppointmentSuccessProceed = () => {
    dispatch(closeConfirmationModal());
    history.replace(`/`);
  };
  const showScheduleAppointmentSuccess = (holdCallData) => {
    const thunk = showConfirmationModal({
      onProceed: showScheduleAppointmentSuccessProceed,
      proceedText: "Accept",
      children: `Thanks you. Your consultation is scheduled for ${moment(
        holdCallData?.to
      ).format(
        "hh:mm a"
      )}. You will receive an SMS alert when the doctor joins`,
      onClose: () => dispatch(closeConfirmationModal()),
    });
    dispatch(thunk);
  };

  const initUser = async (patient, envs, token) => {
    patient.roles = [{ name: "patient" }];
    await dispatch(initUserSession({ env: envs, user: patient, token }));
  };
  return (
    <div className="register-scroll">
      <Helmet>
        <title>Docto Online Hospital - Sign Up Membership</title>
        <meta
          name="description"
          content="Docto Online Hospital, Australia's first - Sign Up Membership"
        />
      </Helmet>
      {/* <div class="reg-logo" style="padding: 30px 0 0;"><img src="/api/v1/containers/docto-company-logos/download/production%2F1504651481648_csgi.png" style="
    width: 100%;
    max-width: 160px;
"></div> */}
      <div className="register-header company-signup">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 company-wrap jagw_form">
              <div className="sm-h0 h100"></div>
              <EmergencyConsultAccess
                {...{
                  user,
                  isNursingRoom: false,
                  renderStatus,
                  onJoinNow,
                  setPrice,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Default;
