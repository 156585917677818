import React, { useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import AppLayout from "@layouts/appLayout";
import Default from "./default/";
import Assistravel from "./assistravel";
import ChatRoom from "@containers/emergency_consult_access/chat_room";
import { Company } from "@helpers/index";
import { history } from "modules";

function Landings() {
  const [status, setStatus] = useState("LOADING");
  const [company, setCompany] = useState(null);
  const { slug } = useParams();
  const findBySlug = async () => {
    try {
      const c = await Company.findBySlug(slug);
      setCompany(c);
      setStatus("DONE");
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    findBySlug();
  }, []);
  if (status === "LOADING") {
    return null;
  }

  if (company === null) {
    history.replace("/not-found");
    return null;
  }
  console.log({ status, company });

  return (
    <AppLayout theme={slug}>
      <div className="account">
        <Switch>
          <Route
            exact
            path="/companies/:slug/emergency/:appointmentId"
            render={(props) => {
              console.log({ props });
              return <ChatRoom company={company} {...props} />;
            }}
          />
          <Route
            exact
            path="/companies/assistravel"
            render={(props) => <Assistravel company={company} {...props} />}
          />
          <Route
            exact
            path="/companies/:slug"
            render={(props) => <Default company={company} {...props} />}
          />
        </Switch>
      </div>
    </AppLayout>
  );
}

export default Landings;
