import React from "react";
import { Link } from "react-router-dom";
import AppointmentsTable from "./table";

const AppointmentSection = ({
  appointments,
  status,
  title,
  type,
  handleFilterChange,
}) => {
  const renderNoAppointments = () => {
    return (
      <div className="appointments-dash">
        <div className="appointments-dash-title">
          <span>{title}</span>
        </div>
        <div className="appointments-dash-body">
          <div className="ad-pill status-info">
            <div className="ad-pill-info">
              <span>You dont have any {title}.</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (status === "COMPLETED" && appointments.length <= 0) {
    return renderNoAppointments();
  }

  return (
    <div className="appointments-dash">
      <div className="appointments-dash-title">
        <span>{title}</span>
      </div>

      <div className="appointments-dash-body">
        <AppointmentsTable
          handleFilterChange={(q) => handleFilterChange(q)}
          loading={status === "LOADING"}
          type={type}
          appointments={appointments}
        />
        <div className="appointments-dash-footer">
          <Link to={`/admin/appointments/${type}`}>View More</Link>
        </div>
      </div>
    </div>
  );
};

export default AppointmentSection;
