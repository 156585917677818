import React from "react";
import LogoutHeader from "./logout_header";

function NurseAppNavigation({ showMenu }) {
  return (
    <ul className={`nav ${showMenu ? "d-block" : ""}`}>
      <LogoutHeader />
    </ul>
  );
}

export default NurseAppNavigation;
