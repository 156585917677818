import React, { useEffect, useState } from "react";
import moment from "moment";
import { Routes } from "@constants";
import { Browser, DateHelpers } from "@helpers";
import { history } from "@modules/";
import copy from "copy-to-clipboard";
import { Toast } from "@helpers/index";
let OT;
if (typeof window !== "undefined") {
  // eslint-disable-next-line no-undef
  OT = require("@opentok/client");
}
let hideTimer = null;
const RequestCall = ({
  callType,
  appointment,
  user,
  senderId,
  sendJoining,
}) => {
  const [hide, setHide] = useState(false);
  const ONE_MINUTE = 1000 * 60;
  const [acceptCallClick, setAcceptCallClick] = useState(false);
  useEffect(() => {
    hideTimer = setTimeout(() => {
      setHide(true);
    }, ONE_MINUTE);
    return () => {
      clearTimeout(hideTimer);
    };
  }, []);

  const acceptCall = (type, appointment) => {
    if (!acceptCallClick) {
      setAcceptCallClick(true);
      clearTimeout(hideTimer);
      const isVideoCall = type === "video";
      history.push(
        `/consultation-room/${appointment.id}/audio/${!isVideoCall}`
      );
      if (sendJoining) {
        sendJoining();
      }
    }
  };

  if (hide === true) {
    return true;
  }
  if (user.id === senderId) {
    return (
      <div className="pre-chat-message">
        <p>Call requested</p>
      </div>
    );
  }
  if (acceptCallClick) {
    return (
      <div className="pre-chat-message">
        <p>Please wait connecting with your doctor ...</p>
      </div>
    );
  }
  return (
    <div className="chat-item chat-message-info">
      <div className="ci-message-bubble">
        <span>The doctor is calling.</span>
        <div className="ci-message-actions">
          {callType === "audio" ? (
            <a
              className="btn-chat"
              onClick={() => acceptCall("audio", appointment)}
            >
              <i className="icon-phone-bubble"></i>Accept Audio Call
            </a>
          ) : (
            <a
              className="btn-chat"
              onClick={() => acceptCall("video", appointment)}
            >
              <i className="icon-bubble-video"></i>Accept Video Call
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
const UnsupportedBrowser = ({ user, copyLink }) => {
  return (
    <div className="pre-chat-message">
      <p>
        You are using an unsupported browser.
        <br />
        <a onClick={() => copyLink(user)}>Copy link</a> and paste it into
        Firefox, Chrome or latest version of Safari.
      </p>
    </div>
  );
};
const UnsupportedIOSBrowser = ({ user, copyLink }) => {
  return (
    <div className="pre-chat-message">
      <p>
        This browser is not yet supported on IOS.
        <br />
        <a onClick={() => copyLink(user)}>Copy link</a> and paste it into safari
        to begin your consultation.
      </p>
    </div>
  );
};
const UnsupportedPatientBrowser = ({ senderId, user, copyLink }) => {
  if (user.id !== senderId) {
    return (
      <div className="pre-chat-message">
        <p>
          Your patient is using an IOS unsupported browser.
          <br />
          Ask them to use Firefox, Chrome or latest version of Safari to receive
          video calls
        </p>
      </div>
    );
  }
  return (
    <div className="pre-chat-message">
      <p>
        You are using an unsupported browser.
        <br />
        <a onClick={() => copyLink(user)}>Copy link</a> and paste it into
        Firefox, Chrome or latest version of Safari
      </p>
    </div>
  );
};
const UnsupportedPatientBrowserIOS = ({ senderId, user, copyLink }) => {
  if (user.id !== senderId) {
    return (
      <div className="pre-chat-message">
        <p>
          Your patient is using an IOS unsupported browser.
          <br />
          Ask them to use latest version of Safari to receive video calls.
        </p>
      </div>
    );
  }
  return (
    <div className="pre-chat-message">
      <p>
        This browser is not yet supported on IOS.
        <br />
        <a onClick={() => copyLink(user)}>Copy link</a> and paste it into safari
        to begin your consultation.
      </p>
    </div>
  );
};
const SmsWaitingPatient = ({ senderId, user, changePhoneNumber }) => {
  if (user.id !== senderId) {
    return (
      <div className="pre-chat-message">
        <p>
          Apologies, the doctors are all consulting at the moment. We will send
          you an email and SMS&nbsp;
          <a onClick={() => changePhoneNumber(user)}>
            +{user.countryCode}
            {user.phoneNumber}
          </a>
          &nbsp;when the next doctor is available. It will not be more than 20
          minutes`
        </p>
      </div>
    );
  }
};
const PostPoneMessage = (props) => {
  const { timestamp, meta } = props;
  const { patientName, posponeEmergencyReminderUntil } = meta;
  const formmatedTime = DateHelpers.displayDoctoChatTime(timestamp);
  const divStyle = {
    backgroundImage:
      "url(" +
      Routes.thumbnailProfilePicUrl(posponeEmergencyReminderUntil.callerImage) +
      ")",
  };

  return (
    <div className={"chat-item "}>
      <span className="ci-time-stamp">{formmatedTime}</span>
      {posponeEmergencyReminderUntil.callerImage ? (
        <div className="ci-avatar" style={divStyle}></div>
      ) : null}

      <div className="chat-bubble ci-text-bubble">
        <span>
          <span>
            {`Hi ${patientName}, sorry, I am with another patient. I will
              send a message as soon as I am finished. It will not be more than
              20 minutes. Apologies for the inconvenvience.`}
            <br /> {`Dr ${posponeEmergencyReminderUntil.doctorName}`}
          </span>
        </span>
      </div>
    </div>
  );
};
const DoctoMessage = (props) => {
  const {
    sendJoining,
    body,
    type,
    user,
    senderId,
    timestamp,
    meta,
    expDate,
    callType,
    appointment,
  } = props;
  const copyLink = (user) => {
    const link = `${window.location.href.split(/[?#]/)[0]}?accessToken=${
      user.token
    }`;
    copy(link, {
      debug: true,
      message: "Press #{key} to copy",
    });
    Toast.displayToast({
      kind: "success",
      message: "link copied to your clipboard",
    });
  };
  const changePhoneNumber = () => {
    console.log("changePhoneNumber");
  };

  switch (type) {
    case "postPoneMessage":
      return <PostPoneMessage {...{ timestamp, meta }} />;
    case "SmsWaitingPatient":
      return <SmsWaitingPatient {...{ senderId, user, changePhoneNumber }} />;
    case "UnsupportedBrowser":
      return <UnsupportedPatientBrowser {...{ senderId, user, copyLink }} />;
    case "UnsupportedBrowserIOS":
      return <UnsupportedPatientBrowserIOS {...{ senderId, user, copyLink }} />;
    case "RequestCall":
      if (!OT.checkSystemRequirements()) {
        return Browser.isIOs() ? (
          <UnsupportedIOSBrowser {...{ user, copyLink }} />
        ) : (
          <UnsupportedBrowser {...{ user, copyLink }} />
        );
      }
      return (
        <RequestCall
          {...{ callType, appointment, user, senderId, sendJoining }}
        />
      );
    default:
      return (
        <div className="pre-chat-message">
          <p>{body}</p>
        </div>
      );
  }
};

export default DoctoMessage;
