import React from "react";
import Header from "./header";
import Footer from "./footer";

// import Loader from '@modules/loader';

const DoctoLayout = ({ children, props }) => {
  return (
    <div>
      <div className="landing-page" id="landing">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default DoctoLayout;
