export default {
  default: {
    title: "Instant online consultation",
    subtitle: "",
    firstName: "Given names",
    lastName: "Surname",
    email: "email",
    birthday: "DD/MM/YYYY (date of birth)",
    phoneNumber: "phone number",
    fullAddress: "Home address",
    medicareNumber: "Medicare Number (optional)",
    submitBtn: "Continue",
    footer: null,
    showSignIn: false,
  },
  nursingRoom: {
    title: "Virtual Emergency Department",
    subtitle: "Instant online consultation",
    email: "patient email",
  },
  assistravel: {
    subtitle: "",
    firstName: "Nombre",
    lastName: "Apellido",
    email: "email del paciente",
    birthday: "DD/MM/YYYY (dia de nacimiento)",
    phoneNumber: "numero de telefono",
    fullAddress: "Direccion de la casa",
    medicareNumber: null,
    submitBtn: "Continuar",
    footer:
      "Night surcharge (day rate × 2) applies 2200-0600 Brisbane time (0900-1700 Buenos Aires)",
    showSignIn: false,
  },
};
