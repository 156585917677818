import React, { useState } from "react";
import moment from "moment";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import MaskedInput from "react-maskedinput";
import {
  parseNumber,
  getCountryCallingCode,
  isValidNumber,
} from "libphonenumber-js";
import isEmpty from "is-empty";
import loadingLightBlue from "@assets/images/loadingLightBlue.gif";

const Signup = (props) => {
  const { close, setStep, submitting, createProfile, email } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [error, setError] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");

  const validateSignUp = () => {
    let errors = error;
    errors.phoneNumber =
      phoneNumber === undefined || !isValidNumber(phoneNumber)
        ? "Invalid Number"
        : null;
    errors.firstName = firstName ? null : "Please enter your name";
    errors.lastName = lastName ? null : "Please enter your name";
    errors.birthday = moment(birthday, "DD/MM/YYYY").isValid()
      ? null
      : "Invalid Date (DD/MM/YYYY)";
    errors = Object.entries(errors).reduce(
      (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
      {}
    );
    if (!error.birthday) {
      setBirthday("");
    }
    setError((prev) => {
      return { ...prev, ...errors };
    });
    console.log({ isempty: isEmpty(errors) });
    return isEmpty(errors);
  };
  const signup = () => {
    if (!validateSignUp()) {
      return;
    }
    const parsePhoneNumber = parseNumber(phoneNumber);
    const countryCode = getCountryCallingCode(parsePhoneNumber.country);
    const phone = parsePhoneNumber.phone;
    createProfile({
      firstName,
      lastName,
      birthday,
      phoneNumber: phone,
      countryCode,
      email,
    });
  };
  return (
    <div className="popup-wrap">
      <div className="modal-window md-sign-up">
        <div className="modal">
          <div className="md-header">
            <a href="#" className="md-close" onClick={close}>
              <i className="icon-cross-circle"></i>
            </a>
            <h4>Create Profile</h4>
          </div>

          <div className="md-body">
            <input
              tabIndex="6001"
              type="text"
              value={firstName}
              maxLength="50"
              placeholder={error?.firstName ? error?.firstName : "Given Names"}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              tabIndex="6002"
              type="text"
              value={lastName}
              maxLength="50"
              placeholder={error?.lastName ? error?.lastName : "Last Name"}
              onChange={(e) => setLastName(e.target.value)}
            />
            <MaskedInput
              mask="11/11/1111"
              placeholder={error?.birthday ? error?.birthday : "DD/MM/YYYY"}
              placeholderChar=" "
              value={birthday}
              type="tel"
              onChange={(e) => setBirthday(e.target.value)}
            />
            <PhoneInput
              country="AU"
              value={phoneNumber}
              smartCaret={false}
              placeholder={
                error?.phoneNumber ? error?.phoneNumber : "Enter phone number"
              }
              onChange={(phone) => setPhoneNumber(phone)}
            />
          </div>

          <div className="md-footer">
            <button className="btn btn-info" onClick={(e) => signup(e)}>
              {submitting ? (
                <img className="loading-login" src={loadingLightBlue} />
              ) : (
                "Email me a login link"
              )}
            </button>
            <button className="btn link" onClick={() => setStep("init")}>
              Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
