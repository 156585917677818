import React, { useEffect, useState } from "react";
import { history } from "@modules/";
import { User, Appointments } from "@helpers";
import { useDispatch } from "react-redux";
import {
  showDoctorNotes,
  showPatientMedicalRecord,
  showEditBookAppointmentModal,
} from "@modules/gui";
import { Toast } from "@helpers/index";
import downloadCsv from "download-csv";

const MoreOptions = (props) => {
  const { appointment, user, cancelAppointmentClick, userRole } = props;
  const [showOptions, setShowOptions] = useState(false);
  const appointmentId = appointment?.id;
  const bookStatus = appointment?.bookStatus;
  const dispatch = useDispatch();

  const parentHasClass = (element, classname) => {
    if (
      element.className &&
      element.className.split(" ").indexOf(classname) >= 0
    )
      return true;
    return element.parentNode && parentHasClass(element.parentNode, classname);
  };

  const handleClick = (e) => {
    const element = e.target;
    if (element.className !== "icon-ellipsis") {
      if (!parentHasClass(element, "ref-modal-more-options")) {
        setShowOptions(false);
      }
    }
  };

  const unmount = () => {
    document.removeEventListener("mousedown", handleClick, false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return unmount;
  }, []);

  const goToAppointmentFiles = () => {
    dispatch(showPatientMedicalRecord({ show: true, appointmentId }));
  };
  const goToAppointmentNotes = () => {
    dispatch(showDoctorNotes({ show: true, appointmentId }));
  };

  const goToEmergencyAppointment = () => {
    history(`/appointments/emergency/${appointmentId}`);
  };
  const downloadtoCsv = async () => {
    try {
      const response = await Appointments.getChatTranscripts(
        appointmentId,
        user.token
      );
      if (response.chats.length === 0) {
        Toast.displayToast({
          message: "there are no chat transcripts for this appointment",
          kind: "warning",
        });
        return;
      }
      const transcripts = response.chats.map(function (chat) {
        return {
          consultancy_date: new Date(chat.consultancy_date),
          Patient: User.userToNameString(chat.patient),
          Specialist: User.userToNameString(chat.doctor),
          Time: new Date(chat.unixTime),
          From: User.userToNameString(chat.user),
          Message: chat.message,
        };
      });
      const columns = { consultancy_date: "Consultancy Date" };
      const fileName = appointmentId;
      downloadCsv(transcripts, columns, fileName);
    } catch (error) {
      console.error(error);
      Toast.displayToast({
        message: "Error Occur Please contact administration",
        kind: "error",
      });
    }
  };
  const editScheduledAp = () => {
    dispatch(showEditBookAppointmentModal({ appointmentId: appointment.id }));
  };

  const renderOptions = (option) => {
    switch (option) {
      case "delete":
        return (
          <button
            key="delete-option"
            onClick={() => cancelAppointmentClick()}
            className="inner-pill ip-red"
          >
            <i className="icon-trash2"></i> Cancel consultation
          </button>
        );
      case "edit":
        return (
          <button
            key="edit-option"
            onClick={() => editScheduledAp()}
            className="inner-pill ip-red"
          >
            <i className="icon-pencil2"></i> Edit appointment
          </button>
        );

      case "joinNowEmergency":
        return (
          <button
            onClick={() => goToEmergencyAppointment()}
            key="join now"
            className="inner-pill ip-green"
          >
            <i className="icon-group-work"></i> Join Now
          </button>
        );
      case "notes":
        return (
          <button
            key="notes"
            onClick={() => goToAppointmentNotes()}
            className="inner-pill ip-green"
          >
            <i className="icon-document"></i> Notes
          </button>
        );
      case "files":
        return (
          <button
            key="files-option"
            onClick={() => goToAppointmentFiles()}
            className="inner-pill ip-green"
          >
            <i className="icon-files"></i> Medical Records
          </button>
        );
      case "csv":
        return (
          <button
            key="csv-option"
            onClick={() => downloadtoCsv()}
            className="inner-pill ip-green"
          >
            <i className="icon-register"></i> chat-csv
          </button>
        );
      default:
        return null;
    }
  };
  const renderAdminOptions = () => {
    let options = [null];
    switch (bookStatus) {
      case "ADMIN-REVIEW":
      case "NEW":
      case "PREAPROVED-DATES":
      case "REQUEST-DATES":
      case "ACCEPTED":
        options.push(renderOptions("delete"));
        options.push(renderOptions("notes"));
        options.push(renderOptions("files"));
        options.push(renderOptions("csv"));
        options.push(renderOptions("edit"));
        break;
      case "CANCELLED":
      case "EMERGENCY-CONSULTATION":
      case "COMPLETED":
      case "EXPIRED":
        options.push(renderOptions("notes"));
        options.push(renderOptions("files"));
        options.push(renderOptions("csv"));
        break;
      default:
        return null;
    }
    return options;
  };
  const renderDoctorOptions = (bookStatus) => {
    let options = [null];
    switch (bookStatus) {
      case "ADMIN-REVIEW":
      case "NEW":
      case "PREAPROVED-DATES":
      case "REQUEST-DATES":
      case "ACCEPTED":
        options.push(renderOptions("delete"));
        options.push(renderOptions("notes"));
        options.push(renderOptions("files"));
        options.push(renderOptions("csv"));
        break;
      case "CANCELLED":
      case "EMERGENCY-CONSULTATION":
      case "COMPLETED":
      case "EXPIRED":
        options.push(renderOptions("notes"));
        options.push(renderOptions("files"));
        options.push(renderOptions("csv"));
        break;
      default:
        return null;
    }
    return options;
  };
  const renderPatientOptions = (bookStatus) => {
    let options = [null];
    switch (bookStatus) {
      case "ADMIN-REVIEW":
      case "NEW":
      case "PREAPROVED-DATES":
      case "REQUEST-DATES":
      case "ACCEPTED":
        options.push(renderOptions("delete"));
        break;

      case "EMERGENCY-CONSULTATION":
        options.push(renderOptions("joinNowEmergency"));
        break;
      case "CANCELLED":
      case "COMPLETED":
      case "EXPIRED":
        break;
      default:
        return null;
    }
    return options;
  };
  const renderOptionsByRole = (role, bookStatus) => {
    switch (role) {
      case "doctor":
        return renderDoctorOptions(bookStatus);
      case "admin":
        return renderAdminOptions(bookStatus);
      case "patient":
      default:
        return renderPatientOptions(bookStatus);
    }
  };

  return (
    <div className="chat-action-list-ittem ">
      <div onClick={() => setShowOptions(!showOptions)} className="cpl-trigger">
        <button className="cpl-options">
          <i className="icon-ellipsis"></i>
        </button>
        <div
          className={
            showOptions
              ? "ref-modal-more-options cpl-tooltip show"
              : "ref-modal-more-options cpl-tooltip"
          }
        >
          {renderOptionsByRole(userRole, bookStatus)}
        </div>
      </div>
    </div>
  );
};

export default MoreOptions;
