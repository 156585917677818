import React, { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";
import api from "@src/api";
import { Routes } from "@constants/";
import { useSelector } from "react-redux";
import spin from "@assets/images/spin.gif";
import mime from "mime-types";

const DownloadReferral = (props) => {
  const { key } = props.computedMatch.params;
  const user = useSelector((state) => state.session.user);
  const url = Routes.downloadReferralRequest(key, user.token);
  const [file, setFile] = useState(null);
  const [blob, setBlob] = useState(null);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    loadDocument();
  }, [url]);

  const loadDocument = async () => {
    try {
      // setFile({ type, source: url });

      const b = await api.getBlob(`${url}`);
      const f = decodeURIComponent(key).split("/").pop().toLowerCase();
      setFileName(f);
      setBlob(b);
      const fileURL = URL.createObjectURL(b);
      const type = mime.extension(b.type);
      setFile({ type, source: fileURL });
    } catch (error) {
      console.error(error);
    }
  };
  const showFile = () => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = data;
    link.download = fileName;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  };

  return (
    <div className="modal-window">
      <div className="md-align-view-file">
        <div className="modal-view-file">
          <div className="md-header">
            <button className="btn-info mt-5" onClick={() => showFile()}>
              Download
            </button>
          </div>

          {file ? (
            <FileViewer fileType={file.type} filePath={file.source} />
          ) : (
            <div className="md-loader">
              <img src={spin} className="logo-spin" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DownloadReferral;
