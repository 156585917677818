/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import { Helmet } from "react-helmet";
import MedicareModal from "@src/components/modals/medicare";
import { Routes } from "@constants";
import { Specialties, User } from "@helpers";
import { sortBy } from "lodash";
import initials from "initials";
import { connect } from "react-redux";
import Loader from "./loader";
import { SHOW_SPECIALTY_BOT } from "@modules/gui";

const DoctorImage = ({ user, hidden }) => {
  let divStyle = {};
  if (typeof user.thumbnailImage !== "undefined") {
    divStyle = {
      backgroundImage:
        "url(" + Routes.thumbnailProfilePicUrl(user.thumbnailImage) + ")",
    };
  }
  const ini = initials(User.fullName(user)).split("").join(".");
  return (
    <div className={hidden ? "spec-image-ittem hidden" : "spec-image-ittem"}>
      <div style={divStyle} className="si-image"></div>
      <div className="si-name">
        <span>
          {User.titleString(user)} {ini}
        </span>
      </div>
    </div>
  );
};

class SpecialtyBox extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedIndex: 0,
    };
  }

  bookSpecialty(specialty) {
    this.props.bookSpecialty(specialty);
  }
  nextDoctor() {
    var nextIndex = this.state.selectedIndex + 1;
    if (
      !this.props.specialty.users ||
      nextIndex >= this.props.specialty.users.length
    ) {
      nextIndex = 0;
    }
    this.setState({ selectedIndex: nextIndex });
  }

  prevDoctor() {
    var nextIndex = this.state.selectedIndex - 1;
    if (!this.props.specialty.users || nextIndex < 0) {
      nextIndex = 0;
    }
    this.setState({ selectedIndex: nextIndex });
  }

  render() {
    var specialty = this.props.specialty;

    return (
      <div className="spec-block" key={specialty.id}>
        <h2>
          <a onClick={() => this.bookSpecialty(specialty)}>{specialty.name}</a>
        </h2>
        <p>{specialty.description}</p>
        <div className="spec-image-wrap">
          {specialty.users
            ? specialty.users.map((user, index) => {
                return (
                  <DoctorImage
                    key={`${user.id}_userpic`}
                    hidden={
                      index >= this.state.selectedIndex + 3 ||
                      index < this.state.selectedIndex
                    }
                    user={user}
                  />
                );
              })
            : null}
        </div>

        {specialty.users && specialty.users.length > 3 && (
          <div className="spec-image-action">
            <div className="sia-left" onClick={() => this.prevDoctor()}>
              <i className="icon-chevron-left"></i>
            </div>
            <div className="sia-right" onClick={() => this.nextDoctor()}>
              <i className="icon-chevron-right"></i>
            </div>
          </div>
        )}

        <a onClick={() => this.bookSpecialty(specialty)} className="btn-find">
          Book Now
        </a>
      </div>
    );
  }
}

class BrowseHospital extends React.Component {
  constructor() {
    super();
    this.state = {
      specialties: [],
      specialtyId: "",
      showMedicare: false,
      showDropDownMenu: false,
      specialtyDrsIndex: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const fetchData = async () => {
      const specialties = await Specialties.fetchSpecialtiesWithExtras(
        "medical"
      );
      this.setState({ loading: false, specialties: specialties });
    };
    fetchData();
  }

  onChangeTab(type) {
    console.log("on change tab");
    // flux.actions.specialties.setActive(type);
    // flux.actions.specialties.fetchSpecialties(type);
  }

  bookSpecialty(specialty) {
    if (specialty.publicUrl) {
      return this.props.history.push(`/${specialty.slug}`);
    }
    return this.showBot(specialty);
  }
  showBot(specialty) {
    this.props.showSpecialtyBot(specialty.id);
  }
  render() {
    const { loading, specialties, showDropDownMenu } = this.state;

    var sortByName = (a, b) => {
      var nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    };

    let { location } = this.props;
    const isActive = location.hash === "#allied" ? "allied" : "medical";
    let medicalClass = isActive === "medical" ? "tab active" : "tab";
    let alliedClass = isActive === "allied" ? "tab active" : "tab";
    const sortedSpecialties =
      specialties.length > 0
        ? sortBy(specialties, (specialty2) => {
            return specialty2.order;
          })
        : [];
    const sortSpecialtyByName =
      specialties.length > 0 ? specialties.sort(sortByName) : [];

    return (
      <div>
        <Helmet>
          <title>Medical specialist Telehealth consultations</title>
          <meta
            name="description"
            content="Medical specialist telehealth online consultations. Specialties include Dermatology, Neurology, Paediatrics, ENT, Rheumatology and all tertiary hospital specialties."
          />
          <link
            rel="canonical"
            href="https://www.docto.com.au/browse-hospital"
          />
        </Helmet>

        <div
          className="sub--hero hero--cover--search"
          onClick={
            showDropDownMenu
              ? () => this.setState({ showDropDownMenu: false })
              : null
          }
        >
          <h1 className="hero__title">
            Medical Specialist Telehealth Consultations
          </h1>

          <form className="find-spec-form">
            <div className="dropdown">
              <button
                onClick={() => this.setState({ showDropDownMenu: true })}
                className="dropdown-toggle rounded"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                BOOK BY SPECIALTY
              </button>

              <div
                className={
                  showDropDownMenu ? "dropdown-menu show" : "dropdown-menu"
                }
                aria-labelledby="dropdownMenuButton"
              >
                {sortSpecialtyByName.map((specialty) => {
                  return (
                    <a
                      key={`dropdownKey-${specialty.id}`}
                      className="dropdown-item"
                      onClick={() => this.bookSpecialty(specialty)}
                    >
                      {specialty.name}
                    </a>
                  );
                })}
                <a
                  key={`dropdownKey-other`}
                  className="dropdown-item"
                  href="/contact"
                >
                  Other
                </a>
              </div>
            </div>
          </form>
        </div>
        {loading && <Loader />}
        {!loading && (
          <section>
            <div className="browse--hospital">
              <div className="browse__tabs hide">
                <a
                  href="#medical"
                  className={medicalClass}
                  onClick={() => this.onChangeTab("medical")}
                >
                  <i className="icon-checkmark-circle"></i> Medical Specialists
                </a>
                <a
                  href="#allied"
                  className={alliedClass}
                  onClick={() => this.onChangeTab("allied")}
                >
                  <i className="icon-checkmark-circle"></i> Allied Health
                </a>
              </div>

              <div className="spec-wrap">
                {sortedSpecialties.map((specialty) => {
                  return (
                    <SpecialtyBox
                      key={`${specialty.id}_spec`}
                      bookSpecialty={() => this.showBot(specialty)}
                      specialty={specialty}
                    />
                  );
                })}
              </div>
            </div>
          </section>
        )}
        {this.state.showMedicare && (
          <MedicareModal
            onClose={() => this.setState({ showMedicare: false })}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showSpecialtyBot: (specialtyId, params = {}) =>
      dispatch({
        type: SHOW_SPECIALTY_BOT,
        params: { specialtyId, show: true, params },
      }),
    showEmergencyBot: (show, initMessage) =>
      dispatch({
        type: SHOW_SPECIALTY_BOT,
        show: false,
      }),
  };
};
const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(BrowseHospital);
