import React, { Component } from "react";

class EditableElement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.element = null;
    this.emitChange = this.emitChange.bind(this);
  }
  shouldComponentUpdate(nextProps) {
    return nextProps.html !== document.getElementById(this.props.id).innerHTML;
  }
  emitChange() {
    const el = document.getElementById(this.props.id).children;
    const elements = Array.from(el);
    const values = elements
      .map((elem) => {
        return elem.innerHTML;
      })
      .join(`<br/>`);

    if (values) {
      if (this.props.onChange && values !== this.lastHtml) {
        this.props.onChange(this.props.id, values);
      }
      this.lastHtml = values;
      return;
    }
  }
  render() {
    return (
      <div
        id={this.props.id}
        onBlur={this.emitChange}
        contentEditable={this.props.enabled}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: this.props.html }}
      ></div>
    );
  }
}

export default EditableElement;
