import { Cookies } from "react-cookie";
const cookies = new Cookies();

const Token = class Token {
  static getToken() {
    return cookies.get("accessToken");
  }
  static getGuestToken() {
    return cookies.get("guestToken");
  }
  static setGuestToken(token) {
    console.log("set guestToken", token);
    cookies.set("guestToken", token, { path: "/" });
  }
  static getMaskToken() {
    return cookies.get("mascaradeToken");
  }
  static clearMaskToken() {
    return cookies.remove("mascaradeToken", { path: "/" });
  }
  static setMaskToken(token) {
    cookies.set("mascaradeToken", token, { path: "/" });
  }
  static setToken(token) {
    console.log("set token", token);
    cookies.set("accessToken", token, { path: "/" });
  }
  static clearToken() {
    console.log("clear token");
    cookies.remove("accessToken", { path: "/" });
  }
  static clearGuestToken() {
    console.log("clear guestTOken");
    cookies.remove("guestToken", { path: "/" });
  }
};
export default Token;
