import api from "@api";
import { Routes } from "@constants/";

const Services = class Services {
  static async isMedicareElegibleWithPostalCode(postCode) {
    return await api.get(
      `${Routes.isMedicareElegibleWithPostalCode(postCode)}`
    );
  }
  static async isMedicareElegibleWithLocation(long, lat) {
    return await api.get(`${Routes.isMedicareElegible(long, lat)}`);
  }
  static async validateDoctoEmail(email) {
    return await api.get(Routes.validateDoctoEmail(email));
  }
  static async findPharmacy(value) {
    return await api.post(Routes.findPharmacy(), { search: value });
  }
  static async sendSmsCode(email, phoneNumberWithCountryCode) {
    return await api.post(Routes.sendSmsCode(), {
      email: email,
      fullPhoneNumber: phoneNumberWithCountryCode,
    });
  }
  static async getNearbyPharmacy(lat, long) {
    return await api.post(Routes.getNearbyPharmacy(), { lat, long });
  }
  static async getSessiontest() {
    return await api.post(`${Routes.userSessionTest}`, { timeout: 5000 });
  }
  static async verifySmsCode(email, pinCode) {
    return await api.post(Routes.verifySmsCode(), { email, pinCode });
  }
};
export default Services;
