import React from "react";
const TableLoader = ({ columns }) => {
  const amount = [1, 2, 3, 4, 5];

  return (
    <div className="ReactTable no-hover patient-table -striped -highlight txt-center">
      <div className="rt-table">
        <div className="rt-thead -header">
          <div className="rt-tr">
            {columns.map((col, key) => {
              return (
                <div
                  key={`header-${key}-${col}`}
                  className="rt-th rt-resizable-header"
                >
                  <div className="rt-resizable-header-content">{col}</div>
                  <div className="rt-resizer"></div>
                </div>
              );
            })}
          </div>
        </div>

        {amount.map((times) => {
          return (
            <div key={`col-${times}`}>
              <div className="rt-tbody">
                <div className="rt-tr-group">
                  <div className="rt-tr">
                    {columns.map((col, key) => {
                      return (
                        <div key={`${times}-${col}-${key}`} className="rt-td">
                          <div className="loader-animation">
                            <div></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="rt-tbody">
                <div className="rt-tr-group">
                  <div className="rt-tr -odd">
                    {columns.map((col, key) => {
                      return (
                        <div key={`2-${times}-${col}-${key}`} className="rt-td">
                          <div className="loader-animation">
                            <div></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableLoader;
