import api from "@src/api";
import { Routes } from "@constants";

const savePaymentMethod = (id, token, creditCardInfo) => {
  return api.post(Routes.savePaymentMethod(id, token), creditCardInfo);
};

const deleteCreditCard = (userId, token) => {
  return api.del(Routes.userMethodWithToken(userId, token, "creditCard"));
};

export default { savePaymentMethod, deleteCreditCard };
