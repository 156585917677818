import React from "react";
import empty from "is-empty";
import $ from "jquery";
window.$ = $;

const autoCompleteComponentForm = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  country: "long_name",
  postal_code: "short_name",
};
const autoCompleteDoctoAddress = {
  street_number: "address",
  route: "address",
  locality: "address2",
  administrative_area_level_1: "region",
  country: "country",
  postal_code: "postcode",
};

class AutoCompleteAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {
        address2: null,
        address: null,
        region: null,
        country: null,
        postcode: null,
      },
    };
  }
  componentWillUnmount() {
    this.setState({
      address: {
        address2: null,
        address: null,
        region: null,
        country: null,
        postcode: null,
      },
    });
    this.autocomplete = null;
  }
  componentDidMount() {
    this.initAutocomplete();
  }
  initAutocomplete() {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      this.refs.autocomplete,
      { types: ["geocode"] }
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(["address_component"]);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autocomplete.addListener("place_changed", () => this.fillInAddress());
    setTimeout(function () {
      $(".pac-container").prependTo(".bot-send-form");
    }, 300);
  }

  fillInAddress() {
    try {
      let place = this.autocomplete.getPlace();
      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      let res = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (autoCompleteComponentForm[addressType]) {
          let key = autoCompleteDoctoAddress[addressType];
          let val =
            place.address_components[i][autoCompleteComponentForm[addressType]];
          res[key] = res[key] ? `${res[key]} ${val}` : val;
        }
      }
      this.setState({ address: res });
    } catch (error) {
      this.setState({ address: {} });
    }
  }
  setAddress() {
    if (empty(this.refs.autocomplete.value)) {
      return;
    }
    let address = this.state.address;
    address.inputText = this.refs.autocomplete.value;
    if (!address.country) {
      address.address = address.inputText;
    }
    this.props.onAddressSelect(address);
  }

  // Bias the autocomplete object to the user's geographical location,
  // as supplied by the browser's 'navigator.geolocation' object.
  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // eslint-disable-next-line no-undef
        let circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        this.autocomplete.setBounds(circle.getBounds());
      });
    }
  }
  keyEnter(ev) {
    if (ev.key === "Enter") {
      this.setAddress();
    }
  }
  render() {
    return (
      <div className="bot-send-form stick-bottom">
        <input
          ref="autocomplete"
          onFocus={() => this.geolocate()}
          placeholder="Enter your address"
          onKeyPress={(ev) => this.keyEnter(ev, false)}
          type="text"
        />

        <button onClick={() => this.setAddress()}>Send</button>
      </div>
    );
  }
}
export default AutoCompleteAddress;
