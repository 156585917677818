import React from "react";

const VideoBox = (props) => {
  let extraClass = props.extraClass || "";
  let expanded = props.expanded
    ? "vid-box-float vid-full expand"
    : "vid-box-float";

  return (
    <div key="video" className={`${expanded} ${extraClass}`}>
      <div className="vid-header"></div>
      <div className="vid-body" id={props.id}></div>
      <div className="vid-name">
        <span>{props.title}</span>
      </div>
    </div>
  );
};

export default VideoBox;
