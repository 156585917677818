import React from "react";
import MaskedInput from "react-maskedinput";
import { useField } from "react-final-form";

export const DateField = ({
  name,
  label,
  fieldProps,
  formControlProps,
  formLabelProps,
  groupClass = null,
  showError = ({ meta: { touched, invalid } }) => touched && invalid,
  type,
  isDisabled,
  ...props
}) => {
  const { input, meta } = useField(name, { type, ...fieldProps });
  const { error } = meta;
  const isError = showError({ meta });
  let fieldClass = groupClass ? groupClass : "form-group";
  fieldClass = isError ? `${fieldClass} alert-error` : `${fieldClass}`;
  return (
    <div
      className={fieldClass}
      disabled={isDisabled}
      {...formControlProps}
      data-alert={error || ""}
    >
      {!!label && (
        <label htmlFor={input.name} {...formLabelProps}>
          {label}
        </label>
      )}
      <MaskedInput
        {...props.input}
        mask="11/11/1111"
        placeholder="dd/mm/yyyy"
      />
    </div>
  );
};
export default DateField;
