import api from "@api";
import { Routes } from "@constants/";
import { Analytics } from "@helpers/";

const fetchAppointments = (token, type, role, page) => {
  return api.get(Routes.getAppointments(token, type, role, page));
};

const getDetailsAppointments = (token, role) => {
  return api.get(Routes.getDetailsAppointments(token, role));
};
const cancelAppointment = (appointmentId, token) => {
  return api.put(Routes.cancelAppointment(appointmentId, token));
};
const deleteAppointment = (appointmentId, token) => {
  return api.del(Routes.destroyAppointmentById(appointmentId, token));
};
const getAppointmentPatientToken = (token, appointmentId) => {
  return api.post(Routes.getAppointmentPatientToken(token), {
    appointmentId: appointmentId,
  });
};
const uploadAppointmentPatientReferralDocument = (
  formData,
  appointmentId,
  token
) => {
  return api.multiPart(
    Routes.uploadAppointmentPatientReferralDocument(appointmentId, token),
    formData
  );
};
const createUserWithAppointmentRequest = async (payload) => {
  const response = await api.post(
    Routes.createUserWithAppointmentRequest(),
    payload
  );
  console.log("uno ---");
  const { appointment, payment } = response;
  if (payment) {
    Analytics.trackConversion({
      id: payment.id,
      price: payment.price,
      specialty: payment.specialtyName,
      type: appointment.type,
    });
  }

  //response.payment
  return response;
};
const createPatientWithSpecialtyRequestNoReferral = async (payload) => {
  return await api.post(
    Routes.createPatientWithSpecialtyRequestNoReferral(),
    payload
  );
};
const createAppointmentRequest = async (payload, token) => {
  const response = await api.post(
    Routes.createAppointmentRequest(token),
    payload
  );
  console.log("dos ---");
  //response.payment
  const { appointment, payment } = response;
  if (payment) {
    Analytics.trackConversion({
      id: payment.id,
      price: payment.price,
      specialty: payment.specialtyName,
      type: appointment.type,
    });
  }

  return response?.appointment;
};
const getAdminAppointments = (type, query) => {
  return api.get(Routes.getAdminAppointments(type, query));
};
const validateEmergencyAppointmentAccess = (appointmentId, token) => {
  return api.get(
    Routes.validateEmergencyAppointmentAccess(appointmentId, token)
  );
};
const acceptEmergencyAppointment = (appointmentId, token) => {
  return api.post(Routes.acceptEmergencyAppointment(appointmentId, token));
};
const createPatientWithEmergencyAccess = async ({
  card,
  patient,
  consultInfo,
  confirmation,
  companyId,
}) => {
  const response = await api.post(Routes.createPatientWithEmergencyAccess(), {
    card,
    patient,
    consultInfo,
    confirmation,
    companyId,
  });
  //response.payment;
  console.log("tres ---");
  const { appointment, payment } = response;
  if (payment) {
    Analytics.trackConversion({
      id: payment.id,
      price: payment.price,
      specialty: payment.specialtyName,
      type: appointment.type,
    });
  }

  return response;
};
const companyEmergencyRequestForPatient = ({ patient, companyId }) => {
  return api.post(Routes.companyEmergencyRequestForPatient(), {
    patient,
    companyId,
  });
};

const createPatientWithFreeEmergencyAccess = (patient, consultInfo) => {
  return api.post(Routes.createPatientWithFreeEmergencyAccess(), {
    patient,
    consultInfo,
  });
};
const createPatientWithNurseEmergencyAccess = (token, patient, consultInfo) => {
  return api.post(Routes.createPatientWithNurseEmergencyAccess(token), {
    patient,
    consultInfo,
  });
};
const requestEmergencyAccessWithPayment = async ({
  token,
  card,
  confirmation,
  consultInfo,
  companyId,
}) => {
  const response = await api.post(
    Routes.requestEmergencyAccessWithPayment(token),
    {
      card,
      confirmation,
      consultInfo,
      companyId,
    }
  );
  //response.payment
  console.log("cuatro ---");
  const { appointment, payment } = response;
  if (payment) {
    Analytics.trackConversion({
      id: payment.id,
      price: payment.price,
      specialty: payment.specialtyName,
      type: appointment.type,
    });
  }
  return response;
};
const requestFreeEmergencyAccess = (token, consultInfo) => {
  return api.post(Routes.requestFreeEmergencyAccess(token), {
    consultInfo,
  });
};
const requestNurseEmergencyAccess = (token, consultInfo, email) => {
  return api.post(Routes.requestNurseEmergencyAccess(token), {
    consultInfo,
    email,
  });
};
const uploadAppointmentReferralDocument = (appointmentId, token, formData) => {
  return api.multiPart(
    Routes.uploadAppointmentReferralDocument(appointmentId, token),
    formData
  );
};
const fetchAppointmentChat = (appointmentId, token) => {
  return api.get(Routes.fetchAppointmentChat(appointmentId, token));
};
const sendEmergencyCallReminder = (appointmentId, token) => {
  return api.post(Routes.sendEmergencyCallReminder(appointmentId, token));
};
const requestMessages = (appointmentId, currentUser, token) => {
  return api.get(Routes.requestMessages(appointmentId, token));
};
const acceptAppointmentDate = async (id, token, card, date) => {
  const response = await api.post(Routes.acceptAppointmentDate(id, token), {
    card,
    date,
  });
  //response.payment
  console.log("cinco ---");
  const { appointment, payment } = response;
  if (payment) {
    Analytics.trackConversion({
      id: payment.id,
      price: payment.price,
      specialty: payment.specialtyName,
      type: appointment.type,
    });
  }

  return response?.appointment;
};
const requestNewDate = (id, token) => {
  return api.post(Routes.requestNewDates(id, token), {});
};
const acceptAppointment = (appointmentId, token, payload) => {
  // payload {userId} || {patientId}
  return api.post(Routes.acceptAppointment(appointmentId, token), payload);
};
const fetchDoctorNotesByAppointment = (appointmentId, token) => {
  return api.get(Routes.fetchDoctorNotesByAppointment(appointmentId, token));
};
const deleteDoctorNote = (noteId, token) => {
  return api.del(Routes.updateDoctorNote(noteId, token));
};
const updateDoctorNote = (noteId, token, message) => {
  return api.put(Routes.updateDoctorNote(noteId, token), { message });
};

const createDoctorNoteByAppointment = (appointmentId, token, message) => {
  return api.post(Routes.createDoctorNoteByAppointment(appointmentId, token), {
    message,
  });
};
const listPatientDocumentsByAppointmentId = (appointmentId, token) => {
  return api.get(
    Routes.listPatientDocumentsByAppointmentId(appointmentId, token)
  );
};
const removeReferralFile = (appointmentId, fileName, token) => {
  return api.del(Routes.removeReferralFile(appointmentId, fileName, token));
};
const validateAppointmentAccess = (appointmentId, token) => {
  return api.get(Routes.validateAppointmentAccess(appointmentId, token));
};
const getAppointment = (appointmentId, token) => {
  return api.get(Routes.getAppointment(appointmentId, token));
};
const acceptAppointmentGuest = (appointmentId, payload) => {
  return api.post(Routes.acceptAppointmentGuest(appointmentId), payload);
};
const updateBookStatus = (appointmentId, token, payload) => {
  return api.post(Routes.updateBookStatus(token, appointmentId), payload);
};
const changeConsultationCost = (appointmentId, token, price) => {
  return api.post(Routes.changeConsultationCost(appointmentId, token), {
    price,
  });
};
const getChatTranscripts = (appointmentId, token) => {
  return api.get(Routes.getChatTranscripts(appointmentId, token));
};
const listPatientReferralDocuments = (appointmentId, token) => {
  return api.get(Routes.listPatientReferralDocuments(appointmentId, token));
};
const deleteReferralPatientDocument = (appointmentId, fileName, token) => {
  return api.del(
    Routes.deleteReferralPatientDocument(appointmentId, fileName, token)
  );
};
const getAppointmentWithMessages = (appointmentId, token) => {
  return api.get(Routes.getAppointmentWithMessages(appointmentId, token));
};
const createChat = (appointmentId, payload) => {
  return api.post(Routes.createChat(appointmentId), payload);
};
const getMessages = (page, role, token) => {
  return api.get(Routes.getMessages(token, role, page));
};
const pendingAmount = (appointment) => {
  const {
    isMedicareElegible,
    medicareFeeCents,
    price,
    amountPaid,
  } = appointment;
  const nextprice = isMedicareElegible
    ? Number(medicareFeeCents)
    : Number(price);
  const pendingAmountCents = nextprice - amountPaid;
  const pendingAmount = pendingAmountCents / 100;
  return pendingAmount < 0 ? 0 : pendingAmount;
};
const getToken = (sessionId, token) => {
  return api.get(Routes.getToken(sessionId, "doctor", token));
};
const createFreeScheduledAppointment = async (token, appointmentData) => {
  return await api.post(
    Routes.createFreeScheduledAppointment(token),
    appointmentData
  );
};

const updateFreeScheduledAppointment = async (token, appointmentData) => {
  return await api.put(
    Routes.updateFreeScheduledAppointment(appointmentData.id, token),
    appointmentData
  );
};

const appointmentsWithToken = (token, appointmentData) => {
  return api.post(Routes.appointmentsWithToken(token), appointmentData);
};
const createScheduledAppointmentDoctorRequest = (
  patientId,
  doctorId,
  price,
  sheduledDate,
  token
) => {
  return api.post(Routes.createScheduledAppointmentDoctorRequest(token), {
    patientId,
    doctorId,
    price,
    sheduledDate,
  });
};
const getDoctorAppointmentsByDate = (userId, dateFormat, token) => {
  return api.get(Routes.getDoctorAppointmentsByDate(userId, dateFormat, token));
};
const registerFeedBack = (appointmentId, payload, token) => {
  return api.post(Routes.registerFeedBack(appointmentId, token), payload);
};
const endAppointment = (appointmentId, token, endByPatient) => {
  return api.put(`${Routes.endAppointment(appointmentId, token)}`, {
    endByPatient,
  });
};
const editSpecialtyAppointment = (
  appointmentId,
  token,
  { preApprovedDates, userId, askDatesToDoctor }
) => {
  return api.post(`${Routes.editSpecialtyAppointment(appointmentId, token)}`, {
    preApprovedDates,
    userId,
    askDatesToDoctor,
  });
};
export default {
  editSpecialtyAppointment,
  companyEmergencyRequestForPatient,
  pendingAmount,
  getMessages,
  createChat,
  getAppointmentWithMessages,
  deleteReferralPatientDocument,
  listPatientReferralDocuments,
  getChatTranscripts,
  changeConsultationCost,
  updateBookStatus,
  acceptAppointmentGuest,
  getAppointment,
  validateAppointmentAccess,
  endAppointment,
  registerFeedBack,
  getDoctorAppointmentsByDate,
  createScheduledAppointmentDoctorRequest,
  getAppointmentPatientToken,
  appointmentsWithToken,
  getDetailsAppointments,
  cancelAppointment,
  deleteAppointment,
  uploadAppointmentPatientReferralDocument,
  createUserWithAppointmentRequest,
  createPatientWithSpecialtyRequestNoReferral,
  createAppointmentRequest,
  getAdminAppointments,
  acceptAppointmentDate,
  requestNewDate,
  validateEmergencyAppointmentAccess,
  acceptEmergencyAppointment,
  createPatientWithEmergencyAccess,
  createPatientWithFreeEmergencyAccess,
  createPatientWithNurseEmergencyAccess,
  requestEmergencyAccessWithPayment,
  requestFreeEmergencyAccess,
  requestNurseEmergencyAccess,
  uploadAppointmentReferralDocument,
  fetchAppointmentChat,
  sendEmergencyCallReminder,
  requestMessages,
  acceptAppointment,
  fetchDoctorNotesByAppointment,
  deleteDoctorNote,
  updateDoctorNote,
  createDoctorNoteByAppointment,
  listPatientDocumentsByAppointmentId,
  removeReferralFile,
  createFreeScheduledAppointment,
  updateFreeScheduledAppointment,
  getToken,
  fetchAppointments,
};
