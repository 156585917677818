import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { User, Toast } from "@helpers/";
import UserProfileForm from "./form";
import Preloader from "./preloader";
import PoweredByBrand from "components/powered_by_brand";
import empty from "is-empty";
import { showConfirmationModal, closeConfirmationModal } from "@modules/gui";

function index() {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const viewer = useSelector((state) => state.session.user);

  const fetchUser = async () => {
    try {
      const user = await User.fetchUser(viewer.id, viewer.token);
      setUser(user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [viewer]);

  const submit = (values) => {
    try {
      User.updateUser(values, viewer.id, viewer.token).then(() => {
        Toast.displayToast({
          type: "success",
          message: "Your profile has been updated",
        });
      });
    } catch (error) {
      Toast.displayToast({
        type: "error",
        message: "Something went wrong, please try again",
      });
    }
  };

  const removeAccount = async () => {
    const { id, token } = viewer;
    dispatch(closeConfirmationModal());
    try {
      await User.softDeleteUser(id, token);
      window.location.href = "/";
    } catch (error) {
      Toast.displayToast({ type: "error", message: error });
    }
  };

  const confirmDeleteAccount = () => {
    dispatch(
      showConfirmationModal({
        cancelText: "Cancel",
        proceedText: "Delete my account",
        onProceed: () => {
          removeAccount();
        },
        onCancel: () => {
          dispatch(closeConfirmationModal());
        },
        children:
          "If you choose to close Docto account, keep in mind that you won't be able to login again.",
      })
    );
  };
  if (empty(user))
    return (
      <div className="prof-body">
        <Preloader />
      </div>
    );

  let basicInfoInputs = [
    {
      label: "First name:",
      ref: "firstName",
      required: true,
      value: user.firstName,
    },
    {
      label: "Last name:",
      ref: "lastName",
      required: true,
      value: user.lastName,
    },
    {
      label: "Email:",
      ref: "email",
      required: true,
      value: user.email,
    },
  ];

  return (
    <div className="prof-body">
      {User.isIncompleteProfileForPrescriptions(user) && (
        <div className="prof-message danger">
          <span className="prof-message__large">
            <b>{User.fullName(user)}</b>
            &nbsp; Account Incomplete
          </span>

          <span className="prof-message__small">
            *Essential for prescription and medical certificates
          </span>
        </div>
      )}

      <h2>Basic Information</h2>

      {basicInfoInputs.map((field) => (
        <div key={field.ref} className="form-group ">
          <label>{field.label}</label>
          <div className="hidden-input">
            <span>{field.value}</span>
          </div>
        </div>
      ))}

      {user && <UserProfileForm initialValues={user} onSubmit={submit} />}
      <button onClick={confirmDeleteAccount}>DELETE ACCOUNT</button>
      <PoweredByBrand />
    </div>
  );
}

export default index;
