import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import plusPrimary from "@src/assets/images/plus-primary-300x300.png";
import plusSecondary from "@src/assets/images/plus-secondary-300x300.png";

const About = () => {
  const consultDoctor = () => {
    console.log("showBot");
    // flux.actions.gui.showEmergencyChatBot(this.props.user);
  };
  return (
    <div>
      <Helmet>
        <title>about DOCTO</title>
        <meta
          name="description"
          content="DOCTO, the online hospital provides accelerated access to Australian Medical Specialists. Instant online consultations and specialist by appointment."
        />
        <link rel="canonical" href="https://www.docto.com.au/about" />
      </Helmet>
      <div className="hero  hero--middle  hero--cover  flex-embed  flex-embed--3by1">
        <div className="flex-embed__content">
          <div className="wrap">
            <div className="hero__body">
              <h1 className="hero__title" itemProp="headline">
                About Docto
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="site-inner">
        <div className="wrap">
          <section
            className="[ flexible-contents xflexible-contents--01 ]"
            id="section:"
          >
            <div className="features">
              <div className="feature__block">
                <div className="flag flag--responsive flag--flush">
                  <div className="flag__image desk-one-half">
                    <img alt="" src={plusPrimary} />
                  </div>

                  <div className="flag__body desk-one-half">
                    <p>
                      <Link to="/">DOCTO</Link> is a telehealth platform
                      providing patients with accelerated access to a{" "}
                      <Link to="/browse-hospital">
                        large network of Australian Specialists
                      </Link>{" "}
                      in over 25 disciplines via video consultation.
                    </p>

                    <p>
                      Offering streamlined secondary care options for patients,
                      and support for regional, rural and remote GPs and
                      healthcare workers, Docto acts as a doorbell to specialist
                      care no matter the postcode.
                    </p>
                    <p>
                      The costs of the consultations are subsidised by Medicare
                      and waiting time minimal with no need to travel for
                      specialist care. DOCTO’s professional network ensures all
                      patients are matched to the nearest, most appropriate
                      Specialist for their care in a matter of days instead of
                      months.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature__block">
                <div className="flag flag--responsive flag--flush flag--rev">
                  <div className="flag__image desk-one-half">
                    <img alt="" src={plusSecondary} />
                  </div>

                  <div className="flag__body desk-one-half">
                    <p>
                      Docto encourages collaboration with local services and
                      offers all Australian specialist doctors the opportunity
                      to take telehealth referrals through our site so that
                      patients may be matched to the closest service in their
                      community.
                    </p>
                    <p>
                      Recently supported by the Queensland Government’s{" "}
                      <a href="https://advance.qld.gov.au/" target="blank">
                        Advance Fund
                      </a>
                      , we aim to integrate with existing services to support
                      Australia’s healthcare workforce. Patients are encouraged
                      to present to our site with a referral from their regular
                      GP to ensure follow-up care.
                    </p>
                  </div>
                </div>
              </div>

              <div className="feature__block">
                <div className="flag flag--responsive flag--flush">
                  <div className="flag__image desk-one-half">
                    <img alt="" src={plusPrimary} />
                  </div>

                  <div className="flag__body desk-one-half">
                    <p>
                      We also encourage rural and remote GPs and healthcare
                      workers to reach out to us for free advice and support and
                      to{" "}
                      <Link to="/refer-patient">
                        book joint consultations with their patients here.
                      </Link>{" "}
                      Patients may also be referred via Medical Objects to
                      ‘Telehealth DOCTO’ or fax 07 3485 0689.
                    </p>
                    <p>
                      DOCTO prefers patients to obtain a referral from their
                      regular GP where possible, but the{" "}
                      <button onClick={consultDoctor}>
                        24-hour virtual ED
                      </button>
                      , can provide referrals and care after hours if needed.
                    </p>
                    <p>
                      For more information, contact Tanya Scardamaglia,{" "}
                      <a href="mailto:tanya@docto.com">tanya@docto.com</a> for a
                      demonstration of this innovative new support service for
                      Australian Rural GPs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default About;
