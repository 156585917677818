import api from "@api";
import { Routes, Medicare } from "@constants/";
import { parseNumber, getCountryCallingCode } from "libphonenumber-js";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const fetchDoctors = async (token) => {
  return await api.get(Routes.getEmergencyDoctors(token));
};
const fetchShifts = async () => {
  return await api.get(Routes.getShifts);
};
const fetchEmergencyDoctors = async (token) => {
  return await api.get(Routes.getEmergencyDoctors(token));
};
const fetchEmergencyDoctorShifts = () => {
  return api.get(Routes.getEmergencyShifts).then((res) => {
    const shifts = [];
    const dayShifts = ["morning", "morningSec", "night", "nightSec"];
    const findShift = (shifts, day, shiftName) => {
      return shifts.find((instance) => {
        return instance.day === day && instance.shift.name === shiftName;
      });
    };

    for (let i = 0; i < 7; i++) {
      let dayShift = {};
      let dayName = days[i];
      dayShift["day"] = dayName;

      for (let j = 0; j < 4; j++) {
        let shiftName = dayShifts[j];
        dayShift[shiftName] = findShift(res, dayName, shiftName);
      }
      shifts.push(dayShift);
    }
    return shifts;
  });
};

const updateEmergencyDoctor = (shiftObject, newDoctor, token) => {
  let apiPayload = {
    day: shiftObject.day,
    dayNumber: shiftObject.dayNumber,
    shiftId: shiftObject.shift.id,
    userId: newDoctor.id,
    id: shiftObject.id,
  };

  return api
    .put(Routes.updateEmergencyDoctor(token), apiPayload)
    .then((response) => {
      return response;
    });
};
const fetchAll = async (token, params) => {
  const param = Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");

  return await api.get(`${Routes.getAdminSpecialists(token)}&${param}`);
};
const delUserWithToken = (specialistId, token) => {
  return api.del(Routes.userWithToken(specialistId, token));
};
const appointmentsWithToken = (token, appointmentData) => {
  return api.post(Routes.appointmentsWithToken(token), appointmentData);
};
const mascarade = (patient, token) => {
  console.log("mascarate");
};
const softDelete = (userID, token) => {
  return api.del(Routes.softDeleteUser(userID, token));
};
const updateDoctor = (userId, token, password) => {
  return api.put(Routes.updateDoctor(userId, token), { password: password });
};

const createDoctorWithImage = (file, payload, token, doctorId) => {
  // if (payload.phoneNumber) {
  // 	var parsePhoneNumber = parseNumber(payload.phoneNumber);
  // 	payload.phoneNumber = parsePhoneNumber.phone;
  // 	payload.countryCode = getCountryCallingCode(parsePhoneNumber.country);
  // }
  // if (payload.practicePhoneNumber) {
  // 	var parsePracticePhoneNumber = parseNumber(payload.practicePhoneNumber);
  // 	payload.practicePhoneNumber = parsePracticePhoneNumber.phone;
  // 	payload.practiceCountryCode = getCountryCallingCode(
  // 		parsePracticePhoneNumber.country
  // 	);
  // }

  // if (payload.firstConsultMedicareItemNumber) {
  // 	var firstConsultMedicareItemNumber = Medicare.itemNumbers.find(function (
  // 		item
  // 	) {
  // 		return (
  // 			item['first-consult-item-number'] ===
  // 			payload.firstConsultMedicareItemNumber
  // 		);
  // 	});
  // 	payload.firstConsultMedicareRebate =
  // 		firstConsultMedicareItemNumber['first-consult-medicare-rebate'];
  // }

  // if (payload.followUpConsultMedicareItemNumber) {
  // 	var followUpConsultMedicareItemNumber = Medicare.itemNumbers.find(
  // 		(item) => {
  // 			return (
  // 				item['follow-up-consult-item-number'] ===
  // 				payload.followUpConsultMedicareItemNumber
  // 			);
  // 		}
  // 	);
  // 	payload.followUpConsultMedicareRebate =
  // 		followUpConsultMedicareItemNumber['follow-up-consult-medicare-rebate'];
  // }

  if (doctorId) {
    return api
      .put(Routes.updateDoctor(doctorId, token), payload)
      .then((response) => {
        if (file) {
          return uploadProfileImage(response.id, token, file).then();
        }
        return response;
      });
  } else {
    return api.post(Routes.createDoctor(token), payload).then((response) => {
      if (file) {
        return uploadProfileImage(response.id, token, file);
      }
      return response;
    });
  }
};

const uploadProfileImage = (userId, token, file) => {
  return api
    .multiPart(Routes.uploadProfilePicture(userId, token), file)
    .then((response) => {
      return response;
    });
};

export default {
  fetchDoctors,
  fetchShifts,
  fetchEmergencyDoctors,
  fetchEmergencyDoctorShifts,
  updateEmergencyDoctor,
  fetchAll,
  delUserWithToken,
  appointmentsWithToken,
  mascarade,
  softDelete,
  updateDoctor,
  createDoctorWithImage,
};
