import queryString from "query-string";
import moment from "moment";
import api from "@api/";
import mime from "mime-types";
const fileSize = function (files) {
  for (var i = 0; i < files.length; i++) {
    var element = files[i];
    if (element.size > 25485760) {
      return true;
    }
  }
  return false;
};
const useQuery = (location) => {
  return queryString.parse(location.search);
};
const getObjectUrl = async (url) => {
  try {
    const b = await api.getBlob(url);
    return URL.createObjectURL(b);
  } catch (error) {
    console.error(error);
  }
};

const getFormData = function (files) {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    var element = files[i];
    formData.append("file", element);
  }
  return formData;
};

const getFormDataFiles = function (files) {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append("file", files[i].file);
  }
  return formData;
};

const updateScreen = (location, history) => {
  const parsed = queryString.parse(location.search);
  parsed.updunix = moment().unix();
  const search = queryString.stringify(parsed);

  history.replace(`${location.pathname}?${search}`);
};

const isEmpty = function (value) {
  return (
    value === null ||
    value === undefined ||
    value === false ||
    value.toString().trim() === ""
  );
};
export default {
  getObjectUrl,
  updateScreen,
  useQuery,
  exceedFileSize: fileSize,
  getFormData: getFormData,
  getFormDataFiles: getFormDataFiles,
  isEmpty: isEmpty,
};
