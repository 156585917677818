/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useRef, useEffect, useState, useMemo } from "react";
import { Specialties } from "@helpers/";
import { Routes } from "@constants/";
import { Form, Field } from "react-final-form";
import { Medicare } from "@constants/";
import { InputField } from "@src/components/fields/input";
import PhoneAdapter from "@src/components/fields/phone_adapter";
import formValidators from "@src/components/fields/field_validators";
import ReactSelectAdapter from "@src/components/fields/select";
import Resizer from "react-image-file-resizer";

function UserProfileForm({ onSubmit, setImage, initialValues = null }) {
  const imageInput = useRef();
  const [defaultPriceOption, setDefaultPriceOption] = useState("default");
  const [specialties, setSpecialties] = useState([]);
  const [previewImageSrc, setPreviewImageSrc] = useState(null);
  const { required, validURL } = formValidators;

  useEffect(() => {
    setDefaultPriceOption(
      initialValues && initialValues.firstConsultPrice > 0
        ? "custom"
        : "default"
    );
  }, [initialValues]);

  useEffect(() => {
    Specialties.fetchSpecialtiesAdmin().then((response) => {
      setSpecialties(response);
    });
  }, []);

  const imageChange = (e) => {
    var image = e.target.files[0];

    if (image) {
      setImage(image);
      Resizer.imageFileResizer(
        image,
        300,
        300,
        "JPEG",
        95,
        0,
        (uri) => {
          setPreviewImageSrc(uri);
        },
        "base64"
      );
    }
  };

  const medicareFirstConsultItemNumbers = useMemo(() => {
    return Medicare.itemNumbers.map(function (i, index) {
      return (
        <option key={`firmst-${index}`} value={i["first-consult-item-number"]}>
          {`${i["first-consult-item-number"]}: ${i["specialty"]} - $${i["first-consult-medicare-rebate"]}`}
        </option>
      );
    });
  }, [Medicare]);

  const medicareFollowUpConsultItemNumbers = useMemo(() => {
    return Medicare.itemNumbers.map(function (i, index) {
      return (
        <option
          key={`follow-${index}`}
          value={i["follow-up-consult-item-number"]}
        >
          {`${i["follow-up-consult-item-number"]}: ${i["specialty"]} - $${i["first-consult-medicare-rebate"]}`}
        </option>
      );
    });
  }, [Medicare]);

  const specialtyOptions = useMemo(() => {
    return specialties.map(function (s) {
      return { value: s.id, label: s.name };
    });
  });

  const divStyle = {
    backgroundImage: previewImageSrc
      ? `url(${previewImageSrc})`
      : initialValues &&
        initialValues.profileImage !== "undefined" &&
        initialValues.profileImage
      ? `url(${Routes.profilePicUrl(initialValues.profileImage)})`
      : `url(${Routes.assets.doctorPlaceHolder})`,
  };

  return (
    <Form
      initialValues={initialValues || {}}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="doctor-account">
          <div className="profile-img">
            <div className="profile-avatar " style={divStyle}></div>
            <a
              onClick={() => imageInput.current.click()}
              className="edit-avatar"
            >
              Edit Image
            </a>
            <input
              ref={imageInput}
              type="file"
              name="file"
              className="hidden"
              onChange={imageChange}
            />
          </div>
          <InputField
            label="Room name:"
            name="username"
            type="text"
            fieldProps={{ validate: required }}
          />
          <InputField
            label="First name:"
            name="firstName"
            required={true}
            fieldProps={{ validate: required }}
          />
          <InputField
            label="Last name:"
            name="lastName"
            fieldProps={{ validate: required }}
          />
          <InputField label="Password" type="password" name="password" />
          <div className="form-group phone">
            <label>Phone Number</label>
            <Field
              defaultCountry="AU"
              component={PhoneAdapter}
              name="phoneNumber"
              smartCaret={false}
              placeholder="Enter phone number"
            />
          </div>
          <div className="form-group phone">
            <label>Practice Phone Number</label>
            <Field
              defaultCountry="AU"
              component={PhoneAdapter}
              name="practicePhoneNumber"
              smartCaret={false}
              placeholder="Enter phone number"
            />
          </div>
          <InputField
            name="email"
            label="Personal Email Address"
            type="email"
            fieldProps={{ validate: required }}
          />
          <InputField
            label="Practice managers email address:"
            name="practiceEmail"
          />
          <InputField label="Practice address:" name="practiceAddress" />
          <InputField label="Provider Number:" name="providerNumber" />

          <hr className="divider" />

          <div className="form-group">
            <label>Price Options</label>
            <select
              value={defaultPriceOption}
              onChange={(e) => {
                console.log(e);
                setDefaultPriceOption(e.currentTarget.value);
              }}
            >
              <option value="default">Use default settings</option>
              <option value="custom">Customize settings</option>
            </select>
          </div>
          {defaultPriceOption === "custom" && (
            <div>
              <InputField
                type="number"
                label="First Consult A$:"
                name="firstConsultPrice"
                fieldProps={{ validate: required }}
              />
              <div className="form-group">
                <label>"First Consult Medicare Item Number:"</label>
                <select name="firstConsultMedicareItemNumber">
                  {medicareFirstConsultItemNumbers}
                </select>
              </div>
              <InputField
                type="number"
                label="Follow Up Consult A$:"
                name="followUpConsultPrice"
                fieldProps={{ validate: required }}
              />
              <div className="form-group">
                <label>Follow Up Consult Medicare Item Number:</label>
                <select name="followUpConsultMedicareItemNumber">
                  {medicareFollowUpConsultItemNumbers}
                </select>
              </div>
            </div>
          )}

          <hr className="divider" />
          <div className="form-group">
            <label>Bio:</label>
            <textarea name="bio" />
          </div>
          <InputField label="Degree:" name="degree" />
          <InputField label="Title:" name="title" />

          <div className="form-group">
            <label>Specialty:</label>
            <div className="selectWrap">
              <Field
                classNamePrefix="docto"
                className="specialtySelect"
                name="specialties"
                component={ReactSelectAdapter}
                options={specialtyOptions}
                isMulti
              />
            </div>
          </div>
          <div className="form-group">
            <div className="buttons">
              <button
                type="submit"
                className={
                  submitting ? "btn-submitting" : "btn-purple btn-large"
                }
              >
                UPDATE USER
              </button>
            </div>
          </div>
        </form>
      )}
    />
  );
}
export default UserProfileForm;
