import React from "react";

function Preloader() {
  return (
    <div className="account-body">
      <div className="prof-load">
        <div className="left">
          <div className="loader-animation">
            <div></div>
          </div>
        </div>
        <div className="right">
          <div className="loader-animation">
            <div></div>
          </div>
        </div>
      </div>
      <div className="prof-load">
        <div className="left">
          <div className="loader-animation">
            <div></div>
          </div>
        </div>
        <div className="right">
          <div className="loader-animation">
            <div></div>
          </div>
        </div>
      </div>
      <div className="prof-load">
        <div className="wide">
          <div className="loader-animation">
            <div></div>
          </div>
        </div>
      </div>
      <div className="prof-load">
        <div className="wide">
          <div className="loader-animation">
            <div></div>
          </div>
        </div>
      </div>
      <div className="prof-load">
        <div className="left">
          <div className="loader-animation">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preloader;
