import React from "react";
import { Link } from "react-router-dom";

const StepModal = (props) => {
  return (
    <div className="popup-wrap">
      <div className="modal-window md-sign-up">
        <div className="modal">
          <div>
            <div className="md-header">
              <a href="#" className="md-close" onClick={() => props.onClose()}>
                <i className="icon-cross-circle"></i>
              </a>
              <h4>{props.title}</h4>
            </div>

            <div className="md-body">{props.children}</div>

            <div className="md-footer">
              {props.topBtnText ? (
                <a
                  className={
                    props.topBtnClass
                      ? `btn ${props.topBtnClass}`
                      : "btn btn-info "
                  }
                  onClick={() => props.onTopBtnClick()}
                >
                  {props.topBtnText}
                </a>
              ) : null}
              {props.bottomBtnText ? (
                <a
                  className={
                    props.bottomBtnClass
                      ? `btn  ${props.bottomBtnClass}`
                      : "btn btn-info "
                  }
                  onClick={() => props.onBottomBtnClick()}
                >
                  {props.bottomBtnText}
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StepModal;
