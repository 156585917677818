import toastr from "toastr";

const displayToast = ({ kind, message }) => {
  switch (kind) {
    case "success":
      return toastr.success(message);
    case "error":
      return toastr.error(message);
    case "warning":
      return toastr.warning(message);
    default:
      return toastr.warning(message);
  }
};
export default { displayToast };
