import React from "react";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import { history } from "@modules/";
import DoctoLayout from "@layouts/doctoLayout";
import AppLayout from "@layouts/appLayout";
import LandingWrapper from "./wrappers/landing_wrapper";
import About from "@containers/static_pages/about";
import Contact from "@containers/static_pages/contact_us";
import ReferAPatient from "@containers/static_pages/refer_a_patient";
import ExecutiveTeam from "@containers/static_pages/executive_team";
import Press from "@containers/static_pages/press";
import DoctorAccount from "@containers/doctor_pages/account";
import DoctorAppointments from "@containers/doctor_pages/appointments";
import AllAppointments from "@containers/doctor_pages/appointments/list";
import Admin from "@containers/admin";
import SpecialtyCms from "@containers/specialties_cms";
import Business from "@containers/static_pages/business";
import FetchUser from "./fetch_user";
import CompanyLanding from "@containers/company_landings";
import NotFound from "./not_found";
import { useSelector, useDispatch } from "react-redux";
import { initUserWithToken, initGuestWithToken } from "@modules/session";
import { Token, User } from "@helpers";
import Routes from "@constants/routes";
import AppModals from "@src/components/modals";
import BrowseHospital from "@containers/static_pages/browse_hospital/index";
import ExpertOpinion from "@containers/static_pages/expert_opinion/index";
import queryString from "query-string";
import Account from "@containers/account";
import EmergencyRoom from "@containers/emergency_room";
import AppointmentAccess from "@containers/appointment_access";
import Messages from "@containers/messages";
import ConsultationRoom from "@containers/consultation_room";
import Jotform from "@containers/jotform";
import ApointmentTimeZoneChecker from "@containers/appointment_time_zone_checker";
import CorporateAccess from "@containers/emergency_consult_access/corporate_access";
import NurseAccess from "@containers/emergency_consult_access/nurse_access";
import EmergencyAccess from "@containers/emergency_consult_access/emergency_access";
import ChatRoom from "@containers/emergency_consult_access/chat_room";
import Login from "@containers/login";
import DownloadReferral from "@containers/download_referral";

const getQueryToken = (location) => {
  const parsed = queryString.parse(location.search);
  const queryToken = parsed?.accessToken;
  if (queryToken) {
    parsed.accessToken = undefined;
    const search = queryString.stringify(parsed);
    history.replace(`${location.pathname}?${search}`);
  }
  return queryToken;
};
const getQueryGuestToken = (location) => {
  const parsed = queryString.parse(location.search);
  const queryToken = parsed?.guestToken;
  if (queryToken) {
    parsed.guestToken = undefined;
    const search = queryString.stringify(parsed);
    history.replace(`${location.pathname}?${search}`);
  }
  return queryToken;
};

const App = (props) => {
  const user = useSelector((state) => state.session.user);
  const guestUser = useSelector((state) => state.session.guestUser);
  const { location } = history;
  const queryToken = getQueryToken(location);
  const token = queryToken || Token.getToken();
  if (queryToken || (!user.token && token)) {
    const dispatch = useDispatch();
    const initUser = initUserWithToken(token);
    dispatch(initUser);
    return <FetchUser />;
  }
  return (
    <Router history={history}>
      <AppModals {...{ ...props, location, guestUser, user }} />
      <Switch>
        <LandingWrapper exact path="/" />
        {/* JOTFORMS */}
        <Route exact path="/login" component={Login} />
        <JotformWrapper
          name="jotform"
          path="/medhealth"
          jotformId="92658250763868"
          component={Jotform}
        />
        <JotformWrapper
          name="jotform"
          path="/AGCC"
          jotformId="92730195903863"
          component={Jotform}
        />

        <JotformWrapper
          name="jotform"
          path="/karratha777"
          jotformId="83149094103857"
          component={Jotform}
        />
        <JotformWrapper
          path="/bbwp"
          jotformId="83056480517862"
          component={Jotform}
        />
        <JotformWrapper
          path="/mermaidchempro"
          jotformId="91630055188861"
          component={Jotform}
        />
        <JotformWrapper
          path="/ilsc"
          jotformId="201410659428049"
          component={Jotform}
        />
        {/* END JOTFORMS */}

        {/* Redirect companies */}
        <Redirect path="/assistravel" to="/companies/assistravel" />
        <Redirect path="/ahi" to="/companies/ahi" />
        <Redirect path="/csgi" to="/companies/csgi" />
        {/* <Redirect path="/r2s" to="/companies/r2s" /> */}
        <Redirect from="/gold-coast-private-hospital" to="/consult-dr-now" />
        {/* EndRedirect companiens */}
        <RouteWrapper
          roles={["admin"]}
          exact
          isPrivate
          path={`/download-referral-request/:key`}
          component={DownloadReferral}
        />
        <RouteWrapper
          path="/not-found"
          layout={DoctoLayout}
          exact
          component={NotFound}
        />
        <RouteWrapper
          layout={DoctoLayout}
          exact
          path={Routes.paths.contact}
          component={Contact}
        />

        <RouteWrapper
          layout={DoctoLayout}
          exact
          path={Routes.paths.about}
          component={About}
        />
        <RouteWrapper
          layout={DoctoLayout}
          exact
          path={Routes.paths.businessPage}
          component={Business}
        />
        <RouteWrapper
          layout={DoctoLayout}
          exact
          path={Routes.paths.referAPatient}
          component={ReferAPatient}
        />
        <RouteWrapper
          layout={DoctoLayout}
          exact
          path={Routes.paths.executiveTeam}
          component={ExecutiveTeam}
        />
        <RouteWrapper
          layout={DoctoLayout}
          exact
          path={Routes.paths.browseHospital}
          component={BrowseHospital}
        />
        <RouteWrapper
          layout={DoctoLayout}
          exact
          path={Routes.paths.expertOpinion}
          component={ExpertOpinion}
        />
        <RouteWrapper
          layout={DoctoLayout}
          exact
          path={Routes.paths.press}
          component={Press}
        />

        {/* APlication ROUTES */}
        {/* public routes */}
        <RouteWrapper
          layout={AppLayout}
          exact
          path="/time-check/:appointmentId"
          component={ApointmentTimeZoneChecker}
        />
        <RouteWrapper
          layout={AppLayout}
          routeName="consultDrNowDemo"
          exact
          path={"/consult-dr-now/corporate"}
          component={CorporateAccess}
        />
        <RouteWrapper
          layout={AppLayout}
          routeName="consultDrNow"
          exact
          path={Routes.paths.consultDrNow}
          component={EmergencyAccess}
        />
        {/* end public routes */}
        <RouteWrapper
          isPrivate
          layout={AppLayout}
          exact
          path={Routes.paths.messages}
          component={Messages}
        />
        <RouteWrapper
          isPrivate
          layout={AppLayout}
          exact
          path={Routes.paths.doctor_profile}
          component={DoctorAccount}
        />

        <RouteWrapper
          layout={AppLayout}
          routeName="consultDrNow"
          exact
          path={`${Routes.paths.consultDrNow}/:appointmentId`}
          component={ChatRoom}
        />
        <RouteWrapper
          layout={AppLayout}
          routeName="emergencyAppointmentAccess"
          exact
          path="/appointments/emergency/:appointmentId"
          component={ChatRoom}
        />

        <RouteWrapper
          layout={AppLayout}
          routeName="NursingRoom"
          roles={["nurse"]}
          exact
          isPrivate
          usePassword
          path={`/emerg-room`}
          component={NurseAccess}
        />

        <RouteWrapper
          layout={AppLayout}
          exact
          isPrivate
          roles={["doctor"]}
          path={`/emergency/:appointmentId`}
          component={EmergencyRoom}
        />

        <ConsultationRoomWrapper
          layout={AppLayout}
          isPrivate
          path="/consultation-room/:appointmentId/audio/:audio"
          exact
          component={ConsultationRoom}
        />

        <ConsultationRoomWrapper
          layout={AppLayout}
          isPrivate
          path="/consultation-room/:appointmentId"
          authRequired={true}
          exact
          component={ConsultationRoom}
        />

        <RouteWrapper
          layout={AppLayout}
          exact
          routeName="guestRoomAccess"
          path="/room/guest/:appointmentId"
          component={AppointmentAccess}
        />
        <RouteWrapper
          isPrivate
          layout={AppLayout}
          exact
          routeName="visitorWithAppointment"
          path="/room/:username/appointment/:appointmentId"
          component={AppointmentAccess}
        />

        <RouteWrapper
          layout={AppLayout}
          exact
          path={Routes.paths.appointments}
          component={DoctorAppointments}
        />
        <RouteWrapper
          isPrivate
          layout={AppLayout}
          exact
          path="/appointments/list/:type/:page"
          component={AllAppointments}
        />

        <Route path="/admin" component={Admin} />
        <Route path="/account" component={Account} />
        <Redirect path="/dashboard" to="/account/dashboard" />

        <Route exact path="/companies/:slug" component={CompanyLanding} />
        <Route
          exact
          path="/companies/:slug/emergency/:appointmentId"
          component={CompanyLanding}
        />

        <RouteWrapper
          path="/:slug"
          layout={DoctoLayout}
          exact
          component={SpecialtyCms}
        />
        <RouteWrapper layout={DoctoLayout} exact component={NotFound} />
      </Switch>
    </Router>
  );
};

const useQuery = () => {
  const { location } = history;
  return queryString.parse(location.search);
};

const JotformWrapper = ({ component: Component, ...rest }) => {
  return <Route render={(props) => <Component {...rest} {...props} />} />;
};

const RouteWrapper = ({
  component: Component,
  layout: Layout,
  isPrivate,
  usePassword,
  name,
  roles,
  ...rest
}) => {
  const query = useQuery();
  const user = useSelector((state) => state.session.user);
  const navigation = usePassword ? "password" : null;
  const role = User.getRoleName(user);
  if ((isPrivate && !user.id) || (roles && !roles.includes(role))) {
    return <Login navigation={navigation} />;
  }

  if (roles && !roles.includes(role)) {
    return <Login navigation={navigation} />;
  }
  if (!Layout) {
    return (
      <Route
        render={(props) => (
          <Component {...props} {...rest} user={user} {...{ name, query }} />
        )}
      />
    );
  }
  return (
    <Route
      render={(props) => (
        <Layout {...props} name={name}>
          <Component {...props} {...rest} user={user} {...{ name, query }} />
        </Layout>
      )}
    />
  );
};

const ConsultationRoomWrapper = ({ component: Component, name, ...rest }) => {
  const user = useSelector((state) => state.session.user);
  const guestUser = useSelector((state) => state.session.guestUser);
  const query = useQuery();
  const { location } = history;
  const queryToken = getQueryGuestToken(location);
  const token = queryToken || Token.getGuestToken();
  if (queryToken || (!guestUser.token && token)) {
    const dispatch = useDispatch();
    const initUser = initGuestWithToken(token);
    dispatch(initUser);
    return <FetchUser />;
  }

  if (!user.id && !guestUser.id) {
    return <Login />;
  }
  const consultationUser = user.id ? user : guestUser;

  return (
    <Route
      render={(props) => (
        <AppLayout {...props} name={name}>
          <Component
            {...rest}
            {...props}
            user={consultationUser}
            {...{ name, query }}
          />
        </AppLayout>
      )}
    />
  );
};

export default App;
