import React from "react";
import { bucketUrl } from "@src/constants";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const BusinessThumb = ({ children, type, image, clickAction, active }) => {
  let imageFolder =
    type === "workplace"
      ? "managing-workplace-health"
      : "succesfull-industry-solutions";

  let src = active
    ? `${bucketUrl}/business/${imageFolder}/active.jpg`
    : `${bucketUrl}/business/${imageFolder}/${image}.jpg`;
  return (
    <div className="col-md-fifth col-xs-6">
      <Link to="#" onClick={(e) => clickAction(e)} className="business-thumb">
        {children}
        <img className="responsive" src={src} />
      </Link>
    </div>
  );
};

class BusinessDescription extends React.Component {
  render() {
    let { business, type } = this.props;
    let imageFolder =
      type === "workplace"
        ? "managing-workplace-health"
        : "succesfull-industry-solutions";
    let src = `${bucketUrl}/business/${imageFolder}/${business.image}_L.jpg`;
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="row show-extra-info ">
            <div className="col-sm-12 col-md-4 col-md-offset-1">
              <img className="responsive" src={src} />
            </div>
            <div className="col-sm-12 col-md-6">
              <p className="large">{business.content}</p>
              <Link onClick={() => this.showBot()} className="btn-more">
                CONTACT US
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class BusinessPage extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedWorkplace: undefined,
      selectedIndustry: undefined,
      companyFields: {},
      companyErrors: {},
      companyPlaceHolders: {
        companyName: "Company",
        companyEmail: "Email Address",
        companyContact: "Name",
      },
    };

    this.workplaces = [
      {
        title: (
          <span>
            Infectious
            <br />
            Diseases
          </span>
        ),
        image: "Infectious-Diseases",
      },
      {
        title: (
          <span>
            Back
            <br />
            Injuries
          </span>
        ),
        image: "Back-Injuries",
      },
      {
        title: (
          <span>
            Heart
            <br />
            Disease
          </span>
        ),
        image: "Heart-Disease",
      },
      {
        title: (
          <span>
            Alchohol and
            <br />
            Drug Abuse
          </span>
        ),
        image: "Alchohol-and-Drug-Abuse",
      },
      {
        title: (
          <span>
            Mental
            <br />
            Health
          </span>
        ),
        image: "Mental-Health",
      },
      {
        title: (
          <span>
            Workplace
            <br />
            Injury
          </span>
        ),
        image: "Workplace-Injury",
      },
      {
        title: (
          <span>
            Chronic
            <br />
            Disease
          </span>
        ),
        image: "Chronic-Disease",
      },
      {
        title: (
          <span>
            Workers
            <br />
            Compensation
          </span>
        ),
        image: "Workers-Compensation",
      },
      {
        title: (
          <span>
            Unforseen
            <br />
            Illness
          </span>
        ),
        image: "Unforseen-Illness",
      },
      {
        title: (
          <span>
            Wellbeing
            <br />
            Programs
          </span>
        ),
        image: "Wellbeing-Programs",
      },
    ];
    this.industries = [
      {
        title: (
          <span>
            Health
            <br />
            Insurance
          </span>
        ),
        image: "Health-Insurance",
        content: "Proven strategies to reduce hospital admissions.",
      },
      {
        title: (
          <span>
            Travel
            <br />
            Insurance
          </span>
        ),
        image: "Travel-Insurance",
        content:
          "Reduce costs by simplifying the assistance process, as well as through access to Australian expert medical opinions.",
      },
      {
        title: (
          <span>
            Workers
            <br />
            Compensation
          </span>
        ),
        image: "Workers-Compensation",
        content:
          "Early medical care reduces lost time through injury. We can also help you manage the return to work process.",
      },
      {
        title: (
          <span>
            Emergency
            <br />
            Management
          </span>
        ),
        image: "Emergency-Management",
        content:
          "Add a 24/7 telemedicine capability with global reach to your existing service.",
      },
      {
        title: (
          <span>
            Corrective
            <br />
            Services
          </span>
        ),
        image: "Corrective-Services",
        content:
          "We can support your embedded nursing staff, as well as helping you avoid the need to transfer inmates for specialist medical opinions.",
      },
      {
        title: <span>Military</span>,
        image: "Military",
        content:
          "Routine and emergency medical care for military personnel, whether in barracks or on deployment.",
      },
      {
        title: <span>Transport</span>,
        image: "Transport",
        content:
          "By plane, train, road or sea - protect staff and passengers with access to 24/7 care from anywhere in the world.",
      },
      {
        title: <span>Tourism</span>,
        image: "Tourism",
        content:
          "Wherever you are, keep holidays on track with our emergency support and specialist care.",
      },
      {
        title: (
          <span>
            Aged
            <br />
            Care
          </span>
        ),
        image: "Aged-Care",
        content:
          "Medical support for nursing staff to enhance care and reduce hospital admissions.",
      },
      {
        title: <span>Education</span>,
        image: "Education",
        content: "Telemedicine support for your school nursing staff.",
      },
      {
        title: (
          <span>
            Overseas
            <br />
            Students
          </span>
        ),
        image: "Overseas-Students",
        content:
          "Rapid medical advice (via interpreters) to help students navigate the Australian medical system.",
      },
      {
        title: (
          <span>
            Remote
            <br />
            Workers
          </span>
        ),
        image: "Remote-Workers",
        content:
          "Limit the risks of remote work with rapid emergency medical advice anywhere, any time.",
      },
      {
        title: (
          <span>
            Mining, Gas
            <br />
            and Oil
          </span>
        ),
        image: "Mining-Oil-and-Gas",
        content:
          "Keep your operation running with telemedicine support for resource sector workers and embedded paramedics / nursing staff.",
      },
      {
        title: <span>Expatriates</span>,
        image: "Expatriates",
        content:
          "We can help you consult Australian doctors while living or working overseas.",
      },
      {
        title: <span>Custom</span>,
        image: "Custom",
        content:
          "Customised emergency and specialist telemedicine to suit your needs.",
      },
    ];
  }
  workplaceAction(e, index) {
    e.preventDefault();
    this.setState({
      selectedWorkplace:
        index === this.state.selectedWorkplace ? undefined : index,
    });
  }

  industryAction(e, index) {
    e.preventDefault();
    this.setState({
      selectedIndustry:
        index === this.state.selectedIndustry ? undefined : index,
    });
  }

  showBot() {
    console.log("show bot");
    // flux.actions.bot.initBot('welcome_isBusinness');
  }
  setCompanyFields(field) {
    var companyFields = this.state.companyFields;
    companyFields[field.key] = field.value;
    this.setState({ companyFields: companyFields });
  }
  businessContact() {
    let contactInfo = {
      companyName: this.state.companyFields.companyName,
      companyEmail: this.state.companyFields.companyEmail,
      companyContact: this.state.companyFields.companyContact,
    };
    var companyFields = this.state.companyFields;
    var companyErrors = this.state.companyErrors;
    if (
      !this.state.companyFields.companyName ||
      !this.state.companyFields.companyEmail ||
      !this.state.companyFields.companyContact ||
      this.state.companyFields.companyName == "" ||
      this.state.companyFields.companyEmail == "" ||
      this.state.companyFields.companyContact == ""
    ) {
      if (
        !this.state.companyFields.companyName ||
        this.state.companyFields.companyName == ""
      ) {
        companyFields.companyName = "";
        companyErrors.companyName = "Company can not be blank";
      }
      if (
        !this.state.companyFields.companyEmail ||
        this.state.companyFields.companyEmail == ""
      ) {
        companyFields.companyEmail = "";
        companyErrors.companyEmail = "Email can not be blank";
      }
      if (
        !this.state.companyFields.companyContact ||
        this.state.companyFields.companyContact == ""
      ) {
        companyFields.companyContact = "";
        companyErrors.companyContact = "Name can not be blank";
      }
      this.setState({
        companyErrors: companyErrors,
        companyFields: companyFields,
      });
      return;
    }

    // api
    // 	.post(`${Routes.email}/sendCompanyContact`, contactInfo)
    // 	.then((response) => {
    // 		let toastBody = {
    // 			message:
    // 				'Thank you for your enquiry. Our team will contact you shortly',
    // 			kind: 'success',
    // 		};
    // 		Toast.displayToast(toastBody);
    // 		this.setState({ companyErrors: {}, companyFields: {} });
    // 	})
    // 	.catch((error) => {
    // 		console.error('FULL CONTACT ErROR', error);
    // 		var res = JSON.parse(error.response);
    // 		if (res.error.code === 'ValidationError') {
    // 			companyFields.companyEmail = null;
    // 			this.setState({
    // 				companyErrors: res.error.message,
    // 				companyFields: {},
    // 				companyFields: companyFields,
    // 			});
    // 		} else {
    // 			let toastBody = {
    // 				message: 'Unexpected error. Please try again later',
    // 				kind: 'error',
    // 			};
    // 			Toast.displayToast(toastBody);
    // 		}
    // 	});
  }

  render() {
    let { selectedIndustry } = this.state;
    // let categoryElements = Browser.isMobileResolution() ? 2 : 5;
    let categoryElements = 5;
    return (
      <div id="page-wrapper" className="business-page">
        <Helmet>
          <title>Docto Online Hospital - Business</title>
          <link rel="canonical" href="https://www.docto.com.au/business" />
          <meta
            name="description"
            content="Business Partnerships - Docto Online Hospital"
          />
        </Helmet>
        <div className="business-hero">
          <div className="container hide">
            <h2>Give your staff & customers the future of healthcare</h2>
            <select className="hide">
              <option>SELECT YOUR INDUSTRY</option>
              <option>SELECT YOUR INDUSTRY</option>
              <option>SELECT YOUR INDUSTRY</option>
            </select>
          </div>

          <div className="container form-container">
            <div className="row row-flex">
              <div className="col-xs-12 col-md-7">
                <h2>Give your staff & customers the future of healthcare</h2>
              </div>

              <div className="col-xs-12 col-md-4">
                <div className="home-primary-form">
                  <div className="hpf-body">
                    <h2>Business Contact</h2>
                    <p>Request a demo or quote now.</p>

                    <div className="hpf-inputs">
                      <input
                        value={
                          this.state.companyFields.companyName
                            ? this.state.companyFields.companyName
                            : ""
                        }
                        onChange={(event) =>
                          this.setCompanyFields({
                            key: "companyName",
                            value: event.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          this.state.companyErrors.companyName
                            ? this.state.companyErrors.companyName
                            : this.state.companyPlaceHolders.companyName
                        }
                      />
                      <input
                        value={
                          this.state.companyFields.companyContact
                            ? this.state.companyFields.companyContact
                            : ""
                        }
                        onChange={(event) =>
                          this.setCompanyFields({
                            key: "companyContact",
                            value: event.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          this.state.companyErrors.companyContact
                            ? this.state.companyErrors.companyContact
                            : this.state.companyPlaceHolders.companyContact
                        }
                      />
                      <input
                        value={
                          this.state.companyFields.companyEmail
                            ? this.state.companyFields.companyEmail
                            : ""
                        }
                        onChange={(event) =>
                          this.setCompanyFields({
                            key: "companyEmail",
                            value: event.target.value,
                          })
                        }
                        type="text"
                        placeholder={
                          this.state.companyErrors.companyEmail
                            ? this.state.companyErrors.companyEmail
                            : this.state.companyPlaceHolders.companyEmail
                        }
                      />
                    </div>

                    <a
                      onClick={() => this.businessContact()}
                      className="hpf-submit"
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="staff" className="lndng-section-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-5 col-md-offset-1">
                <img src={`${bucketUrl}/business/device-staff-support.png`} />
              </div>
              <div className="col-sm-12 col-md-5">
                <h4 className="lined">STAFF SUPPORT</h4>
                <p className="large">
                  Enhance the health and productivity of any workforce whilst
                  significantly reducing your lost time injury frequency rate.
                </p>

                <p className="small">
                  Our team delivers online illness and injury prevention
                  programs as well as acute illness, injury and chronic disease
                  management. This range of healthcare services is delivered via
                  our emergency physicians and online medical specialists.
                </p>

                <p className="small">
                  We are able to act as first point of contact, 24 hr support to
                  existing care personnel or strategists to lower LTIF and
                  prevent health problems in any workforce.
                </p>

                <Link onClick={() => this.showBot()} className="btn-more">
                  CONTACT US
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="customer-assistance" className="lndng-section-grey">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-5 col-md-offset-1">
                <h1 className="lined">Telehealth Partnerships</h1>
                <p className="large">
                  Insurers and Assistance providers save time and reduce costs
                  significantly through our instant, expert medical advice.
                </p>

                <p className="small">
                  Improve your client’s user experience by simplifying and
                  streamlining the process of accessing accurate medical advice.
                </p>

                <p className="small">
                  Working alongside your existing assistance provider in a
                  collaborative and collegiate fashion to provide enhanced
                  patient care at reduced overall cost.
                </p>

                <Link onClick={() => this.showBot()} className="btn-more">
                  CONTACT US
                </Link>
              </div>
              <div className="col-sm-12 col-md-5">
                <img
                  src={`${bucketUrl}/business/device-customer-assistance.png`}
                />
              </div>
            </div>
          </div>
        </div>

        <div id="support" className="lndng-section-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-5 col-md-offset-1">
                <img src={`${bucketUrl}/business/device-emergency.png`} />
              </div>
              <div className="col-sm-12 col-md-5">
                <h4 className="lined">
                  SUPPORT FOR EMERGENCY MANAGEMENT PROVIDERS
                </h4>
                <p className="large">
                  First responders, Industrial medics, Paramedics, Registered
                  nurses and General practitioners benefit from these Docto
                  support services...
                </p>

                <p className="small-list">
                  • Assistance with obtaining drugs and poisons licences
                </p>
                <p className="small-list">
                  • Assistance with obtaining approvals for commercial
                  paramedics
                </p>
                <p className="small-list">
                  • Drug protocols and clinical practice guidelines (review,
                  update or custom build)
                </p>
                <p className="small-list">
                  • 24/7 real time clinical governance, drug orders etc via our
                  “live” emergency physicians
                </p>
                <p className="small-list">
                  • Telemedicine consultations with a wide variety of medical
                  and allied health specialists
                </p>
                <p className="small-list">
                  • Assistance with your Quality Assurance system – case review,
                  external audit etc
                </p>
                <p className="small-list">
                  • Continuing medical education for all levels of training
                </p>

                <Link onClick={() => this.showBot()} className="btn-more">
                  CONTACT US
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="lndng-section-white">
          <h4 className="lined">TRUSTED AND RELIED UPON BY</h4>
          <div className="company-partners">
            <img src={`${bucketUrl}/business/company-partners.png`} />
          </div>
        </div>

        {/* Commenting this until further notice
        <div className="lndng-section-grey">
          <h4 className="lined">MANAGING WORKPLACE HEALTH</h4>
          <div className="container">
            <div className="thumb-wrap workplace">
              <div className="row">
                {this.workplaces.map((workplace, index) =>
                  <BusinessThumb key={`Workplace${index}`}
                                 type="workplace"
                                 image={workplace.image}
                                 active={selectedWorkplace === index}
                                 clickAction={(e) => this.workplaceAction(e, index)}>
                    {workplace.title}
                  </BusinessThumb>
                )}
              </div>
              {selectedWorkplace !== undefined?
                <BusinessDescription type="workplace" business={this.workplaces[selectedWorkplace]}/>
                :
                null
              }
            </div>
          </div>
        </div>
*/}

        <div className="lndng-section-grey">
          <h4 className="lined">SUCCESSFUL INDUSTRY SOLUTIONS</h4>
          <div className="container">
            <div className="thumb-wrap industry">
              <div className="row">
                {this.industries.map((industry, index) => (
                  <div key={`Industry${index}`}>
                    <BusinessThumb
                      type="industry"
                      image={industry.image}
                      active={selectedIndustry === index}
                      clickAction={(e) => this.industryAction(e, index)}
                    >
                      {industry.title}
                    </BusinessThumb>
                    {selectedIndustry !== undefined &&
                    index % categoryElements === categoryElements - 1 &&
                    Math.floor(index / categoryElements) ===
                      Math.floor(selectedIndustry / categoryElements) ? (
                      <BusinessDescription
                        type="industry"
                        business={this.industries[selectedIndustry]}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="body-section-hero">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-10 col-md-offset-1">
                <div className="row">
                  <div className="col-md-7">
                    <h2>Trial Future Health</h2>
                    <p>
                      Try the online hospital today for Free! You’re not
                      comitting to anything other than sampling our service.
                    </p>
                    <p>Prefer to talk it over?</p>
                    <p>
                      Our senior medical management group are available to chat
                      at any time.
                    </p>
                    <div className="ceo-sign">
                      <p>
                        <span className="large">Dr Jon Field</span>
                        <span>DIRECTOR & CO-FOUNDER</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 for-business">
                    <h2>for Business</h2>
                    <div className="demo-request">
                      <h4>Enterprise Plan</h4>
                      <span>
                        <i className="icon-check"></i> "White labelled" 24hr
                        medical cover
                      </span>
                      <span>
                        <i className="icon-check"></i> Company branded url
                        landing page
                      </span>
                      <span>
                        <i className="icon-check"></i> Dedicated company phone
                        number and email address
                      </span>
                      <span>
                        <i className="icon-check"></i> Expert opinions,
                        medico-legal and insurance reports
                      </span>
                      <span>
                        <i className="icon-check"></i> Quality assurance and
                        clinical governance reports
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BusinessPage;
