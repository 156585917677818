import React from "react";
import ReactTable from "react-table-v6";
import TableLoader from "../preloader/table";
import { User, DateHelpers } from "@helpers";
import { history } from "@modules";

class PatientTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: props.filters.textFilter,
    };
    this.setFilterName = this.setFilterName.bind(this);
    this.handleFilterNameSearch = this.handleFilterNameSearch.bind(this);
    this.clearFilterNameSearch = this.clearFilterNameSearch.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleFilterNameSearch();
    }
  }
  setFilterName(e) {
    const { value } = e.target;
    this.setState({ filterName: value });
  }
  handleFilterNameSearch() {
    const { filterName } = this.state;
    this.props.handleFilterChange("textFilter", filterName);
  }
  clearFilterNameSearch() {
    this.setState({ filterName: "" });
    this.props.handleFilterChange("textFilter", "");
  }
  goToPatientFiles(patient) {
    history.push(`/admin/patients/${patient.id}/files`);
  }
  render() {
    const { patients, loading } = this.props;

    if (loading)
      return (
        <div className="mt-10 mb-10">
          <TableLoader
            columns={[
              "name",
              "email",
              "phone",
              "joined",
              "company",
              "status",
              "actions",
            ]}
          />
        </div>
      );
    const {
      setShowDeleteConfirmation,
      setShowDeleteConfirmation2,
      unBlockPatient,
      blockPatient,
      mascarateUser,
      filters,
      handleFilterChange,
      setPatitentType,
    } = this.props;

    const pageSize =
      filters.pageSize < patients.filter.totalCount
        ? filters.pageSize
        : patients.filter.totalCount === 0
        ? 10
        : patients.filter.totalCount;
    return (
      <div>
        <div className="admin-search-bar">
          <select
            defaultValue={filters.patientType}
            onChange={setPatitentType}
            className="form-styled"
          >
            <option value="">All Patients</option>
            <option value="paying">Paying Patients</option>
          </select>

          <div className="input-group">
            <input
              type="text"
              value={this.state.filterName}
              className="form-styled"
              onChange={this.setFilterName}
              onKeyDown={this.handleKeyDown}
              placeholder="Filter by patient name"
            />
            <button
              type="submit"
              onClick={this.handleFilterNameSearch}
              className="btn btn-green"
            >
              Search
            </button>
          </div>

          <button
            type="reset"
            onClick={this.clearFilterNameSearch}
            className="btn btn-grey btn-clear"
          >
            Clear
          </button>
        </div>

        {loading && (
          <TableLoader
            columns={[
              "name",
              "email",
              "phone",
              "joined",
              "company",
              "status",
              "actions",
            ]}
          />
        )}
        {!loading && (
          <ReactTable
            manual
            sortable={false}
            loading={loading}
            page={filters.page}
            pageSize={pageSize}
            onPageChange={(page) => handleFilterChange("page", page)}
            onPageSizeChange={(size) => handleFilterChange("pageSize", size)}
            data={patients.data}
            pages={patients.filter.totalPages}
            columns={[
              {
                Header: "Name",
                accessor: "firstName",
                className: "name",
                minWidth: 250,
                Cell: (row) => User.userToNameString(row.original),
                textAlign: "left",
              },
              {
                Header: "Email",
                accessor: "email",
                className: "email",
                minWidth: 250,
                textAlign: "left",
              },
              {
                Header: "Phone",
                accessor: "phoneNumber",
                className: "phone",
              },
              {
                Header: "Joined",
                accessor: "created",
                className: "joined",
                Cell: (row) =>
                  row.value ? DateHelpers.toDisplayDate(row.value) : "",
              },
              {
                Header: "Company",
                accessor: "company.name",
                className: "company",
                foldable: true,
              },
              {
                Header: "Status",
                accessor: "status",
                className: "status",
                Cell: (props) => {
                  const patient = props.original;
                  return patient._blockedAt ? (
                    <span className="badge badge-pill badge-danger">
                      Blocked
                    </span>
                  ) : patient._isDeleted ? (
                    <span className="badge badge-pill badge-info">
                      Inactive
                    </span>
                  ) : (
                    <span className="badge badge-pill badge-success">
                      Active
                    </span>
                  );
                },
              },
              {
                Header: "",
                accessor: "actions",
                minWidth: 250,
                Cell: (row) => {
                  const patient = row.original;

                  return (
                    <div className="actions-cell">
                      {patient._blockedAt ? (
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={() => unBlockPatient(patient)}
                        >
                          <i className="icon-unlock"></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={() => blockPatient(patient)}
                        >
                          <i className="icon-user-lock"></i>
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => mascarateUser(patient)}
                      >
                        <i className="icon-theater"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => this.goToPatientFiles(patient)}
                      >
                        <i className="icon-files"></i>
                      </button>

                      <button
                        className="btn btn-red"
                        onClick={() =>
                          patient._isDeleted
                            ? setShowDeleteConfirmation2(patient)
                            : setShowDeleteConfirmation(patient)
                        }
                      >
                        <i className="icon-trash2"></i>
                      </button>
                    </div>
                  );
                },
              },
            ]}
            className="patient-table -striped -highlight"
          />
        )}
      </div>
    );
  }
}

export default PatientTable;
