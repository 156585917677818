import React from "react";
import { history } from "@modules/";

const JoinNow = (props) => {
  const { appointment } = props;
  const { bookStatus } = appointment;
  if (!(bookStatus === "ACCEPTED")) {
    return null;
  }
  return (
    <div className="chat-action-list-ittem ">
      <div className="cpl-trigger">
        <a
          className="btn-begin"
          onClick={() =>
            history.push(
              `/room/${appointment.user.username}/appointment/${appointment.id}`
            )
          }
        >
          JOIN NOW
        </a>
      </div>
    </div>
  );
};

export default JoinNow;
