import React from "react";
import { Form } from "react-final-form";
import { InputField } from "@src/components/fields/input";

function SpecialtyForm({ initialValues, onSubmit }) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <InputField name="name" label="Name:" />
          <InputField name="description" label="Description:" />
          <InputField name="type" label="Type:" />
          <div className="form-group">
            <label>Profile Visibility</label>
            <select name="visibility">
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
          </div>
          <InputField name="order" label="Order:" type="number" />
          <InputField name="upfrontFee" label="Upfront Fee:" type="number" />
          <InputField
            name="ruralUpfrontFee"
            label="Rural Upfront Fee:"
            type="number"
          />
          <InputField
            name="afterMedicareRebate"
            label="Out-of-pocket Metro Rebate:"
            type="number"
          />
          <InputField
            name="ruralAfterMedicareRebate"
            label="Out-of-pocket Rural Rebate:"
            type="number"
          />
          <InputField
            name="followUpfrontFee"
            label="Follow Upfront Fee:"
            type="number"
          />
          <InputField
            name="ruralFollowUpfrontFee"
            label="Rural Follow Upfront Fee:"
            type="number"
          />
          <InputField
            label="Out-of-pocket follow-up Metro Rebate:"
            name="followAfterMedicareRebate"
          />
          <InputField
            label="Out-of-pocket follow-up Rural Rebate:"
            name="ruralFollowAfterMedicareRebate"
          />
          <div className="buttons">
            <button
              type="submit"
              disabled={submitting || pristine}
              className={
                submitting || pristine
                  ? "btn-submitting"
                  : "btn-purple btn-large"
              }
            >
              Save
            </button>
          </div>
        </form>
      )}
    />
  );
}

export default SpecialtyForm;
