import api from "@api";
import { Routes } from "@constants/";
import empty from "is-empty";
import moment from "moment";

const Bot = class Session {
  static async askBot(message, context) {
    return await api.post(`${Routes.askBot}`, { message, context });
  }
  static botUser(user) {
    if (!user) {
      return {};
    }
    const birthday = empty(user.birthday)
      ? null
      : moment(user.birthday).format("DD/MM/YYYY");
    const [medicareNumber, IRN] = empty(user.medicareNumber)
      ? [null, null]
      : user.medicareNumber.split("/");
    //4 Brisbane Street, Eltham VIC, Australia
    const phoneNumber = empty(user.phoneNumber) ? null : user.phoneNumber;
    const address = empty(user.address)
      ? null
      : `${user.address}, ${user.address2} ${user.region}, ${user.country}`;
    let botUser = {
      userType: "isLogged",
      patientId: user.id,
      gpName: user.gpName,
      gpPractice: user.gpPractice,
      postcode: user.postcode,
      phoneNumberAny: phoneNumber,
      homeaddress: address,
      dob: birthday,
      MedicareNumber: medicareNumber,
      IRN: IRN,
      name: user.firstName,
      medicareFee: "25", //TODO GET THIS FROM DB
      email: user.email,
      lastname: user.lastName,
    };
    Object.keys(botUser).forEach((key) =>
      botUser[key] === undefined || botUser[key] === null
        ? delete botUser[key]
        : ""
    );
    return botUser;
  }
};

export default Bot;
