import React, { useState, useEffect } from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

let timerInterval = null;
const SessionTimer = (props) => {
  const { appointment } = props;
  const [durationCounter, setDurationCounter] = useState("");

  const startTimer = () => {
    timerInterval = setInterval(() => {
      if (appointment?.startedAt) {
        const diffDuration = moment.duration(
          moment() - moment(appointment?.startedAt)
        );
        const format = diffDuration.format("hh:mm:ss");
        setDurationCounter(format);
      }
    }, 1000);
  };
  useEffect(() => {
    if (!["CLOSED_BY_PATIENT", "CLOSED"].includes(appointment?.status)) {
      startTimer();
    }
    return clearChatTimer;
  }, []);

  const clearChatTimer = () => {
    clearInterval(timerInterval);
  };

  return (
    <span className="ps-timer">
      {["CLOSED_BY_PATIENT", "CLOSED"].includes(appointment?.status)
        ? "CHAT ENDED"
        : durationCounter}
    </span>
  );
};
export default SessionTimer;
