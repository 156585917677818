import api from "@src/api";
import { Routes } from "@constants";

const getCompanyWithToken = (token, id) => {
  return api.get(Routes.companyWithToken(token, id));
};
const deleteCompanyWithToken = (id, token) => {
  return api.del(Routes.companyWithToken(token, id));
};
const editCompany = async (file, companyId, payload, token) => {
  if (file) {
    var formData = new FormData();
    formData.append(file.name, file);

    const response = await api.multiPart(
      Routes.uploadCompanyLogo(token),
      formData
    );
    var fileName = response.result.files[file.name][0].name;
    payload.imagePath = fileName;
    return await api.put(Routes.companyWithToken(token, companyId), payload);
  } else {
    return await api.put(Routes.companyWithToken(token, companyId), payload);
  }
};

const createCompany = async (file, payload, token) => {
  if (file) {
    var formData = new FormData();
    formData.append(file.name, file);
    return await api
      .multiPart(Routes.uploadCompanyLogo(token), formData)
      .then((response) => {
        var fileName = response.result.files[file.name][0].name;
        payload.imagePath = fileName;

        return api.post(Routes.companiesWithToken(token), payload);
      });
  } else {
    return await api.post(Routes.companiesWithToken(token), payload);
  }
};
const fetchCompanyImage = async (file) => {
  return await api.get(Routes.companyLogo(file));
};
const findBySlug = async (slug) => {
  return await api.get(Routes.companyFindBySlug(slug));
};
const fetchAll = async () => {
  return await api.get(Routes.companies);
};
export default {
  findBySlug,
  getCompanyWithToken,
  createCompany,
  deleteCompanyWithToken,
  fetchAll,
  editCompany,
  fetchCompanyImage,
};
