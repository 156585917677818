import React from "react";
import CallSettings from "@components/call_settings";

const JoinNow = (props) => {
  const { joinNow, user, onBack, callSettingError } = props;

  return (
    <div className="jag_info_wrap">
      {!user.id ? (
        <div className="jag_prev">
          <a onClick={() => onBack()}>
            <i className="icon-chevron-left"></i>
            Back
          </a>
        </div>
      ) : null}

      <h1 className="jag_title"></h1>
      <span className="jag_sub_title_capitalize">
        Check connections then <span className="join_now">JOIN NOW</span> to
        connect to the doctor
      </span>

      <CallSettings
        joinNowData={{
          isDoctor: false,
          doctorOnline: true,
        }}
        allowChat={true}
        joinNow={() => joinNow()}
        onError={(error) => callSettingError(error)}
      />
      <div className="jag_footer_action">
        <a onClick={() => joinNow()}>JOIN NOW</a>
      </div>
    </div>
  );
};

export default JoinNow;
