import React, { useState } from "react";
import { Routes } from "@constants/";
import ta from "time-ago";
import ReferralFiles from "./referral_files";
import ChangePriceModal from "./change_price_modal";
import Title from "./title";
import MoreOptions from "./more_options";
import JoinNow from "./join_now";
import { Appointments, User, DateHelpers } from "@helpers/index";
import { useDispatch } from "react-redux";
import { closeConfirmationModal, showConfirmationModal } from "@modules/gui";
import userPlaceHolder from "@assets/images/patient_placeholder.jpg";

const Header = ({
  updateBookStatus,
  userRole,
  appointment,
  user,
  onAppointmentChange,
  showConversations,
}) => {
  const [showChangePriceModal, setShowChangePriceModal] = useState(false);
  const appointmentId = appointment?.id;
  const bookStatus = appointment?.bookStatus;
  const dispatch = useDispatch();

  const cancelAppointment = () => {
    const payload = { bookStatus: "CANCELLED", cancelledBy: userRole };
    updateBookStatus(payload);
  };
  const renderFeeAdmin = (appointment, role, bookStatus) => {
    if (
      !(
        role === "admin" &&
        ["ADMIN-REVIEW", "NEW", "PREAPROVED-DATES", "REQUEST-DATES"].includes(
          bookStatus
        )
      )
    ) {
      return null;
    }
    return (
      <div className="tc-actions">
        {appointment.isMedicareElegible ? (
          <div className="label-wrap-left">
            Fee {appointment.medicareFeeCents / 100} AUD
          </div>
        ) : (
          <div
            className="label-wrap-left"
            onClick={() => setShowChangePriceModal(true)}
          >
            {appointment.price / 100} AUD
          </div>
        )}
        {appointment.isFree ? null : (
          <div className="label-wrap-right">
            <label>
              <input
                onChange={(e) => medicareButtonClicked(false)}
                type="radio"
                name="bulk-bill"
                value={false}
                checked={!appointment.isMedicareElegible}
              />
              <div className="radio-indicator"></div>
              No
            </label>
            <span>Medicare</span>
            <label>
              <input
                onChange={(e) => medicareButtonClicked(true)}
                type="radio"
                name="bulk-bill"
                value={true}
                checked={appointment.isMedicareElegible}
              />
              <div className="radio-indicator"></div>
              Yes
            </label>
          </div>
        )}
      </div>
    );
  };
  const showConfirmCancel = () => {
    dispatch(
      showConfirmationModal({
        onProceed: () => cancelAppointment(),
        onCancel: () => dispatch(closeConfirmationModal()),
        onClose: () => dispatch(closeConfirmationModal()),
        proceedText: "Cancel appointment",
        cancelText: "close",
        children: `Are you sure you want to cancel the consultation`,
      })
    );
  };
  const showConfirmNoMedicare = () => {
    dispatch(
      showConfirmationModal({
        onProceed: () => changetoMedicareConsultation(false),
        onCancel: () => dispatch(closeConfirmationModal()),
        onClose: () => dispatch(closeConfirmationModal()),
        proceedText: "Ok",
        cancelText: "close",
        children: `Are you sure you want to set this appointment as a A$
        ${appointment.price / 100} consultation`,
      })
    );
  };
  const showConfirmMedicare = () => {
    dispatch(
      showConfirmationModal({
        onProceed: () => changetoMedicareConsultation(true),
        onCancel: () => dispatch(closeConfirmationModal()),
        onClose: () => dispatch(closeConfirmationModal()),
        proceedText: "Ok",
        cancelText: "close",
        children: `Are you sure you want to set this appointment as a A$
        ${appointment.medicareFeeCents / 100} consultation`,
      })
    );
  };
  const medicareButtonClicked = (isMedicare) => {
    if (isMedicare) {
      showConfirmMedicare();
      return;
    }
    showConfirmNoMedicare();
  };
  const changetoMedicareConsultation = (isMedicare) => {
    const payload = {
      bookStatus: "SET_MEDICARE_ELEGIBLE",
      isMedicareElegible: isMedicare,
    };
    updateBookStatus(payload);
    dispatch(closeConfirmationModal());
  };
  const changeConsultationPrice = async (price) => {
    try {
      const response = await Appointments.changeConsultationCost(
        appointmentId,
        user.token,
        price
      );
      console.log({ type: "changeConsultationPrice", response });
      onAppointmentChange();
      setShowChangePriceModal(false);
    } catch (error) {
      console.error(error);
      setShowChangePriceModal(false);
    }
  };
  const renderOptions = () => {
    const options = [
      <JoinNow key="joinNow" appointment={appointment} user={user} />,
      <ReferralFiles key="refFiles" appointment={appointment} user={user} />,
      <MoreOptions
        user={user}
        userRole={User.appointmentRole(user, appointment)}
        key="moreOptions"
        appointment={appointment}
        cancelAppointmentClick={() => showConfirmCancel()}
      />,
    ];

    return (
      <div className="chat-action-list-wrap">
        <div className="chat-action-list-mobile">{options}</div>
        <div className="chat-action-list">{options}</div>
      </div>
    );
  };

  let owner = {};
  let recipient = {};
  let recipientImage = userPlaceHolder;
  if (appointment && user) {
    recipient = User.getRecipient(appointment, user);
    owner = User.getOwner(appointment, user);
    recipientImage =
      recipient && recipient.profileImage
        ? Routes.profilePicUrl(recipient.profileImage)
        : userPlaceHolder;
  }

  const statusRender = renderOptions();
  const feeAdminHeader = renderFeeAdmin(appointment, userRole, bookStatus);

  return (
    <div className="header">
      <div className="header-upper">
        <a className="chat-wrap-toggle" onClick={() => showConversations()}>
          <i className="icon-arrow-left"></i>
        </a>

        <div className="this-chat-info">
          <div className="tc-inner">
            <img src={recipientImage} />
            <div className="tc-main">
              <div className="tc-info">
                <span>{User.conversationDisplayName(recipient)}</span>
                <b>to</b>
                <span>
                  {owner && owner.id === user.id
                    ? "Me"
                    : User.conversationDisplayName(owner)}
                </span>
              </div>

              <div className="tc-date">
                <span>
                  {DateHelpers.displayDoctoChatTime(appointment.updatedAt)} -{" "}
                  {ta.ago(appointment.updatedAt)}
                </span>
              </div>
            </div>
          </div>
          {feeAdminHeader}
        </div>
        {statusRender}
      </div>
      <Title
        {...{ owner, recipient, role: userRole, appointment, bookStatus }}
      />
      {showChangePriceModal && (
        <ChangePriceModal
          price={appointment.price / 100}
          onChangePrice={(price) => changeConsultationPrice(price)}
          closeModal={() => setShowChangePriceModal(false)}
        />
      )}
    </div>
  );
};

export default Header;
