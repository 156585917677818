import React, { useEffect, useState } from "react";
import { Routes } from "@constants";
import isImage from "is-image";
import { DateHelpers, PubNubInterface, Utils } from "@helpers/";
import { Appointments } from "@helpers/index";
import { useDispatch } from "react-redux";
import { showDocumentViewer } from "@modules/gui";
const TextMessage = (props) => {
  const { body, link } = props;
  let isArray = Array.isArray(body);
  return (
    <span>
      {isArray ? (
        body.map((text, index) => (
          <span key={`message${index}`}>
            {text}
            <br />
          </span>
        ))
      ) : link ? (
        <a href={link} rel="noreferrer" target="_blank">
          {body}
        </a>
      ) : (
        <span>{body}</span>
      )}
    </span>
  );
};
const FileMessage = (props) => {
  const dispatch = useDispatch();
  const [meta, setMeta] = useState({
    isImage: false,
    fileUrl: null,
    fileReader: null,
    fileName: null,
    type: "file",
  });
  const [objectUrl, setObjectUrl] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [errorFileMessage, setErrorFileMessage] = useState(null);

  const sendFile = async () => {
    let payload = Object.assign({}, props.payload);
    payload.type = "file";
    // let messagingService = payload.messagingService;
    payload.messagingService = null;
    let formData = new FormData();
    let file = payload.file;
    let reader = new FileReader();

    reader.onloadend = () => {
      let metaState = {
        fileUrl: null,
        isImage: file.type.match("image.*"),
        fileReader: reader.result,
        fileName: file.name,
        type: "file",
      };
      setMeta(metaState);
    };
    reader.readAsDataURL(file);
    formData.append("file", file);
    try {
      const filesUploaded = await Appointments.uploadAppointmentReferralDocument(
        payload.channel,
        payload.token,
        formData
      );
      console.log(filesUploaded);
      const { fileName } = filesUploaded[0];
      const meta = {
        fileUrl: fileName,
        fileName: fileName,
        type: "file",
        bucket: "docto-referral-documents",
      };
      payload.meta = meta;
      setMeta(meta);
      payload.pubNubInterface.sendMessage({
        data: payload.data,
        meta,
        senderId: payload.data.senderId,
        type: "file",
      });
    } catch (error) {
      setErrorFileMessage("failed");
    }
  };

  useEffect(() => {
    if (!meta) {
      return;
    }
    if (meta.fileReader) {
      setObjectUrl(meta.fileReader);
      return;
    }
    if (meta.bucket) {
      const appointmentId = props.payload.channel;
      const fileUrl =
        meta.bucket === "docto-referral-documents"
          ? Routes.downloadReferralDocument(appointmentId, meta.fileUrl)
          : Routes.chatFileUrl(meta.fileUrl);
      setFileUrl(fileUrl);
      loadObjectUrl(`${fileUrl}?accessToken=${props.user.token}`);
    }
  }, [meta]);
  const loadObjectUrl = async (url) => {
    const objectUr = await Utils.getObjectUrl(url);
    setObjectUrl(objectUr);
  };
  useEffect(() => {
    setMeta(props.meta);
    if (props.payload.type === "sendFile") {
      sendFile();
    }
  }, []);

  const showFile = (url) => {
    dispatch(showDocumentViewer({ url, token: props.user.token }));
  };
  return (
    <div className="chat-item attachment">
      {isImage(meta?.fileName) && objectUrl ? <img src={objectUrl} /> : null}
      <br />
      {fileUrl ? (
        <a
          rel="noreferrer"
          onClick={() => showFile(fileUrl)}
          className="ci-attch-link"
        >
          {meta.fileName}
        </a>
      ) : (
        <span className="ci-attch-link">
          {errorFileMessage ? errorFileMessage : `Sending...`}
        </span>
      )}
    </div>
  );
};

const Message = (props) => {
  let {
    body,
    timestamp,
    meta,
    link,
    type,
    thumbnailImage,
    senderId,
    user,
  } = props;
  const formmatedTime = DateHelpers.displayDoctoChatTime(timestamp);
  const received = senderId !== user.id;
  let extraClass = received ? "" : "received";
  let isFileMessage = (meta && meta.type === "file") || type === "sendFile";
  var divStyle = {
    backgroundImage:
      "url(" + Routes.thumbnailProfilePicUrl(thumbnailImage) + ")",
  };
  if (meta && meta.type === "system") {
    return null;
  }

  return (
    <div className={"chat-item " + extraClass}>
      <span className="ci-time-stamp">{formmatedTime}</span>
      {thumbnailImage && received ? (
        <div className="ci-avatar" style={divStyle}></div>
      ) : null}

      <div className="chat-bubble ci-text-bubble">
        {isFileMessage ? (
          <FileMessage
            meta={meta}
            payload={props}
            user={user}
            received={received}
          />
        ) : (
          <TextMessage body={body} link={link} />
        )}
      </div>
    </div>
  );
};

export default Message;
