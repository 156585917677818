import React from "react";
import loadingLightBlue from "@assets/images/loadingLightBlue.gif";

const Password = (props) => {
  const {
    error,
    submitting,
    close,
    loginWithPassword,
    password,
    setPassword,
    enable,
    email,
    setEmail,
  } = props;
  const tryLogin = (e) => {
    if (e.key === "Enter") {
      loginWithPassword();
    }
  };
  return (
    <div className="popup-wrap">
      <div className="modal-window md-sign-up">
        <div className="modal">
          <div>
            <div className="md-header">
              <a className="md-close" onClick={close}>
                <i className="icon-cross-circle"></i>
              </a>
              <h4>Log In</h4>
            </div>
            <div className="md-body">
              <input
                autoCapitalize="off"
                tabIndex="6001"
                type="email"
                placeholder={error?.email ? error?.email : "Email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={enable ? false : "disabled"}
              />

              <input
                autoCapitalize="off"
                tabIndex="6002"
                onKeyDown={(e) => tryLogin(e)}
                type="password"
                placeholder={error?.password ? error?.password : "Password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="md-footer">
              <a className="btn btn-info" onClick={loginWithPassword}>
                {submitting ? (
                  <img className="loading-login" src={loadingLightBlue} />
                ) : (
                  "Log In"
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
