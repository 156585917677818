import React from "react";
import { Helmet } from "react-helmet";

const ExecutiveTeam = () => {
  return (
    <div>
      <Helmet>
        <title>Docto Online Hospital - Meet the Team - About us</title>
        <meta
          name="description"
          content="Meet our Docto Online Hospital Team. Docto Online."
        />
        <link rel="canonical" href="https://www.docto.com.au/executive-team" />
      </Helmet>

      <div className="hero hero--team">
        <div className="wrap">
          <div className="hero__body">
            <h1 className="hero__title" itemProp="headline">
              Meet The Team
            </h1>

            <div className="flag flag--large flag--responsive team-member__block">
              <div className="flag__image has-video popup--video">
                <img
                  alt="Dr Jon Field"
                  src={"/assets/images/executive-team/jon600X600.jpg"}
                />
              </div>
              <div className="flag__body">
                <h2 className="team-member__title">Dr Jon Field</h2>

                <p className="team-member__role">
                  Director and Co-Founder (MbChB, FACEM, FCICM)
                </p>

                <p>
                  Jon is one of the driving forces behind Docto. He received his
                  medical degree at the University of Manchester in 1990 and has
                  practiced medicine in Australia since 1992. Jon’s specialist
                  training took him to all over Australia from Port Hedland,
                  Thursday Island, Cairns, Brisbane and Perth, to the Gold
                  Coast. Jon has dual fellowships in both Emergency Medicine and
                  Intensive Care, is currently a practicing specialist in both
                  fields and director of Intensive Care at the Gold Coast
                  University Hospital. He has interests in telemedicine,
                  eHealth, aeromedical retrieval, echocardiography and organ
                  donation.
                </p>

                <p>
                  He has provided telemedicine services for international travel
                  insurance, medical assistance and emergency management
                  companies for over 10 years.
                </p>

                <ul className="author-details"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-inner layout__team">
        <div className="layout">
          <main
            className="content layout__item one-whole layout__team"
            itemProp="mainContentOfPage"
            role="main"
          >
            <div className="team">
              <ul className="layout layout--flush layout--flex">
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block has-video has-avatar popup--video">
                    <div className="block__img team-member__img">
                      <img
                        alt="Dr Tim Harraway"
                        className="team-member__avatar"
                        src="../assets/images/executive-team/DOCTO_Tim-cropped-300x300.jpg"
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">Dr Tim Harraway</h3>

                      <p className="team-member__role">
                        Director of Emergency Medicine and Co-founder (MBBS,
                        FACEM)
                      </p>

                      <p>
                        Tim is a proud New Zealander who received his medical
                        degree from the University of Auckland before relocating
                        to Queensland to train in Emergency Medicine, receiving
                        his specialist qualification in 1998. His sub-specialty
                        interests are pre-hospital and remote area care. He
                        works both in public hospital practice and for a major
                        aeromedical provider.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block has-video has-avatar popup--video">
                    <div className="block__img team-member__img">
                      <img
                        alt="Dr Andrew Jones"
                        className="team-member__avatar"
                        src="../assets/images/executive-team/DOCTO_Andrew-cropped-300x300.jpg"
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">Dr Andrew Jones</h3>

                      <p className="team-member__role">
                        Medical Specialist Director and Co-founder (MBBS,
                        MRCP(UK), MSc, FRACP, FRCPA)
                      </p>

                      <p>
                        Andrew is an Infectious Diseases Specialist Physician
                        and Clinical Microbiologist with 15 years experience in
                        Public and Private Hospital Practices on the Gold Coast.
                        His interests and expertise lie in clinical infection –
                        including tropical and travel related infection,
                        infection in intensive care patients and microbiology.
                        As well as being part of a number of major medical
                        organisations, Andrew’s qualifications include an MBBS
                        at the University of London, General Medical training in
                        the UK, a Master of Science (Clinical Microbiology) at
                        The Royal London Hospital.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block">
                    <div className="block__img team-member__img">
                      <img
                        alt="Richard Anderson"
                        className="team-member__avatar"
                        src="../assets/images/executive-team/2016-03-10-06.50.26-300x300.png"
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">Richard Anderson</h3>

                      <p className="team-member__role">
                        Chief Financial Officer B.Com. ACA.
                      </p>

                      <p>
                        Richard Anderson received a Bachelor of Commerce from
                        University of Western Australia and subsequently
                        qualified as a Chartered Accountant with Grant Thornton
                        Chartered Accountants. Richard also gained a Graduate
                        Diploma in Applied Finance from The Securities Institute
                        of Australia.
                      </p>
                      <p>
                        Richard is an Investment Director with PieLAB Venture
                        Partners Pty Ltd, a Private Equity group based in
                        Brisbane. PieLAB Venture Partners currently manage a
                        fund that invests in late stage startups in the Real
                        Estate support services sector.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block">
                    <div className="block__img team-member__img">
                      <img
                        alt="Susan McLennan"
                        className="team-member__avatar"
                        src="../assets/images/executive-team/Screenshot-2015-08-11-07.06.07-300x300.png"
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">Susan McLennan</h3>

                      <p className="team-member__role">
                        Corporate Accounts Manager
                      </p>

                      <p>
                        Susan has over 25 years medical administration
                        experience in both public and private hospital settings,
                        as well as ten years practice management experience for
                        a multi-disciplinary private practice, in additional to
                        establishing&nbsp; a medical typing company supplying
                        typing and administrative services for various
                        specialists for the last 15 years.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block">
                    <div className="block__img team-member__img">
                      <img
                        alt="Shane George"
                        className="team-member__avatar"
                        src={"/assets/images/shane_george.png"}
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">Shane George</h3>

                      <p className="team-member__role">
                        Chief Technical Officer
                      </p>

                      <p>
                        Shane George, Chief Technology Officer at Docto. Shane
                        has built several medical applications in JavaScript,
                        Python, Ruby and CSS. He has a particular interest in
                        digital security, including contemporary encryption
                        techniques, website security, electronic medical records
                        and secure messaging. He is an expert in
                        videoconferencing and cloud based telecommunications
                        technology.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block">
                    <div className="block__img team-member__img">
                      <img
                        alt="Martin Gordon Brown"
                        className="team-member__avatar"
                        src="../assets/images/executive-team/martin_gordon.jpg"
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">
                        Martin Gordon Brown
                      </h3>

                      <p className="team-member__role">Designer</p>

                      <p>
                        Martin is a multi-media production and placement
                        specialist with a diverse history in product, system and
                        user design across a variety of green and care
                        industries. Studying product design and marketing
                        enabled Martin to merge the manufacture of products with
                        the experience of using them to produce compelling
                        narratives of their application to user life. This broad
                        skill-set has been used to establish businesses in
                        product design and manufacture, digital enterprise
                        systems, retail design and fit-out, dining experiences
                        and online products. Combining these skills and
                        application to detail enabled Martin to co-found Secret
                        Agency in 2015. This agency has worked alongside the
                        Docto visionaries from Day 1 in the creation of their
                        brand, market strategy, multi-media production and
                        social media content.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block">
                    <div className="block__img team-member__img">
                      <img
                        alt="Phoebe Skye Owen Brown"
                        className="team-member__avatar"
                        src="../assets/images/executive-team/phoebe_skye.jpg"
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">
                        Phoebe Skye Owen Brown
                      </h3>

                      <p className="team-member__role">Marketing</p>

                      <p>
                        Phoebe is a communications specialist with a strong
                        background across the arts, medical and research
                        industries. After receiving a Bachelor of Communications
                        in Journalism and Public Relations, Phoebe has spent the
                        past ten years working for a number of high profile arts
                        and medical organisations, including the Gallery of
                        Modern Art, Queensland Theatre Company and Mater
                        Foundation—where she worked on creating engaging medical
                        and research content to drive fundraising. Branching out
                        to combine her passion for writing, marketing and public
                        relations, in 2015 Phoebe co-founded Secret Agency—who
                        proudly contribute branding, marketing and public
                        relations strategy and advice to Docto.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block">
                    <div className="block__img team-member__img">
                      <img
                        alt="Zoe Hackett "
                        className="team-member__avatar"
                        src="../assets/images/executive-team/billy300X300.jpg"
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">Biliana Necheva</h3>

                      <p className="team-member__role">Ai Architect</p>

                      <p>
                        Biliana has over 18 years of professional experience as
                        an editor, communication strategist, UX designer and
                        entrepreneur. In the past two years, she was fascinated
                        by the breakthroughs in the field of Ai and decided to
                        use her knowledge and passion to be part of the
                        development of smart and human-centric conversational
                        interfaces. As a graduated “Library and Information
                        Sciences” and “Public relations” professional, she feels
                        comfortable to mix coding with creativity, knowledge
                        management, and communications studies. Billy is the
                        lead Ai Architect at DOCTO.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block">
                    <div className="block__img team-member__img">
                      <img
                        alt="Dr Matthew Ostwald"
                        className="team-member__avatar"
                        src="../assets/images/executive-team/mathew300X300.jpg"
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">Dr Matthew Ostwald</h3>

                      <p className="team-member__role">
                        Cyber Security Officer
                      </p>

                      <p>
                        He started his career in IT, obtaining a B.IT from the
                        Queensland University of Technology before working as an
                        IT security consultant, firstly in Australia before
                        moving to Japan, working in both Tokyo and Seoul.
                        <br />
                        He returned to Australia to study medicine, gaining his
                        B.Sc and MBBS from the University of Queensland, and
                        subsequently becoming an intensive care specialist.
                        <br />
                        He currently lives on the Gold Coast and is trying not
                        to be the slowest amateur triathlete on the coast.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
                <li className="layout__item team-member__item desk-one-half layout--flex--cell">
                  <div className="block block--center team-member__block">
                    <div className="block__img team-member__img">
                      <img
                        alt="Tanya Scardamaglia"
                        className="team-member__avatar"
                        src="../assets/images/executive-team/mathew300X300.jpg"
                      />
                    </div>

                    <div className="block__body">
                      <h3 className="team-member__title">Tanya Scardamaglia</h3>

                      <p className="team-member__role">Proyect Manager</p>

                      <p>
                        Tanya’s expertise is Digital Health having worked across
                        North Queensland and the Northern Territory in digital
                        program service delivery (eHealth) in the primary
                        healthcare sector. <br />
                        Tanya has a Bachelor in Communications and aims to
                        creatively engage with stakeholders throughout regional
                        areas building capacity in health care workers to
                        enhance the efficiency of healthcare delivery through
                        innovative technology.
                      </p>

                      <ul className="author-details"></ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default ExecutiveTeam;
