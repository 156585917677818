import React from "react";
import { useSelector } from "react-redux";
import { User } from "@helpers/";
import { Route, Switch, Redirect } from "react-router-dom";
import AppLayout from "@layouts/appLayout";
import Dashboard from "./dashboard";
import Profile from "./profile";

function Account() {
  const user = useSelector((state) => state.session.user);

  if (!User.isPatient(user)) {
    return <Redirect to="/" />;
  }

  return (
    <AppLayout>
      <div className="account">
        <Switch>
          <Route exact path="/account/dashboard" component={Dashboard} />
          <Route path="/account/Profile" component={Profile} />
          <Route path="/account" component={Profile} />
        </Switch>
      </div>
    </AppLayout>
  );
}

export default Account;
