import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Nurses, Toast } from "@helpers";
import Preloader from "../preloader/account";
import Form from "@src/containers/admin/nurses/form";
import powerdByImg from "@assets/images/vidBox-brand.png";

const EditNurse = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.session.user);

  const submit = (values) => {
    async function createNurse() {
      setLoading(true);
      try {
        Nurses.createNurse(values, user.token).then((result) => {
          if (result) {
            setLoading(false);
            Toast.displayToast({
              type: "success",
              message: "Nurse has been created",
            });
            return history.push("/admin/emergency-room-manager");
          }
        });
      } catch (error) {
        setLoading(false);
        Toast.displayToast({
          type: "error",
          message: "Something Went Wrong",
        });
      }
    }
    createNurse();
  };

  if (loading) return <Preloader title="Edit Nurse" />;
  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>Edit Nurse</h1>
        <div className="header-lined"></div>
        <Form onSubmit={submit} />
        <div className="vid-brand">
          <span>Powered By:</span>
          <img src={powerdByImg} />
        </div>
      </div>
    </div>
  );
};

export default EditNurse;
