import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table-v6";
import { User } from "@helpers";
import TableLoader from "../preloader/table";

class SpecialistTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: props.filters.textFilter,
    };
    this.setFilterName = this.setFilterName.bind(this);
    this.handleFilterNameSearch = this.handleFilterNameSearch.bind(this);
    this.clearFilterNameSearch = this.clearFilterNameSearch.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleFilterNameSearch();
    }
  }
  setFilterName(e) {
    const { value } = e.target;
    this.setState({ filterName: value });
  }
  handleFilterNameSearch(e) {
    const { filterName } = this.state;
    this.props.handleFilterChange("textFilter", filterName);
  }
  clearFilterNameSearch() {
    this.setState({ filterName: "" });
    this.props.handleFilterChange("textFilter", "");
  }
  render() {
    const {
      specialists,
      showDeleteAlert,
      showGuestLink,
      onChangePassword,
      filters,
      handleFilterChange,
      mascaradeUser,
      loading,
    } = this.props;

    if (!specialists) {
      return (
        <div className="mt-10 mb-10">
          <TableLoader
            columns={["name", "email", "specialty", "room_url", "actions"]}
          />
        </div>
      );
    }
    console.log(specialists);
    return (
      <div>
        <div className="admin-search-bar">
          <div className="input-group">
            <input
              type="text"
              value={this.state.filterName}
              className="form-control"
              onChange={this.setFilterName}
              placeholder="Filter by doctor name"
              onKeyDown={this.handleKeyDown}
            />
            <button
              type="submit"
              onClick={this.handleFilterNameSearch}
              className="btn btn-green"
            >
              Search
            </button>
          </div>
          <button
            type="reset"
            onClick={this.clearFilterNameSearch}
            className="btn btn-grey btn-clear"
          >
            Clear
          </button>
        </div>
        {loading && (
          <TableLoader
            columns={["name", "email", "specialty", "room_url", "actions"]}
          />
        )}
        {!loading && (
          <ReactTable
            manual
            sortable={false}
            loading={loading}
            page={filters.page}
            pageSize={
              filters.pageSize < specialists.totalCount
                ? filters.pageSize
                : specialists.totalCount
            }
            onPageChange={(page) => handleFilterChange("page", page)}
            onPageSizeChange={(size) => handleFilterChange("pageSize", size)}
            data={specialists.data}
            pages={specialists.totalPages}
            columns={[
              {
                Header: "Name",
                accessor: "firstName",
                className: "name",
                textAlign: "center",
                Cell: (row) => {
                  const specialist = row.original;
                  return (
                    <span>
                      <Link
                        className="capitalize"
                        to={`/admin/doctors/edit/${specialist.id}`}
                      >
                        {`${User.titleString(
                          specialist
                        )} ${User.userToNameString(
                          specialist
                        )} ${User.degreeString(specialist)}`}
                      </Link>
                    </span>
                  );
                },
              },
              {
                Header: "Email",
                accessor: "email",
                className: "email",
                textAlign: "center",
              },
              {
                Header: "Specialty",
                accessor: "specialty",
                className: "specialty",
                textAlign: "center",
                Cell: (row) => {
                  return User.specialtiesStr(row.original);
                },
              },
              {
                Header: "Room",
                accessor: "room",
                className: "room_url",
                textAlign: "center",
                Cell: (row) => {
                  let appointmentLink = `${window.location.origin}/room/${row.original.username}`;
                  return (
                    <a href={appointmentLink} target="_blank" rel="noreferrer">
                      {appointmentLink}
                    </a>
                  );
                },
              },
              {
                Header: "Actions",
                accessor: "actions",
                textAlign: "center",
                Cell: (row) => {
                  const specialist = row.original;
                  return (
                    <div className="actions-cell">
                      <button
                        className="btn btn-warning"
                        onClick={() => {
                          console.log("should show guest link");
                          return mascaradeUser(specialist);
                        }}
                      >
                        <i className="icon-theater"></i>
                      </button>
                      <button
                        className="btn btn-green"
                        onClick={() => {
                          console.log("should show guest link");
                          return onChangePassword(specialist.id);
                        }}
                      >
                        <i className="icon-rotation-lock"></i>
                      </button>

                      <button
                        className="btn btn-info"
                        onClick={() => {
                          console.log("should show guest link");
                          return showGuestLink(specialist);
                        }}
                      >
                        <i className="icon-link"></i>
                      </button>
                      <Link
                        className="btn btn-green"
                        to={`/admin/appointments/doctor/${specialist.id}`}
                      >
                        <i className="icon  icon-appt_calendar_add"></i>
                      </Link>

                      <button
                        className="btn btn-red"
                        onClick={() => {
                          return showDeleteAlert(specialist.id);
                        }}
                      >
                        <i className="icon icon-trash2"></i>
                      </button>
                    </div>
                  );
                },
              },
            ]}
            className="patients-table -striped -highlight"
          />
        )}
      </div>
    );
  }
}

export default SpecialistTable;
