import moment from "moment";

/**
 * Compares two time strings.
 *
 * @param {String} startTime
 * @param {String} endTime
 * @method isBeforeTime
 * @return {Boolean}
 */
const DateHelpers = {
  // isBeforeTime(startTime, endTime) {
  // 	startTime = moment(startTime, 'HH:mm A');
  // 	endTime = moment(endTime, 'HH:mm A');

  // 	return startTime.isBefore(endTime);
  // },

  // displayDuration(startTime, endTime) {
  // 	const duration = moment.duration(moment(endTime) - moment(startTime));
  // 	let minutes = duration.minutes();
  // 	let seconds = duration.seconds();

  // 	minutes = minutes < 10 ? `0${minutes}` : minutes;
  // 	seconds = seconds < 10 ? `0${seconds}` : seconds;

  // 	return `${minutes}:${seconds}`;
  // },

  displayDoctoChatTime(timestamp) {
    let formmatedTime = moment(timestamp).format("h:mm A");
    const sevenDaysAgo = moment().subtract(7, "day").unix();
    const OneDayAgo = moment().subtract(1, "day").unix();
    const OnehourAgo = moment().subtract(1, "hour").unix();
    const OneYearAgo = moment().subtract(1, "year").unix();
    const messageTime = moment(timestamp).unix();

    if (messageTime < OneYearAgo) {
      formmatedTime = moment(timestamp).format("MMM Do YYYY h:mm A ");
    } else if (messageTime < sevenDaysAgo) {
      formmatedTime = moment(timestamp).format("MMM Do h:mm A");
    } else if (messageTime < OneDayAgo && messageTime > OnehourAgo) {
      formmatedTime = moment(timestamp).format("ddd h:mm A");
    } else {
      formmatedTime = moment(timestamp).format("h:mm A");
    }
    return formmatedTime;
  },

  // hoursFromNow(startDate) {
  // 	return moment(Date.now()).diff(moment(startDate), 'hours');
  // },

  // secondsFromNow(startDate) {
  // 	return moment(Date.now()).diff(moment(startDate), 'seconds');
  // },

  // shortDatetime(date) {
  // 	return moment(date).format('ddd h:mma');
  // },

  // fulldayShortDatetime(date) {
  // 	return moment(date).format('dddd [at] h:mma');
  // },

  australianDateFormat(date) {
    return moment(date).format("DD/MM/YYYY h:mma");
  },

  // fullDayShortDate(date) {
  // 	return moment(date).format('MMM D, YYYY');
  // },

  // shortTime(date) {
  // 	return moment(date).format('h:mma');
  // },

  // toBrisbaneTimezone(date) {
  // 	return this.toTimezone(date, 10);
  // },

  // toTimezone(date, hoursOffset) {
  // 	const offset = date.getTimezoneOffset() / 60 + hoursOffset;
  // 	return new Date(date.getTime() + offset * 3600 * 1000);
  // },

  // isDate(date) {
  // 	return moment(date, 'DD/MM/YYYY').isValid();
  // },

  // toDate(date) {
  // 	return moment(date, 'DD/MM/YYYY').toDate();
  // },

  toDisplayDate(date) {
    return moment(date).format("DD/MM/YYYY");
  },
};

export default DateHelpers;
