import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { history } from "@modules/";
import Helmet from "react-helmet";
import {
  closeConfirmationModal,
  showConfirmationModal,
  showChatSideBar,
  showLoadingModal,
} from "@modules/gui";
import { useDispatch, useSelector } from "react-redux";
import { Appointments, Toast, User, Utils } from "@helpers/index";
import JoinNow from "@components/emergency_consult_access/join_now";

const InvalidAppointment = (props) => {
  const { errors } = props;
  return (
    <div className="jag_info_wrap">
      <h1 className="jag_title"></h1>
      <span className="jag_sub_title"></span>
      <div className="jag-call-alert">
        <div className="message-alert-box alert-danger">
          <i className="icon-warning"></i>
          <h4>Access Denied</h4>
          {errors.map((error, index) => {
            return <p key={`error-${index}`}>{error}</p>;
          })}
        </div>
      </div>
    </div>
  );
};
const Loading = () => {
  return (
    <div>
      <div className="jag_info_wrap">
        <h1 className="jag_title"></h1>
        <span className="jag_sub_title">LOADING ...</span>
      </div>
    </div>
  );
};

const ChatRoom = (props) => {
  console.log(props);
  const [errors, setErrors] = useState([]);
  const [appointment, setAppointment] = useState({});
  const [status, setStatus] = useState("LOADING");
  const params = props.computedMatch
    ? props.computedMatch.params
    : props.match.params;
  const { appointmentId } = params;
  const user = useSelector((state) => state.session.user);
  console.log(user);
  const query = Utils.useQuery(history.location);
  const { accepted } = query;
  const isAccepted = accepted === "true";
  const dispatch = useDispatch();
  const closeConfirmationModalThunk = closeConfirmationModal();
  const [holdCallData, setHoldCallData] = useState(null);

  useEffect(() => {
    fetchSubscriptionPlans();
    if (appointmentId) {
      validateEmergencyAppointmentAccess(appointmentId, user, isAccepted);
      return;
    }
  }, []);

  const fetchSubscriptionPlans = async () => {
    try {
      const response = await User.fetchSubscriptionPlans();
      if (response) {
        const { plans, onHoldData } = response;
        const planEnable = plans.find((plan) => {
          return plan.enabled;
        });
        setHoldCallData(onHoldData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateEmergencyAppointmentAccess = async (
    appointmentId,
    user,
    accepted
  ) => {
    try {
      const response = await Appointments.validateEmergencyAppointmentAccess(
        appointmentId,
        user.token
      );
      const { appointment } = response;
      setAppointment(appointment);
      if (accepted) {
        const isNurse = User.isNurse(user);
        let chatUser = isNurse ? appointment?.patient : user;
        chatUser.token = user.token;
        onInitChat(chatUser, response);
        return;
      }
      setStatus("REQUEST_ACCESS");
    } catch (error) {
      console.error(error);
      var res = JSON.parse(error.response);
      var message = res.error.message;
      if (res.error.code === "INVALID_APPOINTMENT_USER_ACCESS") {
        setErrors([message]);
        setStatus("ERROR");
        return;
      }
      if (res.error.code === "EMERGENCY_APPOINTMENT_EXPIRED") {
        history.replace("/consult-dr-now");
        setStatus("PATIENT_INFORMATION");
        let toastInfo = {
          message: "Emergency consultation expired. Please request a new one",
          kind: "warning",
        };
        Toast.displayToast(toastInfo);
        return;
      }
      setErrors(["Error Occur. Please contact administration"]);
      setStatus("ERROR");
    }
  };
  const acceptEmergencyAppointment = async (appointmentId) => {
    setStatus("INIT_CHAT");
    try {
      dispatch(showLoadingModal(true));
      const response = await Appointments.acceptEmergencyAppointment(
        appointmentId,
        user.token
      );
      onInitChat(user, response);
      dispatch(showLoadingModal(false));
    } catch (error) {
      dispatch(showLoadingModal(false));
      setStatus("REQUEST_ACCESS");
      const toastInfo = {
        message:
          "Error please try again. If the error persist please contact docto support.",
        kind: "error",
      };
      Toast.displayToast(toastInfo);
    }
  };

  const onInitChat = (patient, data) => {
    const { appointment, isHoldCallEnable } = data;
    if (isHoldCallEnable) {
      showScheduleAppointmentSuccess(true);
      return;
    }
    setStatus("INIT_CHAT");
    history.replace(`${history.location.pathname}?accepted=true`);
    dispatch(
      showChatSideBar({ user: patient, appointmentId: appointment?.id })
    );

    return;
  };

  const joinNow = (confirmHoldCallUntil) => {
    if (
      holdCallData &&
      !confirmHoldCallUntil &&
      appointment.status !== "ACCEPTED"
    ) {
      if (appointmentId) {
        showHoldCallUnavailableModal();
        return;
      }
      showHoldCallConfirmationModal();
      return;
    }
    closeConfirmationModal();

    if (appointmentId) {
      acceptEmergencyAppointment(appointmentId);
      return;
    }
  };
  const gotToDashBoard = () => {
    history.replace(`/`);
    dispatch(closeConfirmationModalThunk);
  };

  const showHoldCallConfirmationModal = () => {
    const thunk = showConfirmationModal({
      onProceed: () => joinNow(true),
      proceedText: "Accept",
      cancelText: "Cancel",
      children: `The next available appointment is at ${moment(
        holdCallData?.to
      ).format("hh:mm a")}`,
      onCancel: () => dispatch(closeConfirmationModalThunk),
      onClose: () => dispatch(closeConfirmationModalThunk),
    });
    dispatch(thunk);
  };
  const showHoldCallUnavailableModal = () => {
    const thunk = showConfirmationModal({
      onProceed: () => dispatch(closeConfirmationModalThunk),
      proceedText: "Accept",
      children: `Hello due to high demand, we are running behind schedule. Please
                 click the link in your email at {moment(to).format("hh:mm a")}.
                 Apologies for the inconvenience.`,

      onClose: () => dispatch(closeConfirmationModalThunk),
    });
    dispatch(thunk);
  };
  const showScheduleAppointmentSuccess = () => {
    const thunk = showConfirmationModal({
      onProceed: () => gotToDashBoard(),
      proceedText: "Accept",
      children: `Thanks you. Your consultation is scheduled for ${moment(
        holdCallData?.to
      ).format(
        "hh:mm a"
      )}. You will receive an SMS alert when the doctor joins`,
      onClose: () => dispatch(closeConfirmationModalThunk),
    });
    dispatch(thunk);
  };
  const child = () => {
    switch (status) {
      case "ERROR":
        return <InvalidAppointment errors={errors} />;
      case "LOADING":
        return <Loading />;
      case "REQUEST_ACCESS":
        return (
          <JoinNow
            {...props}
            user={user}
            location={history.location}
            joinNow={() => joinNow()}
            callSettingError={(e) => console.log(e)}
          />
        );

      case "INIT_CHAT":
      default:
        return null;
    }
  };

  return (
    <div className="joinAsGuestWrap">
      <Helmet>
        <title>Telehealth consultation</title>
        <meta
          name="description"
          content="Instant online medical consultation for online prescription, e-prescription, referral or certificate when your GP is not available"
        />
        <link rel="canonical" href="https://www.docto.com.au/consult-dr-now" />
      </Helmet>

      {child()}
      <div className="jag_brand"></div>
    </div>
  );
};

export default ChatRoom;
