import React from "react";
import { User } from "@helpers";
import initials from "initials";
import { Routes } from "@constants";

function specialist_box({ user }) {
  let divStyle = {};
  const ini = initials(User.fullName(user)).split("").join(".");
  if (typeof user.thumbnailImage !== "undefined") {
    divStyle = {
      backgroundImage: `url(${Routes.thumbnailProfilePicUrl(
        user.thumbnailImage
      )})`,
    };
  }
  return (
    <div className="mo-image-ittem">
      <div style={divStyle} className="mo-image"></div>
      <div className="mo-type">
        <span>
          {User.titleString(user)} {ini}
        </span>
      </div>
    </div>
  );
}

export default specialist_box;
