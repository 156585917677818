import React from "react";

function Preloader() {
  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>Account</h1>
        <div className="header-lined"></div>
        <div className="profile-img">
          <div className="profile-avatar" id="previewImage">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
          <a className="edit-avatar">
            <div className="loader-animation">
              <div></div>
            </div>
          </a>
        </div>

        <div className="prof-load">
          <div className="left">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
          <div className="right">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
        <div className="prof-load">
          <div className="left">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
          <div className="right">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
        <div className="prof-load">
          <div className="wide">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
        <div className="prof-load">
          <div className="wide">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
        <div className="prof-load">
          <div className="left">
            <div className="loader-animation">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preloader;
