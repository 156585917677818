import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "@constants";
import { useDispatch } from "react-redux";
import { SHOW_EMERGENCY_BOT } from "@modules/gui";

//Images
import LinkedInIcon from "@src/assets/images/icon-docto-linkedin.jpg";
import InstagramIcon from "@src/assets/images/icon-docto-instagram.jpg";
import FacebookIcon from "@src/assets/images/icon-docto-facebook.jpg";
import TwitterIcon from "@src/assets/images/icon-docto-twitter.jpg";

function footer() {
  const dispatch = useDispatch();

  const requestChat = () => {
    return dispatch({
      type: SHOW_EMERGENCY_BOT,
      show: true,
      initMessage: "welcome_isConsultOnlineDoctor",
    });
  };
  return (
    <div>
      <footer className="column-footer">
        <div className="container">
          <div className="row">
            <div className="col-xs-fifth">
              <h2>
                <Link to={Routes.paths.about}>About</Link>
              </h2>
              <ul>
                <li>
                  <Link to="/?pricing=true">Pricing</Link>
                </li>
                <li>
                  <Link to={Routes.paths.executiveTeam}>Executive Team</Link>
                </li>
                <li>
                  <Link to={Routes.paths.press}>Press</Link>
                </li>

                <li>
                  <a
                    href="https://s3-ap-southeast-2.amazonaws.com/docto-public-documents/DoctoTermsAndConditions.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and conditions
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3-ap-southeast-2.amazonaws.com/docto-public-documents/medications+DOCTO+will+not+prescribe.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Medications DOCTO will not prescribe
                  </a>
                </li>
                <li>
                  <a
                    to="https://s3-ap-southeast-2.amazonaws.com/docto-public-documents/DOCTO+policy+medical+certificates.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DOCTO policy medical certificates
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-xs-fifth">
              <h2>Services</h2>
              <ul>
                <li>
                  <button onClick={requestChat}>Consult Dr Now</button>
                </li>
                <li>
                  <button onClick={requestChat}>Medical Report</button>
                </li>
                <li>
                  <button onClick={requestChat}>Sickness Certificate</button>
                </li>
                <li>
                  <button onClick={requestChat}>Prescription</button>
                </li>
                <li>
                  <Link to={Routes.paths.expertOpinion}>Expert Opinion</Link>
                </li>
                <li>
                  <button onClick={requestChat}>Get a referral</button>
                </li>
                <li>
                  <Link to={Routes.paths.browseHospital}>
                    Consult Medical Specialist{" "}
                  </Link>
                </li>

                <li>
                  <Link to={Routes.paths.referAPatient}>Refer a patient</Link>
                </li>
              </ul>
            </div>

            <div className="col-xs-fifth">
              <h2>Business</h2>
              <ul>
                <li>
                  <Link to={Routes.paths.businessPage}>Docto for Business</Link>
                </li>
                <li>
                  <Link to={`${Routes.paths.businessPage}#staff`}>
                    Staff Support
                  </Link>
                </li>
                <li>
                  <Link to={`${Routes.paths.businessPage}#customer-assistance`}>
                    Customer Assistance
                  </Link>
                </li>
                <li>
                  <Link to={Routes.paths.privacyPolicy}>Privacy Statement</Link>
                </li>
                <li>
                  <Link to={Routes.paths.patientInfo}>Patient Information</Link>
                </li>
              </ul>
            </div>

            <div className="col-xs-fifth">
              <h2>Contact</h2>
              <ul>
                <li>
                  <a href="fax:+611300336286">fax: 07 3485 0689</a>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <a className="highlight-link" href="mailto:team@docto.com.au">
                    team@docto.com.au
                  </a>
                </li>
                <li>
                  <a href="tel:+611300336286">+61 1300 336 286</a>
                </li>
              </ul>
            </div>

            <div className="col-xs-fifth">
              <h2>Follow Us</h2>
              <ul>
                <li>
                  <Link to="https://www.doctoblog.com" target="_blank">
                    Blog
                  </Link>
                </li>
                <li>
                  <span className="spacer"></span>
                </li>
                <li>
                  <Link
                    to="https://www.facebook.com/docto247/"
                    target="_blank"
                    className="img-icon"
                  >
                    <img alt="docto facebook" src={FacebookIcon} />
                  </Link>
                  <Link
                    to="https://twitter.com/docto247"
                    target="_blank"
                    className="img-icon"
                  >
                    <img alt="docto twitter" src={TwitterIcon} />
                  </Link>
                  <Link
                    to="https://www.instagram.com/docto247/"
                    target="_blank"
                    className="img-icon"
                  >
                    <img alt="docto instagram" src={InstagramIcon} />
                  </Link>
                  <Link
                    to="https://au.linkedin.com/company/docto"
                    target="_blank"
                    className="img-icon"
                  >
                    <img alt="docto linkedin" src={LinkedInIcon} />
                  </Link>
                </li>
                <li>
                  <span className="version-number">Version #</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <span>© 2021 DOCTO - All Rights Reserved</span>
        </div>
      </footer>
    </div>
  );
}

export default footer;
