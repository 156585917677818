import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Utils,
  Toast,
  User,
  Appointments,
  Specialties,
  Specialists,
  Guid,
} from "@helpers/";
import _ from "lodash";
import empty from "is-empty";
import { useDispatch, useSelector } from "react-redux";
import Datetime from "react-datetime";
import {
  closeEditBookAppointmentModal,
  showConfirmationModal,
  showLoadingModal,
  closeConfirmationModal,
} from "@modules/gui";
import { useLocation } from "react-router-dom";
import { history } from "@modules/";

const EditBookAppointentModal = (props) => {
  const appointmentId = useSelector(
    (state) => state.gui.editBookAppointmentModal.appointmentId
  );
  const user = useSelector((state) => state.session.user);
  const dispatch = useDispatch();
  const [appointment, setAppointment] = useState(null);
  const [specialists, setSpecialists] = useState([]);
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const [specialistId, setSpecialistId] = useState("");
  const [loadingSpecialist, setLoadingSpecialist] = useState(true);
  const location = useLocation();
  const validDate = (current) => {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  const validateAndEditAppointment = async (confirm, askDatesToDoctor) => {
    if (empty(specialistId)) {
      Toast.displayToast({
        message: "Please select a specialist to forward",
        kind: "warning",
      });
      return;
    }
    const preApprovedDates = _.compact([date1, date2]);
    console.log({ preApprovedDates, l: preApprovedDates.length, t: confirm });
    const specialist = specialists.find((sp) => sp.id === specialistId);

    const pendingAmmount = Appointments.pendingAmount(appointment);
    const preApprovedDatesFormat = preApprovedDates.map((pd) =>
      pd.format(`Do MMM YYYY @HH:mm`)
    );
    const datesFormat = preApprovedDatesFormat.join(" and ");
    if (!confirm && preApprovedDates.length === 0) {
      dispatch(
        showConfirmationModal({
          onProceed: () => validateAndEditAppointment(true, true),
          proceedText: "CONTINUE",
          children: `Send referral to ${User.specialistName(
            specialist
          )} and request dates?`,
          onClose: () => dispatch(closeConfirmationModal()),
        })
      );
      return;
    }
    if (!confirm) {
      dispatch(
        showConfirmationModal({
          onProceed: () => validateAndEditAppointment(true, false),
          proceedText: "CONTINUE",
          children: `Offer appointments with ${User.specialistName(
            specialist
          )} ${datesFormat} Patient will be charged $${pendingAmmount}" `,
          onClose: () => dispatch(closeConfirmationModal()),
        })
      );
      return;
    }
    const userId = empty(specialistId) ? null : specialistId;

    console.log({ preApprovedDates, userId, askDatesToDoctor });
    dispatch(closeConfirmationModal());
    dispatch(showLoadingModal(true));

    try {
      const response = await Appointments.editSpecialtyAppointment(
        appointmentId,
        user.token,
        { preApprovedDates, userId, askDatesToDoctor }
      );
      console.log(response);
      closeModal();
      dispatch(showLoadingModal(false));
      updateConveration();
    } catch (error) {
      dispatch(showLoadingModal(false));
      console.error(error);
    }
  };
  const updateConveration = () => {
    Utils.updateScreen(location, history);
  };
  const closeModal = () => {
    dispatch(closeEditBookAppointmentModal());
  };
  useEffect(() => {
    fetchAppointment();
    loadSpecialists();
  }, []);

  const fetchAppointment = async () => {
    try {
      const response = await Appointments.fetchAppointmentChat(
        appointmentId,
        user.token
      );
      setAppointment(response.appointment);
      if (response.appointment?.userId) {
        setSpecialistId(response.appointment.userId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const loadSpecialists = async () => {
    try {
      setLoadingSpecialist(true);
      const spclists = await Specialists.fetchSpecialists();
      spclists.sort(function (a, b) {
        var nameA = a.firstName.toLowerCase(),
          nameB = b.firstName.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      setSpecialists(spclists);
      setLoadingSpecialist(false);
    } catch (error) {
      console.error(error);
      setLoadingSpecialist(false);
    }
  };

  if (!appointment) {
    return null;
  }

  const errorClass = "ad-pill ad-pill-input";
  const successClass = "ad-pill ad-pill-input referal-pill";
  const { patient } = appointment;
  return (
    <div className="modal-window md-appointment">
      <div className="modal">
        <div className="appointments-dash-header">
          <span>Edit Consultation</span>
          <a onClick={() => closeModal()} className="back-btn">
            Close
          </a>
        </div>
        <div className="appointments-dash-title">
          <span>Patient Details</span>
        </div>
        <div className="appointments-dash-body">
          <div className="ad-pill ad-pill-input referal-pill">
            <div className="read-only">
              <i className="icon-user"></i>
              {User.userToNameString(patient)}
            </div>
          </div>
        </div>
        <div className="appointments-dash-title">
          <span>Doctor Information</span>
        </div>

        <div className="appointments-dash-body">
          {loadingSpecialist ? (
            <div className="ad-pill ad-pill-input referal-pill">
              <select key={Guid()} defaultValue={""} disabled="disabled">
                <option value={""} disabled="disabled">
                  LOADING...
                </option>
              </select>
            </div>
          ) : (
            <div className={successClass}>
              <select
                defaultValue={""}
                value={specialistId}
                key={Guid()}
                onChange={(ev) => setSpecialistId(ev.target.value)}
              >
                <option value={""}>unassigned</option>

                {specialists.map((s, index) => {
                  return (
                    <option key={`${s.id}_${index}`} value={s.id}>
                      {User.userToNameString(s)}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>

        <div className="appointments-dash-title">
          <span>Dates</span>
        </div>
        <div className="appointments-dash-body">
          <div className="ad-pill ad-pill-input">
            <Datetime
              value={date1}
              onChange={(value) => {
                setDate1(value);
              }}
              inputProps={{ placeholder: "Select Date (option 1)" }}
              timeFormat={false}
              dateFormat={"DD/MM/YYYY"}
              isValidDate={validDate}
            />
            <Datetime
              onChange={(value) => {
                setDate1(value);
              }}
              inputProps={{ placeholder: "Select Time (option 1)" }}
              value={date1}
              timeFormat={true}
              dateFormat={false}
              isValidDate={validDate}
            />
          </div>
          {date1 && (
            <div className="ad-pill ad-pill-input">
              <Datetime
                value={date2}
                onChange={(value) => {
                  setDate2(value);
                }}
                inputProps={{ placeholder: "Select Date (option 2)" }}
                timeFormat={false}
                dateFormat={"DD/MM/YYYY"}
                isValidDate={validDate}
              />
              <Datetime
                onChange={(value) => {
                  setDate2(value);
                }}
                inputProps={{ placeholder: "Select Time (option 2)" }}
                value={date2}
                timeFormat={true}
                dateFormat={false}
                isValidDate={validDate}
              />
            </div>
          )}
        </div>

        <div
          onClick={() => validateAndEditAppointment(false, false)}
          className="modal-footer"
        >
          Edit Consultation
        </div>
      </div>
    </div>
  );
};

export default EditBookAppointentModal;
