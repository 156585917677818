import React, { useState } from "react";
import loadingLightBlue from "@assets/images/loadingLightBlue.gif";

function MagicLink(props) {
  const { close, error, sendMagicLink, submitting, email, setEmail } = props;
  const tryMagicLinkSignUp = (e) => {
    if (e.key === "Enter") {
      sendMagicLink(email);
    }
  };
  return (
    <div className="popup-wrap">
      <div className="modal-window md-sign-up">
        <div className="modal">
          <div>
            <div className="md-header">
              <a className="md-close" onClick={close}>
                <i className="icon-cross-circle"></i>
              </a>
              <h4>Log in to DOCTO</h4>
            </div>

            <div className="md-body">
              <p>
                Instead of forcing you to remember a password, we email you a
                secure one-time link every time you login.
              </p>
              <input
                autoCapitalize="off"
                key="email-requ"
                tabIndex="6001"
                onKeyDown={(e) => tryMagicLinkSignUp(e)}
                type="email"
                placeholder={error?.["email"] ? error?.["email"] : "Email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {/*<a target="_blank" onClick={() => this.showSignupModal()} className="forgot">Dont have account?</a>*/}
            </div>

            <div className="md-footer">
              <button
                className="btn btn-info"
                onClick={() => sendMagicLink(email)}
              >
                {submitting ? (
                  <img className="loading-login" src={loadingLightBlue} />
                ) : (
                  "Email me a login link"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MagicLink;
