import React from "react";
import { Link } from "react-router-dom";
import LogoutAdmin from "./logout_admin";

function AdminNavigation() {
  return (
    <ul
      id="menu-primary-menu"
      className="menu genesis-nav-menu menu-primary responsive-menu"
    >
      <li className="menu-item menu-item-type-post_type menu-item-object-page">
        <Link to={"/admin/emergency-doctors"}>Admin Panel</Link>
      </li>
      <LogoutAdmin />
    </ul>
  );
}

export default AdminNavigation;
