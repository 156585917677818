import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Company, Toast } from "@helpers";
import Preloader from "../preloader/account";
import Form from "@src/containers/admin/create_company/form";
import powerdByImg from "@assets/images/vidBox-brand.png";

const EditCompany = () => {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({});
  const { id } = useParams();
  const user = useSelector((state) => state.session.user);

  const fetchCompany = async () => {
    try {
      const response = await Company.getCompanyWithToken(user.token, id);
      setCompany(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCompany();
  }, [user]);

  const submit = (values) => {
    async function editCompany() {
      setLoading(true);
      try {
        Company.editCompany(file, id, values, user.token).then((result) => {
          if (result) {
            setLoading(false);
            Toast.displayToast({
              type: "success",
              message: "Company has been updated",
            });
            return history.push("/admin/companies");
          }
        });
      } catch (error) {
        setLoading(false);
        Toast.displayToast({
          type: "error",
          message: "Something Went Wrong aqui",
        });
      }
    }
    editCompany();
  };

  if (loading) return <Preloader title="Edit Company" />;
  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>Edit Company</h1>
        <div className="header-lined"></div>
        <Form initialValues={company} onSubmit={submit} setImage={setFile} />
        <div className="vid-brand">
          <span>Powered By:</span>
          <img src={powerdByImg} />
        </div>
      </div>
    </div>
  );
};

export default EditCompany;
