import React from "react";
import { Appointments, Toast } from "@helpers";
import copy from "copy-to-clipboard";

class AppointmentLinkModal extends React.Component {
  constructor() {
    super();
    this.state = {
      showGuestLink: false,
      guestLinkStatus: "PROCESSING",
      guestLink: "",
    };
  }

  componentDidMount() {
    this.setState({
      guestLinkStatus: "PROCESSING",
      showGuestLink: true,
      guestLink: "",
    });
    const { token, username } = this.props.user;
    const { appointmentId } = this.props;
    console.log(this.props.user);
    try {
      Appointments.getAppointmentPatientToken(token, appointmentId).then(
        (response) => {
          console.log(response);
          let { token, appointmentId, userName } = response;
          let appointmentLink = `${window.location.origin}/room/${
            userName || username
          }/appointment/${appointmentId}?guestToken=${token}`;
          this.setState({
            guestLinkStatus: "SUCCESS",
            guestLink: appointmentLink,
          });
        }
      );
    } catch (error) {
      this.setState({
        guestLinkStatus: "PROCESSING",
        showGuestLink: false,
        guestLink: "",
      });
      Toast.displayToast({
        kind: "error",
        message: "An error occurred, please try again later.",
      });
    }
  }

  copyGuestLink() {
    copy(this.state.guestLink, {
      debug: true,
      message: "Press #{key} to copy",
    });
    Toast.displayToast({
      kind: "success",
      message: "link copied to your clipboard",
    });
  }

  render() {
    return (
      <div className="modal-window md-copy-url">
        <div className="modal">
          <div className="md-head">
            <a
              onClick={() => this.props.closeModal()}
              className="md-close"
              href="#"
            >
              <i className="icon-cross-circle"></i>
            </a>
            <h1>CONSULT ROOM LINK</h1>
            <p>
              {this.state.guestLinkStatus === "SUCCESS"
                ? "Copy link and share it with your patient."
                : "Generating your room link please wait..."}
            </p>
          </div>

          <div className="md-body">
            {this.state.guestLinkStatus === "SUCCESS" ? (
              <div className="md-copy-url-wrap">
                <input type="text" value={this.state.guestLink} readOnly />
                <button onClick={() => this.copyGuestLink()} type="button">
                  <i className="icon-copy"></i>
                  Copy link
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default AppointmentLinkModal;
