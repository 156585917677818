import React from "react";
const Success = (props) => {
  const { close } = props;
  return (
    <div className="popup-wrap">
      <div className="modal-window md-sign-up">
        <div className="modal">
          <div>
            <div className="md-header">
              <a href="#" className="md-close" onClick={close}>
                <i className="icon-cross-circle"></i>
              </a>
              <h4>Check your email now!</h4>
            </div>
            <div className="md-body">
              <p>
                Go to your inbox and click the link we have just sent you to log
                into DOCTO.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
