import React from "react";
import { useHistory } from "react-router-dom";
import Row from "./appointment_row";

function AppointmentSection({
  title,
  status,
  appointments,
  type,
  user,
  showUpsertAppointmentModal,
  appointmentsPatientWaiting,
  updateAppointments,
}) {
  const history = useHistory();
  return (
    <div className="appointments-dash">
      <div className="appointments-dash-title">
        <span>{title}</span>
      </div>
      <div className="appointments-dash-body">
        {status == "LOADING" ? (
          <div className="ad-pill">
            <div className="ad-pill-info">
              <span>
                <div className="loader-animation"></div>
              </span>
              <span>
                <div className="loader-animation">
                  <div></div>
                </div>
              </span>
            </div>
          </div>
        ) : appointments.length > 0 ? (
          appointments.map((ap) => {
            return (
              <Row
                key={`row-${ap.id}`}
                user={user}
                type={type}
                appointment={ap}
                updateAppointments={updateAppointments}
                patientWaiting={appointmentsPatientWaiting[ap.id]}
              />
            );
          })
        ) : (
          <div className="ad-pill status-info">
            <div className="ad-pill-info">
              <span>You dont have any {type} appoinments.</span>
              {type == "upcoming" ? (
                <span>
                  Click{" "}
                  <strong>
                    <a onClick={showUpsertAppointmentModal}>here</a>
                  </strong>{" "}
                  to create a new appointment.
                </span>
              ) : null}
            </div>
          </div>
        )}
        {appointments.length > 0 ? (
          <div className="appointments-dash-footer">
            <a onClick={() => history.push(`/appointments/list/${type}/1`)}>
              View More
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AppointmentSection;
