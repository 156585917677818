import React from "react";
import { connect } from "react-redux";
import { User } from "@helpers";
import {
  SHOW_PRICING_MODAL,
  SHOW_EMERGENCY_BOT,
  SHOW_PAYMENT_MODAL,
} from "@modules/gui";

class PricingModal extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedSubscriptionIndex: null,
      subscriptionPlans: [],
    };
  }
  componentDidMount() {
    User.fetchSubscriptionPlans(this.props.user).then((response) => {
      if (response) {
        this.setState({ subscriptionPlans: response.plans });
      }
    });
  }

  closeModal() {
    this.props.showPricingModal(false);
  }

  confirmCheckout(price) {
    if (!this.props.user.id) {
      this.closeModal();
      return this.props.requestChatAccess(this.props.user);
    }
    this.props.showPaymentModal(true);
    this.setState({ paymentModalPrice: price });
  }

  render() {
    const { subscriptionPlans } = this.state;
    return (
      <div>
        {this.props.gui.showPricingModal ? (
          <div className="modal-window md-pricing md-pricing_colored">
            <div className="modal">
              <div className="md-header">
                <div className="md-header-h4">ONLINE DOCTOR PRICING</div>
                <a
                  className="md-close"
                  onClick={() => this.closeModal()}
                  href="#"
                >
                  <i className="icon-cross-circle"></i>
                </a>
              </div>

              <div className="md-body">
                {subscriptionPlans &&
                  subscriptionPlans.map((subscription, subscriptionIndex) => (
                    <div
                      key={`subscription-${subscriptionIndex}`}
                      className="md-pricing__block"
                    >
                      <span className="md-pricing__block__price">
                        <sup>$</sup>
                        {subscription.price}
                      </span>
                      <span className="md-pricing__block__detail">
                        {subscription.name}
                      </span>
                      {subscription.enabled ? (
                        <button
                          onClick={() =>
                            this.confirmCheckout(subscription.price)
                          }
                          className="btn btn-black"
                        >
                          Checkout
                        </button>
                      ) : (
                        <button className="btn btn-black disabled">
                          Checkout
                        </button>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestChatAccess: (user) =>
      dispatch({
        type: SHOW_EMERGENCY_BOT,
        show: true,
        initMessage: "welcome_isConsultOnlineDoctor",
        user: user,
      }),
    showPricingModal: (show) =>
      dispatch({
        type: SHOW_PRICING_MODAL,
        show: show,
      }),
    showPaymentModal: (show, price) =>
      dispatch({
        type: SHOW_PAYMENT_MODAL,
        show: show,
        price: price,
      }),
  };
};
const mapStateToProps = ({ session, gui }) => ({
  user: session.user,
  gui,
});
export default connect(mapStateToProps, mapDispatchToProps)(PricingModal);
