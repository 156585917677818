import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Specialties, Toast } from "@helpers";
import SpecialtyForm from "./form";

function EditSpecialty() {
  const { id } = useParams();
  const { user } = useSelector((state) => state.session);
  const [specialty, setSpecialty] = useState([]);

  useEffect(() => {
    try {
      Specialties.fetchSpecialtyById(id).then((response) => {
        console.log(response);
        setSpecialty(response);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onSubmit = (values) => {
    try {
      if (user && user.token) {
        Specialties.editSpecialty(values, user.token, specialty.id).then(
          (response) => {
            console.log(response);
            return Toast.displayToast({
              kind: "success",
              message: "Specialty updated",
            });
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!specialty) return <div>loading</div>;
  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>Edit {specialty.name}</h1>
        <div className="header-lined"></div>
        <SpecialtyForm onSubmit={onSubmit} initialValues={specialty} />
      </div>
    </div>
  );
}

export default EditSpecialty;
