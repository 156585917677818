import React, { Component } from "react";

class EditableList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.element = null;
    this.emitChange = this.emitChange.bind(this);
  }
  // shouldComponentUpdate(nextProps) {
  // 	return nextProps.html !== document.getElementById(this.props.id).innerHTML;
  // }
  emitChange() {
    const elements = document.getElementById(this.props.id).children;
    var array = Array.from(elements);

    var values = array
      .map((el) => {
        return el.innerHTML;
      })
      .join("|");
    if (this.props.onChange && values !== this.lastHtml) {
      this.props.onChange(this.props.id, values);
    }
    this.lastHtml = values;
    return;
  }
  render() {
    const splitValues = this.props.values.split("|");
    const list = splitValues.map((value, index) => {
      return (
        <li
          key={index}
          className={`${this.props.childClass} editable-block`}
          contentEditable={this.props.enabled}
          suppressContentEditableWarning={true}
          onBlur={this.emitChange}
        >
          {value}
        </li>
      );
    });
    return (
      <ul id={this.props.id} suppressContentEditableWarning={true}>
        {list}
      </ul>
    );
  }
}

export default EditableList;
