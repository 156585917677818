import React from "react";
import { User } from "@helpers/";
import moment from "moment-timezone";

const HeaderTitle = ({ recipient, role, appointment }) => {
  const tz = moment.tz.guess();
  return (
    <div className="header-tittle">
      {role === "patient" ? (
        <h1>Consult with Dr {User.conversationDisplayName(recipient)} </h1>
      ) : (
        <h1>Consult with {User.conversationDisplayName(recipient)}</h1>
      )}
      <div className="ht-date">
        <strong>
          {appointment.specialty
            ? `${appointment.specialty.name} Specialist`
            : `${User.specialtiesStr(appointment.user)} Specialist`}
        </strong>
        <span>
          {moment(appointment.scheduledOn)
            .tz(tz)
            .format("HH:mm - MMM DD, YYYY")}
        </span>
      </div>
    </div>
  );
};
const HeaderUpcomingEmergencyConsultation = ({ appointment }) => {
  var tz = moment.tz.guess();
  return (
    <div className="header-tittle">
      <h1>Upcoming video consultation</h1>

      <div className="ht-date">
        <span>
          {moment(appointment.scheduledOn)
            .tz(tz)
            .format("HH:mm - MMM DD, YYYY")}
        </span>
      </div>
    </div>
  );
};
const HeaderTitleCompleted = ({ recipient, role, appointment }) => {
  var tz = moment.tz.guess();
  return (
    <div className="header-tittle">
      {role === "patient" ? (
        <h1>
          Consult Completed with Dr {User.conversationDisplayName(recipient)}{" "}
        </h1>
      ) : (
        <h1>
          Consult Completed with {User.conversationDisplayName(recipient)}
        </h1>
      )}
      <div className="ht-date">
        <strong>
          {appointment.specialty
            ? appointment.specialty.name
            : User.specialtiesStr(appointment.user)}{" "}
          Specialist
        </strong>
        <span>
          {moment(appointment.scheduledOn)
            .tz(tz)
            .format("HH:mm - MMM DD, YYYY")}
        </span>
      </div>
    </div>
  );
};
const HeaderTitlePreaprovedDates = ({ role }) => {
  return (
    <div className="header-tittle">
      {role === "patient" ? (
        <h1>Consult Dates Offered</h1>
      ) : (
        <h1>Consult Dates Offered</h1>
      )}
    </div>
  );
};
const HeaderTitleCancelled = () => {
  return (
    <div className="header-tittle">
      <h1>Consult Cancelled</h1>
    </div>
  );
};
const HeaderTitleRequestNewDates = ({ role }) => {
  return (
    <div className="header-tittle">
      {role === "patient" ? (
        <h1>New dates requested</h1>
      ) : (
        <h1>New dates requested</h1>
      )}
    </div>
  );
};
const Title = (props) => {
  const { owner, recipient, role, appointment, bookStatus } = props;

  switch (bookStatus) {
    case "PREAPROVED-DATES":
      return (
        <HeaderTitlePreaprovedDates
          {...{ owner, recipient, role, appointment }}
        />
      );
    case "REQUEST-DATES":
      return <HeaderTitleRequestNewDates {...{ role }} />;
    case "ACCEPTED":
      return <HeaderTitle {...{ recipient, role, appointment }} />;
    case "CANCELLED":
      return <HeaderTitleCancelled />;
    case "EMERGENCY-CONSULTATION":
      return <HeaderUpcomingEmergencyConsultation {...{ appointment }} />;
    case "COMPLETED":
      return <HeaderTitleCompleted {...{ recipient, role, appointment }} />;
    case "ADMIN-REVIEW":
    case "NEW":
    case "EXPIRED":
    default:
      return null;
  }
};
export default Title;
