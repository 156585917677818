import React from "react";
import { connect } from "react-redux";
import PubNub from "pubnub";
import { User, Appointments } from "@helpers";
import { Docto } from "@constants";
import Row from "@containers/doctor_pages/appointments/appointment_row";
import { SHOW_UPSERT_APPOINTMENT_MODAL } from "@modules/gui";

class AppointmentList extends React.Component {
  constructor() {
    super();
    this.state = {
      status: "LOADING",
      pages: 0,
      appointmentStatus: [],
      appointments: [],
    };
    this.updateAppointments = this.updateAppointments.bind(this);
  }

  componentDidMount() {
    this.initPubNub();
    this.updateAppointments();
  }

  initPubNub() {
    if (this.pubnub) {
      this.unsubscribePubNub;
    }
    this.pubnub = new PubNub({
      publishKey: this.props.envs.PUB_NUB_PUBLISH_KEY,
      subscribeKey: this.props.envs.PUB_NUB_SUBSCRIBE_KEY,
      ssl: true,
      presenceTimeout: 120,
      heartbeatInterval: 30,
      uuid: `${this.props.user.id}:${Docto.version}:${User.getRoleName(
        this.props.user
      )}`,
      error: function (error) {
        console.error("PUB NUB Error:", error);
      },
    });
  }

  unsubscribePubNub() {
    if (!this.pubnub) {
      return;
    }
    try {
      const channels = this.state.appointments.map((ap) => {
        return `${ap.id}-waiting`;
      });
      var existingListener = {
        message: function () {},
        presence: function () {},
      };
      this.pubnub.unsubscribe({ channels: channels });
      this.pubnub.removeListener(existingListener);
      this.pubnub.destroy();
    } catch (error) {
      console.error(error);
    }
  }

  subscribePubNubForMessages(appointments) {
    if (!this.pubnub) {
      return;
    }

    this.unsubscribePubNub();
    this.initPubNub();
    const channels = appointments.map((ap) => {
      return `${ap.id}-waiting`;
    });
    this.pubnub.subscribe({
      channels: channels,
      withPresence: true,
    });
    this.pubnub.addListener({
      message: (message) => {
        console.log(message);
      },
      presence: (p) => {
        var [uuid] = p.uuid.split(":");
        var [appointmentId] = p.channel.split("-");
        let appointmentStatus = this.state.appointmentStatus;

        if (p.action === "join" && uuid !== this.props.user.id) {
          appointmentStatus[appointmentId] = "active";
        } else {
          if (uuid !== this.props.user.id) {
            delete appointmentStatus[appointmentId];
          }
        }
        this.setState({ appointmentStatus: appointmentStatus });
      },
    });

    this.pubnub.hereNow(
      {
        channels: channels,
        includeUUIDs: true,
        includeState: true,
      },
      (status, response) => {
        let channels = response.channels;
        const channelsIds = Object.keys(channels);
        let appointmentStatus = this.state.appointmentStatus;
        channelsIds.forEach((channelID) => {
          let channel = channels[channelID];
          var [appointmentId] = channelID.split("-");
          var apppointment = this.state.appointments.find((a) => {
            return a.id == appointmentId;
          });
          const occupantsIDs = channel.occupants.map((oc) => {
            let [uuid] = oc.uuid.split(":");
            return uuid;
          });
          if (occupantsIDs.includes(apppointment.patient.id)) {
            appointmentStatus[appointmentId] = "active";
          }
        });
        this.setState({ appointmentStatus: appointmentStatus });
      }
    );
  }

  async updateAppointments() {
    this.setState({ status: "LOADING" });
    const role = "doctor";
    let { page, type } = this.props.computedMatch.params;
    page = Number(page) - 1;
    try {
      const response = await Appointments.fetchAppointments(
        this.props.user.token,
        type,
        "doctor",
        page
      );
      this.setState({
        appointments: response.appointments,
        pages: response.pages,
        status: "COMPLETED",
      });
      this.subscribePubNubForMessages(response.appointments);
    } catch (error) {
      console.log(error);
    }
  }
  goNext() {
    let { page, type } = this.props.computedMatch.params;
    let next = Number(page) + 1;
    this.props.history.push(`/appointments/list/${type}/${next}`);
    this.updateAppointments();
  }
  goPrev() {
    let { page, type } = this.props.computedMatch.params;
    let prev = Number(page) <= 1 ? 1 : Number(page) - 1;
    this.props.history.push(`/appointments/list/${type}/${prev}`);
    this.updateAppointments();
  }

  render() {
    const { appointments, status } = this.state;
    let { page, type } = this.props.computedMatch.params;
    type = type || "past";

    return (
      <div className="meeting-container">
        <div className="admin">
          <div className="appointments-inner">
            <div className="appointments-dash">
              <div className="appointments-dash-header">
                <span>YOUR APPOINTMENTS</span>
                <a
                  onClick={() =>
                    this.props.showUpsertAppointmentModal({ show: true })
                  }
                  className="add-btn"
                >
                  Add New
                </a>
              </div>
            </div>

            <div className="appointments-dash">
              <div className="appointments-dash-title">
                <span>{type} Appointments</span>
              </div>
              <div className="appointments-dash-body">
                {status === "LOADING" && (
                  <div className="ad-pill">
                    <div className="ad-pill-info">
                      <span>
                        <div className="loader-animation">
                          <div></div>
                        </div>
                      </span>
                      <span>
                        <div className="loader-animation">
                          <div></div>
                        </div>
                      </span>
                    </div>
                  </div>
                )}
                {appointments.map((ap) => {
                  return (
                    <Row
                      key={`row-${ap.id}`}
                      user={this.props.user}
                      type={type}
                      appointment={ap}
                      patientWaiting={this.state.appointmentStatus[ap.id]}
                      updateAppointments={this.updateAppointments}
                    />
                  );
                })}
                {appointments.length === 0 && (
                  <div className="ad-pill status-info">
                    <div className="ad-pill-info">
                      <span>You dont have any scheduled appointments.</span>
                      <span>
                        Click{" "}
                        <strong>
                          <a
                            onClick={() =>
                              this.props.showUpsertAppointmentModal({
                                show: true,
                              })
                            }
                          >
                            here
                          </a>
                        </strong>{" "}
                        to create a new appointment.
                      </span>
                    </div>
                  </div>
                )}
                <div className="appointments-dash-footer">
                  <div className="ad-pag">
                    {this.props.computedMatch.params.page > 1 ? (
                      <a onClick={() => this.goPrev()}>
                        <i className="icon-chevron-left"></i>
                      </a>
                    ) : null}

                    <span>{page}</span>
                    {this.props.computedMatch.params.page < this.state.pages ? (
                      <a onClick={() => this.goNext()}>
                        <i className="icon-chevron-right"></i>
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showUpsertAppointmentModal: ({ show, appointment, onComplete }) =>
      dispatch({
        type: SHOW_UPSERT_APPOINTMENT_MODAL,
        params: {
          show,
          appointment,
          onComplete,
        },
      }),
  };
};

const mapStateToProps = ({ session }) => ({
  user: session.user,
  envs: session.env,
});
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentList);
