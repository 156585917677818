import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Routes } from "@constants/";
import { Link } from "react-router-dom";
import { Token } from "@helpers";
import { logout, unmascaradeUser } from "@modules/session";
import { SHOW_LOGIN_MODAL } from "@modules/gui";

const Login = (props) => {
  const dispatch = useDispatch();

  const { showLoginModal } = props;

  const signout = useCallback(() => {
    const logoutFunction = logout();
    dispatch(logoutFunction);
  }, [dispatch]);

  const unMask = () => {
    dispatch(unmascaradeUser());
  };
  const mascaradeToken = Token.getMaskToken();
  const { user } = props;

  return (
    <li style={{ listStyle: "none", display: "inline" }}>
      <ul className="side-right">
        {user.id ? (
          <li key={"3menuItem"} className="menu-item">
            <Link to={Routes.paths.account.index}>
              <i className="account-icon"></i>
              {user.firstName}
            </Link>
            <ul className="sub-menu sub-menu-right">
              <li className="menu-item menu-item-type-post_type menu-item-object-page ">
                <Link
                  className="logout-landing"
                  to={Routes.paths.account.index}
                >
                  Profile
                </Link>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page ">
                <Link className="logout-landing" to={Routes.paths.messages}>
                  Appointments
                </Link>
              </li>
              {mascaradeToken ? (
                <li className="menu-item menu-item-type-post_type menu-item-object-page ">
                  <a className="logout-landing" onClick={unMask}>
                    UNMASK {user.firstName}
                  </a>
                </li>
              ) : (
                <li className="menu-item menu-item-type-post_type menu-item-object-page ">
                  <a className="logout-landing" onClick={signout}>
                    Logout {user.firstName}
                  </a>
                </li>
              )}
            </ul>
          </li>
        ) : (
          <li className="menu-item menu-item-popup" id="menu-item-923">
            <a onClick={showLoginModal} className="logout-landing">
              <i className="account-icon"></i>
              Log In
            </a>
          </li>
        )}
      </ul>
    </li>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoginModal: () =>
      dispatch({
        type: SHOW_LOGIN_MODAL,
        show: true,
      }),
  };
};
const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
