import React from "react";
import PubNub from "pubnub";
import { connect } from "react-redux";
import { Appointments, User } from "@helpers/";
import { Docto } from "@constants/";
import AppointmentSection from "./appointment_section";
import { SHOW_UPSERT_APPOINTMENT_MODAL } from "@modules/gui";
class AppointmentsPage extends React.Component {
  constructor() {
    super();
    this.state = {
      status: "LOADING",
      upcomingAppointments: [],
      pastAppointments: [],
      emergencyAppointments: [],
      appointmentStatus: {},
    };
    this.showUpsertAppointmentModal = this.showUpsertAppointmentModal.bind(
      this
    );
    this.updateAppointments = this.updateAppointments.bind(this);
  }

  unsubscribePubNub() {
    if (!this.pubnub) {
      return;
    }
    try {
      const upcomingChannelsWaiting = this.state.upcomingAppointments.map(
        (ap) => {
          return `${ap.id}-waiting`;
        }
      );
      const pastChannelsWaiting = this.state.pastAppointments.map((ap) => {
        return `${ap.id}-waiting`;
      });

      const channels = upcomingChannelsWaiting.concat(pastChannelsWaiting);
      var existingListener = {
        message: function () {},
        presence: function () {},
      };
      this.pubnub.removeListener(existingListener);
      this.pubnub.unsubscribe({ channels: channels });
      this.pubnub.destroy();
    } catch (error) {
      console.error(error);
    }
  }

  componentWillUnmount() {
    this.unsubscribePubNub();
  }

  subscribePubNubForMessages(upcomingAppointments, pastAppointments) {
    const upcomingChannelsWaiting = upcomingAppointments.map((ap) => {
      return `${ap.id}-waiting`;
    });
    const pastChannelsWaiting = pastAppointments.map((ap) => {
      return `${ap.id}-waiting`;
    });
    const allAppointments = upcomingAppointments.concat(pastAppointments);

    const channels = upcomingChannelsWaiting.concat(pastChannelsWaiting);
    this.unsubscribePubNub();
    this.initPubNub();
    this.pubnub.subscribe({
      channels: channels,
      withPresence: true,
    });
    this.pubnub.addListener({
      message: (message) => {
        console.log(message);
      },
      presence: (p) => {
        var [uuid] = p.uuid.split(":");
        // let channel = p.channel;
        var [appointmentId] = p.channel.split("-");
        let appointmentStatus = this.state.appointmentStatus;

        if (p.action === "join" && uuid !== this.props.user.id) {
          appointmentStatus[appointmentId] = "active";
        } else {
          if (uuid !== this.props.user.id) {
            delete appointmentStatus[appointmentId];
          }
        }
        this.setState({ appointmentStatus: appointmentStatus });
      },
    });

    this.pubnub.hereNow(
      {
        channels: channels,
        includeUUIDs: true,
        includeState: true,
      },
      (status, response) => {
        if (!response?.channels) {
          return;
        }
        const channels = response.channels;
        const channelsIds = Object.keys(channels);
        let appointmentStatus = this.state.appointmentStatus;
        channelsIds.forEach((channelID) => {
          let channel = channels[channelID];
          var [appointmentId] = channelID.split("-");
          var appointment = allAppointments.find((a) => {
            return a.id == appointmentId;
          });
          const occupantsIDs = channel.occupants.map((oc) => {
            let [uuid] = oc.uuid.split(":");
            return uuid;
          });
          if (occupantsIDs.includes(appointment.patient.id)) {
            appointmentStatus[appointmentId] = "active";
          }
        });
        this.setState({ appointmentStatus: appointmentStatus });
      }
    );
  }

  updateAppointments() {
    this.setState({ status: "LOADING" });
    const role = "doctor";
    Appointments.getDetailsAppointments(this.props.user.token, role).then(
      (response) => {
        this.setState({
          upcomingAppointments: response.upcoming,
          pastAppointments: response.past,
          emergencyAppointments: response.emergency,
          status: "COMPLETED",
        });
        this.subscribePubNubForMessages(response.upcoming, response.past);
      }
    );
  }

  initPubNub() {
    if (this.pubnub) {
      this.unsubscribePubNub;
    }
    this.pubnub = new PubNub({
      publishKey: this.props.envs.PUB_NUB_PUBLISH_KEY,
      subscribeKey: this.props.envs.PUB_NUB_SUBSCRIBE_KEY,
      ssl: true,
      presenceTimeout: 120,
      heartbeatInterval: 30,
      uuid: `${this.props.user.id}:${Docto.version}:${User.getRoleName(
        this.props.user
      )}`,
      error: function (error) {
        console.error("PUB NUB Error:", error);
      },
    });
  }

  componentDidMount() {
    // this.initPubNub();
    this.updateAppointments();
  }

  showUpsertAppointmentModal() {
    this.props.showUpsertAppointmentModal({
      show: true,
      appointment: null,
      onComplete: () => this.updateAppointments(),
    });
  }

  render() {
    return (
      <div className="meeting-container">
        <div className="admin">
          <div className="appointments-inner">
            <div className="appointments-dash">
              <div className="appointments-dash-header">
                <span>YOUR APPOINTMENTS</span>
                <button
                  onClick={this.showUpsertAppointmentModal}
                  className="add-btn"
                >
                  Add New
                </button>
              </div>
            </div>

            <AppointmentSection
              title="Scheduled Appointments"
              status={this.state.status}
              appointments={this.state.upcomingAppointments}
              appointmentsPatientWaiting={this.state.appointmentStatus}
              user={this.props.user}
              showUpsertAppointmentModal={this.showUpsertAppointmentModal}
              type={"upcoming"}
              updateAppointments={this.updateAppointments}
            />
            <AppointmentSection
              title="Past Appointments"
              status={this.state.status}
              appointments={this.state.pastAppointments}
              appointmentsPatientWaiting={this.state.appointmentStatus}
              user={this.props.user}
              type={"past"}
            />
            {this.state.emergencyAppointments.length > 0 ? (
              <AppointmentSection
                title="Emergency Chats"
                status={this.state.status}
                appointments={this.state.emergencyAppointments}
                appointmentsPatientWaiting={this.state.appointmentStatus}
                user={this.props.user}
                type={"emergency"}
                updateAppointments={this.updateAppointments}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showUpsertAppointmentModal: ({ show, appointment, onComplete }) =>
      dispatch({
        type: SHOW_UPSERT_APPOINTMENT_MODAL,
        params: {
          show,
          appointment,
          onComplete,
        },
      }),
  };
};

const mapStateToProps = ({ session }) => ({
  user: session.user,
  envs: session.env,
});
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsPage);
