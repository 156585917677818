import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { history } from "@modules";
import { User, Specialties, Toast } from "@helpers";
import EditableElement from "@src/components/editable_element";
import EditableList from "@src/components/editable_element/editable_list";
import ReferralUpload from "@src/components/upload_referral";
import CircleLoader from "@src/components/upload_referral/circle_loader";
import TagForm from "@src/components/tag_form";
import { SHOW_SPECIALTY_BOT } from "@modules/gui";

const defaultValues = {
  seo: {
    meta_description: "Australia's online hospital",
  },
  seoTags: [
    {
      name: "keywords",
      content: "Doctor, remote doctor, virtual, australia",
    },
    {
      name: "og:title",
      content: "Docto Online Hospital",
    },
  ],
  sectionOne: {
    title: "Online Skin Clinic",
    subtitle: "Things right here about skin dermatology",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    specialty: "Dermatology",
    callToActionDescription:
      "Consult our online dermatologist from your computer or phone",
  },
  sectionTwo: {
    title: "Australia's most convenient dermatology consult",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    features:
      "Lorem ipsum dolor sit amet | Lorem ipsum dolor sit amet | Lorem ipsum dolor sit amet | Lorem ipsum dolor sit amet",
  },
  sectionThree: {
    title: "What does the dermatology consultation cost?",
    description:
      "Use the cost calculator bellow to determine your total out of pocket expence for consultating our online dermatologist.",
  },
  sectionFour: {
    title: "Choose Convenience",
    subtitle: "THINGS HERE ABOUT THE CONVENIENCE",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    specialty: "Dermatology",
    callToAction: "Video consult our leading dermatologists",
  },
  images: {
    hero_bg:
      "https://docto-files.s3-ap-southeast-2.amazonaws.com/dermatology-hero.jpg",
    section_phone:
      "https://docto-files.s3-ap-southeast-2.amazonaws.com/specialty-phone.jpg",
    hero_mid:
      "https://docto-files.s3-ap-southeast-2.amazonaws.com/specialty-phone-hero.jpg",
  },
};
class SpecialtyLanding extends React.Component {
  constructor() {
    super();
    this.state = {
      specialty: "",
      isEditing: false,
      original: {},
      cmsValues: {},
      loading: true,
      inAdmin: false,
      slug: "",
      specialtyId: "",
      showSEOInput: false,
      uploadStatus: "IDLE",
      showSlug: false,
    };
    this.setPublicUrl = this.setPublicUrl.bind(this);
    this.saveCms = this.saveCms.bind(this);
    this.saveSEO = this.saveSEO.bind(this);
    this.changeBg = this.changeBg.bind(this);
    this.bookNow = this.bookNow.bind(this);
    this.fetchSpecialty = this.fetchSpecialty.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }

  componentDidMount() {
    const { match, computedMatch } = this.props;
    if (match.path.indexOf("/admin") === -1) {
      this.fetchSpecialty(match.path, "slug", computedMatch.params.slug);
    } else {
      this.fetchSpecialty(match.path, "id", match.params.id);
    }
  }

  async fetchSpecialty(path, by, fetcher) {
    try {
      const response = await Specialties.fetchSpecialtyBy(by, fetcher);
      const inAdmin = path.indexOf("/admin/") !== -1;
      const fromServer = response.cms || {};
      const mergedVal = { ...defaultValues, ...fromServer };
      const isThisUserAdmin = this.props.user && User.isAdmin(this.props.user);
      if (!inAdmin && !response.publicUrl) {
        return history.replace("/not-found");
      }
      return this.setState({
        specialty: response.name,
        slug: response.slug,
        original: response,
        cmsValues: mergedVal,
        loading: false,
        inAdmin: inAdmin,
        specialtyId: response.id,
        isUserAdmin: isThisUserAdmin,
      });
    } catch (error) {
      history.replace("/not-found");
    }
  }
  triggerBgClick(event) {
    const fileInput = document.getElementById("hero-bg-input");
    fileInput.click();
  }
  revertBgImage() {
    const { cmsValues, oldHeroImg } = this.state;
    cmsValues.images.hero_bg = oldHeroImg;
    this.setState(cmsValues);
  }

  changeBg(event) {
    const {
      target: { files, name },
    } = event;
    const { cmsValues, specialtyId } = this.state;

    if (files && files[0]) {
      const currentImg = cmsValues.images[name];
      cmsValues.images[name] = URL.createObjectURL(files[0]);

      this.setState({
        cmsValues,
        oldHeroImg: currentImg,
        uploadStatus: "PROGRESS",
      });

      let formData = new FormData();
      formData.append("file", files[0]);
      try {
        Specialties.uploadHero(
          formData,
          this.props.user.token,
          specialtyId
        ).then((response) => {
          const imageUrl = `https://specialties-hero.s3-ap-southeast-2.amazonaws.com/${response[0].key}`;
          cmsValues.images[name] = imageUrl;
          this.setState({ cmsValues, uploadStatus: "DONE" });
        });
      } catch (error) {
        let toastInfo = {
          message: JSON.parse(error.response).error.message,
          kind: "error",
        };
        Toast.displayToast({ ...toastInfo });
      }
    }
  }
  changeValue(id, value) {
    const { cmsValues } = this.state;

    const index = (obj, i) => {
      return obj[i];
    };
    _.set(cmsValues, id, value);

    this.setState({ cmsValues });
  }
  changeInputValue(event) {
    const { cmsValues } = this.state;
    const { name, value } = event.target;
    _.set(cmsValues, name, value);
    this.setState({ cmsValues });
  }
  handleSlugChange(event) {
    return this.setState({
      slug: _.kebabCase(event.target.value),
    });
  }
  saveSlug() {
    const { specialtyId } = this.state;
    try {
      Specialties.setSpecialtySlug(
        specialtyId,
        this.props.user.token,
        this.state.slug
      ).then((response) => {
        this.setState({ original: response, showSlug: false });
        let toastInfo = {
          message: "Slug has been saved",
          kind: "success",
        };
        Toast.displayToast({ ...toastInfo });
      });
    } catch (error) {
      let toastInfo = {
        message: JSON.parse(error.response).error.message,
        kind: "error",
      };
      Toast.displayToast({ ...toastInfo });
    }
  }
  cancelSEO() {
    const { original, cmsValues } = this.state;
    const defaultCMS = original.cms
      ? original.cms.seoTags
      : defaultValues.seoTags;
    cmsValues.seoTags = defaultCMS;
    this.setState({ cmsValues, showSEOInput: false });
  }
  saveSEO() {
    const { specialtyId, cmsValues } = this.state;
    try {
      Specialties.updateSpecialtyCMS(
        specialtyId,
        this.props.user.token,
        cmsValues
      ).then((response) => {
        this.setState({
          original: response,
          isEditing: false,
          showSEOInput: false,
        });

        return Toast.displayToast({
          message: "SEO has been saved",
          kind: "success",
        });
      });
    } catch (error) {
      let toastInfo = {
        message: JSON.parse(error.response).error.message,
        kind: "error",
      };
      Toast.displayToast({ ...toastInfo });
    }
  }
  saveSeoTags(values) {
    const { cmsValues } = this.state;
    cmsValues.seoTags = values;
    this.setState({ cmsValues });
  }
  saveCms() {
    const { specialtyId, cmsValues } = this.state;
    try {
      Specialties.updateSpecialtyCMS(
        specialtyId,
        this.props.user.token,
        cmsValues
      ).then((response) => {
        this.setState({ original: response, isEditing: false });
      });
    } catch (error) {
      let toastInfo = {
        message: JSON.parse(error.response).error.message,
        kind: "error",
      };
      Toast.displayToast({ ...toastInfo });
    }
  }
  setPublicUrl(isPublicUrl) {
    const { specialtyId } = this.state;
    try {
      Specialties.setSpecialtyPublic(
        specialtyId,
        this.props.user.token,
        isPublicUrl
      ).then((response) => {
        this.setState({ original: response });
      });
    } catch (error) {
      let toastInfo = {
        message: JSON.parse(error.response).error.message,
        kind: "error",
      };
      Toast.displayToast({ ...toastInfo });
    }
  }
  showPricingModal() {
    this.props.showNewDoctoBot(true, this.state.specialtyId, {
      init: "check pricing",
    });
  }
  showSlugEdit() {
    const { showSlug } = this.state;
    this.setState({ showSlug: !showSlug });
  }
  bookNow() {
    this.props.showNewDoctoBot(true, this.state.specialtyId);
  }
  render() {
    const {
      isEditing,
      cmsValues,
      loading,
      inAdmin,
      original,
      showSEOInput,
      slug,
      showSlug,
      uploadStatus,
      specialty,
    } = this.state;
    if (loading) {
      return <div>loading</div>;
    }
    const origin = window.location.origin;

    return (
      <div
        className={
          isEditing ? "specialty-landing  edit-mode" : "specialty-landing"
        }
      >
        <Helmet>
          <link rel="canonical" href={`${origin}/${slug}`} />
          <title> {`${specialty} online`}</title>
          <meta name="description" content={cmsValues.seo.meta_description} />
          {cmsValues.seo &&
            cmsValues.seoTags.map((tag, index) => {
              return <meta key={index} name={tag.name} content={tag.content} />;
            })}
        </Helmet>

        {inAdmin && (
          <div className="edit-holder">
            <div className="actions">
              <div className="left-header">
                <div className="title">
                  <a href="/admin/specialties" className="back-button">
                    <i className="icon-arrow-left" />
                  </a>
                  <h2 className="page-title">{original.name}</h2>
                </div>
                <div className="slug-url">
                  {slug !== "" && (
                    <div>
                      <strong>Url:</strong>
                      <span className="txt-blue"> {origin}/</span>
                      <span className="txt-red">{slug}</span>
                    </div>
                  )}{" "}
                  {slug !== "" && (
                    <button
                      className="edit-preview"
                      onClick={this.showSlugEdit.bind(this)}
                    >
                      {showSlug ? "Cancel" : "Change"}
                    </button>
                  )}
                </div>
              </div>

              <div className="action-holder">
                {isEditing && (
                  <button
                    onClick={(e) => this.setState({ isEditing: false })}
                    className="stop-edit"
                  >
                    Cancel
                  </button>
                )}
                {isEditing && (
                  <button onClick={this.saveCms} className="save">
                    Save
                  </button>
                )}

                {!isEditing && (
                  <button
                    onClick={(e) => this.setState({ isEditing: true })}
                    className="edit"
                  >
                    Edit
                  </button>
                )}
                {!isEditing && !original.publicUrl && (
                  <button
                    onClick={(e) => this.setPublicUrl(true)}
                    className="edit-publish"
                  >
                    Publish
                  </button>
                )}

                {!isEditing && original.publicUrl && (
                  <button
                    className="edit-published"
                    onClick={(e) => this.setPublicUrl(false)}
                  >
                    Published <i className="icon-check" />
                  </button>
                )}
                {!isEditing && original.slug && original.publicUrl && (
                  <a
                    className="edit-preview"
                    target="_blank"
                    href={`/${original.slug}`}
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                )}
              </div>
            </div>
            {(!original.slug || showSlug) && (
              <div className="actions change-slug-div">
                <label htmlFor="slug">
                  Address: <span>{origin}/</span>
                </label>

                <input
                  onChange={this.handleSlugChange.bind(this)}
                  className="slug-input"
                  type="text"
                  id="slug"
                  value={slug}
                />
                <button className="edit" onClick={this.saveSlug.bind(this)}>
                  Save
                </button>
              </div>
            )}
            <div className="seo-information">
              <span className="seo-title">
                SEO Information:
                <button
                  onClick={(e) =>
                    this.setState({ showSEOInput: !showSEOInput })
                  }
                  className="open-seo"
                >
                  <i className="icon-chevron-down" />
                </button>
              </span>
              <div className={showSEOInput ? "seo-open" : "hidden"}>
                <div className="input-holder">
                  <div className="input-group">
                    <label className="label">Description: </label>
                    <input
                      value={cmsValues.seo.meta_description}
                      onChange={this.changeInputValue}
                      type="text"
                      name="seo.meta_description"
                    />
                  </div>
                </div>
                <div className="input-holder">
                  <div className="input-group tags">
                    <label className="label">Headers: </label>
                    <TagForm
                      default={{ value: "", content: "" }}
                      values={cmsValues.seoTags}
                      saveValues={this.saveSeoTags.bind(this)}
                    />
                  </div>
                </div>
                <div className="input-holder right">
                  <button
                    className="cancel"
                    onClick={this.cancelSEO.bind(this)}
                  >
                    Close
                  </button>
                  <button className="save" onClick={this.saveSEO}>
                    Save SEO Information
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className="sp-hero sp-hero-top"
          style={{
            backgroundImage: `url(
							${cmsValues.images.hero_bg}
						)`,
          }}
        >
          {isEditing && (
            <div className="change-picture">
              {uploadStatus !== "PROGRESS" && (
                <div
                  onClick={this.triggerBgClick.bind(this)}
                  className="editable-image-input"
                >
                  <input
                    name="hero_bg"
                    id="hero-bg-input"
                    className="hidden"
                    onChange={this.changeBg}
                    type="file"
                  />
                </div>
              )}
              {uploadStatus === "PROGRESS" && (
                <CircleLoader size={"3em"} done={uploadStatus === "DONE"} />
              )}
              {uploadStatus === "DONE" && (
                <button
                  className="revert-image-input"
                  onClick={this.revertBgImage.bind(this)}
                >
                  X
                </button>
              )}
            </div>
          )}
          <div className="container">
            <div className="row row-flex">
              <div className="col-sm-12 col-md-7 col-lg-7">
                <EditableElement
                  id="sectionOne.title"
                  html={`<h1  class="editable-block">${cmsValues.sectionOne.title}</h1>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
                <EditableElement
                  id="sectionOne.subtitle"
                  html={`<h4  class="editable-block">${cmsValues.sectionOne.subtitle}</h4>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
                <EditableElement
                  id="sectionOne.description"
                  html={`<p  class="editable-block">${cmsValues.sectionOne.description}</p>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
              </div>

              <div className="col-sm-12 col-md-5 col-lg-5">
                <div className="book-now-block">
                  <div className="book-now-body">
                    <EditableElement
                      id="sectionOne.specialty"
                      html={`<h3  class="editable-block">${cmsValues.sectionOne.specialty}</h3>`}
                      onChange={this.changeValue.bind(this)}
                      enabled={isEditing}
                    />
                    <EditableElement
                      id="sectionOne.callToActionDescription"
                      html={`<p  class="editable-block">${cmsValues.sectionOne.callToActionDescription}</p>`}
                      onChange={this.changeValue.bind(this)}
                      enabled={isEditing}
                    />
                  </div>
                  <div className="book-now-button">
                    <button onClick={this.bookNow}>Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sp-section-white">
          <div className="container">
            <div className="row row-flex align-items-flex-end">
              <div className="col-sm-12 col-md-7 col-lg-7 sp-section-white-info">
                <EditableElement
                  id="sectionTwo.title"
                  html={`<h2  class="editable-block">${cmsValues.sectionTwo.title}</h2>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
                <EditableElement
                  id="sectionTwo.description"
                  html={`<p  class="editable-block">${cmsValues.sectionTwo.description}</p>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
                <EditableList
                  childclassName={"section-two-features"}
                  values={cmsValues.sectionTwo.features}
                  id="sectionTwo.features"
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
              </div>

              <div className="col-sm-12 col-md-5 col-lg-5 mock-bottom">
                <img src={`${cmsValues.images.section_phone}`} />
              </div>
            </div>
          </div>
        </div>
        <div className="sp-section-blue">
          <div className="container">
            <div className="row row-flex">
              <div className="col-sm-12">
                <EditableElement
                  id="sectionThree.title"
                  html={`<h2  class="editable-block">${cmsValues.sectionThree.title}</h2>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
                <EditableElement
                  id="sectionThree.description"
                  html={`<p  class="editable-block">${cmsValues.sectionThree.description}</p>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
                <a
                  className="btn-round-white"
                  onClick={this.showPricingModal.bind(this)}
                >
                  <span>Price</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="sp-section-files">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <ReferralUpload specialtyId={this.state.original.name} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="sp-hero sp-hero-mid"
          style={{
            backgroundImage: `url(
							${cmsValues.images.hero_mid}
						)`,
          }}
        >
          <div className="container">
            <div className="row row-flex">
              <div className="col-sm-12 col-md-7 col-lg-7">
                <EditableElement
                  id="sectionFour.title"
                  html={`<h2  class="editable-block">${cmsValues.sectionFour.title}</h2>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
                <EditableElement
                  id="sectionFour.subtitle"
                  html={`<h4  class="editable-block">${cmsValues.sectionFour.subtitle}</h4>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
                <EditableElement
                  id="sectionFour.description"
                  html={`<p  class="editable-block">${cmsValues.sectionFour.description}</p>`}
                  onChange={this.changeValue.bind(this)}
                  enabled={isEditing}
                />
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5">
                <div className="book-now-block">
                  <div className="book-now-body">
                    <EditableElement
                      id="sectionFour.specialty"
                      html={`<h3  class="editable-block">${cmsValues.sectionFour.specialty}</h3>`}
                      onChange={this.changeValue.bind(this)}
                      enabled={isEditing}
                    />
                    <EditableElement
                      id="sectionFour.callToAction"
                      html={`<p  class="editable-block">${cmsValues.sectionFour.callToAction}</p>`}
                      onChange={this.changeValue.bind(this)}
                      enabled={isEditing}
                    />
                  </div>
                  <div className="book-now-button">
                    <button onClick={this.bookNow}>Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showNewDoctoBot: (show, specialtyId, params) =>
      dispatch({
        type: SHOW_SPECIALTY_BOT,
        params: { specialtyId, show, params },
      }),
  };
};
const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(SpecialtyLanding);
