import api from "@api";
import { Routes } from "@constants/";

const Session = class Session {
  static async sendBotActionEmail(data) {
    return await api.post(Routes.sendBotActionEmail, data);
  }
  static async sendSignUpConfirmation(email, data = {}) {
    return await api.post(
      `${Routes.email}/sendConfirmationSignUp/${email}`,
      data
    );
  }
  static async reactivateAccount(email) {
    return await api.post(`${Routes.email}/reactivateAccount/${email}`);
  }
  static async contactUs(contactInfo) {
    return await api.post(`${Routes.email}/sendFullContact`, contactInfo);
  }
};

export default Session;
