import { Routes } from "@constants";
import api from "@api";

class PubNubInterface {
  constructor(pubnub) {
    this.pubnub = pubnub;
  }

  // sendFile(payload) {
  //   let formData = new FormData();
  //   const file = payload.file;
  //   formData.append("file", file);
  //   api
  //     .multiPart(
  //       Routes.uploadAppointmentReferralDocument(
  //         payload.channel,
  //         payload.token
  //       ),
  //       formData
  //     )
  //     .then((response) => {
  //       const filesUploaded = response.data;
  //       const { fileName } = filesUploaded[0];

  //       let meta = {
  //         fileUrl: fileName,
  //         fileName: fileName,
  //         type: "file",
  //         bucket: "docto-referral-documents",
  //       };
  //       payload.meta = meta;
  //       this.sendMessage(payload);
  //     })
  //     .catch((err) => {
  //       console.log("error on upload file", err);
  //     });
  // }

  sendMessage(message) {
    console.log("sendMessage", { message });
    this.pubnub.publish(
      {
        channel: message.data.channel,
        message: message,
        meta: message.meta,
      },
      () => {
        this.save_message(message);
      }
    );
  }
  sendMessageNoSave(message) {
    this.pubnub.publish({
      channel: message.data.channel,
      message: message,
      meta: message?.meta,
    });
  }

  sendMessageNotification(message) {
    this.pubnub.publish({
      channel: message.data.channel,
      message: message,
      meta: message?.meta,
    });
  }

  save_message(message) {
    console.log("save_message", message);
    if (message.data.senderId && message.data.sender !== "Docto") {
      let payload = {
        unixTime: message.data.timestamp,
        userId: message.data.senderId,
        message: message.data.body,
        meta: message.meta,
      };
      api.post(Routes.createChat(message.data.channel), payload);
    }
  }
}

export default PubNubInterface;
