import React from "react";
import { Helmet } from "react-helmet";
import { FormHelpers, Toast, Email } from "@helpers/";

class ContactField extends React.Component {
  render() {
    let { index, label, value, setValue, error } = this.props;
    let inputName = `input_${index}`;
    let inputId = `input_3_${index}`;

    label = error ? error : label;
    return (
      <li
        id="field_3_1"
        className="gfield gfield_contains_required field_sublabel_below field_description_below"
      >
        <label className="gfield_label" htmlFor={inputId}>
          {label}
          <span className="gfield_required">*</span>
        </label>
        <div className="ginput_container ginput_container_text">
          <input
            name={inputName}
            id={inputId}
            type="text"
            value={value}
            className="medium"
            tabIndex={index}
            placeholder={label}
            onChange={(event) => setValue(event)}
          />
        </div>
      </li>
    );
  }
}

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      nameCompany: "",
      email: "",
      phone: "",
      message: "",
      errorName: "",
      errorEmail: "",
      errorPhone: "",
      errorMessage: "",
    };
  }

  setNameCompany(event) {
    this.setState({ nameCompany: event.target.value });
  }

  setEmail(event) {
    this.setState({ email: event.target.value });
  }

  setPhone(event) {
    this.setState({ phone: event.target.value });
  }

  setMessage(event) {
    this.setState({ message: event.target.value });
  }

  validateForm() {
    let errorName = this.state.nameCompany ? "" : "Please enter your name";
    let errorEmail = FormHelpers.validateEmail(this.state.email)
      ? ""
      : "Please enter a valid email";
    let errorPhone = this.state.phone ? "" : "Please enter your phone number";
    let errorMessage = this.state.message ? "" : "Please enter a message";

    this.setState({ errorName: errorName });
    this.setState({ errorEmail: errorEmail });
    this.setState({ errorPhone: errorPhone });
    this.setState({ errorMessage: errorMessage });

    if (errorEmail) {
      this.setState({ email: "" });
    }

    return !errorName && !errorEmail && !errorPhone && !errorMessage;
  }

  trySubmit(e) {
    if (e.key === "Enter") {
      this.submit();
    }
  }

  async submit(event) {
    event.preventDefault();
    let valid = this.validateForm();
    if (!valid) {
      let toastBody = { message: "Fields can not be empty", kind: "error" };
      Toast.displayToast(toastBody);
    }
    if (valid) {
      let contactInfo = {
        name: this.state.nameCompany,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
      };
      // todo use api here
      //   flux.actions.gui.showLoadingModal(true);
      try {
        await Email.contactUs(contactInfo);
        let toastBody = {
          message:
            "Thank you for your enquiry. Our team will contact you shortly",
          kind: "success",
        };
        Toast.displayToast(toastBody);
        this.setState({
          nameCompany: "",
          email: "",
          phone: "",
          message: "",
          requestDemo: false,
        });
      } catch (error) {
        //   flux.actions.gui.showLoadingModal(false);
        var res = JSON.parse(error.response);
        if (res.error.code === "ValidationError") {
          let toastBody = { message: "Invalid email", kind: "error" };
          Toast.displayToast(toastBody);
          this.setState({ errorEmail: "Invalid email", email: "" });
        } else {
          let toastBody = {
            message: "Unexpected error. Please try again later",
            kind: "error",
          };
          Toast.displayToast(toastBody);
        }
      }
    }
  }

  render() {
    let messageLabel = this.state.errorMessage
      ? this.state.errorMessage
      : "Message";
    return (
      <div>
        <Helmet>
          <title>Docto Online Hospital - Contact</title>
          <link rel="canonical" href="https://www.docto.com.au/contact" />
          <meta
            name="description"
            content="Australia’s first online hospital. A Telehealth service providing Medical specialist online consultations 24 hours a day."
          />
        </Helmet>
        <div className="hero  hero--middle  hero--cover  flex-embed  flex-embed--3by1">
          <div className="flex-embed__content">
            <div className="wrap">
              <div className="hero__body">
                <h1 className="hero__title" itemProp="headline">
                  Get in touch with us
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="site-inner">
          <div className="wrap">
            <div className="layout">
              <main
                className="content layout__item desk-eight-twelfths push--desk-two-twelfths"
                itemProp="mainContentOfPage"
                role="main"
              >
                <article
                  className="post-1023 page type-page status-publish entry"
                  itemScope="itemScope"
                  itemType="http://schema.org/CreativeWork"
                >
                  <header className="entry-header">
                    <h1 className="entry-title" itemProp="headline">
                      Contact us
                    </h1>
                  </header>

                  <div className="entry-content" itemProp="text">
                    <div className="center-text">
                      <p>
                        General enquiries{" "}
                        <a href="tel:1300336286">13003DOCTO</a> (1300336286)
                      </p>
                      <p>
                        For instant medical advice{" "}
                        <a href="/consult-dr-now">CLICK HERE</a>
                      </p>
                    </div>
                    <section>
                      <div className="gform_wrapper">
                        <form>
                          <div className="gform_body">
                            <ul
                              id="gform_fields_3"
                              className="gform_fields top_label form_sublabel_below description_below"
                            >
                              <ContactField
                                index="1"
                                label="Name"
                                value={this.state.nameCompany}
                                setValue={(event) => this.setNameCompany(event)}
                                onKeyDown={(event) => this.trySubmit(event)}
                                error={this.state.errorName}
                              />
                              <ContactField
                                index="2"
                                label="Email"
                                value={this.state.email}
                                setValue={(event) => this.setEmail(event)}
                                onKeyDown={(event) => this.trySubmit(event)}
                                error={this.state.errorEmail}
                              />
                              <ContactField
                                index="3"
                                label="Phone"
                                value={this.state.phone}
                                setValue={(event) => this.setPhone(event)}
                                onKeyDown={(event) => this.trySubmit(event)}
                                error={this.state.errorPhone}
                              />

                              <li
                                id="field_3_4"
                                className="gfield gfield_contains_required field_sublabel_below field_description_below"
                              >
                                <label
                                  className="gfield_label"
                                  htmlFor="input_3_4"
                                >
                                  {messageLabel}
                                  <span className="gfield_required">*</span>
                                </label>
                                <div className="ginput_container ginput_container_text">
                                  <textarea
                                    name="input_4"
                                    id="input_3_4"
                                    type="text"
                                    value={this.state.message}
                                    className="medium"
                                    tabIndex="4"
                                    placeholder={messageLabel}
                                    ref="textareaField"
                                    onChange={(event) => this.setMessage(event)}
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                          <br />

                          <div className="gform_footer top_label">
                            <button
                              className="btn  btn--full  btn--positive"
                              id="gform_submit_button_3"
                              onClick={(event) => this.submit(event)}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </section>
                  </div>
                </article>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
