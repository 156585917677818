import React, { useEffect, useRef, useState } from "react";
import { Utils, Toast } from "@helpers/";
import { Appointments } from "@helpers/index";
import { Routes } from "@constants/";
import SpinImage from "@assets/images/spin.gif";
import { useDispatch } from "react-redux";
import { showDocumentViewer } from "@modules/gui";

const ReferralFiles = (props) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("LOADING");
  const [referralFiles, setReferralFiles] = useState([]);
  const [showReferralFiles, setShowReferralFiles] = useState(false);
  const { appointment, user } = props;
  const appointmentId = appointment?.id;
  const uploadFile = useRef();

  const parentHasClass = (element, classname) => {
    if (
      element.className &&
      element.className.split(" ").indexOf(classname) >= 0
    )
      return true;
    return element.parentNode && parentHasClass(element.parentNode, classname);
  };

  const handleClick = (e) => {
    const element = e.target;
    if (element.className !== "icon-appt_files") {
      if (!parentHasClass(element, "ref-modal")) {
        setShowReferralFiles(false);
      }
    }
  };

  const unmount = () => {
    document.removeEventListener("mousedown", handleClick, false);
  };
  useEffect(() => {
    listReferralDocuments();
    document.addEventListener("mousedown", handleClick, false);
    return unmount;
  }, []);

  const listReferralDocuments = async () => {
    setStatus("LOADING");
    try {
      const refFiles = await Appointments.listPatientReferralDocuments(
        appointmentId,
        user.token
      );
      setReferralFiles(refFiles);
      setStatus("COMPLETED");
    } catch (error) {
      console.error(error);
      setStatus("COMPLETED");
    }
  };
  const removeReferralFile = async (apId, referralFile, index) => {
    let refFiles = referralFiles;
    refFiles[index].loading = true;
    setReferralFiles([...refFiles]);
    try {
      await Appointments.deleteReferralPatientDocument(
        apId,
        referralFile?.fileName,
        user.token
      );
      refFiles.splice(index, 1);
      setReferralFiles([...refFiles]);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFormData = async (formData) => {
    try {
      const filesUploaded = await Appointments.uploadAppointmentPatientReferralDocument(
        formData,
        appointmentId,
        user.token
      );
      let refFiles = referralFiles;
      filesUploaded.forEach((element) => {
        const index = refFiles.findIndex((rf) => {
          return rf.fileName === element.fileName;
        });
        if (index > -1) {
          refFiles[index] = element;
          return;
        }
        refFiles.push(element);
      });
      setReferralFiles([...refFiles]);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadReferralChange = (ev) => {
    let files = Array.from(ev.target.files);

    if (Utils.exceedFileSize(files)) {
      Toast.displayToast({
        message: "files exceeds the 25MB file-size",
        kind: "error",
      });
      return;
    }
    const formData = Utils.getFormData(files);
    const filesToUpload = files.map((element) => {
      return {
        fileName: element.name,
        size: element.size,
        loading: true,
      };
    });
    setReferralFiles((oldRefFiles) => [...oldRefFiles, ...filesToUpload]);
    uploadFormData(formData);
  };

  const uploadReferral = () => {
    uploadFile.current.click();
  };

  const showFile = (url) => {
    dispatch(showDocumentViewer({ url, token: props.user.token }));
  };

  return (
    <div className="chat-action-list-ittem ">
      <div
        className="cpl-trigger"
        onClick={() => setShowReferralFiles((srf) => !srf)}
      >
        <i className="icon-appt_files"></i>
        <div className="pdf-info">
          <span>
            {status === "LOADING" ? "" : `${referralFiles.length} Files`}
          </span>
        </div>
      </div>
      <div
        id="refmdal"
        className={
          showReferralFiles
            ? "ref-modal cpl-dropdown"
            : " ref-modal cpl-dropdown hide"
        }
      >
        <ul>
          {referralFiles.map((referralFile, index) => {
            return (
              <li key={`${index}-referralfile`}>
                <div className="cpl-pdf-item">
                  <em className="pdf">
                    {referralFile.fileName.split(".").pop()}
                  </em>
                  <div
                    className="pdf-info"
                    onClick={() =>
                      showFile(
                        Routes.downloadReferralPatient(
                          appointmentId,
                          referralFile.fileName
                        )
                      )
                    }
                  >
                    <b>{referralFile.fileName}</b>
                    <span></span>
                  </div>
                  {referralFile.loading ? (
                    <img className="cpl-loader" src={SpinImage} />
                  ) : (
                    <a
                      href="#"
                      className="cpl-delete"
                      onClick={() =>
                        removeReferralFile(appointmentId, referralFile, index)
                      }
                    >
                      DELETE
                    </a>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
        <input
          multiple={true}
          id="upload-file"
          className="hide"
          ref={uploadFile}
          type="file"
          onChange={(e) => uploadReferralChange(e)}
        />
        <a className="cpl-btn" onClick={() => uploadReferral()}>
          Upload
        </a>
      </div>
    </div>
  );
};

export default ReferralFiles;
