import React, { useState } from "react";
import EmergencyConsultAccess from "@components/emergency_consult_access";
import Helmet from "react-helmet";
import { Appointments, Toast } from "@helpers/";
import { useDispatch, useSelector } from "react-redux";
import { showLoadingModal } from "@modules/gui";
import Success from "@components/emergency_consult_access/success";
import { history } from "@modules/";
import { useLocation } from "react-router-dom";

const Assistravel = (props) => {
  const { company } = props;
  const location = useLocation();

  const user = useSelector((state) => state.session.user);
  console.log("assistravel", { user, props });
  const [status, setStatus] = useState("INIT");
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();

  const onPatientAccepted = (patient) => {
    companyEmergencyRequestForPatient({ ...patient, companyId: company.id });
  };
  const onContinue = () => {
    history.replace(location.pathname);
    setStatus("INIT");
  };
  const companyEmergencyRequestForPatient = async (patientData) => {
    try {
      dispatch(showLoadingModal(true));
      await Appointments.companyEmergencyRequestForPatient({
        patient: patientData,
        companyId: company.id,
      });
      setSuccessMessage(`Link de emergencia enviado a ${patientData.email}`);
      setStatus("SUCCESS");
      dispatch(showLoadingModal(false));
    } catch (e) {
      dispatch(showLoadingModal(false));
      console.error(e);
      const paymentError = {
        message: "Unexpected error occur. Please try again",
        kind: "error",
      };
      Toast.displayToast(paymentError);
    }
  };

  return (
    <div className="register-scroll">
      <Helmet>
        <title>Docto Online Hospital - Sign Up Membership</title>
        <meta
          name="description"
          content="Docto Online Hospital, Australia's first - Sign Up Membership"
        />
      </Helmet>
      {/* <div class="reg-logo" style="padding: 30px 0 0;"><img src="/api/v1/containers/docto-company-logos/download/production%2F1504651481648_csgi.png" style="
    width: 100%;
    max-width: 160px;
"></div> */}
      <div className="register-header company-signup">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 company-wrap jagw_form">
              <div className="sm-h0 h100"></div>

              {status === "SUCCESS" ? (
                <Success
                  message={successMessage}
                  onContinue={onContinue}
                  title="Instant online consultation"
                  continueText="Continuar"
                />
              ) : (
                <EmergencyConsultAccess
                  {...{
                    contentTemplate: "assistravel",
                    user,
                    isNursingRoom: false,
                    renderStatus: null,
                    onPatientAccepted,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assistravel;
