export const INIT_USER = "session/INIT_USER";
export const INIT_GUEST_USER = "session/INIT_GUEST_USER";
import { User, Token, Session } from "@helpers/";
import { SHOW_LOGIN_MODAL } from "@modules/gui";
import { Toast } from "@helpers";
import { history } from "@modules/";

const initialState = {
  guestUser: {},
  user: {},
  env: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_USER:
      return {
        ...state,
        user: action.user,
        env: action.env,
      };
    case INIT_GUEST_USER:
      return {
        ...state,
        guestUser: action.user,
        env: action.env,
      };
    default:
      return state;
  }
};

export const initUserWithToken = (oldToken) => {
  return async (dispatch) => {
    try {
      const data = await User.fetchUserWithToken("me", oldToken);
      let { user, env, token } = data.result;
      user.token = token;
      Token.setToken(token);
      console.log("dispatch initUser");
      dispatch({
        type: INIT_USER,
        user,
        env,
      });
    } catch (error) {
      console.error(error);
      Toast.displayToast({ kind: "warning", message: "token expires" });
      Token.clearToken();
      dispatch({
        type: INIT_USER,
        user: {},
        env: {},
      });
    }
  };
};

export const mascaradeUserWithToken = (newToken) => {
  const token = Token.getToken();
  Token.setMaskToken(token);
  return async (dispatch) => {
    try {
      const data = await User.fetchUserWithToken("me", newToken);
      let { user, env, token } = data.result;
      user.token = token;
      Token.setToken(token);
      dispatch({
        type: INIT_USER,
        user,
        env,
      });
      history.replace("/");
    } catch (error) {
      console.error(error);
      Toast.displayToast({ kind: "warning", message: "token expires" });
      Token.clearToken();
      dispatch({
        type: INIT_USER,
        user: {},
        env: {},
      });
    }
  };
};
export const unmascaradeUser = () => {
  return async (dispatch) => {
    try {
      const masktoken = Token.getMaskToken();
      Token.clearMaskToken();
      const data = await User.fetchUserWithToken("me", masktoken);
      let { user, env, token } = data.result;
      user.token = token;
      Token.setToken(token);
      dispatch({
        type: INIT_USER,
        user,
        env,
      });
      history.replace("/");
    } catch (error) {
      console.error(error);
      Toast.displayToast({ kind: "warning", message: "token expires" });
      Token.clearToken();
      dispatch({
        type: INIT_USER,
        user: {},
        env: {},
      });
    }
  };
};
export const initGuestWithToken = (oldToken) => {
  return async (dispatch) => {
    try {
      const data = await User.fetchUserWithToken("me", oldToken);
      let { user, env, token } = data.result;
      user.token = token;
      Token.setGuestToken(token);
      dispatch({
        type: INIT_GUEST_USER,
        user,
        env,
      });
    } catch (error) {
      console.error(error);
      Toast.displayToast({ kind: "warning", message: "token expires" });
      Token.clearGuestToken();
      dispatch({
        type: INIT_GUEST_USER,
        user: {},
        env: {},
      });
    }
  };
};

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      const res = await Session.login(email, password);

      let { user, env, id } = res;
      user.token = id;
      Token.setToken(id);
      dispatch({
        type: INIT_USER,
        user,
        env,
      });
      dispatch({
        type: SHOW_LOGIN_MODAL,
        show: false,
      });
      return res;
    } catch (error) {
      console.error(error);
      const res = JSON.parse(error.response);
      if (res.error) {
        Toast.displayToast({ kind: "error", message: res.error.message });
        return;
      }
    }
  };
};

export const initUser = ({ env, user, token }) => {
  const logingUser = token ? { ...user, token } : user;
  Token.setToken(token);
  return (dispatch) => {
    dispatch({
      type: INIT_USER,
      env,
      user: logingUser,
    });
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await Session.logout();
      Token.clearToken();
      dispatch({
        type: INIT_USER,
        user: {},
        env: {},
      });
    } catch (error) {
      console.error(error);
      Token.clearToken();
      dispatch({
        type: INIT_USER,
        user: {},
        env: {},
      });
    }
  };
};
