import React, { Component, useEffect, useState } from "react";
import empty from "is-empty";
import { Toast } from "@helpers/";
import Services from "@helpers/services";

const SmsVerification = (props) => {
  const [error, setError] = useState(null);
  const [smsCode, setSmsCode] = useState("");
  const { patient, onBack, onEmailVerify } = props;
  useEffect(() => {
    sendSmsCode();
  }, []);

  const sendSmsCode = async () => {
    let { email, countryCode, phoneNumber } = patient;
    phoneNumber = phoneNumber
      .replace(/[^0-9]/g, "")
      .replace(/^0/, "")
      .substr(-9);
    try {
      await Services.sendSmsCode(email, `+${countryCode}${phoneNumber}`);
    } catch (error) {
      console.error(error);
      Toast.displayToast({
        kind: "error",
        message: `Invalid Phone Number. Please review it and try again `,
      });
      onBack();
    }
  };

  const verifySmsCode = async () => {
    try {
      const { email } = patient;
      const response = await Services.verifySmsCode(email, smsCode);
      const { valid } = response;
      if (!valid) {
        throw new Error("Invalid code. Please note, code is case sensitive");
      }
      onEmailVerify();
    } catch (error) {
      console.error(error);
      setError("Invalid code. Please note, code is case sensitive");
    }
  };

  const renderErrors = () => {
    if (!empty(error)) {
      return (
        <div className="error-alert">
          <ul>
            <li>{error}</li>
          </ul>
        </div>
      );
    }
  };

  return (
    <div className="jag_info_wrap">
      <div className="jag_prev">
        <a onClick={() => onBack()}>
          <i className="icon-chevron-left"></i>
          Back
        </a>
      </div>
      <h1 className="jag_title">Instant online consultation</h1>
      <span className="jag_sub_title">
        Enter the code you have just received by SMS
      </span>

      <div className="jag_body_wrap">
        {renderErrors()}
        <input
          type="text"
          className="jag-lined-box"
          value={smsCode}
          onChange={(ev) => setSmsCode(ev.target.value)}
          placeholder="SMS CODE"
        />

        <a className="jag-solid-btn" onClick={() => verifySmsCode()} href="#">
          Continue
        </a>
      </div>
      <div className="jag_footer_wrap">
        <span>I didnt receive my verification code?</span>
        <a href="#" onClick={() => sendSmsCode()}>
          Send Again
        </a>
      </div>
    </div>
  );
};

export default SmsVerification;
