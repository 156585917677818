import api from "@api";
import { Routes } from "@constants/";

const fetchSpecialtiesWithExtras = async (type, orderBy = "order") => {
  let filter = type
    ? `filter[order]=${orderBy}&filter[where][type]=${type}&filter[where][visibility]=public&filter[include][users]`
    : `filter[order]=${orderBy}&filter[where][visibility]=public&filter[include][users]`;
  return await api.get(Routes.fetchFilteredSpecialties(filter));
};
const fetchSpecialties = async () => {
  return await api.get(Routes.fetchSpecialties());
};
const fetchSpecialtiesAdmin = async (orderBy = "order") => {
  let query = `filter[order]=${orderBy}&filter[include][users]`;
  return await api.get(`${Routes.specialties}?${query}`);
};
const fetchSpecialtyById = async (id) => {
  return await api.get(Routes.fetchSpecialtyById(id));
};
const editSpecialty = async (data, token, specialty) => {
  return await api.put(Routes.specialtyWithToken(token, specialty), data);
};
const uploadHero = async (formData, token, specialtyId) => {
  return await api.post(Routes.uploadHeroImage(token, specialtyId), formData, {
    contentType: "multipart/form-data",
  });
};
const setSpecialtySlug = async (specialtyId, token, slug) => {
  return await api.post(Routes.setSpecialtySlug(specialtyId, token), {
    slug: slug,
  });
};
const setSpecialtyPublic = async (specialtyId, token, isPublic) => {
  return await api.post(Routes.setSpecialtyPublic(specialtyId, token), {
    publicUrl: isPublic,
  });
};
const updateSpecialtyCMS = (specialtyId, token, newValues) => {
  api.post(Routes.updateSpecialtyCMS(specialtyId, token), {
    cms: newValues,
  });
};
const createSpecialty = async (token, data) => {
  return await api.post(Routes.specialtiesWithToken(token), data);
};
const fetchSpecialtyBy = async (type, id) => {
  if (type === "slug") {
    return await api.get(Routes.fetchSpecialtyBySlug(id));
  }
  return fetchSpecialtyById(id);
};

export default {
  fetchSpecialtiesWithExtras,
  fetchSpecialties,
  fetchSpecialtiesAdmin,
  fetchSpecialtyById,
  createSpecialty,
  editSpecialty,
  fetchSpecialtyBy,
  setSpecialtySlug,
  updateSpecialtyCMS,
  uploadHero,
  setSpecialtyPublic,
};
