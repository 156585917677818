import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { User, Toast } from "@helpers";
import { Routes } from "@constants/";
import UnboundConfirmationModal from "@src/components/modals/unbound_confirmation_modal";
import {
  showAdvancePaymentModal,
  closeAdvancePaymentModal,
} from "@modules/gui";
import { Appointments } from "@helpers/index";

const ActionRequired = ({ user, appointment, onClose }) => {
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState({
    show: false,
    proceedText: "",
    confirmText: "",
    proceedFunction: null,
  });

  const proceedWithPayment = (card, date) => {
    dispatch(
      showAdvancePaymentModal({
        loading: true,
        unix: moment().unix(),
      })
    );
    try {
      Appointments.acceptAppointmentDate(
        appointment.id,
        user.token,
        card,
        date.toDate()
      ).then(() => {
        closePaymentModal();
        onClose(appointment.id);
        Toast.displayToast({
          kind: "success",
          message:
            "Video consultation booked. Please check your email for more information.",
        });
      });
    } catch (error) {
      {
        console.error(error);
        const res = JSON.parse(error.response);
        if (res.error.code === "INVALID_PAYMENT_METHOD") {
          dispatch(
            showAdvancePaymentModal({
              loading: false,
              unix: moment().unix(),
              navigation: "billingInfo",
            })
          );

          setTimeout(() => {
            Toast.displayToast({
              kind: "error",
              message: "Invalid payment method. Please try again",
            });
          }, 100);
          return;
        }
        if (res.error.code === "PAYMENT_DECLINE") {
          Toast.displayToast({
            kind: "error",
            message:
              "Payment decline. Please update your payment method and try again.",
          });
          setTimeout(() => {
            dispatch(
              showAdvancePaymentModal({
                loading: false,
                unix: moment().unix(),
                navigation: "billingInfo",
              })
            );
          }, 100);
          return;
        }
        Toast.displayToast({
          kind: "error",
          message: "An unexpected error occur. please try again",
        });
        setTimeout(() => {
          dispatch(
            showAdvancePaymentModal({
              show: false,
            })
          );
        }, 100);
      }
    }
  };
  const closePaymentModal = () => {
    dispatch(closeAdvancePaymentModal());
  };
  const requestNewDate = async () => {
    try {
      await Appointments.requestNewDate(appointment.id, user.token);
      onClose(appointment.id);
      Toast.displayToast({
        kind: "success",
        message: "New dates requested :)",
      });
    } catch (error) {
      console.error(error);
      Toast.displayToast({
        kind: "error",
        message: "An unexpected error occur. please try again",
      });
    }
  };
  const confirmNewDate = () => {
    setConfirm({
      show: true,
      confirmText: "Do you want to request new dates?",
      proceedText: "CONFIRM",
      proceedFunction: () => requestNewDate(),
    });
  };

  const acceptDate = (date) => {
    const { price } = appointment;
    const hasValidPaymentMethod = User.hasValidPayment(user);
    const priceNoCents = Number(price) / 100;
    dispatch(
      showAdvancePaymentModal({
        show: true,
        onClose: () => closePaymentModal(),
        navigation: hasValidPaymentMethod ? "confirmation" : null,
        price: priceNoCents,
        unix: moment().unix(),
        onConfirm: (card) => proceedWithPayment(card, date),
      })
    );
  };

  const close = () => {
    onClose(appointment.id);
  };

  const doctor = appointment.user;
  const preApprovedDates = Object.values(appointment.preApprovedDates);
  let divStyle = {
    backgroundImage: "url(" + Routes.profilePicUrl(doctor.profileImage) + ")",
  };
  return (
    <div className="simple-view-cta">
      <h1>
        Welcome
        <br />
        {user.firstName}!
      </h1>

      <div className="simple-view-panel">
        <a className="sv-close" onClick={() => close()}>
          <i className="icon-cross-circle"></i>
        </a>

        <div className="svp-header svp-header-alert">
          <h2>Your Action Required</h2>
          {appointment.specialtyId ? (
            <p>
              Please select a date for your {appointment.specialty.name}{" "}
              appointment with
            </p>
          ) : (
            <p>Please select a date for your video call with</p>
          )}
        </div>

        <div className="svp-body">
          <div className="svp-dr-info">
            <div className="dr-image" style={divStyle}></div>
            <p>
              {User.titleString(doctor)} {User.fullName(doctor)}
            </p>
          </div>

          <div className="svp-date-list">
            {preApprovedDates.map((d) => {
              const date = moment(d);
              return (
                <div key={date.unix()} className="svp-item">
                  <span>{date.format("ddd Do MMM YYYY [at] h:mm a")}</span>
                  <button
                    onClick={() => acceptDate(date)}
                    type="button"
                    className="btn svp-btn-info"
                  >
                    Accept
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="svp-footer">
          <button
            onClick={() => confirmNewDate()}
            type="button"
            className="svp-btn-grey"
          >
            Or Request a New Date
          </button>
        </div>
      </div>
      <p className="small">
        By using this service you agree to our Terms of Service and Privacy
        Policy
      </p>
      {confirm.show && (
        <UnboundConfirmationModal
          open={confirm.show}
          close={() =>
            setConfirm({
              show: false,
              proceedText: "",
              confirmText: "",
              proceedFunction: null,
            })
          }
          proceed={() => confirm.proceedFunction()}
          cancel={() => setConfirm({ show: false })}
          onClose={() => setConfirm({ show: false })}
          proceedText={confirm.proceedText}
          cancelText="Close"
        >
          {ReactHtmlParser(confirm.confirmText)}
        </UnboundConfirmationModal>
      )}
    </div>
  );
};

export default ActionRequired;
