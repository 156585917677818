import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Doctors } from "@helpers/";
import UserProfileForm from "./form";

function index() {
  const [file, setFile] = useState(null);
  const user = useSelector((state) => state.session.user);

  const submit = (values) => {
    let formData = new FormData();
    formData.append("file", file);
    const specialtyIds =
      (values.specialties &&
        values.specialties.map((specialty) => specialty.value)) ||
      [];
    values.specialties = specialtyIds;
    async function createOrUpdateDoctor() {
      const result = await Doctors.createDoctorWithImage(
        formData,
        values,
        user.token
      );
      console.log(result);
    }
    createOrUpdateDoctor();
  };
  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>Account</h1>
        <UserProfileForm onSubmit={submit} setImage={setFile} />
      </div>
    </div>
  );
}

export default index;
