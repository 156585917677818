export default {
  itemNumbers: [
    {
      specialty: "None",
      "first-consult-item-number": "None",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "None",
      "follow-up-consult-medicare-rebate": 0,
    },
    {
      specialty: "Breast Surgery",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Cardiology",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Cardiothorasic Surgery",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Clinical Toxicology",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Dentistry and Orthodontics",
      "first-consult-item-number": "na",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "na",
      "follow-up-consult-medicare-rebate": 0,
    },
    {
      specialty: "Dermatology",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Emergency Medicine",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Endocrinology",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "ENT Surgery",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Gastroenterology",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "General Medicine",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "General Surgery",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Haematology",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Hepatology",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Infectious Disease",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Intensive Care Medicine or Critical Care Medicine",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Military Medicine",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Nephrology",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Neurology",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Neurosurgery",
      "first-consult-item-number": 6007,
      "first-consult-medicare-rebate": 165,
      "follow-up-consult-item-number": 6009,
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Obstetrics and Gyneacology",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Oncology",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Opthalmology",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Oral and Maxillofacial Surgery",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Orthopaedic Surgery",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Paediatrics",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Pain Medicine",
      "first-consult-item-number": "2801, 2820",
      "first-consult-medicare-rebate": 192,
      "follow-up-consult-item-number": 2801,
      "follow-up-consult-medicare-rebate": 96,
    },
    {
      specialty: "Plastic Surgery",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Psychiatry",
      "first-consult-item-number": "288, 291",
      "first-consult-medicare-rebate": 577,
      "follow-up-consult-item-number": "288, 293",
      "follow-up-consult-medicare-rebate": 361,
    },
    {
      specialty: "Radiology",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Rehabilitation Medicine",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Rheumatology",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Respiratory Medicine",
      "first-consult-item-number": "110+112",
      "first-consult-medicare-rebate": 360,
      "follow-up-consult-item-number": "116+112",
      "follow-up-consult-medicare-rebate": 217,
    },
    {
      specialty: "Urology",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Vascular Sugery",
      "first-consult-item-number": "104+99",
      "first-consult-medicare-rebate": 108,
      "follow-up-consult-item-number": "99,105",
      "follow-up-consult-medicare-rebate": 55,
    },
    {
      specialty: "Clinical Psychology",
      "first-consult-item-number": "na",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "na",
      "follow-up-consult-medicare-rebate": 0,
    },
    {
      specialty: "Dietics",
      "first-consult-item-number": "na",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "na",
      "follow-up-consult-medicare-rebate": 0,
    },
    {
      specialty: "Occupational Therapist",
      "first-consult-item-number": "na",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "na",
      "follow-up-consult-medicare-rebate": 0,
    },
    {
      specialty: "Orthotics and Prosthetics",
      "first-consult-item-number": "na",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "na",
      "follow-up-consult-medicare-rebate": 0,
    },
    {
      specialty: "Physiotherapy",
      "first-consult-item-number": "na",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "na",
      "follow-up-consult-medicare-rebate": 0,
    },
    {
      specialty: "Social Work",
      "first-consult-item-number": "na",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "na",
      "follow-up-consult-medicare-rebate": 0,
    },
    {
      specialty: "Speech Pathology",
      "first-consult-item-number": "na",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "na",
      "follow-up-consult-medicare-rebate": 0,
    },
    {
      specialty: "Training Education",
      "first-consult-item-number": "na",
      "first-consult-medicare-rebate": 0,
      "follow-up-consult-item-number": "na",
      "follow-up-consult-medicare-rebate": 0,
    },
  ],
};
