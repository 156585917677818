import React, { useEffect, useState } from "react";
import Settings from "./settings";
import { history } from "@modules/";
let OT;
if (typeof window !== "undefined") {
  // eslint-disable-next-line no-undef
  OT = require("@opentok/client");
}
const MessagesItem = ({ showMessages }) => {
  return (
    <div className="meeting-controls">
      <a className="mc-btn mc-chat" href="#" onClick={() => showMessages()}>
        <i className="icon-chat-lined"></i>
      </a>
    </div>
  );
};
const Controls = (props) => {
  const {
    publisher,
    stream,
    appointmentId,
    showConfirmEndConsultation,
    showMessages,
    isAudioCall,
    publisherReady,
  } = props;
  const [hasAudio, setHasAudio] = useState(true);
  const [hasVideo, setHasVideo] = useState(true);
  const [videoInputs, setVideoInputs] = useState([]);
  const [audioInputs, setAudioInputs] = useState([]);
  const [viewCallSettings, setViewCallSettings] = useState(false);
  const [audioIndex, setAudioIndex] = useState(0);
  const [audioLabel, setAudioLabel] = useState("");

  useEffect(() => {
    setControlsWithStream(stream);
  }, [stream]);

  useEffect(() => {
    initControls(publisher);
  }, [publisher]);

  const initControls = (publisher) => {
    if (!publisher) {
      return;
    }
    const audioSource = publisher.getAudioSource();
    OT.getDevices((err, devices) => {
      const audioInput = devices.filter(
        (device) => device.kind === "audioInput"
      );
      const videoInput = devices.filter(
        (device) => device.kind === "videoInput"
      );
      const audioInd = audioInputs.findIndex((device) => {
        return device.label === audioSource.label;
      });
      setVideoInputs(videoInput);
      setAudioInputs(audioInput);
      setAudioIndex(audioInd);
      setAudioLabel(audioSource.label);
    });
  };

  const setControlsWithStream = (stream) => {
    if (!stream) {
      return;
    }

    setHasVideo(stream.hasVideo);
    setHasAudio(stream.hasAudio);
  };

  const toggleAudio = () => {
    const hasAud = !hasAudio;
    publisher.publishAudio(hasAud);
    setHasAudio(hasAud);
  };

  const toggleVideo = () => {
    const hasVid = !hasVideo;
    setHasVideo(hasVid);
    if (isAudioCall) {
      history.replace(`/consultation-room/${appointmentId}`);
      return;
    }
    publisher.publishVideo(hasVid);
  };

  const cycleVideo = () => {
    if (videoInputs.length <= 1) {
      return;
    }
    publisher.cycleVideo().then((e) => {
      console.log(e);
    });
  };

  const cycleMic = () => {
    console.log("audionInputs cycleMic", audioInputs);
    if (audioInputs.length <= 1) {
      return;
    }
    let newAudioIndex = audioIndex + 1;
    newAudioIndex = newAudioIndex % audioInputs.length;
    const device = audioInputs[newAudioIndex];
    const deviceId = device.deviceId;
    console.log("cycleMicrophone", deviceId);
    publisher.setAudioSource(deviceId);
    setAudioLabel(device.label);
    setAudioIndex(newAudioIndex);
  };

  const closeSettings = () => {
    setViewCallSettings(false);
  };

  if (!publisherReady) {
    return <MessagesItem showMessages={showMessages} />;
  }

  return (
    <div>
      {viewCallSettings && (
        <Settings
          closeSettings={() => closeSettings()}
          hasVideo={hasVideo}
          hasAudio={hasAudio}
          toggleVideo={() => toggleVideo()}
          toggleMute={() => toggleAudio()}
          cycleMic={() => cycleMic()}
          cycleVideo={() => cycleVideo()}
          audioLabel={audioLabel}
          publisher={publisher}
        />
      )}
      <div className="meeting-controls">
        <a
          onClick={() => showConfirmEndConsultation()}
          className="mc-btn mc-end-call"
          href="#"
        >
          <i className="icon-phone-lined-down"></i>
        </a>
        <a onClick={() => toggleAudio()} className="mc-btn" href="#">
          {hasAudio ? (
            <i className="icon-mic-lined"></i>
          ) : (
            <i className="icon-mic-lined-mute"></i>
          )}
        </a>
        <a onClick={() => toggleVideo()} className="mc-btn" href="#">
          {hasVideo ? (
            <i className="icon-camera-lined"></i>
          ) : (
            <i className="icon-camera-lined-off"></i>
          )}
        </a>
        {videoInputs.length > 1 && (
          <a className="mc-btn " onClick={() => cycleVideo()}>
            <i className="icon-camera-swith"></i>
          </a>
        )}
        <a
          className="mc-btn "
          onClick={() => setViewCallSettings(!viewCallSettings)}
        >
          <i className="icon-cog"></i>
        </a>
        <a className="mc-btn mc-chat" href="#" onClick={() => showMessages()}>
          <i className="icon-chat-lined"></i>
        </a>
      </div>
    </div>
  );
};

export default Controls;
