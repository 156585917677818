import { map, any, identity, contains } from "ramda";
import moment from "moment";
import { isEmpty } from "lodash";
import { RoleEnum } from "@constants";
import empty from "is-empty";
import api from "@api";
import { Routes } from "@constants";
/**
 * This is a collection of functions that mainly operate on
 * a user (or collection of users) and to return information
 * pertaining to that user (or set of users).
 *
 * @module UserHelpers
 */
var UserHelpers = {
  checkMail: (email) => {
    return api.get(Routes.verifyMail(email));
  },
  verifyEmail: (email) => {
    return api.get(Routes.verifyMail(email));
  },
  getRoles: function (user) {
    if (!user) {
      return [];
    }
    return map((role) => {
      return role.name;
    }, user.roles || []);
  },
  getRoleName: function (user) {
    if (!user) {
      return "";
    }
    return UserHelpers.getRoles(user)[0];
  },

  // /**
  //  * Returns comma seperated list of a user' roles
  //  *
  //  * @param {Object} user
  //  * @method getRoleString
  //  * @return {String}
  //  */
  // getRoleString: function (user) {
  //   return compose(
  //     join(", "),
  //     map((role) => capitalize(role))
  //   )(UserHelpers.getRoles(user));
  // },
  // /**
  //  * Determins if a given user has any admin type role
  //  *
  //  * @param {Object} user
  //  * @method isAnyAdmin
  //  * @return {Boolean}
  //  */
  isAnyAdmin: function (user) {
    return any(identity)([this.isDoctoAdmin(user), this.isAdmin(user)]);
  },
  isNurse: function (user) {
    const role = UserHelpers.getRoleName(user);
    return role === "nurse";
  },
  /**
   * Determins if a given user is an admin
   *
   * @param {Object} user
   * @method isAdmin
   * @return {Boolean}
   */
  isAdmin: function (user) {
    return contains(RoleEnum.admin)(UserHelpers.getRoles(user));
  },
  /**
   * Determins if a given user is a doctor
   *
   * @param {Object} user
   * @method isDoctor
   * @return {Boolean}
   */
  isDoctor: function (user) {
    return contains(RoleEnum.doctor)(UserHelpers.getRoles(user));
  },
  isAppointmentDoctor: function (user, appointment) {
    if (user && appointment) {
      return user.id === appointment.userId;
    }
    return false;
  },
  appointmentRole: function (user, appointment) {
    if (!user || !appointment) {
      return null;
    }
    if (user.id == appointment.userId) {
      return "doctor";
    }
    if (user.id == appointment.patientId) {
      return "patient";
    }
    if (UserHelpers.isDoctoAdmin) {
      return "admin";
    }
    return null;
  },
  isPatient: function (user) {
    return contains(RoleEnum.patient)(UserHelpers.getRoles(user));
  },
  /**
   * Determins if a given user is a Docto admin
   *
   * @param {Object} user
   * @method isDoctoAdmin
   * @return {Boolean}
   */
  isDoctoAdmin: function (user) {
    return contains(RoleEnum.doctoAdmin)(UserHelpers.getRoles(user));
  },
  /**
   * Converts a user object to the string version.
   * FristName LastName.
   *
   * @param {Object} user
   * @method userToNameString
   * @return {String} ex: => 'Robin Hood'
   */
  userToNameString: function (user) {
    if (!user) {
      return "";
    }
    let lastName = user.lastName ? ` ${user.lastName}` : "";
    return `${user.firstName}${lastName}`;
  },
  conversationDisplayName: function (user) {
    if (!user) {
      return "Unassigned";
    }
    let lastName = user.lastName ? ` ${user.lastName}` : "";
    return `${user.firstName}${lastName}`;
  },
  conversationDescription: function (conversation, recipient) {
    if (conversation.type.split("-")[0] == "emergency") {
      return "emergency consultation";
    }
    return conversation.specialty
      ? conversation.specialty.name
      : UserHelpers.specialtiesStr(recipient);
  },
  fullName: function (user) {
    return this.userToNameString(user);
  },
  userPhoneNumber: function (user) {
    if (!user) {
      return "";
    }
    return `(${user.countryCode}) ${user.phoneNumber}`;
  },
  titleString: function (user) {
    if (!user || !user.title) {
      return "";
    }
    return user.title;
  },
  specialistName: function (user) {
    return `${UserHelpers.titleString(user)} ${user.lastName}`;
  },
  degreeString: function (user) {
    if (!user || !user.degree) {
      return "";
    }
    return user.degree;
  },
  /**
   * Converts a user specialty object to the string version.
   * specialty name.
   *
   * @param {Object} user
   * @method specialtiesStr
   * @return {String} ex: => ''
   */
  specialtiesStr: function (user) {
    if (!user || !user.specialties || user.specialties.length === 0) {
      return "";
    }
    let specialtiesNames = user.specialties.map(function (specialty) {
      return specialty.name;
    });
    return specialtiesNames.join(", ");
  },
  subscriptionPlan: function (user) {
    return user.permissions?.subscriptionPlan;
  },
  hasValidPayment: function (user) {
    if (user.paymentMethod) {
      return true;
    }
    return false;
  },
  hasBusinessTrialValidSubscription: function (user) {
    return user.permissions.hasBusinessTrialValidSubscription;
  },
  hasValidSubscription: function (user) {
    if (!user || !user.permissions) {
      return false;
    }
    return user.permissions.hasValidSubscription;
  },
  hasOneOffValidSubscription: function (user) {
    return user.oneOffUntil && moment(user.oneOffUntil) >= moment();
  },
  fetchPayments: function (userId, token) {
    return api.get(Routes.fetchPayments(userId, token));
  },
  hasPaymentErrors: function (user) {
    return user.permissions.paymentError;
  },
  isIncompleteProfileForPrescriptions: function (user) {
    return (
      isEmpty(user.countryCode) ||
      isEmpty(user.phoneNumber) ||
      isEmpty(user.country) ||
      isEmpty(user.region) ||
      isEmpty(user.address) ||
      isEmpty(user.address2) ||
      isEmpty(user.postcode) ||
      isEmpty(user.birthday)
    );
  },
  hasCreditCard: function (user) {
    return Boolean(user.paymentMethod);
  },
  updatePermissions: function (token, userId, cbSuccess, cbError) {
    console.log("update permissions");
    // get(Routes.getPermissions(token, userId))
    //   .then(function (responsePermissions) {
    //     var user = responsePermissions.data.user;
    //     delete responsePermissions.data.user;
    //     var permissions = responsePermissions.data;
    //     user.permissions = permissions;
    //     cbSuccess({ user: user, permissions: permissions });
    //   })
    //   .error((error) => {
    //     cbError(error);
    //   });
  },
  createUpdateDoctor: function (payload, token, doctorId, cbError, cbSuccess) {
    console.log("createUpdateDoctor");

    // if (doctorId) {
    //   put(Routes.updateDoctor(doctorId, token), payload)
    //     .then((response) => {
    //       cbSuccess(response);
    //     })
    //     .error((error) => {
    //       cbError(error);
    //     });
    // } else {
    //   post(Routes.createDoctor(token), payload)
    //     .then((response) => {
    //       cbSuccess(response);
    //     })
    //     .error((error) => {
    //       cbError(error);
    //     });
    // }
  },
  updateUser: function (update, id, token) {
    return api.put(Routes.userWithToken(id, token), update);
  },
  getAddress: function (user) {
    if (!user) {
      return "";
    }
    const address = empty(user.address) ? "" : user.address;
    const address2 = empty(user.address2) ? "" : `${user.address2}`;
    const region = empty(user.region) ? "" : `, ${user.region}`;
    const postcode = empty(user.postcode) ? "" : ` ${user.postcode}`;
    return `${address} ${address2}${region}${postcode}`;
  },
  getRecipient: function (appointment, user) {
    var role = user.roles[0];
    switch (role.name) {
      case "patient":
        return appointment.user;
      case "admin":
      case "doctor":
        return appointment.patient;
      default:
        return appointment.user;
    }
  },

  getOwner: function (appointment, user) {
    if (!user) {
      return "unasigned";
    }
    if (user.id === appointment.patientId) {
      return appointment.patient;
    }
    return appointment.user;
  },
  fetchUserWithToken: async (userId, token) => {
    return await api.post(
      `/api/v1/users/${userId}/logInUser?accessToken=${token}`
    );
  },
  fetchUser: async (userId, token) => {
    return await api.get(`/api/v1/users/${userId}?accessToken=${token}`);
  },
  fetchPatients: (params) => {
    return api.get(Routes.getPatients(), params);
  },
  getSpecialtyInfoForUser: async (specialtyId, user) => {
    return await api.get(Routes.getSpecialtyInfoForUser(specialtyId, user?.id));
  },

  hardDelete: (userID, token) => {
    return api.del(Routes.userWithToken(userID, token));
  },
  block: (userID, block, token) => {
    return api.post(Routes.blockUser(userID, token), { block: block });
  },

  softDeleteUser: (userID, token) => {
    return api.del(Routes.softDeleteUser(userID, token));
  },
  mascaradePatient: (patient, token) => {
    return api.post(Routes.getPatientAccessToken(patient.id, token), {});
  },

  fetchSubscriptionPlans: (user = {}) => {
    return api.get(Routes.fetchSubscriptionPlans(user.id));
  },
  fetchNotifications: (id, token) => {
    return api.get(Routes.getNotifications(id, token));
  },
  getUserWithSpecialties: (userID, token) => {
    return api.get(
      `${Routes.getUser(
        userID
      )}?accessToken=${token}&filter=${encodeURIComponent(
        '{"include":"specialties"}'
      )}`
    );
  },
};

export default UserHelpers;
