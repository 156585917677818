import React from "react";

const Preview = ({ meta }) => {
  const { name, percent, status, remove, canRemove } = meta;
  return (
    <div key={name} className="file-item-wrap">
      <i className="type-icon icon-file-image" />
      <span>{name}</span>
      {status === "done" && <i className="f-right icon-check"></i>}
      {status !== "done" && (
        <div className="loading-bar">
          <div
            className="loading-bar-inner"
            style={{ width: `${percent}%` }}
          ></div>
        </div>
      )}
      {canRemove && <span className="dzu-previewButton" onClick={remove} />}
    </div>
  );
};

export default Preview;
