/* eslint-disable react/no-unescaped-entities */
import React from "react";

function Testimonials() {
  return (
    <div className="foc-wrap">
      <div className="container">
        <div className="row">
          <h3 className="small">Rural Australia</h3>
          <h3>From Our Customers</h3>

          <div className="col-xs-12 col-sm-12 col-md-4">
            <div className="foc-av-rationg-wrap">
              <div className="avr-left">
                <span>DOCTO AVG RATING</span>

                <span className="avr-num">4.9</span>

                <div className="avr-start-wrap">
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                </div>

                <div lang="avr-total">
                  <span>
                    <i className="icon-user-alt"></i>
                    5106 total
                  </span>
                </div>
              </div>

              <div className="avr-right">
                <div className="avr-level-wrap">
                  <div className="avr-level">
                    <i className="icon-star-full"></i>
                    <span className="level-num">5</span>
                    <div className="level-col lc-light">
                      <span>1250</span>
                    </div>
                  </div>

                  <div className="avr-level">
                    <i className="icon-star-full"></i>
                    <span className="level-num">4</span>
                    <div className="level-col lc-mid">
                      <span>650</span>
                    </div>
                  </div>

                  <div className="avr-level">
                    <i className="icon-star-full"></i>
                    <span className="level-num">3</span>
                    <div className="level-col lc-dark">
                      <span>53</span>
                    </div>
                  </div>

                  <div className="avr-level">
                    <i className="icon-star-full"></i>
                    <span className="level-num">23</span>
                    <div className="level-col"></div>
                  </div>

                  <div className="avr-level">
                    <i className="icon-star-full"></i>
                    <span className="level-num">5</span>
                    <div className="level-col"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 foc-comment-main">
            <div className="foc-comment-ittem">
              <div className="ci-image-wrap"></div>
              <p>
                Couldn't ask for a better online doctor! Finding the time to get
                checked out when you're under the weather isn't easy. Great
                tool, easy to use.
              </p>
              <div className="ci-name-rate">
                <span className="ci-name"></span>
                <span className="ci-rate">
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                </span>
              </div>
            </div>

            <div className="foc-comment-ittem">
              <div className="ci-image-wrap"></div>
              <p>
                Being on the road a lot means not having a chance to visit my
                usual doctor. Having an online doctor who can provide scripts
                quickly is what I needed.
              </p>
              <div className="ci-name-rate">
                <span className="ci-name"></span>
                <span className="ci-rate">
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                </span>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 foc-comment-main">
            <div className="foc-comment-ittem">
              <div className="ci-image-wrap"></div>
              <p>
                Living in a rural community makes it difficult to access medical
                professionals- especially in urgent situations. Being able to
                speak with a doctor online, who quickly diagnosed and faxed a
                prescription to my nearest pharmacy.
              </p>
              <div className="ci-name-rate">
                <span className="ci-name"></span>
                <span className="ci-rate">
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                </span>
              </div>
            </div>

            <div className="foc-comment-ittem">
              <div className="ci-image-wrap"></div>
              <p>
                Dr Jones was very knowledgeable and took great care to explain
                clearly to me the various microbiological challenges I was
                facing with my condition. His post-consultation report was
                without a doubt the most comprehensive and useful I have ever
                had done.
              </p>
              <div className="ci-name-rate">
                <span className="ci-name"></span>
                <span className="ci-rate">
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                  <i className="icon-star-full"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
