import React, { Component } from "react";
import Dropzone from "react-dropzone-uploader";
import PhoneInput from "react-phone-number-input";
import toastr from "toastr";
import CircleLoader from "./circle_loader";
import Preview from "./preview";
import { Specialties, Referrals, User, Utils, Toast } from "@helpers";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        userEmail: "",
        phone: "",
        name: "",
        specialty: "n/a",
        company: "",
      },
      emailError: null,
      referralFiles: [],
      isUploading: false,
      uploadStatus: "IDLE",
      loaded: true,
      specialties: [],
      selectedSpecialty: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.uploadFormData = this.uploadFormData.bind(this);
  }
  async fetchSpecialtiesData() {
    const specialties = await Specialties.fetchSpecialties();
    this.setState({ specialties: specialties });
    if (!this.props.selectedSpecialty) {
      this.setState({ specialty: specialties[0].id });
    }
  }
  componentDidMount() {
    if (this.props.selectedSpecialty) {
      this.setState({ specialty: this.props.selectedSpecialty });
    }
    if (this.props.showSpecialtySelector) {
      this.fetchSpecialtiesData();
    }
  }
  displayToast({ kind, message }) {
    return toastr.warning(message);
  }
  handlePhoneChange(value) {
    const newValues = this.state.form;
    newValues.phone = value;
    this.setState({ form: newValues });
  }
  handleChange(e) {
    const { value, name } = e.target;
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  async uploadFormData(formData, filesToUpload) {
    let { referralFiles } = this.state;
    referralFiles = [...referralFiles, ...filesToUpload];

    this.setState({
      referralFiles,
      isUploading: true,
      uploadStatus: "UPLOADING",
    });
    try {
      console.log(formData.get("specialty"));
      const uploadedFiles = referralFiles.map((element) => {
        return {
          key: element.key,
          fileName: element.fileName,
          size: element.size,
          status: "DONE",
        };
      });

      this.setState({
        referralFiles: uploadedFiles,
        isUploading: true,
        uploadStatus: "DONE",
      });
      const datos = await Referrals.uploadReferral(formData);
      Toast.displayToast({
        message: "Thank you! we will contact you shortly",
        kind: "success",
      });
    } catch (error) {
      this.displayToast({
        message: "Unexpected error occur. Please try again",
        kind: "error",
      });
    }
  }

  onDrop(ev) {
    let files = Array.from(ev.target.files);
    if (Utils.exceedFileSize(files)) {
      this.displayToast({
        message: "files exceeds the 25MB file-size",
        kind: "error",
      });
      return;
    }
  }

  handleSubmit(files) {
    const { form } = this.state;
    const { showName, showPractice, showPhone } = this.props;
    this.setState({ emailError: null });

    if (form.userEmail == "") {
      return this.displayToast({
        message: "Please fill out your email address",
        kind: "error",
      });
    }
    const filesToUpload = files.map((element) => {
      return {
        key: element.file.name,
        fileName: element.file.name,
        size: element.file.size,
        status: "LOADING",
      };
    });

    User.verifyEmail(form.userEmail).then((verified) => {
      if (verified.valid) {
        console.log("verified");
        const formData = new FormData();
        formData.append("email", form.userEmail);
        if (showName) {
          formData.append("name", form.name);
        }
        console.log(this.props);
        if (this.props.specialtyId) {
          formData.append("specialty", this.props.specialtyId);
        } else {
          formData.append("specialty", form.specialty);
        }

        if (showPractice) formData.append("company", form.company);
        if (showPhone) formData.append("phone", form.phone);

        for (var i = 0; i < files.length; i++) {
          var element = files[i].file;
          formData.append("file", element);
        }

        this.uploadFormData(formData, filesToUpload);
      } else {
        this.setState({ emailError: "Please verify your email" });
      }
    });
  }
  render() {
    const {
      form,
      referralFiles,
      isUploading,
      uploadStatus,
      loaded,
      emailError,
      specialties,
    } = this.state;
    const {
      showName,
      showPractice,
      showPhone,
      showSpecialtySelector,
    } = this.props;

    return (
      <div className="sp-section-files">
        <div className="sf-wrap">
          <div className="sf-wrap-head">
            <h4>Upload Referal</h4>
          </div>
          {uploadStatus === "IDLE" && (
            <div>
              {showName && (
                <div key="name" className="sf-wrap-input">
                  <label>Your Name</label>
                  <input
                    required
                    type="text"
                    name="name"
                    value={form.name}
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Your Name"
                  />
                </div>
              )}

              <div key="email" className="sf-wrap-input">
                <label>Your Email Address</label>
                <input
                  required
                  type="email"
                  name="userEmail"
                  value={form.userEmail}
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="email"
                />
                {emailError && <span className="error-text">{emailError}</span>}
              </div>

              {showPhone && (
                <div key="phone" className="sf-wrap-input phone">
                  <label>Your Phone Number</label>

                  <PhoneInput
                    className="form-control"
                    international
                    name="phone"
                    defaultCountry="AU"
                    countrySelectProps={{ unicodeFlags: true }}
                    countryCallingCodeEditable={false}
                    placeholder="Enter phone number"
                    value={form.phone}
                    onChange={this.handlePhoneChange}
                  />
                </div>
              )}
              {showPractice && (
                <div key="practice" className="sf-wrap-input">
                  <label>Your Company/Practice</label>
                  <input
                    type="text"
                    name="company"
                    value={form.company}
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Your Company"
                  />
                </div>
              )}
              {showSpecialtySelector && specialties.length > 0 && (
                <div className="sf-wrap-input">
                  <select
                    name="specialty"
                    className="medium"
                    onChange={this.handleChange}
                  >
                    {specialties.map((specialty, index) => {
                      return (
                        <option key={index} value={specialty.name}>
                          {specialty.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          )}

          <div className="sf-wrap-list">
            {referralFiles &&
              referralFiles.map((file, index) => {
                return (
                  <div key={index} className="file-item-wrap">
                    <i className="type-icon icon-file-image" />
                    <span>{file.fileName}</span>
                    {file.status === "DONE" && (
                      <i className="f-right icon-check" />
                    )}
                    {file.status === "LOADING" && (
                      <i className="f-right icon-loading" />
                    )}
                  </div>
                );
              })}
          </div>

          {uploadStatus !== "IDLE" && (
            <div className="loader-holder">
              <CircleLoader done={uploadStatus === "DONE"} />
              {isUploading && uploadStatus != "DONE" && (
                <span>One moment, we are uploading your referral</span>
              )}
              {uploadStatus === "DONE" && (
                <span>
                  Your referral has been received, we will contact you shortly
                </span>
              )}
            </div>
          )}

          {loaded && (
            <Dropzone
              key="drop"
              classNames={{
                dropzone: isUploading ? "hidden" : "sf-wrap-drop",
                submitButtonContainer: "sf-wrap-footer",
                input: "dz-input ",
                inputLabelWithFiles: "sf-wrap-label",
                preview: "sf-wrap-list",
              }}
              disabled={isUploading}
              canRemove
              autoUpload={false}
              onSubmit={this.handleSubmit}
              PreviewComponent={Preview}
              submitButtonContent={
                isUploading ? "Sending Information" : "Upload"
              }
              submitButtonDisabled={(files) => {
                return isUploading || files.length === 0;
              }}
              inputContent={(files, extra) =>
                extra.reject ? (
                  "Image, audio and video files only"
                ) : (
                  <div>
                    <i className="icon-cloud-upload"></i>
                    <span>Drag and drop here</span>
                    <span className="as-link">or browse</span>
                  </div>
                )
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default index;
