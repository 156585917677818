import React from "react";

function how_it_works() {
  return (
    <div className="mo-why-docto-wrap">
      <div className="container">
        <div className="mo-wd-header">
          <span>24hr Online Doctor</span>
          <h2>Why Docto?</h2>
        </div>

        <div className="row">
          <ul className="mo-wd-list">
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Video Doctor</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Online Doctor Chat</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Prescriptions Online</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Online Urologist</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Online Neurology</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Online Gynaecology</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Doctor App</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Get Prescribed</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>24/7 Doctor</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Virtual Dr Visit</span>
            </li>
            <li className="col-xs-6 col-sm-4 col-md-3">
              <span>Telemedicine Doctor</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default how_it_works;
