import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table-v6";
import TableLoader from "../preloader/table";
import { User, DateHelpers } from "@helpers/";
import { history } from "@modules";

class AppointmentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: "",
    };
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      const { filterName } = this.state;
      this.handleFilterChange({ search: filterName, page: 0 });
    }
  }

  clearFilterNameSearch() {
    this.handleFilterChange({ search: "", page: 0 });
  }

  handleFilterChange(query) {
    const { filters } = this.props.appointments;
    const q = {
      ...filters,
      ...query,
    };
    this.props.handleFilterChange(q);
  }

  onChangeSearch(ev) {
    const search = ev.target.value;
    this.setState({ filterName: search });
  }

  showAppointment(appointment) {
    history.push(`/messages?appointmentId=${appointment.id}`);
  }

  render() {
    const { appointments, loading } = this.props;

    if (loading)
      return (
        <div className="mt-10 mb-10">
          <TableLoader columns={["Doctor", "Patient", "date", "actions"]} />
        </div>
      );

    const { filters, data } = appointments;

    const pageSize = filters.count === 0 ? 1 : filters.pageSize;
    console.log(appointments);
    return (
      <div>
        <div className="admin-search-bar">
          <div className="input-group">
            <input
              type="text"
              value={this.state.filterName}
              className="form-styled"
              onChange={(ev) => this.onChangeSearch(ev)}
              onKeyDown={(e) => this.handleKeyDown(e)}
              placeholder="Filter by patient name"
            />
            <button
              type="submit"
              onClick={() =>
                this.handleFilterChange({
                  search: this.state.filterName,
                  page: 0,
                })
              }
              className="btn btn-green"
            >
              Search
            </button>
          </div>

          <button
            type="reset"
            onClick={() => this.clearFilterNameSearch()}
            className="btn btn-grey btn-clear"
          >
            Clear
          </button>
        </div>

        {loading && (
          <TableLoader columns={["Doctor", "Patient", "date", "actions"]} />
        )}
        {!loading && (
          <ReactTable
            manual
            sortable={false}
            loading={loading}
            page={filters.page}
            pageSize={pageSize}
            onPageChange={(page) => this.handleFilterChange({ page })}
            onPageSizeChange={(size) =>
              this.handleFilterChange({ pageSize: size, page: 0 })
            }
            data={data}
            pages={filters.totalPages}
            columns={[
              {
                Header: "Doctor",
                accessor: "user",
                className: "doctor",
                minWidth: 250,
                Cell: (row) => User.userToNameString(row.value),
                textAlign: "left",
              },
              {
                Header: "Patient",
                accessor: "patient",
                className: "patient",
                minWidth: 250,
                Cell: (row) => User.userToNameString(row.value),
                textAlign: "left",
              },
              {
                Header: "Date",
                accessor: "scheduledOn",
                className: "date",
                Cell: (row) =>
                  row.value ? DateHelpers.australianDateFormat(row.value) : "",
              },
              {
                Header: "",
                accessor: "actions",
                minWidth: 250,
                Cell: (row) => {
                  const appointment = row.original;
                  const { patient, user } = row.original;

                  return (
                    <div className="actions-cell">
                      <Link
                        className="btn btn-info"
                        to={`/messages?appointmentId=${appointment.id}`}
                      >
                        {" "}
                        <i className="icon-chat-lined" />
                      </Link>
                    </div>
                  );
                },
              },
            ]}
            className="patient-table -striped -highlight"
          />
        )}
      </div>
    );
  }
}

export default AppointmentsTable;
