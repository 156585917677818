import React, { useCallback } from "react";
import { Cookies } from "react-cookie";
import { connect, useDispatch, useStore } from "react-redux";
import { logout, unmascaradeUser } from "@modules/session";

function LogoutAdminButton({ user }) {
  const store = useStore();
  console.log("store");
  console.log(store);
  var cookies = new Cookies();
  var mascaradeToken = cookies.get("mascaradeToken");
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    const logoutFunction = logout();
    dispatch(logoutFunction);
  }, [dispatch]);

  const unMask = () => {
    dispatch(unmascaradeUser());
  };
  return (
    <li id="menu-item-923" className="menu-item menu-item-popup">
      {user.id && (
        <a
          className="logout-landing"
          href="#"
          onClick={mascaradeToken ? unMask : logOut}
        >
          {mascaradeToken
            ? `UNMASK ${user.firstName}`
            : `LogOut ${user.firstName}`}
        </a>
      )}
    </li>
  );
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps)(LogoutAdminButton);
