import React, { useEffect, useRef, useState } from "react";
import { Chat as ChatHelpers, User } from "@helpers";
import { Docto } from "@constants";
import Message from "./message";
import BookStatus from "./book_status";
import _ from "underscore";
import moment from "moment-timezone";
import HeaderMessage from "./header";
import PubNub from "pubnub";
import { Appointments, Utils } from "@helpers/index";
import { useSelector } from "react-redux";
import { closeConfirmationModal, showLoadingModal } from "@modules/gui";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { history } from "@modules/";

let pubnub = null;
const Conversation = (props) => {
  const [status, setStatus] = useState("LOADING");
  const [appointment, setAppointment] = useState(null);
  const [onlineRoles, setOnlineRoles] = useState([]);
  const [chatInput, setChatInput] = useState("");
  const { appointmentId, user, showConversations, updunix } = props;
  const chatInputRef = useRef();
  const messageContainerScroll = useRef();
  const [userRole, setUserRole] = useState("patient");
  const dispatch = useDispatch();
  const location = useLocation();

  const envs = useSelector((state) => state.session.env);

  const updateBookStatus = async (payload) => {
    try {
      dispatch(closeConfirmationModal());
      dispatch(showLoadingModal(true));
      const response = await Appointments.updateBookStatus(
        appointmentId,
        user.token,
        payload
      );
      console.log({ payload, response });
      onAppointmentChange();
      dispatch(showLoadingModal(false));
    } catch (error) {
      dispatch(showLoadingModal(false));
      console.error(error);
    }
  };
  useEffect(() => {
    if (updunix) {
      fetchAppointment();
      triggerUpdateConversation();
    }
  }, [updunix]);

  useEffect(() => {
    if (appointmentId) {
      fetchAppointment();
    }
    return unsubscribePubNub;
  }, [appointmentId]);

  const onAppointmentChange = () => {
    console.log("ONAPPOINTMENTCHANGE 22");
    Utils.updateScreen(location, history);
    fetchAppointment();
    triggerUpdateConversation();
  };

  const triggerUpdateConversation = () => {
    if (!appointmentId || !pubnub) {
      return;
    }
    const payload = {
      type: "updateConversation",
      data: {
        channel: `message-${appointmentId}`,
        sender: user.firsName,
        senderId: user.id,
        timestamp: new Date().getTime(),
      },
    };
    pubnub.publish({
      channel: `message-${appointmentId}`,
      message: payload,
    });
  };
  const fetchAppointment = async () => {
    setStatus("LOADING");
    try {
      setStatus("COMPLETED");
      const apmnt = await Appointments.getAppointmentWithMessages(
        appointmentId,
        user.token
      );
      setAppointment(apmnt);
      const usrRole = User.appointmentRole(user, apmnt);
      setUserRole(usrRole);
      scrollToBottom();
      subscribePubNubForMessages(apmnt);
    } catch (error) {
      console.error(error);
      setAppointment(null);
      setStatus("COMPLETED");
    }
  };

  const initPubNub = () => {
    if (pubnub) {
      unsubscribePubNub();
    }
    pubnub = new PubNub({
      publishKey: envs.PUB_NUB_PUBLISH_KEY,
      subscribeKey: envs.PUB_NUB_SUBSCRIBE_KEY,
      ssl: true,
      presenceTimeout: 120,
      heartbeatInterval: 30,
      uuid: `${user.id}:${Docto.version}:${User.getRoleName(user)}`,
      error: (error) => {
        console.error("PUB NUB Error:", error);
      },
    });
  };

  const unsubscribePubNub = () => {
    try {
      if (!pubnub) {
        return;
      }

      const existingListener = {
        message: function () {},
        presence: function () {},
      };
      pubnub.removeListener(existingListener);
      pubnub.unsubscribe({
        channels: [`message-${appointmentId}`],
      });
      pubnub.destroy();
      pubnub = null;
    } catch (error) {
      console.error(error);
    }
  };
  const scrollToBottom = () => {
    setTimeout(() => {
      chatInputRef?.current?.focus();
      messageContainerScroll?.current?.scrollTo(
        0,
        messageContainerScroll?.current?.scrollHeight
      );
    }, 500);
  };
  // TODO: Refactor this code to create a Pubnub component
  const onPubnubMessage = (income, appointment) => {
    const message = income.message;

    const { body, senderId } = message.data;
    if (senderId == user.id) {
      return;
    }
    switch (message.type) {
      case "updateConversation":
        fetchAppointment(appointmentId);
        break;
      case "chat":
        pushChat(body, appointment);
        break;
      default:
        break;
    }
  };
  const pushChat = (body, appointment) => {
    if (!appointment.chats) {
      return;
    }
    let apmnt = appointment;
    apmnt.chats.push(body);
    setAppointment({ ...apmnt });
    scrollToBottom();
  };

  const onPubnubPrescense = (p) => {
    const [uuid, version, role] = p.uuid.split(":");
    let onlnRoles = onlineRoles;
    switch (p.action) {
      case "join":
        if (uuid !== user.id) {
          if (onlnRoles.indexOf(role) === -1) {
            onlnRoles.push(role);
            setOnlineRoles([...onlnRoles]);
          }
        }
        break;
      case "leave":
        onlnRoles = _.reject(onlineRoles, function (id) {
          return id == role;
        });
        setOnlineRoles([...onlnRoles]);
        break;
      default:
        break;
    }
  };
  const onPubnubHereNow = (status, response) => {
    const channels = response.channels;
    let onlnRoles = onlineRoles;
    const channelsIds = Object.keys(channels);
    channelsIds.forEach((channelID) => {
      const channel = channels[channelID];
      channel.occupants.forEach((oc) => {
        const [uuid, version, role] = oc.uuid.split(":");
        if (uuid !== user.id) {
          if (onlnRoles.indexOf(role) === -1) {
            onlnRoles.push(role);
            setOnlineRoles([...onlnRoles]);
          }
        }
      });
    });
  };
  const subscribePubNubForMessages = (appointment) => {
    const channel = `message-${appointmentId}`;
    initPubNub();
    pubnub.addListener({
      message: (income) => onPubnubMessage(income, appointment),
      presence: onPubnubPrescense,
    });

    pubnub.subscribe({
      channels: [channel],
      withPresence: true,
    });
    pubnub.hereNow(
      {
        channels: [channel],
        includeUUIDs: true,
        includeState: true,
      },
      onPubnubHereNow
    );
  };
  const sendMessage = async () => {
    const message = chatInput;
    setChatInput("");
    let onlnRoles = onlineRoles;
    onlnRoles.push(User.getRoleName(user));
    const rolesToSend =
      appointment.bookStatus === "ADMIN-REVIEW"
        ? ["admin", "patient"]
        : ["doctor", "patient"];
    const sendToRoles = _.difference(rolesToSend, onlnRoles);
    const payload = {
      unixTime: moment().unix(),
      userId: user.id,
      message: message,
      sendToRoles: sendToRoles,
      meta: {},
    };
    try {
      const response = await Appointments.createChat(appointmentId, payload);
      if (response.success) {
        const chat = {
          appointmentId: appointmentId,
          createdAt: response.chat.createdAt,
          id: response.chat.id,
          message,
          unixTime: response.chat.unixTime,
          user: user,
          userId: user.id,
        };
        let apmnt = appointment;
        apmnt.chats.push(chat);
        setAppointment({ ...apmnt });
        scrollToBottom();
        const payload = {
          type: "chat",
          data: {
            body: chat,
            channel: `message-${appointmentId}`,
            sender: user.firsName,
            senderId: user.id,
            timestamp: new Date().getTime(),
          },
        };

        pubnub.publish({
          channel: `message-${appointmentId}`,
          message: payload,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const trySubmit = (ev) => {
    if (ev.key === "Enter") {
      sendMessage();
    }
  };

  const renderMessage = (messages, message, messageIndex) => {
    const prevMessage = messageIndex <= 0 ? null : messages[messageIndex - 1];
    message.currentUser = user;
    if (
      prevMessage &&
      prevMessage.headerFormatTime === message.headerFormatTime
    ) {
      return (
        <Message
          key={`message${messageIndex}`}
          updateBookStatus={updateBookStatus}
          onAppointmentChange={onAppointmentChange}
          message={message}
          currentUser={user}
          appointment={appointment}
        />
      );
    }
    return [
      <div
        key={`messageformatedtime${messageIndex}`}
        className="arci-ittem time-stamp"
      >
        <div className="arci-time-stamp">{message.headerFormatTime}</div>
      </div>,
      <Message
        key={`message${messageIndex}`}
        updateBookStatus={updateBookStatus}
        onAppointmentChange={onAppointmentChange}
        message={message}
        currentUser={user}
        appointment={appointment}
      />,
    ];
  };
  const renderLoading = () => {
    return (
      <div className="chat-wrap">
        <div className="chat-scroll chat-box-active">
          <div className="appointment-chat-wrap">
            <div className="aow-row-chat-ittems">
              <div className="arci-ittem">
                <div className="arci-time-stamp">
                  <div className="loader-animation">
                    <div></div>
                  </div>
                </div>
                <div className="loader-animation">
                  <div></div>
                </div>
              </div>
              <div className="arci-ittem received">
                <div className="arci-time-stamp">
                  <div className="loader-animation">
                    <div></div>
                  </div>
                </div>
                <div className="loader-animation">
                  <div></div>
                </div>
              </div>
              <div className="arci-ittem">
                <div className="arci-time-stamp">
                  <div className="loader-animation">
                    <div></div>
                  </div>
                </div>
                <div className="loader-animation">
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!appointment) {
    return null;
  }
  if (status == "LOADING") {
    return renderLoading();
  }
  const messages = ChatHelpers.formatMessages(appointment.chats, user);

  return (
    <div className="chat-wrap">
      <HeaderMessage
        {...{
          userRole,
          appointment,
          user,
          onAppointmentChange,
          showConversations,
          updateBookStatus,
        }}
      />
      <div
        key="conversationBody"
        ref={messageContainerScroll}
        className={"chat-scroll chat-box-active"}
      >
        <BookStatus
          {...{ role: userRole, user, appointment, onAppointmentChange }}
        />
        <div className="appointment-chat-wrap">
          <div
            key={`appointment${appointmentId}`}
            className="aow-row-chat-ittems"
          >
            {messages && messages.length > 0
              ? messages.map((message, messageIndex) => {
                  return renderMessage(messages, message, messageIndex);
                })
              : null}
          </div>
        </div>
        {appointment.bookStatus === "CANCELLED" ||
        appointment.bookStatus === "EMERGENCY-CONSULTATION" ||
        (appointment.bookStatus === "COMPLETED" &&
          moment(appointment.endedAt).add(6, "hours").unix() <
            moment().unix()) ? null : (
          <div className="appointment-chat-box-wrap fixed-bottom">
            <h4>
              {User.isAppointmentDoctor(user, appointment) ||
              User.isAnyAdmin(user)
                ? "Send message to Patient"
                : "Send message to Doctor"}
            </h4>
            <textarea
              onKeyPress={(ev) => trySubmit(ev)}
              ref={chatInputRef}
              placeholder="Type Something..."
              value={chatInput}
              onChange={(ev) => setChatInput(ev.target.value)}
            ></textarea>

            <div className="acb-footer">
              <div className="acb-actions"></div>

              <a
                onClick={() => sendMessage()}
                href="#"
                className="acb-submit acb-submit-icon"
              >
                Send
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Conversation;
