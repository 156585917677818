import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Company, Toast } from "@helpers";
import Form from "./form";
import Preloader from "../preloader/account";
import powerdByImg from "@assets/images/vidBox-brand.png";

const CreateCompany = () => {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.session.user);

  const submit = (values) => {
    async function createCompany() {
      try {
        setLoading(true);
        const result = await Company.createCompany(file, values, user.token);
        if (result) {
          setLoading(false);
          Toast.displayToast({
            type: "success",
            message: "Company has been created",
          });
          return history.push("/admin/companies");
        }
      } catch (error) {
        setLoading(false);
        Toast.displayToast({ type: "error", message: "Something Went Wrong" });
      }
    }
    createCompany();
  };
  if (loading) return <Preloader title="Create Company" />;
  return (
    <div className="register-holder">
      <div className="reg-body">
        <h1>Create a Company</h1>
        <div className="header-lined"></div>
        <Form onSubmit={submit} setImage={setFile} />
        <div className="vid-brand">
          <span>Powered By:</span>
          <img src={powerdByImg} />
        </div>
      </div>
    </div>
  );
};

export default CreateCompany;
