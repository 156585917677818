import React, { useEffect, useState } from "react";
import PubNub from "pubnub";
import InvalidAppointment from "./invalid_appointment";

import { FormHelpers, User, Toast } from "@helpers/index";
import { Routes, Docto } from "@constants";
import CallSettings from "@components/call_settings";
import { history } from "@modules/";
import empty from "is-empty";
import { Appointments, Token } from "@helpers/index";
import { useDispatch } from "react-redux";
import { initUser as initUserSession } from "@modules/session";
import { showLoadingModal, showLoginModal } from "@modules/gui";
let pubnub;
let channels = "";

const AppointmentAccess = (props) => {
  const [doctorOnline, setDoctorOnline] = useState(false);
  const [patientWaiting, setPatientWaiting] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [renderSettings, setRenderSettings] = useState(false);
  const [guestUser, setGuestUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [callError, setCallError] = useState(null);
  const { user, routeName } = props;
  const { appointmentId } = props.computedMatch.params;
  const dispatch = useDispatch();

  const unmount = () => {
    if (pubnub) {
      var existingListener = {
        message: function () {},
        presence: function () {},
      };
      pubnub.removeListener(existingListener);
      pubnub.unsubscribe({ channels: channels });
    }
  };
  const validateAppointmentAccess = async () => {
    try {
      const response = await Appointments.validateAppointmentAccess(
        appointmentId,
        user.token
      );
      const ap = response.appointment;
      const envs = response.envs;
      setAppointment(ap);
      subscribePubNubForMessages(ap, user, envs);
    } catch (error) {
      console.error(error);
      const res = JSON.parse(error.response);
      const message = res.error.message;
      if (res.error.code === "INVALID_APPOINTMENT_ACCESS") {
        setAppointment({
          id: appointmentId,
          errors: [message],
        });
      } else {
        history.replace("/");
        let toastInfo = {
          message: "Error Occur. Please contact administration",
          kind: "error",
        };
        Toast.displayToast(toastInfo);
      }
    }
  };
  const fetchAppointment = async () => {
    if (!appointmentId) {
      return;
    }
    try {
      const ap = await Appointments.getAppointment(appointmentId, user.token);
      setAppointment(ap);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (user.token || routeName === "guestRoomAccess") {
      validateAppointmentAccess();
    } else {
      fetchAppointment();
    }
    return unmount;
  }, []);
  const pubnubOnPresence = (p, appointment) => {
    const [uuid] = p.uuid.split(":");
    const channel = p.channel;
    if (p.action === "join") {
      if (appointment.userId === uuid && channel == `${appointment.id}`) {
        setDoctorOnline(true);
      }
      if (channel == `${appointment.id}-waiting` && user.id !== uuid) {
        setPatientWaiting(true);
      }
    } else if (p.action === "leave") {
      if (appointment.userId === uuid && channel == `${appointment.id}`) {
        setDoctorOnline(false);
      }
      if (channel == `${appointment.id}-waiting` && user.id !== uuid) {
        setPatientWaiting(false);
      }
    }
  };
  const pubnunbOnHereNow = (status, response, appointment) => {
    const channelWaiting = response.channels[`${appointment.id}-waiting`];
    const channelAppointment = response.channels[`${appointment.id}`];

    if (channelWaiting) {
      const occupantsID = channelWaiting.occupants.map((oc) => {
        let [uuid] = oc.uuid.split(":");
        return uuid;
      });

      if (occupantsID.includes(`${appointment.patientId}`)) {
        setPatientWaiting(true);
      }
    }

    if (channelAppointment) {
      const occupantsID = channelAppointment.occupants.map((oc) => {
        let [uuid] = oc.uuid.split(":");
        return uuid;
      });
      if (occupantsID.includes(`${appointment.userId}`)) {
        setDoctorOnline(true);
      }
    }
  };
  const subscribePubNubForMessages = (appointment, user, envs) => {
    pubnub = new PubNub({
      publishKey: envs.PUB_NUB_PUBLISH_KEY,
      subscribeKey: envs.PUB_NUB_SUBSCRIBE_KEY,
      ssl: true,
      presenceTimeout: 120,
      heartbeatInterval: 30,
      uuid: `${user.id}:${Docto.version}:${User.getRoleName(user)}`,
      error: function (error) {
        console.log("PUB NUB Error:", error);
      },
    });

    channels = User.isAppointmentDoctor(user, appointment)
      ? [`${appointment.id}-waiting`]
      : [appointment.id, `${appointment.id}-waiting`];

    pubnub.addListener({
      message: (message) => {
        console.log(message);
      },
      presence: (p) => pubnubOnPresence(p, appointment),
    });
    pubnub.subscribe({
      channels: channels,
      withPresence: true,
    });

    pubnub.hereNow(
      {
        channels: channels,
        includeUUIDs: true,
        includeState: true,
      },
      (status, response) => pubnunbOnHereNow(status, response, appointment)
    );
  };
  const requestAppointment = async () => {
    if (!user.id) {
      joinGuestConsultation();
      return;
    }
    const payload = User.isAppointmentDoctor(user, appointment)
      ? { userId: user.id }
      : { patientId: user.id };
    try {
      await Appointments.acceptAppointment(appointmentId, user.token, payload);
      history.push({
        pathname: `/consultation-room/${appointmentId}/audio/false`,
      });
    } catch (error) {
      var toastInfo = {
        message:
          "Error please try again. If the error persist please contact docto support.",
        kind: "error",
      };
      Toast.displayToast(toastInfo);
    }
  };
  const renderInvalidAppointment = (errors) => {
    return (
      <div className="meeting-holder">
        <InvalidAppointment errors={errors} />
      </div>
    );
  };
  const renderAcceptPatient = () => {
    const doctor = appointment?.user;
    let divStyle = {};
    if (typeof doctor.profileImage !== "undefined") {
      divStyle = {
        backgroundImage:
          "url(" + Routes.profilePicUrl(doctor.profileImage) + ")",
      };
    }

    return (
      <div className="joinAsGuestWrap">
        <div className="jag_info_wrap">
          <div className="jag_avatar" style={divStyle}></div>

          <h1 className="jag_title">
            {User.titleString(doctor)} {User.userToNameString(doctor)}
          </h1>
          <span className="jag_sub_title">Online Consulting Room</span>

          <CallSettings
            joinNowData={{
              appointment: appointment,
              isDoctor: false,
              patientWaiting,
              doctorOnline,
            }}
            joinNow={() => requestAppointment()}
            onError={(error) => callSettingError(error)}
          />
          {!callError ? (
            <div className="jag_footer_action">
              {doctorOnline ? (
                <a onClick={() => requestAppointment()}>JOIN NOW</a>
              ) : (
                <a className="disabled">WAITING FOR DOCTOR</a>
              )}
            </div>
          ) : null}
        </div>

        <div className="jag_brand"></div>
      </div>
    );
  };
  const callSettingError = (e) => {
    console.log("callSettingError", e);
    setCallError(e);
  };
  const renderAcceptDoctor = () => {
    const doctor = appointment.user;
    let divStyle = {};
    if (typeof doctor.profileImage !== "undefined") {
      divStyle = {
        backgroundImage:
          "url(" + Routes.profilePicUrl(doctor.profileImage) + ")",
      };
    }

    return (
      <div className="joinAsGuestWrap">
        <div className="jag_info_wrap">
          <div className="jag_avatar" style={divStyle}></div>

          <h1 className="jag_title">
            Welcome Dr {User.userToNameString(doctor)}
          </h1>
          <span className="jag_sub_title">Online Consulting Room</span>

          <CallSettings
            joinNowData={{
              isDoctor: true,
              patientWaiting: patientWaiting,
            }}
            joinNow={() => requestAppointment()}
            onError={(error) => callSettingError(error)}
          />
          {!callError ? (
            <div className="jag_footer_action">
              {patientWaiting ? (
                <a onClick={() => requestAppointment()}>PATIENT IS WAITING</a>
              ) : (
                <a onClick={() => requestAppointment()}>JOIN NOW</a>
              )}
            </div>
          ) : null}
        </div>

        <div className="jag_brand"></div>
      </div>
    );
  };
  const joinGuestConsultation = async () => {
    try {
      const response = await Appointments.acceptAppointmentGuest(
        appointmentId,
        {
          guestUser,
        }
      );
      Token.setGuestToken(response.guestToken);
      history.push({
        pathname: `/consultation-room/${appointmentId}`,
      });
    } catch (error) {
      console.error(error);
      const toastInfo = {
        message:
          "Error please try again. If the error persist please contact docto support.",
        kind: "error",
      };
      Toast.displayToast(toastInfo);
    }
  };
  const initGuestUser = (patient, envs, token) => {
    console.log("InitUser");
    patient.roles = [{ name: "guest" }];
    dispatch(initUserSession(envs, patient, token));
  };
  const acceptGuest = async () => {
    let payload = { errors: {} };
    if (!FormHelpers.validateEmail(email)) {
      payload.errors.email = "Please enter a valid Email";
      setEmail("");
    }
    if (empty(firstName)) {
      payload.errors.firstName = "Please enter First name";
    }
    if (empty(lastName)) {
      payload.errors.lastName = "Please enter Last name";
    }
    if (!empty(payload.errors)) {
      setErrors(payload.errors);
      return;
    }
    dispatch(showLoadingModal(true));
    try {
      const response = await User.checkMail(email);
      if (!response.valid) {
        setErrors((errors) => {
          return { ...errors, ...{ email: "Please enter a valid Email" } };
        });
        setEmail("");
        return;
      }
      setGuestUser({ firstName, lastName, email });
      setRenderSettings(true);
      dispatch(showLoadingModal(false));
    } catch (error) {
      console.error(error);
      const toastInfo = {
        message:
          "Error please try again. If the error persist please contact docto support.",
        kind: "error",
      };
      Toast.displayToast(toastInfo);
      dispatch(showLoadingModal(false));
    }
  };
  const renderGuestAccess = () => {
    const doctor = appointment.user;
    let divStyle = {};
    if (typeof doctor.profileImage !== "undefined") {
      divStyle = {
        backgroundImage:
          "url(" + Routes.profilePicUrl(doctor.profileImage) + ")",
      };
    }
    if (renderSettings) {
      return renderAcceptPatient();
    }
    return (
      <div className="joinAsGuestWrap">
        <div className="jag_info_wrap">
          <div className="jag_avatar" style={divStyle}></div>

          <h1 className="jag_title">Dr {User.userToNameString(doctor)}</h1>
          <span className="jag_sub_title">Online Consulting Room</span>

          <div className="jag_body_wrap">
            <input
              type="text"
              className="jag-lined-box"
              value={firstName}
              onChange={(ev) => setFirstName(ev.target.value)}
              placeholder={errors.firstName ? errors.firstName : "First name"}
            />
            <input
              type="text"
              className="jag-lined-box"
              value={lastName}
              onChange={(ev) => setLastName(ev.target.value)}
              placeholder={errors.lastName ? errors.lastName : "Last name"}
            />
            <input
              type="text"
              className="jag-lined-box"
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
              placeholder={errors.email ? errors.email : "email"}
            />

            <button className="jag-solid-btn" onClick={() => acceptGuest()}>
              Join As Guest
            </button>
          </div>
          <div className="jag_footer_wrap">
            <span>Already have an account?</span>
            <button
              onClick={() => {
                dispatch(showLoginModal(true));
              }}
            >
              Sign In
            </button>
          </div>
        </div>

        <div className="jag_brand"></div>
      </div>
    );
  };
  const renderAppointmentInfo = () => {
    const doctor = appointment?.user;
    let divStyle = {};
    if (typeof doctor.profileImage !== "undefined") {
      divStyle = {
        backgroundImage:
          "url(" + Routes.profilePicUrl(doctor.profileImage) + ")",
      };
    }

    return (
      <div className="joinAsGuestWrap">
        <div className="jag_info_wrap">
          <div className="jag_avatar" style={divStyle}></div>

          <h1 className="jag_title">
            {User.titleString(doctor)} {User.userToNameString(doctor)}
          </h1>
          <span className="jag_sub_title">Online Consulting Room</span>

          <CallSettings
            joinNowData={{
              appointment: appointment,
              isDoctor: false,
              patientWaiting: false,
              doctorOnline: false,
            }}
            signIn={() => {
              dispatch(showLoginModal(true));
            }}
            onError={(error) => callSettingError(error)}
          />
          <div className="jag_footer_action">
            <a
              onClick={() => {
                dispatch(showLoginModal(true));
              }}
            >
              SIGN IN
            </a>
          </div>
        </div>

        <div className="jag_brand"></div>
      </div>
    );
  };

  if (!appointment.id) {
    return (
      <div className="dr-profile-wrap">
        <div className="drp-scroll">
          <div className="drp-panel">
            <div className="drp-name">
              <span>Loading...</span>
            </div>
            <div className="drp-desc"></div>
            <div className="drp-footer"></div>
          </div>
        </div>
      </div>
    );
  }
  if (appointment.id && appointment.errors && appointment.errors.length) {
    return renderInvalidAppointment(appointment.errors);
  }
  if (!user.id && appointment.type === "guest-video") {
    return renderGuestAccess();
  }
  if (!user.id) {
    return renderAppointmentInfo();
  }
  var isDoctor = User.isAppointmentDoctor(user, appointment);
  if (isDoctor) {
    return renderAcceptDoctor();
  }
  return renderAcceptPatient();
};

export default AppointmentAccess;
