const texts = {
  testimonials: [
    {
      description:
        "medical services more readily available to Australia’s rural residents and travellers",
      imgUrl: "../assets/images/companies/Home__Testimonial_AusDoctor.png",
    },
    {
      description:
        "It is preventing a mother from having to go to a foreign hospital in the middle of the night. She can press a button and see a doctor in real time, without leaving the house.",
      imgUrl:
        "../assets/images/companies/Home__Testimonial_InsuranceBussiness.png",
    },
    {
      description: "Forget self diagnosing, speak to a real Dr, in real time.",
      imgUrl: "../assets/images/companies/Home__Testimonial_The_Australian.png",
    },
  ],
  reviews: [
    { description: "Amazing service. The dr just appeared on my phone!" },
    {
      description:
        "The doctor was clear and arranged a script for my local pharmacy.",
    },
    {
      description:
        "Late on Saturday night - saved us a trip to hospital. Thanks very much.",
    },
  ],
};
export default texts;
