import React from "react";
import { connect } from "react-redux";
import { stringify } from "query-string";
import AppointmentSection from "./appointment_section";
import { Appointments } from "@helpers/";

const INIT_STATUS = {
  upcoming: "LOADING",
  past: "LOADING",
  emergency: "LOADING",
};
const COMPLETE_STATUS = {
  upcoming: "COMPLETED",
  past: "COMPLETED",
  emergency: "COMPLETED",
};

class AppointmentView extends React.Component {
  constructor() {
    super();

    this.state = {
      status: INIT_STATUS,
      upcomingAppointments: [],
      pastAppointments: [],
      emergencyAppointments: [],
    };
  }

  updateAppointments() {
    this.setState({ status: INIT_STATUS });
    if (this.props.user) {
      Appointments.getDetailsAppointments(this.props.user.token, "admin").then(
        (response) => {
          console.log(response);
          this.setState({
            upcomingAppointments: response.upcoming,
            pastAppointments: response.past,
            emergencyAppointments: response.emergency,
            status: COMPLETE_STATUS,
          });
        }
      );
    }
  }

  componentDidMount() {
    this.updateAppointments();
  }
  showUpsertAppointmentModal() {
    console.log("updsert appointemnt modal");
  }

  handleFilterChange(type, query) {
    const { status } = this.state;
    status[type] = "LOADING";
    query.access_token = this.props.user.token;
    const queryStringify = stringify(query);
    this.setState({ status });
    Appointments.getAdminAppointments(type, queryStringify)
      .then((response) => {
        status[type] = "COMPLETED";
        const state = { status };
        state[`${type}Appointments`] = response;

        this.setState(state);
      })
      .catch((err) => {
        console.error(err);
        status[type] = "COMPLETED";
        this.setState({ status });
      });
  }

  render() {
    return (
      <div className="meeting-container">
        <div className="admin">
          <div className="appointments-inner">
            <div className="appointments-dash">
              <div className="appointments-dash-header">
                <span>APPOINTMENTS</span>
                <button
                  onClick={this.showUpsertAppointmentModal}
                  className="add-btn"
                >
                  Add New
                </button>
              </div>
            </div>

            <AppointmentSection
              title="Scheduled Appointments"
              status={this.state.status.upcoming}
              appointments={this.state.upcomingAppointments}
              user={this.props.user}
              type="upcoming"
              handleFilterChange={(q) => this.handleFilterChange("upcoming", q)}
            />
            <AppointmentSection
              title="Past Appointments"
              status={this.state.status.past}
              appointments={this.state.pastAppointments}
              user={this.props.user}
              type="past"
              handleFilterChange={(q) => this.handleFilterChange("past", q)}
            />

            <AppointmentSection
              title="Emergency Chats"
              status={this.state.status.emergency}
              appointments={this.state.emergencyAppointments}
              user={this.props.user}
              type="emergency"
              handleFilterChange={(q) =>
                this.handleFilterChange("emergency", q)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps)(AppointmentView);
