import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Doctors, Guid } from "@helpers";

function EmergencyDoctors() {
  const user = useSelector((state) => state.session.user);
  const [doctors, setDoctors] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [emergencyDoctorShifts, setEmergencyDoctorsShifts] = useState([]);

  useEffect(() => {
    if (user) {
      Doctors.fetchDoctors(user.token).then((response) => setDoctors(response));
      Doctors.fetchShifts().then((response) => setShifts(response));

      Doctors.fetchEmergencyDoctorShifts(user.token).then((response) => {
        console.log(response);
        setEmergencyDoctorsShifts(response);
      });
    }
  }, [user]);

  const changeSelectedDoctor = async (event, index, shiftName) => {
    let doctorId = event.target.value;
    let doctor = doctors.find((doctor) => {
      return doctor.id === doctorId;
    });
    console.log(doctorId);
    let shiftObject = emergencyDoctorShifts[index][shiftName];
    try {
      const change = await Doctors.updateEmergencyDoctor(
        shiftObject,
        doctor,
        user.token
      );
      console.log(change);
    } catch (error) {
      console.log(error);
    }
  };

  const renderShiftHours = (shiftItem) => {
    const getShiftText = (hour) =>
      hour > "12" ? parseInt(hour) - 12 + "pm" : hour + "am";
    let startText = getShiftText(shiftItem.starts);
    let endText = getShiftText(shiftItem.ends);
    let shiftText = [startText, "to", endText].join(" ");
    return <small>{shiftText}</small>;
  };

  const renderSelect = (index, shiftName) => {
    if (!doctors || !emergencyDoctorShifts) {
      return;
    }
    const selected = emergencyDoctorShifts[index][shiftName].user.id;
    return (
      <select
        defaultValue={selected}
        key={`${Guid()}`}
        onChange={(event) => changeSelectedDoctor(event, index, shiftName)}
      >
        {doctors.map((doctor, docIndex) => (
          <option key={`${index}_${docIndex}_${doctor.id}`} value={doctor.id}>
            {doctor.firstName + " " + doctor.lastName}
          </option>
        ))}
      </select>
    );
  };
  if (!shifts || !emergencyDoctorShifts) return <div></div>;

  return (
    <div className="admin-holder">
      <div className="doctor-list">
        <table className="styled table-responsive">
          <thead>
            <tr>
              <td>Day</td>
              {shifts.map((shiftItem, index) => (
                <td key={shiftItem.id}>
                  {shiftItem.description}
                  {renderShiftHours(shiftItem)}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {emergencyDoctorShifts.map((day, index) => (
              <tr key={day.day}>
                <td>{day.day}</td>
                <td>{renderSelect(index, "morning")}</td>
                <td>{renderSelect(index, "morningSec")}</td>
                <td>{renderSelect(index, "night")}</td>
                <td>{renderSelect(index, "nightSec")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EmergencyDoctors;
