import React, { useEffect, useState } from "react";
import empty from "is-empty";
import { Session, Toast } from "@helpers";
import { initUser } from "@modules/session";
import { useDispatch } from "react-redux";
import Services from "@helpers/services";

const LoginWithPincode = (props) => {
  const { onBack, onLoginWithPincode, data } = props;
  const { email, countryCode, phoneNumber } = data;
  const [error, setError] = useState(null);
  const [smsCode, setSmsCode] = useState("");
  const init = true;
  const last4numbers = phoneNumber.slice(-4);
  const dispatch = useDispatch();

  useEffect(() => {
    sendSmsCode();
  }, [init]);

  const sendSmsCode = async () => {
    try {
      await Services.sendSmsCode(email, `+${countryCode}${phoneNumber}`);
      console.log("code sent");
    } catch (error) {
      console.error(error);
      Toast.displayToast({
        kind: "error",
        message: `Invalid Phone Number. Please review it and try again `,
      });
      onBack();
    }
  };

  const verifySmsCode = async () => {
    try {
      const response = await Session.loginWithPinCode(email, smsCode);
      const { patient, envs, token } = response;
      const initUserThunk = initUser({ env: envs, user: patient, token });
      dispatch(initUserThunk);
      onLoginWithPincode();
    } catch (error) {
      setError("Invalid code. Please note, code is case sensitive");
    }
  };

  const renderErrors = () => {
    if (!empty(error)) {
      return (
        <div className="error-alert">
          <ul>
            <li>{error}</li>;
          </ul>
        </div>
      );
    }
  };

  return (
    <div className="jag_info_wrap">
      <div className="jag_prev">
        <a onClick={() => onBack()}>
          <i className="icon-chevron-left"></i>
          Back
        </a>
      </div>
      <h1 className="jag_title">Instant online consultation</h1>
      <span className="jag_sub_title">
        Your email address is registered. Enter the code you have just received
        by SMS to <strong>XXXX{last4numbers}</strong>.
      </span>

      <div className="jag_body_wrap">
        {renderErrors()}
        <input
          type="text"
          className="jag-lined-box"
          value={smsCode}
          onChange={(ev) => setSmsCode(ev.target.value)}
          placeholder="SMS CODE"
        />

        <a className="jag-solid-btn" onClick={() => verifySmsCode()} href="#">
          Continue
        </a>
      </div>
      <div className="jag_footer_wrap">
        <span>I didnt receive my verification code?</span>
        <a href="#" onClick={() => sendSmsCode()}>
          Send Again
        </a>
      </div>
    </div>
  );
};

export default LoginWithPincode;
