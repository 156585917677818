import React from "react";
import { Form } from "react-final-form";
import { InputField } from "@src/components/fields/input";
import formValidators from "@src/components/fields/field_validators";

function NurseForm({ onSubmit, initialValues = {} }) {
  const { required } = formValidators;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <InputField
            name="firstName"
            label="First Name:"
            fieldProps={{ validate: required }}
          />
          <InputField
            name="lastName"
            label="Last Name:"
            fieldProps={{ validate: required }}
          />

          <InputField
            name="email"
            label="Email:"
            fieldProps={{ validate: required }}
          />
          <InputField name="password" label="Password:" type="password" />
          <div className="buttons">
            <button
              type="submit"
              disabled={submitting}
              className={
                submitting || pristine ? "btn-large" : "btn-purple btn-large"
              }
            >
              {initialValues && initialValues.id
                ? "Edit Nurse"
                : "Create Nurse"}
            </button>
          </div>
        </form>
      )}
    />
  );
}

export default NurseForm;
