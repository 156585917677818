export default {
  prescriptionDrugs: [
    {
      name: "Abisart HCTZ 150/12.5",
    },
    {
      name: "Abisart HCTZ 300/12.5",
    },
    {
      name: "Abisart HCTZ 300/25",
    },
    {
      name: "Abisart-150mg",
    },
    {
      name: "Abisart-300mg",
    },
    {
      name: "Abisart-75mg",
    },
    {
      name: "Accupril 10 mg",
    },
    {
      name: "Accupril 20 mg",
    },
    {
      name: "Accupril 5 mg",
    },
    {
      name: "Accuretic 10/12.5",
    },
    {
      name: "Accuretic 20/12.5",
    },
    {
      name: "Acetec 10 mg",
    },
    {
      name: "Acetec 20 mg",
    },
    {
      name: "Aciclovir 200 mg",
    },
    {
      name: "Aciclovir 800 mg",
    },
    {
      name: "Acimax 20 mg enteric",
    },
    {
      name: "Acpio 15 mg",
    },
    {
      name: "Acpio 30 mg",
    },
    {
      name: "Acpio 45 mg",
    },
    {
      name: "Acquin 10 mg",
    },
    {
      name: "Acquin 20 mg",
    },
    {
      name: "Acquin 5 mg",
    },
    {
      name: "Acris Combi (4 x 35 mg)",
    },
    {
      name: "Acris Once-a-Month 150 mg",
    },
    {
      name: "Acris Once-a-Week 35 mg",
    },
    {
      name: "Actaze 15 mg",
    },
    {
      name: "Actaze 30 mg",
    },
    {
      name: "Actaze 45 mg",
    },
    {
      name: "Actonel 30 mg",
    },
    {
      name: "Actonel 5 mg",
    },
    {
      name: "Actonel EC Once-a-Week 35 mg enteric",
    },
    {
      name: "Actonel Once-a-Month 150 mg",
    },
    {
      name: "Actos 15 mg",
    },
    {
      name: "Actos 30 mg",
    },
    {
      name: "Actos 45 mg",
    },
    {
      name: "Adalat Oros 20 mg MR",
    },
    {
      name: "Adalat Oros 30 mg MR",
    },
    {
      name: "Adalat Oros 60 mg MR",
    },
    {
      name: "Adcirca 20 mg",
    },
    {
      name: "Addos XR 30 mg modified release",
    },
    {
      name: "Addos XR 60 mg modified release",
    },
    {
      name: "Adefin 10 mg",
    },
    {
      name: "Adefin 20 mg",
    },
    {
      name: "Adefin XL 30 mg modified release",
    },
    {
      name: "Adefin XL 60 mg modified release",
    },
    {
      name: "Adefovir Dipivoxil 10 mg",
    },
    {
      name: "Adempas 1 mg",
    },
    {
      name: "Adempas 1.5 mg",
    },
    {
      name: "Adempas 2 mg",
    },
    {
      name: "Adempas 2.5 mg",
    },
    {
      name: "Adempas 500 microgram",
    },
    {
      name: "Adenuric 80 mg",
    },
    {
      name: "Adesan 16 mg",
    },
    {
      name: "Adesan 32 mg",
    },
    {
      name: "Adesan 4 mg",
    },
    {
      name: "Adesan 8 mg",
    },
    {
      name: "Adesan HCT 16/12.5",
    },
    {
      name: "Adesan HCT 32/12.5",
    },
    {
      name: "Adesan HCT 32/25",
    },
    {
      name: "Adrenaline Auto-Injector 300 ug/0.3 mL , 0.3 mL pen device",
    },
    {
      name: "Adrenaline Jr Auto-Injector 150 ug/0.3 mL , 0.3 mL pen device",
    },
    {
      name: "Advagraf XL 1 mg modified release capsule, 60",
    },
    {
      name: "Advagraf XL 5 mg modified release capsule, 30",
    },
    {
      name: "Advagraf XL 500 microgram modified release capsule, 30",
    },
    {
      name: "Advantan 0.1% cream, 15 g",
    },
    {
      name: "Advantan 0.1% lotion, 20 g",
    },
    {
      name: "Advantan 0.1% ointment, 15 g",
    },
    {
      name: "Advantan Fatty 0.1% ointment, 15 g",
    },
    {
      name: "Aeron 250 microgram/mL inhalation solution, 30 x 1 mL ampoules",
    },
    {
      name: "Aeron 500 microgram/mL inhalation solution, 30 x 1 mL ampoules",
    },
    {
      name:
        "Airomir Autohaler 100 microgram/actuation inhalation, 200 actuations",
    },
    {
      name: "Akamin 50 mg",
    },
    {
      name: "Akynzeo capsule, 1",
    },
    {
      name: "Aldactone 100 mg",
    },
    {
      name: "Aldactone 25 mg",
    },
    {
      name: "Aldara 5% cream, 12 x 250 mg sachets",
    },
    {
      name: "Aldara 5% cream, 2 x 2 g",
    },
    {
      name: "Aldiq 5% cream, 12 x 250 mg sachets",
    },
    {
      name: "Aldomet 250 mg",
    },
    {
      name: "Alecensa 150 mg capsule, 4 x 56",
    },
    {
      name: "Alendro Once Weekly 70 mg",
    },
    {
      name: "Alendrobell 70 mg",
    },
    {
      name: "Alendronate (Sandoz) 70 mg",
    },
    {
      name: "Alendronate D3 70 mg/140 microgram (DRLA)",
    },
    {
      name: "Alendronate Once Weekly (Apo) 70 mg",
    },
    {
      name: "Alendronate Plus D3 70 mg/140 microgram (Apo)",
    },
    {
      name: "Alendronate Plus D3 70 mg/70 microgram (Apo)",
    },
    {
      name: "Alkeran 2 mg",
    },
    {
      name: "Allegron 10 mg",
    },
    {
      name: "Allegron 25 mg",
    },
    {
      name: "Allereze 10 mg",
    },
    {
      name: "Allmercap 20 mg/mL oral liquid, 100 mL",
    },
    {
      name: "Allopurinol 100 mg",
    },
    {
      name: "Allopurinol 300 mg",
    },
    {
      name: "Allosig 100 mg",
    },
    {
      name: "Allosig 300 mg",
    },
    {
      name: "Alphagan 0.2% eye drops, 5 mL",
    },
    {
      name: "Alphagan P 0.15% eye drops, 5 mL",
    },
    {
      name: "Alphapress 25 mg",
    },
    {
      name: "Alphapress 50 mg",
    },
    {
      name: "Alvesco 160 microgram/actuation inhalation, 120 actuations",
    },
    {
      name: "Alvesco 80 microgram/actuation inhalation, 120 actuations",
    },
    {
      name: "Alzene 10 mg",
    },
    {
      name: "Amiodarone (Apo) 200 mg",
    },
    {
      name: "Amipride 400 mg",
    },
    {
      name: "Amira 150 mg",
    },
    {
      name: "Amira 300 mg",
    },
    {
      name: "Amitriptyline 10 mg",
    },
    {
      name: "Amitriptyline 25 mg",
    },
    {
      name: "Amitriptyline 50 mg",
    },
    {
      name: "Amlodipine 10 mg",
    },
    {
      name: "Amlodipine 5 mg",
    },
    {
      name: "Anafranil 25 mg",
    },
    {
      name: "Anandron 150 mg",
    },
    {
      name: "Anaprox 550 mg",
    },
    {
      name: "Anastrol 1 mg",
    },
    {
      name: "Anastrozole 1 mg",
    },
    {
      name: "Andepra 30 mg enteric capsule, 28",
    },
    {
      name: "Andepra 60 mg enteric capsule, 28",
    },
    {
      name: "Andriol Testocap 40 mg capsule, 60",
    },
    {
      name: "Androcur 100 mg",
    },
    {
      name: "Androcur 50 mg",
    },
    {
      name: "Androderm 2.5 mg/24 hours patch, 60",
    },
    {
      name: "Androderm 5 mg/24 hours patch, 30",
    },
    {
      name: "Androforte 5% (50 mg/mL) cream, 50 mL",
    },
    {
      name: "Anginine 600 microgram sublingual",
    },
    {
      name: "Anoro Ellipta 62.5/25 powder for inhalation, 30 actuations",
    },
    {
      name: "Anpec 40 mg",
    },
    {
      name: "Anpec 80 mg",
    },
    {
      name: "Anterone 100 mg",
    },
    {
      name: "Anterone 50 mg",
    },
    {
      name: "Anthel 125 mg",
    },
    {
      name: "Anthel 250 mg",
    },
    {
      name: "Anti-Fungal (Pharmacy Action) 1% cream, 20 g",
    },
    {
      name: "Anti-Fungal Nail Treatment (Pharmacy Action) 5% solution, 5 mL",
    },
    {
      name: "Antroquoril 0.02% cream, 100 g",
    },
    {
      name: "Anusol ointment, 50 g",
    },
    {
      name: "Anusol suppository, 12",
    },
    {
      name: "APO-Primidone 250 mg",
    },
    {
      name: "Apoc-5Fu 5% cream, 20 g",
    },
    {
      name: "Aporyl Anti-Fungal Nail Treatment 5% solution, 5 mL",
    },
    {
      name: "Aprepitant (Apotex) 165 mg capsule, 1",
    },
    {
      name: "Aptivus 250 mg capsule, 120",
    },
    {
      name: "Aquacare/HP 10% cream, 100 g",
    },
    {
      name: "Arabloc 10 mg",
    },
    {
      name: "Arabloc 20 mg",
    },
    {
      name: "Aratac 100 mg",
    },
    {
      name: "Aratac 200 mg",
    },
    {
      name: "Arava 10 mg",
    },
    {
      name: "Arava 20 mg",
    },
    {
      name: "Arazil 10 mg",
    },
    {
      name: "Arazil 5 mg",
    },
    {
      name: "Arianna-1 1 mg",
    },
    {
      name: "Aricept 10 mg",
    },
    {
      name: "Aricept 5 mg",
    },
    {
      name: "Aridon 10 mg",
    },
    {
      name: "Aridon 5 mg",
    },
    {
      name: "Aridon APN 10 mg",
    },
    {
      name: "Aridon APN 5 mg",
    },
    {
      name: "Arimidex 1 mg",
    },
    {
      name: "Aristocort 0.02% cream, 100 g",
    },
    {
      name: "Aristocort 0.02% ointment, 100 g",
    },
    {
      name: "Arnuity Ellipta 100 ug/act powder for inhalation",
    },
    {
      name: "Arnuity Ellipta 200 ug/act powder for inhalation",
    },
    {
      name: "Aromasin 25 mg",
    },
    {
      name: "Aropax 20 mg",
    },
    {
      name: "Artane 2 mg",
    },
    {
      name: "Artane 5 mg",
    },
    {
      name: "Arthrexin 25 mg capsule, 50",
    },
    {
      name: "Artige 10 mg",
    },
    {
      name: "Asacol 800 mg enteric",
    },
    {
      name: "Asartan HCT 16/12.5",
    },
    {
      name: "Asartan HCT 32/12.5",
    },
    {
      name: "Asartan HCT 32/25",
    },
    {
      name: "Asasantin SR modified release capsule, 60",
    },
    {
      name: "Asmol CFC-Free 100 microgram/actuation inhalation, 200 actuations",
    },
    {
      name:
        "Asmol Uni-Dose 2.5 mg/2.5 mL inhalation solution, 30 x 2.5 mL ampoules",
    },
    {
      name:
        "Asmol Uni-Dose 5 mg/2.5 mL inhalation solution, 30 x 2.5 mL ampoules",
    },
    {
      name: "Astzol 1 mg",
    },
    {
      name: "Atacand 16 mg",
    },
    {
      name: "Atacand 32 mg",
    },
    {
      name: "Atacand 4 mg",
    },
    {
      name: "Atacand 8 mg",
    },
    {
      name: "Atacand Plus 16/12.5",
    },
    {
      name: "Atacand Plus 32/12.5",
    },
    {
      name: "Atacand Plus 32/25",
    },
    {
      name: "Ataris 10 mg",
    },
    {
      name: "Ataris 20 mg",
    },
    {
      name: "Atazanavir (Mylan) 200 mg capsule, 60",
    },
    {
      name: "Atazanavir (Mylan) 300 mg capsule, 60",
    },
    {
      name: "Atenolol (AFT) 50 mg/10 mL oral liquid, 300 mL",
    },
    {
      name: "Atenolol 50 mg",
    },
    {
      name: "Atorvachol 10 mg",
    },
    {
      name: "Atorvachol 20 mg",
    },
    {
      name: "Atorvachol 40 mg",
    },
    {
      name: "Atorvastatin 80 mg",
    },
    {
      name: "Atozet 10 mg/10 mg",
    },
    {
      name: "Atozet 10 mg/20 mg",
    },
    {
      name: "Atozet 10 mg/40 mg",
    },
    {
      name: "Atozet 10 mg/80 mg",
    },
    {
      name: "Atripla",
    },
    {
      name: "Atrovent 21 microgram/actuation",
    },
    {
      name: "Atrovent 22 microgram/actuation nasal spray",
    },
    {
      name:
        "Atrovent Adult UDV 500 microgram/mL inhalation solution, 30 x 1 mL ampoules",
    },
    {
      name: "Atrovent Forte 44 microgram/actuation nasal spray, 180 actuations",
    },
    {
      name:
        "Atrovent UDV 250 microgram/mL inhalation solution, 30 x 1 mL ampoules",
    },
    {
      name: "Aurorix 150 mg",
    },
    {
      name: "Aurorix 300 mg",
    },
    {
      name: "Auscap (Aspen) 20 mg capsule, 28",
    },
    {
      name: "Ausfam 20 mg",
    },
    {
      name: "Ausfam 40 mg",
    },
    {
      name: "Ausgem 600 mg",
    },
    {
      name: "Ausran 150 mg",
    },
    {
      name: "Ausran 300 mg",
    },
    {
      name: "Avanza 30 mg",
    },
    {
      name: "Avapro 150 mg",
    },
    {
      name: "Avapro 300 mg",
    },
    {
      name: "Avapro 75 mg",
    },
    {
      name: "Avapro HCT 150/12.5",
    },
    {
      name: "Avapro HCT 300/12.5",
    },
    {
      name: "Avapro HCT 300/25",
    },
    {
      name: "Avodart 500 microgram capsule, 30",
    },
    {
      name: "Avsartan 150 mg",
    },
    {
      name: "Avsartan 300 mg",
    },
    {
      name: "Avsartan 75 mg",
    },
    {
      name: "Avsartan HCT 150/12.5",
    },
    {
      name: "Avsartan HCT 300/12.5",
    },
    {
      name: "Avsartan HCT 300/25",
    },
    {
      name: "Axit 15 mg",
    },
    {
      name: "Axit 30 mg",
    },
    {
      name: "Axit 45 mg",
    },
    {
      name: "Aylide 1 mg",
    },
    {
      name: "Aylide 2 mg",
    },
    {
      name: "Aylide 3 mg",
    },
    {
      name: "Aylide 4 mg",
    },
    {
      name: "Azapin 50 mg",
    },
    {
      name: "Azarga eye drops, 5 mL",
    },
    {
      name: "Azathioprine 25 mg",
    },
    {
      name: "Azathioprine 50 mg",
    },
    {
      name: "Azilect 1 mg",
    },
    {
      name: "Azol 100 mg",
    },
    {
      name: "Azol 200 mg",
    },
    {
      name: "Azopt 1% eye drops, 5 mL",
    },
    {
      name: "Baclofen 10 mg",
    },
    {
      name: "Baclofen 25 mg",
    },
    {
      name: "Bactroban 2% cream, 15 g",
    },
    {
      name: "Bactroban Nasal 2% ointment, 3 g",
    },
    {
      name: "Baraclude 1 mg",
    },
    {
      name: "Baraclude 500 microgram",
    },
    {
      name: "Barbloc 5 mg",
    },
    {
      name: "Benztrop 2 mg",
    },
    {
      name: "Beprol 10 mg",
    },
    {
      name: "Beprol 2.5 mg",
    },
    {
      name: "Beprol 5 mg",
    },
    {
      name: "Betaloc 100mg",
    },
    {
      name: "Betaloc 50mg",
    },
    {
      name: "Betavit 100 mg",
    },
    {
      name: "Betnovate 0.1% cream, 30 g",
    },
    {
      name: "Betnovate 0.1% ointment, 30 g",
    },
    {
      name: "Betnovate-1/2 0.05% cream, 15 g",
    },
    {
      name: "Betnovate-1/5 0.02% cream, 100 g",
    },
    {
      name: "Betoptic 0.5% eye drops, 5 mL",
    },
    {
      name: "Betoquin 0.5% eye drops, 5 mL",
    },
    {
      name: "Bicard 10 mg",
    },
    {
      name: "Bicard 2.5 mg",
    },
    {
      name: "Bicard 5 mg",
    },
    {
      name: "Bicor 10 mg",
    },
    {
      name: "Bicor 2.5 mg",
    },
    {
      name: "Bicor 5 mg",
    },
    {
      name: "Biltricide 600 mg",
    },
    {
      name: "Bimatoprost (Apo) 0.03% eye drops, 3 mL",
    },
    {
      name: "Bimtop 0.03% eye drops, 3 mL",
    },
    {
      name: "Biodone Forte 5 mg/mL oral liquid, 1 L",
    },
    {
      name: "Biodone Forte 5 mg/mL oral liquid, 200 mL",
    },
    {
      name: "Bion Tears 0.1% / 0.3% eye drops, 28 x 0.4 mL unit doses",
    },
    {
      name: "Bisacodyl (Petrus) 10 mg suppository, 10",
    },
    {
      name: "Bisacodyl (Petrus) 10 mg suppository, 12",
    },
    {
      name: "Bisalax Micro 10 mg/5 mL enema, 25 x 5 mL",
    },
    {
      name: "Bisoprolol 10 mg",
    },
    {
      name: "Bisoprolol 2.5 mg",
    },
    {
      name: "Bisoprolol 5 mg",
    },
    {
      name: "Bondronat 50 mg",
    },
    {
      name: "Bosleer 125 mg",
    },
    {
      name: "Bosleer 62.5 mg",
    },
    {
      name: "Brenda-35 ED",
    },
    {
      name: "Breo Ellipta 100/25",
    },
    {
      name: "Breo Ellipta 200/25",
    },
    {
      name: "Bretaris Genuair",
    },
    {
      name: "Brevinor 21",
    },
    {
      name: "Brevinor 28",
    },
    {
      name: "Brevinor 28 Day",
    },
    {
      name: "Brevinor-1 21",
    },
    {
      name: "Brevinor-1 28",
    },
    {
      name: "Brevinor-1 28",
    },
    {
      name: "Bricanyl 500 microgram/mL , 5 x 1 mL ampoules",
    },
    {
      name: "Bricanyl Turbuhaler 500",
    },
    {
      name: "Brilinta 90 mg",
    },
    {
      name: "Brimica Genuair 340/12",
    },
    {
      name: "Brinzoquin 1% eye drops, 5 mL",
    },
    {
      name: "Briviact 10 mg/mL oral liquid, 300 mL",
    },
    {
      name: "Briviact 100 mg",
    },
    {
      name: "Briviact 25 mg",
    },
    {
      name: "Briviact 50 mg",
    },
    {
      name: "Briviact 75 mg",
    },
    {
      name: "Bronchitol 40 mg powder for inhalation, 280 capsules",
    },
    {
      name: "Brufen 400 mg",
    },
    {
      name:
        "Budamax Aqueous 64 microgram/actuation nasal spray, 120 actuations",
    },
    {
      name: "Buspar 10 mg",
    },
    {
      name: "Buspar 5 mg",
    },
    {
      name: "Cabaser 1 mg",
    },
    {
      name: "Cabaser 2 mg",
    },
    {
      name: "Cabergoline (Apo) 500 microgram",
    },
    {
      name: "Cabometyx 20 mg",
    },
    {
      name: "Cabometyx 40 mg",
    },
    {
      name: "Cabometyx 60 mg",
    },
    {
      name: "Cadivast 10/10",
    },
    {
      name: "Cadivast 10/20",
    },
    {
      name: "Cadivast 10/40",
    },
    {
      name: "Cadivast 10/80",
    },
    {
      name: "Cadivast 5/10",
    },
    {
      name: "Cadivast 5/20",
    },
    {
      name: "Cadivast 5/40",
    },
    {
      name: "Cadivast 5/80",
    },
    {
      name: "Caduet 10/10",
    },
    {
      name: "Caduet 10/20",
    },
    {
      name: "Caduet 10/40",
    },
    {
      name: "Caduet 10/80",
    },
    {
      name: "Caduet 5/40",
    },
    {
      name: "Caduet 5/80",
    },
    {
      name: "Calci-Tab 600 Tablet 600 mg (as carbonate), 240",
    },
    {
      name: "Calcipotriol/Betamethasone 50/500 (Sandoz) ointment, 30 g",
    },
    {
      name: "Calciprox 0.25 microgram capsule, 100",
    },
    {
      name: "Calcitriol (AN) 0.25 microgram capsule, 100",
    },
    {
      name: "Calcitriol (Apo) 0.25 microgram capsule, 100",
    },
    {
      name: "Calindamin 150 mg capsule, 24",
    },
    {
      name: "Calitrol 0.25 ug",
    },
    {
      name: "Calutex 50 mg",
    },
    {
      name: "Camino Pro Complete 12+ Years bar, 7 x 81 g",
    },
    {
      name: "Campral 333 mg enteric",
    },
    {
      name: "Candesan 16 mg",
    },
    {
      name: "Candesan 32 mg",
    },
    {
      name: "Candesan 4 mg",
    },
    {
      name: "Candesan 8 mg",
    },
    {
      name: "Candesan Combi 16/12.5",
    },
    {
      name: "Candesan Combi 32/12.5",
    },
    {
      name: "Candesan Combi 32/25",
    },
    {
      name: "Candesartan Cilexetil Combi 16/12.5",
    },
    {
      name: "Candesartan Cilexetil Combi 32/12.5",
    },
    {
      name: "Candesartan Cilexetil Combi 32/25",
    },
    {
      name: "Candesartan Cilexetil HCTZ 16/12.5",
    },
    {
      name: "Candesartan Cilexetil HCTZ 32/12.5",
    },
    {
      name: "Candesartan Cilexetil HCTZ 32/25",
    },
    {
      name: "Capoten 25 mg",
    },
    {
      name: "Capoten 5 mg/mL oral liquid, 95 mL",
    },
    {
      name: "Capoten 50 mg",
    },
    {
      name: "Captopril 12.5 mg",
    },
    {
      name: "Captopril 25 mg",
    },
    {
      name: "Captopril 50 mg",
    },
    {
      name: "Carafate 1 g",
    },
    {
      name: "Carbamazepine (Sandoz) 100 mg",
    },
    {
      name: "Carbamazepine (Sandoz) 200 mg",
    },
    {
      name: "Carbidopa 25 mg and Levodopa 250mg Tablets, USP",
    },
    {
      name: "Carbidopa 50 mg and Levodopa 200 mg Extended-release",
    },
    {
      name: "Cardizem 60 mg",
    },
    {
      name: "Cardizem CD 180 mg modified release capsule, 30",
    },
    {
      name: "Cardizem CD 240 mg modified release capsule, 30",
    },
    {
      name: "Cardizem CD 360 mg modified release capsule, 30",
    },
    {
      name: "Cardol 160 mg",
    },
    {
      name: "Cardol 80 mg",
    },
    {
      name: "Carolyn-35 ED",
    },
    {
      name: "Carvedilol 12.5 mg",
    },
    {
      name: "Carvedilol 25 mg",
    },
    {
      name: "Carvedilol 3.125 mg",
    },
    {
      name: "Carvedilol 6.25 mg",
    },
    {
      name: "Catapres 100 microgram",
    },
    {
      name: "Catapres 150 microgram",
    },
    {
      name: "Cavstat 10 mg",
    },
    {
      name: "Cavstat 20 mg",
    },
    {
      name: "Cavstat 40 mg",
    },
    {
      name: "Cavstat 5 mg",
    },
    {
      name: "Celapram 10 mg",
    },
    {
      name: "Celapram 20 mg",
    },
    {
      name: "Celapram 40 mg",
    },
    {
      name: "Celaxib 100 mg capsule, 60",
    },
    {
      name: "Celaxib 200 mg capsule, 30",
    },
    {
      name: "Celebrex 100 mg capsule, 60",
    },
    {
      name: "Celebrex 200 mg capsule, 30",
    },
    {
      name: "Celecoxib 100 mg capsule, 60",
    },
    {
      name: "Celecoxib 200 mg capsule, 30",
    },
    {
      name: "Celestone-M 0.02% cream, 100 g",
    },
    {
      name: "Celexi 100 mg capsule, 60",
    },
    {
      name: "Celexi 200 mg capsule, 30",
    },
    {
      name: "Cellcept 250 mg capsule, 100",
    },
    {
      name: "Cellcept 500 mg",
    },
    {
      name: "Celsentri 150 mg",
    },
    {
      name: "Celsentri 300 mg",
    },
    {
      name: "Ceptolate 250 mg capsule, 50",
    },
    {
      name: "Ceptolate 500 mg",
    },
    {
      name: "Certican 1 mg",
    },
    {
      name: "Certican 250 microgram",
    },
    {
      name: "Certican 500 microgram",
    },
    {
      name: "Certican 750 microgram",
    },
    {
      name: "Cerumol ear drops, 10 mL",
    },
    {
      name: "Chelsea-35 ED",
    },
    {
      name: "Chlorsig 0.5% eye drops, 10 mL",
    },
    {
      name: "Cholstat 10 mg",
    },
    {
      name: "Cholstat 20 mg",
    },
    {
      name: "Cholstat 40 mg",
    },
    {
      name: "Cifran 500 mg",
    },
    {
      name: "Cilopam-S 10 mg",
    },
    {
      name: "Cilopam-S 20 mg",
    },
    {
      name: "Ciloquin 0.3% eye drops, 5 mL",
    },
    {
      name: "Ciloxan 0.3% ear drops, 5 mL",
    },
    {
      name: "Ciloxan 0.3% eye drops, 5 mL",
    },
    {
      name: "Cipramil 20 mg",
    },
    {
      name: "Citalopram 20 mg",
    },
    {
      name: "Citalopram 40 mg",
    },
    {
      name: "Citalopram 10 mg",
    },
    {
      name: "Citrulline Easy 1 g",
    },
    {
      name: "Clarac 250 mg",
    },
    {
      name: "Claratyne 10 mg",
    },
    {
      name: "Clarihexal 250 mg",
    },
    {
      name: "Clobemix 150 mg",
    },
    {
      name: "Clobemix 300 mg",
    },
    {
      name: "Clomipramine (Apo) 25 mg",
    },
    {
      name: "Clomipramine Hydrochloride (GenRx) 25 mg",
    },
    {
      name: "Clonac 25 mg enteric",
    },
    {
      name: "Clonac 50 mg enteric",
    },
    {
      name: "Clonea 1% cream, 20 g",
    },
    {
      name: "Clonea Clotrimazole Thrush Treatment 3 Day 2% vaginal cream, 20 g",
    },
    {
      name: "Clonea Clotrimazole Thrush Treatment 6 Day 1% vaginal cream, 35 g",
    },
    {
      name: "Clonidine 100 microgram",
    },
    {
      name: "Clopidogrel 75 mg",
    },
    {
      name: "Clopidogrel/Aspirin 75/100",
    },
    {
      name: "Clopine 100 mg",
    },
    {
      name: "Clopine 200 mg",
    },
    {
      name: "Clopine 25 mg",
    },
    {
      name: "Clopine 50 mg",
    },
    {
      name: "Clopine 50 mg/mL oral liquid, 100 mL",
    },
    {
      name: "Clopixol Depot 200 mg/mL , 5 x 1 mL ampoules",
    },
    {
      name: "Clotrimazole 3 Day (Apo) 2% vaginal cream, 20 g",
    },
    {
      name: "Clotrimazole 6 Day (Apo) 1% vaginal cream, 35 g",
    },
    {
      name: "Clovix 75 mg",
    },
    {
      name: "Clozaril 100 mg",
    },
    {
      name: "Clozaril 25 mg",
    },
    {
      name: "Colaxsen",
    },
    {
      name: "Colazide 750 mg capsule, 280",
    },
    {
      name: "Colese 135 mg",
    },
    {
      name: "Colgout 500 microgram",
    },
    {
      name: "Colofac 135 mg",
    },
    {
      name: "Combivir",
    },
    {
      name: "Comtan 200 mg",
    },
    {
      name: "Coplavix 75 mg/100 mg",
    },
    {
      name: "Coralan 5 mg",
    },
    {
      name: "Coralan 7.5 mg",
    },
    {
      name: "Corbeton 40 mg",
    },
    {
      name: "Cordarone X 100 mg",
    },
    {
      name: "Cordarone X 200 mg",
    },
    {
      name: "Cordilox SR 180 mg modified release",
    },
    {
      name: "Cordilox SR 240 mg modified release",
    },
    {
      name: "Cortate 25 mg",
    },
    {
      name: "Cortate 5 mg",
    },
    {
      name: "Cortic-DS 1% cream, 50 g",
    },
    {
      name: "Cortic-DS 1% ointment, 30 g",
    },
    {
      name: "Cortic-DS 1% ointment, 50 g",
    },
    {
      name: "Cortival-1/2 0.05% cream, 15 g",
    },
    {
      name: "Cortival-1/5 0.02% cream, 100 g",
    },
    {
      name: "Cosamide 50 mg",
    },
    {
      name: "Cosdor eye drops, 5 mL",
    },
    {
      name: "Cosopt eye drops, 5 mL",
    },
    {
      name: "Cosudex 50 mg",
    },
    {
      name: "Cotellic 20 mg",
    },
    {
      name: "Coumadin 1 mg",
    },
    {
      name: "Coumadin 2 mg",
    },
    {
      name: "Coumadin 5 mg",
    },
    {
      name: "Coveram 10mg/10mg (perindopril arginine/amlodipine)",
    },
    {
      name: "Coveram 10mg/5mg (perindopril arginine/amlodipine)",
    },
    {
      name: "Coveram 5mg/10mg (perindopril arginine/amlodipine)",
    },
    {
      name: "Coveram 5mg/5mg (perindopril arginine/amlodipine)",
    },
    {
      name: "Coversyl 10 mg",
    },
    {
      name: "Coversyl 2.5 mg",
    },
    {
      name: "Coversyl 5 mg",
    },
    {
      name: "Coversyl Plus 5/1.25",
    },
    {
      name: "Coversyl Plus LD 2.5/0.625",
    },
    {
      name: "Cozavan 25 mg",
    },
    {
      name: "Cozavan 50 mg",
    },
    {
      name: "Creon 10 000 units modified release capsule, 100",
    },
    {
      name: "Creon 25 000 units modified release capsule, 100",
    },
    {
      name: "Creon 40 000 units modified release capsule, 100",
    },
    {
      name: "Creon Micro 5000 units/100 mg enteric coated granules, 20 g",
    },
    {
      name: "Crestor 10 mg",
    },
    {
      name: "Crestor 20 mg",
    },
    {
      name: "Crestor 40 mg",
    },
    {
      name: "Crestor 5 mg",
    },
    {
      name: "Crinone 8% vaginal gel, 15 applications",
    },
    {
      name: "Crosuva 10 mg",
    },
    {
      name: "Crosuva 20 mg",
    },
    {
      name: "Crosuva 40 mg",
    },
    {
      name: "Crosuva 5 mg",
    },
    {
      name: "Crysanal 550 mg",
    },
    {
      name: "Cubitan oral liquid, 24 x 200 mL bottles",
    },
    {
      name: "Cyklokapron 500 mg",
    },
    {
      name: "Cymbalta 30 mg",
    },
    {
      name: "Cymbalta 60 mg",
    },
    {
      name: "Daivobet 50/500 gel, 60 g",
    },
    {
      name: "Daivobet 50/500 ointment, 30 g",
    },
    {
      name: "Daklinza 30 mg",
    },
    {
      name: "Daklinza 60 mg",
    },
    {
      name: "Daktarin 2% cream, 30 g",
    },
    {
      name: "Daktarin 2% cream, 70 g",
    },
    {
      name: "Daktarin 2% powder, 30 g",
    },
    {
      name: "Daktarin Tincture 2% solution, 30 mL",
    },
    {
      name: "Dalacin C 150 mg capsule, 24",
    },
    {
      name: "Dantrium 25 mg capsule, 100",
    },
    {
      name: "Dantrium 50 mg capsule, 100",
    },
    {
      name: "Daonil 5 mg",
    },
    {
      name: "Dapa-Tabs 2.5 mg",
    },
    {
      name: "Densate 70 mg",
    },
    {
      name: "Deptran 10 mg capsule, 50",
    },
    {
      name: "Deptran 25 mg capsule, 50",
    },
    {
      name: "Deptran 50 mg",
    },
    {
      name: "Deralin 10 mg",
    },
    {
      name: "Deralin 160 mg",
    },
    {
      name: "Deralin 40 mg",
    },
    {
      name: "Dermatane 10 mg capsule, 60",
    },
    {
      name: "Dermatane 20 mg capsule, 60",
    },
    {
      name: "Dermatane 40 mg capsule, 30",
    },
    {
      name: "Descovy 200/10 mg",
    },
    {
      name: "Descovy 200/25 mg",
    },
    {
      name: "Desfax 100 mg modified release",
    },
    {
      name: "Desfax 50 mg modified release",
    },
    {
      name: "Desven 100 mg modified release",
    },
    {
      name: "Desven 50 mg modified release",
    },
    {
      name: "Desvenlafaxine 100 mg modified release",
    },
    {
      name: "Desvenlafaxine 50 mg modified release",
    },
    {
      name: "Dexmethsone 4 mg",
    },
    {
      name: "Dexmethsone 500 microgram",
    },
    {
      name: "Diabex 1 g",
    },
    {
      name: "Diabex 500 mg",
    },
    {
      name: "Diabex 850 mg",
    },
    {
      name: "Diabex XR 1 g modified release",
    },
    {
      name: "Diabex XR 500 mg modified release",
    },
    {
      name: "Diaformin 1 g",
    },
    {
      name: "Diaformin 500 mg",
    },
    {
      name: "Diaformin 850 mg",
    },
    {
      name: "Diaformin XR 1 g modified release",
    },
    {
      name: "Diaformin XR 500 mg modified release",
    },
    {
      name: "Dialamine powder for oral liquid, 400 g",
    },
    {
      name: "Diamicron MR 60 mg modified release",
    },
    {
      name: "Diamox 250 mg",
    },
    {
      name: "Diane-35 ED",
    },
    {
      name: "Diapride 1 mg",
    },
    {
      name: "Diapride 2 mg",
    },
    {
      name: "Diapride 3 mg",
    },
    {
      name: "Diapride 4 mg",
    },
    {
      name: "Diasp SR modified release capsule, 60",
    },
    {
      name: "Diastix diagnostic strip, 50",
    },
    {
      name: "Dibenyline 10 mg capsule, 100",
    },
    {
      name: "Dibenzyline 10 mg capsule, 100",
    },
    {
      name: "Dicarz 12.5 mg",
    },
    {
      name: "Dicarz 25 mg",
    },
    {
      name: "Dicarz 6.25 mg",
    },
    {
      name: "Diclofenac (Amneal) 25 mg enteric",
    },
    {
      name: "Diclofenac (Amneal) 50 mg enteric",
    },
    {
      name: "Diflucan 100 mg capsule, 28",
    },
    {
      name: "Diflucan 50 mg capsule, 28",
    },
    {
      name: "Dilantin 100 mg",
    },
    {
      name: "Dilantin 30 mg",
    },
    {
      name: "Dilart 160 mg",
    },
    {
      name: "Dilart 320 mg",
    },
    {
      name: "Dilart 40 mg",
    },
    {
      name: "Dilart 80 mg",
    },
    {
      name: "Dilatrend 12.5 mg",
    },
    {
      name: "Dilatrend 25 mg",
    },
    {
      name: "Dilatrend 6.25 mg",
    },
    {
      name: "Dilaudid 1 mg/mL oral liquid, 200 mL",
    },
    {
      name: "Dilaudid 2 mg",
    },
    {
      name: "Dilaudid 2 mg/mL , 5 x 1 mL ampoules",
    },
    {
      name: "Dilaudid 4 mg",
    },
    {
      name: "Dilaudid 8 mg",
    },
    {
      name: "Diltiazem Hydrochloride 60 mg",
    },
    {
      name: "Diltiazem Hydrochloride CD 180 mg",
    },
    {
      name: "Diltiazem Hydrochloride CD 240 mg",
    },
    {
      name: "Diltiazem Hydrochloride CD 360 mg",
    },
    {
      name: "Dimirel 1 mg",
    },
    {
      name: "Dimirel 2 mg",
    },
    {
      name: "Dimirel 3 mg",
    },
    {
      name: "Dimirel 4 mg",
    },
    {
      name: "Diovan 160 mg",
    },
    {
      name: "Diovan 320 mg",
    },
    {
      name: "Diovan 40 mg",
    },
    {
      name: "Diovan 80 mg",
    },
    {
      name: "Dipentum 250 mg capsule, 100",
    },
    {
      name: "Dipentum 500 mg",
    },
    {
      name: "Diprosone 0.05% cream, 15 g",
    },
    {
      name: "Diprosone 0.05% ointment, 15 g",
    },
    {
      name: "Distaph 250 mg capsule, 24",
    },
    {
      name: "Distaph 500 mg capsule, 24",
    },
    {
      name: "Dithiazide 25 mg",
    },
    {
      name: "Ditropan 5 mg",
    },
    {
      name: "Dizole 100 mg capsule, 28",
    },
    {
      name: "Dizole 200 mg capsule, 28",
    },
    {
      name: "Dizole 50 mg capsule, 28",
    },
    {
      name: "Dolapril 1 mg capsule, 28",
    },
    {
      name: "Dolapril 2 mg capsule, 28",
    },
    {
      name: "Dolapril 4 mg capsule, 28",
    },
    {
      name: "Dolapril 500 microgram capsule, 28",
    },
    {
      name: "Donepezil Hydrochloride (AN) 10 mg",
    },
    {
      name: "Donepezil Hydrochloride (AN) 5 mg",
    },
    {
      name: "Dorzolamide (Apo) 2% eye drops, 5 mL",
    },
    {
      name: "Dostinex 500 microgram",
    },
    {
      name: "Dosulepin (Mylan) 25 mg capsule, 50",
    },
    {
      name: "Dosulepin (Mylan) 75 mg",
    },
    {
      name: "Dothep 25 mg capsule, 50",
    },
    {
      name: "Dothep 75 mg",
    },
    {
      name: "Dovato 50/300",
    },
    {
      name: "Drixine Adult 0.05% nasal spray, 15 mL",
    },
    {
      name: "Dulcolax 10 mg suppository, 10",
    },
    {
      name: "Duloxecor 30 mg enteric capsule, 28",
    },
    {
      name: "Duloxecor 60 mg enteric capsule, 28",
    },
    {
      name: "Duloxetine (AN) 30 mg enteric capsule, 28",
    },
    {
      name: "Duloxetine (AN) 60 mg enteric capsule, 28",
    },
    {
      name: "Duocover 75 mg/100 mg",
    },
    {
      name: "Duodart 500/400 modified release capsule, 30",
    },
    {
      name: "Duodopa intestinal gel, 7 x 100 mL",
    },
    {
      name: "Duofilm 16.7% / 16.7% solution, 15 mL",
    },
    {
      name: "Duoplidogrel 75 mg/100 mg",
    },
    {
      name: "Duoresp Spiromax 200/6 powder for inhalation, 120 actuations",
    },
    {
      name: "Duoresp Spiromax 400/12 powder for inhalation, 2 x 60 actuations",
    },
    {
      name: "Duotrav eye drops, 2.5 mL",
    },
    {
      name: "Dutasteride 500 microgram capsule",
    },
    {
      name: "Dytrex 30 mg enteric capsule, 28",
    },
    {
      name: "Dytrex 60 mg enteric capsule, 28",
    },
    {
      name: "Easiphen oral liquid, 18 x 250 mL",
    },
    {
      name: "Ebixa 10 mg",
    },
    {
      name: "Ebixa 20 mg",
    },
    {
      name: "Edecrin 25 mg",
    },
    {
      name: "Edronax 4 mg",
    },
    {
      name: "Edurant 25 mg",
    },
    {
      name: "Efexor-XR 150 mg",
    },
    {
      name: "Efexor-XR 37.5 mg",
    },
    {
      name: "Efexor-XR 75 mg",
    },
    {
      name: "Effient 10 mg",
    },
    {
      name: "Effient 5 mg",
    },
    {
      name: "Efudix 5% cream, 20 g",
    },
    {
      name: "Egoderm 1% cream, 50 g",
    },
    {
      name: "Egoderm ointment, 50 g",
    },
    {
      name: "Egopsoryl TA gel, 30 g",
    },
    {
      name: "Elaxine SR 150 mg",
    },
    {
      name: "Elaxine SR 37.5 mg",
    },
    {
      name: "Elaxine SR 75 mg",
    },
    {
      name: "Eldepryl 5 mg",
    },
    {
      name: "Eleanor 150/30 ED",
    },
    {
      name: "Eleanor 150/30 ED",
    },
    {
      name: "Eleuphrat 0.05% cream, 15 g",
    },
    {
      name: "Eleuphrat 0.05% ointment, 15 g",
    },
    {
      name: "Eleva 100 mg",
    },
    {
      name: "Eleva 50 mg",
    },
    {
      name: "Elidel 1% cream, 15 g",
    },
    {
      name: "Eliquis 2.5 mg",
    },
    {
      name: "Eliquis 5 mg",
    },
    {
      name: "Elocon 0.1% cream, 50 g",
    },
    {
      name: "Elocon 0.1% lotion, 30 mL",
    },
    {
      name: "Elocon 0.1% ointment, 15 g",
    },
    {
      name: "Elocon 0.1% ointment, 50 g",
    },
    {
      name: "Emexlon 10 mg",
    },
    {
      name: "Enalapril Maleate 5 mg",
    },
    {
      name: "Enalapril Maleate 10 mg",
    },
    {
      name: "Enalapril Maleate 20 mg",
    },
    {
      name: "Enalapril/HCT 20mg/6mg (Sandoz)",
    },
    {
      name: "Endep 10 mg",
    },
    {
      name: "Endep 25 mg",
    },
    {
      name: "Endep 50 mg",
    },
    {
      name: "Endometrin 100 mg pessary, 21",
    },
    {
      name: "Enidin 0.2% eye drops, 5 mL",
    },
    {
      name: "Enlafax XR 150 mg",
    },
    {
      name: "Enlafax XR 75 mg",
    },
    {
      name: "Entac 1 mg",
    },
    {
      name: "Entac 500 microgram",
    },
    {
      name: "Enteclude 1 mg",
    },
    {
      name: "Enteclude 500 microgram",
    },
    {
      name: "Entresto 24/26",
    },
    {
      name: "Entresto 49/51",
    },
    {
      name: "Entresto 97/103",
    },
    {
      name: "Entrip 10 mg",
    },
    {
      name: "Entrip 25 mg",
    },
    {
      name: "Entrip 50 mg",
    },
    {
      name: "Epclusa",
    },
    {
      name: "Epiduo 0.1% / 2.5% gel, 30 g",
    },
    {
      name: "Epilim 100 mg",
    },
    {
      name: "Epilim EC 200 mg enteric",
    },
    {
      name: "Epilim EC 500 mg enteric",
    },
    {
      name: "Epilim Liquid Sugar Free 200 mg/5 mL oral liquid, 300 mL",
    },
    {
      name: "Epilim Syrup 200 mg/5 mL oral liquid, 300 mL",
    },
    {
      name: "Epipen Auto-Injector 300 microgram/0.3 mL , 0.3 mL pen device",
    },
    {
      name: "Epipen Jr Auto-Injector 150 microgram/0.3 mL , 0.3 mL pen device",
    },
    {
      name: "Epiramax 100 mg",
    },
    {
      name: "Epiramax 200 mg",
    },
    {
      name: "Epiramax 25 mg",
    },
    {
      name: "Epiramax 50 mg",
    },
    {
      name: "Eplerenone 25 mg",
    },
    {
      name: "Eplerenone 50 mg",
    },
    {
      name: "Erivedge 150 mg capsule, 28",
    },
    {
      name: "Escitalopram 10 mg",
    },
    {
      name: "Escitalopram 20 mg",
    },
    {
      name: "Esipram 10 mg",
    },
    {
      name: "Esipram 20 mg",
    },
    {
      name: "Esitalo 10 mg",
    },
    {
      name: "Esitalo 20 mg",
    },
    {
      name: "Eskazole 400 mg",
    },
    {
      name: "Esomeprazole 20 mg",
    },
    {
      name: "Esomeprazole 40 mg",
    },
    {
      name: "Espler 25 mg",
    },
    {
      name: "Espler 50 mg",
    },
    {
      name: "Estamane 25 mg",
    },
    {
      name: "Estelle-35 ED",
    },
    {
      name: "Eucerin 6% ointment, 100 g",
    },
    {
      name: "Eutroxsig 100 microgram",
    },
    {
      name: "Eutroxsig 200 microgram",
    },
    {
      name: "Eutroxsig 50 microgram",
    },
    {
      name: "Eutroxsig 75 microgram",
    },
    {
      name: "Evelyn 150/30 ED",
    },
    {
      name: "Evifyne 60 mg",
    },
    {
      name: "Eviplera",
    },
    {
      name: "Evista 60 mg",
    },
    {
      name: "Evolve Carmellose 0.5% eye drops, 10 mL",
    },
    {
      name: "Evolve Hypromellose 0.3% w/v eye drops, 10 mL",
    },
    {
      name: "Evotaz",
    },
    {
      name: "Exelon 1.5 mg capsule, 56",
    },
    {
      name: "Exelon 3 mg capsule, 56",
    },
    {
      name: "Exelon 4.5 mg capsule, 56",
    },
    {
      name: "Exelon 6 mg capsule, 56",
    },
    {
      name: "Exelon-10 9.5 mg/24 hours patch, 30",
    },
    {
      name: "Exelon-15 13.3 mg/24 hours patch, 30",
    },
    {
      name: "Exelon-5 4.6 mg/24 hours patch, 30",
    },
    {
      name: "Exemestane 25 mg",
    },
    {
      name: "Exforge 10/160",
    },
    {
      name: "Exforge 10/320",
    },
    {
      name: "Exforge 5/160",
    },
    {
      name: "Exforge 5/320",
    },
    {
      name: "Exforge 5/80",
    },
    {
      name: "Exforge HCT 10/160/12.5",
    },
    {
      name: "Exforge HCT 10/160/25",
    },
    {
      name: "Exforge HCT 10/320/25",
    },
    {
      name: "Exforge HCT 5/160/12.5",
    },
    {
      name: "Exforge HCT 5/160/25",
    },
    {
      name: "Extine 20 mg",
    },
    {
      name: "Ezalo Composite Pack (30 x Ezetrol",
    },
    {
      name: "Ezemichol 10 mg",
    },
    {
      name: "Ezetimibe 10 mg",
    },
    {
      name: "Ezetimibe Simvastatin 10/10",
    },
    {
      name: "Ezetimibe Simvastatin 10/20",
    },
    {
      name: "Ezetimibe Simvastatin 10/40",
    },
    {
      name: "Ezetimibe Simvastatin 10/80",
    },
    {
      name: "Ezetrol 10 mg",
    },
    {
      name: "Ezovir 125 mg",
    },
    {
      name: "Ezovir 250 mg",
    },
    {
      name: "Ezovir 500 mg",
    },
    {
      name: "Ezsimva 10/10",
    },
    {
      name: "Ezsimva 10/20",
    },
    {
      name: "Ezsimva 10/40",
    },
    {
      name: "Ezsimva 10/80",
    },
    {
      name: "Famlo 250 mg",
    },
    {
      name: "Famotidine 20 mg",
    },
    {
      name: "Famotidine 40 mg",
    },
    {
      name: "Famvir 125 mg",
    },
    {
      name: "Famvir 250 mg",
    },
    {
      name: "Famvir 500 mg",
    },
    {
      name: "Fareston 60 mg",
    },
    {
      name: "Faverin 100 mg",
    },
    {
      name: "Faverin 50 mg",
    },
    {
      name: "Favic 125 mg",
    },
    {
      name: "Favic 250 mg",
    },
    {
      name: "Favic 500 mg",
    },
    {
      name: "Febridol 500 mg",
    },
    {
      name: "Feldene 10 mg capsule, 50",
    },
    {
      name: "Feldene 20 mg capsule, 25",
    },
    {
      name: "Feldene-D 20 mg dispersible",
    },
    {
      name: "Felodil XR 10 mg modified release",
    },
    {
      name: "Felodil XR 5 mg modified release",
    },
    {
      name: "Felodur ER 10 mg modified release",
    },
    {
      name: "Felodur ER 2.5 mg modified release",
    },
    {
      name: "Felodur ER 5 mg modified release",
    },
    {
      name: "Femara 2.5 mg",
    },
    {
      name: "Femcream (Pharmacy Action) 1% vaginal cream, 35 g",
    },
    {
      name: "Femme-tab 20/100 ED",
    },
    {
      name: "Femme-Tab ED 20/100",
    },
    {
      name: "Femme-Tab ED 30/150",
    },
    {
      name: "Femolet 2.5 mg",
    },
    {
      name: "Femoston 1/10 (14 x 1 mg",
    },
    {
      name: "Femoston 2/10 (14 x 2 mg",
    },
    {
      name: "Femoston-Conti",
    },
    {
      name: "Fenac 25 mg enteric",
    },
    {
      name: "Fenac 50 mg enteric",
    },
    {
      name: "Fendex ER 10 mg modified release",
    },
    {
      name: "Fendex ER 2.5 mg modified release",
    },
    {
      name: "Fendex ER 5 mg modified release",
    },
    {
      name: "Fenocol 145 mg",
    },
    {
      name: "Fenofibrate 145 mg",
    },
    {
      name: "Fenofibrate 48 mg",
    },
    {
      name: "Fera 2.5 mg",
    },
    {
      name: "Ferriprox 1 g",
    },
    {
      name: "Ferriprox 100 mg/mL oral liquid, 250 mL",
    },
    {
      name: "Ferriprox 500 mg",
    },
    {
      name: "Ferro-f",
    },
    {
      name: "Ferro-Liquid 30 mg/mL (iron 6 mg/mL) oral liquid, 250 mL",
    },
    {
      name: "Ferro-Tab 200 mg (iron 65.7 mg)",
    },
    {
      name: "Fexorelief 120 mg",
    },
    {
      name: "Fibsol 10 mg",
    },
    {
      name: "Fibsol 20 mg",
    },
    {
      name: "Fibsol 5 mg",
    },
    {
      name: "Finasta 5 mg",
    },
    {
      name: "Finasteride 5 mg",
    },
    {
      name: "Finide 5 mg",
    },
    {
      name: "Finnacar 5 mg",
    },
    {
      name: "Finpro 5 mg",
    },
    {
      name: "Fixta 60 mg",
    },
    {
      name: "Flamazine 1% cream, 50 g",
    },
    {
      name: "Flarex 0.1% eye drops, 5 mL",
    },
    {
      name: "Flecainide (Apo) 100 mg",
    },
    {
      name: "Flecainide (Apo) 50 mg",
    },
    {
      name: "Flecainide (Sandoz) 100 mg",
    },
    {
      name: "Flecainide (Sandoz) 50 mg",
    },
    {
      name: "Flecatab 100 mg",
    },
    {
      name: "Flexidress (650941) 10 cm x 9.1 m bandage, 1",
    },
    {
      name: "Flixotide Accuhaler 250",
    },
    {
      name: "Flixotide Accuhaler 500",
    },
    {
      name: "Flixotide CFC-Free 125",
    },
    {
      name: "Flixotide CFC-Free 250",
    },
    {
      name: "Flixotide Junior Accuhaler 100",
    },
    {
      name: "Flixotide Junior Inhaler CFC-Free 50",
    },
    {
      name: "Flomaxtra 400 microgram modified release",
    },
    {
      name: "Florinef 100 microgram",
    },
    {
      name: "Fludara 10 mg",
    },
    {
      name: "Fluotex 20 mg capsule, 28",
    },
    {
      name: "Flutamin 250 mg",
    },
    {
      name: "Fluticasone and Salmeterol 125/25",
    },
    {
      name: "Fluticasone and Salmeterol 250/25",
    },
    {
      name: "Fluticasone Inhaler (Cipla) 125",
    },
    {
      name: "Fluticasone Inhaler (Cipla) 250",
    },
    {
      name: "Flutiform 125 microgram/5 microgram",
    },
    {
      name: "Flutiform 250 microgram/10 microgram",
    },
    {
      name: "Flutiform 50 microgram/5 microgram",
    },
    {
      name: "Fluvoxamine 100 mg",
    },
    {
      name: "Fluvoxamine 50 mg",
    },
    {
      name: "Fluzole 200 mg capsule, 28",
    },
    {
      name: "FML 0.1% eye drops, 5 mL",
    },
    {
      name: "Foltabs 500 microgram",
    },
    {
      name: "Fonat 70 mg",
    },
    {
      name: "Fonatplus 70 mg/140 microgram",
    },
    {
      name: "Fonatplus 70 mg/70 microgram",
    },
    {
      name: "Foradile 12 microgram powder for inhalation, 60 capsules",
    },
    {
      name: "Formet 1 g",
    },
    {
      name: "Formet 500 mg",
    },
    {
      name: "Formet 850 mg",
    },
    {
      name: "Forteo 250 microgram/mL , 2.4 mL pen device",
    },
    {
      name: "Forxiga 10 mg",
    },
    {
      name: "Fosamax Plus Once Weekly 70 mg/140 microgram",
    },
    {
      name: "Fosamax Plus Once Weekly 70 mg/70 microgram",
    },
    {
      name: "Fosetic 20/12.5",
    },
    {
      name: "Fosinopril Sodium 10 mg",
    },
    {
      name: "Fosinopril Sodium 20 mg",
    },
    {
      name: "Fosinopril Sodium HCTZ 20/12.5 mg",
    },
    {
      name: "Fosinopril Sodium/HCT 20/12.5",
    },
    {
      name: "Fosipril 10 mg",
    },
    {
      name: "Fosipril 20 mg",
    },
    {
      name: "Fosrenol 1 g chewable",
    },
    {
      name: "Fosrenol 500 mg chewable",
    },
    {
      name: "Fosrenol 750 mg chewable",
    },
    {
      name: "Frakas 50 mg",
    },
    {
      name: "Frusax 40 mg",
    },
    {
      name: "Frusemide 20 mg",
    },
    {
      name: "Frusemide 40 mg",
    },
    {
      name: "Fucidin 250 mg",
    },
    {
      name: "Fungilin 10 mg lozenge, 20",
    },
    {
      name: "Furosemide (AN) 20 mg",
    },
    {
      name: "Furosemide (AN) 40 mg",
    },
    {
      name: "Fybogel Regular 3.5 g powder for oral liquid, 30 sachets",
    },
    {
      name: "Fycompa 10 mg",
    },
    {
      name: "Fycompa 12 mg",
    },
    {
      name: "Fycompa 2 mg",
    },
    {
      name: "Fycompa 4 mg",
    },
    {
      name: "Fycompa 6 mg",
    },
    {
      name: "Fycompa 8 mg",
    },
    {
      name: "Gabitril 10 mg",
    },
    {
      name: "Gabitril 15 mg",
    },
    {
      name: "Gabitril 5 mg",
    },
    {
      name: "Galantamine MR 16 mg",
    },
    {
      name: "Galantamine MR 24 mg",
    },
    {
      name: "Galantamine MR 8 mg",
    },
    {
      name: "Galantyl MR 16 mg",
    },
    {
      name: "Galantyl MR 24 mg",
    },
    {
      name: "Galantyl MR 8 mg",
    },
    {
      name: "Galvumet 50/1000",
    },
    {
      name: "Galvumet 50/500",
    },
    {
      name: "Galvumet 50/850",
    },
    {
      name: "Galvus 50 mg",
    },
    {
      name: "Gamine XR 16 mg modified release capsule, 28",
    },
    {
      name: "Gamine XR 24 mg modified release capsule, 28",
    },
    {
      name: "Gamine XR 8 mg modified release capsule, 28",
    },
    {
      name: "Ganfort 0.3/5 eye drops, 3 mL",
    },
    {
      name: "Ganfort PF 0.3/5 eye drops, 30 x 0.4 mL unit doses",
    },
    {
      name: "Gantin 300 mg capsule, 100",
    },
    {
      name: "Gantin 400 mg capsule, 100",
    },
    {
      name: "Gantin 800 mg",
    },
    {
      name: "Gastrex 2 mg capsule, 12",
    },
    {
      name: "Gastrex 2 mg capsule, 20",
    },
    {
      name: "Gastro-Stop 2 mg capsule, 12",
    },
    {
      name: "Genox 10 mg",
    },
    {
      name: "Genox 20 mg",
    },
    {
      name: "Genteal Gel 0.3% / 0.2% eye gel, 10 g",
    },
    {
      name: "Genvoya",
    },
    {
      name: "Gilenya 250 microgram capsule, 28",
    },
    {
      name: "Gilenya 500 microgram capsule, 28",
    },
    {
      name: "Gilmat 100 mg",
    },
    {
      name: "Gilmat 400 mg",
    },
    {
      name: "Giotrif 20 mg",
    },
    {
      name: "Giotrif 30 mg",
    },
    {
      name: "Giotrif 40 mg",
    },
    {
      name: "Giotrif 50 mg",
    },
    {
      name: "Gliadel 7.7 mg implant, 8",
    },
    {
      name: "Gliclazide (Apo) 80 mg",
    },
    {
      name: "Gliclazide (GenRx) 80 mg",
    },
    {
      name: "Gliclazide MR (Apo) 30 mg modified release",
    },
    {
      name: "Gliclazide MR (Ardix) 60 mg modified release",
    },
    {
      name: "Glimepiride (AN) 1 mg",
    },
    {
      name: "Glimepiride (AN) 2 mg",
    },
    {
      name: "Glimepiride (AN) 3 mg",
    },
    {
      name: "Glimepiride (AN) 4 mg",
    },
    {
      name: "Glivec 100 mg",
    },
    {
      name: "Glivec 400 mg",
    },
    {
      name: "Glucobay 100 mg",
    },
    {
      name: "Glucobay 50 mg",
    },
    {
      name: "Glucobete 1 g",
    },
    {
      name: "Glucobete 500 mg",
    },
    {
      name: "Glucobete 850 mg",
    },
    {
      name: "Glucovance 250/1.25",
    },
    {
      name: "Glucovance 500/2.5",
    },
    {
      name: "Glucovance 500/5",
    },
    {
      name: "Glyade 80 mg",
    },
    {
      name: "Glyade MR 30 mg modified release",
    },
    {
      name: "Glybosay 100 mg",
    },
    {
      name: "Glybosay 50 mg",
    },
    {
      name: "Glycerol for Adults (Petrus) 2.8 g suppository, 12",
    },
    {
      name: "Glycerol for Children (Petrus) 1.4 g suppository, 12",
    },
    {
      name: "Glycerol for Infants (Petrus) 700 mg suppository, 12",
    },
    {
      name:
        "Glycine500 containing 500 mg glycine powder for oral liquid, 30 x 4 g sachets",
    },
    {
      name: "Glycosade powder for oral liquid, 30 x 60 g sachets",
    },
    {
      name: "Glyxambi 10 mg/5 mg",
    },
    {
      name: "Glyxambi 25 mg/5 mg",
    },
    {
      name: "Goanna Heat cream, 100 g",
    },
    {
      name: "Gopten 1 mg capsule, 28",
    },
    {
      name: "Gopten 2 mg capsule, 28",
    },
    {
      name: "Gopten 4 mg capsule, 28",
    },
    {
      name: "Gopten 500 microgram capsule, 28",
    },
    {
      name: "Grisovin 125 mg",
    },
    {
      name: "Grisovin 500 mg",
    },
    {
      name: "Gynotril 2.5 mg",
    },
    {
      name: "Harvoni",
    },
    {
      name: "Hepsera 10 mg",
    },
    {
      name: "Hequinel 200 mg",
    },
    {
      name: "Hiprex 1 g",
    },
    {
      name: "Hydopa 250 mg",
    },
    {
      name: "Hydrea 500 mg capsule, 100",
    },
    {
      name: "Hydrocortisone (Mylan) 20 mg",
    },
    {
      name: "Hydrocortisone (Mylan) 4 mg",
    },
    {
      name: "Hydrocortisone (Pharmacy Action) 1% cream, 30 g",
    },
    {
      name: "Hydroxychloroquine (AN) 200 mg",
    },
    {
      name: "Hydroxychloroquine (GH) 200 mg",
    },
    {
      name: "Hydroxychloroquine Sulfate (Apo) 200 mg",
    },
    {
      name: "Hygroton 25 mg",
    },
    {
      name: "Hylo-Forte 0.2% eye drops, 10 mL",
    },
    {
      name: "Hylo-Fresh 0.1% eye drops, 10 mL",
    },
    {
      name: "Ibavyr 400 mg",
    },
    {
      name: "Ibavyr 600 mg",
    },
    {
      name: "Ibrance 100 mg capsule, 21",
    },
    {
      name: "Ibrance 125 mg capsule, 21",
    },
    {
      name: "Ibrance 75 mg capsule, 21",
    },
    {
      name: "Ibuprofen (Apo) 400 mg",
    },
    {
      name: "Idaprex 2 mg",
    },
    {
      name: "Idaprex 4 mg",
    },
    {
      name: "Idaprex 8 mg",
    },
    {
      name: "Idaprex Combi 4/1.25",
    },
    {
      name: "Ikorel 10 mg",
    },
    {
      name: "Ikorel 20 mg",
    },
    {
      name: "Ikotab 10 mg",
    },
    {
      name: "Ikotab 20 mg",
    },
    {
      name: "Imatinib (AN) 100 mg capsule, 60",
    },
    {
      name: "Imatinib (AN) 400 mg capsule, 30",
    },
    {
      name: "Imatinib Adult (Cipla) 100 mg capsule, 60",
    },
    {
      name: "Imatinib Adult (Cipla) 400 mg capsule, 30",
    },
    {
      name: "Imazan 50 mg",
    },
    {
      name: "Imbruvica 140 mg capsule, 120",
    },
    {
      name: "Imbruvica 140 mg capsule, 90",
    },
    {
      name: "Imdur Durules 120 mg modified release",
    },
    {
      name: "Imdur Durules 60 mg modified release",
    },
    {
      name: "Imigran 20 mg/actuation nasal spray, 2 x 1 actuation",
    },
    {
      name: "Imigran 50 mg",
    },
    {
      name: "Imigran FDT Tablet (fast disintegrating) 50 mg (as succinate), 2",
    },
    {
      name: "Imigran FDT Tablet 50 mg (base) (fast disintegrating), 4",
    },
    {
      name: "Imiquimod (Apo) 5% cream, 12 x 250 mg sachets",
    },
    {
      name: "Implanon NXT 68 mg implant, 1",
    },
    {
      name: "Improvil 28 day",
    },
    {
      name: "Imuran 25 mg",
    },
    {
      name: "Imuran 50 mg",
    },
    {
      name:
        "Incruse Ellipta 62.5 microgram powder for inhalation, 30 actuations",
    },
    {
      name: "Indapamide Hemihydrate 2.5 mg",
    },
    {
      name: "Indapamide Hemihydrate 1.5 mg",
    },
    {
      name: "Inderal 10 mg",
    },
    {
      name: "Inderal 40 mg",
    },
    {
      name: "Indocid 100 mg suppository, 20",
    },
    {
      name: "Indocid 25 mg capsule, 50",
    },
    {
      name: "Indosyl Combi 4/1.25",
    },
    {
      name: "Indosyl Mono 2 mg",
    },
    {
      name: "Indosyl Mono 4 mg",
    },
    {
      name: "Indosyl Mono 8 mg",
    },
    {
      name: "Inlyta 1 mg",
    },
    {
      name: "Inlyta 5 mg",
    },
    {
      name: "Inpler 25 mg",
    },
    {
      name: "Inpler 50 mg",
    },
    {
      name: "Insig 2.5 mg",
    },
    {
      name: "Inspra 25 mg",
    },
    {
      name: "Inspra 50 mg",
    },
    {
      name: "Intal CFC-Free 1 mg/actuation inhalation, 200 actuations",
    },
    {
      name: "Intal Forte CFC-Free 5 mg/actuation inhalation, 112 actuations",
    },
    {
      name: "Intelence 200 mg",
    },
    {
      name: "Intrasite (7313) gel, 25 g",
    },
    {
      name: "Intuniv 1 mg modified release",
    },
    {
      name: "Intuniv 2 mg modified release",
    },
    {
      name: "Intuniv 3 mg modified release",
    },
    {
      name: "Intuniv 4 mg modified release",
    },
    {
      name: "Invega 3 mg modified release",
    },
    {
      name: "Invega 6 mg modified release",
    },
    {
      name: "Invega 9 mg modified release",
    },
    {
      name: "Invirase 500 mg",
    },
    {
      name: "Inza 250 mg",
    },
    {
      name: "Inza 500 mg",
    },
    {
      name: "Iopidine 0.5% eye drops, 10 mL",
    },
    {
      name:
        "Ipratrin Adult Uni-Dose 500 microgram/mL inhalation solution, 30 x 1 mL ampoules",
    },
    {
      name:
        "Ipratrin Uni-Dose 250 microgram/mL inhalation solution, 30 x 1 mL ampoules",
    },
    {
      name:
        "Ipratropium Bromide (Apo) 250 microgram/mL inhalation solution, 30 x 1 mL ampoules",
    },
    {
      name:
        "Ipratropium Bromide (Apo) 500 microgram/mL inhalation solution, 30 x 1 mL ampoules",
    },
    {
      name: "Iptam 50 mg",
    },
    {
      name: "Irbesartan 150 mg",
    },
    {
      name: "Irbesartan 300 mg",
    },
    {
      name: "Irbesartan 75 mg",
    },
    {
      name: "Irbesartan HCT 150/12.5",
    },
    {
      name: "Irbesartan HCT 300/12.5",
    },
    {
      name: "Irbesartan HCT 300/25",
    },
    {
      name: "Iressa 250 mg",
    },
    {
      name: "Iscover 75 mg",
    },
    {
      name: "Isentress 100 mg chewable",
    },
    {
      name: "Isentress 25 mg chewable",
    },
    {
      name: "Isentress 400 mg",
    },
    {
      name: "Isentress HD 600 mg",
    },
    {
      name: "Isobide MR 60 mg modified release",
    },
    {
      name: "Isomonit 60 mg modified release",
    },
    {
      name: "Isoniazid (Arrow) 100 mg",
    },
    {
      name: "Isoptin 80 mg",
    },
    {
      name: "Isoptin SR 180 mg modified release",
    },
    {
      name: "Isoptin SR 240 mg modified release",
    },
    {
      name: "Isopto Carpine 1% eye drops, 15 mL",
    },
    {
      name: "Isopto Carpine 2% eye drops, 15 mL",
    },
    {
      name: "Isopto Carpine 4% eye drops, 15 mL",
    },
    {
      name: "Isordil 5 mg sublingual",
    },
    {
      name: "Isosorbide Mononitrate 60 mg modified release",
    },
    {
      name: "Isotretinoin 10 mg capsule, 60",
    },
    {
      name: "Isotretinoin 20 mg capsule, 60",
    },
    {
      name: "Itracap 100 mg capsule, 60",
    },
    {
      name: "Itraconazole (Apo) 100 mg capsule, 60",
    },
    {
      name: "Itranox 100 mg capsule, 60",
    },
    {
      name: "Ivabradine (Apo) 5 mg",
    },
    {
      name: "Ivabradine (Apo) 7.5 mg",
    },
    {
      name: "Jadenu 180 mg",
    },
    {
      name: "Jadenu 360 mg",
    },
    {
      name: "Jadenu 90 mg",
    },
    {
      name: "Jakavi 10 mg",
    },
    {
      name: "Jakavi 15 mg",
    },
    {
      name: "Jakavi 20 mg",
    },
    {
      name: "Jakavi 5 mg",
    },
    {
      name: "Janumet 50/1000",
    },
    {
      name: "Janumet 50/500",
    },
    {
      name: "Janumet 50/850",
    },
    {
      name: "Janumet XR 100/1000 modified release",
    },
    {
      name: "Janumet XR 50/1000 modified release",
    },
    {
      name: "Januvia 100 mg",
    },
    {
      name: "Januvia 25 mg",
    },
    {
      name: "Januvia 50 mg",
    },
    {
      name: "Jardiamet 12.5 mg/1000 mg",
    },
    {
      name: "Jardiamet 12.5 mg/500 mg",
    },
    {
      name: "Jardiamet 5 mg/1000 mg",
    },
    {
      name: "Jardiamet 5 mg/500 mg",
    },
    {
      name: "Jardiance 10 mg",
    },
    {
      name: "Jardiance 25 mg",
    },
    {
      name: "Jene-35 ED",
    },
    {
      name: "Jinarc (28 x 15 mg",
    },
    {
      name: "Jinarc (28 x 30 mg",
    },
    {
      name: "Juliet-35 ED",
    },
    {
      name: "Juluca",
    },
    {
      name: "Kaletra 100/25",
    },
    {
      name: "Kaletra 200/50",
    },
    {
      name: "Kaletra oral liquid, 60 mL",
    },
    {
      name: "Kalixocin 250 mg",
    },
    {
      name: "Kalydeco 150 mg",
    },
    {
      name: "Kalydeco 50 mg granules, 4 x 14 sachets",
    },
    {
      name: "Kalydeco 75 mg granules, 4 x 14 sachets",
    },
    {
      name: "Kaptan 100 mg",
    },
    {
      name: "Kaptan 200 mg",
    },
    {
      name: "Kaptan 25 mg",
    },
    {
      name: "Kaptan 300 mg",
    },
    {
      name: "Karlor CD 375 mg modified release",
    },
    {
      name: "Karvea 150 mg",
    },
    {
      name: "Karvea 300 mg",
    },
    {
      name: "Karvea 75 mg",
    },
    {
      name: "Karvezide 150/12.5",
    },
    {
      name: "Karvezide 300/12.5",
    },
    {
      name: "Karvezide 300/25",
    },
    {
      name: "Kenacomb Otic ear drops, 7.5 mL",
    },
    {
      name: "Kenacomb Otic ointment, 5 g",
    },
    {
      name: "Keppra 1 g",
    },
    {
      name: "Keppra 100 mg/mL oral liquid, 300 mL",
    },
    {
      name: "Keppra 250 mg",
    },
    {
      name: "Keppra 500 mg",
    },
    {
      name: "Kerron 1 g",
    },
    {
      name: "Kerron 100 mg/mL oral liquid, 300 mL",
    },
    {
      name: "Kerron 250 mg",
    },
    {
      name: "Kerron 500 mg",
    },
    {
      name: "Kevtam 1 g",
    },
    {
      name: "Kevtam 250 mg",
    },
    {
      name: "Kevtam 500 mg",
    },
    {
      name: "Kindergen powder for oral liquid, 400 g",
    },
    {
      name: "Kinson",
    },
    {
      name: "Kisqali 200 mg",
    },
    {
      name: "Kivexa",
    },
    {
      name: "Klacid 250 mg",
    },
    {
      name: "Klacid 250 mg/5 mL powder for oral liquid, 50 mL",
    },
    {
      name: "Ksart HCT 150/12.5",
    },
    {
      name: "Ksart HCT 300/12.5",
    },
    {
      name: "Ksart HCT 300/25",
    },
    {
      name: "Kuvan 100 mg soluble",
    },
    {
      name: "Kytril 2 mg",
    },
    {
      name: "Kytril 3 mg/3 mL , 3 mL ampoule",
    },
    {
      name: "Laila-35 ED",
    },
    {
      name: "Lamictal 100 mg",
    },
    {
      name: "Lamictal 200 mg",
    },
    {
      name: "Lamictal 25 mg",
    },
    {
      name: "Lamictal 5 mg",
    },
    {
      name: "Lamictal 50 mg",
    },
    {
      name: "Lamisil 1% cream, 15 g",
    },
    {
      name: "Lamisil 250 mg",
    },
    {
      name: "Lamisil Dermgel 1% gel, 15 g",
    },
    {
      name: "Lamitan 100 mg",
    },
    {
      name: "Lamitan 200 mg",
    },
    {
      name: "Lamitan 25 mg",
    },
    {
      name: "Lamitan 50 mg",
    },
    {
      name: "Lamivudine (Alphapharm) 150 mg",
    },
    {
      name: "Lamivudine (Alphapharm) 300 mg",
    },
    {
      name: "Lamivudine and Zidovudine 150/300 (Alphapharm)",
    },
    {
      name: "Lamotrigine (AN) 100 mg",
    },
    {
      name: "Lamotrigine (AN) 200 mg",
    },
    {
      name: "Lamotrigine (AN) 25 mg",
    },
    {
      name: "Lamotrigine (AN) 50 mg",
    },
    {
      name: "Lanoxin 250 microgram",
    },
    {
      name: "Lanoxin Paediatric Elixir 50 microgram/mL oral liquid, 60 mL",
    },
    {
      name: "Lanoxin PG 62.5 microgram",
    },
    {
      name: "Lanpro 0.005% eye drops, 2.5 mL",
    },
    {
      name: "Lansoprazole (Apo) 30 mg enteric capsule, 28",
    },
    {
      name: "Lansoprazole ODT (Apo) 15 mg orally disintegrating",
    },
    {
      name: "Lansoprazole ODT (Apo) 30 mg orally disintegrating",
    },
    {
      name: "Lantus 100 units/mL , 5 x 3 mL cartridges",
    },
    {
      name: "Lantus Solostar 100 units/mL , 5 x 3 mL cartridges",
    },
    {
      name: "Lanvis 40 mg",
    },
    {
      name: "Lanzopran 30 mg enteric capsule, 28",
    },
    {
      name: "Largactil 10 mg",
    },
    {
      name: "Largactil 100 mg",
    },
    {
      name: "Largactil 25 mg",
    },
    {
      name: "Largactil 50 mg/2 mL , 10 x 2 mL ampoules",
    },
    {
      name: "Largactil Syrup 5 mg/mL oral liquid, 100 mL",
    },
    {
      name: "Lasix 10 mg/mL oral liquid, 30 mL",
    },
    {
      name: "Lasix 20 mg/2 mL , 5 x 2 mL ampoules",
    },
    {
      name: "Lasix 40 mg",
    },
    {
      name: "Lasix M 20 mg",
    },
    {
      name: "Latanoprost (Actavis) 0.005% eye drops, 2.5 mL",
    },
    {
      name: "Latanoprost (Apo) 0.005% eye drops, 2.5 mL",
    },
    {
      name: "Latanoprost (Sandoz) 0.005% eye drops, 2.5 mL",
    },
    {
      name: "Latanoprost/Timolol 50/5 (Apo) eye drops, 2.5 mL",
    },
    {
      name: "Latanoprost/Timolol 50/5 (Sandoz) eye drops, 2.5 mL",
    },
    {
      name: "Latuda 40 mg",
    },
    {
      name: "Latuda 80 mg",
    },
    {
      name: "Lax-Sachets powder for oral liquid, 30 sachets",
    },
    {
      name: "Lax-Tab 5 mg enteric",
    },
    {
      name: "Laxacon powder for oral liquid, 30 sachets",
    },
    {
      name: "Laxative with Senna (Chemists' Own)",
    },
    {
      name: "Laxative with Senna (Pharmacy Action)",
    },
    {
      name: "Leflunomide (AN) 10 mg",
    },
    {
      name: "Leflunomide (AN) 20 mg",
    },
    {
      name: "Leflunomide (Apotex) 10 mg",
    },
    {
      name: "Leflunomide (Apotex) 20 mg",
    },
    {
      name: "Leflunomide (Generic Health) 10 mg",
    },
    {
      name: "Leflunomide (Generic Health) 20 mg",
    },
    {
      name: "Leflunomide (Sandoz) 10 mg",
    },
    {
      name: "Leflunomide (Sandoz) 20 mg",
    },
    {
      name: "Lenest 20 ED",
    },
    {
      name: "Lenest 30 ED (21 x 150 microgram/30 microgram",
    },
    {
      name: "Lenest ED 30 ED",
    },
    {
      name: "Lengout 500 microgram",
    },
    {
      name: "Lenvima 10 mg capsule, 30",
    },
    {
      name: "Lenvima 4 mg capsule, 30",
    },
    {
      name: "Lercadip 10 mg",
    },
    {
      name: "Lercadip 20 mg",
    },
    {
      name: "Lercan 10 mg",
    },
    {
      name: "Lercan 20 mg",
    },
    {
      name: "Lercanidipine (Apotex) 10 mg",
    },
    {
      name: "Lercanidipine (Apotex) 20 mg",
    },
    {
      name: "Lercanidipine (Blooms The Chemist) 10 mg",
    },
    {
      name: "Lercanidipine (Blooms The Chemist) 20 mg",
    },
    {
      name: "Lercanidipine (BTC) 10 mg",
    },
    {
      name: "Lercanidipine (BTC) 20 mg",
    },
    {
      name: "Lercanidipine Hydrochloride (Apo) 10 mg",
    },
    {
      name: "Lercanidipine Hydrochloride (Apo) 20 mg",
    },
    {
      name: "Lercanidipine Hydrochloride (Sandoz) 10 mg",
    },
    {
      name: "Lercanidipine Hydrochloride (Sandoz) 20 mg",
    },
    {
      name: "Lescol XL 80 mg modified release",
    },
    {
      name: "Letroz 2.5 mg",
    },
    {
      name: "Letrozole (AN) 2.5 mg",
    },
    {
      name: "Letrozole (Apo) 2.5 mg",
    },
    {
      name: "Letrozole (Apotex) 2.5 mg",
    },
    {
      name: "Letrozole (FBM) 2.5 mg",
    },
    {
      name: "Letrozole (GH) 2.5 mg",
    },
    {
      name: "Letrozole (Pharmacor) 2.5 mg",
    },
    {
      name: "Letrozole (Sandoz) 2.5 mg",
    },
    {
      name: "Leucovorin Calcium (DBL) 15 mg",
    },
    {
      name:
        "Leucovorin Calcium (Pfizer (Perth)) 100 mg/10 mL , 10 x 10 mL ampoules",
    },
    {
      name:
        "Leucovorin Calcium (Pfizer (Perth)) 50 mg/5 mL , 10 x 5 mL ampoules",
    },
    {
      name: "Leukeran 2 mg",
    },
    {
      name: "Levactam 1 g",
    },
    {
      name: "Levactam 250 mg",
    },
    {
      name: "Levactam 500 mg",
    },
    {
      name: "Levecetam 1 g",
    },
    {
      name: "Levecetam 250 mg",
    },
    {
      name: "Levecetam 500 mg",
    },
    {
      name: "Levemir Flexpen 100 units/mL , 5 x 3 mL cartridges",
    },
    {
      name: "Levemir Penfill 100 units/mL , 5 x 3 mL cartridges",
    },
    {
      name: "Levetiracetam (AFT) 100 mg/mL oral liquid, 300 mL",
    },
    {
      name: "Levetiracetam (AN) 1 g",
    },
    {
      name: "Levetiracetam (AN) 250 mg",
    },
    {
      name: "Levetiracetam (AN) 500 mg",
    },
    {
      name: "Levetiracetam (Apo) 100 mg/mL oral liquid, 300 mL",
    },
    {
      name: "Levi 1 g",
    },
    {
      name: "Levi 250 mg",
    },
    {
      name: "Levi 500 mg",
    },
    {
      name: "Levitra 10 mg",
    },
    {
      name: "Levitra 20 mg",
    },
    {
      name: "Levlen ED",
    },
    {
      name: "Levlen ED (21 x 150 microgram/30 microgram",
    },
    {
      name: "Lexam 10 mg",
    },
    {
      name: "Lexam 20 mg",
    },
    {
      name: "Lexapro 10 mg",
    },
    {
      name: "Lexapro 20 mg",
    },
    {
      name: "Lexapro 20 mg/mL oral liquid, 15 mL",
    },
    {
      name: "Lexotan 3 mg",
    },
    {
      name: "Lexotan 6 mg",
    },
    {
      name: "Lioresal 10 mg",
    },
    {
      name: "Lioresal 25 mg",
    },
    {
      name: "Lipex 10 mg",
    },
    {
      name: "Lipex 20 mg",
    },
    {
      name: "Lipex 40 mg",
    },
    {
      name: "Lipex 80 mg",
    },
    {
      name: "Lipidil 145 mg",
    },
    {
      name: "Lipidil 48 mg",
    },
    {
      name: "Lipigem 600 mg",
    },
    {
      name: "Lipitor 10 mg",
    },
    {
      name: "Lipitor 20 mg",
    },
    {
      name: "Lipitor 40 mg",
    },
    {
      name: "Lipitor 80 mg",
    },
    {
      name: "Lipostat 10 mg",
    },
    {
      name: "Lipostat 20 mg",
    },
    {
      name: "Lipostat 40 mg",
    },
    {
      name: "Lipostat 80 mg",
    },
    {
      name: "Lisinopril (AN) 10 mg",
    },
    {
      name: "Lisinopril (AN) 20 mg",
    },
    {
      name: "Lisinopril (AN) 5 mg",
    },
    {
      name: "Lithicarb 250 mg",
    },
    {
      name: "Locilan 28",
    },
    {
      name: "Loette",
    },
    {
      name: "Lofenoxal",
    },
    {
      name: "Logem 100 mg",
    },
    {
      name: "Logem 200 mg",
    },
    {
      name: "Logem 25 mg",
    },
    {
      name: "Logem 50 mg",
    },
    {
      name: "Logicin Sinus 60 mg",
    },
    {
      name: "Logynon",
    },
    {
      name: "Logynon ED (6 x 50 microgram/30 microgram",
    },
    {
      name: "Lomotil",
    },
    {
      name: "Loniten 10 mg",
    },
    {
      name: "Lonsurf 15/6.14",
    },
    {
      name: "Lonsurf 20/8.19",
    },
    {
      name: "Lophlex oral liquid: powder for, 30 x 27.8 g sachets",
    },
    {
      name: "Lopresor 100 Tablet 100 mg, 60",
    },
    {
      name: "Lopresor 50 Tablet 50 mg, 100",
    },
    {
      name: "Lorano 10 mg",
    },
    {
      name: "Lorastyne (Pharmacy Action) 10 mg",
    },
    {
      name: "Lorstat 10 mg",
    },
    {
      name: "Lorstat 20 mg",
    },
    {
      name: "Lorstat 40 mg",
    },
    {
      name: "Lorstat 80 mg",
    },
    {
      name: "Losec 10 mg enteric",
    },
    {
      name: "Losec 20 mg enteric",
    },
    {
      name: "Lovan 20 mg capsule, 28",
    },
    {
      name: "Lovan Dispersible 20 mg",
    },
    {
      name: "Lovir 200 mg",
    },
    {
      name: "Loxalate 10 mg",
    },
    {
      name: "Loxalate 20 mg",
    },
    {
      name: "Loxip 500 mg",
    },
    {
      name: "Loxip 750 mg",
    },
    {
      name: "Lozanoc 50 mg capsule, 60",
    },
    {
      name: "LPV 250 mg capsule, 50",
    },
    {
      name: "LPV 500 mg capsule, 50",
    },
    {
      name: "Lubri-Gel, 100 g",
    },
    {
      name: "Lukair 4 mg chewable",
    },
    {
      name: "Lukair 5 mg chewable",
    },
    {
      name: "Lumigan 0.03% eye drops, 3 mL",
    },
    {
      name: "Lumigan PF 0.03% eye drops, 30 x 0.4 mL unit doses",
    },
    {
      name: "Lumin 10 mg",
    },
    {
      name: "Lumin 20 mg",
    },
    {
      name: "Lunava 10 mg",
    },
    {
      name: "Lunava 20 mg",
    },
    {
      name: "Luvox 100 mg",
    },
    {
      name: "Luvox 50 mg",
    },
    {
      name: "Lycinate 600 microgram sublingual",
    },
    {
      name: "Lyclear Scabies 5% cream, 30 g",
    },
    {
      name: "Lypralin 150 mg capsule, 56",
    },
    {
      name: "Lypralin 25 mg capsule, 56",
    },
    {
      name: "Lypralin 300 mg capsule, 56",
    },
    {
      name: "Lypralin 75 mg capsule, 56",
    },
    {
      name: "Lyrica 150 mg capsule, 56",
    },
    {
      name: "Lyrica 25 mg capsule, 56",
    },
    {
      name: "Lyrica 300 mg capsule, 56",
    },
    {
      name: "Lyrica 75 mg capsule, 56",
    },
    {
      name: "Lyzalon 150 mg capsule, 56",
    },
    {
      name: "Lyzalon 25 mg capsule, 56",
    },
    {
      name: "Lyzalon 300 mg capsule, 56",
    },
    {
      name: "Lyzalon 75 mg capsule, 56",
    },
    {
      name: "Madopar 125",
    },
    {
      name: "Madopar 125 capsule, 100",
    },
    {
      name: "Madopar 200/50",
    },
    {
      name: "Madopar 200/50 capsule, 100",
    },
    {
      name: "Madopar 62.5 capsule, 100",
    },
    {
      name: "Madopar HBS 100/25 modified release capsule, 100",
    },
    {
      name: "Madopar Rapid 125 dispersible",
    },
    {
      name: "Madopar Rapid 62.5 dispersible",
    },
    {
      name: "Mag-A (Amcal) 500 mg (magnesium 37.4 mg)",
    },
    {
      name: "Mag-Sup 500 mg (magnesium 37.4 mg)",
    },
    {
      name: "Magicul 400 mg",
    },
    {
      name: "Magmin 500 mg (magnesium 37.4 mg)",
    },
    {
      name: "Magnesium (Pharmacy Care) 500 mg (magnesium 37.4 mg)",
    },
    {
      name: "Malarone 250/100",
    },
    {
      name: "Malean 10 mg",
    },
    {
      name: "Malean 20 mg",
    },
    {
      name: "Malean 5 mg",
    },
    {
      name: "Marevan 1 mg",
    },
    {
      name: "Marevan 3 mg",
    },
    {
      name: "Marevan 5 mg",
    },
    {
      name: "Marvelon 28",
    },
    {
      name: "Mavenclad 10 mg",
    },
    {
      name: "Maviret",
    },
    {
      name: "Maxalt 10 mg wafer, 2",
    },
    {
      name: "Maxamox 1 g",
    },
    {
      name: "Maxamox 500 mg/5 mL powder for oral liquid, 100 mL",
    },
    {
      name: "Maxidex 0.1% eye drops, 5 mL",
    },
    {
      name: "Maxolon 10 mg",
    },
    {
      name: "Maxor 20 mg enteric capsule, 30",
    },
    {
      name: "Mebeverine (Apo) 135 mg",
    },
    {
      name: "Mekinist 2 mg",
    },
    {
      name: "Mekinist 500 microgram",
    },
    {
      name: "Melizide 5 mg",
    },
    {
      name: "Melobic 15 mg capsule, 30",
    },
    {
      name: "Melobic 7.5 mg capsule, 30",
    },
    {
      name: "Meloxiauro 15 mg",
    },
    {
      name: "Meloxiauro 7.5 mg",
    },
    {
      name: "Meloxibell 15 mg",
    },
    {
      name: "Meloxibell 7.5 mg",
    },
    {
      name: "Meloxicam (AN) 15 mg",
    },
    {
      name: "Meloxicam (AN) 7.5 mg",
    },
    {
      name: "Memantine (Apo) 10 mg",
    },
    {
      name: "Memantine (Apo) 20 mg",
    },
    {
      name: "Memantine 10 mg",
    },
    {
      name: "Memantine 20 mg",
    },
    {
      name: "Memanxa 10 mg",
    },
    {
      name: "Mesasal 250 mg enteric",
    },
    {
      name: "Mestinon 10 mg",
    },
    {
      name: "Mestinon 60 mg",
    },
    {
      name: "Mestinon Timespan 180 mg modified release",
    },
    {
      name: "Metex XR 1 g modified release",
    },
    {
      name: "Metex XR 500 mg modified release",
    },
    {
      name: "Metformin (Apo) 1 g",
    },
    {
      name: "Metformin (Apo) 500 mg",
    },
    {
      name: "Metformin (Apo) 850 mg",
    },
    {
      name: "Metformin 1g",
    },
    {
      name: "Metformin XR 1 g modified release",
    },
    {
      name: "Metformin XR 500 mg modified release",
    },
    {
      name: "Methoblastin 10 mg",
    },
    {
      name: "Methoblastin 2.5 mg",
    },
    {
      name: "Methopt Tears 0.5% eye drops, 15 mL",
    },
    {
      name: "Metoclopramide 10 mg",
    },
    {
      name: "Metoprolol 100 mg, 60",
    },
    {
      name: "Metoprolol 50 mg, 100",
    },
    {
      name: "Metoprolol Tartrate (AN) 100 mg",
    },
    {
      name: "Metoprolol Tartrate (AN) 50 mg",
    },
    {
      name: "Metoprolol Tartrate (Apo) 100 mg",
    },
    {
      name: "Metoprolol Tartrate (Apo) 50 mg",
    },
    {
      name: "Metrogyl 200 mg",
    },
    {
      name: "Metrogyl 400 mg",
    },
    {
      name: "Metrol 100 Tablet 100 mg, 60",
    },
    {
      name: "Metrol 50 Tablet 50 mg, 100",
    },
    {
      name: "Metrol-XL 190 Tablet 190 mg (controlled release), 30",
    },
    {
      name: "Metrol-XL 23.75 Tablet 23.75 mg (controlled release), 15",
    },
    {
      name: "Metrol-XL 47.5 Tablet 47.5 mg (controlled release), 30",
    },
    {
      name: "Metrol-XL 95 Tablet 95 mg (controlled release), 30",
    },
    {
      name: "Metronide 200 mg",
    },
    {
      name: "Metronide 400 mg",
    },
    {
      name: "Mezavant 1.2 g modified release",
    },
    {
      name: "Miacalcic 100 units/mL , 5 x 1 mL ampoules",
    },
    {
      name: "Micardis 40 mg",
    },
    {
      name: "Micardis 80 mg",
    },
    {
      name: "Micardis Plus 40/12.5",
    },
    {
      name: "Micardis Plus 80/12.5",
    },
    {
      name: "Micardis Plus 80/25",
    },
    {
      name: "Micolette Micro enema, 12 x 5 mL",
    },
    {
      name: "Micolette Micro enema, 4 x 5 mL",
    },
    {
      name: "Microdacyn Wound Care irrigation solution, 250 mL",
    },
    {
      name: "Microgynon 20 ED",
    },
    {
      name: "Microgynon 30",
    },
    {
      name: "Microgynon 50",
    },
    {
      name: "Microgynon 50 ED (21 x 125 microgram/50 microgram",
    },
    {
      name: "Microlevlen ED",
    },
    {
      name: "Microlut",
    },
    {
      name: "Microlut 30 microgram",
    },
    {
      name: "Micronelle 20 ED Tablet",
    },
    {
      name: "Micronelle 30 ED",
    },
    {
      name: "Micronelle 30 ED (21 x 150 microgram/30 microgram",
    },
    {
      name: "Micronor",
    },
    {
      name: "Midazolam (Pfizer (Perth)) 5 mg/mL , 10 x 1 mL ampoules",
    },
    {
      name: "Milivin OD 15 mg orally disintegrating",
    },
    {
      name: "Milivin OD 30 mg orally disintegrating",
    },
    {
      name: "Milivin OD 45 mg orally disintegrating",
    },
    {
      name: "Minax 100 Tablet 100 mg, 60",
    },
    {
      name: "Minax 50 Tablet 50 mg, 100",
    },
    {
      name: "Minax XL Tablet 190 mg (controlled release), 30",
    },
    {
      name: "Minax XL Tablet 23.75 mg (controlled release), 15",
    },
    {
      name: "Minax XL Tablet 47.5 mg (controlled release), 30",
    },
    {
      name: "Minax XL Tablet 95 mg (controlled release), 30",
    },
    {
      name: "Minidiab 5 mg",
    },
    {
      name: "Minipress 1 mg",
    },
    {
      name: "Minipress 2 mg",
    },
    {
      name: "Minipress 5 mg",
    },
    {
      name: "Minirin 10 microgram/actuation nasal spray",
    },
    {
      name: "Minirin 100 microgram/mL nasal drops, 2.5 mL",
    },
    {
      name: "Minirin 200 microgram",
    },
    {
      name: "Minirin Melt 120 microgram sublingual wafer, 30",
    },
    {
      name: "Minirin Melt 240 microgram sublingual wafer, 30",
    },
    {
      name: "Minitran 10 mg/24 hours patch, 30",
    },
    {
      name: "Minitran 15 mg/24 hours patch, 30",
    },
    {
      name: "Minitran 5 mg/24 hours patch, 30",
    },
    {
      name: "Minomycin 50 mg",
    },
    {
      name: "Minulet ED",
    },
    {
      name: "Mirtanza 15 mg",
    },
    {
      name: "Mirtanza 30 mg",
    },
    {
      name: "Mirtanza 45 mg",
    },
    {
      name: "Mirtazapine (AN) 15 mg",
    },
    {
      name: "Mirtazapine (AN) 30 mg",
    },
    {
      name: "Mirtazapine (AN) 45 mg",
    },
    {
      name: "Mirtazon 30 mg",
    },
    {
      name: "Mirtazon 45 mg",
    },
    {
      name: "Mistrom 100 mg",
    },
    {
      name: "Mistrom 50 mg",
    },
    {
      name: "Mizart 40 mg",
    },
    {
      name: "Mizart 80 mg",
    },
    {
      name: "Mizart HCT 40/12.5 mg",
    },
    {
      name: "Mizart HCT 80/12.5 mg",
    },
    {
      name: "Mizart HCT 80/25 mg",
    },
    {
      name: "Mobic 15 mg",
    },
    {
      name: "Mobic 15 mg capsule, 30",
    },
    {
      name: "Mobic 7.5 mg",
    },
    {
      name: "Mobic 7.5 mg capsule, 30",
    },
    {
      name: "Mobilis 10 mg capsule, 50",
    },
    {
      name: "Mobilis 20 mg capsule, 25",
    },
    {
      name: "Mobilis D 10 mg dispersible",
    },
    {
      name: "Mobilis D 20 mg dispersible",
    },
    {
      name: "Moclobemide (AN) 150 mg",
    },
    {
      name: "Moclobemide (AN) 300 mg",
    },
    {
      name: "Moclobemide (Apo) 150 mg",
    },
    {
      name: "Moclobemide (Apo) 300 mg",
    },
    {
      name: "Moclobemide (GenRx) 300 mg",
    },
    {
      name: "Moclobemide (Sandoz) 150 mg",
    },
    {
      name: "Moclobemide (Sandoz) 300 mg",
    },
    {
      name: "Modafin 100 mg",
    },
    {
      name: "Moduretic",
    },
    {
      name: "Mogadon 5 mg",
    },
    {
      name: "Mohexal 150 mg",
    },
    {
      name: "Molaxole powder for oral liquid, 30 sachets",
    },
    {
      name: "Momasone 0.1% cream, 15 g",
    },
    {
      name: "Momasone 0.1% lotion, 30 mL",
    },
    {
      name: "Momasone 0.1% ointment, 15 g",
    },
    {
      name: "Momasone 0.1% ointment, 50 g",
    },
    {
      name: "Momex SR 10 mg modified release",
    },
    {
      name: "Momex SR 100 mg modified release",
    },
    {
      name: "Momex SR 30 mg modified release",
    },
    {
      name: "Momex SR 60 mg modified release",
    },
    {
      name: "Monace 10 mg",
    },
    {
      name: "Monace 20 mg",
    },
    {
      name: "Monodur Durules 120 mg modified release",
    },
    {
      name: "Monodur Durules 60 mg modified release",
    },
    {
      name: "Monofeme",
    },
    {
      name: "Monofeme (21 x 150 microgram/30 microgram",
    },
    {
      name: "Monogen powder for oral liquid, 400 g",
    },
    {
      name: "Montelair 4 mg chewable",
    },
    {
      name: "Montelair 5 mg chewable",
    },
    {
      name: "Montelukast (AN) 4 mg chewable",
    },
    {
      name: "Montelukast (AN) 5 mg chewable",
    },
    {
      name: "Motilium 10 mg",
    },
    {
      name: "Movalis 15 mg",
    },
    {
      name: "Movalis 15 mg capsule, 30",
    },
    {
      name: "Movalis 7.5 mg",
    },
    {
      name: "Movalis 7.5 mg capsule, 30",
    },
    {
      name: "Movicol powder for oral liquid, 30 sachets",
    },
    {
      name: "Movox 100 mg",
    },
    {
      name: "Movox 50 mg",
    },
    {
      name: "Moxicam 15 mg",
    },
    {
      name: "Moxicam 15 mg capsule, 30",
    },
    {
      name: "Moxicam 7.5 mg",
    },
    {
      name: "Moxicam 7.5 mg capsule, 30",
    },
    {
      name: "Moxiclav Duo 500/125",
    },
    {
      name: "Moxiclav Duo Forte 875/125",
    },
    {
      name: "Moxonidine (Apo) 200 microgram",
    },
    {
      name: "Moxonidine (Apo) 400 microgram",
    },
    {
      name: "Mupirocin (Apo) 2% ointment, 15 g",
    },
    {
      name: "Mupirocin (Medicianz) 2% ointment, 15 g",
    },
    {
      name: "Mupirocin Nasal (Medsurge) 2% ointment, 5 g",
    },
    {
      name: "Murelax 30 mg",
    },
    {
      name: "Mycobutin 150 mg capsule, 30",
    },
    {
      name: "Mycocept 500 mg",
    },
    {
      name: "Mycostatin 100 000 units/g cream, 15 g",
    },
    {
      name: "Mycostatin 100 000 units/mL oral liquid, 24 mL",
    },
    {
      name: "Myfortic 180 mg enteric",
    },
    {
      name: "Myfortic 360 mg enteric",
    },
    {
      name: "Myleran 2 mg",
    },
    {
      name: "Mysoline 250 mg",
    },
    {
      name: "Naprosyn 250 mg",
    },
    {
      name: "Naprosyn 500 mg",
    },
    {
      name: "Naprosyn SR 1000 1 g modified release",
    },
    {
      name: "Naprosyn SR 750 mg modified release",
    },
    {
      name: "Naramig 2.5 mg",
    },
    {
      name: "Nardil 15 mg",
    },
    {
      name: "Nardil 15 mg sugar coated",
    },
    {
      name: "Natrilix 2.5 mg",
    },
    {
      name: "Natrilix SR 1.5 mg modified release",
    },
    {
      name: "Navelbine 20 mg capsule, 1",
    },
    {
      name: "Navelbine 30 mg capsule, 1",
    },
    {
      name: "Nebilet 1.25 mg",
    },
    {
      name: "Nebilet 10 mg",
    },
    {
      name: "Nebilet 5 mg",
    },
    {
      name: "Nebivolol (Apo) 1.25 mg",
    },
    {
      name: "Nebivolol (Apo) 10 mg",
    },
    {
      name: "Nebivolol (Apo) 5 mg",
    },
    {
      name: "Neo-Mercazole 5 mg",
    },
    {
      name: "Neoral 10 mg capsule, 60",
    },
    {
      name: "Neoral 100 mg capsule, 30",
    },
    {
      name: "Neoral 100 mg/mL oral liquid, 50 mL",
    },
    {
      name: "Neoral 25 mg capsule, 30",
    },
    {
      name: "Neoral 50 mg capsule, 30",
    },
    {
      name: "Neotigason 10 mg capsule, 100",
    },
    {
      name: "Neotigason 25 mg capsule, 100",
    },
    {
      name: "Nesina 12.5 mg",
    },
    {
      name: "Nesina 25 mg",
    },
    {
      name: "Nesina 6.25 mg",
    },
    {
      name: "Nesina Met 12.5 mg/1000 mg",
    },
    {
      name: "Nesina Met 12.5 mg/500 mg",
    },
    {
      name: "Nesina Met 12.5 mg/850 mg",
    },
    {
      name: "Neulactil 10 mg",
    },
    {
      name: "Neulactil 2.5 mg",
    },
    {
      name: "Neuroccord 150 mg capsule, 56",
    },
    {
      name: "Neuroccord 25 mg capsule, 56",
    },
    {
      name: "Neuroccord 300 mg capsule, 56",
    },
    {
      name: "Neuroccord 75 mg capsule, 56",
    },
    {
      name: "Neurontin 100 mg capsule, 100",
    },
    {
      name: "Neurontin 300 mg capsule, 100",
    },
    {
      name: "Neurontin 400 mg capsule, 100",
    },
    {
      name: "Neurontin 600 mg",
    },
    {
      name: "Neurontin 800 mg",
    },
    {
      name: "Nevirapine (Alphapharm) 200 mg",
    },
    {
      name: "Nevirapine XR (Apotex) 400 mg modified release",
    },
    {
      name: "Nexavar 200 mg",
    },
    {
      name: "Nexazole 20 mg enteric",
    },
    {
      name: "Nexazole 40 mg enteric",
    },
    {
      name: "Nexium 20 mg enteric",
    },
    {
      name: "Nexium 40 mg enteric",
    },
    {
      name: "Nexole 20 mg enteric",
    },
    {
      name: "Nexole 40 mg enteric",
    },
    {
      name: "Nicorandil (Apo) 10 mg",
    },
    {
      name: "Nicorandil (Apo) 20 mg",
    },
    {
      name: "Nidem 80 mg",
    },
    {
      name: "Nifedipine XR (Apo) 30 mg modified release",
    },
    {
      name: "Nifedipine XR (Apo) 60 mg modified release",
    },
    {
      name: "Nilstat 20 000 units/g vaginal cream, 75 g",
    },
    {
      name: "Nilstat 500 000 units",
    },
    {
      name: "Nilstat 500 000 units capsule, 50",
    },
    {
      name: "Nitrolingual 400 microgr",
    },
    {
      name: "Nitrostat 300 microgram sublingual",
    },
    {
      name: "Nitrostat 600 microgram sublingual",
    },
    {
      name: "Nizac 150 mg capsule, 60",
    },
    {
      name: "Nizac 300 mg capsule, 30",
    },
    {
      name: "Nizoral 1% shampoo, 100 mL",
    },
    {
      name: "Nizoral 2% cream, 30 g",
    },
    {
      name: "Nizoral 2% shampoo, 60 mL",
    },
    {
      name: "Nolvadex-D 20 mg",
    },
    {
      name: "Nordette 28",
    },
    {
      name: "Nordette 30 ED",
    },
    {
      name: "Nordip 10 mg",
    },
    {
      name: "Nordip 5 mg",
    },
    {
      name: "Noriday 28",
    },
    {
      name: "Norimin 28",
    },
    {
      name: "Norimin-1",
    },
    {
      name: "Norimin-1 28",
    },
    {
      name: "Norinyl-1 21",
    },
    {
      name: "Norinyl-1 28",
    },
    {
      name: "Norprolac 75 microgram",
    },
    {
      name: "Norvapine 10 mg",
    },
    {
      name: "Norvapine 5 mg",
    },
    {
      name: "Norvasc 10 mg",
    },
    {
      name: "Norvasc 5 mg",
    },
    {
      name: "Norvir 100 mg",
    },
    {
      name: "Noten 50 mg",
    },
    {
      name: "Novasone 0.1% cream, 15 g",
    },
    {
      name: "Novasone 0.1% lotion, 30 mL",
    },
    {
      name: "Novasone 0.1% ointment, 15 g",
    },
    {
      name: "Novatears 100% eye drops, 3 mL",
    },
    {
      name: "Novatin 10 mg capsule, 100",
    },
    {
      name: "Novatin 25 mg capsule, 100",
    },
    {
      name: "Noxafil 100 mg modified release",
    },
    {
      name: "Noxafil 40 mg/mL oral liquid, 105 mL",
    },
    {
      name: "Noxicid 20 mg enteric capsule, 30",
    },
    {
      name: "Noxicid 40 mg enteric capsule, 30",
    },
    {
      name: "Nuelin 133.3 mg/25 mL oral liquid, 500 mL",
    },
    {
      name: "Nuelin SR 200 mg modified release",
    },
    {
      name: "Nuelin SR 250 mg modified release",
    },
    {
      name: "Nuelin SR 300 mg modified release",
    },
    {
      name: "Nufloxib 400 mg",
    },
    {
      name: "Nupentin 100 mg capsule, 100",
    },
    {
      name: "Nupentin 300 mg capsule, 100",
    },
    {
      name: "Nupentin 400 mg capsule, 100",
    },
    {
      name: "NuvaRing",
    },
    {
      name: "Nuvigil 150 mg",
    },
    {
      name: "Nuvigil 250 mg",
    },
    {
      name: "Nuvigil 50 mg",
    },
    {
      name: "Nystatin 100 000 units/mL oral liquid",
    },
    {
      name: "Ocuflox 0.3% eye drops, 5 mL",
    },
    {
      name: "Odaplix SR 1.5 mg modified release",
    },
    {
      name: "Odefsey",
    },
    {
      name: "Odomzo 200 mg capsule, 30",
    },
    {
      name: "Ofev 100 mg capsule, 60",
    },
    {
      name: "Ofev 150 mg capsule, 60",
    },
    {
      name: "Olanzapine (AN) 10 mg",
    },
    {
      name: "Olanzapine (AN) 2.5 mg",
    },
    {
      name: "Olanzapine (AN) 5 mg",
    },
    {
      name: "Olanzapine (AN) 7.5 mg",
    },
    {
      name: "Olanzapine ODT (AN) 10 mg orally disintegrating",
    },
    {
      name: "Olanzapine ODT (AN) 15 mg orally disintegrating",
    },
    {
      name: "Olanzapine ODT (AN) 20 mg orally disintegrating",
    },
    {
      name: "Olanzapine ODT (AN) 5 mg orally disintegrating",
    },
    {
      name: "Olmekar 20/5 film-coated",
    },
    {
      name: "Olmekar 40/10 film-coated",
    },
    {
      name: "Olmekar 40/5 film-coated",
    },
    {
      name: "Olmertan 20 mg",
    },
    {
      name: "Olmertan 40 mg",
    },
    {
      name: "Olmertan Combi 20/12.5",
    },
    {
      name: "Olmertan Combi 40/12.5",
    },
    {
      name: "Olmertan Combi 40/25",
    },
    {
      name: "Olmesartan (AN) 20 mg",
    },
    {
      name: "Olmesartan (AN) 40 mg",
    },
    {
      name: "Olmesartan HCT 20/12.5 (AN)",
    },
    {
      name: "Olmesartan HCT 20/12.5 (Myl)",
    },
    {
      name: "Olmesartan HCT 40/12.5 (AN)",
    },
    {
      name: "Olmesartan HCT 40/12.5 (Myl)",
    },
    {
      name: "Olmesartan HCT 40/25 (AN)",
    },
    {
      name: "Olmesartan HCT 40/25 (Myl)",
    },
    {
      name: "Olmesartan HCTZ 20/12.5 (Apo)",
    },
    {
      name: "Olmesartan HCTZ 20/12.5 (Pharmacor)",
    },
    {
      name: "Olmesartan HCTZ 40/12.5 (Apo)",
    },
    {
      name: "Olmesartan HCTZ 40/12.5 (Pharmacor)",
    },
    {
      name: "Olmesartan HCTZ 40/25 (Apo)",
    },
    {
      name: "Olmesartan HCTZ 40/25 (Pharmacor)",
    },
    {
      name: "Olmesartan/Amlodipine 20/5 (Apotex)",
    },
    {
      name: "Olmesartan/Amlodipine 20/5 (Mylan)",
    },
    {
      name: "Olmesartan/Amlodipine 40/10 (Apotex)",
    },
    {
      name: "Olmesartan/Amlodipine 40/10 (Mylan)",
    },
    {
      name: "Olmesartan/Amlodipine 40/5 (Apotex)",
    },
    {
      name: "Olmesartan/Amlodipine 40/5 (Mylan)",
    },
    {
      name: "Olmesartan/HCT 20/12.5 (Sandoz)",
    },
    {
      name: "Olmesartan/HCT 40/12.5 (Sandoz)",
    },
    {
      name: "Olmesartan/HCT 40/25 (Sandoz)",
    },
    {
      name: "Olmetec 20 mg",
    },
    {
      name: "Olmetec 40 mg",
    },
    {
      name: "Olmetec Plus 20/12.5 mg",
    },
    {
      name: "Olmetec Plus 40/12.5 mg",
    },
    {
      name: "Olmetec Plus 40/25 mg",
    },
    {
      name: "Olumiant 2 mg",
    },
    {
      name: "Olumiant 4 mg",
    },
    {
      name: "Omepral 20 mg enteric",
    },
    {
      name: "Omeprazole (AN) 20 mg enteric",
    },
    {
      name: "Omeprazole (Apo) 20 mg enteric",
    },
    {
      name: "Omeprazole (Apo) 20 mg enteric capsule, 30",
    },
    {
      name: "Omeprazole (Generic Health) 20 mg enteric",
    },
    {
      name: "Omeprazole (Pharmacor) 20 mg enteric",
    },
    {
      name: "Omeprazole (Pharmacor) 20 mg enteric capsule, 30",
    },
    {
      name: "Omeprazole (Sandoz) 20 mg enteric",
    },
    {
      name: "Omeprazole (Sandoz) 20 mg enteric capsule, 30",
    },
    {
      name: "Onbrez Breezhaler 150",
    },
    {
      name: "Onbrez Breezhaler 300",
    },
    {
      name: "Ondansetron 4 mg",
    },
    {
      name: "Ondansetron 8 mg",
    },
    {
      name: "Onglyza 2.5 mg",
    },
    {
      name: "Onglyza 5 mg",
    },
    {
      name: "Opsumit 10 mg",
    },
    {
      name: "Optifresh 0.2% eye gel, 10 g",
    },
    {
      name: "Oratane 10 mg capsule, 60",
    },
    {
      name: "Oratane 20 mg capsule, 60",
    },
    {
      name: "Oratane 40 mg capsule, 30",
    },
    {
      name: "Oratane 5 mg capsule, 60",
    },
    {
      name: "Ordine 10 mg/mL oral liquid, 200 mL",
    },
    {
      name: "Ordine 2 mg/mL oral liquid, 200 mL",
    },
    {
      name: "Ordine 5 mg/mL oral liquid, 200 mL",
    },
    {
      name: "Oripro 100 mg pessary, 15",
    },
    {
      name: "Oripro 200 mg pessary, 15",
    },
    {
      name: "Orkambi 100/125",
    },
    {
      name: "Orkambi 100/125 granules, 56 sachets",
    },
    {
      name: "Orkambi 150/188 granules, 56 sachets",
    },
    {
      name: "Orkambi 200/125",
    },
    {
      name: "Oroxine 100 microgram",
    },
    {
      name: "Oroxine 200 microgram",
    },
    {
      name: "Oroxine 50 microgram",
    },
    {
      name: "Oroxine 75 microgram",
    },
    {
      name: "Orudis SR 200 mg modified release capsule, 28",
    },
    {
      name: "Oruvail SR 200 mg modified release capsule, 28",
    },
    {
      name: "Osmolax 1 g/g powder for oral liquid, 510 g",
    },
    {
      name: "Ospolot 200 mg",
    },
    {
      name: "Ospolot 50 mg",
    },
    {
      name: "Osteomol 665 mg modified release",
    },
    {
      name: "Ostira 5 mg/100 mL , 100 mL bag",
    },
    {
      name: "Otocomb Otic ear drops, 7.5 mL",
    },
    {
      name: "Otocomb Otic ointment, 5 g",
    },
    {
      name: "Otodex ear drops, 8 mL",
    },
    {
      name: "Ovestin 0.1% (1 mg/g) cream, 15 g",
    },
    {
      name: "Ovestin Ovula 500 microgram pessary, 15",
    },
    {
      name: "Oxazepam (Apo) 30 mg",
    },
    {
      name: "Oxis Turbuhaler 12 microgram",
    },
    {
      name: "Oxis Turbuhaler 6 microgram",
    },
    {
      name: "Oxybutynin 5 mg",
    },
    {
      name: "Oxytrol 3.9 mg/24 hours patch, 8",
    },
    {
      name: "Ozidal 1 mg",
    },
    {
      name: "Ozidal 2 mg",
    },
    {
      name: "Ozidal 3 mg",
    },
    {
      name: "Ozidal 4 mg",
    },
    {
      name: "Ozidal 500 microgram",
    },
    {
      name: "Ozin 10 mg",
    },
    {
      name: "Ozin 2.5 mg",
    },
    {
      name: "Ozin 5 mg",
    },
    {
      name: "Ozin 7.5 mg",
    },
    {
      name: "Ozmep 20 mg enteric",
    },
    {
      name: "Ozole 100 mg capsule, 28",
    },
    {
      name: "Ozole 200 mg capsule, 28",
    },
    {
      name: "Ozole 50 mg capsule, 28",
    },
    {
      name: "Ozpan 20 mg enteric",
    },
    {
      name: "Ozpan 40 mg enteric",
    },
    {
      name: "Panafcort 1 mg",
    },
    {
      name: "Panafcort 25 mg",
    },
    {
      name: "Panafcort 5 mg",
    },
    {
      name: "Panafcortelone 1 mg",
    },
    {
      name: "Panafcortelone 25 mg",
    },
    {
      name: "Panafcortelone 5 mg",
    },
    {
      name: "Panthron 20 mg enteric",
    },
    {
      name: "Panthron 40 mg enteric",
    },
    {
      name: "Panto 20 mg enteric",
    },
    {
      name: "Panto 40 mg enteric",
    },
    {
      name: "Pantofast 20 mg enteric",
    },
    {
      name: "Pantoprazole 20 mg enteric",
    },
    {
      name: "Pantoprazole 40 mg enteric",
    },
    {
      name: "Panzytrat 25 000 units enteric capsule, 100",
    },
    {
      name: "Paralgin 500 mg",
    },
    {
      name: "Parapane 500 mg",
    },
    {
      name: "Parbezol 10 mg enteric",
    },
    {
      name: "Parbezol 20 mg enteric",
    },
    {
      name: "Pariet 10 mg enteric",
    },
    {
      name: "Pariet 20 mg enteric",
    },
    {
      name: "Parlodel 2.5 mg",
    },
    {
      name: "Parnate 10 mg",
    },
    {
      name: "Paroxetine 20 mg",
    },
    {
      name: "Pavtide MDI 125/25 inhalation",
    },
    {
      name: "Pavtide MDI 250/25 inhalation",
    },
    {
      name: "Paxam 2 mg",
    },
    {
      name: "Paxam 500 microgram",
    },
    {
      name: "Paxtine 20 mg",
    },
    {
      name: "Pemzo 20 mg enteric capsule, 30",
    },
    {
      name: "Pentasa 1 g modified release",
    },
    {
      name: "Pentasa 1 g modified release granules, 120 sachets",
    },
    {
      name: "Pentasa 1 g suppository, 30",
    },
    {
      name: "Pentasa 1 g/100 mL enema, 7 x 100 mL",
    },
    {
      name: "Pentasa 2 g modified release granules, 60 sachets",
    },
    {
      name: "Pentasa 4 g modified release granules, 30 sachets",
    },
    {
      name: "Pentasa 500 mg modified release",
    },
    {
      name: "Perindo 2 mg",
    },
    {
      name: "Perindo 4 mg",
    },
    {
      name: "Perindo 8 mg",
    },
    {
      name: "Perindo Combi 4/1.25",
    },
    {
      name: "Perindopril 2 mg",
    },
    {
      name: "Perindopril 4 mg",
    },
    {
      name: "Perindopril 8 mg",
    },
    {
      name: "Perindopril 10 mg",
    },
    {
      name: "Perindopril 2.5 mg",
    },
    {
      name: "Perindopril 5 mg",
    },
    {
      name: "Perindopril and Indapamide 4/1.25",
    },
    {
      name: "Perindopril Arginine/Amlodipine 10/10",
    },
    {
      name: "Perindopril Arginine/Amlodipine 10/5",
    },
    {
      name: "Perindopril Arginine/Amlodipine 5/10",
    },
    {
      name: "Perindopril Arginine/Amlodipine 5/5",
    },
    {
      name: "Perindopril Erbumine/Indapamide Hemihydrate 4/1.25",
    },
    {
      name: "Persantin SR 200 mg modified release capsule, 60",
    },
    {
      name: "Petibelle",
    },
    {
      name: "Pexsig 100 mg",
    },
    {
      name: "Pharmisil 1% cream, 15 g",
    },
    {
      name: "Pheburane 483 mg/g granules, 174 g",
    },
    {
      name: "Phlexy-10 1 g",
    },
    {
      name: "Phosphate (Phebra) 500 mg effervescent",
    },
    {
      name: "Physeptone 10 mg",
    },
    {
      name: "Physiotens 200 microgram",
    },
    {
      name: "Physiotens 400 microgram",
    },
    {
      name: "Piax 75 mg",
    },
    {
      name: "Piax Plus Aspirin 75/100",
    },
    {
      name: "Picato 0.015% gel, 3 x 470 mg",
    },
    {
      name: "Picato 0.05% gel, 2 x 470 mg",
    },
    {
      name: "Pinetarsol solution, 500 mL",
    },
    {
      name: "Pioglitazone (AN) 15 mg",
    },
    {
      name: "Pioglitazone (AN) 30 mg",
    },
    {
      name: "Pioglitazone (AN) 45 mg",
    },
    {
      name: "Piroxicam (Apo) 10 mg capsule, 50",
    },
    {
      name: "Piroxicam (Apo) 20 mg capsule, 25",
    },
    {
      name: "Piroxicam (GenRx) 10 mg capsule, 50",
    },
    {
      name: "Piroxicam (GenRx) 20 mg capsule, 25",
    },
    {
      name: "Placil 25 mg",
    },
    {
      name: "Plaquenil 200 mg",
    },
    {
      name: "Plavicor 75 mg",
    },
    {
      name: "Plavix 75 mg",
    },
    {
      name: "Plendil ER 10 mg modified release",
    },
    {
      name: "Plendil ER 2.5 mg modified release",
    },
    {
      name: "Plendil ER 5 mg modified release",
    },
    {
      name: "Plidogrel 75 mg",
    },
    {
      name: "Pomalyst 3 mg capsule, 21",
    },
    {
      name: "Pomalyst 4 mg capsule, 21",
    },
    {
      name: "Ponstan 250 mg capsule, 50",
    },
    {
      name: "Pradaxa 110 mg capsule, 10",
    },
    {
      name: "Pradaxa 110 mg capsule, 60",
    },
    {
      name: "Pradaxa 150 mg capsule, 60",
    },
    {
      name: "Pradaxa 75 mg capsule, 10",
    },
    {
      name: "Pradaxa 75 mg capsule, 60",
    },
    {
      name: "Pramin 10 mg",
    },
    {
      name: "Pravachol 10 mg",
    },
    {
      name: "Pravachol 20 mg",
    },
    {
      name: "Pravachol 40 mg",
    },
    {
      name: "Pravachol 80 mg",
    },
    {
      name: "Pravastatin Sodium (AN) 10 mg",
    },
    {
      name: "Pravastatin Sodium (AN) 20 mg",
    },
    {
      name: "Pravastatin Sodium (AN) 40 mg",
    },
    {
      name: "Pravastatin Sodium (AN) 80 mg",
    },
    {
      name: "Prazosin (Apo) 1 mg",
    },
    {
      name: "Prazosin (Apo) 2 mg",
    },
    {
      name: "Prazosin (Apo) 5 mg",
    },
    {
      name: "Predmix 5 mg/mL oral liquid, 30 mL",
    },
    {
      name: "Prednefrin Forte eye drops, 10 mL",
    },
    {
      name: "Predsol 5 mg suppository, 10",
    },
    {
      name: "Predsol Retention 20 mg/100 mL enema, 7 x 100 mL",
    },
    {
      name: "Predsolone 1 mg",
    },
    {
      name: "Predsone 1 mg",
    },
    {
      name: "Pregabalin 25 mg capsule, 56",
    },
    {
      name: "Pregabalin 300 mg capsule, 56",
    },
    {
      name: "Pregabalin 75 mg capsule, 56",
    },
    {
      name: "Pregabalin 150 mg capsule, 56",
    },
    {
      name: "Presolol 100 mg",
    },
    {
      name: "Presolol 200 mg",
    },
    {
      name: "Prexum 10 mg",
    },
    {
      name: "Prexum 2.5 mg",
    },
    {
      name: "Prexum 5 mg",
    },
    {
      name: "Prexum Combi 5/1.25",
    },
    {
      name: "Prexum Combi LD 2.5/0.625",
    },
    {
      name: "Prezcobix",
    },
    {
      name: "Prezista 150 mg",
    },
    {
      name: "Prezista 600 mg",
    },
    {
      name: "Prezista 800 mg",
    },
    {
      name: "Primolut N 5 mg",
    },
    {
      name: "Pristiq 100 mg modified release",
    },
    {
      name: "Pristiq 50 mg modified release",
    },
    {
      name: "Pritor/Amlodipine 40 mg/10 mg",
    },
    {
      name: "Pritor/Amlodipine 40 mg/5 mg",
    },
    {
      name: "Pritor/Amlodipine 80 mg/10 mg",
    },
    {
      name: "Pritor/Amlodipine 80 mg/5 mg",
    },
    {
      name: "Pro-Banthine 15 mg",
    },
    {
      name: "Pro-Cid 500 mg",
    },
    {
      name: "Probitor 20 mg enteric capsule, 30",
    },
    {
      name: "Procalm 5 mg",
    },
    {
      name: "Prochlorperazine Maleate (AN) 5 mg",
    },
    {
      name: "Prochlorperazine Maleate (Apo) 5 mg",
    },
    {
      name: "Prochlorperazine Maleate (GH) 5 mg",
    },
    {
      name: "Prodeine Forte",
    },
    {
      name: "Profore (66050016) four layer bandage, 1",
    },
    {
      name: "Profore Lite (66050415) four layer bandage, 1",
    },
    {
      name: "Progout 100 mg",
    },
    {
      name: "Progout 300 mg",
    },
    {
      name: "Prograf 1 mg capsule, 100",
    },
    {
      name: "Prograf 5 mg capsule, 50",
    },
    {
      name: "Progynova 1 mg",
    },
    {
      name: "Progynova 2 mg",
    },
    {
      name: "Proladone 30 mg suppository, 12",
    },
    {
      name: "Propranolol (Apo) 10 mg",
    },
    {
      name: "Propranolol (Apo) 40 mg",
    },
    {
      name: "Propylthiouracil (Phebra) 50 mg",
    },
    {
      name: "Proscar 5 mg",
    },
    {
      name: "Provera 10 mg",
    },
    {
      name: "Provera 100 mg",
    },
    {
      name: "Provera 200 mg",
    },
    {
      name: "Provera 250 mg",
    },
    {
      name: "Provera 5 mg",
    },
    {
      name: "Provera 500 mg",
    },
    {
      name: "Proxen SR 1 g modified release",
    },
    {
      name: "Proxen SR 750 mg modified release",
    },
    {
      name: "Prozac 20 mg",
    },
    {
      name: "Prozac 20 mg capsule, 28",
    },
    {
      name: "Prozero oral liquid, 18 x 250 mL cartons",
    },
    {
      name: "Prozero oral liquid, 6 x 1 L cartons",
    },
    {
      name: "Pryzex 10 mg",
    },
    {
      name: "Pryzex 2.5 mg",
    },
    {
      name: "Pryzex 5 mg",
    },
    {
      name: "Pryzex 7.5 mg",
    },
    {
      name: "Pryzex ODT 10 mg orally disintegrating",
    },
    {
      name: "Pryzex ODT 15 mg orally disintegrating",
    },
    {
      name: "Pryzex ODT 20 mg orally disintegrating",
    },
    {
      name: "Pryzex ODT 5 mg orally disintegrating",
    },
    {
      name: "Pulmicort Turbuhaler 100 microgram/actuation",
    },
    {
      name: "Pulmicort Turbuhaler 200 microgram/actuation",
    },
    {
      name: "Pulmicort Turbuhaler 400 microgram/actuation",
    },
    {
      name: "Puri-Nethol 50 mg",
    },
    {
      name: "Pyralin EN 500 mg enteric",
    },
    {
      name: "Qlaira",
    },
    {
      name: "Qpril 10 mg",
    },
    {
      name: "Qpril 20 mg",
    },
    {
      name: "Qpril 5 mg",
    },
    {
      name: "Qtern 5/10",
    },
    {
      name: "Quepine XR 150 mg modified release",
    },
    {
      name: "Quepine XR 200 mg modified release",
    },
    {
      name: "Quepine XR 300 mg modified release",
    },
    {
      name: "Quepine XR 400 mg modified release",
    },
    {
      name: "Quepine XR 50 mg modified release",
    },
    {
      name: "Questran Lite 4 g powder for oral liquid, 50 sachets",
    },
    {
      name: "Quetia 100 mg",
    },
    {
      name: "Quetia 200 mg",
    },
    {
      name: "Quetia 25 mg",
    },
    {
      name: "Quetia 300 mg",
    },
    {
      name: "Quetiapine 100 mg",
    },
    {
      name: "Quetiapine 200 mg",
    },
    {
      name: "Quetiapine 25 mg",
    },
    {
      name: "Quetiapine 300 mg",
    },
    {
      name: "Quetiapine XR 50 mg modified release",
    },
    {
      name: "Quetiapine XR 200 mg modified release",
    },
    {
      name: "Quetiapine XR 300 mg modified release",
    },
    {
      name: "Quetiapine XR 400 mg modified release",
    },
    {
      name: "Quilonum SR 450 mg modified release",
    },
    {
      name: "Quinapril (Apo) 10 mg",
    },
    {
      name: "Quinapril (Apo) 20 mg",
    },
    {
      name: "Quinapril (Generic Health) 20 mg",
    },
    {
      name: "Quinate 300 mg",
    },
    {
      name: "Qvar Autohaler 100",
    },
    {
      name: "Qvar Autohaler 50",
    },
    {
      name: "Qvar Inhaler 100",
    },
    {
      name: "Qvar Inhaler 50",
    },
    {
      name: "Rabeprazole 10 mg enteric",
    },
    {
      name: "Rabeprazole 20 mg enteric",
    },
    {
      name: "Ralovera 10 mg",
    },
    {
      name: "Ralovera 5 mg",
    },
    {
      name: "Ralovista 60 mg",
    },
    {
      name: "Raloxifene (Amneal) 60 mg",
    },
    {
      name: "Raloxifene (GH) 60 mg",
    },
    {
      name: "Raloxifene Hydrochloride (AN) 60 mg",
    },
    {
      name: "Ramace 1.25 mg",
    },
    {
      name: "Ramace 10 mg capsule, 30",
    },
    {
      name: "Ramace 2.5 mg",
    },
    {
      name: "Ramace 5 mg",
    },
    {
      name: "Ramipril 1.25 mg capsule, 30",
    },
    {
      name: "Ramipril 10 mg",
    },
    {
      name: "Ramipril 5 mg",
    },
    {
      name: "Ramipril 2.5 mg",
    },
    {
      name: "Ransim 10 mg",
    },
    {
      name: "Ransim 80 mg",
    },
    {
      name: "Rapamune 1 mg",
    },
    {
      name: "Rapamune 1 mg/mL oral liquid, 60 mL",
    },
    {
      name: "Rapamune 2 mg",
    },
    {
      name: "Rapamune 500 microgram",
    },
    {
      name: "Rasagiline (Pharmacor) 1 mg",
    },
    {
      name: "Rasagiline (Teva) 1 mg",
    },
    {
      name: "Reaptan 10mg/10mg (perindopril arginine/amlodipine)",
    },
    {
      name: "Reaptan 10mg/5mg (perindopril arginine/amlodipine)",
    },
    {
      name: "Reaptan 5mg/10mg (perindopril arginine/amlodipine)",
    },
    {
      name: "Reaptan 5mg/5mg (perindopril arginine/amlodipine)",
    },
    {
      name: "Redipred 5 mg/mL oral liquid, 30 mL",
    },
    {
      name: "Reedos 100 mg",
    },
    {
      name: "Reedos 200 mg",
    },
    {
      name: "Reedos 25 mg",
    },
    {
      name: "Reedos 50 mg",
    },
    {
      name: "Remeron Soltab 15 mg orally disintegrating",
    },
    {
      name: "Remeron Soltab 30 mg orally disintegrating",
    },
    {
      name: "Remeron Soltab 45 mg orally disintegrating",
    },
    {
      name: "Renagel 800 mg",
    },
    {
      name: "Renitec 10 mg",
    },
    {
      name: "Renitec 20 mg",
    },
    {
      name: "Renitec Plus 20/6",
    },
    {
      name: "Retrovir 100 mg capsule, 100",
    },
    {
      name: "Retrovir 250 mg capsule, 40",
    },
    {
      name: "Retrovir 50 mg/5 mL oral liquid, 200 mL",
    },
    {
      name: "Revatio 20 mg",
    },
    {
      name: "Riamet",
    },
    {
      name: "Ridaura 3 mg",
    },
    {
      name: "Ridaura 3 mg capsule, 60",
    },
    {
      name: "Rifadin 100 mg/5 mL oral liquid, 60 mL",
    },
    {
      name: "Rilutek 50 mg",
    },
    {
      name: "Riluzole (Apo) 50 mg",
    },
    {
      name: "Riluzole (Pharmacor) 50 mg",
    },
    {
      name: "Riluzole (Sandoz) 50 mg",
    },
    {
      name: "Risedro Once a Week 35 mg",
    },
    {
      name: "Risedronate (Apo) 150 mg",
    },
    {
      name: "Risedronate (Apo) 35 mg",
    },
    {
      name: "Risedronate Sodium (AN) 35 mg",
    },
    {
      name: "Risedronate Sodium (GA) 35 mg",
    },
    {
      name: "Risedronate Sodium (Sandoz) 35 mg",
    },
    {
      name: "Rithmik 200 mg",
    },
    {
      name: "Rixadone 1 mg",
    },
    {
      name: "Rixadone 2 mg",
    },
    {
      name: "Rixadone 3 mg",
    },
    {
      name: "Rixadone 4 mg",
    },
    {
      name: "Rixadone 500 microgram",
    },
    {
      name: "Rixalt 10 mg orally disintegrating",
    },
    {
      name: "Rizatriptan ODT 10 mg orally disintegrating",
    },
    {
      name: "Rocaltrol 0.25 microgram capsule, 100",
    },
    {
      name: "Rocta 10 mg capsule, 60",
    },
    {
      name: "Rocta 20 mg capsule, 60",
    },
    {
      name: "Rostor 10 mg",
    },
    {
      name: "Rostor 20 mg",
    },
    {
      name: "Rostor 40 mg",
    },
    {
      name: "Rostor 5 mg",
    },
    {
      name: "Rosuvastatin 20 mg",
    },
    {
      name: "Rosuvastatin 10 mg",
    },
    {
      name: "Rosuvastatin 40 mg",
    },
    {
      name: "Rosuvastatin 5 mg",
    },
    {
      name: "Roxar 150 mg",
    },
    {
      name: "Roxar 300 mg",
    },
    {
      name: "Roxet 20 mg",
    },
    {
      name: "Roxin 400 mg",
    },
    {
      name: "Rydapt 25 mg capsule, 112",
    },
    {
      name: "Rydapt 25 mg capsule, 56",
    },
    {
      name: "Rythmodan 100 mg capsule, 100",
    },
    {
      name: "Sabril 500 mg",
    },
    {
      name: "Salazopyrin 500 mg",
    },
    {
      name: "Salazopyrin EN 500 mg enteric",
    },
    {
      name: "SalplusF Inhaler 125/25 pressurised inhalation",
    },
    {
      name: "SalplusF Inhaler 250/25 pressurised inhalation",
    },
    {
      name: "Salpraz 20 mg enteric",
    },
    {
      name: "Salpraz 40 mg enteric",
    },
    {
      name: "Sandomigran 500 microgram",
    },
    {
      name: "Saphris 10 mg sublingual wafer, 60",
    },
    {
      name: "Saphris 5 mg sublingual wafer, 60",
    },
    {
      name: "Seebri Breezhaler 50 microgram powder",
    },
    {
      name: "Segluromet 2.5/1000",
    },
    {
      name: "Segluromet 2.5/500",
    },
    {
      name: "Segluromet 7.5/1000",
    },
    {
      name: "Segluromet 7.5/500",
    },
    {
      name: "Seretide Accuhaler 100/50",
    },
    {
      name: "Seretide Accuhaler 250/50",
    },
    {
      name: "Seretide Accuhaler 500/50",
    },
    {
      name: "Seretide MDI 125/25",
    },
    {
      name: "Seretide MDI 250/25",
    },
    {
      name: "Seretide MDI 50/25",
    },
    {
      name: "Serevent Accuhaler 50",
    },
    {
      name: "Seroquel 100 mg",
    },
    {
      name: "Seroquel 200 mg",
    },
    {
      name: "Seroquel 25 mg",
    },
    {
      name: "Seroquel 300 mg",
    },
    {
      name: "Sertra 100 mg",
    },
    {
      name: "Sertra 50 mg",
    },
    {
      name: "Sertraline (AN) 100 mg",
    },
    {
      name: "Sertraline (AN) 50 mg",
    },
    {
      name: "Setrona 100 mg",
    },
    {
      name: "Setrona 50 mg",
    },
    {
      name: "Sevelamer (Apotex) 800 mg",
    },
    {
      name: "Sevikar 20/5",
    },
    {
      name: "Sevikar 40/10",
    },
    {
      name: "Sevikar 40/5",
    },
    {
      name: "Sevikar HCT 20/5/12.5",
    },
    {
      name: "Sevikar HCT 40/10/12.5",
    },
    {
      name: "Sevikar HCT 40/10/25",
    },
    {
      name: "Sevikar HCT 40/5/12.5",
    },
    {
      name: "Sevikar HCT 40/5/25",
    },
    {
      name: "Sevredol 10 mg",
    },
    {
      name: "Sevredol 20 mg",
    },
    {
      name: "Shilova 500 mg",
    },
    {
      name: "Sical 0.25 microgram capsule, 100",
    },
    {
      name: "Sigmacort 1% cream, 50 g",
    },
    {
      name: "Sigmacort 1% ointment, 50 g",
    },
    {
      name: "Sigmaxin 250 microgram",
    },
    {
      name: "Sigmaxin PG 62.5 microgram",
    },
    {
      name: "Simipex 1 mg",
    },
    {
      name: "Simplotan 500 mg",
    },
    {
      name: "Simpral 1 mg",
    },
    {
      name: "Simpral 125 microgram",
    },
    {
      name: "Simpral 250 microgram",
    },
    {
      name: "Simvar 10 mg",
    },
    {
      name: "Simvar 20 mg",
    },
    {
      name: "Simvar 40 mg",
    },
    {
      name: "Simvar 80 mg",
    },
    {
      name: "Simvastatin 10 mg",
    },
    {
      name: "Simvastatin 20 mg",
    },
    {
      name: "Simvastatin 40 mg",
    },
    {
      name: "Simvastatin 80 mg",
    },
    {
      name: "Sinemet 100/25",
    },
    {
      name: "Sinemet 250/25",
    },
    {
      name: "Sinemet CR 200/50 modified release",
    },
    {
      name: "Sinequan 10 mg capsule, 50",
    },
    {
      name: "Sinequan 25 mg capsule, 50",
    },
    {
      name: "Singulair 4 mg chewable",
    },
    {
      name: "Singulair 5 mg chewable",
    },
    {
      name: "Sodibic 840 mg capsule, 100",
    },
    {
      name: "Sodium Valproate (Sandoz) 200 mg enteric",
    },
    {
      name: "Sodium Valproate (Sandoz) 500 mg enteric",
    },
    {
      name: "Soflax",
    },
    {
      name: "Sofradex ear drops, 8 mL",
    },
    {
      name: "Soframycin 0.5% eye/ear drops, 8 mL",
    },
    {
      name: "Solaraze 3% gel, 25 g",
    },
    {
      name: "Solavert 160 mg",
    },
    {
      name: "Solavert 80 mg",
    },
    {
      name: "Solone 25 mg",
    },
    {
      name: "Solone 5 mg",
    },
    {
      name: "Solprin 300 mg",
    },
    {
      name: "Somac 20 mg enteric",
    },
    {
      name: "Somac 40 mg enteric",
    },
    {
      name: "Somac 40 mg enteric coated granules, 30 sachets",
    },
    {
      name: "Sone 25 mg",
    },
    {
      name: "Sone 5 mg",
    },
    {
      name: "Sotacor 160 mg",
    },
    {
      name: "Sotacor 80 mg",
    },
    {
      name: "Sotalol 160 mg",
    },
    {
      name: "Sotalol 80 mg",
    },
    {
      name: "Sovaldi 400 mg",
    },
    {
      name: "Sozol 20 mg enteric",
    },
    {
      name: "Sozol 40 mg enteric",
    },
    {
      name: "Spedra 100 mg",
    },
    {
      name: "Spedra 200 mg",
    },
    {
      name: "Spedra 50 mg",
    },
    {
      name: "Spiolto Respimat inhalation solution, 60 actuations",
    },
    {
      name: "Spiractin 100 mg",
    },
    {
      name: "Spiractin 25 mg",
    },
    {
      name: "Spiriva 18 microgram powder for inhalation",
    },
    {
      name: "Spiriva Respimat 2.5 microgram/actuation inhalation solution",
    },
    {
      name: "Sporanox 100 mg capsule, 60",
    },
    {
      name: "Spren 100 mg",
    },
    {
      name: "Steglatro 15 mg",
    },
    {
      name: "Steglatro 5 mg",
    },
    {
      name: "Steglujan 15/100",
    },
    {
      name: "Steglujan 5/100",
    },
    {
      name: "Stelax 10 mg",
    },
    {
      name: "Stelax 25 mg",
    },
    {
      name: "Stemetil 5 mg",
    },
    {
      name: "Stemzine 5 mg",
    },
    {
      name: "Steripaste (3610) 7.5 cm x 6 m bandage, 1",
    },
    {
      name: "Stocrin 200 mg",
    },
    {
      name: "Stocrin 30 mg/mL oral liquid, 180 mL",
    },
    {
      name: "Stocrin 600 mg",
    },
    {
      name: "Stribild",
    },
    {
      name: "Stromectol 3 mg",
    },
    {
      name: "Sulprix 100 mg",
    },
    {
      name: "Sulprix 200 mg",
    },
    {
      name: "Sulprix 400 mg",
    },
    {
      name: "Sumatran 50 mg",
    },
    {
      name: "Sumatriptan (AN) 50 mg",
    },
    {
      name: "Sumatriptan (Apo) 50 mg",
    },
    {
      name: "Sutent 12.5 mg capsule, 28",
    },
    {
      name: "Sutent 25 mg capsule, 28",
    },
    {
      name: "Sutent 37.5 mg capsule, 28",
    },
    {
      name: "Sutent 50 mg capsule, 28",
    },
    {
      name: "Symbicort Rapihaler 100/3 inhalation",
    },
    {
      name: "Symbicort Rapihaler 200/6 inhalation",
    },
    {
      name: "Symbicort Rapihaler 50/3 inhalation",
    },
    {
      name: "Symbicort Turbuhaler 100/6",
    },
    {
      name: "Symbicort Turbuhaler 200/6",
    },
    {
      name: "Symbicort Turbuhaler 400/12",
    },
    {
      name: "Symdeko (28 x 100 mg/150 mg",
    },
    {
      name: "Symmetrel 100 mg capsule, 100",
    },
    {
      name: "Syquet 100 mg",
    },
    {
      name: "Syquet 200 mg",
    },
    {
      name: "Syquet 25 mg",
    },
    {
      name: "Syquet 300 mg",
    },
    {
      name: "Systane 0.4% / 0.3% eye drops, 15 mL",
    },
    {
      name: "Tacidine 150 mg capsule, 60",
    },
    {
      name: "Tacidine 300 mg capsule, 30",
    },
    {
      name: "Tagrisso 40 mg",
    },
    {
      name: "Tagrisso 80 mg",
    },
    {
      name: "Talam 10 mg",
    },
    {
      name: "Talam 20 mg",
    },
    {
      name: "Talam 40 mg",
    },
    {
      name: "Tamate 100 mg",
    },
    {
      name: "Tamate 200 mg",
    },
    {
      name: "Tamate 25 mg",
    },
    {
      name: "Tamate 50 mg",
    },
    {
      name: "Tambocor 100 mg",
    },
    {
      name: "Tambocor 50 mg",
    },
    {
      name: "Tamosin 20 mg",
    },
    {
      name: "Tamoxifen 20 mg",
    },
    {
      name: "Tamsil 250 mg",
    },
    {
      name: "Tarceva 100 mg",
    },
    {
      name: "Tarceva 150 mg",
    },
    {
      name: "Tarceva 25 mg",
    },
    {
      name: "Tarka 2/180 modified release",
    },
    {
      name: "Tarka 4/240 modified release",
    },
    {
      name: "Tasigna 150 mg capsule, 120",
    },
    {
      name: "Tasigna 200 mg capsule, 120",
    },
    {
      name: "Tazac Pulvule 150 mg capsule, 60",
    },
    {
      name: "Tazac Pulvule 300 mg capsule, 30",
    },
    {
      name: "Tegretol 100 mg",
    },
    {
      name: "Tegretol 100 mg/5 mL oral liquid, 300 mL",
    },
    {
      name: "Tegretol 200 mg",
    },
    {
      name: "Tegretol CR 200 mg modified release",
    },
    {
      name: "Tegretol CR 400 mg modified release",
    },
    {
      name: "Telfast 120 mg",
    },
    {
      name: "Telfast 60 mg",
    },
    {
      name: "Telmisartan (AN) 40 mg",
    },
    {
      name: "Telmisartan (AN) 80 mg",
    },
    {
      name: "Telmisartan HCT 80/12.5mg",
    },
    {
      name: "Telmisartan HCT 80/25mg",
    },
    {
      name: "Telmisartan HCTZ 40/12.5mg",
    },
    {
      name: "Telmisartan HCTZ 80/12.5mg",
    },
    {
      name: "Telmisartan HCTZ 80/25mg",
    },
    {
      name: "Telmisartan/HCT 40 mg/12.5 mg",
    },
    {
      name: "Telmisartan/HCT 80 mg/12.5 mg",
    },
    {
      name: "Telmisartan/HCT 80 mg/25 mg",
    },
    {
      name: "Teltartan 40 mg",
    },
    {
      name: "Teltartan 80 mg",
    },
    {
      name: "Teltartan HCT 40/12.5mg",
    },
    {
      name: "Teltartan HCT 80/12.5mg",
    },
    {
      name: "Teltartan HCT 80/25mg",
    },
    {
      name: "Telzir 700 mg",
    },
    {
      name: "Tenaxil SR 1.5 mg modified release",
    },
    {
      name: "Tenolten 50 mg",
    },
    {
      name: "Tenormin 50 mg",
    },
    {
      name: "Tensig 50 mg",
    },
    {
      name: "Terbinafine 250 mg",
    },
    {
      name: "Teveten 400 mg",
    },
    {
      name: "Teveten 600 mg",
    },
    {
      name: "Teveten Plus",
    },
    {
      name: "Thalomid 100 mg capsule, 28",
    },
    {
      name: "Thalomid 50 mg capsule, 28",
    },
    {
      name: "Thioprine 50 mg",
    },
    {
      name: "Tilade CFC-Free 2 mg/actuation inhalation",
    },
    {
      name: "Timoptol 0.5% eye drops, 5 mL",
    },
    {
      name: "Timoptol-XE 0.5% eye drops, 2.5 mL",
    },
    {
      name: "Tinaderm 0.07% pressurised spray, 100 g",
    },
    {
      name: "Tinasil 250 mg",
    },
    {
      name: "Tivicay 50 mg",
    },
    {
      name: "Tixol 30 mg enteric capsule, 28",
    },
    {
      name: "Tixol 60 mg enteric capsule, 28",
    },
    {
      name: "Tobrex 0.3% eye drops, 5 mL",
    },
    {
      name: "Tobrex 0.3% eye ointment, 3.5 g",
    },
    {
      name: "Tofranil 10 mg",
    },
    {
      name: "Tofranil 25 mg",
    },
    {
      name: "Topamax 100 mg",
    },
    {
      name: "Topamax 200 mg",
    },
    {
      name: "Topamax 25 mg",
    },
    {
      name: "Topamax 50 mg",
    },
    {
      name: "Topamax Sprinkle 15 mg capsule, 60",
    },
    {
      name: "Topamax Sprinkle 25 mg capsule, 60",
    },
    {
      name: "Topamax Sprinkle 50 mg capsule, 60",
    },
    {
      name: "Topiramate (AN) 100 mg",
    },
    {
      name: "Topiramate (AN) 200 mg",
    },
    {
      name: "Topiramate (AN) 25 mg",
    },
    {
      name: "Topiramate (AN) 50 mg",
    },
    {
      name: "Toprol-XL 190mg",
    },
    {
      name: "Toprol-XL 47.5mg",
    },
    {
      name: "Toprol-XL 95mg",
    },
    {
      name: "Torvastat 10 mg",
    },
    {
      name: "Torvastat 20 mg",
    },
    {
      name: "Torvastat 40 mg",
    },
    {
      name: "Torvastat 80 mg",
    },
    {
      name: "Tracleer 125 mg",
    },
    {
      name: "Tracleer 62.5 mg",
    },
    {
      name: "Trajenta 5 mg",
    },
    {
      name: "Trajentamet 2.5 mg/1000 mg",
    },
    {
      name: "Trajentamet 2.5 mg/500 mg",
    },
    {
      name: "Trajentamet 2.5 mg/850 mg",
    },
    {
      name: "Tranalpha 1 mg capsule, 28",
    },
    {
      name: "Tranalpha 2 mg capsule, 28",
    },
    {
      name: "Tranalpha 4 mg capsule, 28",
    },
    {
      name: "Tranalpha 500 microgram capsule, 28",
    },
    {
      name: "Trandate 100 mg",
    },
    {
      name: "Trandate 200 mg",
    },
    {
      name: "Tranexamic Acid (Apo) 500 mg",
    },
    {
      name: "Transiderm-Nitro-25 5 mg/24 hours patch",
    },
    {
      name: "Transiderm-Nitro-50 10 mg/24 hours patch",
    },
    {
      name: "Travatan 0.004% eye drops, 2.5 mL",
    },
    {
      name: "Trelegy Ellipta 100/62.5/25",
    },
    {
      name: "Triasyn 2.5/2.5 modified release",
    },
    {
      name: "Triasyn 5.0/5.0 modified release",
    },
    {
      name: "Tricortone 0.02% cream, 100 g",
    },
    {
      name: "Tricortone 0.02% ointment, 100 g",
    },
    {
      name: "Trifeme",
    },
    {
      name: "Trifeme 28",
    },
    {
      name: "Trileptal 150 mg",
    },
    {
      name: "Trileptal 300 mg",
    },
    {
      name: "Trileptal 60 mg/mL oral liquid, 250 mL",
    },
    {
      name: "Trileptal 600 mg",
    },
    {
      name: "Triphasil",
    },
    {
      name: "Triprim 300 mg",
    },
    {
      name: "Triquilar ED",
    },
    {
      name: "Tritace 1.25 mg",
    },
    {
      name: "Tritace 10 mg",
    },
    {
      name: "Tritace 10 mg capsule, 30",
    },
    {
      name: "Tritace 2.5 mg",
    },
    {
      name: "Tritace 5 mg",
    },
    {
      name: "Triumeq",
    },
    {
      name: "Trizivir",
    },
    {
      name: "Trovas 10 mg",
    },
    {
      name: "Trovas 20 mg",
    },
    {
      name: "Trovas 40 mg",
    },
    {
      name: "Trovas 80 mg",
    },
    {
      name: "Trusamide 2% eye drops, 5 mL",
    },
    {
      name: "Trusopt 2% eye drops, 5 mL",
    },
    {
      name: "Truvada",
    },
    {
      name: "Tryzan 1.25 mg",
    },
    {
      name: "Tryzan 1.25 mg capsule, 30",
    },
    {
      name: "Tryzan 10 mg",
    },
    {
      name: "Tryzan 10 mg capsule, 30",
    },
    {
      name: "Tryzan 2.5 mg",
    },
    {
      name: "Tryzan 2.5 mg capsule, 30",
    },
    {
      name: "Tryzan 5 mg",
    },
    {
      name: "Tryzan 5 mg capsule, 30",
    },
    {
      name: "Twynsta 40/10 mg",
    },
    {
      name: "Twynsta 40/5 mg",
    },
    {
      name: "Twynsta 80/10 mg",
    },
    {
      name: "Twynsta 80/5 mg",
    },
    {
      name: "Tykerb 250 mg",
    },
    {
      name: "Ultibro Breezhaler 110/50",
    },
    {
      name: "Urederm 10% cream, 100 g",
    },
    {
      name: "Uremide 40 mg",
    },
    {
      name: "Urex 40 mg",
    },
    {
      name: "Urex Forte 500 mg",
    },
    {
      name: "Urex-M 20 mg",
    },
    {
      name: "Urocarb 10 mg",
    },
    {
      name: "Ursodox (GH) 250 mg capsule, 100",
    },
    {
      name: "Ursofalk 250 mg capsule, 100",
    },
    {
      name: "Ursofalk 500 mg",
    },
    {
      name: "Ursosan 250 mg capsule, 100",
    },
    {
      name: "Utrogestan 200 mg capsule: soft, 42",
    },
    {
      name: "Vaclovir 500 mg",
    },
    {
      name: "Valaciclovir (AN) 500 mg",
    },
    {
      name: "Valcyte 450 mg",
    },
    {
      name: "Valette",
    },
    {
      name: "Valganciclovir (Mylan) 450 mg",
    },
    {
      name: "Valganciclovir (Sandoz) 450 mg",
    },
    {
      name: "Valprease 200 mg enteric",
    },
    {
      name: "Valprease 500 mg enteric",
    },
    {
      name: "Valpro EC 200 mg enteric",
    },
    {
      name: "Valpro EC 500 mg enteric",
    },
    {
      name: "Valproate EC 200 mg enteric",
    },
    {
      name: "Valproate EC 500 mg enteric",
    },
    {
      name: "Valsartan/Amlodipine 160/10",
    },
    {
      name: "Valsartan/Amlodipine 160/5",
    },
    {
      name: "Valsartan/Amlodipine 320/10",
    },
    {
      name: "Valsartan/Amlodipine 320/5",
    },
    {
      name: "Valsartan/Amlodipine 80/5",
    },
    {
      name: "Valsartan/Amlodipine/HCT 160/10/12.5",
    },
    {
      name: "Valsartan/Amlodipine/HCT 160/10/25",
    },
    {
      name: "Valsartan/Amlodipine/HCT 160/5/12.5",
    },
    {
      name: "Valsartan/Amlodipine/HCT 160/5/25",
    },
    {
      name: "Valsartan/Amlodipine/HCT 320/10/25",
    },
    {
      name: "Vasocardol 60 mg",
    },
    {
      name: "Vasocardol CD 180 mg",
    },
    {
      name: "Vasocardol CD 240 mg",
    },
    {
      name: "Vasocardol CD 360 mg",
    },
    {
      name: "Vedafil 100 mg",
    },
    {
      name: "Vedafil 25 mg",
    },
    {
      name: "Vedafil 50 mg",
    },
    {
      name: "Vedilol 12.5 mg",
    },
    {
      name: "Vedilol 25 mg",
    },
    {
      name: "Vedilol 3.125 mg",
    },
    {
      name: "Vedilol 6.25 mg",
    },
    {
      name: "Venclexta 10 mg",
    },
    {
      name: "Venclexta 100 mg",
    },
    {
      name: "Venclexta 50 mg",
    },
    {
      name: "Venlafaxine SR 150 mg",
    },
    {
      name: "Venlafaxine SR 37.5 mg",
    },
    {
      name: "Venlafaxine SR 75 mg",
    },
    {
      name: "Ventolin Inhaler CFC-Free 100",
    },
    {
      name: "Vepesid 100 mg capsule, 10",
    },
    {
      name: "Vepesid 50 mg capsule, 20",
    },
    {
      name: "Versacloz 50 mg/mL oral liquid, 100 mL",
    },
    {
      name: "Verzenio 100 mg",
    },
    {
      name: "Verzenio 150 mg",
    },
    {
      name: "Verzenio 50 mg",
    },
    {
      name: "Vexazone 15 mg",
    },
    {
      name: "Vexazone 30 mg",
    },
    {
      name: "Vexazone 45 mg",
    },
    {
      name: "Vfend 200 mg",
    },
    {
      name: "Vfend 50 mg",
    },
    {
      name: "Viramune 10 mg/mL oral liquid, 240 mL",
    },
    {
      name: "Viramune XR 400 mg modified release",
    },
    {
      name: "Viread 300 mg",
    },
    {
      name: "Viscotears Liquid 0.2% eye gel, 10 g",
    },
    {
      name: "Volibris 10 mg",
    },
    {
      name: "Volibris 5 mg",
    },
    {
      name: "Volirop 12.5 mg",
    },
    {
      name: "Volirop 25 mg",
    },
    {
      name: "Volirop 3.125 mg",
    },
    {
      name: "Volirop 6.25 mg",
    },
    {
      name: "Voltaren 100 mg suppository, 20",
    },
    {
      name: "Voltaren 25 mg enteric",
    },
    {
      name: "Voltaren 50 mg enteric",
    },
    {
      name: "Vosevi",
    },
    {
      name: "Votrient 200 mg",
    },
    {
      name: "Votrient 400 mg",
    },
    {
      name: "Voxam 100 mg",
    },
    {
      name: "Voxam 50 mg",
    },
    {
      name: "Vttack 200 mg",
    },
    {
      name: "Vttack 50 mg",
    },
    {
      name: "Vytorin 10/10",
    },
    {
      name: "Vytorin 10/20",
    },
    {
      name: "Vytorin 10/40",
    },
    {
      name: "Vytorin 10/80",
    },
    {
      name: "Vzole 200 mg",
    },
    {
      name: "Vzole 50 mg",
    },
    {
      name: "Xadago 100 mg",
    },
    {
      name: "Xadago 50 mg",
    },
    {
      name: "Xarelto 10 mg",
    },
    {
      name: "Xarelto 15 mg",
    },
    {
      name: "Xarelto 20 mg",
    },
    {
      name: "Xatral SR 10 mg modified release",
    },
    {
      name: "Xelabine 500 mg",
    },
    {
      name: "Xeljanz 5 mg",
    },
    {
      name: "Xelocitabine 150 mg",
    },
    {
      name: "Xelocitabine 500 mg",
    },
    {
      name: "Xenical 120 mg capsule, 84",
    },
    {
      name: "Xergic 120 mg",
    },
    {
      name: "Xifaxan 550 mg",
    },
    {
      name: "Xigduo XR 10/1000 modified release",
    },
    {
      name: "Xigduo XR 10/500 modified release",
    },
    {
      name: "Xigduo XR 5/1000 modified release",
    },
    {
      name: "Xtandi 40 mg capsule, 112",
    },
    {
      name: "Yasmin",
    },
    {
      name: "YAZ Flex",
    },
    {
      name: "YAZ#",
    },
    {
      name: "Zabep 20 mg enteric",
    },
    {
      name: "Zactin 20 mg",
    },
    {
      name: "Zactin 20 mg capsule, 28",
    },
    {
      name: "Zanidip 10 mg",
    },
    {
      name: "Zanidip 20 mg",
    },
    {
      name: "Zarontin 250 mg capsule, 100",
    },
    {
      name: "Zarontin 250 mg capsule, 200",
    },
    {
      name: "Zatamil 0.1% lotion, 30 mL",
    },
    {
      name: "Zatamil 0.1% ointment, 15 g",
    },
    {
      name: "Zavedos 5 mg capsule, 1",
    },
    {
      name: "Zedace 25 mg",
    },
    {
      name: "Zedace 50 mg",
    },
    {
      name: "Zedd 500 mg",
    },
    {
      name: "Zeffix 100 mg",
    },
    {
      name: "Zeklen 10/10",
    },
    {
      name: "Zeklen 10/20",
    },
    {
      name: "Zeklen 10/40",
    },
    {
      name: "Zeklen 10/80",
    },
    {
      name: "Zelboraf 240 mg",
    },
    {
      name: "Zelitrex 500 mg",
    },
    {
      name: "Zentel 200 mg",
    },
    {
      name: "Zepatier",
    },
    {
      name: "Zestril 10 mg",
    },
    {
      name: "Zestril 20 mg",
    },
    {
      name: "Zestril 5 mg",
    },
    {
      name: "Zetin 10 mg capsule, 100",
    },
    {
      name: "Zetin 25 mg capsule, 100",
    },
    {
      name: "Zetlam 100 mg",
    },
    {
      name: "Ziagen 300 mg",
    },
    {
      name: "Zient 10 mg",
    },
    {
      name: "Zilarex 10 mg",
    },
    {
      name: "Zimstat 10 mg",
    },
    {
      name: "Zimstat 20 mg",
    },
    {
      name: "Zimstat 40 mg",
    },
    {
      name: "Zimstat 5 mg",
    },
    {
      name: "Zimstat 80 mg",
    },
    {
      name: "Zinnat 250 mg",
    },
    {
      name: "Zinopril 10 mg",
    },
    {
      name: "Zinopril 20 mg",
    },
    {
      name: "Zinopril 5 mg",
    },
    {
      name: "Zitrocin 500 mg",
    },
    {
      name: "Zocor 10 mg",
    },
    {
      name: "Zocor 20 mg",
    },
    {
      name: "Zocor 40 mg",
    },
    {
      name: "Zocor 80 mg",
    },
    {
      name: "Zoely",
    },
    {
      name: "Zofran 4 mg/5 mL oral liquid, 50 mL",
    },
    {
      name: "Zofran 8mg",
    },
    {
      name: "Zoloft 100 mg",
    },
    {
      name: "Zoloft 50 mg",
    },
    {
      name: "Zomig 2.5 mg",
    },
    {
      name: "Zyloprim 100 mg",
    },
    {
      name: "Zyloprim 300 mg",
    },
  ],
};
