import React from "react";
import { useSelector } from "react-redux";
import { User } from "@helpers";
import { Route, Switch, Redirect } from "react-router-dom";
import AppLayout from "@layouts/appLayout";
import EmergencyDoctors from "./emergency_doctors";
import CreateCompany from "./create_company";
import EditCompany from "./companies/edit";
import Specialists from "./specialists";
import Companies from "./companies";
import Nurses from "./nurses";
import CreateNurse from "./nurses/create";
import EditNurse from "./nurses/edit";
import Specialties from "./specialties";
import CreateSpecialty from "./specialties/new";
import EditSpecialty from "./specialties/edit";
import SpecialtyCms from "@containers/specialties_cms";
import Referrals from "./patients/referrals";
import Patients from "./patients";
import Appointments from "./appointments";
import DoctorProfile from "@src/containers/doctor_pages/account";
import CreateDoctorProfile from "@src/containers/doctor_pages/account/new";

function Admin() {
  const user = useSelector((state) => state.session.user);
  const isAdmin = User.isAdmin(user);
  if (!user || !isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <AppLayout>
      <div className="admin">
        <Switch>
          <Route path="/admin/emergency-doctors" component={EmergencyDoctors} />
          <Route path="/admin/create-company" component={CreateCompany} />
          <Route path="/admin/specialists" component={Specialists} />
          <Route path="/admin/referrals" component={Referrals} />
          <Route path="/admin/patients" component={Patients} />
          <Route path="/admin/companies/:id" component={EditCompany} />
          <Route path="/admin/companies" component={Companies} />
          <Route
            path="/admin/create-emergency-room-manager"
            component={CreateNurse}
          />
          <Route
            path="/admin/emergency-room-manager/:id"
            component={EditNurse}
          />
          <Route path="/admin/emergency-room-manager" component={Nurses} />
          <Route path="/admin/appointments" component={Appointments} />
          <Route path="/admin/signup-doctor" component={CreateDoctorProfile} />
          <Route path="/admin/doctors/edit/:id" component={DoctorProfile} />
          <Route path="/admin/specialties/edit/:id" component={EditSpecialty} />
          <Route
            exact
            path="/admin/specialties/new"
            component={CreateSpecialty}
          />
          <Route path="/admin/specialty/:id" component={SpecialtyCms} />
          <Route path="/admin/specialties" component={Specialties} />
        </Switch>
      </div>
    </AppLayout>
  );
}

export default Admin;
