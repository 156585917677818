import React, { useState, useEffect } from "react";
import PatientInfo from "./patient_information";
import SmsVerification from "./sms_verification";
import LoginWithPincode from "./login_with_pincode";
import JoinNow from "./join_now";
import { history } from "@modules/";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { User, Utils } from "@helpers/index";
import { closeConfirmationModal, showConfirmationModal } from "@modules/gui";
import moment from "moment";

const InvalidAppointment = (props) => {
  const { errors } = props;
  return (
    <div className="jag_info_wrap">
      <h1 className="jag_title"></h1>
      <span className="jag_sub_title"></span>
      <div className="jag-call-alert">
        <div className="message-alert-box alert-danger">
          <i className="icon-warning"></i>
          <h4>Access Denied</h4>
          {errors.map((error, index) => {
            return <p key={`error-${index}`}>{error}</p>;
          })}
        </div>
      </div>
    </div>
  );
};
const Loading = () => {
  return (
    <div className="jag_info_wrap">
      <h1 className="jag_title"></h1>
      <span className="jag_sub_title">LOADING ...</span>
    </div>
  );
};

const EmergencyConsultAccess = (props) => {
  const {
    user,
    isNursingRoom,
    renderStatus,
    onJoinNow,
    setPrice,
    onPatientAccepted,
    contentTemplate,
  } = props;

  const [errors, setErrors] = useState([]);
  const [patient, setPatient] = useState({});
  const [status, setStatus] = useState("LOADING");
  const [pinCodeData, setPinCodeData] = useState({});
  const [holdCallData, setHoldCallData] = useState(null);
  const location = useLocation();
  const query = Utils.useQuery(location);
  const consultInfo = query?.consultInfo;
  const [confirmHoldCallUntil, seConfirmHoldCallUntil] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchSubscriptionPlans();
  }, []);

  const consultInfoParse = () => {
    let parse = {};
    if (typeof consultInfo == "string" || consultInfo instanceof String) {
      parse = JSON.parse(decodeURIComponent(consultInfo));
    }
    return parse;
  };
  const fetchSubscriptionPlans = async () => {
    try {
      const response = await User.fetchSubscriptionPlans();
      if (response) {
        const { plans, onHoldData } = response;
        const planEnable = plans.find((plan) => {
          return plan.enabled;
        });
        setPrice(planEnable.price);
        setHoldCallData(onHoldData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!renderStatus) {
      setStatus("PATIENT_INFORMATION");
      return;
    }
    setStatus(renderStatus);
  }, [renderStatus]);

  const joinNow = () => {
    if (holdCallData && !confirmHoldCallUntil) {
      showHoldCallConfirmationModal();
      return;
    }
    dispatch(closeConfirmationModal());
    closeConfirmationModal();
    onJoinNow(patient, consultInfoParse(), confirmHoldCallUntil);
  };

  const confirmHoldcall = () => {
    seConfirmHoldCallUntil(true);
    onJoinNow(patient, consultInfoParse(), confirmHoldCallUntil);
  };

  const showHoldCallConfirmationModal = () => {
    const thunk = showConfirmationModal({
      onProceed: () => confirmHoldcall(),
      proceedText: "Accept",
      cancelText: "Cancel",
      children: `The next available appointment is at ${moment(
        holdCallData?.to
      ).format("hh:mm a")}`,
      onCancel: () => dispatch(closeConfirmationModal()),
      onClose: () => dispatch(closeConfirmationModal()),
    });
    dispatch(thunk);
  };

  const onUserExists = (userData) => {
    if (onPatientAccepted) {
      onPatientAccepted(userData);
      return;
    }
    const { user } = props;
    const role = User.getRoleName(user);
    if (role === "nurse") {
      setPatient(userData);
      setStatus("REQUEST_ACCESS");
      return;
    }
    setPinCodeData({ ...userData });
    setStatus("LOGIN_WITH_PINCODE");
  };

  const callSettingError = (e) => {
    console.log("callSettingError", e);
  };
  const patientAccepted = (p) => {
    if (onPatientAccepted) {
      onPatientAccepted(p);

      return;
    }
    const role = User.getRoleName(user);

    if (role === "nurse") {
      setPatient(p);
      setStatus("REQUEST_ACCESS");
      return;
    }
    setPatient(p);
    setStatus("SMS_VERIFICATION");
  };
  const onEmailVerify = () => {
    console.log("Verification Success");
    setStatus("REQUEST_ACCESS");
  };

  const onLoginWithPincode = () => {
    console.log("onLoginWithPincode Success");
    setStatus("REQUEST_ACCESS");
  };

  const child = () => {
    const role = User.getRoleName(user);
    let renderStatus = status;
    if (user.id && status === "PATIENT_INFORMATION") {
      renderStatus = "REQUEST_ACCESS";
      if (role === "nurse") {
        renderStatus = "PATIENT_INFORMATION";
      }
    }
    switch (renderStatus) {
      case "ERROR":
        return <InvalidAppointment errors={errors} />;
      case "LOADING":
        return <Loading />;
      case "PATIENT_INFORMATION":
        return (
          <PatientInfo
            contentTemplate={contentTemplate}
            user={user}
            onAccept={(patient) => patientAccepted(patient)}
            isNursingRoom={isNursingRoom}
            onUserExists={(data) => onUserExists(data)}
          />
        );
      case "SMS_VERIFICATION":
        return (
          <SmsVerification
            patient={patient}
            onBack={() => setStatus("PATIENT_INFORMATION")}
            onEmailVerify={() => onEmailVerify()}
          />
        );

      case "REQUEST_ACCESS":
        return (
          <div>
            <JoinNow
              user={user}
              onBack={() => setStatus("PATIENT_INFORMATION")}
              joinNow={() => joinNow()}
              callSettingError={(e) => callSettingError(e)}
            />
          </div>
        );
      case "LOGIN_WITH_PINCODE":
        return (
          <LoginWithPincode
            data={pinCodeData}
            onBack={() => setStatus("PATIENT_INFORMATION")}
            onLoginWithPincode={() => onLoginWithPincode()}
          />
        );
      case "INIT_CHAT":
      default:
        return null;
    }
  };

  return child();
};

export default EmergencyConsultAccess;
