import React from "react";
import { connect } from "react-redux";
import StepModal from "@src/components/modals/step_modal";
import { isEmpty } from "lodash";
import { Services, Toast } from "@helpers/";
import { SHOW_POSTCODE_CHECKER } from "@modules/gui";

class PostCodeMedicareCheckerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { postcode: "", medicareResult: null };
  }
  onChangeMedicarePostCode(postCode) {
    let parseNumber = Number.parseInt(postCode);
    if (isEmpty(postCode)) {
      parseNumber = 0;
    }
    this.setState({ postcode: parseNumber });
  }
  closeModal() {
    this.props.showPostCodeMedicareChecker(false);
  }
  checkMedicarePostCode() {
    const { postcode } = this.state;
    try {
      Services.isMedicareElegibleWithPostalCode(postcode).then((response) => {
        let { isMedicare } = response;
        let medicareResult = `Postcode ${postcode} is <strong>NOT</strong> a Medicare eligible telehealth area.`;
        if (isMedicare) {
          medicareResult = `Postcode ${postcode} is a Medicare <strong>ELEGIBLE</strong> telehealth area.`;
        }
        this.setState({ medicareResult, postcode: "" });
      });
    } catch (error) {
      Toast.displayToast({
        kind: "error",
        message: "An error occurred, please try again later.",
      });
    }
  }
  render() {
    const { postcode, medicareResult } = this.state;
    return (
      <div>
        {this.props.gui.showPostCodeMedicareChecker ? (
          <StepModal
            title="Check Medicare Elegibility"
            bottomBtnClass={"btn-info"}
            onBottomBtnClick={() => this.checkMedicarePostCode()}
            onClose={() => this.closeModal()}
            bottomBtnText="Check Medicare Elegibility"
          >
            {medicareResult ? (
              <p dangerouslySetInnerHTML={{ __html: medicareResult }}></p>
            ) : (
              <p>Please type post code.</p>
            )}
            <input
              className="input-center"
              type="tel"
              placeholder="ENTER POSTCODE"
              value={postcode}
              onChange={(ev) => this.onChangeMedicarePostCode(ev.target.value)}
            />
          </StepModal>
        ) : null}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showPostCodeMedicareChecker: (show) =>
      dispatch({
        type: SHOW_POSTCODE_CHECKER,
        show: show,
      }),
  };
};
const mapStateToProps = ({ gui }) => ({
  gui,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostCodeMedicareCheckerModal);
