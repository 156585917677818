import React from "react";
import logoSpin from "@assets/images/docto-symbol.svg";
const FetchUser = () => {
  return (
    <div id="app-wrapper">
      <div className="loader-wrapper">
        <div className="spinner">
          <img src={logoSpin} className="logo-spin" />
        </div>
        <span>Loading information...</span>
      </div>
    </div>
  );
};

export default FetchUser;
