/* eslint-disable react/no-unescaped-entities */
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import { SHOW_EMERGENCY_BOT } from "@modules/gui";

function Hero() {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("guest");
  const [isCounting, setIsCounting] = useState(false);
  const history = useHistory();

  const imAPatient = () => {
    setUserType("patient");
    setIsCounting(true);
  };
  const onImDoctorClick = () => {
    history.push("/refer-patient");
  };

  const onImBusiness = () => {
    history.push("/business");
  };
  const requestChat = () => {
    setIsCounting(false);
    return dispatch({
      type: SHOW_EMERGENCY_BOT,
      show: true,
      initMessage: "welcome_isConsultOnlineDoctor",
    });
  };
  const onMedicalSpecialistClick = () => {
    history.push("/browse-hospital");
  };
  const countDownDate = useMemo(() => {
    const date = moment().add("minutes", 7).toDate();
    return date;
  }, [isCounting]);
  return (
    <div>
      {userType === "guest" && (
        <div className="home-medical-telehealth">
          <div className="container">
            <div className="">
              <h1>Medical Specialist Telehealth</h1>
              <p>Supporting Australians through the COVID Pandemic</p>

              <div className="mo-hero-actions">
                <button onClick={imAPatient} className="btn btn-red">
                  I'm a Patient
                </button>
                <button onClick={onImDoctorClick} className="btn">
                  I'm a Doctor
                </button>
                <button onClick={onImBusiness} className="btn btn-blue">
                  Corporate
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {userType === "patient" && (
        <div className="home-oh-hero">
          <div className="container">
            <div className="">
              <h1>The Online Hospital</h1>
              <div className="mo-hero-actions">
                {isCounting && (
                  <button className="btn btn-red" onClick={requestChat}>
                    <span>
                      CONSULT ONLINE DR IN{" "}
                      <Countdown
                        date={countDownDate}
                        renderer={({ minutes, seconds }) => {
                          return (
                            <span>
                              {minutes}:{seconds}
                            </span>
                          );
                        }}
                      />
                    </span>
                  </button>
                )}
                <button onClick={onMedicalSpecialistClick} className="btn">
                  <span>Medical Specialist Video Consults</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Hero;
