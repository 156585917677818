import React from "react";
import LoginModal from "@components/modals/login";
const login = (props) => {
  const { navigation } = props;
  return (
    <div className="login-stand-alone">
      <LoginModal {...{ navigation }} />
      <div className="login-stand-alone-brand"></div>
    </div>
  );
};

export default login;
